import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Honorific } from 'src/app/models/Honorific';
import { Gender } from 'src/app/models/Gender';
import { ISDDateMethods } from 'src/app/models/ISDDateMethods';
import { ActorService } from 'src/app/services/actor.service';
import { ExcluderService } from 'src/app/services/excluder.service';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';


@Component({
  selector: 'app-customerdetail',
  templateUrl: './customerdetail.component.html',
  styleUrls: ['./customerdetail.component.scss', '../generic-form.component.scss'],
})

export class CustomerdetailComponent implements OnInit {

  constructor(
    public asrv: ActorService,
    public esrv: ExcluderService
  ) { }

  // post and get - reinstatements
  // extensions - exclusion endpoint take the normal model, no id. supply the reference


  index: number;
  birthDateString: string;
  isMSAUser: boolean = false;
  private dateMethods: ISDDateMethods = new ISDDateMethods();

  honorifics: Honorific[];
  @Input('honorifics') get Honorific(): Honorific[] {
    return this.honorifics;
  };
  @Output('honorifics') honorificsChange: EventEmitter<Honorific[]> = new EventEmitter();
  set Honorific(val: Honorific[]) {
    this.honorifics = val;
    this.honorificsChange.emit(this.honorifics);
  }

  genders: Gender[];
  @Input('genders') get Gender(): Gender[] {
    return this.genders;
  };
  @Output('genders') gendersChange: EventEmitter<Gender[]> = new EventEmitter();
  set Gender(val: Gender[]) {
    this.genders = val;
    this.gendersChange.emit(this.genders);
  }

  events: string[] = [];

  addEvent(type: string, event: MatDatepickerInputEvent<Date>) {
    this.events.push(`${type}: ${event.value}`);
    // console.log(new Date(this.birthDateString).toISOString().slice(0, 10) + '')
  }

  ngOnInit() {
    this.birthDateString = new Date(this.esrv.CurrentExclusion.InteractedActorCompositeModel.BiologicalActorCompositeModel.BirthDate).toISOString().slice(0, 10) + '';
  }

  updateExclsionBirthDate() {
    this.esrv.CurrentExclusion.InteractedActorCompositeModel.BiologicalActorCompositeModel.BirthDate = new Date(this.birthDateString);
    // console.log(this.esrv.CurrentExclusion.InteractedActorCompositeModel.BiologicalActorCompositeModel.BirthDate)
  }
}
