export class Names {
    NameId: number;
    Name: string;
    Edited: Date;
    Edited_ActorId: number;
}

export class NameDetails {
    ActorId: number;
    NameId: number;
    NameClassKeyId: number;
    Order: number;
    Edited: Date;
}

export class Actors_Names {
    ActorId: number;
    NameId: number;
    Name: Name;
    NameClassKeyId: number;
    Order: number;
    Edited: Date;
    Edited_ActorId: number;
}

// Only shows up in Actors_Names when calling a composite model
class Name {
    NameId: number;
    Name: string;
    NameClassKeyId: number;
}