<div class="row" [style.marginTop]="asrv.Globals.Positioning.Small">
    <div class="col-md-6" style="text-align: center;">
        <button *ngIf="!dataModel.DataLoaded" type="button" id="btnExtend" class="btn btn-dark view-button-disabled" disabled>Convert To PDF</button>
        <button *ngIf="dataModel.DataLoaded" type="button" id="btnExtend" class="btn btn-dark view-button-standard" (click)="ConvertToPDF();">Convert To PDF</button>
    </div>
    <div class="col-md-6" style="text-align: center;">
        <button *ngIf="!dataModel.DataLoaded" type="button" id="btnExtend" class="btn btn-dark view-button-disabled" disabled>Export To Excel</button>
        <button *ngIf="dataModel.DataLoaded" type="button" id="btnExtend" class="btn btn-dark view-button-standard" (click)="ExportToExcel();">Export to Excel</button>
    </div>
</div>
<div class="row" [style.marginTop]="asrv.Globals.Positioning.Small">
    <div class="col-6" style="text-align: left;">
        <span *ngIf="showLeftIndicator" style="color:red; font-size: 40px;opacity: 0.4; font-weight:600"><b><<</b></span>
    </div>
    <div class="col-6" style="text-align: right;">
        <span *ngIf="showRightIndicator" style="color:red; font-size: 40px;opacity: 0.4; font-weight:600"><b>>></b></span>
    </div>
</div>
<div #table class="table-responsive bacta-table-outline" (scroll)="TableScrollEvent();" style="margin-top:10px;">
    <table class="table">
        <thead class="table-header">
            <th class="table-header-inner" *ngFor="let key of csvObjectKeys">{{key}}</th>
        </thead>
        <tbody class="table-row" *ngFor="let test of csvObject, index of i" style="background-color: white; color:red;">
            <td class="table-row-inner" *ngFor="let x of tableKeys">
                <span *ngIf="test[x]">{{test[x]}}</span>
                <div *ngIf="test[x] === false" class="spinner-border" role="status">
                    <span class="sr-only"></span>
                </div>        
            </td>            
        </tbody>
    </table>
</div>
