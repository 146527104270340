<div *ngIf="(showSpinner)">
    <app-loading></app-loading>
</div>
<div *ngIf="!(showSpinner)" class ="excluder-container global-seperator" style="overflow-y:auto;overflow-x:hidden;">     
    <div class="form-content" style="height:83%;">
        <div class="row" style="height:2vh"></div> 
        <app-customerdetail *ngIf="(stage === 0)" [(genders)]="genders" [(honorifics)]="honorifics"></app-customerdetail>
        <app-customeraddress *ngIf="(stage === 1)" [(object)]="esrv.CurrentExclusion.InteractedActorCompositeModel.BiologicalActorCompositeModel" [(formName)]="formName"></app-customeraddress>
        <app-excluder-exclusion *ngIf="(stage === 2)"></app-excluder-exclusion>
        <app-exclusion-zone *ngIf="(stage === 3)" [(motorWayCollection)]="motorWayCollection"></app-exclusion-zone>
        <app-customer-photo *ngIf="(stage === 4)" [(mediaModel)]="esrv.CurrentExclusion.MediaModelCollection" (ImageTaken)="ImageTaken();" (NoImageTaken)="ImageDiscarded();"></app-customer-photo>
        <app-terms-and-conditions *ngIf="(stage === 5)" (ShowHideForwardArrow)="ShowHideForwardArrow();"></app-terms-and-conditions>
        <app-exclusion-declaration #appExclusionDeclaration *ngIf="(stage === 6)" (GoForward)="GoForward()"></app-exclusion-declaration>
        <app-exclusion-confirmed *ngIf="(stage === 7)" [(formMessage)]="formMessage"></app-exclusion-confirmed>
    </div>       
    <div class="form-footer">
        <div class="navigation" (click)="GoBack()">
            <span *ngIf="(stage > 0) && (stage < 7) && (showForwardArrow)"><input type="image" src=".\assets\icons\Bacta Assets\Back button blue.svg" style="width:100px" /></span>
        </div>
        <div class="navigation-warning" style="text-align: center; vertical-align:middle; height: 100%;">
            <span [hidden]="(!displayWarning)"><p id="errorMessageDisplay"></p></span>
            <span *ngIf="showSpinner">        
                <div class="col-md-12" style="text-align: center;">
                    <div class="spinner-border text-danger" style="width: 3rem; height: 3rem;" role="status">
                        <span class="sr-only"></span>
                    </div>
                </div>
            </span>
        </div>   
        <div class="navigation" (click)="GoForward()">
            <span *ngIf="(stage >= 0) && (stage < 6) && (showForwardArrow)"><input type="image" src=".\assets\icons\Bacta Assets\Next button blue.svg" style="width:100px" /></span>
        </div>   
    </div>
</div>