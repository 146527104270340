import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Parameter } from '../models/Parameter';
import { ActorService } from './actor.service';
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorhanderService {

  private defaultBorderColor = '#223464';
  private errorBorderColor = 'red'

  private parameters: Parameter[] = [];

  public name;
  public header;
  public message;
  public keys;
  public status;

  // Database Errors
  public lastPostObject;
  public showDataBaseCallback: boolean;
  public showPostObject: boolean;
  public messages: string[] = [];

  public showOops: boolean = false;
  public httpOops: boolean;
  public validationOops: boolean;
  public expiredOops: boolean;

  constructor(private router: Router) { }

  ShowHttpOops(error: any, showPostObject: boolean) {
    this.ClearError();
    if (error) {
      if (error.name) {
        this.name = error.name;
      }
      if (error.status === 400 || error.status === 500) {
        if (error.error) {
          this.showDataBaseCallback = true;
          this.keys = Object.keys(error.error);
          this.message = Object.keys(error.error).toString().split(',').join(', ');
          this.messages = Object.values(error.error);
        }
      } else if (error.message) {
        this.message = error.message;
      }
      if (error.status) {
        this.status = error.status;
      }
    }
    this.showPostObject = showPostObject;
    this.showOops = true;
    this.httpOops = true;
    window.scroll(0,0);
    document.body.style.overflow = 'hidden';
  }

  ShowValidationOops(header, message) {
    this.ClearError();
    this.header = header;
    this.message = message;
    this.showOops = true;
    this.validationOops = true;
  }

  HideOops() {
    this.showDataBaseCallback = false;
    this.showPostObject = false;
    this.showOops = false;
    this.httpOops = false;
    this.validationOops = false;
    this.expiredOops = false;
    document.body.style.overflow = 'auto';
  }

  ClearError() {
    this.name = null;
    this.message = null;
    this.status = null;
  }

  CatchError(functionName, componentName, error) {
    var obj = {function: functionName, component: componentName, error: error, parameters: this.parameters}
    console.error(obj);
    this.parameters = [];
  }

  CreateParameter(name, value) {
    var parameter = new Parameter();
    parameter.name = name;
    parameter.value = value;
    this.parameters.push(parameter)
  }

  BreachStringBuilder(errorObject) {
    if (errorObject.Breached) {
      document.getElementById('ctrlBreachDate').style.borderColor = this.errorBorderColor;
      document.getElementById('ctrlBreachTime').style.borderColor = this.errorBorderColor;
    } else {
      document.getElementById('ctrlBreachDate').style.borderColor = this.defaultBorderColor;
      document.getElementById('ctrlBreachTime').style.borderColor = this.defaultBorderColor;
    }
    if (errorObject.SiteId) {
      document.getElementById('ctrlSites').style.borderColor = this.errorBorderColor;
    } else {
      document.getElementById('ctrlSites').style.borderColor = this.defaultBorderColor;
    }
  }

  AddExclusionStringBuilder(errorObject) {
    var stringBuilder = 'Missing Required Data, please ensure that the ';
    if (errorObject.ExclusionId) {
      stringBuilder += 'ExclusionId, ';
    }
    if (errorObject.Reference) {
      stringBuilder += 'Reference, ';
    }
    if (errorObject.BirthDate) {
      stringBuilder += 'Birthdate, ';
    }
    if (errorObject.Forename) {
      stringBuilder += 'Forename, ';
    }
    if (errorObject.Surname) {
      stringBuilder += 'Surname, ';
    }
    if (errorObject.GenderId) {
      stringBuilder += 'GenderId, '
    }
    if (errorObject.HonorificId) {
      stringBuilder += 'HonorificId, ';
    }
    if (errorObject.County) {
      stringBuilder += 'County, ';
    }
    if (errorObject.Line1) {
      stringBuilder += 'Address Line 1, ';
    }
    if (errorObject.Line2) {
      stringBuilder += 'Address Line 2, ';
    }
    if (errorObject.Line3) {
      stringBuilder += 'Address Line 3, ';
    }
    if (errorObject.Postcode) {
      stringBuilder += 'Postcode, ';
    }
    if (errorObject.TownCity) {
      stringBuilder += 'City, ';
    }
    if (errorObject.Landline) {
      stringBuilder += 'Landline, ';
    }
    if (errorObject.Mobile) {
      stringBuilder += 'Mobile, ';
    }
    if (errorObject.Email) {
      stringBuilder += 'Email, ';
    }
    if (errorObject.CarReg) {
      stringBuilder += 'Car Registration, ';
    }
    if (errorObject.Employee_ActorId) {
      stringBuilder += 'Employee_ActorId, ';
    }
    if (errorObject.ExclusionDurationOptionId) {
      stringBuilder += 'ExclusionDurationOptionId, ';
    }
    if (errorObject.ExclusionReasonKeyId) {
      stringBuilder += 'ExclusionReasonKeyId, ';
    }
    if (errorObject.IdentificationDocumentKeyId) {
      stringBuilder += 'IdentificationDocumentKeyId, ';
    }
    /*
    if (errorObject.Image_Signiture) {
      stringBuilder += 'Image_Signature, ';
    }
    */
    if (errorObject.Notes) {
      stringBuilder += 'Notes, ';
    }
    if (errorObject.Radius) {
      stringBuilder += 'Radius, ';
    }
    if (errorObject.SiteId) {
      stringBuilder += 'SiteId, ';
    }
    if (errorObject.Start) {
      stringBuilder += 'Startdate, ';
    }
    if (errorObject.SiteCollection) {
      stringBuilder += 'SiteCollection, ';
    }
    if (errorObject.IdCheck) {
      stringBuilder += 'IdCheck, ';
    }
    if (errorObject.ReadTCs) {
      stringBuilder += 'ReadTCs Check, ';
    }
    if (errorObject.Exclude_Bingo) {
      stringBuilder += 'Exclude Bingo Check, ';
    }
    if (errorObject.Exclude_Casino) {
      stringBuilder += 'Exclude Casino Check, ';
    }
    if (errorObject.Exclude_BetShop) {
      stringBuilder += 'Exclude Betshop Check, ';
    }
    if (errorObject.Exclude_Online) {
      stringBuilder += 'Exclude Online Check, ';
    }
    if (errorObject.PlanExclude_Bingo) {
      stringBuilder += 'Plan to exclude bingo check, ';
    }
    if (errorObject.PlanExclude_Casino) {
      stringBuilder += 'plan to exclude casino check, ';
    }
    if (errorObject.PlanExclude_BetShop) {
      stringBuilder += 'Plan to exclude betshop check, ';
    }
    if (errorObject.PlanExclude_Online) {
      stringBuilder += 'Plan to exclude online check, ';
    }
    if (errorObject.Confirmed) {
      stringBuilder += 'Exclusion confirm check, ';
    }
    if (errorObject.Extension) {
      stringBuilder += 'Extension object, ';
    }
    if (errorObject.Reinstate) {
      stringBuilder += 'Reinstate object, ';
    }
    if (errorObject.Image_Excluder) {
      stringBuilder += 'Image_Excluder, ';
    }
    if (errorObject.EndDate) {
      stringBuilder += 'EndDate, ';
    }

    stringBuilder += ' please investigate.'

    if (errorObject.message) {
      console.log(errorObject.message);
    }
  }
}
