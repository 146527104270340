<div *ngIf="gsrv.LoadingCards">
    <div class="col-md-12" style="text-align: center;" style="height:100vh;">
        <app-loading></app-loading>
    </div>
</div>
<div *ngIf="!gsrv.LoadingCards" class="container global-seperator"
    style="margin-top: 10px; padding-left: 10px; padding-right: 10px;">
    <div class="row" *ngIf="!showSpinner">
        <div class="col-xs-12 col-md-4" *ngFor="let excluder of gsrv.ExcluderBriefsCurrentlyVisible; let j = index">
            <app-excluder-card (click)="ViewExcluder(excluder)" [excluder]="excluder"></app-excluder-card>
        </div>
    </div>
    <div class="page-counter" style="text-align: center;margin-top:20px;">
        <span *ngIf="gsrv.MaximumPageCount > 0" style="margin-top:10px;margin-bottom:10px;">Page {{gsrv.CurrentPage+1}}
            / {{gsrv.MaximumPageCount}}</span>
    </div>
    <div class="form-footer">
        <div class="navigation" (click)="gsrv.PreviousPage()">
            <span *ngIf="(gsrv.CurrentPage > 0)"><input type="image"
                    src=".\assets\icons\Bacta Assets\Back button blue.svg" style="width:100px" /></span>
        </div>
        <span style="margin-top:10px;margin-bottom:10px;"></span>
        <div class="navigation" (click)="gsrv.NextPage()">
            <span *ngIf="(gsrv.MaximumPageCount > (gsrv.CurrentPage+1))"><input type="image"
                    src=".\assets\icons\Bacta Assets\Next button blue.svg" style="width:100px" /></span>
        </div>
    </div>
</div>