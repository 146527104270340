<div class="container global-seperator" (click)="HideVariables()">
    <div class="row">
        <div class="col-md-12">
            <h5><b>Description</b></h5>
        </div>
    </div>

    <div class="row">
        <div class="col-md-6">
            <div class="input-group" id="inputTitle">
                <div class="input-group-prepend">
                    <span class="input-group-text">Age</span>
                </div>
                <select class="form-control drop-down-purple color-bacta-standard" name="age" [(ngModel)]="isrv.AgeId">
                    <option *ngFor="let age of isrv.AgeOptions" [ngValue]="age.AgeId">{{age.Age}}</option>
                </select>
            </div>
        </div>
        <div class="col-md-6">
            <div class="input-group" id="inputGender">
                <div class="input-group-prepend">
                    <span class="input-group-text">Hair Style</span>
                </div>
                <select class="form-control drop-down-purple color-bacta-standard" name="hairstyle" [(ngModel)]="isrv.HairstyleId">
                    <option *ngFor="let hairstyle of isrv.HairstyleOptions" [ngValue]="hairstyle.HairstyleId">{{hairstyle.Hairstyle}}</option>
                </select>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-6">
            <div class="input-group" id="inputGender">
                <div class="input-group-prepend">
                    <span class="input-group-text">Ethnicity</span>
                </div>
                <select class="form-control drop-down-purple color-bacta-standard" name="ethnicity" [(ngModel)]="isrv.EthnicityId" (click)="EthnicityClick()">
                    <option *ngFor="let ethnicity of isrv.EthnicityOptions" [ngValue]="ethnicity.EthnicityId">{{ethnicity.Ethnicity}}</option>
                </select>
            </div>
        </div>
        <div class="col-md-6">
            <div class="input-group" id="inputGender">
                <div class="input-group-prepend">
                    <span class="input-group-text">Hair Colour</span>
                </div>
                <select class="form-control drop-down-purple color-bacta-standard" name="haircolour" [(ngModel)]="isrv.HairColourId">
                    <option *ngFor="let hairColour of isrv.HairColourOptions" [ngValue]="hairColour.HairColourId">{{hairColour.HairColour}}</option>   
                </select>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-6">
            <div class="input-group" id="inputGender">
                <div class="input-group-prepend">
                    <span class="input-group-text">Height</span>
                </div>
                <select class="form-control drop-down-purple color-bacta-standard" name="height" [(ngModel)]="isrv.HeightId">
                    <option *ngFor="let height of isrv.HeightOptions" [ngValue]="height.HeightId">{{height.Height}}</option>
                </select>
            </div>
        </div>
        <div class="col-md-6">
            <div class="input-group" id="inputGender">
                <div class="input-group-prepend">
                    <span class="input-group-text">Build</span>
                </div>
                <select class="form-control drop-down-purple color-bacta-standard" name="build" [(ngModel)]="isrv.BuildId">
                    <option *ngFor="let build of isrv.BuildOptions" [ngValue]="build.BuildId">{{build.Build}}</option>
                </select>
            </div>
        </div>
    </div>

    <div class="row" style="min-height:200px; padding-top: 20px;padding-bottom:20px;">
        <div class="col-md-12">
            <div style="background-color: #223464; padding: 10px; padding-top: 0px !important; border: 2px solid #223464; border-radius: 0.5em;">
                <div class="row">
                    <span style="color:white;">Notes</span>
                </div>
                <div class="row">
                    <!--[(ngModel)]="interaction.Notes"-->
                    <textarea id="ctrlNotes" class="color-bacta-standard" style="width:100%; height: 150px;" [(ngModel)]="isrv.Interaction.Notes"></textarea>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="showEthnicityInfo" class="col-12" style="margin-top: 30px;">
        <div class="row">
            <h4><u>Help</u></h4>
        </div>
        <div class="row">
            South Asian: Indian / Pakistani / Bangladeshi
        </div>      
        <div class="row">
            East Asian: Chinese / Japanese / Korean
        </div>      
    </div>

</div>