import { Component, OnInit } from '@angular/core';
import { MessageList } from 'src/app/models/messaging/MessageList';
import { Title } from '@angular/platform-browser';
import { MessagingService } from 'src/app/services/messaging.service';

@Component({
  selector: 'app-messaging-main',
  templateUrl: './messaging-main.component.html',
  styleUrls: ['./messaging-main.component.scss']
})
export class MessagingMainComponent implements OnInit {

  public messageList: MessageList;
  public selectedFilter: number;

  constructor(
    public msrv: MessagingService, 
    private titleService: Title) { 
  }

  ngOnInit() {
    this.titleService.setTitle('Messages');
    this.msrv.GetAllMessages().then(value => {
    });
  }

  ChangeFilter() {
    this.msrv.SetEndDateFilter(new Date());
    switch(this.selectedFilter) {
      case 0:
        this.msrv.GetAllMessages().then(value => {
        });
        break;
      case 1:
        this.msrv.GetImportantMessage().then(value => {
        });
        break;
      case 2:
        this.msrv.GetUnreadMessages().then(value => {
        });
        break;
    }
  }
  CloseCompose(){}
}
