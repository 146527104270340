<div class="container global-seperator">
    <div class="row">
        <div class="col-md-6">
            <div class="input-group" id="inputReportSelect">
                <div class="input-group-prepend">
                    <span class="input-group-text">Report</span>
                </div>
                <select class="form-control drop-down-purple" name="report" [(ngModel)]="report">
                    <option [ngValue]="0"></option>
                    <!-- <option *ngIf="isAdmin" [ngValue]="1">Photo Gallery</option> -->
                    <option [ngValue]="2">Excluded Customer List</option>
                    <option [ngValue]="3">Breaches</option>
                    <option [ngValue]="4">Reinstatements</option>
                    <!--<option [ngValue]="5">User List</option>-->
                    <option [ngValue]="6">Usage Summary</option>
                    <option [ngValue]="7">Organisation Sites</option>
                    <option [ngValue]="8">Customer Interaction List</option>
                    <option [ngValue]="9">Age Verifications</option>
                    <option [ngValue]="10">Crime Alerts</option>
                    <option [ngValue]="11">Fire Checks</option>
                    <option [ngValue]="12">Toilet Checks</option>
                    <option [ngValue]="13">Cleaning Checks</option>
                    <option [ngValue]="14">Machine Payouts</option>
                    <option [ngValue]="15">Machine Service Vists</option>
                    <option [ngValue]="16">Statutory Visits</option>
                </select>
            </div>     
        </div>
    </div>
    <!--[(sharedObject)]="sharedObject"-->
    <app-main-view *ngIf="report > 1" [(report)]="report"></app-main-view>
    <app-photo-gallery *ngIf="report === 1"></app-photo-gallery>
</div>
