<div class="container global-seperator .d-none .d-sm-block">
    <div class="row">
        <div class="col-md-12">
            <h4 class="form-text">Exclusion Zone</h4>
        </div>
    </div>
    <div class="row global-seperator">
        <div class="col-md-6">                
            <div class="input-group">
                <div class="input-group-prepend">
                    <span class="input-group-text">Organisation*</span>
                </div>
                <select class="form-control drop-down-purple" [(ngModel)]="organisationFilterId" (ngModelChange)="PopulateSiteDropDown();">
                    <option *ngFor="let dropDownItem of asrv.OrganisationSelect.DropDownItems" [ngValue]="dropDownItem.Key">{{dropDownItem.Value}}</option>
                </select>
            </div>                
        </div>
        
        <div class="col-md-6">
            <div class="flex-check-box" style="height:50px;">
                <div class="flex-check-prepend">EXCLUDE ELSEWHERE</div>
                <input type="checkbox" class="flex-check-box-control">            
            </div>  
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <div class="input-group">
                <div class="input-group-prepend">
                    <span class="input-group-text">Site*</span>
                </div>
                <select id="ctrlSites" class="form-control drop-down-purple" [(ngModel)]="esrv.CurrentExclusion.SiteId" (change)="GetSiteList()">
                    <option *ngFor="let dropDownItem of asrv.SiteSelect.DropDownItems" [ngValue]="dropDownItem.Key">{{dropDownItem.Value}}</option>
                </select>
            </div>
        </div>
        <div class="col-md-6">
            <div class="input-group">
                <div class="input-group-prepend">
                    <span class="input-group-text">DISTANCE</span>
                </div>
                <!-- [(ngModel)]="Exclusion.Radius"-->
                <input style="text-align: center; background-color: rgb(175, 170, 170) !important;" type="text" class="form-control" [(ngModel)]="esrv.CurrentExclusion.ClientValues.Radius" name="radius" (ngModelChange)="radius.next($event)" disabled />
                <div class="input-group-append">
                    <span class="input-group-text">KM</span>
                </div>
            </div>
        </div>
    </div>

    <app-motorway-services *ngIf="showMotorwayServices" [(motorWayCollection)]="motorWayCollection" style="margin-top:7.5px;padding-bottom:7.5px;"></app-motorway-services>
    <app-site-shopping-basket [(showSpinner)]="showSpinner" style="margin-top:7.5px;padding-bottom:7.5px;"></app-site-shopping-basket>
</div>

