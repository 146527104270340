import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CustomerInteraction } from 'src/app/models/CustomerInteraction';
import { CustomerInteractionReinstateSEReview } from 'src/app/models/CustomerInteractionReinstateSEReview';

@Component({
  selector: 'app-customer-se-review',
  templateUrl: './customer-se-review.component.html',
  styleUrls: ['./customer-se-review.component.scss']
})
export class CustomerSeReviewComponent implements OnInit {

  constructor() { }

  public review: CustomerInteractionReinstateSEReview = new CustomerInteractionReinstateSEReview();

  interaction: CustomerInteraction;
  @Input('interaction') get Interaction(): CustomerInteraction {
    return this.interaction;
  };
  @Output('interaction') interactionChange: EventEmitter<CustomerInteraction> = new EventEmitter();
  set Interaction(val: CustomerInteraction) {
    this.interaction = val;
    this.interactionChange.emit(this.interaction);
  }

  ngOnInit() {
  }

}
