import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-customer-interaction',
  templateUrl: './customer-interaction.component.html',
  styleUrls: ['./customer-interaction.component.scss']
})
export class CustomerInteractionComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    document.body.style.backgroundColor = '#ebf6f6';
    document.body.style.color = '#5e698e';
  }
}
