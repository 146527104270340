<div class="container global-seperator" *ngIf="!(fromReportBuilder)" style="margin-top:10px;">
    <div class="row">
        <div class="d-none d-md-block col-md-12" style="margin-top:10px;">
            <div class="col-xs-12 excluder-box table" [style.border]="asrv.Globals.Border.ThinSolidBlack" [style.borderRadius]="asrv.Globals.Border.StandardRadius">
                <div class="row table-row">
                    <div class="flex-no-padding col-xs-2 col-sm-1">
                        <div class="col-img col-img-radius" [style.backgroundColor]="asrv.Globals.BackgroundColor.Red"></div>
                    </div>
                    <div class="col-xs-10 col-sm-5 key-table-text">Review Overdue</div>
                    <div class="flex-no-padding col-xs-2 col-sm-1 key-table-text">
                        <div class="col-img col-img-radius" [style.backgroundColor]="asrv.Globals.BackgroundColor.Yellow"></div>
                    </div>
                    <div class="col-xs-10 col-sm-5 key-table-text">Review Due</div>
                </div>
                <div class="row table-row">
                    <div class="flex-no-padding col-xs-2 col-sm-1  key-table-text">
                        <div class="col-img col-img-radius" [style.backgroundColor]="asrv.Globals.BackgroundColor.Green"></div>
                    </div>
                    <div class="col-xs-10 col-sm-5 key-table-text">Awaiting Review</div>
                    <div class="flex-no-padding col-xs-2 col-sm-1  key-table-text">
                        <img class="col-img" src=".\assets\icons\Bacta Assets\Reinstated SE.svg">
                    </div>
                    <div class="col-xs-10 col-sm-5 key-table-text">Reinstated Self-Excluder</div>
                </div>
                <div class="row table-row">
                    <div class="flex-no-padding col-xs-2 col-sm-1  key-table-text">
                        <div class="col-img col-img-radius" [style.color]="asrv.Globals.FontColor.Red" [style.backgroundColor]="asrv.Globals.BackgroundColor.White">T</div>
                    </div>
                    <div class="col-xs-10 col-sm-5 key-table-text">Time Out</div>
                    <div class="flex-no-padding col-xs-2 col-sm-1  key-table-text">
                        <div class="col-img col-img-radius" [style.backgroundColor]="asrv.Globals.BackgroundColor.CyanBlue"></div>
                    </div>
                    <div class="col-xs-10 col-sm-5 key-table-text">Incomplete Record</div>
                </div>    
            </div>
        </div>
        <div class="d-block d-md-none col-md-12 col-lg-8">
            <div class="col-xs-12 excluder-box container global-seperator table" style="border: 1px solid black;color:black; border-radius: 0.5em;">
                <div class="row table-row">
                    <div class="flex-no-padding col-sm-1" style="width:20%;">
                        <div class="col-img col-img-radius" [style.backgroundColor]="asrv.Globals.BackgroundColor.Red"></div>
                    </div>
                    <span class="key-table-text-mobile" style="width:80%;">Review Overdue</span>
                </div>
                <div class="row table-row">
                    <div class="flex-no-padding col-sm-1" style="width:20%;">
                        <div class="col-img col-img-radius" [style.backgroundColor]="asrv.Globals.BackgroundColor.Yellow"></div>
                    </div>
                    <span class="key-table-text-mobile" style="width:80%;">Review Due</span>
                </div>
                <div class="row table-row">
                    <div class="flex-no-padding col-sm-1" style="width:20%;">
                        <div class="col-img col-img-radius" [style.backgroundColor]="asrv.Globals.BackgroundColor.Green"></div>
                    </div>
                    <span class="key-table-text-mobile" style="width:80%;">Awaiting Review</span>
                </div>
                <div class="row table-row">
                    <div class="flex-no-padding col-sm-1" style="width:20%;">
                        <img class="col-img" src=".\assets\icons\Bacta Assets\Reinstated SE.svg">
                    </div>
                    <span class="key-table-text-mobile" style="width:80%;">Reinstated Self-Excluder</span>
                </div>
                <div class="row table-row">
                    <div class="flex-no-padding col-sm-1" style="width:20%;">
                        <div class="col-img col-img-radius" [style.color]="asrv.Globals.FontColor.Red" [style.backgroundColor]="asrv.Globals.BackgroundColor.White">T</div>
                    </div>
                    <div class="key-table-text-mobile">Time Out</div>
                </div>   
                <div class="row table-row">
                    <div class="flex-no-padding col-sm-1" style="width:20%;">
                        <div class="col-img col-img-radius" [style.backgroundColor]="asrv.Globals.BackgroundColor.CyanBlue"></div>
                    </div>
                    <div class="key-table-text-mobile">Incomplete Record</div>
                </div>   
            </div>
        </div>
        <div class="row" style="margin-top:10px;">
            <div class="col-md-6">
                <div class="input-group">
                    <div class="input-group-prepend" [style.backgroundColor]="asrv.Globals.BackgroundColor.DarkPurple">
                        <span class="input-group-text" [style.backgroundColor]="asrv.Globals.BackgroundColor.DarkPurple">Sort By</span>
                    </div>
                    <select class="form-control sort-by-control drop-down-purple color-bacta-standard" name="sort" [(ngModel)]="gsrv.Sort" (change)="gsrv.SortGallery();">
                        <option *ngFor="let sortOption of sortOptions" [ngValue]="sortOption.Id">{{sortOption.Value}}</option>
                    </select>  
                </div>
            </div>
            <div class="col-md-6">
                <div class="input-group">
                    <div class="input-group-prepend" [style.backgroundColor]="asrv.Globals.BackgroundColor.DarkPurple">
                        <span class="input-group-text" [style.backgroundColor]="asrv.Globals.BackgroundColor.DarkPurple">Filter By</span>
                    </div>
                    <select class="form-control sort-by-control drop-down-purple color-bacta-standard" name="filter" [(ngModel)]="gsrv.Filter" (change)="gsrv.FilterGallery();">
                        <option *ngFor="let filterOption of filterOptions" [ngValue]="filterOption.Id">{{filterOption.Value}}</option>
                    </select>  
                </div>
            </div>
        </div>
        <div class="row">
            <div *ngIf="gsrv.IsAdmin" class="col-md-6">
                <div class="input-group">
                    <div class="input-group-prepend">
                        <span class="input-group-text">Organisation</span>
                    </div>
                    <select class="form-control sort-by-control drop-down-purple" name="Filter" [(ngModel)]="gsrv.ActiveOrganisationFilterId" (ngModelChange)="FilterOrganisations();">
                        <option [ngValue]="0">All</option>
                        <option [ngValue]="organisation.Key" *ngFor="let organisation of gsrv.OrganisationList">{{organisation.Value}}</option>
                    </select>  
                </div>
            </div>
            <div class="col-md-6">
                <div class="input-group">
                    <div class="input-group-prepend">
                        <span class="input-group-text">Site</span>
                    </div>
                    <select class="form-control sort-by-control drop-down-purple" name="Filter" [(ngModel)]="gsrv.ActiveSiteFilterId" (ngModelChange)="gsrv.GetInteractionCards();">
                        <option [ngValue]="0">All</option>
                        <option [ngValue]="site.Key" *ngFor="let site of gsrv.SiteListToShow">{{site.Value}}</option>
                    </select>  
                </div>
            </div>  
        </div>
        <div class="row" [style.marginTop]="asrv.Globals.Positioning.Small">
            <!--
                <div class="col-sm-4 exclusion-buttons btn-barred">
                    <input #btnBarred id="btnBarred" class="bacta-button" type="image" src=".\assets\icons\Bacta Assets\Barred Blue.svg" style="box-sizing: border-box;" (click)="ShowHideBarred();" />
                </div>
            -->
            <div class="col-sm-12 btn-start-self-exclusion" style="text-align: center;">
                <input id="btnStartSelfExclusion" class="bacta-button" type="image" src=".\assets\icons\Bacta Assets\Start Interaction green.svg" (click)="CreateNewCustomerInteraction();" />
            </div>
            <!--
                <div class="col-sm-4 exclusion-buttons btn-archive">
                    <input #btnArchive id="btnArchive" class="bacta-button" type="image" src=".\assets\icons\Bacta Assets\Archive Blue.svg" style="box-sizing: border-box;" (click)="ShowHideArchived();" />
                </div>
            -->
        </div>
    </div> 
</div>  
<div *ngIf="showSpinner && !(fromReportBuilder)" class="container">
    <div class="row" [style.height]="asrv.Globals.Positioning.StandardBoxHeight" [style.width]="asrv.Globals.Positioning.StandardBoxWidth">
        <app-loading [style.width]="asrv.Globals.Positioning.StandardBoxWidth"></app-loading>
    </div>
</div>
<div *ngIf="!showSpinner" class="container global-seperator" [style.margin]="asrv.Globals.Positioning.Auto" [style.padding]="asrv.Globals.Positioning.None">
    <app-interaction-cards></app-interaction-cards>
</div>