import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})

export class ISDDateMethods {
    private millisecond: number = 1000;
    private second: number = 60;
    private minute: number = 60;
    private hour: number = 24;
    private day: number = 365.25;
    private checkDay: number = (this.millisecond * this.second * this.minute * this.hour);
    private checkYear: number = (this.checkDay * this.day);

    public AddDays(date: Date, daysToAdd: number): Date {
        let addition = daysToAdd * this.checkDay;
        let dateInteger = this.ConvertDateToInteger(date);

        return new Date(dateInteger + addition);
    }

    public AddWeeks(date: Date, weeksToAdd: number): Date {
        let addition = weeksToAdd * (this.checkDay*7);
        let dateInteger = this.ConvertDateToInteger(date);

        return new Date(dateInteger + addition);
    }
    
    public CheckBirthdateIsValid(birthDate: Date): Boolean {
        let dateDiff = this.DateDifferenceInYears(birthDate);
        return (dateDiff > 17.9986320109439124);
    }

    public DateDifferenceInDays(checkDate: Date): number {
        let dateInNumbers = this.ConvertDateToInteger(checkDate);
        return ~~((Date.now() - dateInNumbers) / (this.checkDay));
    }

    public DateDifferenceInYears(checkDate: Date): number {
        let dateInNumbers = this.ConvertDateToInteger(checkDate);
        return ~~((Date.now() - dateInNumbers) / (this.checkYear));
    }

    public SameDay(dateOne: Date, dateTwo: Date) {
        return dateOne.getFullYear() === dateTwo.getFullYear() &&
        dateOne.getMonth() === dateTwo.getMonth() &&
        dateOne.getDate() === dateTwo.getDate();
    }

    public SetEndOfDayForDate(startDate: Date) {
        let dateInteger = this.ConvertDateToInteger(startDate);
        dateInteger+= this.checkDay-1;
        let returnDate: Date = new Date(dateInteger);
        return returnDate;
    }

    public ConvertDateToInteger(dateToConvert): number {
        return +new Date(dateToConvert);
    }

    public DateNow() {
        return new Date().toISOString().slice(0, 16);
    }

    public GetNewStartDate(endDate: Date): Date {
        let endDateInteger = this.ConvertDateToInteger(endDate);
        endDateInteger+= this.checkDay;
        let returnDate: Date = new Date(endDateInteger);
        return returnDate;
    }

    public FormatDateForReport(date: Date): string {
        return date.toISOString().slice(8,10) + '/' + date.toISOString().slice(5,7) + '/' + date.getFullYear();
    }

    public AddTimeToDate(date: Date, millisecond: number) {
        return new Date(this.ConvertDateToInteger(date) + millisecond);
    }

    public GetMonitoringPeriodUsingWeeks(numberOfWeeks): number {
        return 7*numberOfWeeks;
    }

    public GetMonitoringPeriodUsingMonths(startDate: Date, numberOfMonths: number): number {
        startDate = new Date(startDate.toDateString());
        let returnDate: Date = new Date(startDate);
        returnDate.setMonth(returnDate.getMonth() + numberOfMonths);
        let diff: number = Math.abs(returnDate.getTime() - startDate.getTime());

        // Works once every 1461 days
        if ((startDate.getDate() === 29 && startDate.getMonth() === 1) && numberOfMonths === 12) {
            diff = diff - (this.checkDay);
        }
        return Math.round(diff / this.checkDay);
    }

    public GetMonthDifference(startDate, numberOfDays): number {
        var start = new Date(startDate);
        var endDate = this.AddDays(startDate, numberOfDays);

        var months;
        months = (endDate.getFullYear() - start.getFullYear()) * 12;
        months -= start.getMonth();
        months += endDate.getMonth();
        return months <= 0 ? 0 : months;
    }

    public GetEndDate(startDate: any, month: number) {
        let date: Date = new Date(startDate);
        let returnDate: Date = new Date(date.setMonth(date.getMonth() + month));
        return returnDate;
    }

    private LeapYear(year: number): boolean {
        return (year % 4) === 0 && (year % 100) !== 0;
    }
}