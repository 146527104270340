
<div class="row">
    <div class="col-md-6">
        <div class="input-group" id="tagLookupGroup">
            <div class="input-group-prepend">
                <span class="input-group-text">Tag</span>
            </div>
            <input type="text" class="form-control" name="tagLookup" maxlength="30" [(ngModel)]="userSearchPhrase" (ngModelChange)="tagLookUpController.next($event)">
        </div>
    </div>

    <div *ngIf="!tsrv.AllowNewTags" class="col-md-6">
        <div class="input-group" id="inputTagSelect">
            <div class="input-group-prepend">
                <span class="input-group-text">Tag Lookup</span>
            </div>
            <select class="form-control drop-down-purple" id="selectTagLookup" [(ngModel)]="userSelectedTag" (change)="AddTag();" *ngIf="!loadingTagList && loadedTags">
                <option></option>
                <option *ngFor="let tag of loadedTags" [ngValue]="tag">
                    <span *ngIf="!tag.Name"></span>
                    <span *ngIf="tag.Name">{{tag.Name}}</span>
                </option>
            </select>
        </div>
    </div>

    <div *ngIf="tsrv.AllowNewTags" class="col-md-6">
        <div class="form-group" style="width: 100%; text-align:center; margin-top:10px;">
            <button #btnPushTag type="button" class="btn btn-success" (click)="AddTag();">Add Tag</button>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-sm-6 col-md-4 col-lg-3" *ngFor="let tag of tsrv.Tags">
        <span style="width:100%;">
            <app-tag [(value)]="tag.Name" [(id)]="tag.TagId" (RemoveTag)="RemoveTag($event);"></app-tag>
        </span>
    </div>
</div>