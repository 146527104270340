import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { SiteCollection } from 'src/app/models/SiteCollection';
import { ExcluderService } from 'src/app/services/excluder.service';
import { NonBiologicalName } from 'src/app/models/actor/Name';
@Component({
  selector: 'app-motorway-services',
  templateUrl: './motorway-services.component.html',
  styleUrls: ['./motorway-services.component.scss']
})
export class MotorwayServicesComponent implements OnInit {
  
  public searchPhrase: string;
  public inspectMotorway: boolean = false;
  public motorway: SiteCollection;
  public displayMotorWayCollection: SiteCollection[] = [];

  motorWayCollection: SiteCollection[] = []; 
  @Input('motorWayCollection') get MotorWayCollection(): SiteCollection[] {
    return this.motorWayCollection;
  };
  @Output('motorWayCollection') motorWayCollectionChange: EventEmitter<SiteCollection[]> = new EventEmitter();
  set MotorWayCollection(val: SiteCollection[]) {
    this.motorWayCollection = val;
    this.motorWayCollectionChange.emit(this.motorWayCollection);
  }

  constructor(
    public esrv: ExcluderService
  ) { }

  ngOnInit() {
    this.displayMotorWayCollection = this.motorWayCollection;
  }

  SearchTable() {
    this.displayMotorWayCollection = [];
    this.motorWayCollection.forEach(motorway => {
      if (motorway.Name.toLowerCase().startsWith(this.searchPhrase.toLowerCase())) {
        this.displayMotorWayCollection.push(motorway);
      }
    })
  }

  AddMotorwayToExclusion(motorway: SiteCollection) {
    motorway.Sites.forEach(site => {
      let pushSite = new NonBiologicalName();
      pushSite.ActorId = site.ActorId;
      pushSite.Name = site.Name;
      pushSite.ClientValues.LoadedFromMotorwayServices = true;
      if (!this.esrv.CurrentExclusion.ClientValues.SiteCollection.some(i => i.ActorId === pushSite.ActorId)) {
        this.esrv.CurrentExclusion.SiteIdCollection.push(pushSite.ActorId);
        this.esrv.CurrentExclusion.ClientValues.SiteCollection.push(pushSite)
      };
    });
    motorway.AddedToExclusion = true;
  }

  RemoveMotorwayFromExclusion(motorway: SiteCollection) {
    let remainingSites: NonBiologicalName[] = [];
    motorway.Sites.forEach(site => {
      remainingSites = [];

      if (!this.esrv.CurrentExclusion.ClientValues.SiteCollection.some(i => i.ActorId === site.ActorId && i.ClientValues.LoadedFromMotorwayServices)) {
        var obj = new NonBiologicalName();
        obj.ActorId = site.ActorId;
        obj.Name = site.Name;
        remainingSites.push(obj);
      }
      this.esrv.CurrentExclusion.ClientValues.SiteCollection = remainingSites;
    });
    motorway.AddedToExclusion = false;
  }

  showMotorwaySites(motorway: SiteCollection) {
    this.motorway = motorway;
    this.inspectMotorway = true;
  }

  CloseInspect() {
    this.inspectMotorway = false;
  }

}
