import { Component, OnInit, Output, Input, EventEmitter, ElementRef, ViewChild, AfterViewInit } from '@angular/core';

import { ISDDateMethods } from 'src/app/models/ISDDateMethods';
import { SiteDetail } from 'src/app/models/SiteDetail';
import { ActorService } from 'src/app/services/actor.service';
import { DataService } from 'src/app/services/data.service';
import { ExcluderService } from 'src/app/services/excluder.service';

declare var require: any;
const jsPDF = require('jspdf');

const redalert: string = '#d11722';
const yellowalert: string = '#f8b03d';
const greenalert: string = '#64b32e';
const bluealert: string = '#24ace2';

@Component({
  selector: 'app-exclusion-confirmed',
  templateUrl: './exclusion-confirmed.component.html',
  styleUrls: ['./exclusion-confirmed.component.scss', '../generic-form.component.scss']
})
export class ExclusionConfirmedComponent implements OnInit, AfterViewInit {
  @ViewChild('btnPrint') btnPrint: ElementRef;
  @ViewChild('btnPrintMobile') btnPrintMobile: ElementRef;

  private dateMethods: ISDDateMethods = new ISDDateMethods();
  public SiteName: String;
  Title: string;

  public hasBreached: boolean = false;
  public exclusionSummary: string = 'ExclusionSummary';
  public exclusionSummaryVersion: string;
  public exclusionSummaryHTML: string;
  public printEnabled: boolean;
  private _siteDetails: SiteDetail[] = [];


  formMessage: string;
  @Input('formMessage') get FormMessage(): string
  { 
    return this.formMessage;
  };
  @Output('formMessage') formMessageChange: EventEmitter<string> = new EventEmitter();
  set FormMessage(val: string) {
    this.formMessage = val;
    this.formMessageChange.emit(this.formMessage);
  }

  constructor(
    public asrv: ActorService, 
    public esrv: ExcluderService,
    public dsrv: DataService
  ) {}

  ngOnInit() {  
    // No no no no nooooo
    this.asrv.GetSiteCompositeModel(this.esrv.CurrentExclusion.SiteId).subscribe(s => {
      this.SiteName = s.Name;
    });

    let siteCollecitonComplete = false;
    let summaryComplete = false;
    this.esrv.CurrentExclusion.SiteIdCollection.forEach(id => {
      this.asrv.GetSiteDetails(id).subscribe(res => {
        this._siteDetails.push(res);
        siteCollecitonComplete = true;
        if (siteCollecitonComplete && summaryComplete) {
          this.EnablePrint();
        }
      })
    })

    let exclusionSummaryVersionSubscription = this.asrv.GetExclusionSummaryVersion(this.exclusionSummary).subscribe(res => {
      res.sort((n1, n2) => {
        if (n1 > n2) {
          return 1;
        }
        if (n1 < n2) {
          return -1;
        }
        return 0;
      })

      res.forEach(version => {
        this.exclusionSummaryVersion = version;
      });

      let exclusionSummaryHTMLSubscription = this.asrv.GetExclusionSummary(this.esrv.CurrentExclusion.InteractionId, this.exclusionSummary, this.exclusionSummaryVersion).subscribe(res => {
        this.exclusionSummaryHTML = res;
        this.exclusionSummaryHTML = this.exclusionSummaryHTML.replace('bactalink="exclusionImagePath"', 'id="imageExcluder" src="' + this.esrv.CurrentExclusion.MediaModelCollection.find(i => i.MediaClassKeyId === 4).Data + '"');
        summaryComplete = true;
        if (siteCollecitonComplete && summaryComplete) {
          this.EnablePrint();
        }
        exclusionSummaryHTMLSubscription.unsubscribe();
      }, err => {
        this.exclusionSummaryHTML = "Error Fetching Exclusion Summary, Please Contact Support."
        exclusionSummaryHTMLSubscription.unsubscribe();
      })
      
      exclusionSummaryVersionSubscription.unsubscribe();
    }, err => {
      exclusionSummaryVersionSubscription.unsubscribe();
    })
  }

  ngAfterViewInit() {
    this.DisablePrint();
  }

  GetExcluderColour() : string {   
    let diffDaysStart = this.dateMethods.DateDifferenceInDays(this.esrv.CurrentExclusion.InteractionMonitoringPeriod.Start)
    let diffDaysEnd = this.dateMethods.DateDifferenceInDays(this.dateMethods.AddDays(this.esrv.CurrentExclusion.InteractionMonitoringPeriod.Start, this.esrv.CurrentExclusion.InteractionMonitoringPeriod.Duration));
    if(this.esrv.CurrentExclusion.ClientValues.ReinstatementExists) {
      return bluealert;
    } else if(diffDaysStart <= 5)
      return redalert;
    else if(diffDaysEnd  >= 0)
      return greenalert;
    else return yellowalert;
  }

  GetEndDate(): Date {
    return this.esrv.GetCurrentExcluderEndDate();
  }

  PrintExclusionTsAndCs() {
    var doc = new jsPDF('1', 'px');

    var specialElementHandlers = {
      '#skipthis': function(element, renderer) {
        return true;
      }
    }

    document.getElementById('jspdfInsert').innerHTML = this.exclusionSummaryHTML;
    var element: any = document.getElementById('imageExcluder');
    var imgHeight = element.clientHeight;
    var imgWidth = element.clientWidth;

    document.getElementById('jspdfInsert').innerHTML = '';

    while (imgHeight > 400) {
      imgHeight = imgHeight / 2;
      imgWidth = imgWidth / 2;
    }

    while (imgWidth > 300) {
      imgHeight = imgHeight / 2;
      imgWidth = imgWidth / 2;
    } 

    doc.fromHTML('<h1>Exclusion Summary</h1>', 15, 15, {
      width: 400,
      'elementHandlers': specialElementHandlers
    });
    doc.addImage(this.esrv.CurrentExclusion.MediaModelCollection.find(i => i.MediaClassKeyId === 4).Data, 'PNG', 15, 55, imgWidth, imgHeight);

    let pdfHTML = '<p>' + this.esrv.CurrentExclusion.InteractedActorCompositeModelCollection[0].BiologicalActorCompositeModel.Forename + ' ' + this.esrv.CurrentExclusion.InteractedActorCompositeModelCollection[0].BiologicalActorCompositeModel.Surname + '<br>' +
                  'Ref No: ' + this.esrv.CurrentExclusion.Reference + '<br>' +
                  this.esrv.CurrentExclusion.InteractedActorCompositeModelCollection[0].BiologicalActorCompositeModel.Line1 + ', ' + (this.esrv.CurrentExclusion.InteractedActorCompositeModelCollection[0].BiologicalActorCompositeModel.Line2? this.esrv.CurrentExclusion.InteractedActorCompositeModelCollection[0].BiologicalActorCompositeModel.Line2 + ', ': '') + this.esrv.CurrentExclusion.InteractedActorCompositeModelCollection[0].BiologicalActorCompositeModel.TownCity + ', ' +
                  this.esrv.CurrentExclusion.InteractedActorCompositeModelCollection[0].BiologicalActorCompositeModel.Postcode + ', ' + this.esrv.CurrentExclusion.InteractedActorCompositeModelCollection[0].BiologicalActorCompositeModel.Country + '</p>' +
                  '<p>Exclusion Confirmation <br>' + 'Duration: ' + this.esrv.CurrentExclusion.InteractionMonitoringPeriod.Start.toString().slice(0,10) + ' - ' + this.GetEndDate().toISOString().slice(0,10) + '<br>' +
                  'Exclusion Radius: ' + 1 + ' Kilometers (km)' + '<br>';
    // pdfHTML = pdfHTML+this.esrv.CurrentExclusionMobile? ('Tel No: ' + this.esrv.CurrentExclusionMobile + '<br>'): '';
    // pdfHTML = pdfHTML+this.esrv.CurrentExclusionEmail? ('Email: ' + this.esrv.CurrentExclusionEmail + '<br>'): '' + '</p>';

    pdfHTML = pdfHTML + '<p>You have self-excluded from ' + this._siteDetails.find(i => i.ActorId === this.esrv.CurrentExclusion.SiteId).Name + ' and all other AGCs listed below within ' + this.esrv.CurrentExclusion.ClientValues.Radius + 'Kilometers (km) radius for a period of ' + (this.dateMethods.GetMonthDifference(this.esrv.CurrentExclusion.InteractionMonitoringPeriod.Start, this.esrv.CurrentExclusion.InteractionMonitoringPeriod.Duration)) + ' months. Below is a list of sites which you are excluded from:</p>';
    pdfHTML = pdfHTML + '<table style="font-size:10px"><tr><th>Excluded Sites</th><th>Address</th></tr>'

    // Site Loop
    this._siteDetails.forEach(site => {
      pdfHTML = pdfHTML + '<tr><td>' + site.Name + '</td><td>' + (site.Line1? (site.Line1 + ', '): ('')) + (site.TownCity? (site.TownCity + ', '): ('')) + (site.Postcode? (site.Postcode): ('')) + (', ' + site.Country? (', ' + site.County): ('')) + '</td></tr>';
    })
    pdfHTML = pdfHTML + '</table>';
    
    pdfHTML = pdfHTML + '<p>You have already acknowledged the terms and conditions and been made aware of the privacy policy in relation to your self-exclusion. Below is a copy of the full Terms and Conditions and full Privacy Policy.</p>'
    pdfHTML = pdfHTML + '<p>During the period of self- exclusion, you are not permitted to enter any premises you are excluded from to gamble. This is known as a breach and you will be challenged by staff and requested to leave the premises.</p>'
    pdfHTML = pdfHTML + '<p>At the end of your self- exclusion period you cannot return to gambling within the next 6 month period unless you actively return to gambling. This process is known as reinstating. In normal circumstances you can reinstate at the premises you self- excluded from but you can reinstate at any premises on the list of excluded premises.</p>'
    pdfHTML = pdfHTML + '<h2>Safer Gambling Information</h2>';
    pdfHTML = pdfHTML + '<p>During the self-exclusion process, you will have discussed problem gambling issues and been signposted to help and support. Below is a link to the Gamcare website. You may also have been signposted to a local Gamcare provider in your area. The National Gambling Helpline is open 24hours a day and Gamcare provides telephone and online support. </p>'
    pdfHTML = pdfHTML + '<p>Gamcare Freephone 08088020133</p>';
    pdfHTML = pdfHTML + '<p><a>https://www.gamcare.org.uk/?gclid=EAIaIQobChMIvMfqg7S45wIVjbTtCh1hxACjEAAYASAAEgLaJPD_BwE</a></p>'
    doc.fromHTML(pdfHTML, 15, imgHeight+55, {
      width: 400,
      'elementHandlers': specialElementHandlers
    });

    if (/Android|webOS|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      var pdf = doc.output('datauristring');
      var pdfWindow = window.open();
      pdfWindow.document.open();
      pdfWindow.document.location=pdf;  
    } else {
      doc.save(new Date().toISOString() + '_' + this.esrv.CurrentExclusion.Reference + '.pdf');
    }
  }

  DisablePrint() {
    this.printEnabled = false;
    this.UpdateButtonProperties();
  }

  EnablePrint() {
    this.printEnabled = true;
    this.UpdateButtonProperties();
  }

  UpdateButtonProperties() {
    this.btnPrint.nativeElement.disabled = !this.printEnabled;
    this.btnPrintMobile.nativeElement.disabled = !this.printEnabled;
    this.btnPrint.nativeElement.style.filter = (this.printEnabled?'Grayscale(0)':'Grayscale(0.75)');
    this.btnPrintMobile.nativeElement.style.filter = (this.printEnabled?'Grayscale(0)':'Grayscale(0.75)');
  }
}
