
<div class="container">
    <div class="row" style="padding-top:25px; padding-bottom:25px;">
        <div class="col-md-12">
            <h3><b>Interaction Type</b></h3>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <div class="flex-check-box">
                <div class="flex-check-prepend">STAFF OBSERVATION</div>
                <input id="chkSelfExclude" type="checkbox" class="flex-check-box-control" [(ngModel)]="staffObservationTrue" (click)="ChangeObservation(true)">
                <div class="interaction-group">
                    <div class="hidden-input interaction-group"></div>
                </div>
            </div>           
        </div>
        <div class="col-md-6 tool-tip-flex d-none d-md-block">
            <span #ttpDesktop class="tool-tip-text">
                <h5 class="info-header">Information:</h5>
                <ul>
                    <li *ngFor="let item of listItems; let j = index">
                        {{item}}
                    </li>
                </ul>
            </span>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <div class="flex-check-box">
                <div class="flex-check-prepend">CUSTOMER-LED</div>
                <input id="chkCustomerLed" type="checkbox" class="flex-check-box-control" [(ngModel)]="staffObservationFalse" (click)="ChangeObservation(false)">
                <div class="interaction-group">
                    <div class="hidden-input interaction-group"></div>
                </div>
            </div>           
        </div>
    </div>

    <div class="row" style="padding-top:25px; padding-bottom:25px;">
        <div class="col-md-12">
            <h3 style="font-weight: 500;"><b>Reasons for interaction</b></h3> 
        </div>
    </div>

    <div class="row">
        <div class="col-md-6">
            <div class="flex-check-box">
                <div class="flex-check-prepend">TIME</div>
                <input id="chkTime" type="checkbox" class="flex-check-box-control" (click)="CheckboxClick($event, reasonTime)" >
                <div class="interaction-group">
                    <div class="info-input interaction-group" (click)="ToggleToolTip(1)">i</div>
                </div>
            </div>           
        </div>
        <div class="col-md-6">
            <div class="flex-check-box">
                <div class="flex-check-prepend">VULNERABILITY</div>
                <input id="chkVulnerability" type="checkbox" class="flex-check-box-control" (click)="CheckboxClick($event, ReasonVulnerability)" >
                <div class="interaction-group">
                    <div class="info-input interaction-group" (click)="ToggleToolTip(2)">i</div>
                </div>
            </div>           
        </div>
    </div>

    <div class="row">
        <div class="col-md-6">
            <div class="flex-check-box">
                <div class="flex-check-prepend">SPEND</div>
                <input id="chkSpend" type="checkbox" class="flex-check-box-control" (click)="CheckboxClick($event, reasonSpend)" >
                <div class="interaction-group">
                    <div class="info-input interaction-group" (click)="ToggleToolTip(3)">i</div>
                </div>
            </div>           
        </div>
        <div class="col-md-6">
            <div class="flex-check-box">
                <div class="flex-check-prepend">BEHAVIOUR</div>
                <input id="chkBehaviour" type="checkbox" class="flex-check-box-control" (click)="CheckboxClick($event, ReasonBehaviour)" >
                <div class="interaction-group">
                    <div class="info-input interaction-group" (click)="ToggleToolTip(5)">i</div>
                </div>
            </div>           
        </div>
    </div>

    <div class="row">
        <div class="col-md-6">
            <div class="flex-check-box">
                <div class="flex-check-prepend">MACHINE ALERT</div>
                <input id="chkMachineAlert" type="checkbox" class="flex-check-box-control" (click)="CheckboxClick($event, ReasonMachineAlert)" >
                <div class="interaction-group">
                    <div class="info-input interaction-group" (click)="ToggleToolTip(6)">i</div>
                </div>
            </div>           
        </div>
        <div class="col-md-6">
            <div class="flex-check-box">
                <div class="flex-check-prepend">WISH TO SELF-EXCLUDE</div>
                <input id="chkSelfExclude" type="checkbox" class="flex-check-box-control" (click)="CheckboxClick($event, ReasonWishToExclude)" >
                <div class="interaction-group">
                    <div class="info-input interaction-group" (click)="ToggleToolTip(4)">i</div>
                </div>
            </div>           
        </div>
    </div>

    <div class="col-md-12 tool-tip-flex d-block d-md-none" style="height: 200px;">
        <span #ttpMobile class="tool-tip-text">
            <h5 class="info-header">Information:</h5>
            <ul>
                <li *ngFor="let item of listItems; let j = index">
                    {{item}}
                </li>
            </ul>
        </span>
    </div>

    <div class="row" style="min-height:200px; padding-top: 20px;padding-bottom:20px;">
        <div class="col-md-12">
            <div style="background-color: #223464; padding: 10px; padding-top: 0px !important; border: 2px solid #223464; border-radius: 0.5em;">
                <div class="row">
                    <span style="color:white;">Notes</span>
                </div>
                <div class="row">
                    <!--[(ngModel)]="interaction.Notes"-->
                    <textarea id="ctrlNotes" class="color-bacta-standard" style="width:100%; height: 150px;" [(ngModel)]="isrv.Interaction.Notes"></textarea>
                </div>
            </div>
        </div>
    </div>
</div>






<!--
    <div class="row" style="padding-top:25px; padding-bottom:25px;">
        <div class="col-md-12">
            <h3><b>Reason for interaction</b></h3>
        </div>
    </div>
    <div class="row" style="margin-top:0px; margin-bottom: 0px;">
        <div class="col-md-6">
            <div class="input-group input-group-no-border">
                <div class="input-group-prepend form-checkbox-prepend">
                    <span class="input-group-text input-group-checkbox-text" style="font-size: 25px; font-weight: 500;"><b>Staff Observation</b></span>
                </div>
                <input id="chkStaffObservation" type="checkbox" class="form-checkbox reason-check-box" [(ngModel)]="interaction.StaffObservation" >
            </div>              
        </div>
        <div class="col-md-6 tool-tip-flex d-none d-md-block">
            <span #ttpDesktop class="tool-tip-text">
                <h5 class="info-header">Information:</h5>
                <ul>
                    <li *ngFor="let item of listItems; let j = index">
                        {{item}}
                    </li>
                </ul>
            </span>
        </div>
    </div>
    <div class="row" style="margin-top:0px; margin-bottom: 0px;">
        <div class="col-md-6">
            <div class="input-group input-group-no-border">
                <div class="input-group-prepend form-checkbox-prepend">
                    <span class="input-group-text input-group-checkbox-text" style="font-size: 25px; font-weight: 500;"><b>Customer-Led</b></span>
                </div>
                <input id="chkCustomerLed" type="checkbox" class="form-checkbox reason-check-box" [(ngModel)]="interaction.CustomerLed" >
            </div>              
        </div>
    </div>
    <div class="row" style="margin-top:0px; margin-bottom: 0px;">
        <div class="col-md-6">
            <div class="input-group input-group-no-border">
                <div class="input-group-prepend form-checkbox-prepend">
                    <span class="input-group-text input-group-checkbox-text" style="font-size: 25px; font-weight: 500;"><b>Incomplete Record</b></span>
                </div>
                <div class="interaction-group" style="max-width: 20% !important;">
                    <div class="info-input" (click)="ToggleToolTip(0)">i</div>
                </div>
            </div>              
        </div>
    </div>

    <div class="row" style="padding-top:30px;">
        <div class="col-md-12">
            <span style="font-size: 22px; font-weight: 500;"><b>Reasons</b></span> 
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <div class="input-group input-group-no-border">
                <div class="input-group-prepend form-checkbox-prepend">
                    <span class="input-group-text input-group-checkbox-text" style="font-size: 20px; font-weight: 500;"><b>Time</b></span>
                </div>
                <input id="chkTime" type="checkbox" class="form-checkbox reason-check-box" [(ngModel)]="interaction.ReasonTime" >
                <div class="interaction-group">
                    <div class="info-input interaction-group" (click)="ToggleToolTip(1)">i</div>
                </div>
            </div>              
        </div>
        <div class="col-md-6">
            <div class="input-group input-group-no-border">
                <div class="input-group-prepend form-checkbox-prepend" style="flex-grow:2;">
                    <span class="input-group-text input-group-checkbox-text" style="font-size: 20px; font-weight: 500;"><b>Vulnerability</b></span>
                </div>
                <input id="chkVulnerability" type="checkbox" class="form-checkbox reason-check-box" [(ngModel)]="interaction.ReasonVulnerability" >
                <div class="interaction-group">
                    <div class="info-input interaction-group" (click)="ToggleToolTip(2)">i</div>
                </div>
            </div>              
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <div class="input-group input-group-no-border">
                <div class="input-group-prepend form-checkbox-prepend">
                    <span class="input-group-text input-group-checkbox-text" style="font-size: 20px; font-weight: 500;"><b>Spend</b></span>
                </div>
                <input id="chkSpend" type="checkbox" class="form-checkbox reason-check-box" [(ngModel)]="interaction.ReasonSpend" >
                <div class="interaction-group">
                    <div class="info-input interaction-group" (click)="ToggleToolTip(3)">i</div>
                </div>
            </div>              
        </div>

        <div class="col-md-6">
            <div class="input-group input-group-no-border">
                <div class="input-group-prepend form-checkbox-prepend">
                    <span class="input-group-text input-group-checkbox-text" style="font-size: 20px; font-weight: 500;"><b>Wish to self-exclude</b></span>
                </div>
                
                <input id="chkSelfExclude" type="checkbox" class="form-checkbox reason-check-box" [(ngModel)]="interaction.ReasonWishToSelfExclude" >
                <div class="interaction-group">
                    <div class="info-input interaction-group" (click)="ToggleToolTip(4)">i</div>
                </div>
            </div>              
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <div class="input-group input-group-no-border">
                <div class="input-group-prepend form-checkbox-prepend">
                    <span class="input-group-text input-group-checkbox-text" style="font-size: 20px; font-weight: 500;"><b>Behaviour</b></span>
                </div>
                <input id="chkBehaviour" type="checkbox" class="form-checkbox reason-check-box" [(ngModel)]="interaction.ReasonBehaviour" >
                <div class="interaction-group">
                    <div class="info-input interaction-group" (click)="ToggleToolTip(5)">i</div>
                </div>
            </div>              
        </div>
        <div class="col-md-6">
            <div class="input-group input-group-no-border">
                <div class="input-group-prepend form-checkbox-prepend">
                    <span class="input-group-text input-group-checkbox-text" style="font-size: 20px; font-weight: 500;"><b>Machine Alert</b></span>
                </div>
                <input id="chkMachineAlert" type="checkbox" class="form-checkbox reason-check-box" [(ngModel)]="interaction.ReasonMachineAlert" (click)="HideToolTip()">
                <div class="interaction-group">
                    <div class="info-input interaction-group" (click)="ToggleToolTip(6)">i</div>
                </div>
            </div>              
        </div>
        <div class="col-md-12 tool-tip-flex d-block d-md-none" style="height: 200px;">
            <span #ttpMobile class="tool-tip-text">
                <h5 class="info-header">Information:</h5>
                <ul>
                    <li *ngFor="let item of listItems; let j = index">
                        {{item}}
                    </li>
                </ul>
            </span>
        </div>
    </div>
    -->