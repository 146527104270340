<div class="container global-seperator" style="height:100%;">
    <div class="col-md-12">
        <!--Add Variable to indicate an update or an add-->
        <div class="row">
            <h4>Edit Site</h4>
        </div>
    </div>
    <div class="col-md-12">
        <!--Details-->
        <div class="panel-body">
            <div class="row">
                <h5>Details</h5>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div id="inputGroupName" class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text">Name</span>
                        </div>
                        <input type="text" class="form-control" [(ngModel)]="adminsrv.site.Name" >
                    </div>
                </div>
                <div *ngIf="!adminsrv.organisationCreateMode" class="col-md-6">
                    <div id="inputGroupOrganisation" class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text">Organisation</span> 
                        </div>
                        <select class="form-control drop-down-purple" name="organisation" [(ngModel)]="adminsrv.site.Organisation_ActorId">
                            <option></option>
                            <option *ngFor="let organisation of adminsrv.OrganisationFilter" [ngValue]="organisation.OrganisationId">{{organisation.OrganisationName}}</option>
                        </select>
                    </div>  
                </div>  
            </div>
        </div>
        <!--Address-->
        <div class="panel-body">
            <div class="row">
                <h5>Address</h5>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div id="inputGroupPostcode" class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text">Postcode</span>
                        </div>
                        <input type="text" class="form-control" [(ngModel)]="adminsrv.site.Postcode" (ngModelChange)="postCodeControl.next($event)">
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="input-group" id="inputAddressSelect">
                        <div class="input-group-prepend">
                            <span class="input-group-text">Lookup</span>
                        </div>
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="loadingAddressList"></span>
                        <span class="sr-only input-group-text" *ngIf="loadingAddressList"> Fetching Addresses...</span>
                        <input type="text" class="form-control" *ngIf="!loadingAddressList && !addresses.Items" disabled>
                        <select class="form-control drop-down-purple" id="selectAddressLookup" (change)="PopulateAddress();" [(ngModel)]="currentAddressId" *ngIf="!loadingAddressList && addresses.Items">
                            <option></option>
                            <option *ngFor="let address of addresses.Items" [ngValue]="address.Id">{{address.Text + ',' + address.Description}}</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div id="inputGroupLine1" class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text">Address 1</span>
                        </div>
                        <input type="text" class="form-control" [(ngModel)]="adminsrv.site.Line1">
                    </div>  
                </div>
                <div class="col-md-6">
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text">Address 2</span>
                        </div>
                        <input type="text" class="form-control" [(ngModel)]="adminsrv.site.Line2">
                    </div>
                </div>  
            </div>  
            <div class="row">
                <div class="col-md-6">       
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text">Town/City</span>
                        </div>
                        <input type="text" class="form-control" [(ngModel)]="adminsrv.site.TownCity">
                    </div>
                </div> 
                <div class="col-md-6"> 
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text">County</span>
                        </div>
                        <input type="text" class="form-control" [(ngModel)]="adminsrv.site.County">
                    </div>
                </div>  
            </div>
        </div>  
        <div class="panel-body  global-seperator">
            <div class="row">
                <h5>Employees</h5>
            </div>  
            <div class="row" style="min-height:200px;">
                <div class="col-md-12">
                    <div class="bacta-table-outline">
                        <div class="row" style="height:40px;">
                            <span class="col-sm-6 bacta-table-header-text">Name</span>
                            <span class="col-sm-6 bacta-table-header-text" style="text-align: center; padding-left:0px !important;padding-right: 10px !important;">Receive Emails</span>
                        </div>
                        <div class="bacta-table-row-format">
                            <div class="row" *ngFor="let row of adminsrv.site.EmployeeCollection" style="margin-top:5px;">
                                <div *ngIf="!row.Name" class="spinner-border" role="status">
                                    <span class="sr-only"></span>
                                </div> 
                                <span *ngIf="row.Name" class="col-sm-6 bacta-table-row-content">
                                    {{row.Name}}
                                </span>
                                <span *ngIf="row.Name" class="col-sm-6 bacta-table-row-content" style="text-align: center; padding-right: 0px !important;">
                                    <input type="checkbox" [(ngModel)]="row.Contactable">
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="panel-body">
            <div class="row">
                <h5>Tags</h5>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="flex-check-box">
                        <div class="flex-check-prepend">MOTORWAY SERVICE</div>
                        <input id="chkTakeABreak" type="checkbox" class="flex-check-box-control" [(ngModel)]="isMotorwayService" (click)="SetMotorwayService()">
                        <div class="interaction-group">
                            <div class="hidden-input interaction-group"></div>
                        </div>
                    </div>  
                </div>
            </div>
            <div class="row">
                <!--[(dataModel)]="adminsrv.siteTags"-->
                <app-tags id="appTags" [(allowNewTags)]="allowNewTags"></app-tags>
            </div>
        </div>  
    </div>
</div>

<div *ngIf="!adminsrv.organisationCreateMode" class="form-footer">
    <div class="navigation" (click)="GoBack()">
        <span><input type="image" src=".\assets\icons\Bacta Assets\Back button blue.svg" style="width:100px" /></span>
    </div>
    <div class="navigation-warning" style="text-align: center; vertical-align:middle; height: 100%;">
        <div *ngIf="tsrv.TagsLoaded" class="col-md-12" >
            <div *ngIf="!adminsrv.creatingNewRecord" class="form-group" style="width: 100%; text-align:center; margin-top:10px;">
                <button *ngIf="!siteInactive" type="button" class="btn btn-danger" (click)="SuspendSite();">Suspend Site</button>
                <button *ngIf="siteInactive" type="button" class="btn btn-success" (click)="ReinstateSite();">Reinstate Site</button>
            </div>
        </div>
    </div>   
    <div class="navigation" (click)="GoForward()">
        <span><input type="image" src=".\assets\icons\Bacta Assets\Next button blue.svg" style="width:100px" /></span>
    </div>   
</div>