import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActorService } from 'src/app/services/actor.service';
import { CustomerList } from 'src/app/models/Reports/CustomerList';
import { BreachList } from 'src/app/models/Reports/BreachList';
import { ISDDateMethods } from 'src/app/models/ISDDateMethods';
import { ReinstatementList } from 'src/app/models/Reports/ReinstatementList';
import { UserAPIUsage } from 'src/app/models/Reports/UserAPIUsage';
import { OrganisationSitesList } from 'src/app/models/Reports/OrganisationSitesList';
import { GenericTagList } from 'src/app/models/admin/GenericTag';
import { PostObjects } from 'src/app/models/PostObjects';
import { ExcluderService } from 'src/app/services/excluder.service';
import { InteractionService } from 'src/app/services/interaction.service';
import { InteractionList } from 'src/app/models/Reports/InteractionList';
import { TagService } from 'src/app/services/tag.service';
import { ApiService } from 'src/app/services/api.service';
import { AgeVerificationList } from 'src/app/models/Reports/AgeVerificationList';
import { CrimeAlertList } from 'src/app/models/Reports/CrimeAlertList';
import { FireCheckList } from 'src/app/models/Reports/FireCheckList';
import { ToiletCheckList } from 'src/app/models/Reports/ToiletCheckList';
import { CleaningCheckList } from 'src/app/models/Reports/CleaningCheckList';
import { MachinePayoutList } from 'src/app/models/Reports/MachinePayoutList';
import { MachineServiceVisitList } from 'src/app/models/Reports/MachineServiceVisitList';
import { StatutoryVisitList } from 'src/app/models/Reports/StatutoryVisitList';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';

const nothing = 0;
const photoGalleryReport = 1;
const customerListReport = 2;
const breachesReport = 3;
const reinstatementsReport = 4;
const userListReport = 5;
const userAPIReport = 6;
const organisationSiteReport = 7;
const interactionListReport = 8;
const ageVerificationListReport = 9;
const crimeAlertListReport = 10;
const fireCheckListReport = 11;
const toiletCheckListReport = 12;
const cleaningCheckListReport = 13;
const machinePayoutListReport = 14;
const machineServiceVisitListReport = 15;
const statutoryVisitListReport = 16;
@Component({
  selector: 'app-main-view',
  templateUrl: './main-view.component.html',
  styleUrls: ['./main-view.component.scss']
})
export class MainViewComponent implements OnInit {

  public dateRequired: boolean;
  public getDataWithoutFurtherInput: boolean;
  public additionalInfoRequired: boolean;
  public chartRequired: boolean;
  public tableRequired: boolean;
  public radioGroupRequired: boolean;
  public dataReturned: boolean;
  public tagRequired: boolean;
  public report: number;
  public sites_ActorCollection: number[] = [];
  public dateTo: Date;
  public dateFrom: Date;
  public tagInput: string;
  public returnIds: number[] = [];
  public data: any[] = [];
  public dataModel: any;
  public showData: boolean = false;
  public dataSpinner: boolean = false;
  public dateMethods: ISDDateMethods = new ISDDateMethods();
  public reportName: string;
  public reinstatementFilter: number = 0;
  public organisationIds: number[] = [];
  public allowNewTags: boolean = false;
  public tagFilters: GenericTagList = new GenericTagList(this.asrv);

  @Input('report') get Report(): number {
    return this.report;
  };
  @Output('report') reportChange: EventEmitter<number> = new EventEmitter();
  set Report(val: number) {
    this.report = val;
    this.FetchReportControlRequirements();
    this.reportChange.emit(this.report);
  }

  constructor(
    public asrv: ActorService,
    public isrv: InteractionService,
    public esrv: ExcluderService,
    public tsrv: TagService,
    private _api: ApiService
  ) {
  }

  events = [];
  addEvent(type: string, event: MatDatepickerInputEvent<Date>) {
    this.events.push(`${type}: ${event.value}`);
  }

  ngOnInit() {
    this.tsrv.Init(false, []);
    this.FetchReportControlRequirements();
  }

  FetchData() {
    this.dataSpinner = true;
    this.showData = false;
    let sendObject = new PostObjects();

    if (this.tsrv.Tags.length > 0) {
      sendObject.Model.SiteParameters.TagIdLookupOperator = 'Exists';
      sendObject.Model.SiteParameters.TagIdCollection = this.tsrv.Tags.map(function (el) { return el.TagId; });
    } else {
      sendObject.Model.SiteParameters.TagIdLookupOperator = 'NotExists';
    }

    if (this.dateRequired) {
      sendObject.Model.Start = this.dateFrom;
      sendObject.Model.End = this.dateMethods.SetEndOfDayForDate(this.dateTo);
    }

    switch (this.report) {
      case nothing:
        break;
      case photoGalleryReport:
        this.reportName = 'Excluder Images';
        break;
      case customerListReport:
        this.reportName = 'Excluded Customer List';
        this.PrepareCustomerListReport(sendObject.Model);
        break;
      case breachesReport:
        // this.tsrv.SetSearchMode(1);
        this.reportName = 'Breach List';
        this.PrepareBreachListReport(sendObject.Model);
        break;
      case reinstatementsReport:
        // this.tsrv.SetSearchMode(1);
        this.reportName = "Reinstatement List";
        this.PrepareReinstatementListReport(sendObject.Model);
        break;
      case userListReport:
        // this.tsrv.SetSearchMode(1);
        this.reportName = "User List";
        break;
      case userAPIReport:
        // this.tsrv.SetSearchMode(1);
        this.reportName = "Usage Summary";
        this.PrepareUserAPIUsageReport(sendObject.Model);
        break;
      case organisationSiteReport:
        // this.tsrv.SetSearchMode(1);
        this.reportName = "Organisation Sites";
        this.PrepareOrganisationSitesReport(sendObject.Model.SiteParameters.TagIdCollection);
        break;
      case interactionListReport:
        this.reportName = "Customer Interaction List";
        this.PrepareInteractionListReport(sendObject.Model);
        break;
      case ageVerificationListReport:
        this.reportName = "Age Verification List";
        this.PrepareAgeVerificationListReport(sendObject.Model);
        break;
      case crimeAlertListReport:
        this.reportName = "Crime Alert List";
        this.PrepareCrimeAlertListReport(sendObject.Model);
        break;
      case fireCheckListReport:
        this.reportName = "Fire Checks List";
        this.PrepareFireCheckListReport(sendObject.Model);
        break;
      case toiletCheckListReport:
        this.reportName = "Toilet Checks List";
        this.PrepareToiletCheckListReport(sendObject.Model);
        break;
      case cleaningCheckListReport:
        this.reportName = "Cleaning Checks List";
        this.PrepareCleaningCheckListReport(sendObject.Model);
        break;
      case machinePayoutListReport:
        this.reportName = "Machine Payout List";
        this.PrepareMachinePayoutListReport(sendObject.Model);
        break;
      case machineServiceVisitListReport:
        this.reportName = "Machine Service Visit List";
        this.PrepareMachineServiceVisitListReport(sendObject.Model);
        break;
      case statutoryVisitListReport:
        this.reportName = "Statutory Visit List";
        this.PrepareStatutoryVisitListReport(sendObject.Model);
        break;
    }
  }

  PrepareCustomerListReport(sendObject: any) {
    console.log(sendObject);
    this.asrv.GetExclusionIds(sendObject).subscribe(res => {
      this.dataModel = new CustomerList(this.asrv, this.esrv, res, this._api);
      this.dataModel.SubscriptionsOpened = res.length;
      this.dataReturned = true;
      this.showData = true;
      this.dataSpinner = false;
    });
  }

  PrepareBreachListReport(sendObject: any) {
    sendObject.OrganisationIdCollection = [];
    sendObject.SiteIdCollection = [];
    this.asrv.GetBreachIds(sendObject).subscribe(res => {
      this.dataModel = new BreachList(this.asrv, this.esrv, res, this._api);
      this.dataReturned = true;
      this.showData = true;
      this.dataSpinner = false;
    });
  }

  PrepareReinstatementListReport(sendObject: any) {
    sendObject.OrganisationIdCollection = [];
    sendObject.SiteIdCollection = [];
    this.asrv.GetReinstatementIds(sendObject).subscribe(res => {
      this.dataModel = new ReinstatementList(this.asrv, this.esrv, res, this._api);
      this.dataReturned = true;
      // this.dataModel.ShowUserAccepted(); Removed due to breaking reinstatement reports
      this.showData = true;
      this.dataSpinner = false;
    });
  }

  PrepareUserListReport(sendObject: any) {
    // No Endpoint yet
  }

  PrepareUserAPIUsageReport(sendObject: any) {
    this.dataModel = new UserAPIUsage(this.asrv);
    sendObject.OrganisationIdCollection = [];
    sendObject.SiteIdCollection = [];
    this.asrv.GetUserExclusionSummary(sendObject).subscribe(res => {
      this.dataModel.SubscriptionsOpened = res.length;
      this.dataReturned = true;
      res.forEach(element => {
        this.dataModel.AddRow(element)
      });

      console.warn(this.dataModel);

      this.dataModel.Rows.sort((a, b) => {
        if (a.ActorName === b.ActorName) {
          if (a.ActorId < b.ActorId) {
            return 1;
          } else {
            return -1;
          }
        }

        if (new Date(a.ActorName) < new Date(b.ActorName)) {
          return 1;
        }

        if (new Date(a.ActorName) > new Date(b.ActorName)) {
          return -1;
        }

        return 0;
      });

      this.showData = true;
      this.dataSpinner = false;
    })
  }

  PrepareOrganisationSitesReport(tagIds: number[]) {
    this.asrv.GetSiteIds(0, 0, null, tagIds).then(res => {
      this.dataModel = new OrganisationSitesList(this.asrv, res);
      this.dataModel.SubscriptionsOpened = 0;
      this.dataReturned = true;
      this.showData = true;
      this.dataSpinner = false;
    });
  }

  PrepareInteractionListReport(sendObject: any) {
    this.asrv.GetInteractionIds(sendObject).subscribe(res => {
      this.dataModel = new InteractionList(this.asrv, this.isrv, res, this._api);
      this.dataModel.SubscriptionsOpened = res.length;
      this.dataReturned = true;
      this.showData = true;
      this.dataSpinner = false;
    });
  }

  PrepareAgeVerificationListReport(sendObject: any) {
    this.asrv.GetAgeVerificationIds(sendObject).subscribe(res => {
      this.dataModel = new AgeVerificationList(this.asrv, this.isrv, res, this._api);
      this.dataModel.SubscriptionsOpened = res.length;
      this.dataReturned = true;
      this.showData = true;
      this.dataSpinner = false;
    });
  }

  PrepareCrimeAlertListReport(sendObject: any) {
    this.asrv.GetCrimeAlertIds(sendObject).subscribe(res => {
      this.dataModel = new CrimeAlertList(this.asrv, this.isrv, res, this._api);
      this.dataModel.SubscriptionsOpened = res.length;
      this.dataReturned = true;
      this.showData = true;
      this.dataSpinner = false;
    });
  }

  PrepareFireCheckListReport(sendObject: any) {
    this.asrv.GetFireCheckIds(sendObject).subscribe(res => {
      this.dataModel = new FireCheckList(this.asrv, this.isrv, res, this._api);
      this.dataModel.SubscriptionsOpened = res.length;
      this.dataReturned = true;
      this.showData = true;
      this.dataSpinner = false;
    });
  }

  PrepareToiletCheckListReport(sendObject: any) {
    this.asrv.GetToiletCheckIds(sendObject).subscribe(res => {
      this.dataModel = new ToiletCheckList(this.asrv, this.isrv, res, this._api);
      this.dataModel.SubscriptionsOpened = res.length;
      this.dataReturned = true;
      this.showData = true;
      this.dataSpinner = false;
    });
  }

  PrepareCleaningCheckListReport(sendObject: any) {
    this.asrv.GetCleaningCheckIds(sendObject).subscribe(res => {
      this.dataModel = new CleaningCheckList(this.asrv, this.isrv, res, this._api);
      this.dataModel.SubscriptionsOpened = res.length;
      this.dataReturned = true;
      this.showData = true;
      this.dataSpinner = false;
    });
  }

  PrepareMachinePayoutListReport(sendObject: any) {
    this.asrv.GetMachinePayoutIds(sendObject).subscribe(res => {
      this.dataModel = new MachinePayoutList(this.asrv, this.isrv, res, this._api);
      this.dataModel.SubscriptionsOpened = res.length;
      this.dataReturned = true;
      this.showData = true;
      this.dataSpinner = false;
    });
  }

  PrepareMachineServiceVisitListReport(sendObject: any) {
    this.asrv.GetMachineServiceVisitIds(sendObject).subscribe(res => {
      this.dataModel = new MachineServiceVisitList(this.asrv, this.isrv, res, this._api);
      this.dataModel.SubscriptionsOpened = res.length;
      this.dataReturned = true;
      this.showData = true;
      this.dataSpinner = false;
    });
  }

  PrepareStatutoryVisitListReport(sendObject: any) {
    this.asrv.GetStatutoryVisitIds(sendObject).subscribe(res => {
      this.dataModel = new StatutoryVisitList(this.asrv, this.isrv, res, this._api);
      this.dataModel.SubscriptionsOpened = res.length;
      this.dataReturned = true;
      this.showData = true;
      this.dataSpinner = false;
    });
  }



  FetchReportControlRequirements() {
    this.showData = false;
    this.tagFilters.Rows = [];
    switch (this.report) {
      case nothing:
        // Set all parameters to false
        this.getDataWithoutFurtherInput = false;
        this.dateRequired = false;
        this.tagRequired = false;
        this.additionalInfoRequired = false;
        this.chartRequired = false;
        this.tableRequired = false;
        break;
      case photoGalleryReport:
        this.getDataWithoutFurtherInput = true;
        this.dateRequired = false;
        this.tagRequired = false;
        this.additionalInfoRequired = false;
        this.chartRequired = false;
        this.tableRequired = false;
        this.radioGroupRequired = true;
        break;
      case customerListReport:
        this.getDataWithoutFurtherInput = false;
        this.dateRequired = true;
        this.tagRequired = true;
        this.additionalInfoRequired = false;
        this.chartRequired = false;
        this.tableRequired = true;
        this.radioGroupRequired = true;
        break;
      case breachesReport:
        this.getDataWithoutFurtherInput = false;
        this.dateRequired = true;
        this.tagRequired = true;
        this.additionalInfoRequired = false;
        this.chartRequired = false;
        this.tableRequired = true;
        break;
      case reinstatementsReport:
        this.getDataWithoutFurtherInput = false;
        this.dateRequired = true;
        this.tagRequired = true;
        this.additionalInfoRequired = false;
        this.chartRequired = false;
        this.tableRequired = true;
        break;
      case userListReport:
        this.getDataWithoutFurtherInput = true;
        this.dateRequired = false;
        this.tagRequired = true;
        this.additionalInfoRequired = false;
        this.chartRequired = false;
        this.tableRequired = true;
        break;
      case userAPIReport:
        this.getDataWithoutFurtherInput = false;
        this.dateRequired = true;
        this.tagRequired = true;
        this.additionalInfoRequired = false;
        this.chartRequired = false;
        this.tableRequired = true;
        break;
      case organisationSiteReport:
        this.getDataWithoutFurtherInput = true;
        this.dateRequired = false;
        this.tagRequired = true;
        this.additionalInfoRequired = false;
        this.chartRequired = false;
        this.tableRequired = true;
        break;
      case interactionListReport:
        this.getDataWithoutFurtherInput = true;
        this.dateRequired = true;
        this.tagRequired = true;
        this.additionalInfoRequired = false;
        this.chartRequired = false;
        this.tableRequired = true;
        break;
      case ageVerificationListReport:
        this.getDataWithoutFurtherInput = true;
        this.dateRequired = true;
        this.tagRequired = true;
        this.additionalInfoRequired = false;
        this.chartRequired = false;
        this.tableRequired = true;
        break;
      case crimeAlertListReport:
        this.getDataWithoutFurtherInput = true;
        this.dateRequired = true;
        this.tagRequired = true;
        this.additionalInfoRequired = false;
        this.chartRequired = false;
        this.tableRequired = true;
        break;
      case fireCheckListReport:
        this.getDataWithoutFurtherInput = true;
        this.dateRequired = true;
        this.tagRequired = true;
        this.additionalInfoRequired = false;
        this.chartRequired = false;
        this.tableRequired = true;
        break;
      case toiletCheckListReport:
        this.getDataWithoutFurtherInput = true;
        this.dateRequired = true;
        this.tagRequired = true;
        this.additionalInfoRequired = false;
        this.chartRequired = false;
        this.tableRequired = true;
        break;
      case cleaningCheckListReport:
        this.getDataWithoutFurtherInput = true;
        this.dateRequired = true;
        this.tagRequired = true;
        this.additionalInfoRequired = false;
        this.chartRequired = false;
        this.tableRequired = true;
        break;
      case machinePayoutListReport:
        this.getDataWithoutFurtherInput = true;
        this.dateRequired = true;
        this.tagRequired = true;
        this.additionalInfoRequired = false;
        this.chartRequired = false;
        this.tableRequired = true;
        break;
      case machineServiceVisitListReport:
        this.getDataWithoutFurtherInput = true;
        this.dateRequired = true;
        this.tagRequired = true;
        this.additionalInfoRequired = false;
        this.chartRequired = false;
        this.tableRequired = true;
        break;
      case statutoryVisitListReport:
        this.getDataWithoutFurtherInput = true;
        this.dateRequired = true;
        this.tagRequired = true;
        this.additionalInfoRequired = false;
        this.chartRequired = false;
        this.tableRequired = true;
        break;
    }
  }
}
