<div class="container global-seperator" style="overflow-y:auto;overflow-x:hidden;">
    <div class="container global-seperator" style="padding: 20px 0 !important;">
        <div class="row">
            <div class="col-md-12">
                <h3><b>Age Verify</b></h3>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-6">
            <div id="ctrlAgeDate" class="input-group">
                <div class="input-group-prepend">
                    <span class="input-group-text">Date</span>
                </div>
                <input class="form-control d-none d-lg-block" matInput [matDatepicker]="cddp1"
                    (dateInput)="addEvent('input', $event)" (dateChange)="addEvent('change', $event)"
                    [(ngModel)]="ageDateModel">
                <div class="input-group-append time-selector d-none d-lg-block">
                    <mat-datepicker-toggle class="form-control-date-toggle d-none d-lg-block" matSuffix [for]="cddp1">
                    </mat-datepicker-toggle>
                </div>
                <mat-datepicker class="d-none d-lg-block" touchUi #cddp1></mat-datepicker>
                <input type="date" class="form-control date-picker-padding d-lg-none" name="ageDate"
                    [(ngModel)]="ageDateModel" (ngModelChange)="addEvent('change', $event)">
            </div>
        </div>
        <div class="col-md-6">
            <div id="ctrlAgeTime" class="input-group">
                <div class="input-group-prepend">
                    <span class="input-group-text">Time</span>
                </div>
                <input type="time" class="form-control d-none d-lg-block" name="ageTime" [(ngModel)]="ageTime"
                    (ngModelChange)="setTime($event)">
                <input type="time" class="form-control date-picker-padding d-lg-none" name="ageTime"
                    [(ngModel)]="ageTime" (ngModelChange)="setTime($event)">
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-6">
            <div class="input-group" id="inputUser">
                <div class="input-group-prepend">
                    <span class="input-group-text">Challenge made by</span>
                </div>
                <div *ngIf="employeeListLoading" class="inline-spinner">
                    <div class="spinner-border" style="width:40px; height: 40px;" role="status">
                        <span class="sr-only"></span>
                    </div>
                </div>

                <select *ngIf="!employeeListLoading && !usingLoggedInName" class="form-control drop-down-purple"
                    name="user" [(ngModel)]="employeeId">
                    <option></option>
                    <option *ngFor="let dropDownItem of employeeList" [ngValue]="dropDownItem.ActorId">
                        {{dropDownItem.Forename}} {{dropDownItem.Surname}}</option>
                </select>
                <select *ngIf="!employeeListLoading && usingLoggedInName" class="form-control drop-down-purple"
                    name="user" [(ngModel)]="loggedInUserID">
                    <option [ngValue]="loggedInUserID" selected="selected"> {{loggedInName}}</option>
                </select>
            </div>
        </div>
        <div class="col-md-6">
            <div id="ctrlSites" class="input-group">
                <div class="input-group-prepend">
                    <span class="input-group-text">Details of Challenge</span>
                </div>
                <div *ngIf="challengeListLoading" class="inline-spinner">
                    <div class="spinner-border" style="width:40px; height: 40px;" role="status">
                        <span class="sr-only"></span>
                    </div>
                </div>
                <select *ngIf="!challengeListLoading" id="ctrlSites" class="form-control drop-down-purple"
                    [(ngModel)]="challengeType" (ngModelChange)="setChallengeType();">
                    <option></option>
                    <option *ngFor="let dropDownItem of detailsOfChallengeList" [ngValue]="dropDownItem['TagId']">
                        Challenged {{dropDownItem['Name']}}</option>
                </select>
            </div>
        </div>

    </div>
    <div class="row">
        <div class="col-md-6">
            <div class="flex-check-box">
                <div class="flex-check-prepend">Did customer show ID</div>
                <div class="checkbox-label">
                    <span>Yes</span>
                    <input id="chkTime" type="checkbox" class="flex-check-box-control" [(ngModel)]="checkedIDTrue"
                        (click)="showedIDCheck(true)">
                </div>
                <div class="checkbox-label">
                    <span>No</span>
                    <input id="chkTime" type="checkbox" class="flex-check-box-control" [(ngModel)]="checkedIDFalse"
                        (click)="showedIDCheck(false)">
                </div>
                <!-- <mat-radio-group aria-label="Select an option" [(ngModel)]="showedID" (ngModelChange)="IDChecked($event)">
                    <mat-radio-button value="yes">Yes</mat-radio-button>
                    <mat-radio-button value="no">No</mat-radio-button>
                </mat-radio-group> -->

                <!-- <div class="interaction-group">
                    <div class="info-input interaction-group" (click)="ToggleToolTip(1)">i</div>
                </div> -->
            </div>
        </div>
    </div>

    <ng-container *ngIf="checkedIDTrue">
        <div class="row">
            <div class="col-md-6">
                <div id="ctrlSites" class="input-group">
                    <div class="input-group-prepend">
                        <span class="input-group-text">ID Type</span>
                    </div>
                    <div *ngIf="idTypeListLoading" class="inline-spinner">
                        <div class="spinner-border" style="width:40px; height: 40px;" role="status">
                            <span class="sr-only"></span>
                        </div>
                    </div>
                    <select *ngIf="!idTypeListLoading" id="ctrlSites" class="form-control drop-down-purple"
                        [(ngModel)]="idType" (ngModelChange)="setIDType();">
                        <option></option>
                        <option *ngFor="let dropDownItem of idTypeList" [ngValue]="dropDownItem['TagId']">
                            {{dropDownItem['Name']}}</option>
                    </select>
                </div>
            </div>

            <div class="col-md-6">
                <div class="input-group" id="inputGender">
                    <div class="input-group-prepend">
                        <span class="input-group-text">Gender</span>
                    </div>
                    <div *ngIf="genderListLoading" class="inline-spinner">
                        <div class="spinner-border" style="width:40px; height: 40px;" role="status">
                            <span class="sr-only"></span>
                        </div>
                    </div>
                    <select *ngIf="!genderListLoading" class="form-control drop-down-purple" name="gender"
                        [(ngModel)]="gender" (ngModelChange)="setGender()">
                        <option></option>
                        <option *ngFor="let gender of genderList" [ngValue]="gender.GenderId">{{gender.Name}}</option>
                    </select>
                </div>
            </div>

            <div class="col-md-6">
                <div id="ctrlAgeDate" class="input-group">
                    <div class="input-group-prepend">
                        <span class="input-group-text">Date of Birth</span>
                    </div>
                    <input class="form-control d-none d-lg-block" matInput [matDatepicker]="cddp2"
                        (dateInput)="addEvent('input', $event)" (dateChange)="addEvent('change', $event)"
                        [(ngModel)]="dobDateModel">
                    <div class="input-group-append time-selector d-none d-lg-block">
                        <mat-datepicker-toggle class="form-control-date-toggle d-none d-lg-block" matSuffix
                            [for]="cddp2">
                        </mat-datepicker-toggle>
                    </div>
                    <mat-datepicker class="d-none d-lg-block" touchUi #cddp2></mat-datepicker>
                    <input type="date" class="form-control date-picker-padding d-lg-none" name="dobDate"
                        [(ngModel)]="dobDateModel">
                </div>
            </div>
        </div>


    </ng-container>

    <ng-container *ngIf="checkedIDFalse">
        <div class="row">
            <div class="col-md-6">
                <div class="flex-check-box">
                    <div class="flex-check-prepend">If NO, please confirm if customer was asked to leave</div>
                    <input id="chkNo" type="checkbox" class="flex-check-box-control" [(ngModel)]="wasAskedToLeave"
                        (ngModelChange)="askedToLeave()">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-8">
                <div class="input-group" id="inputPlayed">
                    <div class="input-group-prepend" style="width: 62%;">
                        <span class="input-group-text">Did customer play the machines?</span>
                    </div>
                    <select class="form-control drop-down-purple" name="didPlay" [(ngModel)]="transactions"
                        (ngModelChange)="setTransactions()">
                        <option></option>
                        <option *ngFor="let dropDownItem of transactionsList" [ngValue]="dropDownItem['TagId']">
                            {{dropDownItem.Value}}</option>
                    </select>
                </div>
            </div>
        </div>
    </ng-container>



    <div class="row" style="min-height:200px; padding-top: 20px;">
        <div class="col-md-12">
            <div
                style="background-color: #223464; padding: 10px; padding-top: 0px !important; border: 2px solid #223464; border-radius: 0.5em;">
                <div class="row">
                    <span style="color:white;"><b>Notes</b></span>
                </div>
                <div class="row">
                    <!--[(ngModel)]="interaction.Notes"-->
                    <textarea id="ctrlNotes" style="width:100%; height: 150px; padding-left:5px; padding-right: 5px;"
                        [(ngModel)]="notes"></textarea>
                </div>
            </div>
        </div>
    </div>

    <div class="form-footer">
        <div class="navigation" (click)="GoBack()">
            <span><input type="image" class="stage-button" src=".\assets\icons\Bacta Assets\Back button blue.svg"
                    style="width:100px" /></span>
        </div>
        <!-- 
        <div *ngIf="stage===2" class="navigation-warning" style="text-align: center; vertical-align:middle; height: 100%;">
            <input id="btnStartSelfExclusion" class="bacta-button" type="image" src=".\assets\icons\Bacta Assets\Start Self-exclusion orange.svg" alt="Settings" (click)="StartSelfExclusion();" />
        </div>   -->
        <div class="navigation save" (click)="saveAgeVerification()">
            <span><input type="image" class="stage-button" src=".\assets\icons\Bacta Assets\Save Interaction green.svg"
                    style="width:100px" /></span>
        </div>
    </div>
</div>
<div class="notification-dialog--overlay" *ngIf="isSaving">
    <div class="saving-dialog--spinner">
        <div class="dialog-spinner">
            <div class="text">
                Saving...
            </div>
            <div class="spinner-border" style="width:100px; height: 100px;" role="status">
                <span class="sr-only"></span>
            </div>
        </div>
    </div>
</div>

<div class="notification-dialog--overlay" *ngIf="isSavedSuccess">
    <div class="message-wrapper">
        <div class="message-wrapper--header">
            <div class="text">Success</div>
            <div class="close">X</div>
        </div>
        <div class="message-wrapper--body">
            Saved Age Verification Challenge
        </div>
    </div>
</div>

<div class="notification-dialog--overlay" *ngIf="isError">
    <div class="message-wrapper error">
        <div class="message-wrapper--header error">
            <div class="text">Error</div>
            <div class="close" (click)="closeNotification()">X</div>
        </div>
        <div class="message-wrapper--body">
            There was an error saving the age verification. Please check all fields and try again.
            <br>
            {{errorMsg | json}}
        </div>
    </div>
</div>