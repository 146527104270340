import { Injectable, Input } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { Reasons } from '../models/controllers/Interaction Controllers/Reasons';
import { Views_BiologicalActorTraitModel } from '../models/InteractionExclusion/Views_BiologicalActorTraitModel';
import { Views_InteractedActorCompositeModel } from '../models/InteractionExclusion/Views_InteractedActorCompositeModel';
import { Views_InteractionMonitoringPeriodsModel } from '../models/InteractionExclusion/Views_InteractionMonitoringPeriodsModel';
import { Views_InteractionsCompositeModel } from '../models/InteractionExclusion/Views_InteractionsCompositeModel';
import { Views_MediaModel } from '../models/InteractionExclusion/Views_MediaModel';
import { Views_ReviewsCompositeModel } from '../models/InteractionExclusion/Views_ReviewsCompositeModel';
import { Post_InteractionReferenceCompositeModel, View_InteractionReferenceCompositeModel } from '../models/InteractionExclusion/View_InteractionReferenceCompositeModel';
import { ISDDateMethods } from '../models/ISDDateMethods';
import { ActorService } from './actor.service';
import { ApiService } from './api.service';
import { DataService } from './data.service';
import { ErrorhanderService } from './errorhander.service';

const redalert: string = '#d11722';
const yellowalert: string = '#f8b03d';
const greenalert: string = '#64b32e';
const greyalert: string = '#9d9d9c';

@Injectable({
  providedIn: 'root'
})

export class InteractionService {
  public Interaction: Views_InteractionsCompositeModel;
  public HistoricIds: number[];
  public Review: Views_ReviewsCompositeModel;
  public ReadPrivacyNotice: boolean = false;
  public PersonCompletingForm: number;
  public Employees: string[] = [];
  public ReviewPageVisited: boolean;
  public chkGambling: boolean = false;
  public chkBehaviour: boolean = false;
  public chkPattern: boolean = false;

  public saveInteraction = new BehaviorSubject<boolean>(false);

  public reviewMediaCollection = '';

  private _previousEvent: any;
  private _currentSelectedIconId: number;
  private _interactionUIDate: Date;
  private _dateMethods: ISDDateMethods = new ISDDateMethods();
  private _databaseCalls: DatabaseCalls;

  public ViewDataLoading: boolean;
  public ActorsDataProtectionOfficer: any = false;

  private readonly stageReason: number = 0;
  private readonly stagePrivacyNotice: number = 1;
  private readonly stageAction: number = 2;
  private readonly stageCustomerDetails: number = 3;
  private readonly stageIdentification: number = 4;
  private readonly stageDescription: number = 5;
  private readonly stageReview: number = 6;
  private readonly stageConfirmation: number = 7;

  // Tags
  private readonly archived: number = 4;
  private readonly incomplete: number = 5;
  private readonly identityChecked: number = 31;
  private readonly readTermsAndConditions: number = 32;
  private readonly excludeBingo: number = 33;
  private readonly excludeCasino: number = 34;
  private readonly excludeBetShop: number = 35;
  private readonly excludeOnline: number = 36;
  private readonly planExcludeBingo: number = 37;
  private readonly planExcludeCasinoW: number = 38;
  private readonly planExcludeBetShop: number = 39;
  private readonly planExcludeOnline: number = 40;
  private readonly marketing: number = 41;
  private readonly thirdPartyContactable: number = 42;
  private readonly participated: number = 50;
  private readonly siteNotificationContact: number = 61;
  private readonly interactionCustomerLed: number = 90;
  private readonly interactionReasonTime: number = 91;
  private readonly interactionReasonSpend: number = 92;
  private readonly interactionReasonBehaviour: number = 93;
  private readonly interactionReasonMachineAlert: number = 94;
  private readonly interactionReasonVulnerability: number = 95;
  private readonly interactionReasonWishToExclude: number = 96;
  private readonly interactionActionBanned: number = 110;
  private readonly interactionActionTakeABreak: number = 111;
  private readonly interactionActionImposeTimeLimits: number = 112;
  private readonly interactionActionImposeSpendLimits: number = 113;
  private readonly interactionActionChangeCategory: number = 114;
  private readonly interactionActionMonitorBehaviour: number = 115;
  private readonly interactionActionSignpostToGamcare: number = 116;
  private readonly interactionActionStayInControlLeaflet: number = 117;
  private readonly interactionActionGambleManagementApp: number = 118;
  private readonly interactionActionIssuedPlayDiary: number = 119;
  private readonly interactionReviewGamblingToolUsed: number = 130;
  private readonly interactionReviewBehaviourChanged: number = 131;
  private readonly interactionReviewGamblingPatternChange: number = 132;

  public readonly whiteSpaceImage = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADkAAAA9CAIAAACY61bEAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAABUSURBVGhD7c5BDQAwEASh+je9VcHjkkEBb3d0NboaXY2uRlejq9HV6Gp0NboaXY2uRlejq9HV6Gp0NboaXY2uRlejq9HV6Gp0NboaXY2uRlejq7B974OYmhOKU9kAAAAASUVORK5CYII=';

  // public ReasonControllers: Reasons;

  public SystemIconImageList = [
    { id: 1, name: 'BlueApple', src: '/assets/icons/fruits-new/Blue_Apple.svg', domid: 'iconImageOne' },
    { id: 2, name: 'BlueBanana', src: '/assets/icons/fruits-new/Blue_Banana.svg', domid: 'iconImageTwo' },
    { id: 3, name: 'BlueCherry', src: '/assets/icons/fruits-new/Blue_Cherry.svg', domid: 'iconImageThree' },
    { id: 4, name: 'BlueGrape', src: '/assets/icons/fruits-new/Blue_Grape.svg', domid: 'iconImageFour' },
    { id: 5, name: 'BlueLemon', src: '/assets/icons/fruits-new/Blue_Lemon.svg', domid: 'iconImageFive' },
    { id: 6, name: 'BluePear', src: '/assets/icons/fruits-new/Blue_Pear.svg', domid: 'iconImageSix' },
    { id: 7, name: 'BluePineapple', src: '/assets/icons/fruits-new/Blue_Pineapple.svg', domid: 'iconImageSeven' },
    { id: 8, name: 'BlueStrawberry', src: '/assets/icons/fruits-new/Blue_Strawberry.svg', domid: 'iconImageEight' },
    { id: 9, name: 'GreenApple', src: '/assets/icons/fruits-new/Green_Apple.svg', domid: 'iconImageNine' },
    { id: 10, name: 'GreenBanana', src: '/assets/icons/fruits-new/Green_Banana.svg', domid: 'iconImageTen' },
    { id: 11, name: 'GreenCherry', src: '/assets/icons/fruits-new/Green_Cherry.svg', domid: 'iconImageEleven' },
    { id: 12, name: 'GreenGrape', src: '/assets/icons/fruits-new/Green_Grape.svg', domid: 'iconImageTwelve' },
    { id: 13, name: 'GreenLemon', src: '/assets/icons/fruits-new/Green_Lemon.svg', domid: 'iconImageThirteen' },
    { id: 14, name: 'GreenPear', src: '/assets/icons/fruits-new/Green_Pear.svg', domid: 'iconImageFourteen' },
    { id: 15, name: 'GreenPineapple', src: '/assets/icons/fruits-new/Green_Pineapple.svg', domid: 'iconImageFifteen' },
    { id: 16, name: 'GreenStrawberry', src: '/assets/icons/fruits-new/Green_Strawberry.svg', domid: 'iconImageSixteen' },
    { id: 17, name: 'MauveApple', src: '/assets/icons/fruits-new/Mauve_Apple.svg', domid: 'iconImageSeventeen' },
    { id: 18, name: 'MauveBanana', src: '/assets/icons/fruits-new/Mauve_Banana.svg', domid: 'iconImageEighteen' },
    { id: 19, name: 'MauveCherry', src: '/assets/icons/fruits-new/Mauve_Cherry.svg', domid: 'iconImageNineteen' },
    { id: 20, name: 'MauveGrape', src: '/assets/icons/fruits-new/Mauve_Grape.svg', domid: 'iconImageTwenty' },
    { id: 21, name: 'MauveLemon', src: '/assets/icons/fruits-new/Mauve_Lemon.svg', domid: 'iconImageTwentyOne' },
    { id: 22, name: 'MauvePear', src: '/assets/icons/fruits-new/Mauve_Pear.svg', domid: 'iconImageTwentyTwo' },
    { id: 23, name: 'MauvePineapple', src: '/assets/icons/fruits-new/Mauve_Pineapple.svg', domid: 'iconImageTwentyThree' },
    { id: 24, name: 'MauveStrawberry', src: '/assets/icons/fruits-new/Mauve_Strawberry.svg', domid: 'iconImageTwentyFour' },
    { id: 25, name: 'RedApple', src: '/assets/icons/fruits-new/Red_Apple.svg', domid: 'iconImageTwentyFive' },
    { id: 26, name: 'RedBanana', src: '/assets/icons/fruits-new/Red_Banana.svg', domid: 'iconImageTwentySix' },
    { id: 27, name: 'RedCherry', src: '/assets/icons/fruits-new/Red_Cherry.svg', domid: 'iconImageTwentySeven' },
    { id: 28, name: 'RedGrape', src: '/assets/icons/fruits-new/Red_Grape.svg', domid: 'iconImageTwentyEight' },
    { id: 29, name: 'RedLemon', src: '/assets/icons/fruits-new/Red_Lemon.svg', domid: 'iconImageTwentyNine' },
    { id: 30, name: 'RedPear', src: '/assets/icons/fruits-new/Red_Pear.svg', domid: 'iconImageThirty' },
    { id: 31, name: 'RedPineapple', src: '/assets/icons/fruits-new/Red_Pineapple.svg', domid: 'iconImageThirtyOne' },
    { id: 32, name: 'RedStrawberry', src: '/assets/icons/fruits-new/Red_Strawberry.svg', domid: 'iconImageThirtyTwo' }
  ]

  public SystemOutlineImageList = [
    { id: 80, name: 'OutlineMale', src: '/assets/icons/outlines/Male icon.svg', domid: 'outlineImageEighty', width: "100%" },
    { id: 81, name: 'OutlineFemale', src: '/assets/icons/outlines/Female icon.svg', domid: 'outlineImageEightyOne', width: "98%" }
  ]

  public AgeId: number;
  public AgeOptions = [
    { AgeId: 0, Age: '' },
    { AgeId: 1, Age: '18-21' },
    { AgeId: 2, Age: '22-25' },
    { AgeId: 3, Age: '26-30' },
    { AgeId: 4, Age: '31-35' },
    { AgeId: 5, Age: '36-40' },
    { AgeId: 6, Age: '41-49' },
    { AgeId: 7, Age: '50-59' },
    { AgeId: 8, Age: '60-69' },
    { AgeId: 9, Age: '70+' }
  ]
  public HairstyleId: number;
  public HairstyleOptions = [
    { HairstyleId: 0, Hairstyle: '' },
    { HairstyleId: 1, Hairstyle: 'Bald' },
    { HairstyleId: 2, Hairstyle: 'Short' },
    { HairstyleId: 3, Hairstyle: 'Long' }
  ]
  public EthnicityId: number;
  public EthnicityOptions = [
    { EthnicityId: 0, Ethnicity: '' },
    { EthnicityId: 1, Ethnicity: 'White' },
    { EthnicityId: 2, Ethnicity: 'Black' },
    { EthnicityId: 3, Ethnicity: 'Eastern European' },
    { EthnicityId: 4, Ethnicity: 'South Asian' },
    { EthnicityId: 5, Ethnicity: 'East Asian' },
    { EthnicityId: 6, Ethnicity: 'Mixed' }
  ]
  public HairColourId: number;
  public HairColourOptions = [
    { HairColourId: 0, HairColour: '' },
    { HairColourId: 1, HairColour: 'Blonde' },
    { HairColourId: 2, HairColour: 'Brown' },
    { HairColourId: 3, HairColour: 'Black' },
    { HairColourId: 4, HairColour: 'Ginger' },
    { HairColourId: 5, HairColour: 'None' },
    { HairColourId: 6, HairColour: 'Other' }
  ]
  public HeightId: number;
  public HeightOptions = [
    { HeightId: 0, Height: "" },
    { HeightId: 1, Height: "4'0 - 4'11" },
    { HeightId: 2, Height: "5'0 - 5'5" },
    { HeightId: 3, Height: "5'6 - 5'11" },
    { HeightId: 4, Height: "6'0 - 6'5" },
    { HeightId: 5, Height: "6'6+" }
  ]
  public BuildId: number;
  public BuildOptions = [
    { BuildId: 0, Build: '' },
    { BuildId: 1, Build: 'Slim' },
    { BuildId: 2, Build: 'Average' },
    { BuildId: 3, Build: 'Large' }
  ]

  constructor(
    private router: Router,
    public dsrv: DataService,
    public asrv: ActorService,
    public errsrv: ErrorhanderService,
    private _api: ApiService,
  ) {
    this._databaseCalls = new DatabaseCalls(this.dsrv);
  }

  StartNewInteraction() {
    // this.ReasonControllers = new Reasons(this.errsrv);
    this.Interaction = new Views_InteractionsCompositeModel();
    this.Interaction.InteractedActorCompositeModel = new Views_InteractedActorCompositeModel();
    this.Interaction.MediaModelCollection = [];
    this.Interaction.Notes = '';
    this.ReadPrivacyNotice = false;
    this.AgeId = 0;
    this.HairstyleId = 0;
    this.EthnicityId = 0;
    this.HairColourId = 0;
    this.HeightId = 0;
    this.BuildId = 0;
    this.SetInteractionUIDate();
    this.PersonCompletingForm = this.asrv.GetMyUserID();
  }

  SaveProgress(stage: number): Promise<boolean> {
    switch (stage) {
      case this.stageReason:
      // Privacy Notice has not been ticked
      case this.stagePrivacyNotice:
      // Actions have not been selected
      case this.stageAction:
      // Placeholder Customer Details
      case this.stageCustomerDetails:
        // No photo
        if (!this.Interaction.MediaModelCollection) {
          this.Interaction.MediaModelCollection = [];
          this.Interaction.MediaModelCollection.push({ MediaClassKeyId: 4, MediaId: 0, Data: this.whiteSpaceImage });
        }
      case this.stageIdentification:
        // Flag a nickname placeholder
        if (!this.Interaction.InteractedActorCompositeModel.BiologicalActorCompositeModel.Alias) {
          this.Interaction.InteractedActorCompositeModel.BiologicalActorCompositeModel.Alias = prompt('Please enter a nickname to identify this interaction');
        }
        if (!this.Interaction.InteractedActorCompositeModel.BiologicalActorCompositeModel.Alias) {
          alert('Record not saved, please try again.')
          return new Promise<boolean>(resolve => { resolve(false) });
        }
      case this.stageDescription:
        // Handles Customer Review, this is currently not needed
        if (!this.Interaction.InteractionMonitoringPeriod) {
          this.Interaction.InteractionMonitoringPeriod = new Views_InteractionMonitoringPeriodsModel();
        }
        this.Interaction.InteractionMonitoringPeriod.Duration = 56;
        this.Interaction.InteractionMonitoringPeriod.Start = new Date();
      case this.stageReview:
      // Handles Confirmation, this shouldn't ever be needed, but will leave just incase.
      case this.stageConfirmation:
      // Post, shouldn't need anything, but will leave here incase of requests.
    }

    this.Interaction.InteractedActorCompositeModel.BiologicalActorCompositeModel.PostalAddressClassId = 1;
    this.Interaction.SiteId = this.asrv.DefaultSiteId;

    if (!this.Interaction.TagIdCollection.some(i => i === this.incomplete)) {
      this.Interaction.TagIdCollection.push(this.incomplete);
    }

    return new Promise<boolean>(resolve => {
      let subscription = this._api.postInteractionCompositeModel(this.Interaction).subscribe(res => {
        subscription.unsubscribe();
        this.Interaction = res;
        this.Interaction.InteractedActorCompositeModel = res.InteractedActorCompositeModelCollection[0];
        console.log(this.Interaction);
        alert('Saved Successfully');
        resolve(true);
      }, err => {
        subscription.unsubscribe();
        alert('Record not saved, please try again.')
        this.Interaction.InteractedActorCompositeModel.BiologicalActorCompositeModel.Alias = null;
        resolve(false);
      })
    })
  }

  Save(updateTraits?: boolean): Promise<boolean> {
    if (this.HasIconImage() && updateTraits) {
      let traits: Views_BiologicalActorTraitModel[] = [];
      traits.push({ TraitClassKeyId: 1, Data: this.HairColourOptions.find(i => i.HairColourId === this.HairColourId).HairColour });
      traits.push({ TraitClassKeyId: 2, Data: this.HairstyleOptions.find(i => i.HairstyleId === this.HairstyleId).Hairstyle });
      // traits.push({TraitClassKeyId: 3, Data: this.ClothingOptions.find(i => i.ClothingId === this.ClothingId).Clothing});
      // traits.push({TraitClassKeyId: 4, Data: this.AccentOptions.find(i => i.AccentId === this.AccentId).Accent});
      // traits.push({TraitClassKeyId: 5, Data: this.BodyArtOptions.find(i => i.BodyArtId === this.BodyArtId).BodyArt});
      traits.push({ TraitClassKeyId: 6, Data: this.AgeOptions.find(i => i.AgeId === this.AgeId).Age });
      traits.push({ TraitClassKeyId: 7, Data: this.HeightOptions.find(i => i.HeightId === this.HeightId).Height });
      traits.push({ TraitClassKeyId: 8, Data: this.BuildOptions.find(i => i.BuildId === this.BuildId).Build });
      traits.push({ TraitClassKeyId: 9, Data: this.EthnicityOptions.find(i => i.EthnicityId === this.EthnicityId).Ethnicity });

      this.Interaction.InteractedActorCompositeModel.BiologicalActorCompositeModel.BiologicalActorTraitModelCollection = traits;
    }

    this.Interaction.InteractedActorCompositeModel.BiologicalActorCompositeModel.PostalAddressClassId = 1;
    this.Interaction.SiteId = this.asrv.SiteSelect.KeySelected;

    this.RemoveIncompleteTag();

    var tags: number[] = [];
    this.Interaction.TagIdCollection.forEach(tag => {
      if (!tags.some(i => i === tag)) {
        tags.push(tag);
      }
    })
    this.Interaction.TagIdCollection = tags;
    return new Promise<boolean>(resolve => {
      this._api.postInteractionCompositeModel(this.Interaction).subscribe(res => {
        this.Interaction = res;
        resolve(true);
      }, err => {
        resolve(false);
      })
    })
  }

  setSaveInteraction(val) {
    this.saveInteraction.next(val);
  }

  getSaveInteraction(): Observable<boolean> {
    return this.saveInteraction;
  }

  CancelReview(): Promise<Views_InteractionsCompositeModel> {
    delete this.Interaction.InteractionMonitoringPeriod;

    /*
    if (!this.Interaction.MediaModelCollection.find(i => i.MediaClassKeyId === 4).Data) {
      this.Interaction.MediaModelCollection.find(i => i.MediaClassKeyId === 4).Data = this.whiteSpaceImage;
    }
    */

    this.RemoveIncompleteTag();

    return new Promise<Views_InteractionsCompositeModel>(resolve => {
      console.log(this.Interaction.TagIdCollection);
      if (!this.Interaction.TagIdCollection.some(i => i === this.archived)) {
        this.Interaction.TagIdCollection.push(this.archived);
        console.log('not found', this.Interaction.TagIdCollection);
      }
      if (this.Interaction.MediaModelCollection.length > 1) {
        this.Interaction.MediaModelCollection.length = 1;
      }
      if (!this.Interaction.MediaModelCollection.find(i => i.MediaClassKeyId === this.asrv.Globals.ClassKeyIds.MediaModelImage).Data) {
        this.Interaction.MediaModelCollection.find(i => i.MediaClassKeyId === this.asrv.Globals.ClassKeyIds.MediaModelImage).Data = this.whiteSpaceImage;
      }
      this._api.postInteractionCompositeModel(this.Interaction).subscribe(res => {
        this.Interaction = res;
        resolve(this.Interaction);
      })
    })
  }

  Load(interactionId: number): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      let subscription = this._api.getInteractionCompositeModel(interactionId).subscribe(res => {
        subscription.unsubscribe();
        this.Interaction = res;
        this.Interaction.InteractedActorCompositeModel = res.InteractedActorCompositeModelCollection[0];
        this.Interaction.MediaModelCollection = [];
        this.ReadPrivacyNotice = false;
        this.GetHistoricInteractionIds();
        resolve(true);
      })
    })
  }

  LoadViewModel(interactionId: number, reload?: boolean): Promise<boolean> {
    this.ViewDataLoading = true;
    return new Promise<boolean>(resolve => {
      let subscription = this._api.getInteractionCompositeModel(interactionId).subscribe(res => {
        subscription.unsubscribe();
        this.Interaction = res;
        this.Interaction.InteractedActorCompositeModel = res.InteractedActorCompositeModelCollection[0];
        this.Interaction.MediaModelCollection = [];
        this.asrv.GetMediaForInteraction(interactionId).then(value => {
          this.Interaction.MediaModelCollection.push(value);
          if (!reload) { this.GetHistoricInteractionIds(); }
          this.ViewDataLoading = false;
          resolve(true);
        })
      })
    })
  }

  LoadHistoricInteraction(interactionId: number): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      let subscription = this._api.getInteractionCompositeModel(interactionId).subscribe(res => {
        subscription.unsubscribe();
        this.Interaction = res;
        this.Interaction.InteractedActorCompositeModel = res.InteractedActorCompositeModelCollection[0];
        this.Interaction.MediaModelCollection = [];
        resolve(true);
      })
    })
  }

  LoadInteraction(interactionId: number): Promise<Views_InteractionsCompositeModel> {
    return new Promise<Views_InteractionsCompositeModel>(resolve => {
      let subscription = this._api.getInteractionCompositeModel(interactionId).subscribe(res => {
        subscription.unsubscribe();
        res.InteractedActorCompositeModel = res.InteractedActorCompositeModelCollection[0];
        resolve(res);
      })
    })
  }

  GetInteractionDetails(interactionId: number): Promise<Views_InteractionsCompositeModel> {
    return new Promise<Views_InteractionsCompositeModel>(resolve => {
      let subscription = this._api.getInteractionCompositeModel(interactionId).subscribe(res => {
        subscription.unsubscribe();
        res.InteractedActorCompositeModel = res.InteractedActorCompositeModelCollection[0];
        resolve(res);
      })
    })
  }

  // getInteractionCompositeModel(interactionId: number): Observable<Views_InteractionsCompositeModel> {
  //   return this.dsrv.Get<Views_InteractionsCompositeModel>('api/View_InteractionCompositeModel/' + interactionId);
  // }

  private GetHistoricInteractionIds(): void {
    let postObject: Post_InteractionReferenceCompositeModel = new Post_InteractionReferenceCompositeModel([this.Interaction.InteractionId]);
    postObject.ReferenceCollection.push(this.Interaction.Reference);
    let subscription = this.asrv.GetInteractionReferenceCompositeModel(postObject).subscribe(res => {
      subscription.unsubscribe();
      this.HistoricIds = res.map(obj => {
        return obj.InteractionId;
      })
    })
  }

  RemoveIncompleteTag(): void {
    if (this.Interaction.TagIdCollection.some(i => i === this.asrv.Globals.TagValues.IncompleteInteraction)) {
      let index = this.Interaction.TagIdCollection.indexOf(this.asrv.Globals.TagValues.IncompleteInteraction, 0);
      if (index > -1) {
        this.Interaction.TagIdCollection.splice(index, 1);
      }
    }
  }

  StartReview(): void {
    this.Review = new Views_ReviewsCompositeModel();
    this.Review.Reference = this.Interaction.Reference;
    this.Review.Interactions_InteractionId = this.Interaction.InteractionId;
    this.Review.SiteId = this.asrv.DefaultSiteId;
    this.Review.InteractedActorCompositeModel = this.Interaction.InteractedActorCompositeModel;
    this.Review.MediaModelCollection = JSON.parse(this.reviewMediaCollection);
  }

  SaveReview(): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      // console.log(this.Review);
      this.Review.InteractedActorCompositeModelCollection = [];
      this.Review.InteractedActorCompositeModelCollection.push(this.Review.InteractedActorCompositeModel);
      let subscription = this._databaseCalls.PostReviewCompositeModel(this.Review).subscribe(res => {
        subscription.unsubscribe();
        this.Interaction = res;
        resolve(true);
      }, err => {
        subscription.unsubscribe();
        var obj: Views_ReviewsCompositeModel = new Views_ReviewsCompositeModel;
        obj.InteractionId = -1;
        resolve(false);
      })
    })
  }

  GetInteractionReview(interactionid: number): Promise<Views_ReviewsCompositeModel> {
    return new Promise<Views_ReviewsCompositeModel>(resolve => {
      let subscription = this._databaseCalls.GetReviewCompositeModel(interactionid).subscribe(res => {
        subscription.unsubscribe();
        resolve(res);
      }, err => {
        subscription.unsubscribe();
        var obj: Views_ReviewsCompositeModel = new Views_ReviewsCompositeModel;
        obj.InteractionId = -1;
        resolve(obj);
      })
    })
  }

  SetInteractionUIDate() {
    this._interactionUIDate = new Date();
  }

  GetInteractionUIDate(): string {
    if (!this._interactionUIDate) { this.SetInteractionUIDate() };
    return this._interactionUIDate.toString().slice(0, 21);
  }

  GetActorsDataProtectionOfficer() {
    this.asrv.GetUserOrganisationDataProtectionOfficer().then(value => {
      this.ActorsDataProtectionOfficer = value;
    })
  }

  AddItemToTagCollection(tag: number) {
    if (!this.Interaction.TagIdCollection.some(i => i === tag)) {
      this.Interaction.TagIdCollection.push(tag);
    }
  }

  RemoveItemFromTagCollection(tag: number) {
    if (this.Interaction.TagIdCollection.some(i => i === tag)) {
      let index = this.Interaction.TagIdCollection.indexOf(tag, 0);
      if (index > -1) {
        this.Interaction.TagIdCollection.splice(index, 1);
      }
    }
  }

  GetMediaForInteraction(interactionId): Promise<Views_MediaModel> {
    return new Promise<Views_MediaModel>(resolve => {
      let subscription = this.asrv.GetInteractionMedia(interactionId).subscribe(res => {
        subscription.unsubscribe();
        resolve(res);
      }, error => {
        var media = new Views_MediaModel();
        media.MediaClassKeyId = 4;
        media.MediaId = 80;
        resolve(media);
      })
    })
  }

  HasIconImage(media?: Views_MediaModel): boolean {
    if (media) {
      return (media.MediaId > 0 && media.MediaId < 100)
    }

    if (!this.Interaction.MediaModelCollection) {
      return false;
    } else {
      return (this.Interaction.MediaModelCollection[0].MediaId > 0 && this.Interaction.MediaModelCollection[0].MediaId < 100)
    }
  }

  HasFruitImage(media?: Views_MediaModel): boolean {
    if (media) {
      return this.SystemIconImageList.some(i => i.id === media.MediaId);
    }
    return this.SystemIconImageList.some(i => i.id === this.Interaction.MediaModelCollection.find(i => i.MediaClassKeyId === this.asrv.Globals.ClassKeyIds.MediaModelImage).MediaId);
  }

  GetIconNameForInteractionImage() {
    return this.SystemIconImageList.find(i => i.id === this.Interaction.MediaModelCollection.find(i => i.MediaClassKeyId === this.asrv.Globals.ClassKeyIds.MediaModelImage).MediaId).name;
  }

  HasAction() {
    return this.Interaction.TagIdCollection.some(i => (i >= this.interactionActionBanned && i <= this.interactionActionIssuedPlayDiary) || i === 6);
  }

  ReviewHasAction() {
    return this.Review.TagIdCollection.some(i => i >= this.interactionActionBanned && i <= this.interactionActionIssuedPlayDiary);
  }

  GetReasonsString(interaction?: Views_InteractionsCompositeModel) {
    let obj: Views_InteractionsCompositeModel = interaction ? interaction : this.Interaction;
    let reasonsString: string = '';
    let reasonStrings: string[] = [];
    if (obj.TagIdCollection.some(i => i >= 91 && i <= 96)) {
      obj.TagIdCollection.forEach(tag => {
        if (tag === this.interactionReasonTime) { reasonStrings.push('Time') }
        if (tag === this.interactionReasonSpend) { reasonStrings.push('Spend') }
        if (tag === this.interactionReasonBehaviour) { reasonStrings.push('Behaviour') }
        if (tag === this.interactionReasonMachineAlert) { reasonStrings.push('Machine Alert') }
        if (tag === this.interactionReasonVulnerability) { reasonStrings.push('Vulnerability') }
        if (tag === this.interactionReasonWishToExclude) { reasonStrings.push('Wish to Self-Exclude') }
      })

      var numberOfReasons: number = reasonStrings.length;
      var counter: number = 0;
      reasonStrings.forEach(reason => {
        counter += 1;

        if (counter === numberOfReasons) {
          reasonsString = reasonsString + reason + '.';
        } else {
          reasonsString = reasonsString + reason + ', ';
        }
      })
    }

    return reasonsString;
  }

  GetActionsString(interaction?: Views_InteractionsCompositeModel) {
    let obj: Views_InteractionsCompositeModel = interaction ? interaction : this.Interaction;
    let actionsString: string = '';
    let actionStrings: string[] = [];
    if (obj.TagIdCollection.some(i => i >= 110 && i <= 119)) {
      obj.TagIdCollection.forEach(tag => {
        if (tag === this.interactionActionBanned) { actionStrings.push('Barred') }
        if (tag === this.interactionActionTakeABreak) { actionStrings.push('Break') }
        if (tag === this.interactionActionImposeTimeLimits) { actionStrings.push('Time Limits') }
        if (tag === this.interactionActionImposeSpendLimits) { actionStrings.push('Spend Limits') }
        if (tag === this.interactionActionChangeCategory) { actionStrings.push('Change Category') }
        if (tag === this.interactionActionMonitorBehaviour) { actionStrings.push('Behaviour') }
        if (tag === this.interactionActionSignpostToGamcare) { actionStrings.push('Signpost To Gamcare') }
        if (tag === this.interactionActionStayInControlLeaflet) { actionStrings.push('Stay In Control Leaflet') }
        if (tag === this.interactionActionGambleManagementApp) { actionStrings.push('Gamble Management App') }
        if (tag === this.interactionActionIssuedPlayDiary) { actionStrings.push('Play Diary') }
        this.interactionActionMonitorBehaviour

      })

      var numberOfActions: number = actionStrings.length;
      var counter: number = 0;
      actionStrings.forEach(action => {
        counter += 1;

        if (counter === numberOfActions) {
          actionsString = actionsString + action + '.';
        } else {
          actionsString = actionsString + action + ', ';
        }
      })
    }
    return actionsString;
  }

  AddBoxShadowToSelectedElement(event: any, id: number) {
    if (this._previousEvent) {
      this._previousEvent.style.border = '0px solid #5e698e';
      this._previousEvent.style.borderRadius = '0';
      this._previousEvent.style.boxShadow = '0px 0px 0px #5e698e';
      this._previousEvent.style.margin = '10px';
    }

    event.currentTarget.style.border = '5px solid #5e698e';
    if (id > 0 && id <= 8) {
      event.currentTarget.style.boxShadow = '10px 10px 10px #8694bf';
    } else if (id >= 9 && id <= 16) {
      event.currentTarget.style.boxShadow = '10px 10px 10px #82bcbc';
    } else if (id >= 17 && id <= 24) {
      event.currentTarget.style.boxShadow = '10px 10px 10px #9a444b';
    } else if (id >= 25 && id <= 32) {
      event.currentTarget.style.boxShadow = '10px 10px 10px #ba4022';
    } else {
      event.currentTarget.style.boxShadow = '10px 10px 10px #5e698e';
    }

    if (id < 80) {
      event.currentTarget.style.borderRadius = '5em';
    } else {
      event.currentTarget.style.borderRadius = '2.6em';
    }
    event.currentTarget.style.margin = 'px';
    this._previousEvent = event.currentTarget;
    this._currentSelectedIconId = id;
  }

  SaveImage(img?) {
    var currentMedia = new Views_MediaModel();
    currentMedia.MediaClassKeyId = 4;
    if (this.Interaction.MediaModelCollection.length > 0) {
      if (this.Interaction.MediaModelCollection.some(i => i.MediaClassKeyId === 4)) {
        currentMedia = this.Interaction.MediaModelCollection.find(i => i.MediaClassKeyId === 4)
      }
    }

    if (img) {
      currentMedia.Data = img;
      currentMedia.MediaId = 0;
    } else {
      currentMedia.Data = this.whiteSpaceImage;
      currentMedia.MediaId = this._currentSelectedIconId;
    }

    if (this.Interaction.MediaModelCollection.length === 0) {
      this.Interaction.MediaModelCollection.push(currentMedia);
    }
  }

  GetInteractionColor(): string {
    if (this.Interaction.TagIdCollection.some(i => i === this.asrv.Globals.TagValues.ArchivedInteraction)) {
      return this.asrv.Globals.BackgroundColor.Grey;
    } else if (this.Interaction.TagIdCollection.some(i => i === this.asrv.Globals.TagValues.IncompleteInteraction)) {
      return this.asrv.Globals.BackgroundColor.CyanBlue;
      // else if () { this.cardOuter.nativeElement.style.borderColor = this.asrv.Globals.BackgroundColor.MagentaPink; }
    } else if (this.Interaction.InteractionMonitoringPeriod) {
      let diffDaysStart = this._dateMethods.DateDifferenceInDays(new Date(this.Interaction.InteractionMonitoringPeriod.Start))
      let daysUntilReview = (this.Interaction.InteractionMonitoringPeriod.Duration - diffDaysStart)
      if (daysUntilReview <= -14) {
        return this.asrv.Globals.BackgroundColor.Red;
      } else if (daysUntilReview <= 0 && daysUntilReview > -14) {
        return this.asrv.Globals.BackgroundColor.Yellow;
      } else {
        return this.asrv.Globals.BackgroundColor.Green;
      }
    } else {
      return this.asrv.Globals.BackgroundColor.Grey;
    }
  }

}

class DatabaseCalls {
  constructor(public dsrv: DataService) { }

  // Post a "View Model" comprising of an Interaction and associated models to the system. 
  // This is designed to allow an entire Interaction to be submitted to the system in one operation.
  // PostInteractionCompositeModel(interaction: Views_InteractionsCompositeModel): Observable<Views_InteractionsCompositeModel> {
  //   return this.dsrv.Post<Views_InteractionsCompositeModel>('api/View_InteractionCompositeModel', interaction);
  // }

  GetReviewCompositeModel(interactionid: number): Observable<Views_ReviewsCompositeModel> {
    return this.dsrv.Get<Views_ReviewsCompositeModel>('api/View_ReviewCompositeModel/' + interactionid);
  }

  PostReviewCompositeModel(reviewModel: Views_ReviewsCompositeModel): Observable<Views_ReviewsCompositeModel> {
    return this.dsrv.Post<Views_ReviewsCompositeModel>('api/View_ReviewCompositeModel', reviewModel);
  }
}