import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import { PostcodeanywhereService } from 'src/app/services/postcodeanywhere.service';
import { Addresses, Address } from 'src/app/models/Addresses';
import { Maps } from 'src/app/models/google-maps/maps'
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-customeraddress',
  templateUrl: './customeraddress.component.html',
  styleUrls: ['./customeraddress.component.scss', '../generic-form.component.scss']
})
export class CustomeraddressComponent implements OnInit, OnDestroy {
  addresses: Addresses = new Addresses(); 
  address: Address;
  loadingAddressList: boolean = false;
  currentAddressId;
  addressPopulated: boolean = false;
  postCodeSearchIsValid: boolean = false;
  postCodeControl = new Subject<string>();
  postcodeInput: string = '';
  // private map: Maps = new Maps();

  private getAddressIdSubscription: Subscription;
  private getAddressListSubscription: Subscription;
  private allowUnsubscribe: boolean = false;

  constructor(private postcodeAnywhere: PostcodeanywhereService) { 
    this.postCodeControl.pipe(
      debounceTime(400),
      distinctUntilChanged())
      .subscribe(value => {
        this.object.Postcode = this.postCodeControl;
        if (value.length > 4)
          this.AddressLookup();
          this.allowUnsubscribe = true;
      }
    );
  }

  object: any;
  @Input('object') get Object(): any {
    return this.object;
  };
  @Output('object') objectChange: EventEmitter<any> = new EventEmitter();
  set Object(val: any) {
    this.object = val;
    this.objectChange.emit(this.object);
  }

  formName: string;
  @Input('formName') get FormName(): string {
    return this.formName;
  };
  @Output('formName') formNameChange: EventEmitter<string> = new EventEmitter();
  set FormName(val: string) {
    this.formName = val;
    this.formNameChange.emit(this.formName);
  }
    
  ngOnInit() {
    /*
    this.map.Initialise();
    if (this.object.Postcode) {
      this.postcodeInput = this.object.Postcode;
      this.addressPopulated = true;
    }
    */
  }

  ngOnDestroy() {
    if (this.allowUnsubscribe) {
      this.getAddressIdSubscription.unsubscribe();
      this.getAddressListSubscription.unsubscribe();
    }
  }

  @ViewChild('map') mapElement: ElementRef;
  AddressLookup() {
    this.loadingAddressList = true;

    // Google Maps Example
    /*
    this.postcodeAnywhere.getGeocode(this.postcodeInput).subscribe( res => {
      if (res.Items.length > 0) {
        this.mapElement.nativeElement = this.map.GenerateMap(res.Items[0].Latitude, res.Items[0].Longitude);
        this.mapElement.nativeElement = this.map.AddBlankMarker(res.Items[0].Latitude, res.Items[0].Longitude)
      }
    });
    */

    // Query the API and Add the Addresses
    return this.getAddressIdSubscription = this.postcodeAnywhere.getAddressId(this.postcodeInput).subscribe( res => {
      console.log(res);
      this.getAddressListSubscription = this.postcodeAnywhere.getAddressList(this.postcodeInput, res.Items[0].Id).subscribe( res2 => {

          this.addresses = res2;
          console.log(res2);
          this.postCodeSearchIsValid = true;
          this.object.Line1 = null;
          this.object.Line2 = null;
          this.object.TownCity = null;
          this.object.County = null;
          this.object.Postcode = this.postCodeControl;
          this.addressPopulated = false;
          this.loadingAddressList = false;
      });
    });
  }

  PopulateAddress() {
    this.postcodeAnywhere.getAddressDetails(this.currentAddressId).subscribe( res => {
      this.object.Line1 = res.Items[0].Line1;
      this.object.Line2 = res.Items[0].Line2;
      this.object.TownCity = res.Items[0].City;
      this.object.County = res.Items[0].Province;
      this.object.Postcode = res.Items[0].PostalCode;
      this.addressPopulated = true;
    });
  }
}
