import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { CustomerInteraction } from 'src/app/models/CustomerInteraction';
import { InteractionService } from 'src/app/services/interaction.service';

const iconStart = 1;
const iconEnd = 32;
const outlineStart = 80;
const maxOutlines = 82;

@Component({
  selector: 'app-icon-gallery',
  templateUrl: './icon-gallery.component.html',
  styleUrls: ['./icon-gallery.component.scss']
})
export class IconGalleryComponent implements OnInit {

  constructor(public isrv: InteractionService) { }

  iconIds: number[] = [];
  outlineIds: number[] = [];
  allowCSS: boolean = true;

  showIcons: boolean = true;
  @Input('showIcons') get ShowIcons(): boolean {
    return this.showIcons;
  };
  @Output('showIcons') showIconsChange: EventEmitter<boolean> = new EventEmitter();
  set ShowIcons(val: boolean) {
    this.showIcons = val;
    this.showIconsChange.emit(this.showIcons);
  }

  ngOnInit() {
    let iconIdsAvailable: number[] = JSON.parse(localStorage.getItem('IconIdsAvailable'));

    for (let i = iconStart; i <= iconEnd; i++) {
      if (iconIdsAvailable.some(j => j === i)) {
        this.iconIds.push(i);
      }
    }
  }

  SelectImage(id, event) {
    if (this.allowCSS) {
      this.isrv.AddBoxShadowToSelectedElement(event, id);
    }
  }
}