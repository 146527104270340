import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { Views_MediaModel } from 'src/app/models/InteractionExclusion/Views_MediaModel';

@Component({
  selector: 'app-camera-live-view',
  templateUrl: './camera-live-view.component.html',
  styleUrls: ['./camera-live-view.component.scss']
})
export class CameraLiveViewComponent implements OnInit {

  private readonly height: number = 450;
  private readonly width: number = 800;

  public drawingImage: Boolean;
  public media: Views_MediaModel;
  @Input('media') get Media(): Views_MediaModel
  {
    return this.media;
  };
  @Output('media') mediaChange: EventEmitter<Views_MediaModel> = new EventEmitter();
  set Media(val: Views_MediaModel) {
    this.media = val;
    this.mediaChange.emit(this.media);
  }

  //Video Properties
  @ViewChild('video', { static: true }) videoElement: ElementRef;
  @ViewChild('canvas', { static: true }) canvas: ElementRef;  

  public videoWidth: number = 0;
  public videoHeight: number = 0;

  public constraints = {
    video: {
        facingMode: "environment",
        width: { ideal: this.width },
        height: { ideal: this.height }
    }
  };

  constructor(private renderer: Renderer2) { }

  ngOnInit() {
    this.StartCamera();
  }


  StartCamera() {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) { 
      navigator.mediaDevices.getUserMedia(this.constraints).then(this.AttachVideo.bind(this)).catch(this.HandleError);
    } else {
      alert('Sorry, camera not available.');
    }
  }

  AttachVideo(stream) {
    this.renderer.setProperty(this.videoElement.nativeElement, 'srcObject', stream);
    this.renderer.listen(this.videoElement.nativeElement, 'play', (event) => {
        this.videoHeight = this.height;
        this.videoWidth = this.width;
    });
  }

  HandleError(error) {
    console.log('Error: ', error);
  }

  Capture() {
    this.renderer.setProperty(this.canvas.nativeElement, 'width', this.width);
    this.renderer.setProperty(this.canvas.nativeElement, 'height', this.height);
    this.canvas.nativeElement.getContext('2d').drawImage(this.videoElement.nativeElement, 0, 0);
    this.media.Data = this.canvas.nativeElement.toDataURL();
    this.drawingImage = false;
    this.CloseForm.next();    
  }
  @Output() CloseForm = new EventEmitter<string>();
}

/*
import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { Exclusion } from 'src/app/models/Exclusion';


@Component({
  selector: 'app-camera-live-view',
  templateUrl: './camera-live-view.component.html',
  styleUrls: ['./camera-live-view.component.scss']
})
export class CameraLiveViewComponent implements OnInit {

  drawingImage: Boolean;
  exclusion: Exclusion;
  @Input('exclusion') get Exclusion(): Exclusion
  {
    return this.exclusion;
  };
  @Output('exclusion') excluderChange: EventEmitter<Exclusion> = new EventEmitter();
  set Exclusion(val: Exclusion) {
    this.exclusion = val;
    this.excluderChange.emit(this.exclusion);
  }

  //Video Properties
  @ViewChild('video', { static: true }) videoElement: ElementRef;
  @ViewChild('canvas', { static: true }) canvas: ElementRef;  

  videoWidth = 0;
  videoHeight = 0;

  constraints = {
    video: {
        facingMode: "environment",
        width: { ideal: 800 },
        height: { ideal: 450 }
    }
  };

  constructor(private renderer: Renderer2) { }

  ngOnInit() {
    this.startCamera();
  }


  startCamera() {
    if (!!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia)) { 
      navigator.mediaDevices.getUserMedia(this.constraints).then(this.attachVideo.bind(this)).catch(this.handleError);
    } else {
      alert('Sorry, camera not available.');
    }
  }

  attachVideo(stream) {
    this.renderer.setProperty(this.videoElement.nativeElement, 'srcObject', stream);
    this.renderer.listen(this.videoElement.nativeElement, 'play', (event) => {
        this.videoHeight = 800;
        this.videoWidth = 450;
    });
  }

  handleError(error) {
    console.log('Error: ', error);
  }

  capture() {
    this.renderer.setProperty(this.canvas.nativeElement, 'width', 800);
    this.renderer.setProperty(this.canvas.nativeElement, 'height', 450);
    this.canvas.nativeElement.getContext('2d').drawImage(this.videoElement.nativeElement, 0, 0);
    this.exclusion.Image_ExcluderBase64 = this.canvas.nativeElement.toDataURL();
    this.exclusion.Image_Excluder = this.exclusion.Image_ExcluderBase64;
    this.drawingImage = false;
    this.CloseForm.next();    
  }

  @Output() CloseForm = new EventEmitter<string>();

}
*/
