import { Honorific } from '../Honorific';
import { Gender } from '../Gender';
import { ExclusionDurationOption } from '../ExclusionDurationOption';
import { ExclusionReason } from '../ExclusionReason';
import { Site } from '../Site';
import { Organisation } from '../Organisation';
import { IdentificationDocument } from '../IdentificationDocumentKey';
import { SiteCollection } from '../SiteCollection';
import { ISDDateMethods } from '../ISDDateMethods';
import { ActorService } from 'src/app/services/actor.service';
import { Subscription } from 'rxjs';
import { ExcluderService } from 'src/app/services/excluder.service';
import { ApiService } from 'src/app/services/api.service';

// Class for creating tabular reports.
// Every tabular class requires the following properties.
// Rows: any[] = [] --Holds all the newRows pushed.
// HeaderTransaltion = [{}]  --This will print all the headers to the table.
// newRow = [{}] --Make the values all blank but match the HeaderTranslationIds
// public AddRow(rowObject) {} --Use this push rows, ensure all values are hardcoded for now.

export class ReinstatementList {
  private DateMethods: ISDDateMethods = new ISDDateMethods();
  public Rows: any[] = [];
  public AllRows: any[] = [];
  public SubscriptionsOpened: number;
  public CompletedSubscriptions: number = 0;
  private siteNamePromises: SiteNamePromise[] = [];
  public DataLoaded: boolean = false;
  public HeaderTranslation = [{
    'HonorificId': 'Title',
    'Forename': 'Forename',
    'Surname': 'Surname',
    'Accepted': 'Reinstated Date',
    'AcceptedBy': 'Reinstated By',
    'Reference': 'Reference',
    'SiteId': 'Site',
  }];

  public GetExclusionIsSubscribed: boolean;
  public ExclusionSubscription: Subscription;

  constructor(private asrv: ActorService, private esrv: ExcluderService, private interactionids: number[], private _api: ApiService) {
    this.interactionids.forEach(id => {
      this.AddRow(id);
    });
    this.DataLoaded = true;
  }

  public ShowAll() {
    this.Rows = this.AllRows;
  }

  public ShowUserAccepted() {
    this.Rows = [];
    this.AllRows.forEach(row => {
      if (!row['AutoAccepted']) {
        this.Rows.push(row);
      }
    })
  }

  public ShowAutoAccepted() {
    this.Rows = [];
    this.AllRows.forEach(row => {
      if (row['AutoAccepted']) {
        this.Rows.push(row);
      }
    })
  }

  public AddRow(id: number) {
    var returnRow = {};
    returnRow['ExclusionId'] = id;
    returnRow['Reference'] = false;
    returnRow['HonorificId'] = false;
    returnRow['Forename'] = false;
    returnRow['Surname'] = false;
    returnRow['Accepted'] = false;
    returnRow['AcceptedBy'] = false;
    returnRow['SiteId'] = false;
    // returnRow['AcceptedBy'] = false;

    this._api.getReinstatementsForReports(id).subscribe(reinstatement => {
      returnRow['Accepted'] = this.DateMethods.FormatDateForReport(new Date(reinstatement.Accepted));
      this.ExclusionSubscription = this._api.getViewExclusionCompositeModel(reinstatement.Exclusions_InteractionId).subscribe(res => {
        this.GetExclusionIsSubscribed = true;
        res.InteractedActorCompositeModel = res.InteractedActorCompositeModelCollection[0];
        returnRow['Reference'] = res.Reference;
        returnRow['Forename'] = res.InteractedActorCompositeModel.BiologicalActorCompositeModel.Forename;
        returnRow['Surname'] = res.InteractedActorCompositeModel.BiologicalActorCompositeModel.Surname;
        this.asrv.GetHonorifics().then(value => {
          returnRow['HonorificId'] = value.find(x => x.HonorificId === res.InteractedActorCompositeModel.BiologicalActorCompositeModel.HonorificId).Name
        });
        this.GetSiteName(res.SiteId).then(value => {
          returnRow['SiteId'] = value;
        });
        this.asrv.GetActorNames(res.AssociatedActorIdCollection).subscribe(res => {
          res.forEach(employee => {
            const _employee = [];
            _employee.push({
              ActorId: employee.ActorId,
              Forename: employee.NameCollection.find(i => i.NameClassKeyId === 1).Name,
              Surname: employee.NameCollection.find(i => i.NameClassKeyId === 2).Name,
              ActorRoleCollection: null,
              
            });
            returnRow['AcceptedBy'] = `${_employee[0].Forename} ${_employee[0].Surname}`;
          })
        });
      }, error => {
        returnRow['Reference'] = '';
        returnRow['HonorificId'] = 'Error';
        returnRow['Forename'] = 'Fetching';
        returnRow['Surname'] = 'Data';
      });
    }, err => {
      returnRow['Reference'] = '';
      returnRow['HonorificId'] = 'Error';
      returnRow['Forename'] = 'Fetching';
      returnRow['Surname'] = 'Data';
      returnRow['Accepted'] = '';
    })
    this.Rows.push(returnRow);
  }

  GetSiteName(siteid): Promise<string> {
    if (this.siteNamePromises.some(i => i.SiteId === siteid)) {
      return this.siteNamePromises.find(i => i.SiteId === siteid).Promise;
    } else {
      return new Promise<string>(resolve => {
        this.asrv.GetSiteNameCollection([siteid]).then(value => {
          if (value) {
            resolve(value[0].Name);
          } else {
            resolve('');
          }
        })
      })
    }
  }

  
}

class SiteNamePromise {
  SiteId: number;
  Promise: Promise<string>;
}
