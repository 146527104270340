<div class="container global-seperator">    
    <div class="row">
        <div class="col-md-12">
            <h4><b>Reinstate Self-Exclusion</b></h4>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">                
            <div class="input-group">
                <div class="input-group-prepend">
                    <span class="input-group-text">Organisation*</span>
                </div>
                <input *ngIf="!asrv.ElevatedMode" type="text" class="form-control" name="inputReference" style="color: lightgray;" [(ngModel)]="asrv.UserOrganisationName" disabled>
                <select *ngIf="asrv.ElevatedMode" class="form-control drop-down-purple" [(ngModel)]="esrv.ReinstatementControllers.OrganisationId" (ngModelChange)="PopulateSiteDropDown();">
                    <option></option>
                    <option *ngFor="let dropDownItem of asrv.OrganisationSelect.DropDownItems" [ngValue]="dropDownItem.Key">{{dropDownItem.Value}}</option>
                </select>
            </div>                
        </div>
        <div class="col-md-6">
            <div class="input-group">
                <div class="input-group-prepend">
                    <span class="input-group-text">Site*</span>
                </div>
                <div class="spinner-border" *ngIf="loadingSiteDropDown" role="status"><span class="sr-only"></span></div>
                <select id="ctrlSites" *ngIf="!loadingSiteDropDown" class="form-control drop-down-purple" [(ngModel)]="esrv.ReinstatementControllers.SiteId" (ngModelChange)="PopulateEmployeeDropDown();">
                    <option></option>
                    <option *ngFor="let dropDownItem of asrv.SiteSelect.DropDownItems" [ngValue]="dropDownItem.Key">{{dropDownItem.Value}}</option>
                </select>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-6">
            <div class="input-group" id="inputUser">
                <div class="input-group-prepend">
                    <span class="input-group-text">Interview By</span>
                </div>
                <div class="spinner-border" *ngIf="loadingEmployeeDropDown" role="status"><span class="sr-only"></span></div>
                <select id="ctrlEmployee" *ngIf="!loadingEmployeeDropDown"  class="form-control drop-down-purple" name="user" [(ngModel)]="esrv.ReinstatementControllers.EmployeeId">
                    <option></option>
                    <option *ngFor="let dropDownItem of asrv.ColleagueSelect.DropDownItems" [ngValue]="dropDownItem.Key">{{dropDownItem.Value}}</option>    
                </select>
            </div>
        </div>
        <div class="col-md-6">
            <div class="input-group" id="inputMode">
                <div class="input-group-prepend">
                    <span class="input-group-text">Method</span>
                </div>
                <select class="form-control drop-down-purple" name="mode"[(ngModel)]="esrv.ReinstatementControllers.MethodId">
                    <option></option>
                    <option *ngFor="let dropDownItem of esrv.ReinstatementControllers.MethodControl.DropDownItems" [ngValue]="dropDownItem.Key">{{dropDownItem.Value}}</option>
                </select>
            </div>
        </div>
    </div>

    
    <div *ngIf="esrv.ReinstatementControllers.MethodId === 2" class="row" style="margin-top:10px;">
        <div class="col-md-6">
            <div class="input-group">
                <div class="input-group-prepend">
                    <span class="input-group-text">Provided Ref:</span>
                </div>
                <input type="text" class="form-control" name="inputReference" [(ngModel)]="inputReference">
            </div> 
        </div>
        <div class="col-md-6">
            <div class="input-group">
                <div class="input-group-prepend">
                    <span class="input-group-text">Expected Ref:</span>
                </div>
                <input type="text" class="form-control" name="firstName" [(ngModel)]="esrv.CurrentExclusion.Reference" disabled>
            </div> 
        </div>
    </div>

    <div class="row">
        <div class="col-md-6">
            <div class="input-group" id="inputDate">


                <div class="input-group-prepend">
                    <span class="input-group-text">Date</span>
                </div>
                <input class="form-control d-none d-lg-block" matInput [matDatepicker]="picker" (dateInput)="addEvent('input', $event)" (dateChange)="addEvent('change', $event)" [(ngModel)]="esrv.ReinstatementControllers.Date">
                <div class="input-group-append time-selector d-none d-lg-block">
                    <mat-datepicker-toggle class="form-control-date-toggle d-none d-lg-block" matSuffix [for]="picker"></mat-datepicker-toggle>
                </div>
                <mat-datepicker class="d-none d-lg-block" touchUi #picker></mat-datepicker>
                <input type="date" class="form-control date-picker-padding d-lg-none" name="accepted" [(ngModel)]="esrv.ReinstatementControllers.Date">

                <!--
                <div class="input-group-prepend">
                    <span class="input-group-text">Interview Date</span>
                </div>
                <input class="form-control d-none d-lg-block" matInput [matDatepicker]="picker" (dateInput)="addEvent('input', $event)" (dateChange)="addEvent('change', $event)" [(ngModel)]="reinstate.Accepted">
                <div class="input-group-append d-none d-lg-block">
                    <mat-datepicker-toggle class="form-control-date-toggle" matSuffix [for]="picker"></mat-datepicker-toggle>
                </div>
                <mat-datepicker class="d-none d-lg-block" touchUi #picker></mat-datepicker>
                <input type="date" class="form-control date-picker-padding d-lg-none" name="birthdate" [(ngModel)]="reinstate.Accepted">
                --> 
            </div>
        </div>
    </div>

    <div class="row check-box-seperator">
        <div class="col-md-6">
            <div class="flex-check-box mobile-check-box-seperator">
                <div class="flex-check-prepend">{{esrv.ReinstatementControllers.ConsideredControl.Label}}</div>
                <input [id]="esrv.ReinstatementControllers.ConsideredControl.DomId" type="checkbox" class="flex-check-box-control" [(ngModel)]="esrv.ReinstatementControllers.ConsideredControl.Value">
            </div>     
        </div>

        <div class="col-md-6">
            <div class="flex-check-box mobile-check-box-seperator">
                <div class="flex-check-prepend">{{esrv.ReinstatementControllers.RecievedCounsellingControl.Label}}</div>
                <input [id]="esrv.ReinstatementControllers.RecievedCounsellingControl.DomId" type="checkbox" class="flex-check-box-control" [(ngModel)]="esrv.ReinstatementControllers.RecievedCounsellingControl.Value">
            </div>     
        </div>
    </div>

    <div class="row">
        <div class="col-md-6">
            <div class="flex-check-box mobile-check-box-seperator">
                <div class="flex-check-prepend">{{esrv.ReinstatementControllers.UnderstandsCoolingOffControl.Label}}</div>
                <input [id]="esrv.ReinstatementControllers.UnderstandsCoolingOffControl.DomId" type="checkbox" class="flex-check-box-control" [(ngModel)]="esrv.ReinstatementControllers.UnderstandsCoolingOffControl.Value">
            </div>     
        </div>
        <div class="col-md-6">
            <div class="flex-check-box mobile-check-box-seperator">
                <div class="flex-check-prepend">{{esrv.ReinstatementControllers.AwareOfMonitoringControl.Label}}</div>
                <input [id]="esrv.ReinstatementControllers.AwareOfMonitoringControl.DomId" type="checkbox" class="flex-check-box-control" [(ngModel)]="esrv.ReinstatementControllers.AwareOfMonitoringControl.Value">
            </div>     
        </div>
    </div>
</div>  

<!--
    <div class="row" style="min-height:200px;">
        <div class="col-md-12">
            <div class="input-group signature-pad-container global-seperator">
                <span class="input-group-text">SIGNATURE</span>
                <canvas #canvas id="signature-pad" class="signature-pad" style="background-color: white;width:100%;max-height:100px;"></canvas>               
            </div>
        </div>
    </div>
-->
<div class="form-group" style="text-align: right; margin-top:20px;">
    <button type="button" class="btn btn-danger" (click)="evsrv.ShowMainView(false);" style="margin-right: 10px;">
        <span>Close</span>
    </button>
    <button #update type="button" class="btn btn-success" (click)="ValidateAndSendData();">  
        <span *ngIf="showSendingSpinner" class="spinner-grow spinner-grow-sm" role="status"></span>
        Reinstat<span *ngIf="!showSendingSpinner">e</span><span *ngIf="showSendingSpinner">ing</span><span *ngIf="showSendingSpinner">...</span>
    </button>
</div>

