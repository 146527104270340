import { Honorific } from '../Honorific';
import { Gender } from '../Gender';
import { ExclusionDurationOption } from '../ExclusionDurationOption';
import { SiteCollection } from '../SiteCollection';
import { ISDDateMethods } from '../ISDDateMethods';
import { ActorService } from 'src/app/services/actor.service';
import { ExcluderService } from 'src/app/services/excluder.service';
import { InteractionService } from 'src/app/services/interaction.service';
import { ApiService } from 'src/app/services/api.service';
import * as moment from 'moment';

// Class for creating tabular reports.
// Every tabular class requires the following properties.
// Rows: any[] = [] --Holds all the newRows pushed.
// HeaderTransaltion = [{}]  --This will print all the headers to the table.
// newRow = [{}] --Make the values all blank but match the HeaderTranslationIds
// public AddRow(rowObject) {} --Use this push rows, ensure all values are hardcoded for now.

export class CrimeAlertList {
    private DateMethods: ISDDateMethods = new ISDDateMethods();
    public Rows: any[] = [];
    public Honorifics: Honorific[] = [];
    public Genders: Gender[] = [];
    public DurationOptions: ExclusionDurationOption[] = [];
    public SubscriptionsOpened: number;
    public CompletedSubscriptions: number = 0;
    public DataLoaded: boolean = false;
    private siteNamePromises: SiteNamePromise[] = [];
    public HeaderTranslation = [{
        'Recorded': 'Date of Challenge',
        'eventType': 'Event Type',
        'policeCalled': 'Police Called',
        'SiteId': 'Site Name'
    }];
    public isLoading = true;

    constructor(
        public asrv: ActorService,
        public isrv: InteractionService,
        public ids: number[],
        private _api: ApiService,
    ) {
        this.ids.forEach(id => {
            this.AddRow(id);
        })

        this.DataLoaded = true;
    }

    public AddRow(id: number) {
        let idWasShown = 'No';
        let returnRow = {};
        returnRow['InteractionId'] = id;
        returnRow['Recorded'] = false;
        returnRow['eventType'] = false;
        returnRow['policeCalled'] = false;
        returnRow['SiteId'] = false;
        this._api.getCrimeAlertCompositeModel(id).subscribe(res => {
            // res.InteractedActorCompositeModel = res.InteractedActorCompositeModelCollection[0];
            // if (!(res.InteractedActorCompositeModel.BiologicalActorCompositeModel.Forename && res.InteractedActorCompositeModel.BiologicalActorCompositeModel.Surname)) {
            //     returnRow['Name'] = res.InteractedActorCompositeModel.BiologicalActorCompositeModel.Alias;
            // } else {
            //     returnRow['Name'] = res.InteractedActorCompositeModel.BiologicalActorCompositeModel.Forename + ' ' + res.InteractedActorCompositeModel.BiologicalActorCompositeModel.Surname;
            // }
            let machinesPlayed = 'No';
            let eventType: string = '';
            let idShown: string = '—';
            let policeCalled = 'No';
            res.TagIdCollection.forEach(tagId => {
                if (tagId >= 214 && tagId <= 224) {
                    if (eventType.length > 1) {
                        eventType = eventType + ' - ';
                    }
                    switch (tagId) {
                        case 214:
                            eventType = eventType + 'Physical Abuse';
                            break;
                        case 215:
                            eventType = eventType + 'Violence';
                            break;
                        case 218:
                            eventType = eventType + 'Barred Customer';
                            break;
                        case 219:
                            eventType = eventType + 'Machine Damage';
                            break;
                        case 220:
                            eventType = eventType + 'Machine Fraud';
                            break;
                        case 221:
                            eventType = eventType + 'Suspicious';
                            break;
                        case 222:
                            eventType = eventType + 'Theft';
                            break;
                        case 223:
                            eventType = eventType + 'Venue Damage';
                            break;
                        case 224:
                            eventType = eventType + 'Verbal Abuse';
                            break;
                    }
                }

                if (tagId === 120) {
                    policeCalled = 'Yes';
                }


            })
            returnRow['Recorded'] = moment(res.Recorded, 'YYYY-MM-DDTHH:mm:ssZ').format('YYYY-MM-DD HH:mm');;
            returnRow['eventType'] = eventType;
            returnRow['policeCalled'] = policeCalled;
            this.GetSiteName(res.SiteId).then(value => {
                returnRow['SiteId'] = value;
            })
            this.isLoading = false;
        })

        this.Rows.push(returnRow);
    }

    GetSiteName(siteid): Promise<string> {
        if (this.siteNamePromises.some(i => i.SiteId === siteid)) {
            return this.siteNamePromises.find(i => i.SiteId === siteid).Promise;
        } else {
            return new Promise<string>(resolve => {
                this.asrv.GetSiteNameCollection([siteid]).then(value => {
                    if (value) {
                        resolve(value[0].Name);
                    } else {
                        resolve('');
                    }
                })
            })
        }
    }
}

class SiteNamePromise {
    SiteId: number;
    Promise: Promise<string>;
}
