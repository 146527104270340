<div class="row">
    <!--who is receiving the email-->
    <div *ngIf="!SendingToEveryone" class="col-md-6">
        <div class="input-group" id="inputTo">
            <div class="input-group-prepend">
                <span class="input-group-text">Organisation</span>
            </div>
            <select class="form-control drop-down-purple" name="user" [(ngModel)]="msrv.OrganisationIdFilter" (change)="msrv.UpdateOrganisationFilter();">
                <option></option>
                <option *ngFor="let organisation of msrv.AvailableOrganisations" [ngValue]="organisation.ActorId">{{organisation.Name}}</option>    
            </select>
        </div>    
    </div>
    <div *ngIf="!SendingToEveryone" class="col-md-6">
        <div class="input-group" id="inputTo">
            <div class="input-group-prepend">
                <span class="input-group-text">User</span>
            </div>
            <select class="form-control drop-down-purple" name="user" [(ngModel)]="selectedActor" (change)="AddSendTo()">
                <option></option>
                <option *ngFor="let contact of msrv.AvailableContacts" [ngValue]="contact">{{contact.Value}}</option>    
            </select>
        </div>  
    </div>
    <div class="col-md-6">
        <div class="flex-check-box">
            <div class="flex-check-prepend">Send To Everyone</div>
            <input id="chkTakeABreak" type="checkbox" class="flex-check-box-control" [(ngModel)]="SendingToEveryone" (ngModelChange)="AddEveryoneToSendTo();">
        </div> 
    </div>
</div>
<!--<app-tags id="appTags" [(dataModel)]="msrv.TagFilters" [(lookupOperator)]="tagLookupOperator" (TagAddedOrRemoved)="msrv.UpdateTagFilters();"></app-tags>-->
<div *ngIf="!SendingToEveryone" class="row" style="margin-top: 20px;">
    <div class="col-md-12">
        <div style="background-color: #223464; padding: 10px; padding-top: 0px !important; border: 2px solid #223464; border-radius: 0.5em;">
            <div class="row">
                <span style="color:white;"><b>RECIPIENTS</b></span>
            </div>
            <div class="row">
                <!--[(ngModel)]="interaction.Notes"-->
                <textarea id="ctrlNotes" style="width:100%; min-height: 50px;" [(ngModel)]="sendToNames" (keyup.backspace)="BackspaceUp($event)"></textarea>
            </div>
        </div>
    </div>
</div>