<div class="container">
    <div class="row">
        <div class="col-md-6">
            <div class="input-group" id="inputType">
                <div class="input-group-prepend">
                    <span class="input-group-text">Type</span>
                </div>
                <select class="form-control drop-down-purple" name="searchType" [(ngModel)]="searchType" (ngModelChange)="GetGoogleMap();">
                    <option [ngValue]="0">Radius</option>
                    <option [ngValue]="1">Custom</option>
                </select>
            </div>
        </div>

        <div *ngIf="(searchType === 0)" class="col-md-6">
            <div class="input-group">
                <div class="input-group-prepend">
                    <span class="input-group-text">DISTANCE (KM)</span>
                </div>
                <input style="text-align: center;" type="text" class="form-control" [(ngModel)]="esrv.CurrentExclusion.Radius" name="radius" (ngModelChange)="radiusControl.next($event)" />
            </div>
        </div>
    </div>

    <div class="row" style="margin-top:20px;">
        <div #map id="map" style="height:500px; width:100%"></div>
    </div>

    <app-site-shopping-basket [(showSpinner)]="showSpinner" style="margin-top:7.5px;padding-bottom:7.5px;"></app-site-shopping-basket>

    <div class="row" style="margin-top:20px;">
        <div class="form-group" style="text-align: right; width:100%; padding: 15px;">
            <button type="button" class="btn btn-danger" (click)="CloseForm();" style="margin-right: 10px;">
                <span>Close</span>
            </button>
            <button #update type="button" class="btn btn-success" (click)="ValidateAndSendData();">  
                <span *ngIf="showSendingSpinner" class="spinner-grow spinner-grow-sm" role="status"></span>
                Updat<span *ngIf="!showSendingSpinner">e</span><span *ngIf="showSendingSpinner">ing</span><span *ngIf="showSendingSpinner">...</span>
            </button>
        </div>
    </div>
</div>