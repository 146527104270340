import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { Views_InteractionsCompositeModel } from 'src/app/models/InteractionExclusion/Views_InteractionsCompositeModel';
import { InteractionService } from 'src/app/services/interaction.service';

@Component({
  selector: 'app-interaction-action',
  templateUrl: './interaction-action.component.html',
  styleUrls: ['./interaction-action.component.scss']
})
export class InteractionActionComponent implements OnInit {
  @ViewChild('ttp') ttp: ElementRef;

  public interaction: Views_InteractionsCompositeModel;
  @Input('interaction') get Interaction(): Views_InteractionsCompositeModel {
    return this.interaction;
  };
  @Output('interaction') interactionChange: EventEmitter<Views_InteractionsCompositeModel> = new EventEmitter();
  set Interaction(val: Views_InteractionsCompositeModel) {
    this.interaction = val;
    this.interactionChange.emit(this.interaction);
  }

  // Checkbox Values
  public readonly ActionBanned = 110;
  public readonly ActionTakeABreak = 111;
  public readonly ActionImposeTimeLimits = 112;
  public readonly ActionImposeSpendLimits = 113;
  public readonly ActionChangeCategory = 114;
  public readonly ActionMonitorBehaviour = 115;
  public readonly ActionSignpostToGamcare = 116;
  public readonly ActionStayInControlLeaflet = 117;
  public readonly ActionGambleManagementApp = 118;
  public readonly ActionIssuedPlayDiary = 119;
  public readonly ActionTimeOut = 6;

  // Control Values
  public chkTakeABreak: boolean;
  public chkGamcare: boolean;
  public chkTimeLimits: boolean;
  public chkStayInControlLeaflet: boolean;
  public chkSpendLimits: boolean;
  public chkGambleManagementApp: boolean;
  public chkChangeCategory: boolean;
  public chkIssuedPlayDiary: boolean;
  public chkMonitor: boolean;
  public chkNoActionTaken: boolean;
  public chkBarred: boolean;
  public chkTimeOut: boolean;

  constructor(public isrv: InteractionService) { }

  public tooltipToggled: boolean = false;
  public lastClickedInteger: number;

  ngOnInit() {
    if (this.interaction.TagIdCollection.some(i => i >= this.ActionBanned && i <= this.ActionIssuedPlayDiary)) {
      this.interaction.TagIdCollection.forEach(tag => {
        if (tag >= this.ActionBanned && tag <= this.ActionIssuedPlayDiary) {
          switch (tag) {
            case this.ActionBanned:
              this.chkBarred = true;
              break;
            case this.ActionTakeABreak:
              this.chkTakeABreak = true;
              break;
            case this.ActionImposeTimeLimits:
              this.chkTimeLimits = true;
              break;
            case this.ActionImposeSpendLimits:
              this.chkSpendLimits = true;
              break;
            case this.ActionChangeCategory:
              this.chkChangeCategory = true;
              break;
            case this.ActionMonitorBehaviour:
              this.chkMonitor = true;
              break;
            case this.ActionSignpostToGamcare:
              this.chkGamcare = true;
              break;
            case this.ActionStayInControlLeaflet:
              this.chkStayInControlLeaflet = true;
              break;
            case this.ActionGambleManagementApp:
              this.chkGambleManagementApp = true;
              break;
            case this.ActionIssuedPlayDiary:
              this.chkIssuedPlayDiary = true;
              break;
            case this.ActionTimeOut:
              this.chkTimeOut = true;
              break;
          }
        }
      })
    }
  }

  CheckboxClick(event, checkvalue) {
    if (event.currentTarget.checked) {
      this.AddItemToTagCollection(checkvalue);
    } else {
      this.RemoveItemFromTagCollection(checkvalue);
    }
    
    if (checkvalue === this.ActionBanned) {
      this.HideToolTip();
    }
  }

  AddItemToTagCollection(tag: number) {
    if (!this.interaction.TagIdCollection.some(i => i === tag)) {
        this.interaction.TagIdCollection.push(tag);
    }
  }

  RemoveItemFromTagCollection(tag: number ) {
    if (this.interaction.TagIdCollection.some(i => i === tag)) {
      let index = this.interaction.TagIdCollection.indexOf(tag, 0);
      if (index > -1) {
          this.interaction.TagIdCollection.splice(index, 1);
      }
    }
  }

  ToggleToolTip() {
    if (this.tooltipToggled) {
      this.ttp.nativeElement.style.visibility = 'hidden';
    } else {
      this.ttp.nativeElement.style.visibility = 'visible';
    }
    this.tooltipToggled = !this.tooltipToggled;
  }

  HideToolTip() {
    this.ttp.nativeElement.style.visibility = 'hidden';
    this.tooltipToggled = false;
  }
}
