import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { ActorService } from 'src/app/services/actor.service';
import { ExcluderService } from 'src/app/services/excluder.service';
import { BiologicalUserInfo, NonBiologicalName } from 'src/app/models/actor/Name';
import { ExcluderViewService } from 'src/app/services/excluder-view.service';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';

@Component({
  selector: 'app-reinstate',
  templateUrl: './reinstate.component.html',
  styleUrls: ['./reinstate.component.scss']
})
export class ReinstateComponent implements OnInit {

  constructor(
    public asrv: ActorService,
    public esrv: ExcluderService,
    public evsrv: ExcluderViewService
  ) { }

  public showSendingSpinner: boolean = false;
  method: string;

  // Controller Properties
  public organisationFilterId: number;
  public siteFilterId: number;
  public employeeFilterId: number;
  public sites: NonBiologicalName[] = [];
  public employees: BiologicalUserInfo[] = [];
  public loadingOrganisationDropDown: boolean;
  public loadingSiteDropDown: boolean = true;
  public loadingEmployeeDropDown: boolean = true;
  public showReferenceValidation: boolean = false;
  public referenceProvidedIsValid: boolean;
  public inputReference: string = '';

  public ctrlChkBoxOne: boolean;
  public ctrlChkBoxTwo: boolean;
  public ctrlChkBoxThree: boolean;
  public ctrlChkBoxFour: boolean;

  @ViewChild('update', { static: true }) updateElement: ElementRef;  

  events = [];
  addEvent(type: string, event: MatDatepickerInputEvent<Date>) {
    this.events.push(`${type}: ${event.value}`);
  }

  ngOnInit() {
    this.esrv.StartReinstatement();
    if (this.asrv.ElevatedMode) {
      this.asrv.PopulateSiteSelectForOrgansiationId(this.esrv.ReinstatementControllers.OrganisationId).then(value => {
        this.esrv.ReinstatementControllers.SiteId = this.asrv.SiteSelect.KeySelected;
        this.loadingSiteDropDown = false;
        this.asrv.PopulateColleagueSelectForSiteId(this.esrv.ReinstatementControllers.SiteId).then(value => {
          this.esrv.ReinstatementControllers.EmployeeId = this.asrv.ColleagueSelect.KeySelected;
          this.loadingEmployeeDropDown = false;
        })
      });
    } else {
      this.PopulateSiteDropDown();
    }
  }

  PopulateSiteDropDown() {
    this.loadingSiteDropDown = true;
    this.loadingEmployeeDropDown = true;
    this.asrv.PopulateDefaultEmployeeSitesSelect().then(value => {
      this.esrv.ReinstatementControllers.SiteId = this.asrv.SiteSelect.KeySelected;
      this.loadingSiteDropDown = false;
      this.PopulateEmployeeDropDown();
    })
  }

  PopulateEmployeeDropDown() {
    this.loadingEmployeeDropDown = true;
    this.asrv.PopulateColleagueSelectForSiteId(this.esrv.ReinstatementControllers.SiteId).then(value => {
      this.esrv.ReinstatementControllers.EmployeeId = this.asrv.ColleagueSelect.KeySelected;
      this.loadingEmployeeDropDown = false;
    })
  }

  ValidateAndSendData() {
    this.showSendingSpinner = true;
    this.esrv.ReinstatementControllers.AwareOfMonitoringControl.DomId;
    if (this.RequiresValidation()) {
      this.showSendingSpinner = false;
      alert('The Customers Provided Ref must match the Expected Ref.');
      return;
    } else {
      this.esrv.ReinstatementSave().then(value => {
        if (value) {
          this.evsrv.ShowMainView(true);
        }
        this.showSendingSpinner = false;
      }).catch(error => {
        this.showSendingSpinner = false;
      });
    }
  }


  RequiresValidation(): boolean {
    if (this.esrv.ReinstatementControllers.MethodId === 2 && this.inputReference !== this.esrv.CurrentExclusion.Reference) {
      this.referenceProvidedIsValid = false;
      this.showReferenceValidation = true;
      return true;
    } else {
      this.referenceProvidedIsValid = true;
      this.showReferenceValidation = false;
    }
    return false;
  }
  
  CloseReferenceValidation() {
    this.showReferenceValidation = false;
  }
}
