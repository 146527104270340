import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { Views_InteractionsCompositeModel } from 'src/app/models/InteractionExclusion/Views_InteractionsCompositeModel';
import { ISDDateMethods } from 'src/app/models/ISDDateMethods';
import { InteractionService } from 'src/app/services/interaction.service';
@Component({
  selector: 'app-interaction-more-info',
  templateUrl: './interaction-more-info.component.html',
  styleUrls: ['./interaction-more-info.component.scss']
})
export class InteractionMoreInfoComponent implements OnInit {

  private dateMethods: ISDDateMethods = new ISDDateMethods();
  public interactions: Views_InteractionsCompositeModel[] = [];

  constructor(public isrv: InteractionService, private router: Router) { }

  ngOnInit() {
    this.isrv.HistoricIds.forEach(id => {
      this.isrv.LoadInteraction(id).then(value => {
        var interaction: Views_InteractionsCompositeModel = new Views_InteractionsCompositeModel();
        interaction = value;
        interaction.Reasons = this.isrv.GetReasonsString(interaction);
        interaction.Actions = this.isrv.GetActionsString(interaction);
        this.interactions.push(interaction);
      })
    })
  }

  GetStartDate(interaction: Views_InteractionsCompositeModel) {
    if (interaction.InteractionMonitoringPeriod) {
      if (interaction.InteractionMonitoringPeriod.Duration === 0) {
        return '';
      }
      return interaction.InteractionMonitoringPeriod.Start;
    } else {
      return '';
    }
  }

  GetEndDate(interaction: Views_InteractionsCompositeModel) {
    if (interaction.InteractionMonitoringPeriod) {
      if (interaction.InteractionMonitoringPeriod.Duration === 0) {
        return '';
      }
      return this.dateMethods.AddDays(interaction.InteractionMonitoringPeriod.Start, interaction.InteractionMonitoringPeriod.Duration);
    } else {
      return '';
    }
  }

  ShowInteraction(interaction: Views_InteractionsCompositeModel) {
    if (!(interaction.InteractionId === this.isrv.Interaction.InteractionId)) {
      this.SetLoadingData.next();
      this.router.navigate(['/user/customer-interaction/view', interaction.InteractionId]);
      this.isrv.LoadViewModel(interaction.InteractionId, true).then(value => {
        this.ReloadMainApp.next();
      })
    }
  }

  CallCloseForm() {
    this.ShowDefaultApp.next();
  }

  DisableRow(interaction: Views_InteractionsCompositeModel) {
    return interaction.InteractionId === this.isrv.Interaction.InteractionId;
  }

  @Output() SetLoadingData = new EventEmitter<string>();
  @Output() ReloadMainApp = new EventEmitter<string>();
  @Output() ShowDefaultApp = new EventEmitter<string>();
}
