import { Time } from '@angular/common';

export class View_ExclusionBreachesCompositeModel {
    InteractionId: number = 0;
    AssociatedActorIdCollection: number[] = [];
    Notes: string;
    Reference: string;
    SiteId: number;
    TagIdCollection: number[] = [];
    Exclusions_InteractionId: number = -1;
    Breached: string;

    ControllerDate: Date;
    ControllerTime: Time;

    SetBreached() {
        this.Breached = new Date(this.ControllerDate).toISOString().slice(0, 10) + 'T' + this.ControllerTime
    }
}