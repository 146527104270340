import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';

import { IdentificationDocument } from 'src/app/models/IdentificationDocumentKey';
import { ActorService } from 'src/app/services/actor.service';
import { ExcluderService } from 'src/app/services/excluder.service';

@Component({
  selector: 'app-exclusion-declaration',
  templateUrl: './exclusion-declaration.component.html',
  styleUrls: ['./exclusion-declaration.component.scss', '../generic-form.component.scss']
})
export class ExclusionDeclarationComponent implements OnInit {
  constructor(
    public esrv: ExcluderService,
    public asrv: ActorService
  ) { }

  idDocTypes: IdentificationDocument[];
  @Input('idDocTypes') get IdDocTypes(): IdentificationDocument[]
  {
    return this.idDocTypes;
  };
  @Output('idDocTypes') idDocTypesChange: EventEmitter<IdentificationDocument[]> = new EventEmitter();
  set IdDocTypes(val: IdentificationDocument[]) {
    this.idDocTypes = val;
    this.idDocTypesChange.emit(this.idDocTypes);
  }

  public excluderImage: string;
  showHideTerms: Boolean = false;
  ShowHideTermsApp() {
    this.showHideTerms = !this.showHideTerms;
  }

  ngOnInit() {
    this.excluderImage = this.esrv.CurrentExclusion.MediaModelCollection.find(i => i.MediaClassKeyId === 4).Data;
  }

  ValidateExclusion() {
    if (!this.esrv.CurrentExclusion.ClientValues.Confirmed) {
      alert('You Must Confirm The Exclusion Before Proceeding!');
      (document.getElementById('btnProcessExclusion') as HTMLButtonElement).disabled = false;
      (document.getElementById('btnProcessExclusion') as HTMLButtonElement).style.filter = 'Grayscale(0)';
    /*
    } else if (!this.exclusion.Image_SignitureBase64) {
      alert('You Must Sign The Document Before Proceeding!')
    */
    } else {
      this.GoForward.next();
    }
  }

  @Output() GoForward = new EventEmitter<string>();
}

