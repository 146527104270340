import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActorService } from 'src/app/services/actor.service';
import { InteractionService } from 'src/app/services/interaction.service';

@Component({
  selector: 'app-interaction-privacy-notice',
  templateUrl: './interaction-privacy-notice.component.html',
  styleUrls: ['./interaction-privacy-notice.component.scss']
})
export class InteractionPrivacyNoticeComponent implements OnInit {

  constructor(public isrv: InteractionService, public asrv: ActorService) { }

  public html: string;
  public showHideInfo: boolean = false;
  public dataProtectionOfficer: any = false;

  privacyPolicyHTML: string;
  @Input('privacyPolicyHTML') get PrivacyPolicyHTML(): string {
    return this.privacyPolicyHTML;
  };
  @Output('privacyPolicyHTML') privacyPolicyHTMLChange: EventEmitter<string> = new EventEmitter();
  set PrivacyPolicyHTML(val: string) {
    this.privacyPolicyHTML = val;
    this.privacyPolicyHTMLChange.emit(this.privacyPolicyHTML);
  }

  ngOnInit() {
  }

  ShowPrivacyPolicy() {
    this.html = this.asrv.UserDocumentation.PrivacyPolicyHTML;
    this.CallShowHideForwardArrow();
    this.showHideInfo = true;
  }

  CloseForm() {
    this.CallShowHideForwardArrow();
    this.showHideInfo = false;
  }

  CallShowHideForwardArrow() {
    this.ShowHideForwardArrow.next();
  }

  @Output() ShowHideForwardArrow = new EventEmitter<string>();

}
