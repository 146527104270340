<div class="container global-seperator" style="overflow-y:auto;overflow-x:hidden;">
    <div class="container global-seperator" style="padding: 20px 0 !important;">
        <div class="row">
            <div class="col-md-10">
                <h3><b>Crime Alert</b></h3>
            </div>
            <div class="col-md-2 flex">

                <div *ngIf="isLoadingAlerts" class="live-alerts" (click)="showLiveAlerts()">
                    <div class="spinner-border" style="height: 100%;" role="status">
                        <span class="sr-only"></span>
                    </div>
                </div>
                <div *ngIf="!isLoadingAlerts" class="live-alerts" (click)="showLiveAlerts()">
                    <span>LIVE ALERTS</span>
                    <div class="alert-count">
                        {{this.dataModel.Rows?.length}}
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display': showLiveAlertModal ? 'flex' : 'none'}">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Live Crime Alerts</h4>
                    <span class="date-range">Date range: {{dateRange}}</span>
                </div>
                <div class="modal-body">
                    <table class="table table-hover table-sm table-bordered" *ngIf="!isLoadingAlerts">
                        <thead>
                            <tr>
                                <th scope="col">Date</th>
                                <th scope="col">Event Type</th>
                                <th scope="col">Police Called</th>
                                <th scope="col">Site ID</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let alert of dataModel.Rows" (click)="openAlert(alert)">
                                <td>{{alert.Recorded === false ? 'loading...' : alert.Recorded}}</td>
                                <td>{{alert.eventType === false ? 'loading...' : alert.eventType}}</td>
                                <td>{{alert.policeCalled === false ? 'loading...' : alert.policeCalled}}</td>
                                <td>{{alert.SiteId === false ? 'loading...' : alert.SiteId}}</td>

                            </tr>

                        </tbody>
                    </table>

                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger" (click)="closeLiveAlerts()">
                        Close
                    </button>
                </div>
            </div>
        </div>
    </div>

    <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display': showAlertItemModal ? 'flex' : 'none'}">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Crime Alert</h4>
                    <!-- <span class="date-range">Date range: {{dateRange}}</span> -->
                </div>
                <div class="modal-body flex centered" *ngIf="isLoadingAlertItem">
                    <div class="spinner-border" style="width: 50px; height: 50px;" role="status">
                        <span class="sr-only"></span>
                    </div>
                </div>
                <div class="modal-body" *ngIf="!isLoadingAlertItem">
                    <div class="item--wrapper">
                        <div class="item-row date">
                            <strong>Date: &nbsp;</strong>
                            <span>{{alertItem?.Recorded | date:'EEEE, d MMMM  y, h:mm:ss a'}}</span>
                        </div>
                        <div class="item-row date">
                            <strong>Event Type: &nbsp;</strong>
                            <span>{{selectedAlert?.eventType}}</span>
                        </div>
                        <div class="item-row date">
                            <strong>Police Called: &nbsp;</strong>
                            <span>{{selectedAlert?.policeCalled}}</span>
                        </div>
                        <div class="item-row date">
                            <strong>Site ID: &nbsp;</strong>
                            <span>{{selectedAlert?.SiteId}}</span>
                        </div>
                        <div class="item-row suspects">
                            <strong>Suspects: &nbsp;</strong>
                            <span *ngIf="alertItem?.suspects?.length === 0">No Suspects</span>
                            <ul class="suspect-list" *ngIf="alertItem?.suspects?.length > 0">
                                <li *ngFor="let suspect of alertItem?.suspects">
                                    <div class="bio-item">
                                        <strong>Gender: &nbsp;</strong>
                                        <span>{{suspect.gender}}</span>
                                    </div>
                                    <div class="bio-item">
                                        <strong>Hair Colour: &nbsp;</strong>
                                        <span>{{suspect.hairColour}}</span>
                                    </div>
                                    <div class="bio-item">
                                        <strong>Age: &nbsp;</strong>
                                        <span>{{suspect.age}}</span>
                                    </div>
                                    <div class="bio-item">
                                        <strong>Height: &nbsp;</strong>
                                        <span>{{suspect.height}}</span>
                                    </div>
                                </li>
                            </ul>
                        </div>

                        <div class="item-row suspects">
                            <strong>Vehicles: &nbsp;</strong>
                            <span *ngIf="alertItem?.VehicleCollection?.length === 0">No Vehicles</span>
                            <ul class="vehicle-list" *ngIf="alertItem?.VehicleCollection?.length > 0">
                                <li *ngFor="let vehicle of alertItem?.VehicleCollection">
                                    <div class="bio-item" *ngFor="let property of vehicle.VehicleProperties">
                                        <strong>Reg: &nbsp;</strong>
                                        <span>{{property.Data}}</span>
                                    </div>
                                    
                                </li>
                            </ul>
                        </div>
                        <div class="item-row notes">
                            <strong>Notes: &nbsp;</strong>
                            <span>{{alertItem?.Notes}}</span>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger" (click)="closeAlertItem()">
                        Close
                    </button>
                </div>
            </div>
        </div>
    </div>

    <ng-container *ngIf="stage === 1">
        <div class="row">
            <div class="col-md-6">
                <div id="ctrlAgeDate" class="input-group">
                    <div class="input-group-prepend">
                        <span class="input-group-text">Date</span>
                    </div>
                    <input class="form-control d-none d-lg-block" matInput [matDatepicker]="cddp1"
                        (dateInput)="addEvent('input', $event)" (dateChange)="addEvent('change', $event)"
                        [(ngModel)]="alertDateModel">
                    <div class="input-group-append time-selector d-none d-lg-block">
                        <mat-datepicker-toggle class="form-control-date-toggle d-none d-lg-block" matSuffix
                            [for]="cddp1">
                        </mat-datepicker-toggle>
                    </div>
                    <mat-datepicker class="d-none d-lg-block" touchUi #cddp1></mat-datepicker>
                    <input type="date" class="form-control date-picker-padding d-lg-none" name="ageDate"
                        [(ngModel)]="alertDateModel" (ngModelChange)="addEvent('change', $event)">
                </div>
            </div>
            <div class="col-md-6">
                <div id="ctrlAgeTime" class="input-group">
                    <div class="input-group-prepend">
                        <span class="input-group-text">Time</span>
                    </div>
                    <input type="time" class="form-control d-none d-lg-block" name="ageTime" [(ngModel)]="alertTime"
                        (ngModelChange)="setTime($event)">
                    <input type="time" class="form-control date-picker-padding d-lg-none" name="ageTime"
                        [(ngModel)]="alertTime" (ngModelChange)="setTime($event)">
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-6">
                <div class="input-group" id="inputUser">
                    <div class="input-group-prepend">
                        <span class="input-group-text">Event Type</span>
                    </div>
                    <!-- <div *ngIf="employeeListLoading" class="inline-spinner">
                    <div  class="spinner-border" style="width:40px; height: 40px;" role="status">
                        <span class="sr-only"></span>
                    </div>
                </div> -->

                    <select class="form-control drop-down-purple" name="user" [(ngModel)]="eventTypeID">
                        <option></option>
                        <option *ngFor="let dropDownItem of eventTypeList" [ngValue]="dropDownItem['TagId']">
                            {{dropDownItem['Name']}}</option>
                    </select>
                </div>
            </div>
            <div class="col-md-12">
                <div class="input-group" id="inputEquipment">
                    <div class="input-group-prepend">
                        <span class="input-group-text">Equipment Targeted</span>
                    </div>
                    <div *ngIf="isSearching" class="inline-spinner">
                        <div class="spinner-border" style="width:40px; height: 40px;" role="status">
                            <span class="sr-only"></span>
                        </div>
                    </div>
                    <div class="equipment-targeted">
                        <input #equipmentSearch type="text" [style.display]="isSearching ? 'none' : 'flex'"
                            class="form-control color-bacta-standard" name="equipmentTargeted"
                            [(ngModel)]="equipmentTargeted">
                        <div class="no-results-found" *ngIf="showSearchResults && searchResults?.length === 0">
                            <div>No results found</div>
                            <button class="btn btn-success" (click)="addNewTag(equipmentTargeted)"
                                *ngIf="!isSavingTag && textSearch"> + Add to Equipment Catalog </button>
                            <button class="btn btn-success" *ngIf="isSavingTag"> Saving Tag... </button>
                        </div>
                    </div>

                    <div class="search-results" *ngIf="showSearchResults && searchResults?.length > 0">
                        <div class="search-results--header">
                            <div class="text">Results</div>
                            <div class="close" (click)="closeSearchResults()">X</div>
                        </div>
                        <div class="search-results--body">
                            <div class="results--list">
                                <div class="result--item {{item['selected'] ? 'selected' : ''}}"
                                    *ngFor="let item of searchResults" (click)="selectSearchTag(item)">
                                    <div>{{item.MetaData.Name}}</div>
                                    <div class="remove-tag" *ngIf="item['selected']">X</div>
                                </div>
                            </div>
                            <!-- This is for adding a new tag that doesn't exist -->
                            <!-- <div class="results--list" *ngIf="searchResults?.length === 0 && textSearch.length > 0">
                                <div class="no-results">No results found. You can add a new item to the collection by clicking on it:</div>
                                <div class="result--item no-hover">
                                    
                                    
                                </div>
                            </div> -->
                        </div>
                    </div>
                </div>
                <div class="search-tags--list">
                    <div class="search-tag--item" *ngFor="let tag of searchTagCollection">
                        <span>{{tag.MetaData.Name}}</span>
                        <img (click)="removeTag(tag)" class="tag-close--icon"
                            src="../../../assets/icons/Bacta Assets/Close.svg">
                    </div>
                </div>
            </div>

        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="flex-check-box">
                    <div class="flex-check-prepend">Police Called?</div>
                    <div class="checkbox-label">
                        <span>Yes</span>
                        <input id="chkTime" type="checkbox" class="flex-check-box-control"
                            [(ngModel)]="policeCalledTrue" (click)="policeCalled(true)">
                    </div>
                    <div class="checkbox-label">
                        <span>No</span>
                        <input id="chkTime" type="checkbox" class="flex-check-box-control"
                            [(ngModel)]="policeCalledFalse" (click)="policeCalled(false)">
                    </div>

                </div>
            </div>
        </div>


        <div class="row" style="min-height:200px; padding-top: 20px;">
            <div class="col-md-12">
                <span>Please add any notes but avoid using any specific personal identifiers as this may be in
                    contravention of GDPR</span>
                <div
                    style="background-color: #223464; padding: 10px; padding-top: 0px !important; border: 2px solid #223464; border-radius: 0.5em;">
                    <div class="row">
                        <span style="color:white;"><b>Notes</b></span>
                    </div>
                    <div class="row">
                        <textarea id="ctrlNotes"
                            style="width:100%; height: 150px;  padding-left:5px; padding-right: 5px;"
                            [(ngModel)]="notes"></textarea>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="stage === 2">
        <h4 *ngIf="suspectList?.length > 0">Suspects List</h4>
        <div class="suspect-row" *ngFor="let suspect of suspectList; index as suspectIndex">
            <div class="row--header">
                <span>SUSPECT {{suspectIndex+1}}</span>
                <div class="delete--icon" (click)="deleteSuspect(suspectIndex)">
                    <button class="btn btn-danger"><img src="../../../assets/icons/trash3.svg"></button>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6">
                    <div class="input-group" id="inputGender">
                        <div class="input-group-prepend">
                            <span class="input-group-text">Gender</span>
                        </div>
                        <!-- <div *ngIf="genderListLoading" class="inline-spinner">
                        <div class="spinner-border" style="width:40px; height: 40px;" role="status">
                            <span class="sr-only"></span>
                        </div>
                    </div> -->
                        <select class="form-control drop-down-purple" name="gender" [(ngModel)]="suspect.genderId">
                            <option></option>
                            <option *ngFor="let gender of genderList" [ngValue]="gender.GenderId">{{gender.Name}}
                            </option>
                        </select>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="input-group" id="inputTitle">
                        <div class="input-group-prepend">
                            <span class="input-group-text">Age</span>
                        </div>
                        <select class="form-control drop-down-purple color-bacta-standard" name="age"
                            [(ngModel)]="suspect.age">
                            <option *ngFor="let age of isrv.AgeOptions" [ngValue]="age.Age">{{age.Age}}</option>
                        </select>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="input-group" id="inputGender">
                        <div class="input-group-prepend">
                            <span class="input-group-text">Hair Colour</span>
                        </div>
                        <select class="form-control drop-down-purple color-bacta-standard" name="haircolour"
                            [(ngModel)]="suspect.hairColour">
                            <option *ngFor="let hairColour of isrv.HairColourOptions" [ngValue]="hairColour.HairColour">
                                {{hairColour.HairColour}}</option>
                        </select>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="input-group" id="inputGender">
                        <div class="input-group-prepend">
                            <span class="input-group-text">Height</span>
                        </div>
                        <select class="form-control drop-down-purple color-bacta-standard" name="height"
                            [(ngModel)]="suspect.height">
                            <option *ngFor="let height of isrv.HeightOptions" [ngValue]="height.Height">
                                {{height.Height}}
                            </option>
                        </select>
                    </div>
                </div>

                <!-- <div class="col-md-12">
                    <div class="input-group" id="inputEquipment">
                        <div class="input-group-prepend">
                            <span class="input-group-text">Vehicle Reg (if applicable)</span>
                        </div>
                        <input type="text" class="form-control color-bacta-standard" name="vehicleReg"
                            [(ngModel)]="suspect.vehicleReg">
                    </div>
                </div> -->
            </div>
        </div>
        <button class="btn btn-success add-suspect" (click)="addSuspect()">Add Suspect</button>
        <br>
        <h4 style="margin-top: 40px;" *ngIf="vehicleList?.length > 0">Vehicles List</h4>
        <div class="suspect-row" *ngFor="let vehicle of vehicleList; index as vehicleIndex">
            <div class="row--header">
                <span>VEHICLE {{vehicleIndex+1}}</span>
                <div class="delete--icon" (click)="deleteVehicle(vehicleIndex)">
                    <button class="btn btn-danger"><img src="../../../assets/icons/trash3.svg"></button>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="input-group" id="inputEquipment">
                        <div class="input-group-prepend">
                            <span class="input-group-text">Vehicle Reg</span>
                        </div>
                        <input type="text" class="form-control color-bacta-standard" name="vehicleReg"
                            [(ngModel)]="vehicle.vehicleReg">
                    </div>
                </div>
            </div>
        </div>
        <button class="btn btn-success add-suspect" (click)="addVehicle()">Add Vehicle</button>
    </ng-container>

    <div class="form-footer">
        <div class="navigation {{stage === 2 ? 'col-3' : ''}}" (click)="stage === 1 ? GoBack() : stage = 1">
            <span><input type="image" class="stage-button" src=".\assets\icons\Bacta Assets\Back button blue.svg"
                    style="width:100px" /></span>
        </div>

        <!-- 
        <div *ngIf="stage===2" class="navigation-warning" style="text-align: center; vertical-align:middle; height: 100%;">
            <input id="btnStartSelfExclusion" class="bacta-button" type="image" src=".\assets\icons\Bacta Assets\Start Self-exclusion orange.svg" alt="Settings" (click)="StartSelfExclusion();" />
        </div>   -->
        <div class="footer-info {{stage === 2 ? 'col-6' : ''}}" *ngIf="stage === 2">
            <div><strong>If no suspect, please press SAVE INTERACTION</strong></div>
            <div>Once you press Save, an email will be sent to other Operators within the agreed area</div>
        </div>
        <div *ngIf="stage === 1" class="navigation save" (click)="nextStage()">
            <span><input type="image" class="stage-button" src=".\assets\icons\Bacta Assets\Next button blue.svg"
                    style="width:100px" /></span>
        </div>
        
        <div *ngIf="stage === 2 && suspectList?.length >= 0" class="navigation save {{stage === 2 ? 'col-1' : ''}}"
            (click)="saveCrimeAlert()">
            <span><input type="image" class="stage-button" src=".\assets\icons\Bacta Assets\Save Interaction green.svg"
                    style="width:100px" /></span>
        </div>
    </div>
</div>

<div class="notification-dialog--overlay" *ngIf="isSaving">
    <div class="saving-dialog--spinner">
        <div class="dialog-spinner">
            <div class="text">
                Saving...
            </div>
            <div class="spinner-border" style="width:100px; height: 100px;" role="status">
                <span class="sr-only"></span>
            </div>
        </div>
    </div>
</div>

<div class="notification-dialog--overlay" *ngIf="isSavedSuccess">
    <div class="message-wrapper">
        <div class="message-wrapper--header">
            <div class="text">Success</div>
            <div class="close">X</div>
        </div>
        <div class="message-wrapper--body">
            Saved Crime Alert Report
        </div>
    </div>
</div>

<div class="notification-dialog--overlay" *ngIf="isError">
    <div class="message-wrapper error">
        <div class="message-wrapper--header error">
            <div class="text">Error</div>
            <div class="close" (click)="closeNotification()">X</div>
        </div>
        <div class="message-wrapper--body">
            There was an error saving the crime alert. Please check all fields and try again.
            <br>
            {{errorMsg | json}}
        </div>
    </div>
</div>

<div class="notification-dialog--overlay" *ngIf="isMissingFields">
    <div class="message-wrapper error">
        <div class="message-wrapper--header error">
            <div class="text">Error</div>
            <div class="close" (click)="isMissingFields = false">X</div>
        </div>
        <div class="message-wrapper--body">
            The following required fields are missing:
            <br>
            <ul>
                <li *ngFor="let item of missingFields">{{item}}</li>
            </ul>
        </div>
    </div>
</div>