<div class="container" *ngIf="!showHideInfo">
    <div class="row">
        <div class="col-md-12 padding-top padding-bottom">
            <h3><b>PRIVACY NOTICE</b></h3>
        </div>
    </div>

    <div class="row draw-lines flex-control">
        <div class="col-5 col-sm-3"><span class="large-text">WHO?</span></div> 
        <div class="col-7 col-sm-9 flex-center">
            <div *ngIf="!asrv.UserOrganisationName" class="spinner-border" role="status">
                <span class="sr-only"></span>
            </div>  
            <span *ngIf="asrv.UserOrganisationName">{{asrv.UserOrganisationName}}</span>
             is a "controller" in relation to your personal data.
        </div>
    </div> 

    <div class="row draw-lines flex-control">
        <div class="col-5 col-sm-3"><span class="large-text">WHAT?</span></div>
        <div class="col-7 col-sm-9 flex-center">We collect personal data that you provide us with and which we generate ourselves.</div>
    </div> 

    <div class="row draw-lines flex-control">
        <div class="col-5 col-sm-3"><span class="large-text">WHY?</span></div>
        <div class="col-7 col-sm-9 flex-center">To interact with our customers, as required by the Gambling Commission and to provide Self-Exclusion Services, if agreed with you. To provide self-exclusion services, we share your data with other operators, self-exclusion scheme providers and our trade association.</div>
    </div> 

    <div class="row draw-lines flex-control">
        <div class="col-5 col-sm-3"><span class="large-text">HOW?</span></div>
        <div class="col-7 col-sm-9 flex-center">If you agree to our terms, our lawful basis for using your data is we have a Legitimate Interest in processing and distributing your personal data. The data we collect from you for the purpose of excluding you from and Adult Gaming Centre is deemed to be Special Category data as detailed in Article 9 UK GDPR. The lawful basis that we process this data is that it is necessary for reasons of Substantial Public Interest</div>
    </div>
    
    <div class="row draw-lines flex-control" style="border-bottom: 2px solid #5e698e;">
        <div class="col-5 col-sm-3"><span class="large-text">WHEN?</span></div>
        <div class="col-7 col-sm-9 flex-center">We will keep your personal data for a maximum period of 12 months plus 24 hours following on from the end of your exclusion period. It will then be deleted. Following the processing of a customer interaction by an operator, we will keep your personal data for a maximum period of 12 months plus 24 hours following the archiving of your customer interaction by the operator. It will then be deleted.</div>
    </div> 

    <div class="row padding-top">
        <div class="col-12">
            Please note that you have the following rights in relation to your personal data:
        </div>
    </div>

    <div class="row padding-top">
        <div class="col-12">
            Access to a copy of your data; objection to legitimate interest as a basis for processing; withdrawal of consent (if our use is based on consent); request not to use data for marketing purposes; correction of inaccurate data; restriction on processing data; and receipt of data in a machine-readable format.
        </div>
    </div>

    <div class="row padding-top">
        <div class="col-12">
            More info can be found on the Information Commissioner's Office (ICO) website. If you have concerns about the use of your data, please contact us. You can also lodge a complaint with the ICO at any time.
        </div>
    </div>

    <div class="row padding-top">
        <div class="col-12">
            Our full Privacy Policy can be found <span (click)="ShowPrivacyPolicy();" style="height: 100%"><b><u>HERE</u></b></span> and will be sent to your email address.
        </div>
    </div>

    <div class="col-md-6 padding-top">
        <div class="flex-check-box">
            <div class="flex-check-prepend" style="font-size: 16px;padding-left:0;color:#5e698e;">I HAVE READ THE PRIVACY NOTICE</div>
            <input id="chkSelfExclude" type="checkbox" class="flex-check-box-control" [(ngModel)]="isrv.ReadPrivacyNotice" >
        </div>           
    </div>  
</div>

<div class="container" *ngIf="showHideInfo">
    <app-customer-acknowledgement [(html)]="html" (CloseForm)="CloseForm();"></app-customer-acknowledgement>
</div>