import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { NonBiologicalName } from 'src/app/models/actor/Name';
import { Maps } from 'src/app/models/google-maps/maps'
import { Site } from 'src/app/models/Site';
import { ActorService } from 'src/app/services/actor.service';
import { ExcluderViewService } from 'src/app/services/excluder-view.service';
import { ExcluderService } from 'src/app/services/excluder.service';

@Component({
  selector: 'app-update-exclusion',
  templateUrl: './update-exclusion.component.html',
  styleUrls: ['./update-exclusion.component.scss']
})
export class UpdateExclusionComponent implements OnInit, AfterViewInit {
  @ViewChild('map') mapElement: ElementRef;
  private map: Maps = new Maps(this.esrv, this.asrv);
  public exclusionSitePostcode: string;
  public radius = new Subject<string>();
  public mainSite: Site = new Site();
  public sitesToDisplay: Site[];
  public zoom: number = 10;
  public showSendingSpinner: boolean;
  showSpinner: boolean;
  public radiusControl = new Subject<string>();
  public originalRadius;
  public searchType: number = 0;
  /*
    0 = Circle
    1 = Polygon
  */

 sites: Site[];
 @Input('sites') get Sites(): Site[] {
   return this.sites;
 };
 @Output('sites') sitesChange: EventEmitter<Site[]> = new EventEmitter();
 set Sites(val: Site[]) {
   this.sites = val;
   this.sitesChange.emit(this.sites);
 }

  constructor(
    public esrv: ExcluderService, 
    public evsrv: ExcluderViewService,
    public asrv: ActorService) {
    this.radiusControl.pipe(
      debounceTime(400),
      distinctUntilChanged())
      .subscribe(value => {
        if (this.esrv.CurrentExclusion.Radius) {
          if (this.esrv.CurrentExclusion.Radius > 0)  {
            this.GetSiteList();
          }
        }
      }
    );
  }

  ngOnInit() {
    if (this.esrv.CurrentExclusion.Radius) {
      this.originalRadius = this.esrv.CurrentExclusion.Radius;
    }
    this.map.Initialise()
    this.GetExclusionSiteInfo().then(value => {
      this.GetSiteList();
    });
  }

  ngAfterViewInit() {
  }

  GetExclusionSiteInfo(): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      let subscription = this.asrv.GetSiteDetails(this.esrv.CurrentExclusion.SiteId).subscribe(res => {
        subscription.unsubscribe();
        this.mainSite.ActorId = res.ActorId;
        this.mainSite.Latitude = res.Latitude;
        this.mainSite.Longitude = res.Longitude;
        resolve(true);
      });
    });
  }

  GetExclusionSitePostCode(): Promise<string> {
    return new Promise<string>(resolve => {
      let subscription = this.asrv.GetSiteDetails(this.esrv.CurrentExclusion.SiteId).subscribe(res => {
        subscription.unsubscribe();
        this.exclusionSitePostcode = res.Postcode
      }, err => {
        subscription.unsubscribe();
        this.exclusionSitePostcode = null;
      })
    })
  }

  GetSiteList() {
    this.esrv.UpdateSitesWithinRadius().then(value => {
      this.sitesToDisplay = value;
      this.GetGoogleMap();
    });
  }

  GetGoogleMap() {
    this.map.SiteMarkers = this.sites;
    this.esrv.CurrentExclusion.ClientValues.SiteCollection = [];
    if (this.searchType === 0) {
      this.ShowSitesWithinCircleRadius();
    } else {
      this.LoadInitialPolygon().then(value => {
      })
    }
  }

  SetDefaultZoom(): number {
    var x = +this.esrv.CurrentExclusion.Radius;
    switch (true) {
      case x === 1:
        return 14;
      case x === 2 || x === 3:
        return 13;
      case x >= 4 && x <= 6:
        return 12;
      case (x >= 7) && (x <= 12):
        return 11;
      case (x >= 13) && (x <= 24):
        return 10;
      case (x >= 25) && (x <= 48):
        return 9;
      case (x >= 49) && (x <= 96):
        return 8;
      default:
        return 7;
    }
  }

  ShowSitesWithinCircleRadius() {
    this.esrv.CurrentExclusion.SiteIdCollection = [];
    this.esrv.CurrentExclusion.ClientValues.SiteCollection = [];
    this.mapElement.nativeElement = this.map.GenerateMapWithCircle(this.mainSite.Latitude, this.mainSite.Longitude, this.SetDefaultZoom(), this.esrv.CurrentExclusion.Radius);
    this.SortSiteNameAscending();
  }

  SortSiteNameAscending(){
    this.esrv.CurrentExclusion.ClientValues.SiteCollection.sort((a, b) => {
      return a.Name.localeCompare(b.Name);
    }); 
  }

  LoadInitialPolygon(): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      this.mapElement.nativeElement = this.map.GenerateMapWithCustomDrawing(this.mainSite.Latitude, this.mainSite.Longitude, 11);
      resolve(true);
    })
  }

  ValidateAndSendData() {
    this.showSendingSpinner = true;
    this.esrv.CurrentExclusion.SiteIdCollection = this.esrv.CurrentExclusion.ClientValues.SiteCollection.map(function (el) { return el.ActorId; });
    if (this.searchType === 1) {
      this.esrv.CurrentExclusion.Radius = 0;
    }

    this.esrv.SaveExclusion().then(value => {
      this.showSendingSpinner = false;
      if (value) {
        this.evsrv.ShowMainView(true);
      }
    })
  }

  CloseForm() {
    this.esrv.CurrentExclusion.Radius = this.originalRadius;
    this.evsrv.ShowMainView(false);
  }
}
