import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ExcluderService } from 'src/app/services/excluder.service';

@Component({
  selector: 'app-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.scss']
})
export class EmailComponent implements OnInit {
  constructor(
    public esrv: ExcluderService
  ) { }

  ngOnInit() {
  }

  Confirm() {
    let mailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (this.esrv.CurrentExclusion.InteractedActorCompositeModel.BiologicalActorCompositeModel.Email == null || !this.esrv.CurrentExclusion.InteractedActorCompositeModel.BiologicalActorCompositeModel.Email.match(mailFormat)) {
      alert('Please enter a valid email address.');
    } else {
      this.Close()
    }
  }

  Close() {
    this.ShowEmailApp.next();
  }

  @Output() ShowEmailApp = new EventEmitter<String>();

}