import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ExcluderBrief } from 'src/app/models/ExcluderBrief';
import { ISDDateMethods } from 'src/app/models/ISDDateMethods';
import { ActorService } from 'src/app/services/actor.service';
import { ExcluderGalleryService } from 'src/app/services/excluder-gallery.service';

@Component({
  selector: 'app-excluder-card',
  templateUrl: './excluder-card.component.html',
  styleUrls: ['./excluder-card.component.scss']
})
export class ExcluderCardComponent implements OnInit {

  public showImageSpinner: boolean;

  private dateMethods: ISDDateMethods = new ISDDateMethods();
  public excluder: ExcluderBrief;
  @Input('excluder') get Excluder(): ExcluderBrief {
    return this.excluder;
  };
  @Output('excluder') excluderChange: EventEmitter<ExcluderBrief> = new EventEmitter();
  set Excluder(val: ExcluderBrief) {
    this.excluder = val;
    this.excluderChange.emit(this.excluder);
  }

  constructor(public gsrv: ExcluderGalleryService, public asrv: ActorService) { }

  ngOnInit() {
    this.showImageSpinner = true;
    this.asrv.GetMediaForInteraction(this.Excluder.InteractionId).then(value => {
      this.excluder.Image = value.Data;
      this.excluder.MediaId = value.MediaId;
      const _interaction = this.gsrv.ExcluderBriefsToShow.find(i => i.InteractionId === this.excluder.InteractionId);
      if (_interaction) {
        _interaction.Image = value.Data
      }
      // this.gsrv.ExcluderBriefsToShow.find(i => i.InteractionId === this.excluder.InteractionId).Image = value.Data;
      this.showImageSpinner = false;
    });
  }

  GetExcluderColour(excluder: ExcluderBrief): string {
    let diffDaysStart = this.dateMethods.DateDifferenceInDays(excluder.Start)
    let diffDaysEnd = this.dateMethods.DateDifferenceInDays(this.dateMethods.AddDays(excluder.Start, excluder.PeriodDays));
    if (this.ExcluderIsCoolingOff(excluder)) {
      return this.gsrv.Settings.Colors.Blue;
    } else if (diffDaysStart <= 5)
      return this.gsrv.Settings.Colors.Red;
    else if (diffDaysEnd >= 0)
      return this.gsrv.Settings.Colors.Green;
    else return this.gsrv.Settings.Colors.Yellow;
  }

  GetExtensionInMonths(excluder: ExcluderBrief) {
    // console.log('*******', excluder);
    return this.dateMethods.GetMonthDifference(excluder.Extensions.Start, excluder.Extensions.Duration);
  }

  GetEndDate(excluder: ExcluderBrief): Date {
    let startDate: Date = new Date(excluder.Start);
    return new Date(this.dateMethods.AddDays(excluder.Start, excluder.PeriodDays));
  }

  ExcluderIsCoolingOff(excluder: ExcluderBrief) {
    var diff = this.dateMethods.DateDifferenceInDays(excluder.Reinstatement.Accepted);
    if (diff === 0) {
      return true;
    }
    return false;
  }

  ExcluderRecentlyBreached(excluder: ExcluderBrief): Boolean {
    if (excluder.ExclusionBreachesCollection.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  ViewExcluder(ex: any) {
    
  }
}
