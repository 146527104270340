import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss']
})
export class DocumentsComponent implements OnInit {
  documentNotFound = false;
  document = '';
  isLoading = true;
  constructor(
    private _api: ApiService,
    private _route: ActivatedRoute
  ) {
    const _docID = this._route.snapshot.params['id'];
    this._api.getPublicDocument(_docID).subscribe(results => {
      this.isLoading = true;
      const _docVersion = results.reverse()[0];
      this._api.getPublicDocumentVersion(_docID, _docVersion).subscribe(document => {
        this.document = document;
        // console.log(this.document);
        this.isLoading = false;
      });
    },
      error => {
        error.status === 404 ? this.documentNotFound = true : this.documentNotFound = false;
        this.isLoading = false;
      }
    );
  }

  ngOnInit() {
  }

}
