import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Site } from '../models/Site';
import { ActorService } from './actor.service';
import { ExcluderService } from './excluder.service';

@Injectable({
  providedIn: 'root'
})
export class ExcluderViewService {

  // What the user can see
  public CurrentForm: number = 0;
  public readonly AppNone: number = 0
  public readonly AppExtend: number = 1
  public readonly AppRecordBreach: number = 2;
  public readonly AppReinstate: number = 3;
  public readonly AppHTML: number = 4;
  public readonly AppUpdateExclusion: number = 5;
  public UserIsAdmin: Boolean;
  public ShowCard: boolean;
  public ShowButtons: boolean;
  public SiteGeoLocations: Site[] = []; 
  public get CanReinstate(): boolean {
    return this.esrv.EligibleToReinstate()
  }
  public ShowLoadingSpinner: boolean;

  constructor(
    public esrv: ExcluderService,
    public asrv: ActorService, 
    private router: Router,
  ) { }

  public Initialize(InteractionId: number): Promise<boolean> {
    this.ShowLoadingSpinner = true;
    this.ShowCard = false;
    this.ShowButtons = false;
    this.UserIsAdmin = false;

    // End of First Method
    let sitesLoaded: boolean = false;

    // End of Second Method
    let siteNameCollectionLoaded: boolean = false;

    // End of Third Method
    let adminLoaded: boolean = false;

    // Once both methods have loaded return true.
    // Both methods must return a value, so this will always return. It returns true to force a reload with the data.

    return new Promise<boolean>(resolve=> {
      this.esrv.LoadExclusion(InteractionId).then(value => {
        this.esrv.GetCurrentExcluderImage().then(value => {
          this.asrv.GetSiteNameCollection([this.esrv.CurrentExclusion.SiteId]).then(value => {
            siteNameCollectionLoaded = true;
            this.esrv.CurrentExclusion.ClientValues.ExclusionSiteName = value[0].Name;
            if (sitesLoaded && adminLoaded) {
              this.ShowAll();
              resolve(true);
            }
          })
          this.esrv.GetSitesUsingRadius(1000).then(value => {
            sitesLoaded =true;
            this.SiteGeoLocations = value;
            if (siteNameCollectionLoaded && adminLoaded) {
              this.ShowAll();
              resolve(true);
            }
          });
        })
      }) 

      this.asrv.IsLoggedUserAdmin().then(value => {
        adminLoaded = true;
        if (value) {
          this.UserIsAdmin = true;
        }
        if (siteNameCollectionLoaded && sitesLoaded) {
          this.ShowAll();
          resolve(true);
        }
      })
    }) 
  }

  public ShowAll() {
    this.ShowButtons = true;
    this.ShowCard = true;
    this.ShowLoadingSpinner = false;
  }

  public UpdateExcluderDetailsClick() {
    this.router.navigate(['/user/exclusion/update/', this.esrv.CurrentExclusion.InteractionId]);
  }

  public ExtendClick() {
    this.ShowButtons = false;
    this.CurrentForm = this.AppExtend;
  }

  public RecordBreachClick() {
    this.ShowButtons = false;
    this.CurrentForm = this.AppRecordBreach;
  }

  public ReinstateClick() {
    this.ShowButtons = false;
    this.CurrentForm = this.AppReinstate;
  }

  public MoreInfoClick() {
    this.ShowButtons = false;
    this.CurrentForm = this.AppHTML;
  } 

  public UpdateExclusionDetailsClick() {
    this.ShowCard = false;
    this.ShowButtons = false;
    this.CurrentForm = this.AppUpdateExclusion;
  }

  ShowMainView(reload: boolean): Promise<boolean> {
    this.CurrentForm = this.AppNone;
    if (reload) {
      this.ShowLoadingSpinner = true;
      return new Promise<boolean>(resolve => {
        this.Initialize(this.esrv.CurrentExclusion.InteractionId).then(value => {
          this.ShowAll();
          resolve(value);
        });
      })
    } else {
      return new Promise<boolean>(resolve => {
        this.ShowAll();
        resolve(true)
      });
    }
  }

  EnableDisableFormControls() {
    
  }
}
