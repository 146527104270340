import { Component, OnInit, Output, EventEmitter } from '@angular/core';

import { ActorService } from 'src/app/services/actor.service';
import { ISDDateMethods } from 'src/app/models/ISDDateMethods';
import { ExcluderService } from 'src/app/services/excluder.service';
import { BiologicalUserInfo, NonBiologicalName } from 'src/app/models/actor/Name';
import { Views_MediaModel } from 'src/app/models/InteractionExclusion/Views_MediaModel';
import { ExcluderViewService } from 'src/app/services/excluder-view.service';

@Component({
  selector: 'app-extend',
  templateUrl: './extend.component.html',
  styleUrls: ['./extend.component.scss', '../view.component.scss']
})
export class ExtendComponent implements OnInit {
  emailVerification;
  constructor(
    public asrv: ActorService,
    public esrv: ExcluderService,
    public evsrv: ExcluderViewService
  ) { }

  public showSendingSpinner: boolean = false;
  public durationPeriods: number[] = [6, 7, 8, 9, 10, 11, 12]
  public selectedDurationIndex: number = 6;
  public newStartDate: Date;
  public newStartDateString: string;
  public showPhotoApp: boolean = false;
  public associatedActorId: number;
  private dateMethods: ISDDateMethods = new ISDDateMethods();

  public startDate: Date;

  public updateImage: boolean;
  public mediaModel: Views_MediaModel[] = [];

  public loadingSiteDropDown: boolean;
  public siteId: number;
  public siteDropDown: NonBiologicalName[] = [];
  public loadingEmployeeDropDown: boolean;
  public employeeId: number;
  public employeeDropDown: BiologicalUserInfo[] = [];

  ngOnInit() {
    if (this.esrv.GetCurrentExcluderEndDate() > new Date()) {
      this.newStartDateString = this.esrv.GetExtensionStartDate().toString().slice(4, 15);
    } else { 
      this.newStartDateString = new Date().toString().slice(4, 15);
    }

    this.loadingSiteDropDown = true;
    this.loadingEmployeeDropDown = true;
    this.PopulateSiteDropDown();
  }

  PopulateSiteDropDown() {
    this.loadingSiteDropDown = true;
    this.loadingEmployeeDropDown = true;
    this.asrv.PopulateDefaultEmployeeSitesSelect().then(value => {
      this.siteId = this.asrv.SiteSelect.KeySelected;
      this.loadingSiteDropDown = false;
      this.PopulateEmployeeDropDown();
    })
  }

  PopulateEmployeeDropDown() {
    this.loadingEmployeeDropDown = true;
    this.asrv.PopulateColleagueSelectForSiteId(this.siteId).then(value => {
      this.employeeId = this.asrv.GetMyUserID();
      this.loadingEmployeeDropDown = false;
    })
  }

  periodMovePrevious() {
    if (this.selectedDurationIndex <= 0) {
      this.selectedDurationIndex = 0;
    } else {
      this.selectedDurationIndex--;
    }
  }

  periodMoveNext() {
    if (this.selectedDurationIndex >= this.durationPeriods.length - 1) {
      this.selectedDurationIndex = this.durationPeriods.length - 1;
    } else {
      this.selectedDurationIndex++;
    }
  }

  ValidateAndSendData() {
    if (!this.showSendingSpinner) {
      this.showSendingSpinner = true;
      this.esrv.SaveExtension(this.durationPeriods[this.selectedDurationIndex], this.mediaModel).then(value => {
        this.showSendingSpinner = false;
        if (value) {
          this.esrv.CurrentExclusion.ClientValues.ExtensionExists = true;
          this.evsrv.ShowMainView(true);
        }
      });
    }
  }

  UpdatePhoto() {
    this.showPhotoApp = true;
  }

  ClosePhotoApp() {
    this.showPhotoApp = false;
  }

  @Output() CloseForm = new EventEmitter<string>();
}
