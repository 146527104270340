export class Site {
    ActorId: number
    Sites_ActorId: number;
    Distance: number;
    Longitude: number;
    Latitude: number;
    FromMotorwayService: boolean = false;
    Site_Name: string;

    public get Name(): string {
        return this.Site_Name;
    }
};