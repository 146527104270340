<div class="container global-seperator">
    <div class="row" style="min-height:200px;">
        <div class="col-md-12">
            <div style="background-color: #223464; padding: 10px; padding-top: 0px !important; border: 2px solid #223464; border-radius: 0.5em;">
                <div class="row">
                    <span style="color:white;">Notes</span>
                </div>
                <div class="row">
                    <textarea id="ctrlNotes" [(ngModel)]="review.Notes" style="width:100%; height: 150px;"></textarea>
                </div>
            </div>
        </div>
    </div>
</div>
