import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
// import { resolve } from 'url';
import { ActorRole } from '../models/ActorRole';
import { DropDownItem } from '../models/controllers/Standard Controllers/Select';
import { CustomerInteractionBrief } from '../models/CustomerInteractionBrief';
import { Views_InteractionsCompositeModel } from '../models/InteractionExclusion/Views_InteractionsCompositeModel';
import { ISDDateMethods } from '../models/ISDDateMethods';
import { PostObjects } from '../models/PostObjects';
import { ActorService } from './actor.service';
import { OrganisationList, SiteList } from './admin.service';
import { DataService } from './data.service';

const iconStart: number = 0;
const iconEnd: number = 31;

@Injectable({
  providedIn: 'root'
})
export class InteractionGalleryService {
  LoadingCards: boolean;
  public CustomerInteractionBriefs: CustomerInteractionBrief[] = [];
  public CustomerInteractionBriefsToShow: CustomerInteractionBrief[] = [];
  public CustomerInteractionBriefsCurrentlyVisible: CustomerInteractionBrief[] = [];

  public ActiveOrganisationFilterId: number;
  public ActiveSiteFilterId: number;
  public IsAdmin: boolean = false;
  public ActorRole: ActorRole;
  public SiteList: DropDownItem[];
  public SiteListToShow: DropDownItem[];
  public OrganisationList: DropDownItem[];
  public LoadingInteractions: boolean;

  public Limit: number = 9; //Constant for now
  public EventHandler: GalleryEventsHandler = new GalleryEventsHandler();
  public Settings: Settings = new Settings();
  public MaximumPageCount: number;

  public Filter: number = 0;
  public Sort: number = 0;
  public CurrentPage: number = 0;
  private Initializing: boolean;
  private CustomerInteractionIdsToShow: number[] = [];
  private FirstLoad: boolean;

  constructor(public dsrv: DataService, public asrv: ActorService, private dateMethods: ISDDateMethods) {
    if (sessionStorage.getItem('OrganisationFilter')) {
      this.ActiveOrganisationFilterId = +sessionStorage.getItem('OrganisationFilter');
    } else {
      this.ActiveOrganisationFilterId = 0;
    }
    if (sessionStorage.getItem('SiteFilter')) {
      this.ActiveSiteFilterId = +sessionStorage.getItem('SiteFilter');
    } else {
      this.ActiveSiteFilterId = 0;
    }
    if (sessionStorage.getItem('InteractionGalleryPageNumber')) {
      this.CurrentPage = +sessionStorage.getItem('InteractionGalleryPageNumber');
    } else {
      this.CurrentPage = 0;
    }
    this.FirstLoad = true;
  }

  public Initialize(): Promise<boolean> {
    this.CheckUserIsAdmin();
    return this.GetInteractionCards();
  }

  public GetInteractionCards(): Promise<boolean> {
    this.Initializing = true;
    this.CurrentPage = 0;
    this.CustomerInteractionBriefs = [];
    this.CustomerInteractionBriefsToShow = [];
    this.CustomerInteractionBriefsCurrentlyVisible = [];
    this.CustomerInteractionIdsToShow = [];
    return new Promise<boolean>(resolve => {
      this.GetCustomerInteractions(0).then(value => {
        this.SetIconImagesToShow();
        this.GetActiveInteractionIds();
        this.GetIncompleteInteractionIds();
        this.FilterGallery()
        this.SortGallery();
        this.MaximumPageCount = Math.ceil(this.CustomerInteractionBriefsToShow.length / this.Limit);
        this.Initializing = false;
        this.LoadingInteractions = false;
        resolve(true);
      })
    })
  }

  CheckUserIsAdmin() {
    if (!this.OrganisationList) {
      this.asrv.GetActorRole().then(value => {
        this.ActorRole = value;
        this.SiteList = [];
        this.SiteListToShow = [];
        this.OrganisationList = [];
        this.IsAdmin = this.ActorRole.ActorRoleId <= 3? true: false;
        if (this.ActorRole.ActorRoleId <= 4) {
          this.asrv.GetOrganisationIdsDBSync(0, 0).then(value => {
            this.PopulateOrganisationList(value);
          });
        } else {
          this.PopulateSiteList();
        }
      })
    } 
  }

  PopulateOrganisationList(organisationIds: number[]): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      this.asrv.GetOrganisationNameCollection(organisationIds).then(value => {
        value.forEach(object => {
          this.OrganisationList.push({Key: object.ActorId, Value: object.Name});
        })
        this.OrganisationList.sort((a, b) => {
          let x = a.Value.localeCompare(b.Value);
          return x;
        })
        this.PopulateSiteList();
        resolve(true);
      })
    })
  }

  PopulateSiteList(): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      this.asrv.GetSiteIds(0, 0, this.ActiveOrganisationFilterId).then(value => {
        value.forEach(id => {
          this.SiteListToShow.push({Key: id, Value: null});
        })
        this.asrv.GetSiteNameCollection(value).then(value => {
          this.SiteListToShow = [];
          value.forEach(siteName => {
            this.SiteListToShow.push({Key: siteName.ActorId, Value: siteName.Name});
          })
          this.SortSiteListBySiteNameAscending();
          resolve(true);
        })
      })
    });
  }

  SortSiteListBySiteNameAscending() {
    this.SiteListToShow.sort((a, b) => {
      let x = a.Value.localeCompare(b.Value);
      if (x == 0 && !this.IsAdmin) {
        return a.Value.localeCompare(b.Value);
      }
      return x;
    });
  }

  private ViewActiveInteractions(): void {
    this.CustomerInteractionIdsToShow.forEach(id => {
      this.CustomerInteractionBriefsToShow.push(this.CustomerInteractionBriefs.find(i => i.InteractionId === id));
    })
  }

  private GetCustomerInteractions(offset: number): Promise<boolean> {
    this.LoadingInteractions = true;
    return new Promise<boolean>(resolve => {
      let object = new PostObjects();

      object.Model.Start = this.dateMethods.GetEndDate(new Date(), -6);
      object.Model.End = this.dateMethods.AddDays(new Date(), 1);
      object.Model.TagIdCollection.push(this.asrv.Globals.TagValues.ArchivedInteraction);
      object.Model.TagIdCollection.push(this.asrv.Globals.TagValues.IncompleteInteraction);

      if (!this.FirstLoad) {
        if (this.IsAdmin) {
          if (this.ActiveOrganisationFilterId > 0) {
            object.Model.OrganisationParameters.OrganisationIdCollection.push(this.ActiveOrganisationFilterId);
            sessionStorage.setItem('OrganisationFilter', this.ActiveOrganisationFilterId.toString()); 
          } else {
            //object.Model.OrganisationParameters.OrganisationIdCollection.push(this.asrv.UserOrganisationId);
            sessionStorage.setItem('OrganisationFilter', "0"); 
          }
        } else {
          object.Model.OrganisationParameters.OrganisationIdCollection.push(this.asrv.UserOrganisationId);
          sessionStorage.setItem('OrganisationFilter', "0"); 
        }
  
        if (this.ActiveSiteFilterId > 0) {
          object.Model.SiteParameters.SiteIdCollection.push(this.ActiveSiteFilterId);
          sessionStorage.setItem('SiteFilter', this.ActiveSiteFilterId.toString()); 
        } else {
          sessionStorage.setItem('SiteFilter', "0"); 
        }
      } else {
        sessionStorage.setItem('OrganisationFilter', "0"); 
        sessionStorage.setItem('SiteFilter', "0"); 
        this.FirstLoad = false;
      }

      object.Model.Offset = 1000*offset;

      let interactionSubscription = this.dsrv.Post<any>("api/view_InteractionGalleryCompositeModelDBsync", object.Model).subscribe(res => {   
        interactionSubscription.unsubscribe();
        res.forEach(interactionBrief => {
          this.CustomerInteractionBriefs.push(interactionBrief);
        });
        if (res.length === 1000) {
          this.GetCustomerInteractions(offset+1).then(value => {
            resolve(true);
          });
        } else {
          resolve(true);
        }        
      }, err => {
        let returnBrief = new CustomerInteractionBrief();
        let returnBriefs = [];
        returnBrief.InteractionId = -1;
        returnBriefs.push(returnBrief);
        interactionSubscription.unsubscribe();
        resolve(false);
      });
    })
  }

  private SetIconImagesToShow(): void {
    // Temp For Loop until Database flush.
    let mediaIds: number[] = [];
    for (let i: number = 0; i < this.CustomerInteractionBriefs.length; i++) {
      if (this.CustomerInteractionBriefs[i].Media) {
        mediaIds.push(this.CustomerInteractionBriefs[i].Media.MediaId);
      }
    }
    // End

    let ids: number[] = [];
    for (let i: number = iconStart; i <= iconEnd; i++) {
      // if (!this.CustomerInteractionBriefs.some(j => j.Media.MediaId === i)) {
      if (!mediaIds.some(j => j === i)) {
        ids.push(i);
      }
    }
    localStorage.setItem('IconIdsAvailable', JSON.stringify(ids));
  }

  private GetActiveInteractionIds(): void {
    this.CustomerInteractionBriefs.forEach(interaction => {
      if (!interaction.TagIdCollection.some(i => i === this.asrv.Globals.TagValues.IncompleteInteraction)) {
        this.CustomerInteractionIdsToShow.push(interaction.InteractionId);
      }
    });
  }

  private GetIncompleteInteractionIds(): void {
    this.CustomerInteractionBriefs.forEach(interaction => {
      if (interaction.TagIdCollection.some(i => i === this.asrv.Globals.TagValues.IncompleteInteraction)) {
        this.CustomerInteractionIdsToShow.push(interaction.InteractionId);
      }
    });
  }

  private LoadPage(): void {
    this.CustomerInteractionBriefsCurrentlyVisible = [];
    let firstItem = this.CurrentPage*this.Limit;
    let lastItem = ((this.CurrentPage+1)*this.Limit);
    for (let currentItem = firstItem; currentItem < lastItem; currentItem++) {
      if (this.CustomerInteractionBriefsToShow[currentItem]) {
        this.CustomerInteractionBriefsCurrentlyVisible.push(this.CustomerInteractionBriefsToShow[currentItem])
      }
    }
  }

  public FilterGallery() {
    this.CustomerInteractionBriefsCurrentlyVisible = [];
    this.CustomerInteractionBriefsToShow = [];

    switch (this.Filter) {
      case 0: 
        this.ViewActiveInteractions();
        this.SortGallery();
        if (!this.Initializing) {this.ResetPageAndReload();}
        break;
      case 1:
        this.CustomerInteractionBriefs.forEach(interactionBrief => {
          if (interactionBrief.TagIdCollection.some(i => i === this.asrv.Globals.TagValues.InteractionActionBanned)) {
            this.CustomerInteractionBriefsToShow.push(interactionBrief);
          }
        })
        if (!this.Initializing) {this.ResetPageAndReload();}
        break;
      case 2:
        this.GetArchivedInteractionGallery().then(value => {
          this.CustomerInteractionBriefsToShow = value;
          if (!this.Initializing) {this.ResetPageAndReload();}
        })
        break;
      case 3: 
        this.CustomerInteractionBriefs.forEach(interactionBrief => {
          if (interactionBrief.TagIdCollection.some(i => i === this.asrv.Globals.TagValues.InteractionActionTimeOut)) {
            this.CustomerInteractionBriefsToShow.push(interactionBrief);
          }
        });
        if (!this.Initializing) {this.ResetPageAndReload();}
        break;
      case 4: 
      this.GetIncompleteInteractionGallery().then(value => {
        this.CustomerInteractionBriefsToShow = value;
        if (!this.Initializing) {this.ResetPageAndReload();}
      })
        break;
      case 5:
        this.GetReinstatedSelfExcludersGallery().then(value => {
          this.CustomerInteractionBriefsToShow = value;
          if (!this.Initializing) {this.ResetPageAndReload();}
        })
        break;
      case 6:
        this.GetReviewDueGallery().then(value => {
          this.CustomerInteractionBriefsToShow = value;
          if (!this.Initializing) {this.ResetPageAndReload();}
        })
        break;
    }
  }

  private GetArchivedInteractionGallery() : Promise<CustomerInteractionBrief[]> {
    return new Promise<CustomerInteractionBrief[]>(resolve => {
      let object = new PostObjects();

      // Set Dates
      // Add Filtering here.
      object.Model.Start = this.dateMethods.GetEndDate(new Date(), -6);
      object.Model.End = this.dateMethods.AddDays(new Date(), 1);
      object.Model.TagIdCollection.push(this.asrv.Globals.TagValues.ArchivedInteraction);
      object.Model.TagIdLookupOperator = 'Exists';

      if (!this.FirstLoad) {
        if (this.IsAdmin) {
          if (this.ActiveOrganisationFilterId > 0) {
            object.Model.OrganisationParameters.OrganisationIdCollection.push(this.ActiveOrganisationFilterId);
            sessionStorage.setItem('OrganisationFilter', this.ActiveOrganisationFilterId.toString()); 
          } else {
            //object.Model.OrganisationParameters.OrganisationIdCollection.push(this.asrv.UserOrganisationId);
            sessionStorage.setItem('OrganisationFilter', "0"); 
          }
        } else {
          object.Model.OrganisationParameters.OrganisationIdCollection.push(this.asrv.UserOrganisationId);
          sessionStorage.setItem('OrganisationFilter', "0"); 
        }
  
        if (this.ActiveSiteFilterId > 0) {
          object.Model.SiteParameters.SiteIdCollection.push(this.ActiveSiteFilterId);
          sessionStorage.setItem('SiteFilter', this.ActiveSiteFilterId.toString()); 
        } else {
          sessionStorage.setItem('SiteFilter', "0"); 
        }
      } else {
        sessionStorage.setItem('OrganisationFilter', "0"); 
        sessionStorage.setItem('SiteFilter', "0"); 
        this.FirstLoad = false;
      }

      let subscription = this.dsrv.Post<any>('api/view_InteractionGalleryCompositeModelDbSync', object.Model).subscribe(res => {
        subscription.unsubscribe();
        resolve(res);
      }, err => {
        let returnBrief = new CustomerInteractionBrief();
        let returnBriefs = [];
        returnBrief.InteractionId = -1;
        returnBriefs.push(returnBrief);
        subscription.unsubscribe();
        resolve(returnBriefs);
      });
    })
  }

  private GetIncompleteInteractionGallery() : Promise<CustomerInteractionBrief[]> {
    return new Promise<CustomerInteractionBrief[]>(resolve => {
      let object = new PostObjects();

      // Set Dates
      // Add Filtering here.
      object.Model.Start = this.dateMethods.GetEndDate(new Date(), -6);
      object.Model.End = this.dateMethods.AddDays(new Date(), 1);
      object.Model.TagIdCollection.push(this.asrv.Globals.TagValues.IncompleteInteraction);
      object.Model.TagIdLookupOperator = 'Exists';

      if (!this.FirstLoad) {
        if (this.IsAdmin) {
          if (this.ActiveOrganisationFilterId > 0) {
            object.Model.OrganisationParameters.OrganisationIdCollection.push(this.ActiveOrganisationFilterId);
            sessionStorage.setItem('OrganisationFilter', this.ActiveOrganisationFilterId.toString()); 
          } else {
            //object.Model.OrganisationParameters.OrganisationIdCollection.push(this.asrv.UserOrganisationId);
            sessionStorage.setItem('OrganisationFilter', "0"); 
          }
        } else {
          object.Model.OrganisationParameters.OrganisationIdCollection.push(this.asrv.UserOrganisationId);
          sessionStorage.setItem('OrganisationFilter', "0"); 
        }
  
        if (this.ActiveSiteFilterId > 0) {
          object.Model.SiteParameters.SiteIdCollection.push(this.ActiveSiteFilterId);
          sessionStorage.setItem('SiteFilter', this.ActiveSiteFilterId.toString()); 
        } else {
          sessionStorage.setItem('SiteFilter', "0"); 
        }
      } else {
        sessionStorage.setItem('OrganisationFilter', "0"); 
        sessionStorage.setItem('SiteFilter', "0"); 
        this.FirstLoad = false;
      }

      let subscription = this.dsrv.Post<any>('api/view_InteractionGalleryCompositeModelDbSync', object.Model).subscribe(res => {
        subscription.unsubscribe();
        resolve(res);
      }, err => {
        let returnBrief = new CustomerInteractionBrief();
        let returnBriefs = [];
        returnBrief.InteractionId = -1;
        returnBriefs.push(returnBrief);
        subscription.unsubscribe();
        resolve(returnBriefs);
      });
    })
  }

  private GetReinstatedSelfExcludersGallery() : Promise<CustomerInteractionBrief[]> {
    return new Promise<CustomerInteractionBrief[]>(resolve => {
      let object = new PostObjects();

      // Set Dates
      // Add Filtering here.
      object.Model.Start = this.dateMethods.GetEndDate(new Date(), -6);
      object.Model.End = new Date();
      object.Model.TagIdCollection.push(this.asrv.Globals.TagValues.ReinstatedExcluder);
      object.Model.TagIdLookupOperator = 'Exists';

      if (!this.FirstLoad) {
        if (this.IsAdmin) {
          if (this.ActiveOrganisationFilterId > 0) {
            object.Model.OrganisationParameters.OrganisationIdCollection.push(this.ActiveOrganisationFilterId);
            sessionStorage.setItem('OrganisationFilter', this.ActiveOrganisationFilterId.toString()); 
          } else {
            //object.Model.OrganisationParameters.OrganisationIdCollection.push(this.asrv.UserOrganisationId);
            sessionStorage.setItem('OrganisationFilter', "0"); 
          }
        } else {
          object.Model.OrganisationParameters.OrganisationIdCollection.push(this.asrv.UserOrganisationId);
          sessionStorage.setItem('OrganisationFilter', "0"); 
        }
  
        if (this.ActiveSiteFilterId > 0) {
          object.Model.SiteParameters.SiteIdCollection.push(this.ActiveSiteFilterId);
          sessionStorage.setItem('SiteFilter', this.ActiveSiteFilterId.toString()); 
        } else {
          sessionStorage.setItem('SiteFilter', "0"); 
        }
      } else {
        sessionStorage.setItem('OrganisationFilter', "0"); 
        sessionStorage.setItem('SiteFilter', "0"); 
        this.FirstLoad = false;
      }

      let subscription = this.dsrv.Post<any>('api/view_InteractionGalleryCompositeModelDbSync', object.Model).subscribe(res => {
        subscription.unsubscribe();
        resolve(res);
      }, err => {
        let returnBrief = new CustomerInteractionBrief();
        let returnBriefs = [];
        returnBrief.InteractionId = -1;
        returnBriefs.push(returnBrief);
        subscription.unsubscribe();
        resolve(returnBriefs);
      });
    })
  }

  private GetReviewDueGallery() : Promise<CustomerInteractionBrief[]> {
    return new Promise<CustomerInteractionBrief[]>(resolve => {
      let object = new PostObjects();

      // Set Dates
      // Add Filtering here.
      object.Model.Start = this.dateMethods.GetEndDate(new Date(), -6);
      object.Model.End = new Date();

      if (!this.FirstLoad) {
        if (this.IsAdmin) {
          if (this.ActiveOrganisationFilterId > 0) {
            object.Model.OrganisationParameters.OrganisationIdCollection.push(this.ActiveOrganisationFilterId);
            sessionStorage.setItem('OrganisationFilter', this.ActiveOrganisationFilterId.toString()); 
          } else {
            //object.Model.OrganisationParameters.OrganisationIdCollection.push(this.asrv.UserOrganisationId);
            sessionStorage.setItem('OrganisationFilter', "0"); 
          }
        } else {
          object.Model.OrganisationParameters.OrganisationIdCollection.push(this.asrv.UserOrganisationId);
          sessionStorage.setItem('OrganisationFilter', "0"); 
        }
  
        if (this.ActiveSiteFilterId > 0) {
          object.Model.SiteParameters.SiteIdCollection.push(this.ActiveSiteFilterId);
          sessionStorage.setItem('SiteFilter', this.ActiveSiteFilterId.toString()); 
        } else {
          sessionStorage.setItem('SiteFilter', "0"); 
        }
      } else {
        sessionStorage.setItem('OrganisationFilter', "0"); 
        sessionStorage.setItem('SiteFilter', "0"); 
        this.FirstLoad = false;
      }

      let subscription = this.dsrv.Post<any>('api/view_InteractionGalleryCompositeModelDbSync', object.Model).subscribe(res => {
        var returnBriefs: CustomerInteractionBrief[] = [];
        try {
          var databaseBriefs: CustomerInteractionBrief[] = res;
          databaseBriefs.forEach(customerBrief => {
            var endDate: Date = this.dateMethods.AddDays(customerBrief.Start, customerBrief.PeriodDays);
            if (endDate <= new Date() && !(customerBrief.TagIdCollection.some(i => i === this.asrv.Globals.TagValues.ArchivedInteraction))) {
              returnBriefs.push(customerBrief);
            }
          });
        } catch (error) {
          var returnBrief = new CustomerInteractionBrief();
          returnBrief.InteractionId = -1;
          returnBriefs.push(returnBrief);
        }

        subscription.unsubscribe();
        resolve(returnBriefs);
      }, err => {
        var returnBrief = new CustomerInteractionBrief();
        var returnBriefs = [];
        returnBrief.InteractionId = -1;
        returnBriefs.push(returnBrief);
        subscription.unsubscribe();
        resolve(returnBriefs);
      });
    })
  }

  ResetPageAndReload() {
    this.CurrentPage = 0;
    sessionStorage.setItem('InteractionGalleryPageNumber', this.CurrentPage.toString());
    this.MaximumPageCount = Math.ceil(this.CustomerInteractionBriefsToShow.length / this.Limit);
    this.LoadPage();
  }

  NextPage() {
    this.CurrentPage = this.CurrentPage + 1;
    sessionStorage.setItem('InteractionGalleryPageNumber', this.CurrentPage.toString());
    this.LoadPage();
  }

  PreviousPage() {
    this.CurrentPage = this.CurrentPage - 1;
    sessionStorage.setItem('InteractionGalleryPageNumber', this.CurrentPage.toString());
    this.LoadPage();
  }

  SortGallery() {
    switch (this.Sort) {
      case 0:
        this.SortByDateDescending();
        break;
      case 1:
        this.SortByDateAscending();
        break;
      case 2: 
        this.SortByNameAscending();
        break;
      case 3: 
        this.SortByNameDescending();
        break;
    }
  }

  SortByDateAscending() {
    this.CustomerInteractionBriefsToShow.sort((a, b) => {

      if (this.dateMethods.AddDays(a.Start,  a.PeriodDays) === this.dateMethods.AddDays(b.Start, b.PeriodDays)) {
        if (a.InteractionId < b.InteractionId) {
          return 1;
        } else {
          return -1;
        }
      }

      if (this.dateMethods.AddDays(a.Start,  a.PeriodDays) < this.dateMethods.AddDays(b.Start,  b.PeriodDays)) {
        return 1;
      }

      if (this.dateMethods.AddDays(a.Start,  a.PeriodDays) > this.dateMethods.AddDays(b.Start,  b.PeriodDays)) {
        return -1;
      }

      return 0;
    });
    this.PlaceTimeOutFirst();
    this.LoadPage();
  }

  SortByDateDescending() {
    this.CustomerInteractionBriefsToShow.sort((a, b) => {
      if (this.dateMethods.AddDays(a.Start,  a.PeriodDays) === this.dateMethods.AddDays(b.Start,  b.PeriodDays)) {
        if (a.InteractionId > b.InteractionId) {
          return 1;
        } else {
          return -1;
        }
      }

      if (this.dateMethods.AddDays(a.Start,  a.PeriodDays) > this.dateMethods.AddDays(b.Start,  b.PeriodDays)) {
        return 1;
      }

      if (this.dateMethods.AddDays(a.Start,  a.PeriodDays) < this.dateMethods.AddDays(b.Start,  b.PeriodDays)) {
        return -1;
      }

      return 0;
    });
    this.PlaceTimeOutFirst();
    this.LoadPage();
  }

  SortByNameAscending() {
    this.CustomerInteractionBriefsToShow.sort((a, b) => {
      let aNicknameUsed: boolean = false;
      let bNicknameUsed: boolean = false;

      let aCompare: string;
      if (a.Surname) {
        aCompare = a.Surname;
      } else {
        aCompare = a.Alias
        aNicknameUsed = true;
      }

      let bCompare: string;
      if (b.Surname) {
        bCompare = b.Surname;
      } else { 
        bCompare = b.Alias;
        bNicknameUsed = true;
      }

      let x = aCompare.localeCompare(bCompare);
      if (x == 0 && !(aNicknameUsed && bNicknameUsed)) {
        return a.Forename.localeCompare(b.Forename);
      } else if (aNicknameUsed && !bNicknameUsed) {
        return -1;
      } else if (!aNicknameUsed && bNicknameUsed) {
        return 1;
      }
      return x;
    });
    this.PlaceTimeOutFirst();
    this.LoadPage();
  }

  SortByNameDescending() {
    this.CustomerInteractionBriefsToShow.sort((a, b) => {
      let aNicknameUsed: boolean = false;
      let bNicknameUsed: boolean = false;

      let aCompare: string;
      if (a.Surname) {
        aCompare = a.Surname;
      } else {
        aCompare = a.Alias
        aNicknameUsed = true;
      }

      let bCompare: string;
      if (b.Surname) {
        bCompare = b.Surname;
      } else { 
        bCompare = b.Alias;
        bNicknameUsed = true;
      }

      let x = bCompare.localeCompare(aCompare);
      if (x == 0 && !(aNicknameUsed && bNicknameUsed)) {
        return b.Forename.localeCompare(a.Forename);
      } else if (aNicknameUsed && !bNicknameUsed) {
        return -1;
      } else if (!aNicknameUsed && bNicknameUsed) {
        return 1;
      }
      return x;
    });
    this.PlaceTimeOutFirst();
    this.LoadPage();
  }

  PlaceTimeOutFirst() {
    this.CustomerInteractionBriefsToShow.sort((a, b) => {
      let aCompare: string = a.TagIdCollection.some(i => i === this.asrv.Globals.TagValues.InteractionActionTimeOut)? 'T': 'F';
      let bCompare: string = b.TagIdCollection.some(i => i === this.asrv.Globals.TagValues.InteractionActionTimeOut)? 'T': 'F';
      return bCompare.localeCompare(aCompare);
    });
  }

  CustomerInteractionEndDate(CustomerInteraction : CustomerInteractionBrief): Date {
    return this.dateMethods.AddDays(CustomerInteraction.Start, CustomerInteraction.PeriodDays);
  }

  // Generic DOM run commands
  private ButtonActive(domId: string) {
    (document.getElementById(domId) as HTMLButtonElement).style.backgroundColor = 'lightgray';
    (document.getElementById(domId) as HTMLButtonElement).style.borderRadius = '1em';
  }

  private ButtonInactive(domId: string) {
    (document.getElementById(domId) as HTMLButtonElement).style.backgroundColor = this.asrv.Globals.BackgroundColor.LightBlue;
  }
}

class GalleryEventsHandler {
  constructor() {}
}

class Settings {
  public OrganisationActorId: number = 0;
  public SitesActorid: number = 0;
  public Limit: number = 1000;
  public Offset: number = 0;

  public Colors: Colors = new Colors();
  constructor() {}
}

class Colors {
  public readonly Red: string = '#d11722';
  public readonly Yellow: string = '#f8b03d';
  public readonly Green: string = '#64b32e';
  public readonly Blue: string = '#24ace2';
}

