import { ExclusionBreaches } from '../ExclusionBreaches';
import { Extension } from '../Extension';
import { Reinstate } from '../Reinstate';
import { ExclusionClientValues } from './ExclusionClientValues';
import { View_ReinstatementsCompositeModel } from './Reinstatement/View_ReinstatementsCompositeModel';
import { Views_InteractedActorCompositeModel } from './Views_InteractedActorCompositeModel';
import { Views_InteractionMonitoringPeriodsModel } from './Views_InteractionMonitoringPeriodsModel';
import { Views_MediaModel } from './Views_MediaModel';
import { PutExclusionsCompositeModel, Views_Put_ExclusionsCompositeModel } from './Views_Put_ExclusionsCompositeModel';
import { Views_VehicleCompositeModel } from './Views_VehicleCompositeModel';

export class Views_Get_ExclusionsCompositeModel extends Views_Put_ExclusionsCompositeModel {
    Extension: Extension = new Extension();
    Reinstatement: View_ReinstatementsCompositeModel;
    ExclusionBreachesCollection: ExclusionBreaches[];
    ClientValues: ExclusionClientValues = new ExclusionClientValues();
}

export class GetExclusionsCompositeModel extends PutExclusionsCompositeModel {
    ClientValues: ExclusionClientValues = new ExclusionClientValues();
    constructor(
        public InteractionId,
        public AssociatedActorIdCollection,
        public InteractedActorCompositeModel,
        public InteractedActorCompositeModelCollection,
        public InteractionMonitoringPeriod,
        public MediaModelCollection,
        public Notes,
        public Reference,
        public SiteId,
        public TagIdCollection,
        public VehicleCollection,
        public ExclusionReasonKeyId,
        public IdentificationDocumentKeyId,
        public Radius,
        public SiteIdCollection,
        Extension: Extension, 
        Reinstatement: View_ReinstatementsCompositeModel, 
        ExclusionBreachesCollection: ExclusionBreaches[]
    ) {
        super(        
            InteractionId,
            AssociatedActorIdCollection,
            InteractedActorCompositeModel,
            InteractionMonitoringPeriod,
            MediaModelCollection,
            Notes,
            Reference,
            SiteId,
            TagIdCollection,
            VehicleCollection,
            ExclusionReasonKeyId,
            IdentificationDocumentKeyId,
            Radius,
            SiteIdCollection
        )
    }
}

