import { Component, OnInit } from '@angular/core';
import { ActorService } from 'src/app/services/actor.service';
import { DataService } from 'src/app/services/data.service';
import { ErrorhanderService } from 'src/app/services/errorhander.service';

@Component({
  selector: 'app-oops',
  templateUrl: './oops.component.html',
  styleUrls: ['./oops.component.scss']
})
export class OopsComponent implements OnInit {

  public lastPostObject;
  public fieldValues: string[] = [];

  constructor(public errsrv: ErrorhanderService, public dsrv: DataService) { }

  ngOnInit() {
    if (this.errsrv.showPostObject) {
      this.lastPostObject = JSON.stringify(this.dsrv.lastPostModel);
    } 

    /*
    var object = this.dsrv.lastPostModel

    this.errsrv.keys.forEach(key => {
      var fixedKey = key.replace('[', '').replace(']', '');
      this.fieldValues.push(getNestedValue(object, fixedKey));
    })
    */
  }

  HideOops() {
    this.errsrv.HideOops();
  }
}
