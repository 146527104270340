<div class="body">
    <div class="container loginPanel">
        <div class="col-12">
            <img class="center" src="./assets/icons/Bacta Assets/bacta portal logo full colour.svg" alt="Bacta" style="height:auto;max-width:600px;width:90%;" />
            <div class="input-group">
                <div class="input-group-prepend">
                    <span class="input-group-text">New Password</span>
                </div>
                <input type="password" class="form-control" name="Password" [(ngModel)]="Password" (keydown.enter)="SetPassword();">
            </div>
            <div class="input-group">
                <div class="input-group-prepend">
                    <span class="input-group-text">Confirm Password</span>
                </div>
                <input type="password" class="form-control" name="Password" [(ngModel)]="Confirm" (keydown.enter)="SetPassword();">
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="text-center" style="margin-top:20px;">
                        <button class="btn btn-primary" (click)="SetPassword()" style="min-width: 250px;">
                            <span class="spinner-border spinner-border-sm" role="status" *ngIf="showSpinner"></span>
                            Set Password
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>