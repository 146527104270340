export class Addresses {
    Items: Address[];
}

export class Address {
    Id: string;
    Type: string;
    Text: string;
    Highlight: string;
    Description: string;
}

export class AddressDetails {
    Items: AddressDetail[];
}

export class AddressDetail {
    Id: string;
    DomesticId: string;
    Language: string;
    LanguageAlternatives: string;
    Department: string;
    Company: string;
    SubBuilding: string;
    BuildingNumber: string;
    BuildingName: string;
    SecondaryStreet: string;
    Street: string;
    Block: string;
    Neighbourhood: string;
    District: string;
    City: string;
    Line1: string;
    Line2: string;
    Line3: string;
    Line4: string;
    Line5: string;
    AdminAreaName: string;
    AdminAreaCode: string;
    Province: string;
    ProvinceName: string;
    ProvinceCode: string;
    PostalCode: string;
    CountryName: string;
    CountryIso2: string;
    CountryIso3: string;
    CountryIsoNumber: string;
    SortingNumber1: string;
    SortingNumber2: string;
    Barcode: string;
    POBoxNumber: string;
    Label: string;
    Type: string;
    DataLevel: string;
    Field1: string;
    Field2: string;
    Field3: string;
    Field4: string;
    Field5: string;
    Field6: string;
    Field7: string;
    Field8: string;
    Field9: string;
    Field10: string;
    Field11: string;
    Field12: string;
    Field13: string;
    Field14: string;
    Field15: string;
    Field16: string;
    Field17: string;
    Field18: string;
    Field19: string;
    Field20: string;

    // Doesn't always show up in this class.
    Latitude: number;
    Longitude: number;
}

export class GeocodingItems {
    Items: Geocoding[] = [];
}

export class Geocoding {
    Location: string;
    Easting: number;
    Northing: number;
    Latitude: number;
    Longitude: number;
    OsGrid: string;
    Accuracy: string;
}
