import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CustomerInteraction } from 'src/app/models/CustomerInteraction';

@Component({
  selector: 'app-reinstated-se-review',
  templateUrl: './reinstated-se-review.component.html',
  styleUrls: ['./reinstated-se-review.component.scss']
})
export class ReinstatedSeReviewComponent implements OnInit {

  constructor() { }

  interaction: CustomerInteraction;
  @Input('interaction') get Interaction(): CustomerInteraction {
    return this.interaction;
  };
  @Output('interaction') interactionChange: EventEmitter<CustomerInteraction> = new EventEmitter();
  set Interaction(val: CustomerInteraction) {
    this.interaction = val;
    this.interactionChange.emit(this.interaction);
  }

  stage: number;

  ngOnInit() {
    this.stage = 0;
  }

  GoForward() {
    if (this.stage === 0) {
      this.stage = 1;
    }
  }

  GoBack() {
    if (this.stage === 1) {
      this.stage = 0;
    }
  }

  SaveCustomerInteraction() {
    this.ShowDefaultApp.next();
  }

  ReturnToActionPage() {
    this.ShowDefaultApp.next();
  }

  @Output() ShowDefaultApp = new EventEmitter<string>();
}
