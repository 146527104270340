import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { LoginComponent } from './views/login/login.component';
import { RouterModule } from '@angular/router';
import { ForgottenPasswordComponent } from './views/forgotten-password/forgotten-password.component';
import { UserDashboardComponent } from './views/user-dashboard/user-dashboard.component';
import { FeatureCollectionComponent } from './views/feature-collection/feature-collection.component';
import { ProductCollectionComponent } from './views/product-collection/product-collection.component';
import { HttpClient } from '@angular/common/http';
import { GalleryComponent } from './views/excluder/gallery/gallery.component';
import { ExcluderComponent } from './views/excluder/excluder.component';
import { NewComponent } from './views/excluder/exclusion-journey/new/new.component';
import { DetailComponent } from './views/excluder/detail/detail.component';
import { CustomerdetailComponent } from './views/generic-form/customerdetail/customerdetail.component';
import { CustomeraddressComponent } from './views/generic-form/customeraddress/customeraddress.component';
import { ActionComponent } from './views/generic-form/action/action.component';
import { ViewComponent } from './views/excluder/view/view.component';
import { UpdateComponent } from './views/excluder/exclusion-journey/update/update.component';
import { CustomerPhotoComponent } from './views/generic-form/customer-photo/customer-photo.component';
import { ExcluderExclusionComponent } from './views/generic-form/excluder-exclusion/excluder-exclusion.component';
import { ExclusionZoneComponent } from './views/generic-form/exclusion-zone/exclusion-zone.component';
import { ExclusionDeclarationComponent } from './views/generic-form/exclusion-declaration/exclusion-declaration.component';
import { ExclusionTermsComponent } from './views/generic-form/exclusion-terms/exclusion-terms.component';
import { MotorwayServicesComponent } from './views/generic-form/motorway-services/motorway-services.component';
import { ExclusionConfirmedComponent } from './views/generic-form/exclusion-confirmed/exclusion-confirmed.component';
import { RouteguardService } from './services/routeguard.service';
import { RecordBreachComponent } from './views/excluder/view/record-breach/record-breach.component';
import { ReinstateComponent } from './views/excluder/view/reinstate/reinstate.component';
import { ExclusionDeclarationDetailsComponent } from './views/generic-form/exclusion-declaration-details/exclusion-declaration-details.component';
import { DisableControlDirective } from './models/directives/disabled.directive';
import { LoadingComponent } from './views/loading/loading.component';
import { TextPadComponent } from './views/text-pad/text-pad.component';
import { ExtendComponent } from './views/excluder/view/extend/extend.component';
import { ExclusionJourneyComponent } from './views/excluder/exclusion-journey/exclusion-journey.component';
import { GenericFormComponent } from './views/generic-form/generic-form.component';
import { ExcluderCardsComponent } from './views/excluder/gallery/excluder-cards/excluder-cards.component';
import { EmailComponent } from './views/generic-form/email/email.component';
import { ExcluderCardComponent } from './views/excluder/gallery/excluder-cards/excluder-card/excluder-card.component';
import { InspectSitesComponent } from './views/generic-form/motorway-services/inspect-sites/inspect-sites.component';
import { SiteShoppingBasketComponent } from './views/generic-form/site-shopping-basket/site-shopping-basket.component';
import { ExclusionCardMdComponent } from './views/generic-form/exclusion-card-md/exclusion-card-md.component';
import { ExclusionCardMobileComponent } from './views/generic-form/exclusion-card-mobile/exclusion-card-mobile.component';
import { CameraLiveViewComponent } from './views/generic-form/customer-photo/camera-live-view/camera-live-view.component';
import { ReportingMainComponent } from './views/reporting-main/reporting-main.component';
import { MainViewComponent } from './views/reporting-main/main-view/main-view.component';
import { TableComponent } from './views/reporting-main/main-view/table/table.component';
import { FormComponent } from './views/reporting-main/main-view/form/form.component';
import { ChartComponent } from './views/reporting-main/main-view/chart/chart.component';
import { PhotoGalleryComponent } from './views/reporting-main/main-view/photo-gallery/photo-gallery.component';
import { AdminComponent } from './views/admin/admin.component';
import { AdminMainViewComponent } from './views/admin/admin-main-view/admin-main-view.component';
import { AdminDataComponent } from './views/admin/admin-main-view/data/data.component';
import { AdminOrganisationComponent } from './views/admin/admin-main-view/organisation/organisation.component';
import { AdminSiteComponent } from './views/admin/admin-main-view/site/site.component';
import { AdminUserComponent } from './views/admin/admin-main-view/user/user.component';
import { OopsComponent } from './views/oops/oops.component';
import { EditOrganisationComponent } from './views/admin/admin-main-view/organisation/edit-organisation/edit-organisation.component';
import { EditSiteComponent } from './views/admin/admin-main-view/site/edit-site/edit-site.component';
import { EditUserComponent } from './views/admin/admin-main-view/user/edit-user/edit-user.component';
import { A11yModule } from '@angular/cdk/a11y';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { PortalModule } from '@angular/cdk/portal';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { CdkTableModule } from '@angular/cdk/table';
import { CdkTreeModule } from '@angular/cdk/tree';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { MatStepperModule } from '@angular/material/stepper';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatNativeDateModule, MatRippleModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatLegacySliderModule as MatSliderModule } from '@angular/material/legacy-slider';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { EllipsisPipe } from 'src/app/models/pipes/ellipsis-pipe';
import { CustomerAcknowledgementComponent } from './views/generic-form/customer-acknowledgement/customer-acknowledgement.component';
import { TermsAndConditionsComponent } from './views/generic-form/terms-and-conditions/terms-and-conditions.component';
import { MoreInfoComponent } from './views/excluder/view/more-info/more-info.component';
import { MessagingMainComponent } from './views/messaging-main/messaging-main.component';
import { MessagingMainViewComponent } from './views/messaging-main/messaging-main-view/messaging-main-view.component';
import { ReadComposeMessageComponent } from './views/messaging-main/read-compose-message/read-compose-message.component';
import { TagsComponent } from './views/generic-form/tags/tags.component';
import { TagComponent } from './views/generic-form/tags/tag/tag.component';
import { CustomerInteractionComponent } from './views/customer-interaction/customer-interaction.component';
import { InteractionGalleryComponent } from './views/customer-interaction/interaction-gallery/interaction-gallery.component';
import { InteractionNewComponent } from './views/customer-interaction/interaction-new/interaction-new.component';
import { InteractionViewComponent } from './views/customer-interaction/interaction-view/interaction-view.component';
import { InteractionCardsComponent } from './views/customer-interaction/interaction-gallery/interaction-cards/interaction-cards.component';
import { InteractionCardComponent } from './views/customer-interaction/interaction-gallery/interaction-cards/interaction-card/interaction-card.component';
import { InteractionReasonComponent } from './views/generic-form/interaction-reason/interaction-reason.component';
import { InteractionPrivacyNoticeComponent } from './views/generic-form/interaction-privacy-notice/interaction-privacy-notice.component';
import { InteractionActionComponent } from './views/generic-form/interaction-action/interaction-action.component';
import { InteractionCustomerDetailsComponent } from './views/generic-form/interaction-customer-details/interaction-customer-details.component';
import { InteractionIdentificationComponent } from './views/generic-form/interaction-identification/interaction-identification.component';
import { InteractionDescriptionComponent } from './views/generic-form/interaction-description/interaction-description.component';
import { InteractionReviewComponent } from './views/generic-form/interaction-review/interaction-review.component';
import { InteractionConfirmationComponent } from './views/generic-form/interaction-confirmation/interaction-confirmation.component';
import { InteractionCardMdComponent } from './views/generic-form/interaction-card-md/interaction-card-md.component';
import { InteractionCardMobileComponent } from './views/generic-form/interaction-card-mobile/interaction-card-mobile.component';
import { IconGalleryComponent } from './views/generic-form/icon-gallery/icon-gallery.component';
import { ReviewComponent } from './views/customer-interaction/interaction-view/review/review.component';
import { ReinstatedSeReviewComponent } from './views/customer-interaction/interaction-view/reinstated-se-review/reinstated-se-review.component';
import { CancelReviewComponent } from './views/customer-interaction/interaction-view/cancel-review/cancel-review.component';
import { UploadCctvComponent } from './views/customer-interaction/interaction-view/upload-cctv/upload-cctv.component';
import { UpdateDetailsComponent } from './views/customer-interaction/interaction-view/update-details/update-details.component';
import { CustomerReviewComponent } from './views/generic-form/customer-review/customer-review.component';
import { CustomerSeReviewComponent } from './views/generic-form/customer-se-review/customer-se-review.component';
import { CancelReviewFormComponent } from './views/generic-form/cancel-review/cancel-review.component';
import { IconImageComponent } from './views/generic-form/icon-image/icon-image.component';
import { NgInit } from './models/directives/ng-init.directive';
import { InteractionMoreInfoComponent } from './views/customer-interaction/interaction-view/interaction-more-info/interaction-more-info.component';
import { RecipientFilterComponent } from './views/messaging-main/recipient-filter/recipient-filter.component';
import { CustomerPhotoInlineComponent } from './views/generic-form/customer-photo-inline/customer-photo-inline.component';
import { UpdateExclusionComponent } from './views/excluder/view/update-exclusion/update-exclusion.component';
import { AdvancedSearchSearchResultsComponent } from './views/generic-form/advanced-search-search-results/advanced-search-search-results.component';
import { ResetPasswordComponent } from './views/reset-password/reset-password.component';
import { PrivacypolicyComponent } from './views/privacypolicy/privacypolicy.component';
import { DocumentsComponent } from './views/documents/documents.component';
import { AppRoutingModule } from './app-routing.module';
import { CrimeAlertComponent } from './views/crime-alert/crime-alert.component';
import { ToolkitDashboardComponent } from './views/toolkit-dashboard/toolkit-dashboard.component';
import { FireChecksComponent } from './views/fire-checks/fire-checks.component';
import { ToiletCheckComponent } from './views/toilet-check/toilet-check.component';
import { CleaningCheckComponent } from './views/cleaning-check/cleaning-check.component';
import { MachinePayoutsComponent } from './views/machine-payouts/machine-payouts.component';
import { MachineServiceComponent } from './views/machine-service/machine-service.component';
import { BreachViewComponent } from './views/breach/breach-view/breach-view.component';
import { StatutoryVisitComponent } from './views/statutory-visit/statutory-visit.component';
import { TokeninterceptorService } from './services/tokeninterceptor.service';
import { MemorableWordComponent } from './views/memorable-word/memorable-word.component';
import { InactivityService } from './services/inactivity.service';
import { AgeVerificationComponent } from './views/age-verification/age-verification.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ForgottenPasswordComponent,
    UserDashboardComponent,
    FeatureCollectionComponent,
    ProductCollectionComponent,
    GalleryComponent,
    ExcluderComponent,
    NewComponent,
    DetailComponent,
    CustomerdetailComponent,
    CustomeraddressComponent,
    ActionComponent,
    ViewComponent,
    UpdateComponent,
    CustomerPhotoComponent,
    ExcluderExclusionComponent,
    ExclusionZoneComponent,
    ExclusionDeclarationComponent,
    ExclusionTermsComponent,
    ExclusionConfirmedComponent,
    RecordBreachComponent,
    ReinstateComponent,
    ExclusionDeclarationDetailsComponent,
    DisableControlDirective,
    LoadingComponent,
    TextPadComponent,
    ExtendComponent,
    ExclusionJourneyComponent,
    GenericFormComponent,
    InteractionGalleryComponent,
    ExcluderCardsComponent,
    EmailComponent,
    ExcluderCardComponent,
    MotorwayServicesComponent,
    InspectSitesComponent,
    SiteShoppingBasketComponent,
    ExclusionCardMdComponent,
    ExclusionCardMobileComponent,
    CameraLiveViewComponent,
    ReportingMainComponent,
    MainViewComponent,
    TableComponent,
    FormComponent,
    ChartComponent,
    PhotoGalleryComponent,
    AdminComponent,
    AdminMainViewComponent,
    AdminDataComponent,
    AdminOrganisationComponent,
    AdminSiteComponent,
    AdminUserComponent,
    OopsComponent,
    EditOrganisationComponent,
    EditSiteComponent,
    EditUserComponent,
    EllipsisPipe,
    CustomerAcknowledgementComponent,
    TermsAndConditionsComponent,
    MoreInfoComponent,
    MessagingMainComponent,
    MessagingMainViewComponent,
    ReadComposeMessageComponent,
    TagsComponent,
    TagComponent,
    CustomerInteractionComponent,
    InteractionNewComponent,
    InteractionViewComponent,
    InteractionCardsComponent,
    InteractionCardComponent,
    InteractionReasonComponent,
    InteractionPrivacyNoticeComponent,
    InteractionActionComponent,
    InteractionCustomerDetailsComponent,
    InteractionIdentificationComponent,
    InteractionDescriptionComponent,
    InteractionReviewComponent,
    InteractionConfirmationComponent,
    InteractionCardMdComponent,
    InteractionCardMobileComponent,
    IconGalleryComponent,
    ReviewComponent,
    ReinstatedSeReviewComponent,
    CancelReviewComponent,
    UploadCctvComponent,
    UpdateDetailsComponent,
    CustomerReviewComponent,
    CustomerSeReviewComponent,
    CancelReviewFormComponent,
    IconImageComponent,
    NgInit,
    InteractionMoreInfoComponent,
    RecipientFilterComponent,
    CustomerPhotoInlineComponent,
    UpdateExclusionComponent,
    AdvancedSearchSearchResultsComponent,
    ResetPasswordComponent,
    PrivacypolicyComponent,
    DocumentsComponent,
    AgeVerificationComponent,
    CrimeAlertComponent,
    ToolkitDashboardComponent,
    FireChecksComponent,
    ToiletCheckComponent,
    CleaningCheckComponent,
    MachinePayoutsComponent,
    MachineServiceComponent,
    BreachViewComponent,
    StatutoryVisitComponent,
    MemorableWordComponent,
  ],
  exports: [
    A11yModule,
    CdkStepperModule,
    CdkTableModule,
    CdkTreeModule,
    DragDropModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    PortalModule,
    ScrollingModule,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    A11yModule,
    CdkStepperModule,
    CdkTableModule,
    CdkTreeModule,
    DragDropModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    PortalModule,
    ScrollingModule,
    FormsModule,
    AppRoutingModule,
    RouterModule.forRoot([
// { 
//   path: '', 
//   component: LoginComponent 
// },
// { 
//   path: 'forgottenPassword', 
//   component: ForgottenPasswordComponent 
// },
// {
//   path: 'privacypolicy',
//   component: PrivacypolicyComponent
// },
// {
//   path: 'resetpassword/:id',
//   component: ResetPasswordComponent
// },
// { 
//   path: 'user', 
//   component: UserDashboardComponent,
//   canActivate: [RouteguardService],
//   children: [
//     {
//       path: '', 
//       redirectTo: 'features', 
//       pathMatch: 'full'
//     },
//     {
//       path: 'opps',
//       component: OopsComponent
//     },
//     {
//       path: 'features',
//       component: FeatureCollectionComponent,
//     },
//     {
//       path: 'customer-interaction',
//       component: CustomerInteractionComponent,
//       children: [
//         {
//           path: '',
//           redirectTo: 'gallery',
//           pathMatch: 'full'
//         },
//         {
//           path: 'gallery',
//           component: InteractionGalleryComponent
//         },
//         {
//           path: 'gallery/:reload',
//           component: InteractionGalleryComponent
//         },
//         {
//           path: 'new',
//           component: InteractionNewComponent
//         },
//         {
//           path: 'continue/:id',
//           component: InteractionNewComponent
//         },
//         {
//           path: 'view/:id',
//           component: InteractionViewComponent
//         },
//         {
//           path: 'redirect/:url',
//           component: ViewComponent
//         }
//       ]
//     },
//     {
//       path: 'exclusion',
//       component: ExcluderComponent,
//       children:[
//         {
//           path: '',
//           redirectTo: 'gallery', 
//           pathMatch: 'full'
//         },
//         {
//           path: 'gallery',
//           component: GalleryComponent
//         },
//         {
//           path: 'new',
//           component: NewComponent
//         },
//         {
//           path: 'view/:id',
//           component: ViewComponent
//         },
//         {
//           path: 'update/:id',
//           component: UpdateComponent
//         }
//       ]
//     },
//     {
//       path: 'admin',
//       component: AdminComponent,
//       children: [
//         {
//           path: 'user-panel',
//           component: AdminUserComponent
//         },
//         {
//           path: 'site-panel',
//           component: AdminSiteComponent
//         },
//         {
//           path: 'organisation-panel',
//           component: AdminOrganisationComponent
//         }
//       ]
//     },
//     {
//       path: 'reporting',
//       component: ReportingMainComponent
//     },
//     {
//       path: 'messaging',
//       component: MessagingMainComponent,
//       children: [{
//         path: 'view/:id',
//         component: ReadComposeMessageComponent
//       }]
//     },
//     {
//       path: 'search',
//       component: AdvancedSearchSearchResultsComponent
//     }
//   ]
// },
// {
//   path: 'public/documents/:id',
//   component: DocumentsComponent
// },
// {
//   path: '**',
//   redirectTo: '',
//   pathMatch: 'full'
// },
], {})
  ],
  providers: [
    HttpClient,
    //{ provide: HTTP_INTERCEPTORS, useClass: TokeninterceptorService, multi: true }
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokeninterceptorService,
      multi: true,
    },
    Title,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }