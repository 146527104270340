import { Component, OnInit } from '@angular/core';
import { Excluder } from 'src/app/models/data/Excluder';
import { ActorService } from 'src/app/services/actor.service';
import { ExcluderBrief } from 'src/app/models/ExcluderBrief';
import { ExclusionBreaches } from 'src/app/models/ExclusionBreaches';
import { ISDDateMethods } from 'src/app/models/ISDDateMethods';
import { ExclusionDurationOption } from 'src/app/models/ExclusionDurationOption';
import { Subscription, ObjectUnsubscribedError } from 'rxjs';
import { Extension } from 'src/app/models/Extension';
import { Breach } from 'src/app/models/Breach';
import { Reinstate } from 'src/app/models/Reinstate';
import { DataService } from 'src/app/services/data.service';

declare var require: any;
const jsPDF = require('jspdf');
const redalert: string = '#d11722';
const yellowalert: string = '#f8b03d';
const greenalert: string = '#64b32e';
const bluealert: string = '#24ace2';

@Component({
  selector: 'app-photo-gallery',
  templateUrl: './photo-gallery.component.html',
  styleUrls: ['./photo-gallery.component.scss']
})
export class PhotoGalleryComponent implements OnInit {

  public excluders: Excluder[];
  public excluderBriefs: ExcluderBrief[];
  public excluderNames: string[] = [];
  public showSpinner: boolean;
  public exclusionCounter: number = 0;
  public imagesLoadedCounter: number = 0;
  public limit = 1000;

  private dateMethods: ISDDateMethods = new ISDDateMethods();

  private getExcludersSubsription: Subscription;
  private getExcluderIsSubscribed: boolean = false;
  private getExclusionDurationOptionsSubscription: Subscription;
  private getExclusionDurationOptionsIsSubscribed: boolean = false;
  public exclusionImageCounter: number = 0;
  public exclusions: exclusionImageReport[] = []
  public durationOptions: ExclusionDurationOption[] = [];

  public get redalert(): string {
    return redalert;
  }

  public get yellowalert(): string {
    return yellowalert;
  }

  public get greenalert(): string {
    return greenalert;
  }

  public get bluealert(): string {
    return bluealert;
  }

  constructor(public asrv: ActorService, public dsrv: DataService) { }

  ngOnInit() {
    this.showSpinner = false;

    /*
    this.getExcludersSubsription = this.asrv.GetExcluders().subscribe(res => {  
      this.getExcluderIsSubscribed = true;
      let now = this.dateMethods.ConvertDateToInteger(new Date());
      let start = 0;
      this.exclusionCounter = res.length - 1; 
      res.forEach(exclusion => {
        this.getExclusionDurationOptionsIsSubscribed = true;
        start = this.dateMethods.ConvertDateToInteger(exclusion.Start);
        if (start < now) {
          let showExclusion = true;
          if (exclusion.Extensions && !(this.dateMethods.ConvertDateToInteger(this.dateMethods.GetEndDate(exclusion.Start, exclusion.PeriodMonths)) > now)) {
            showExclusion = false;
          }
          if (showExclusion) {              
            let exclusionImageReportBrief = new exclusionImageReport()
            exclusionImageReportBrief.exclusionId = exclusion.ExclusionId;  
            exclusionImageReportBrief.name = exclusion.Forename + ' ' + exclusion.Surname;
            exclusionImageReportBrief.extensions = exclusion.Extensions;
            exclusionImageReportBrief.reinstatements = exclusion.Reinstatements;
            exclusionImageReportBrief.reference = exclusion.Reference;

            let startDate: Date = new Date(exclusion.Start);
            let endDate = new Date(startDate.setMonth(startDate.getMonth() + exclusion.PeriodMonths));
            exclusionImageReportBrief.endDate = endDate.toLocaleDateString().slice(0,10);

            this.asrv.GetBreaches(exclusion.ExclusionId).subscribe(breaches => {
              if (breaches.length > 0) {
                exclusionImageReportBrief.breaches = breaches;
              }
              exclusionImageReportBrief.breachesLoaded = true;
              this.CheckAllExclusionsLoaded(exclusionImageReportBrief);
            }, err => {
              exclusionImageReportBrief.breachesLoaded = true;
              this.CheckAllExclusionsLoaded(exclusionImageReportBrief);
            });

            this.asrv.GetExclusionImage(exclusion.ExclusionId).subscribe(image => {
              if (res) {
                exclusionImageReportBrief.image = image;
              }
              exclusionImageReportBrief.imageLoaded = true;
              this.CheckAllExclusionsLoaded(exclusionImageReportBrief);
            }, err => {
              exclusionImageReportBrief.imageLoaded = true;
              this.CheckAllExclusionsLoaded(exclusionImageReportBrief);
            });
          }
        } else {
          this.exclusionCounter = this.exclusionCounter-1;
        }
      });
    });
    */
    this.getExclusionDurationOptionsSubscription = this.asrv.ExclusionDurationOptions().subscribe(res =>{
      this.durationOptions = res;
      // this.exclusion.ExclusionDurationOptionId = this.durationOptions[this.selectedDurationIndex].ExclusionDurationOptionId;
    }, err => {
      alert('error');
    });
  }

  CheckAllExclusionsLoaded(exclusionImageReportBrief: exclusionImageReport) {
    if (exclusionImageReportBrief.imageLoaded && exclusionImageReportBrief.breachesLoaded) {
      this.exclusionImageCounter+=1;
      this.exclusions.push(exclusionImageReportBrief);
      this.imagesLoadedCounter+=1;
    }
    if (this.imagesLoadedCounter === this.exclusionCounter) {
      this.showSpinner = false;
    }
  }

  GetExcluderExtension(extension: Extension) {
    return this.dateMethods.GetMonthDifference(extension.Start, extension.Duration);
  }

  ExcluderIsCoolingOff(excluder: exclusionImageReport) {
    if (excluder.reinstatements) {
      return true;
    }
    return false;
  } 

  ExcluderRecentlyBreached(excluder: exclusionImageReport): Boolean {
    if (excluder.breaches) {
      return true;
    }
    return false;
  }

  private getAdvancedFilter(offset): AdvancedFilter {
    var advancedFilter: AdvancedFilter = new AdvancedFilter(new Date('2019-01-01'), new Date(), 0, 0, [], [], [], 0);
    return advancedFilter;
  }

  PrintPDF() {
    this.showSpinner = true;
    let doc = new jsPDF('p', 'pt', 'a4');
    let imagecounter = 0
    let imageStart = 15;
    let imagex = 15;
    let imagey = 15;
    let nameStep = 225;
    let refStep = 240;
    let dateStep = 255;
    let iconYStep = 270;
    let iconXStep = 65;
    let imagexStep = 200;
    let imageyStep = 270;
    let page = 1;

    let advancedFilter: AdvancedFilterInterface = this.getAdvancedFilter(0).GetInterface();
    advancedFilter.Limit = 100; //1000

    let data: ExcluderGalleryReport = new ExcluderGalleryReport(this.dsrv);
    data.GetData(advancedFilter).then(value => {
      if (value) {
        let numberOfPages = data.Items.length/9;

        for (let i = 1; i < numberOfPages; i++) {
          // Required to get the appropriate pages on the PDF.
        }
    
        doc.setPage(page);
        data.Items.forEach(excluder => {
          if (excluder.CustomerImage) {
            var width: number = 160;
            var height: number = 0;
            if (excluder.Image.width >= 160 || excluder.Image.height >= 160) {
              if (excluder.Image.width > excluder.Image.height) {
                height = 90;
              } else if (excluder.Image.width < excluder.Image.height) {
                height = 210;
              } else {
                height = 160;
              }
            } else {
              height = 160
            }
            doc.addImage(excluder.CustomerImage, 'PNG', imagex, imagey, width, height);
            doc.text(imagex, imagey+nameStep, excluder.InteractionName);
            doc.text(imagex, imagey+refStep, excluder.Reference);
            doc.text(imagex, imagey+dateStep, this.dateMethods.FormatDateForReport(excluder.EndDate));
            if (excluder.HasBreached) {
              doc.text(imagex, imagey+iconYStep, '/!\\')
            } 
            if (excluder.HasReinstated) {
              doc.text(imagex+iconXStep, imagey+iconYStep, '|24|')
            }
            if (excluder.HasExtended) {
              doc.text(imagex+iconXStep+iconXStep, imagey+iconYStep, '+' + excluder.ExtendedMonths)
            }
            if ((imagecounter+1) % 9 === 0) {
              doc.addPage();
              imagex = imageStart;
              imagey = imageStart;
            } else if ((imagecounter+1) % 3 === 0) {
              imagex = 15;
              imagey += imageyStep;
            } else {
              imagex += imagexStep;
            }
          }
          imagecounter+=1
        })
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
          var pdf = doc.output('datauristring');
          var pdfWindow = window.open();
          pdfWindow.document.open();
          pdfWindow.document.location=pdf;  
        } else {
          doc.save(new Date().toISOString() +'_' + 'test' + '.pdf');
        }
        this.showSpinner = false;
      }
    });
    /*
    let doc = new jsPDF('p', 'pt', 'a4');
    let imagecounter = 0
    let imageStart = 15;
    let imagex = 15;
    let imagey = 15;
    let nameStep = 225;
    let refStep = 240;
    let dateStep = 255;
    let iconYStep = 270;
    let iconXStep = 65;
    let imagexStep = 200;
    let imageyStep = 270;
    // let breachIcon = 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyMy43NSAyMy43NSI+PGRlZnM+PHN0eWxlPi5jbHMtMXtmaWxsOiNmZmY7c3Ryb2tlOiNkMTEyMjI7c3Ryb2tlLWxpbmVjYXA6cm91bmQ7c3Ryb2tlLWxpbmVqb2luOnJvdW5kO3N0cm9rZS13aWR0aDoxLjcycHg7fS5jbHMtMntmaWxsOiNkMTEyMjI7fTwvc3R5bGU+PC9kZWZzPjx0aXRsZT5CcmVhY2ggaWNvbjwvdGl0bGU+PGcgaWQ9IkxheWVyXzIiIGRhdGEtbmFtZT0iTGF5ZXIgMiI+PGcgaWQ9IkxheWVyXzEtMiIgZGF0YS1uYW1lPSJMYXllciAxIj48cG9seWdvbiBjbGFzcz0iY2xzLTEiIHBvaW50cz0iMjIuODkgMjIuODkgMC44NiAyMi44OSAxMS4yNyAwLjg2IDIyLjg5IDIyLjg5Ii8+PHBhdGggY2xhc3M9ImNscy0yIiBkPSJNMTAuODUsMTQuN2wtLjI2LTMuODZjMC0uNzYtLjA3LTEuMy0uMDctMS42M2ExLjQ5LDEuNDksMCwwLDEsLjM1LTEsMS4yNCwxLjI0LDAsMCwxLC45My0uMzguOTQuOTQsMCwwLDEsLjkzLjQ4QTMuMzYsMy4zNiwwLDAsMSwxMyw5LjY2YTEwLDEwLDAsMCwxLS4wNiwxLjA4bC0uMzQsNGEzLDMsMCwwLDEtLjI1LDEuMDkuNjMuNjMsMCwwLDEtLjYxLjM4LjYuNiwwLDAsMS0uNi0uMzdBMy4zOSwzLjM5LDAsMCwxLDEwLjg1LDE0LjdabS45LDUuMzJhMS4yNywxLjI3LDAsMCwxLS44Ni0uMzIsMS4xLDEuMSwwLDAsMS0uMzctLjg5LDEuMjEsMS4yMSwwLDAsMSwxLjIxLTEuMjEsMS4xOSwxLjE5LDAsMCwxLC44Ny4zNiwxLjEzLDEuMTMsMCwwLDEsLjM2Ljg1LDEuMTUsMS4xNSwwLDAsMS0uMzYuODlBMS4yNywxLjI3LDAsMCwxLDExLjc1LDIwWiIvPjwvZz48L2c+PC9zdmc+'

    // let pageHeight = 980;
    let page = 1;

    let numberOfPages = this.exclusionImageCounter/9;

    for (let i = 1; i < numberOfPages; i++) {
      // Required to get the appropriate pages on the PDF.
    }

    doc.setPage(page);
    this.exclusions.forEach(exclusion => {
      if (exclusion.image) {
        doc.addImage(exclusion.image, 'PNG', imagex, imagey, 160, 210);
        doc.text(imagex, imagey+nameStep, exclusion.name);
        doc.text(imagex, imagey+refStep, exclusion.reference);
        doc.text(imagex, imagey+dateStep, exclusion.endDate);
        if (exclusion.breaches) {
          doc.text(imagex, imagey+iconYStep, '/!\\')
        } 
        if (exclusion.reinstatements) {
          doc.text(imagex+iconXStep, imagey+iconYStep, '|24|')
        }
        if (exclusion.extensions) {
          doc.text(imagex+iconXStep+iconXStep, imagey+iconYStep, '+' + this.GetExcluderExtension(exclusion.extensions).toString())
        }
        if ((imagecounter+1) % 9 === 0) {
          doc.addPage();
          imagex = imageStart;
          imagey = imageStart;
        } else if ((imagecounter+1) % 3 === 0) {
          imagex = 15;
          imagey += imageyStep;
        } else {
          imagex += imagexStep;
        }
      }
      imagecounter+=1
    })
    
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      var pdf = doc.output('datauristring');
      var pdfWindow = window.open();
      pdfWindow.document.open();
      pdfWindow.document.location=pdf;  
    } else {
      doc.save('Excluder' + '.pdf');
    }
    */
  }
}

class exclusionImageReport {
  exclusionId: number;
  image: any;
  imageLoaded: boolean;
  name: string;
  breachesLoaded: boolean;
  breaches: Breach[];
  extensions: Extension;
  reinstatements: Reinstate;
  reference: string;
  endDate: string;
}

export class AdvancedFilter {
  public OrganisationParameters: OrganisationParameters;
  public SiteParameters: SiteParameters;
  public TagIdLookupOperator: string;

  private readonly searchAll: number = 1;
  private readonly searchOrganisation: number = 2;
  private readonly searchSite: number = 3;

  constructor(public Start: Date, public End: Date, public Limit: number, public Offset: number, private OrganisationIdCollection: number[], private SiteIdCollection: number[], public TagIdCollection: number[], public TagSearchType: number) {
      End.setDate(End.getDate() + 1);
      switch (this.TagSearchType) {
          case this.searchAll:
              this.OrganisationParameters = new OrganisationParameters(this.OrganisationIdCollection, [1]);
              this.SiteParameters = new SiteParameters(this.SiteIdCollection, [1]);
              break;
          case this.searchOrganisation:
              this.OrganisationParameters = new OrganisationParameters(this.OrganisationIdCollection, this.TagIdCollection);
              this.SiteParameters = new SiteParameters(this.SiteIdCollection, [1]);
              break;
          case this.searchSite:
              this.OrganisationParameters = new OrganisationParameters(this.OrganisationIdCollection, [1]);
              this.SiteParameters = new SiteParameters(this.SiteIdCollection, this.TagIdCollection);
              break;
          default:
              this.OrganisationParameters = new OrganisationParameters(this.OrganisationIdCollection, [1]);
              this.SiteParameters = new SiteParameters(this.SiteIdCollection, []);
              this.TagIdLookupOperator = 'NotExists';
      }   
  }

  public GetInterface() {
      return {
          TagIdLookupOperator: this.TagIdLookupOperator,
          TagIdCollection: this.TagIdCollection,
          OrganisationParameters: {
              OrganisationIdCollection: this.OrganisationParameters.OrganisationIdCollection,
              TagIdLookupOperator: this.OrganisationParameters.TagIdLookupOperator? this.OrganisationParameters.TagIdLookupOperator: 'NotExists',
              TagIdCollection: this.OrganisationParameters.TagIdCollection
          },
          SiteParameters: {
              SiteIdCollection: this.SiteParameters.SiteIdCollection,
              TagIdLookupOperator: this.SiteParameters.TagIdLookupOperator? this.SiteParameters.TagIdLookupOperator: 'NotExists',
              TagIdCollection: this.SiteParameters.TagIdCollection
          },
          Start: this.Start,
          End: this.End,
          Limit: this.Limit,
          Offset: this.Offset
      }
  }

}

export interface AdvancedFilterInterface {
  TagIdLookupOperator: string;
  TagIdCollection: number[];
  OrganisationParameters: {
      OrganisationIdCollection: number[];
      TagIdLookupOperator: string;
      TagIdCollection: number[];
  };
  SiteParameters: {
      SiteIdCollection: number[];
      TagIdLookupOperator: string;
      TagIdCollection: number[];
  };
  Start: Date;
  End: Date;
  Limit: number;
  Offset: number;
}

class PostObjects {
  AssociatedActorNameCompositeModel: AssociatedActorNameCompositeModel = new AssociatedActorNameCompositeModel();
  CommunicationAccountMessageCompositeModelController: CommunicationAccountMessageCompositeModelController = new CommunicationAccountMessageCompositeModelController();
  CommunicationAccountMessagesCompositeModel: CommunicationAccountMessagesCompositeModel = new CommunicationAccountMessagesCompositeModel();
  CommunicationContacts_CommunicationContactTags: CommunicationContacts_CommunicationContactTags = new CommunicationContacts_CommunicationContactTags();
}

class AssociatedActorNameCompositeModel  {
  AssociatedActorIdCollection: number[] = [];
}

class CommunicationAccountMessageCompositeModelController {
  CommunicationId: number;
  TagIdLookupOperator: string = "Exists";
  TagIdCollection: number[] = [];
  Processed: Date;
  Start: Date;
  End: Date;
  Limit: number;
  Offset: number;
}

class CommunicationAccountMessagesCompositeModel {
  ActorIdCollection: number[] = [];
  Body: string;
  Subject: string;
  TagIdCollection: number[] = [];
  Edited_ActorId: number;
}

class CommunicationContacts_CommunicationContactTags {
  ActorId: number;
  CommunicationId: number;
  CommunicationMethodKeyId: number;
  TagId: number;
  Edited: Date;
  Edited_ActorId: number;
}

class BasicPostParameters {
  Start: Date = new Date('2019-01-01');
  End: Date = new Date();
  Limit: number;
  Offset: number = 0;
}

class OrganisationParameters {
  public TagIdLookupOperator: string;
  constructor(public OrganisationIdCollection: number[], public TagIdCollection: number[]) {
  }
}

class SiteParameters {
  public TagIdLookupOperator: string;
  constructor(public SiteIdCollection: number[], public TagIdCollection: number[]) {
  }
}

export class ExcluderGalleryReport {
  public Items: ExcluderGalleryReportItem[];
  public ProfilesLoaded: number;
  public ProfilesToLoad: number;
  public DateMethods = new ISDDateMethods();
  private readonly whiteSpaceImage = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADkAAAA9CAIAAACY61bEAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAABUSURBVGhD7c5BDQAwEASh+je9VcHjkkEBb3d0NboaXY2uRlejq9HV6Gp0NboaXY2uRlejq9HV6Gp0NboaXY2uRlejq9HV6Gp0NboaXY2uRlejq7B974OYmhOKU9kAAAAASUVORK5CYII=';

  constructor(public dsrv: DataService) {}

  public GetData(advancedFilter: AdvancedFilterInterface): Promise<boolean> { 
    // console.log('getting data');
      this.Items = [];
      return new Promise<boolean>(resolve => {
          this.dsrv.Post<any>('api/View_ExclusionInteractionGalleryCompositeModel', advancedFilter).subscribe(res => {
              this.ProfilesToLoad = res.length;
              this.ProfilesLoaded = 0;
              res.sort((a, b) => {
                var aEnd = this.DateMethods.AddDays(a.Start,  a.PeriodDays);
                var bEnd = this.DateMethods.AddDays(b.Start,  b.PeriodDays);
          
                if (aEnd === bEnd) {
                  if (a.InteractionId < b.InteractionId) {
                    return 1;
                  } else {
                    return -1;
                  }
                }
          
                if (aEnd < bEnd) {
                  return 1;
                } else if (aEnd > bEnd) {
                  return -1;
                } else {
                  return 0;
                }
              });
              res.forEach(exclusionBrief => {
                  this.Items.push({
                      InteractionId: exclusionBrief.InteractionId,
                      CustomerImage: null,
                      Image: null,
                      InteractionName: `${exclusionBrief.Forename} ${exclusionBrief.Surname}`,
                      Reference: exclusionBrief.Reference,
                      EndDate: this.DateMethods.GetEndDate(exclusionBrief.Start, this.DateMethods.GetMonthDifference(exclusionBrief.Start, exclusionBrief.PeriodDays)),
                      HasBreached: exclusionBrief.ExclusionBreachesCollection.length > 0,
                      HasReinstated: exclusionBrief.Reinstatement.InteractionId > 0? true: false,
                      HasExtended: exclusionBrief.Extensions? true: false,
                      ExtendedMonths: exclusionBrief.Extensions? (this.DateMethods.GetMonthDifference(exclusionBrief.Extensions.Start, exclusionBrief.Extensions.Duration)): null
                  })
                  this.dsrv.Get<MediaModel>(`api/view_interactionimages/${exclusionBrief.InteractionId}`).subscribe(res => {
                      var excluder = this.Items.find(i => i.InteractionId === exclusionBrief.InteractionId);
                      excluder.Image = new Image()
                      excluder.Image.onload = function() {
                          // console.log(`Width: ${this.width}, Height: ${this.height}`)
                      }
                      excluder.Image.src = res.Data;
                      excluder.CustomerImage = res.Data;
                      this.ProfilesLoaded = this.ProfilesLoaded+1;
                      if (this.ProfilesLoaded === this.ProfilesToLoad) {
                          resolve(true);
                      }
                  }, err => {
                      this.Items.find(i => i.InteractionId === exclusionBrief.InteractionId).CustomerImage = this.whiteSpaceImage;
                      this.ProfilesLoaded = this.ProfilesLoaded+1;
                      if (this.ProfilesLoaded === this.ProfilesToLoad) {
                          resolve(true);
                      }
                  })
              })
          }, err => {
              resolve(false);
          })
      })
  }
}

interface ExcluderGalleryReportItem {
  InteractionId: number;
  CustomerImage: string;
  Image: any;
  InteractionName: string;
  Reference: string;
  EndDate: Date;
  HasBreached: boolean;
  HasReinstated: boolean;
  HasExtended: boolean;
  ExtendedMonths: number;
}

export interface MediaModel {
  MediaId: number;
  MediaClassKeyId: number;
  Data: string;
}


