import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RouteguardService } from './services/routeguard.service';
import { AdminOrganisationComponent } from './views/admin/admin-main-view/organisation/organisation.component';
import { AdminSiteComponent } from './views/admin/admin-main-view/site/site.component';
import { AdminUserComponent } from './views/admin/admin-main-view/user/user.component';
import { AdminComponent } from './views/admin/admin.component';
import { AgeVerificationComponent } from './views/age-verification/age-verification.component';
import { BreachViewComponent } from './views/breach/breach-view/breach-view.component';
import { CleaningCheckComponent } from './views/cleaning-check/cleaning-check.component';
import { CrimeAlertComponent } from './views/crime-alert/crime-alert.component';
import { CustomerInteractionComponent } from './views/customer-interaction/customer-interaction.component';
import { InteractionGalleryComponent } from './views/customer-interaction/interaction-gallery/interaction-gallery.component';
import { InteractionNewComponent } from './views/customer-interaction/interaction-new/interaction-new.component';
import { InteractionViewComponent } from './views/customer-interaction/interaction-view/interaction-view.component';
import { DocumentsComponent } from './views/documents/documents.component';
import { ExcluderComponent } from './views/excluder/excluder.component';
import { NewComponent } from './views/excluder/exclusion-journey/new/new.component';
import { UpdateComponent } from './views/excluder/exclusion-journey/update/update.component';
import { GalleryComponent } from './views/excluder/gallery/gallery.component';
import { ViewComponent } from './views/excluder/view/view.component';
import { FeatureCollectionComponent } from './views/feature-collection/feature-collection.component';
import { FireChecksComponent } from './views/fire-checks/fire-checks.component';
import { ForgottenPasswordComponent } from './views/forgotten-password/forgotten-password.component';
import { AdvancedSearchSearchResultsComponent } from './views/generic-form/advanced-search-search-results/advanced-search-search-results.component';
import { LoginComponent } from './views/login/login.component';
import { MachinePayoutsComponent } from './views/machine-payouts/machine-payouts.component';
import { MachineServiceComponent } from './views/machine-service/machine-service.component';
import { MessagingMainComponent } from './views/messaging-main/messaging-main.component';
import { ReadComposeMessageComponent } from './views/messaging-main/read-compose-message/read-compose-message.component';
import { OopsComponent } from './views/oops/oops.component';
import { PrivacypolicyComponent } from './views/privacypolicy/privacypolicy.component';
import { ReportingMainComponent } from './views/reporting-main/reporting-main.component';
import { ResetPasswordComponent } from './views/reset-password/reset-password.component';
import { StatutoryVisitComponent } from './views/statutory-visit/statutory-visit.component';
import { ToiletCheckComponent } from './views/toilet-check/toilet-check.component';
import { ToolkitDashboardComponent } from './views/toolkit-dashboard/toolkit-dashboard.component';
import { UserDashboardComponent } from './views/user-dashboard/user-dashboard.component';

const routes: Routes = [
  {
    path: '',
    component: LoginComponent
  },
  {
    path: 'forgottenPassword',
    component: ForgottenPasswordComponent
  },
  {
    path: 'privacypolicy',
    component: PrivacypolicyComponent
  },
  {
    path: 'resetpassword/:id',
    component: ResetPasswordComponent
  },
  {
    path: 'user',
    component: UserDashboardComponent,
    canActivate: [RouteguardService],
    children: [
      {
        path: '',
        redirectTo: 'features',
        pathMatch: 'full'
      },
      {
        path: 'opps',
        component: OopsComponent
      },
      {
        path: 'features',
        component: FeatureCollectionComponent,
      },
      {
        path: 'customer-interaction',
        component: CustomerInteractionComponent,
        children: [
          {
            path: '',
            redirectTo: 'gallery',
            pathMatch: 'full'
          },
          {
            path: 'gallery',
            component: InteractionGalleryComponent
          },
          {
            path: 'gallery/:reload',
            component: InteractionGalleryComponent
          },
          {
            path: 'new',
            component: InteractionNewComponent
          },
          {
            path: 'continue/:id',
            component: InteractionNewComponent
          },
          {
            path: 'view/:id',
            component: InteractionViewComponent
          },
          {
            path: 'redirect/:url',
            component: ViewComponent
          }
        ]
      },
      {
        path: 'exclusion',
        component: ExcluderComponent,
        canActivate: [RouteguardService],
        children: [
          {
            path: '',
            redirectTo: 'gallery',
            pathMatch: 'full'
          },
          {
            path: 'gallery',
            component: GalleryComponent
          },
          {
            path: 'new',
            component: NewComponent
          },
          {
            path: 'view/:id',
            component: ViewComponent
          },
          {
            path: 'update/:id',
            component: UpdateComponent
          }
        ]
      },
      {
        path: 'breach',
        component: ExcluderComponent,
        children: [
          {
            path: 'view/:id',
            component: BreachViewComponent
          },
        ]
      },
      {
        path: 'admin',
        component: AdminComponent,
        children: [
          {
            path: 'user-panel',
            component: AdminUserComponent
          },
          {
            path: 'site-panel',
            component: AdminSiteComponent
          },
          {
            path: 'organisation-panel',
            component: AdminOrganisationComponent
          }
        ]
      },
      {
        path: 'reporting',
        component: ReportingMainComponent
      },
      {
        path: 'messaging',
        component: MessagingMainComponent,
        children: [{
          path: 'view/:id',
          component: ReadComposeMessageComponent
        }]
      },
      {
        path: 'search',
        component: AdvancedSearchSearchResultsComponent
      },
      {
        path: 'age-verify',
        component: AgeVerificationComponent
      },
      {
        path: 'crime-alert',
        component: CrimeAlertComponent
      },
      {
        path: 'toolkit',
        component: ToolkitDashboardComponent
      },
      {
        path: 'fire-check',
        component: FireChecksComponent
      },
      {
        path: 'toilet-check',
        component: ToiletCheckComponent
      },
      {
        path: 'cleaning-check',
        component: CleaningCheckComponent
      },
      {
        path: 'machine-payout',
        component: MachinePayoutsComponent
      },
      {
        path: 'machine-service',
        component: MachineServiceComponent
      },
      {
        path: 'statutory-visit',
        component: StatutoryVisitComponent
      },
    ]
  },
  {
    path: 'public/documents/:id',
    component: DocumentsComponent
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
