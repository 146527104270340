import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActorService } from 'src/app/services/actor.service';
import { ExcluderService } from 'src/app/services/excluder.service';

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss']
})
export class TermsAndConditionsComponent implements OnInit {

  showHideInfo: boolean = false;

  showForwardArrow: boolean;
  @Input('showForwardArrow') get ShowForwardArrow(): boolean {
    return this.showForwardArrow;
  };
  @Output('showForwardArrow') showForwardArrowChange: EventEmitter<boolean> = new EventEmitter();
  set ShowForwardArrow(val: boolean) {
    this.showForwardArrow = val;
    this.showForwardArrowChange.emit(this.showForwardArrow);
  }

  public html: string

  constructor(
    public asrv: ActorService,
    public esrv: ExcluderService
  ) { }

  ngOnInit() {

  }

  ShowTerms(showFull) {
    this.CallShowHideForwardArrow();
    if (showFull) {
      this.showHideInfo = true;
      this.html = this.asrv.UserDocumentation.TermsHTML;
    } else {
      this.showHideInfo = true;
      this.html = this.asrv.UserDocumentation.SummaryOfTermsHTML;
    }
  }

  ShowPrivacyPolicy(showFull) {
    this.CallShowHideForwardArrow();
    if (showFull) {
      this.showHideInfo = true;
      this.html = this.asrv.UserDocumentation.PrivacyPolicyHTML;
    } else {
      this.showHideInfo = true;
      this.html = this.asrv.UserDocumentation.SummaryOfPrivacyPolicyHTML;
    }
  }

  ShowIpsosMori() {
    this.CallShowHideForwardArrow();
    this.showHideInfo = true;
    this.html = this.asrv.UserDocumentation.IpsosMoriHTML;
  }

  CloseForm() {
    this.CallShowHideForwardArrow();
    this.showHideInfo = false;
  }

  CallShowHideForwardArrow() {
    this.ShowHideForwardArrow.next();
  }

  @Output() ShowHideForwardArrow = new EventEmitter<string>();

}
