<div class="container global-seperator">
    <div *ngIf="(dataSpinner)">
        <div class="col-md-12" style="text-align: center;">
            <app-loading></app-loading>
        </div>
    </div>

    <div *ngIf="!(dataSpinner)">
        <router-outlet></router-outlet>
    </div>
</div>