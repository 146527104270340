import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { CustomerInteraction } from 'src/app/models/CustomerInteraction';
import { InteractionService } from 'src/app/services/interaction.service';



@Component({
  selector: 'app-interaction-reason',
  templateUrl: './interaction-reason.component.html',
  styleUrls: ['./interaction-reason.component.scss']
})
export class InteractionReasonComponent implements OnInit, AfterViewInit {
  @ViewChild('ttpDesktop') ttpDesktop: ElementRef;
  @ViewChild('ttpMobile') ttpMobile: ElementRef;
  
  // Checkbox Values
  staffObservationTrue: boolean = true;
  staffObservationFalse: boolean = false;
  InteractionCustomerLed = 90
  reasonTime = 91;
  reasonSpend = 92;
  ReasonBehaviour = 93
  ReasonMachineAlert = 94
  ReasonVulnerability = 95
  ReasonWishToExclude = 96

  constructor(public isrv: InteractionService) { }

  public listItems: string[] = [];
  public currentTooltip: number;

  ngOnInit() {
    this.isrv.AddItemToTagCollection(this.InteractionCustomerLed)
  }

  ngAfterViewInit() {
  }

  CheckboxClick(event, checkvalue) {
    if (event.currentTarget.checked) {
      this.isrv.AddItemToTagCollection(checkvalue);
    } else {
      this.isrv.RemoveItemFromTagCollection(checkvalue);
    }
  }

  ChangeObservation(staffObserved) {
    if (staffObserved) {
      this.isrv.AddItemToTagCollection(this.InteractionCustomerLed);
    } else {
      this.isrv.RemoveItemFromTagCollection(this.InteractionCustomerLed);
    }
    this.staffObservationTrue = staffObserved;
    this.staffObservationFalse = !staffObserved;
  }

  ToggleToolTip(tooltip: number) {
    this.listItems = [];
    if (this.currentTooltip === tooltip) {
      this.ttpDesktop.nativeElement.style.visibility = 'hidden';
      this.ttpMobile.nativeElement.style.visibilty = 'hidden';
      this.currentTooltip = null;
    } else {
      this.ttpDesktop.nativeElement.style.visibility = 'visible';
      this.ttpMobile.nativeElement.style.visibility = 'visible';
      switch (tooltip) {
        case 0:
          this.currentTooltip = 0;
          this.listItems.push('Interaction prompted but did not take place');
          this.listItems.push('Record elsewhere and complete at a later date');
          this.listItems.push('Violent or abusive customer/venue closing/staff issue');
          break;
        case 1:
          this.currentTooltip = 1;
          this.listItems.push('Time spent gambling');
          this.listItems.push('Length of stay on premises');
          this.listItems.push('Number of visits');
          break;
        case 2:
          this.currentTooltip = 2;
          this.listItems.push('Physical/mental impairment');
          this.listItems.push('Other vulnerability');
          this.listItems.push('With carer');
          break;
        case 3:
          this.currentTooltip = 3;
          this.listItems.push('Increased spending during a session');
          break;
        case 4:
          this.currentTooltip = 4;
          this.listItems.push('No other reason given for interaction');
          this.listItems.push('Customer led');
          break;
        case 5:
          this.currentTooltip = 5;
          this.listItems.push('Distress');
          this.listItems.push('Agitation');
          this.listItems.push('Change in behaviour');
          this.listItems.push('Aggression');
          break;
        case 6:
          this.currentTooltip = 6;
          this.listItems.push('Staff alerted');
          this.listItems.push('Seen by staff');
          this.listItems.push('Voluntary/mandatory alert');
          break;
      }
    }
  }


}
