<div class="container global-seperator">
    <div class="row">
        <div class="col-md-6" style="height: 35px;">
            <div class="input-group input-group-no-border">
                <div class="input-group-prepend form-checkbox-prepend">
                    <span class="input-group-text input-group-checkbox-text" style="font-size: 14px;"></span>
                </div>
                <span class="form-control bacta-check-box" style="border:none !important;background-color: rgb(235, 246, 246); color: rgb(94, 105, 142);font-size: 12px;text-align: center;"><b>Yes</b></span>
                <span class="form-control bacta-check-box" style="border:none !important;background-color: rgb(235, 246, 246); color: rgb(94, 105, 142);font-size: 12px;text-align: center;"><b>No</b></span>
            </div>  
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <div class="input-group input-group-no-border">
                <div class="input-group-prepend form-checkbox-prepend">
                    <span class="input-group-text input-group-checkbox-text" style="font-size: 11px;">Gambling management tool used?</span>
                </div>
                <input id="chkGamblingManagementToolUsed" type="checkbox" class="form-control bacta-check-box" (click)="GamblingToolUsed(true);" [(ngModel)]="chkGamblingTrue" >
                <input id="chkGamblingManagementToolUsed" type="checkbox" class="form-control bacta-check-box" (click)="GamblingToolUsed(false);" [(ngModel)]="chkGamblingFalse" >
            </div>    
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <div class="input-group input-group-no-border">
                <div class="input-group-prepend form-checkbox-prepend">
                    <span class="input-group-text input-group-checkbox-text" style="font-size: 11px;">Positive behaviour change?</span>
                </div>
                <input id="chkPositiveBehaviourChange" type="checkbox" class="form-control bacta-check-box" (click)="PositiveBehaviourChange(true)" [(ngModel)]="chkBehaviourTrue" >
                <input id="chkPositiveBehaviourChange" type="checkbox" class="form-control bacta-check-box" (click)="PositiveBehaviourChange(false)" [(ngModel)]="chkBehaviourFalse" >
            </div>    
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <div class="input-group input-group-no-border">
                <div class="input-group-prepend form-checkbox-prepend">
                    <span class="input-group-text input-group-checkbox-text" style="font-size: 11px;">Has pattern of gambling changed?</span>
                </div>
                <input id="chkPatternChanged" type="checkbox" class="form-control bacta-check-box" (click)="PatternOfGamblingChanged(true)" [(ngModel)]="chkPatternTrue" >
                <input id="chkPatternChanged" type="checkbox" class="form-control bacta-check-box" (click)="PatternOfGamblingChanged(false)" [(ngModel)]="chkPatternFalse" >
            </div>    
        </div>
    </div>

    <div class="row" style="min-height:200px; margin-top:15px;">
        <div class="col-md-12">
            <div style="background-color: #223464; padding: 10px; padding-top: 0px !important; border: 2px solid #223464; border-radius: 0.5em;">
                <div class="row">
                    <span style="color:white;">Notes</span>
                </div>
                <div class="row">
                    <textarea id="ctrlNotes" [(ngModel)]="isrv.Interaction.Notes" style="width:100%; height: 150px;"></textarea>
                </div>
            </div>
        </div>
    </div>
</div>
