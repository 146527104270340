<div class="container global-seperator form-body">
    <div class="form-content">
        <div class="row">
            <div class="col-sm-12">
                <h4 class="form-text">Capture Live Image</h4>
            </div>
        </div>
        <div class="row" style="margin-top:20px;"></div>
        <div class="row" style="display:flex; align-items: center;">
            <div class="col-md-4 d-none d-md-block" style="text-align: left;">
                <!--<input [hidden]="(photoExists)" type="image" id="captureImg" class="bacta-button" src=".\assets\icons\Bacta Assets\Live Capture Button blue.svg" style="cursor:pointer" onclick="document.getElementById('captureBtn').click();"/>
                <input [hidden]="(!photoExists)" type="image" id="retakeImg" class="bacta-button" src=".\assets\icons\Bacta Assets\Retake Photo button blue.svg" style="cursor:pointer" onclick="document.getElementById('captureBtn').click();"/>-->
                <input [hidden]="(photoExists)" type="image" id="captureImg" class="bacta-button" src=".\assets\icons\Bacta Assets\Capture Live BLUE.svg" style="cursor:pointer" (click)="LaunchLiveCapture();"/>
                <input [hidden]="(!photoExists)" type="image" id="retakeImg" class="bacta-button" src=".\assets\icons\Bacta Assets\Retake Photo button blue.svg" style="cursor:pointer" (click)="LaunchLiveCapture();"/>
            </div>
            <!--<div class="col-md-3 d-none d-md-block">
                <input id="searchImg" type="image" class="bacta-button" src=".\assets\icons\Bacta Assets\Browse and Upload button blue.svg" style="cursor:pointer" onclick="document.getElementById('getDirectory').click();"/>
            </div>-->
            <div class="col-md-4 guidelines d-none d-md-block" style="text-align: center;">
                Guidelines
                <ul class="guidelines-ul">
                    <li>&bull; Passport style</li>
                    <li>&bull; Full face in view</li>
                    <li>&bull; Head and shoulders</li>
                </ul>
            </div>
            <div class="col-md-4 d-none d-md-block" style="text-align: right;">
                <input type="image" class="bacta-button" src=".\assets\icons\outlines\Male icon.svg" >
                <input type="image" class="bacta-button" src=".\assets\icons\outlines\Female icon.svg" >
            </div>
        </div>
        <input #captureBtn id="captureBtn" type="file" accept="image/*;capture=camera" capture="user" style="display:none;" (change)="onFileSelected($event)" />
        <input id="getDirectory" type="file" accept="image/*;capture=camera" style="display:none;" (change)="onFileSelected($event)" />

        <div class="row d-none d-sm-block" style="height:1px;background-color:#223464;"></div>
        <div *ngIf="(photoExists) || (showVideo)" class="row d-none d-sm-block">
            <div class="col-sm-12">
                <h4 class="form-text">PREVIEW</h4>
            </div>
        </div>
        <div class="row" *ngIf="(showVideo)" style="min-height: 500px;">
            <div class="col-sm-12">
                <app-camera-live-view [style.visibility]="media.Data?'hidden':'visible'" [(media)]="media" (CloseForm)="CloseLiveView();"></app-camera-live-view>   
            </div>
        </div>
        <div class="row" style="height:400px;" *ngIf="(photoExists) && !(showVideo)">
            <div class="col-sm-12" style="text-align: center;">
                <img #image class="excluder-image-preview" [src]="media.Data" id="excluderCapture" style="max-height:400px;">
            </div>
        </div>
        <div class="row global-seperator" *ngIf="(photoExists) && !(showVideo)" style="margin:auto;height:100px;margin-top:20px;">
            <div class="col-6" style="text-align: center;">
                <input type="image" class="bacta-button" src=".\assets\icons\Bacta Assets\Turn Anti Clockwise Blue.svg" style="cursor:pointer" (click)="RotateLeft()"/>
            </div>
            <div class="col-6" style="text-align: center;">
                <input type="image" class="bacta-button" src=".\assets\icons\Bacta Assets\Turn Clockwise Blue.svg" style="cursor:pointer" (click)="RotateRight()"/>
            </div>
        </div>
        <div class="d-block d-md-none" style="margin-top:20px;">
            <div class="row" [hidden]="(photoExists)">
                <div class="col-xs-6 guidelines" style="width:50%; text-align:left;">
                    GUIDELINES
                    <ul class="guidelines-ul">
                        <li>&bull; Passport style</li>
                        <li>&bull; Full face in view</li>
                        <li>&bull; Head and shoulders</li>
                    </ul>
                </div>
                <div class="col-xs-6" style="width:50%; text-align: right;">
                    <img class="bacta-button" src=".\assets\icons\Bacta Assets\Heads icon.svg">
                </div>
            </div>
            <div class="col-xs-12" style="text-align: center; margin-top:20px;">           
                <input [hidden]="(photoExists)" type="image" id="captureImg" class="bacta-button" src=".\assets\icons\Bacta Assets\Live Capture Button blue.svg" style="cursor:pointer" onclick="document.getElementById('captureBtn').click();"/>
                <input [hidden]="(!photoExists)" type="image" id="retakeImg" class="bacta-button" src=".\assets\icons\Bacta Assets\Retake Photo button blue.svg" style="cursor:pointer" onclick="document.getElementById('captureBtn').click();"/>
            </div>
        </div>
    </div>
    <div class="form-footer">
        <!--Close Button-->
        <div class="navigation" (click)="Close()">
            <span><input type="image" class="stage-button" src=".\assets\icons\Bacta Assets\Back button blue.svg" style="width:100px" /></span>
        </div>
        <!--Accept Button-->
        <div class="navigation" (click)="Accept()">
            <span><input type="image" class="stage-button" src=".\assets\icons\Bacta Assets\Next button blue.svg" style="width:100px" /></span>
        </div>   
    </div>
</div>