<div #cardOuter id="cardOuter" class="row card-container global-seperator">
    <div class="col-6 card-thumb">   
        <!--Implement on return-->
        <!--<span class="img-background-color">-->
        <img class="img-exclusion" [src]="image"/>
    </div>
    <div class="col-5 card-content" style="font-size:100%"> 
        <h3 class="card-title"><b>{{esrv.CurrentExclusion.InteractedActorCompositeModelCollection[0].BiologicalActorCompositeModel?.Forename}} {{esrv.CurrentExclusion.InteractedActorCompositeModelCollection[0].BiologicalActorCompositeModel?.Surname}}</b></h3>
        <p>
            <b>Date of birth:</b> {{esrv.CurrentExclusion.InteractedActorCompositeModelCollection[0].BiologicalActorCompositeModel?.BirthDate | date: 'dd/MM/yyyy'}} <br/>
            <b>Ref:</b> {{esrv.CurrentExclusion.Reference}}
        </p>
        <p>
            <b>Address:</b> <span *ngIf="esrv.CurrentExclusion.InteractedActorCompositeModelCollection[0].BiologicalActorCompositeModel.Line1"> {{esrv.CurrentExclusion.InteractedActorCompositeModelCollection[0].BiologicalActorCompositeModel?.Line1}}</span> 
            <span *ngIf="esrv.CurrentExclusion.InteractedActorCompositeModelCollection[0].BiologicalActorCompositeModel.Line2">, {{esrv.CurrentExclusion.InteractedActorCompositeModelCollection[0].BiologicalActorCompositeModel?.Line2}}</span>
            <span *ngIf="esrv.CurrentExclusion.InteractedActorCompositeModelCollection[0].BiologicalActorCompositeModel.TownCity">, {{esrv.CurrentExclusion.InteractedActorCompositeModelCollection[0].BiologicalActorCompositeModel?.TownCity}}</span> 
            <span *ngIf="esrv.CurrentExclusion.InteractedActorCompositeModelCollection[0].BiologicalActorCompositeModel.County">, {{esrv.CurrentExclusion.InteractedActorCompositeModelCollection[0].BiologicalActorCompositeModel?.County}}</span> <br />
            <b>Post Code:</b> {{esrv.CurrentExclusion.InteractedActorCompositeModelCollection[0].BiologicalActorCompositeModel?.Postcode}}
        </p>
        <p>

            <b>Exclusion:</b> {{esrv.CurrentExclusion.InteractionMonitoringPeriod?.Start | date: 'dd/MM/yyyy'}} - {{GetEndDate() | date: 'dd/MM/yyyy'}} <br/>
            <b>Site:</b> {{esrv.CurrentExclusion.ClientValues?.ExclusionSiteName}} <br/>
            <b>Exclusion Radius:</b> {{esrv.ExclusionRadius}}
        </p>
        <p *ngIf="breached"> 
            <b>Number of Breaches:</b> {{esrv.CurrentExclusion.ExclusionBreachesCollection.length}} <br />
            <b>Recent Breach:</b> {{recentBreach.Breached | date: 'dd/MM/yyyy'}} <br />       
            <span *ngIf="showBreachSite"><b>Breach Location:</b> {{recentBreach.Site}}</span>
        </p>
        <div class="row" style="height:80px;">
            <div *ngIf="ExcluderIsCoolingOff();" class="flex-no-padding w-33">
                <img class="card-img" src=".\assets\icons\Bacta Assets\24 icon.svg">
            </div>
            <div *ngIf="breached;" class="flex-no-padding w-33">
                <img class="card-img" src=".\assets\icons\Bacta Assets\Breach icon.svg">
            </div>
            <div *ngIf="(esrv.CurrentExclusion.Extension);" class="flex-no-padding w-33">
                <div class="card-img-text card-img-radius">+{{GetExtensionInMonths()}}</div>
            </div>
        </div>

    </div>
    <div class="col-1 close-container" routerLink="/user/exclusion/gallery">
        <div class="row">
            <input class="btn-close btn-close-flex-box" type="image" src=".\assets\icons\Bacta Assets\Close.svg">
        </div>
        <div class="row">
            <span class="close-text" style="text-align:center;">CLOSE</span>
        </div>  
    </div>
</div>