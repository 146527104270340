import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActorService } from 'src/app/services/actor.service';
import { EmployeeDetails } from 'src/app/models/EmployeeDetails';
import { Honorific } from 'src/app/models/Honorific';
import { Gender } from 'src/app/models/Gender';
import { RoleBriefs, Tags } from 'src/app/models/Tags';
import { Credentials } from 'src/app/models/Credentials';
import { OrganisationBrief } from 'src/app/models/OrganisationBrief';
import { UserSites } from 'src/app/models/UserSites';
import { AdminService } from 'src/app/services/admin.service';
import { Actor_Settings } from 'src/app/models/Actor_Settings';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss', '../admin-main-view.component.scss']
})
export class AdminUserComponent implements OnInit {

  object = [{ id: false }];
  objectKeys = [];
  tableKeys = [];

  public dataLoaded: boolean;
  public showEmployeeData: boolean = false;
  public employeeDetails: EmployeeDetails;
  public showSendingSpinner: boolean = false;
  public honorifics: Honorific[];
  public genders: Gender[];
  public addingNewEmployee: boolean = false;
  public actorRole: number;
  public roleBriefs: RoleBriefs[] = [];
  public updateEmployeeCredentials: boolean = false;
  public userCredententials: Credentials;
  public organisationBriefs: OrganisationBrief[];
  public organisationId: number = -1;
  public siteBriefs: UserSites[];
  public siteId: number = -1;
  public disableTable: boolean;
  public showSuspended: boolean;

  constructor(
    public asrv: ActorService,
    private tags: Tags,
    public adminsrv: AdminService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    
  }

  ngOnInit() {
    this.adminsrv.organisationCreateMode = false;
    this.adminsrv.SetOrganisationFilter();
    this.adminsrv.FilterUserList().then(value => {
      if (value) {
        this.SetDataObject();
      }
    });
    this.route.queryParams.subscribe(params => {
      if (params.uid) {
        this.LoadEmployeeFromUID(params.uid)
      }
    });
  }

  SetDataObject() {
    this.object = [];
    this.objectKeys = Object.values(this.adminsrv.UserList.HeaderTranslation[0]);
    this.tableKeys = Object.keys(this.adminsrv.UserList.HeaderTranslation[0]);
    this.object = this.adminsrv.UserListToShow.Rows;
  }

  FilterOrganisations() {
    this.adminsrv.FilterUserList(this.showSuspended).then(value => {
      this.SetDataObject();
    });
  }

  ShowEmployee(employee: any, event: any) {
    const _uid = this.asrv.GetMyUserID();
    if (!this.disableTable) {
      this.disableTable = true;
      document.body.style.cursor = 'progress';
      this.adminsrv.creatingNewRecord = false;
      this.asrv.GetEmployeeDetails(employee.ActorId).subscribe(res => {
        this.adminsrv.userCredententials = new Credentials();
        this.adminsrv.employeeDetails = res;
        let userLoginSubscription = this.asrv.GetUserLogin(this.adminsrv.employeeDetails.ActorId).subscribe(res => {
          userLoginSubscription.unsubscribe();
          res.Hash = '';
          this.adminsrv.userCredententials = res;
          if (_uid === employee.ActorId) {
            this.ShowEmployeeDetails(true);
          } else {
            this.ShowEmployeeDetails();
          }
          
        }, err => {
          this.adminsrv.userCredententials.Hash = '';
          this.adminsrv.userCredententials.Login = '';
          this.adminsrv.userCredententials.ActorId = this.adminsrv.employeeDetails.ActorId;
          alert('User does not have an active login. Without this they cannot use the system.');
          if (_uid === employee.ActorId) {
            this.ShowEmployeeDetails(true);
          } else {
            this.ShowEmployeeDetails();
          }
        })
      })
    }
  }

  LoadEmployeeFromUID(uid: number) {
    if (!this.disableTable) {
      this.disableTable = true;
      document.body.style.cursor = 'progress';
      this.adminsrv.creatingNewRecord = false;
      this.asrv.GetEmployeeDetails(uid).subscribe(res => {
        this.adminsrv.userCredententials = new Credentials();
        this.adminsrv.employeeDetails = res;
        let userLoginSubscription = this.asrv.GetUserLogin(this.adminsrv.employeeDetails.ActorId).subscribe(res => {
          userLoginSubscription.unsubscribe();
          res.Hash = '';
          this.adminsrv.userCredententials = res
          this.ShowEmployeeDetails(true);
        }, err => {
          this.adminsrv.userCredententials.Hash = '';
          this.adminsrv.userCredententials.Login = '';
          this.adminsrv.userCredententials.ActorId = this.adminsrv.employeeDetails.ActorId;
          alert('User does not have an active login. Without this they cannot use the system.');
          this.ShowEmployeeDetails(true);
        })
      })
    }
  }

  AddNewRecord() {
    if (this.adminsrv.UserRoleId <= 5) {
      this.adminsrv.employeeDetails = new EmployeeDetails();
      this.adminsrv.userCredententials = new Credentials();
      this.adminsrv.userCredententials.Login = '';
      this.adminsrv.userCredententials.Hash = '';
      this.adminsrv.creatingNewRecord = true;
      this.ShowEmployeeDetails();
    } else {
      alert('You are unable to add or edit employee records.');
      this.router.navigate(['user']);
    }
  }

  ShowEmployeeDetails(canview?: boolean) {
    if (this.adminsrv.UserRoleId <= 5) {
      this.disableTable = false;
      document.body.style.cursor = 'default';
      this.showEmployeeData = true;
    } else {
      if (canview) {
        this.showEmployeeData = true;
      } else {
        alert('You are unable to add or edit employee records.');
        this.router.navigate(['user']);
      }

    }
  }

  CloseEditForm() {
    this.showEmployeeData = false;
    this.FilterOrganisations();
  }
}