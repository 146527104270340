import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { ExcluderBrief } from 'src/app/models/ExcluderBrief';
import { ISDDateMethods } from 'src/app/models/ISDDateMethods';
import { ActorService } from 'src/app/services/actor.service';
import { DataService } from 'src/app/services/data.service';
import { ExcluderGalleryService } from 'src/app/services/excluder-gallery.service';
import { ExcluderService } from 'src/app/services/excluder.service';

@Component({
  selector: 'app-excluder-cards',
  templateUrl: './excluder-cards.component.html',
  styleUrls: ['./excluder-cards.component.scss', '../gallery.component.scss']
})

export class ExcluderCardsComponent implements OnInit, AfterViewInit {

  @ViewChild('searchField') searchField: ElementRef;
  public searchPhrase: string;
  private dateMethods: ISDDateMethods = new ISDDateMethods();
  showSpinner: boolean;
  constructor(
    public gsrv: ExcluderGalleryService,
    public esrv: ExcluderService,
    public asrv: ActorService,
    private router: Router
  ) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
  }

  ViewExcluder(excluder: ExcluderBrief) {
    this.esrv.StoreExcluderBrief(excluder);
    this.esrv.LoadExclusion(excluder.InteractionId).then(value => {
      this.router.navigate(['/user/exclusion/view', excluder.InteractionId]);
    });
  }

  GetExcluderColour(excluder: ExcluderBrief): string {
    let diffDaysStart = this.dateMethods.DateDifferenceInDays(excluder.Start)
    let diffDaysEnd = this.dateMethods.DateDifferenceInDays(this.dateMethods.AddDays(excluder.Start, excluder.PeriodDays));
    if (this.ExcluderIsCoolingOff(excluder)) {
      return this.gsrv.Settings.Colors.Blue;
    } else if (diffDaysStart <= 5)
      return this.gsrv.Settings.Colors.Red;
    else if (diffDaysEnd >= 0)
      return this.gsrv.Settings.Colors.Green;
    else return this.gsrv.Settings.Colors.Yellow;
  }

  ExcluderIsCoolingOff(excluder: ExcluderBrief) {
    var diff = this.dateMethods.DateDifferenceInDays(excluder.Reinstatement.Accepted);
    if (diff > 0 && diff < 1) {
      return true;
    }
    return false;
  }
}
