import { SettingCollection } from './SettingCollection';
import { Honorific } from './Honorific';
import { Gender } from './Gender';

export class EmployeeDetails {
    ActorId: number;
    Organisation_ActorId: number;
    Forename: string;
    Surname: string;
    Email: string;
    BirthDate: Date;
    GenderId: number;
    Gender: Gender;
    HonorificId: number;
    Honorific: Honorific;
    ActorRoleId: number;
    ActorRole: string;
    TagIdCollection: number[] = [];
    SettingCollection: SettingCollection[] = [];
    SiteIdCollection: number[] = [];
    Country: String;
    County: String;
    Line1: String;
    Line2: String;
    Postcode: string;
    TownCity: String;
    Latitude: number;
    Longitude: number;
    PostalAddressClassId: number;
    Mobile: String;
    MemWord?: string;
}