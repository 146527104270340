import { Component, OnInit } from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ActorService } from 'src/app/services/actor.service';
import { AdvancedSearchService } from 'src/app/services/advanced-search.service';
import { ApiService } from 'src/app/services/api.service';
import { DataService } from 'src/app/services/data.service';
import { ErrorhanderService } from 'src/app/services/errorhander.service';
import * as moment from 'moment';

@Component({
  selector: 'app-fire-checks',
  templateUrl: './fire-checks.component.html',
  styleUrls: ['./fire-checks.component.scss']
})
export class FireChecksComponent implements OnInit {
  checkDate;
  checkDateModel;
  checkTime;
  siteID;
  events: string[] = [];
  usingLoggedInName = false;
  employeeListLoading = false;
  loggedInUserID;
  employeeList = [];
  loggedInName;
  checkOKTrue;
  checkOKFalse;
  isSaving = false;
  employeeId;
  notes;
  errorMsg;
  isSavedSuccess;
  isError;
  itemListLoading = true;
  fireItem;
  fireItemList;
  constructor(
    public dsrv: DataService,
    public asrv: ActorService,
    private router: Router,
    public errsrv: ErrorhanderService,
    public assrv: AdvancedSearchService,
    private titleService: Title,
    private _api: ApiService,
    private _snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    this.getEmployeeList();
    this.getTagCollection(21);
    document.body.style.backgroundColor = '#c3cddd';
  }

  addEvent(type: string, event: MatDatepickerInputEvent<Date>) {
    this.events.push(`${type}: ${event.value}`);
    this.checkDate = moment(this.checkDateModel).format('YYYY-MM-DD');
  }

  getTagCollection(id: number) {
    this._api.getTagCollectionCompositeModel(id).subscribe(collection => {
      switch (id) {
        case 21: {
          this.fireItemList = collection.Tags;
        }
      }
      this.itemListLoading = false;
    });
  }

  setItem() { }

  getEmployeeList() {
    this.usingLoggedInName = false;
    this.employeeListLoading = true;
    const _userID = this.dsrv.GetMyUserID();
    this.loggedInUserID = _userID;
    // console.log(this.loggedInUserID);
    this._api.getUserOrgID(_userID).subscribe(results => {

      const _orgID = results[0].Organisations_ActorId;
      const _employeeIDs = [];
      this._api.getUserEmployeesList(_orgID).subscribe(employeeResults => {
        employeeResults.forEach(element => {
          _employeeIDs.push(element.Employees_ActorId);
        });

        this.asrv.GetBiologicalName(_employeeIDs).then(value => {
          this.employeeList = value;
          this.employeeListLoading = false;
        });
      },
        error => {
          this.usingLoggedInName = true;
          this.loggedInName = this.asrv.GetUsersFullName();
          this.employeeListLoading = false;
        });
    });
  }

  checkItem(wasChecked) {
    if (wasChecked) {
      this.checkOKTrue = true;
      this.checkOKFalse = false;
    } else {
      this.checkOKTrue = false;
      this.checkOKFalse = true;
    }
  }

  GoBack() {
    this.router.navigate(['/user/toolkit']);
  }

  closeNotification() {
    this.isError = false;
    this.isSavedSuccess = false;
  }

  saveCheck() {
    console.log(moment(this.checkDate + ' ' + this.checkTime, 'YYYY-MM-DD HH:mm').utc().format('YYYY-MM-DDTHH:mm'));
    console.log(moment(this.checkDate + ' ' + this.checkTime, 'YYYY-MM-DD HH:mm').utc());
    console.log(moment(this.checkDate + ' ' + this.checkTime, 'YYYY-MM-DD HH:mm'));
    const _recordedDate = moment(this.checkDate + ' ' + this.checkTime, 'YYYY-MM-DD HH:mm').utc().format('YYYY-MM-DDTHH:mm');
    this.siteID = this.asrv.DefaultSiteId;
    this.isSaving = true;
    const _tagIDCollection = [];

    if (this.checkOKTrue) {
      _tagIDCollection.push(3);
    }

    if (this.fireItem) {
      _tagIDCollection.push(this.fireItem);
    }

    if ((this.checkOKFalse && this.notes && this.notes.length > 0) || this.checkOKTrue) {
      console.log(_recordedDate, this.employeeId, this.fireItem, this.checkOKTrue, this.checkOKFalse, this.notes)
      
      this._api.postFireCheck(
        {
          AssociatedActorIdCollection: [
            this.employeeId
          ],
          InteractedActorCompositeModelCollection: [
            {
              BiologicalActorCompositeModel: {
                PostalAddressClassId: 1,
              }
            }
          ],

          Notes: this.notes,
          SiteId: this.siteID,
          Recorded: _recordedDate,
          TagIdCollection: _tagIDCollection,

        }
      ).subscribe(result => {
        this.isSaving = false;
        this.isSavedSuccess = true;
        this.isError = false;
        setTimeout(() => {
          this.isSavedSuccess = false;
          this.router.navigate(['/user/toolkit']);
        }, 3000);
      }, error => {
        this.isSaving = false;
        this.isError = true;
        this.errorMsg = error.error;
        console.log(error);
      });
    }

    this.isSaving = false;
  }

}
