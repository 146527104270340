import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { DataService } from 'src/app/services/data.service';
import { ExcluderGalleryService } from 'src/app/services/excluder-gallery.service';
import { ExcluderService } from 'src/app/services/excluder.service';
import { InactivityService } from 'src/app/services/inactivity.service';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent implements OnInit {
  ShowExclusionButton: boolean;
  public showSpinner: boolean;
  public sort: number = 0;

  public fromReportBuilder: boolean;

  galleryInit$;

  @Input('fromReportBuilder') get FromReportBuilder(): boolean {
    return this.fromReportBuilder;
  };
  @Output('fromReportBuilder') fromReportBuilderChange: EventEmitter<boolean> = new EventEmitter();
  set FromReportBuilder(val: boolean) {
    this.fromReportBuilder = val;
    this.fromReportBuilderChange.emit(this.fromReportBuilder);
  }

  constructor(
    public gsrv: ExcluderGalleryService,
    private router: Router,
    private titleService: Title,
    private _is: InactivityService,
    private _ds: DataService,
    private _api: ApiService
  ) {

  }

  ngOnInit() {
    this.titleService.setTitle('Exclusion Gallery');
    console.log('--------- Gallery Component Loaded');

    const _token = sessionStorage.getItem('__galleryToken');

    if (_token) {
      this._api.getCheckToken(_token).subscribe(
        results => {
          this._ds.setInitGallery(true);
        },
        error => {
          this._ds.setMemwordError(true);
          sessionStorage.removeItem('__galleryToken');
        }
      )
    } else {
      this._ds.setMemwordError(true);
    }

    this.galleryInit$ = this._ds.getInitGallery().subscribe(init => {
      this.galleryInit$.unsubscribe();
      if (!this._ds.dataLoaded) {
        if (!this.gsrv.initRunning) {
          this.initGallery();
        }
      } else {
        this._is.startMonitor();
        this._is.startTimer();
      }
    });
  }

  initGallery() {
    console.log('--------- Gallery Init Fired','First Load:', this.gsrv.FirstLoad);
    this.gsrv.Initialize().then(value => {
     
      if (this.gsrv.ExcluderBriefsCurrentlyVisible.length > 0) {
        this._ds.dataLoaded = true;
        this._is.startMonitor();
        this._is.startTimer();
      } else {
        this._ds.dataLoaded = false;
        console.log('no data loaded');
        this._ds.setMemwordError(true);
      }
      this.showSpinner = false;
      console.warn('gallery loaded');
    });
  }

  // initialInactiveSettings() {
  //   console.log('Inactivity monitor starting');
  //   const idleTimeoutInSeconds: number = 60;
  //   const _isTimer = this.inactivityService.startWatching(idleTimeoutInSeconds).subscribe((isTimeOut: boolean) => {

  //     if (isTimeOut) {
  //       _isTimer.unsubscribe();
  //       const _url = this.router.url;
  //       if (_url === '/user/exclusion/gallery') {
  //         this._ds.setMemwordError(true);
  //       }
  //     }
  //   });
  // }

  CreateExcluder() {
    this.router.navigate(['/user/exclusion/new']);
  }

  SortOnChange() {
    switch (this.sort) {
      case 0:
        this.gsrv.SortByDateAscending();
        break;
      case 1:
        this.gsrv.SortByDateDescending();
        break;
      case 2:
        this.gsrv.SortByNameAscending();
        break;
      case 3:
        this.gsrv.SortByNameDescending();
        break;
    }
  }

  FilterOrganisations() {
    this.gsrv.ActiveSiteFilterId = 0;
    this.gsrv.PopulateSiteList().then(value => {
      this.gsrv.GetExcluderCards();
    });
  }

  ngOnDestroy() {
    console.log('***leaving gallery');
    this._is.killSubscriptions();
    this._ds.setMemwordError(false);
  }
}
