<div class="container global-seperator d-none d-md-block">
    <div class="row">
        <div class="col-md-6" style="text-align: right;">
            <svg class="icon"><image xlink:href="./assets/icons/Bacta Assets/Success icon.svg" alt="Products / Features" class="icon" style="width: 150px; height: 150px; margin: 10px" /></svg>
        </div>
        <div class="col-md-6" class="confirmed-text" style="text-align: left;">
            {{formMessage}}
        </div>
    </div>

    <div class="d-none d-md-block">
        <app-exclusion-card-md></app-exclusion-card-md>
    </div>

    <div class="row" style="max-height:75px;width:100%;text-align: right;flex-direction:row-reverse;">
        <input #btnPrint type="image" src=".\assets\icons\Bacta Assets\Print button blue.svg" style="cursor:pointer;max-width:100%;max-height:100px;margin-right:10px;filter:grayscale(0.75);" (click)="PrintExclusionTsAndCs();" disabled/>
        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="!printEnabled"></span>
    </div>
</div>

<div class="container global-seperator d-block d-md-none">
    <div class="row" style="width:100%;text-align: center;">
        <input type="image" src="./assets/icons/Bacta Assets/Success icon.svg" alt="Products / Features" style="width: 100%; height: 150px; margin: 10px" />  
    </div>
    <div class="row" style="width:100%;text-align: center;">
        <p class="confirmed-text" style="width:100%; text-align: center !important;">{{formMessage}}</p>
    </div>

    <div class ="d-block d-md-none" style="margin:auto">
        <app-exclusion-card-mobile></app-exclusion-card-mobile>
    </div>
    <div class="row" style="flex-direction: column;align-items: center;max-width:820px;">
        <input #btnPrintMobile type="image" src=".\assets\icons\Bacta Assets\Print button blue.svg" style="max-height:100px; filter:grayscale(0.75);" (click)="PrintExclusionTsAndCs();" disabled/>
        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="!printEnabled"></span>
    </div>
</div>


<div id="jspdf" style="margin: auto;width: 1200px;background-color: white;">
    <div id="jspdfInsert" style="margin:10px auto; width:1000px; size:2px;">
    </div>
</div>
