<div _ngcontent-fjt-c2="" class="sticky-top">
    <nav _ngcontent-fjt-c2="" class="main-header-view navbar navbar-dark bg-dark" style="background-color: #223464;">
        <div _ngcontent-fjt-c2="" class="container">
            <div _ngcontent-fjt-c2="" class="flex-container aligner-center">
                <div _ngcontent-fjt-c2="" class="flex-box-bactalogo"><img _ngcontent-fjt-c2="" alt="Bacta"
                        class="img-bactalogo" src="./assets/icons/iconarchive/bacta.png"></div>
                
            </div>
        </div>
    </nav>
</div>


<div class="container global-seperator">
    <app-loading *ngIf="isLoading"></app-loading>
    <div class="document-wrapper" *ngIf="!isLoading && !documentNotFound" [innerHTML]="document"></div>
    <div class="document-wrapper center" *ngIf="!isLoading && documentNotFound">
        <h1>Document not found</h1>
    </div>
</div>