<div class="container global-seperator d-none d-md-block">
    <div class="row">
        <div class="col-md-6" style="text-align: right;">
            <svg class="icon"><image xlink:href="./assets/icons/Bacta Assets/Success icon.svg" alt="Products / Features" class="icon" style="width: 150px; height: 150px; margin: 10px" /></svg>
        </div>
        <div class="col-md-6" class="confirmed-text" style="text-align: left;">
            Customer Interaction
        </div>
    </div>

    <div class="d-none d-md-block">
        <app-interaction-card-md></app-interaction-card-md>
    </div>
</div>

<div class="container global-seperator d-block d-md-none">
    <div class="row" style="width:100%;text-align: center;">
        <input type="image" src="./assets/icons/Bacta Assets/Success icon.svg" alt="Products / Features" style="width: 100%; height: 150px; margin: 10px" />  
    </div>
    <div class="row" style="width:100%;text-align: center;">
        <p class="confirmed-text" style="width:100%; text-align: center !important;">Customer Interaction</p>
    </div>

    <div class ="d-block d-md-none" style="margin:auto">
        <app-interaction-card-mobile></app-interaction-card-mobile>
    </div>
</div>
