<div class="container global-seperator" style="margin-top: 10px !important;">
    <div *ngIf="msrv.ComposeMode" class="row">
        <h5>Recipient Filter</h5>
    </div>
    <div *ngIf="msrv.ComposeMode" class="row">
        <div class="col-12" style="padding:0;">
            <app-recipient-filter></app-recipient-filter>
        </div>
    </div>

    <div class="row" style="margin-top:10px;">
        <h5>Message Details</h5>
    </div>

    <div class="row" *ngIf="!msrv.ComposeMode">
        <!--sender details-->
        <div class="col-md-6">
            <div class="input-group" id="inputFrom">
                <div class="input-group-prepend" style="width:25% !important">
                    <span class="input-group-text">From</span>
                </div>
                <input type="text" class="form-control" name="from" [value]="msrv.SelectedComposer" disabled>
            </div>
        </div>
    </div>

    <div class="row">
        <!--Subject-->
        <div class="col-12">
            <div class="input-group" id="inputSubject">
                <div class="input-group-prepend" style="width:25% !important">
                    <span class="input-group-text">Subject</span>
                </div>
                <input type="text" class="form-control" name="subject" [(ngModel)]="msrv.SelectedSubject" >
            </div>
        </div>
    </div>

    <div class="row" style="min-height:200px; padding-top: 20px;padding-bottom:20px;">
        <div class="col-md-12">
            <div style="background-color: #223464; padding: 10px; padding-top: 0px !important; border: 2px solid #223464; border-radius: 0.5em;">
                <div class="row">
                    <span style="color:white;"><b>MESSAGE</b></span>
                </div>
                <div class="row">
                    <!--[(ngModel)]="interaction.Notes"-->
                    <textarea id="ctrlMessage" [(ngModel)]="msrv.SelectedBody" style="width:100%; height: 150px;"></textarea>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-12" style="text-align: right;">
            <button type="button" class="btn btn-danger" (click)="CallCloseCompose();" style="margin-right: 10px;">Back</button>
            <button *ngIf="msrv.ComposeMode" type="button" class="btn btn-success" (click)="msrv.SendMessage();" style="margin-right: 10px;">Send</button>
        </div>
    </div>
</div>
