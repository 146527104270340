import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CustomerInteraction } from 'src/app/models/CustomerInteraction';
import { InteractionService } from 'src/app/services/interaction.service';

@Component({
  selector: 'app-interaction-description',
  templateUrl: './interaction-description.component.html',
  styleUrls: ['./interaction-description.component.scss']
})
export class InteractionDescriptionComponent implements OnInit {

  constructor(public isrv: InteractionService) { }
  public showEthnicityInfo: boolean = false;
  public lastControlClicked: number;

  ngOnInit() {
  }

  EthnicityClick() {
    this.showEthnicityInfo = !this.showEthnicityInfo;
    if (this.showEthnicityInfo) {
      this.lastControlClicked = 1;
    } else {
      this.lastControlClicked = 0;
    }
  }

  HideVariables() {
    if (this.lastControlClicked === 1) {
      this.lastControlClicked = 0;
    } else {
      this.showEthnicityInfo = false;
    }
  }
}