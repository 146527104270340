<div class="row" style="text-align: center;margin-top:10px;">
    <div class="col-md-12">
        <button type="button" id="btnExtend" class="btn btn-dark view-button-standard" (click)="PrintPDF();">         
            <span id="txtPrintButton" *ngIf="!showSpinner">Create PDF</span>
            <div *ngIf="showSpinner" class="spinner-border" role="status">
                <span class="sr-only"></span>
            </div> 
        </button>
    </div>
</div>

<div class="row" *ngIf="!showSpinner" style="margin-top:10px;">
    <div class="col-xs-12 col-md-4 exclusion-card" *ngFor="let exclusion of exclusions;" style="margin-top:45px;">
        <div class="row exclusion-image">
            <img class="exclusion-image-inner" [src]="exclusion.image">
        </div>
        <div class="row" style="width:100%;margin-top: 5px;">
            <b style="font-weight: 500;width:100%;text-align: center;">{{exclusion.name}}</b>
        </div>
        <div class="row" style="width:100%;text-align: center;">
            <b style="font-weight: 500;width:100%;text-align: center;">{{exclusion.reference}}</b>
        </div>
        <div class="row" style="width:100%;text-align: center;">
            <b style="font-weight: 500;width:100%;text-align: center;">{{exclusion.endDate}}</b>
        </div>
        <div class="row" style="width:100%;text-align: center;">
            <div class="col-4">
                <img *ngIf="ExcluderRecentlyBreached(exclusion);" style="height:22px;width:22px;" class="card-img" src=".\assets\icons\Bacta Assets\Breach icon.svg">
            </div>
            <div class="col-4">
                <img *ngIf="ExcluderIsCoolingOff(exclusion);" style="height:22px;width:22px;" class="card-img" src=".\assets\icons\Bacta Assets\24 icon.svg">
            </div>
            <div *ngIf="(exclusion.extensions);" class="col-4">
                <span class="card-img" style="font-size:125%;background-color:white;color:#64b32e;margin: auto;">+{{GetExcluderExtension(exclusion.extensions)}}</span>
            </div>
        </div>
    </div>
</div>