<div class="container global-seperator">
    <div class="row">
        <div class="col-md-12 padding-top padding-bottom">
            <h3><b>Action</b></h3>
        </div>
    </div>

    <div class="row">
        <div class="col-md-12">
            <h5><b>Discuss gambling management options</b></h5>
        </div>
    </div>

    <div class="row">
        <div class="col-md-6">
            <div class="flex-check-box">
                <div class="flex-check-prepend">TAKE A BREAK</div>
                <input id="chkTakeABreak" type="checkbox" class="flex-check-box-control" [(ngModel)]="chkTakeABreak" (click)="CheckboxClick($event, ActionTakeABreak)">
                <div class="interaction-group">
                    <div class="hidden-input interaction-group"></div>
                </div>
            </div>  
        </div>
        <div class="col-md-6">
            <div class="flex-check-box">
                <div class="flex-check-prepend">SIGNPOST TO GAMCARE</div>
                <input id="chkGamcare" type="checkbox" class="flex-check-box-control" [(ngModel)]="chkGamcare" (click)="CheckboxClick($event, ActionSignpostToGamcare)">
                <div class="interaction-group">
                    <div class="hidden-input interaction-group"></div>
                </div>
            </div>  
        </div>
    </div>

    <div class="row">
        <div class="col-md-6">
            <div class="flex-check-box">
                <div class="flex-check-prepend">TIME LIMITS</div>
                <input id="chkTimeLimits" type="checkbox" class="flex-check-box-control" [(ngModel)]="chkTimeLimits" (click)="CheckboxClick($event, ActionImposeTimeLimits)">
                <div class="interaction-group">
                    <div class="hidden-input interaction-group"></div>
                </div>
            </div>     
        </div>
        <div class="col-md-6">
            <div class="flex-check-box">
                <div class="flex-check-prepend">STAY IN CONTROL LEAFLET</div>
                <input id="chkStayInControlLeaflet" type="checkbox" class="flex-check-box-control" [(ngModel)]="chkStayInControlLeaflet" (click)="CheckboxClick($event, ActionStayInControlLeaflet)">
                <div class="interaction-group">
                    <div class="hidden-input interaction-group"></div>
                </div>
            </div>     
        </div>
    </div>

    <div class="row">
        <div class="col-md-6">
            <div class="flex-check-box">
                <div class="flex-check-prepend">SPEND LIMITS</div>
                <input id="chkSpendLimits" type="checkbox" class="flex-check-box-control" [(ngModel)]="chkSpendLimits" (click)="CheckboxClick($event, ActionImposeSpendLimits)">
                <div class="interaction-group">
                    <div class="hidden-input interaction-group"></div>
                </div>
            </div>        
        </div>
        <div class="col-md-6">
            <div class="flex-check-box">
                <div class="flex-check-prepend">ISSUE PLAY DIARY</div>
                <input id="chkIssuedPlayDiary" type="checkbox" class="flex-check-box-control" [(ngModel)]="chkIssuedPlayDiary" (click)="CheckboxClick($event, ActionIssuedPlayDiary)">
                <div class="interaction-group">
                    <div class="hidden-input interaction-group"></div>
                </div>
            </div>              
        </div>
    </div>

    <div class="row">
        <div class="col-md-6">
            <div class="flex-check-box">
                <div class="flex-check-prepend">CHANGE CATEGORY</div>
                <input id="chkChangeCategory" type="checkbox" class="flex-check-box-control" [(ngModel)]="chkChangeCategory" (click)="CheckboxClick($event, ActionChangeCategory)">
                <div class="interaction-group">
                    <div class="hidden-input interaction-group"></div>
                </div>
            </div>              
        </div>
        <div class="col-md-6">
            <div class="flex-check-box">
                <div class="flex-check-prepend">GAMBLE MANAGEMENT APP</div>
                <input id="chkGambleManagementApp" type="checkbox" class="flex-check-box-control" [(ngModel)]="chkGambleManagementApp" (click)="CheckboxClick($event, ActionGambleManagementApp)">
                <div class="interaction-group">
                    <div class="hidden-input interaction-group"></div>
                </div>
            </div>           
        </div>
    </div>
    
    <div class="row">
        <div class="col-md-6">
            <div class="flex-check-box">
                <div class="flex-check-prepend">MONITOR BEHAVIOUR/GAMBLING</div>
                <input id="chkMonitor" type="checkbox" class="flex-check-box-control" [(ngModel)]="chkMonitor" (click)="CheckboxClick($event, ActionMonitorBehaviour)">
                <div class="interaction-group">
                    <div class="hidden-input interaction-group"></div>
                </div>
            </div>              
        </div>
        <div class="col-md-6">
            <div class="flex-check-box">
                <div class="flex-check-prepend">TIME OUT</div>
                <input id="chkTimeOut" type="checkbox" class="flex-check-box-control" [(ngModel)]="chkTimeOut" (click)="CheckboxClick($event, ActionTimeOut)">
                <div class="interaction-group">
                    <div class="hidden-input interaction-group"></div>
                </div>
            </div>              
        </div>
    </div>
    <div class="row" style="min-height:200px; padding-top: 20px;padding-bottom:20px;">
        <div class="col-md-12">
            <div style="background-color: #223464; padding: 10px; padding-top: 0px !important; border: 2px solid #223464; border-radius: 0.5em;">
                <div class="row">
                    <span style="color:white;">Notes</span>
                </div>
                <div class="row">
                    <!--[(ngModel)]="interaction.Notes"-->
                    <textarea id="ctrlNotes" class="color-bacta-standard" style="width:100%; height: 150px;" [(ngModel)]="interaction.Notes"></textarea>
                </div>
            </div>
        </div>
    </div>
    <!--
        <div class="row" style="padding-bottom: 20px;">
            <div class="col-md-6">
                <div class="flex-check-box">
                    <div class="flex-check-prepend">NO ACTION TAKEN</div>
                    <input id="chkNoActionTaken" type="checkbox" class="flex-check-box-control" [(ngModel)]="interaction.NoActionTaken">
                    <div class="interaction-group">
                        <div class="hidden-input interaction-group"></div>
                    </div>
                </div>              
            </div>
            <div class="col-md-6">
                <div class="flex-check-box">
                    <div class="flex-check-prepend">BARRED</div>
                    <input id="chkBarred" type="checkbox" class="flex-check-box-control" [(ngModel)]="chkBarred" (click)="CheckboxClick($event, ActionBanned)">
                    <div class="interaction-group">
                        <div class="info-input interaction-group" (click)="ToggleToolTip()">i</div>
                    </div>
                    <div class="tool-tip-flex">
                        <span #ttp class="tool-tip-text">
                            <h5 class="info-header">Information:</h5>
                            <ul>
                                <li>Barred customers for SR/safer gambling reasons only</li>
                            </ul>
                        </span>
                    </div>
                </div>               
            </div>
        </div>
    -->
</div>