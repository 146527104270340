<div class="container">
    <div #table class="table-responsive bacta-table-outline" style="margin-top:10px;">
        <table class="table">
            <thead class="table-header">
                <th class="table-header-inner" *ngFor="let key of assrv.MetaDataKeys">{{key}}</th>
            </thead>
            <tbody class="table-row" *ngFor="let result of assrv.SearchResult;" style="background-color: white; color:red;" (click)="assrv.SearchRecordByClick(result);" (auxclick)="assrv.SearchRecordByAuxClick(result);">
                <td class="table-row-inner" *ngFor="let key of assrv.MetaDataKeys">
                    <span>{{result.MetaData[key]}}</span>       
                </td>            
            </tbody>
        </table>
    </div>
</div>  



<!--
<div class="container">
    <div #table class="table-responsive bacta-table-outline" style="margin-top:10px;">
        <table class="table">
            <thead class="table-header">
                <th class="table-header-inner" *ngFor="let key of assrv.MetaDataKeys">{{key}}</th>
            </thead>
            <tbody class="table-row" *ngFor="let test of assrv.MetaData; let i of index;" style="background-color: white; color:red;" (click)="assrv.SearchRecordByClick(test);">
                <td class="table-row-inner" *ngFor="let x of assrv.MetaDataKeys">
                    <span *ngIf="test[x]">{{test[x]}}</span>
                    <div *ngIf="test[x] === false" class="spinner-border" role="status">
                        <span class="sr-only"></span>
                    </div>        
                </td>            
            </tbody>
        </table>
    </div>
</div>  
-->