<div *ngIf="!_dataready">
    <div class="col-md-12" style="text-align: center;" style="height:100vh;">
        <app-loading></app-loading>
    </div>
</div>

<div *ngIf="errsrv.showOops" class="app-opps-outer">
    <app-oops></app-oops>
</div>

<div [class.disabled]="errsrv.showOops" *ngIf="_dataready" class="main-view" style="z-index: 0;">
    <div class="sticky-top">
        <nav class="main-header-view navbar navbar-dark bg-dark" style="background-color: #223464;">
            <div class="container">
                <div class="flex-container aligner-center">
                    <div [class.disabled]="errsrv.showOops" class="flex-box-bactalogo" (click)="ToggleFeatureMenu();">
                        <img class="img-bactalogo" alt="Bacta" src="./assets/icons/iconarchive/bacta.png"  />
                    </div>

                    <div [class.disabled]="errsrv.showOops" class="flex-box-controls aligner-center" (click)="ToggleFeatureMenu();">
                        <div class="d-none d-md-block aligner-bottom">
                            <p style="color:white;margin-top:10px;margin-bottom: 10px;size:16px;">Welcome: <b>{{asrv.GetUsersFullName()}}</b></p>                    
                        </div>
                    </div>
                    <input [class.disabled]="errsrv.showOops" type="image" class="img-home" alt="Bacta" src="./assets/icons/Bacta Assets/Home.svg" routerLink="/user/features" style="width:50px;margin-right:20px;" />

                    <nav [class.disabled]="errsrv.showOops" class="navbar navbar-dark bg-dark" style="outline: none;max-height:100px;max-width:100px; padding: 0 !important;">
                        <button #advancedSearchToggle class="navbar-toggler custom-toggler" (click)="AdvancedSearchClicked();" (dblclick)="AdvancedSearchClicked();" type="button" data-toggle="collapse" data-target="#navbarToggleSearchContent" aria-controls="navbarToggleSearchContent" aria-expanded="false" aria-label="Toggle navigation" style="outline: none !important;border-color:#223464;">
                            <img type="image" src="./assets/icons/Bacta Assets/Search.svg"  style="width:35px;" />
                        </button>
                    </nav>

                    <nav [class.disabled]="errsrv.showOops" class="navbar navbar-dark bg-dark" style="outline: none;">
                        <div *ngIf="unreadMessages" class="messaging-alert">{{unreadMessageCount}}</div>
                        <button #dropDownToggle class="navbar-toggler custom-toggler" (click)="DropDownClicked();" (dblclick)="DropDownClicked();" type="button" data-toggle="collapse" data-target="#navbarToggleExternalContent" aria-controls="navbarToggleExternalContent" aria-expanded="false" aria-label="Toggle navigation" style="outline: none !important;border-color:#223464;">
                            <div class="hamburger-menu"></div>  
                            <div class="hamburger-menu"></div>  
                            <div class="hamburger-menu"></div>  
                        </button>
                    </nav>
                </div>
            </div>
        </nav>

        <!--Drop Down Window-->
        <div class="collapse" id="navbarToggleExternalContent" style="max-width:100vw;">
            <div class="bg-dark p-4">
                <div class="collapsed-content" style="max-width:95%;text-align: center;">
                    <div class="d-block d-md-none">
                        <span class="text-white">Welcome: <b>{{asrv.GetUsersFullName()}}</b></span>
                    </div>
                    <div class="collapse-menu-container">
                        <nav class="navbar navbar-dark bg-dark inner-menu">
                            <div class="navbar-toggler inner-menu" (click)="NavigateToMessaging();"  data-toggle="collapse" data-target="#messagingToggleExternalContent" aria-controls="navbarToggleExternalContent" aria-expanded="false" aria-label="Toggle navigation">
                                <span class="nav-toggle-header-text">Messaging</span>
                            </div>
                        </nav>
                    </div>
                    <div class="collapse-menu-container">
                        <nav class="navbar navbar-dark bg-dark inner-menu">
                            <div class="navbar-toggler inner-menu" (click)="NavigateToReportsBuilder();"  data-toggle="collapse" data-target="#reportingToggleExternalContent" aria-controls="navbarToggleExternalContent" aria-expanded="false" aria-label="Toggle navigation">
                                <span class="nav-toggle-header-text">Reporting</span>
                            </div>
                        </nav>
                    </div>
                    <div class="collapse-menu-container">
                        <nav class="navbar navbar-dark bg-dark inner-menu">
                            <div #adminDropDownToggle class="navbar-toggler inner-menu" (click)="AdminDropDownClicked();" data-toggle="collapse" data-target="#adminToggleExternalContent" aria-controls="navbarToggleExternalContent" aria-expanded="false" aria-label="Toggle navigation">
                                <span class="nav-toggle-header-text">Admin</span>
                                <img class="nav-toggle-header-img" src="./assets/icons/Bacta Assets/Drop down arrow blue.svg" />
                            </div>
                        </nav>
                        <div class="collapse" id="adminToggleExternalContent" style="max-width:100vw;"> 
                            <div class="collapse-menu-item" (click)="NavigateToOrganisationAdmin();"><span class="inner-menu-item">Organisation</span></div>
                            <div class="collapse-menu-item" (click)="NavigateToSiteAdmin();"><span class="inner-menu-item">Site</span></div>
                            <div class="collapse-menu-item" (click)="NavigateToUserAdmin();"><span class="inner-menu-item">User</span></div>
                            <div style="margin-bottom:20px;"></div>
                        </div>
                    </div>
                    <!--*ngIf="asrv.SuperUser"-->
                    <div *ngIf="FeatureNotReady" class="collapse-menu-container">
                        <nav class="navbar navbar-dark bg-dark inner-menu">
                            <div #adminToolsDropDownToggle class="navbar-toggler inner-menu" (click)="AdministrationToolsDropDownClicked();" data-toggle="collapse" data-target="#adminToolsToggleExternalContent" aria-controls="navbarToggleExternalContent" aria-expanded="false" aria-label="Toggle navigation">
                                <span class="nav-toggle-header-text">Administration Tools</span>
                                <img class="nav-toggle-header-img" src="./assets/icons/Bacta Assets/Drop down arrow blue.svg" />
                            </div>
                        </nav>
                        <div class="collapse" id="adminToolsToggleExternalContent" style="max-width:100vw;"> 
                            <div class="collapse-menu-item" (click)="ToggleElevationMode();" [style.backgroundColor]="asrv.ElevatedMode? 'green':'red'"><span class="inner-menu-item">Toggle Elevation Mode</span></div>
                            <div class="collapse-menu-item" (click)="ReloadDropDownControllers();"><span class="inner-menu-item">Reload Dropdown Controllers</span></div>
                            <div class="collapse-menu-item" (click)="UnlockAllButtons();" [style.backgroundColor]="asrv.UnlockedButtons? 'green':'red'"><span class="inner-menu-item">Toggle Locked Buttons On/Off</span></div>
                        </div>
                    </div>
                    <div class="collapse-menu-container">
                        <div><h4 class="text-white inner-menu" (click)="LogOut();"><u>Sign Out</u></h4></div>
                    </div>
                </div>
            </div>
        </div>

        <!--Advanced Search Window-->
        <div class="collapse" id="navbarToggleSearchContent" style="max-width:100vw;">
            <div class="bg-dark p-4">
                <div>
                    <!--
                        <div class="queryList">
                            <div *ngIf="assrv.ShowEditNavigation" class="row" style="margin:auto;max-width:768px;">
                                <div class="col-md-12">
                                    <div class="input-group" id="inputFirstName">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text">EDIT</span>
                                        </div>
                                        <select class="form-control drop-down-purple" name="Property" [(ngModel)]="NavigatedId" (ngModelChange)="NavigateSearchFilter();">
                                            <option></option>
                                            <option *ngFor="let searchFilter of assrv.SearchFilters" [ngValue]="searchFilter.SetId">{{searchFilter.Property}}: {{searchFilter.SearchTerm}} in {{searchFilter.Domain}}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>

                    <div class="editor">
                        <div class="row" style="margin:auto;max-width:768px;">
                            <div class="col-md-12">
                                <div class="input-group" id="inputFirstName">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">DOMAIN</span>
                                    </div>
                                    <select class="form-control drop-down-purple" name="Domain" [(ngModel)]="assrv.Domain">
                                        <option [ngValue]="-1"></option>
                                        <option *ngFor="let domain of assrv.DomainFilters" [ngValue]="domain.MiddleEndValue">{{domain.FrontendValue}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <span *ngFor="let searchTerm of assrv.SearchTerms">
                            <div class="row" style="margin:auto;max-width:768px;">
                                <div class="col-md-12">
                                    <div class="input-group" id="inputFirstName">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text">PROPERTY</span>
                                        </div>
                                        <select class="form-control drop-down-purple" name="Property" [(ngModel)]="searchTerm.Property">
                                            <option [ngValue]="-1"></option>
                                            <option *ngFor="let property of assrv.PropertyFilters" [ngValue]="property.ServerValue">{{property.FrontendValue}}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="row" style="margin:auto;max-width:768px;">
                                <div class="col-md-12">
                                    <div class="input-group" id="inputFirstName">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text">SEARCH TERM</span>
                                        </div>
                                        <input type="text" class="form-control" name="SearchPhrase" [(ngModel)]="searchTerm.SearchTerm" (keydown.enter)="assrv.Search()">
                                    </div>
                                </div>
                            </div>   
                        </span>
                    </div>
                    <div *ngIf="assrv.ShowSearchString" class="row" style="margin:auto;max-width:768px;margin-top:10px;text-align: center;">
                            <p style="color:white;">{{assrv.SearchString}}</p>
                    </div>
                    -->
                    <div class="row" style="margin:auto;max-width:768px;margin-top:10px;text-align: center;">
                        <!--Feature-->
                        <div class="col-md-12">
                            <div class="input-group" id="inputFirstName">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">Feature</span>
                                </div>
                                <select class="form-control drop-down-purple" name="Domain" [(ngModel)]="assrv.SearchingFeatureId">
                                    <option [ngValue]="-1"></option>
                                    <option *ngFor="let feature of assrv.Features" [ngValue]="feature.id">{{feature.display}}</option>
                                </select>
                            </div>
                        </div>
                        <!--Domain-->
                        <div class="col-md-12">
                            <div class="input-group" id="inputFirstName">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">Search By</span>
                                </div>
                                <select class="form-control drop-down-purple" name="Domain" [(ngModel)]="assrv.SearchingDomainId">
                                    <option [ngValue]="-1"></option>
                                    <option *ngFor="let domain of assrv.Features[assrv.SearchingFeatureId].domains" [ngValue]="domain.id">{{domain.display}}</option>
                                </select>
                            </div>
                        </div>
                        <!--Property-->
                        <div class="col-md-12">
                            <span *ngFor="let property of assrv.Domains[assrv.SearchingDomainId].properties">
                                <div class="input-group" id="inputFirstName">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">{{property.name}}</span>
                                    </div>
                                    <input *ngIf="property.type === 'string'" type="text" class="form-control" name="SearchPhrase" [(ngModel)]="property.value" (keydown.enter)="assrv.Search()">
                                    <input *ngIf="property.type === 'datetime'" class="form-control d-none d-lg-block" matInput [matDatepicker]="dp1" (dateInput)="addEvent('input', $event)" (dateChange)="addEvent('change', $event)" [(ngModel)]="property.value">
                                    <div *ngIf="property.type === 'datetime'" class="input-group-append d-none d-lg-block">
                                        <mat-datepicker-toggle class="form-control-date-toggle d-none d-lg-block" matSuffix [for]="dp1"></mat-datepicker-toggle>
                                    </div>
                                    <mat-datepicker *ngIf="property.type === 'datetime'" class="d-none d-lg-block" touchUi #dp1></mat-datepicker>
                                    <input *ngIf="property.type === 'datetime'" type="date" class="form-control date-picker-padding d-lg-none" [(ngModel)]="property.value">
                                </div>
                            </span>
                        </div>
                        <div class="row" style="margin:auto;max-width:768px;margin-top:10px;text-align: center;">
                            <!--<button class="btn btn-primary advanced-search-button" (click)="assrv.AddPropertyToSetId()">ADD NEW PROPERTY</button>-->
                            <!--<button class="btn btn-primary advanced-search-button" (click)="assrv.AddSearchTermCollection()" disabled>ADD NEW DOMAIN</button>-->
                            <!--<button class="btn btn-secondary advanced-search-button" (click)="assrv.ShowSearchQuery();"><span *ngIf="!assrv.ShowSearchString">CHECK</span><span *ngIf="assrv.ShowSearchString">HIDE</span> QUERY</button>-->
                            <!--<button class="btn btn-warning advanced-search-button" (click)="assrv.RemoveLastProperty()">REMOVE PROPERTY</button>-->
                            <!--<button class="btn btn-warning advanced-search-button" (click)="assrv.RemoveSearchTerm()" disabled>REMOVE DOMAIN</button>-->
                            <input class="advanced-search-button" type="image" src="./assets/icons/Bacta Assets/CLEAR_FORM.svg" (click)="assrv.ReinitializeSearchModel()" />
                            <input class="advanced-search-button" type="image" src="./assets/icons/Bacta Assets/SEARCH GREEN.svg" (click)="Search()" />
                        </div>
                    </div>
               </div> 
            </div>
        </div>
    </div>

    <!--https://www.gamcare.org.uk/self-help/self-assessment-tool/-->
    <div class="main-form-view" [style.visibility]="displayFeatures?'visible':'hidden'" [style.height]="displayFeatures?'100%':'0vh'" (click)="HideDropDowns()">
        <app-feature-collection [class.disabled]="errsrv.showOops" (onSelection)="MenuSelectionMade($event)" *ngIf="displayFeatures"></app-feature-collection>
    </div>
    <div class="main-form-view" [style.visibility]="displayFeatures?'hidden':'visible'" [style.height]="displayFeatures?'0vh':'100%'" (click)="HideDropDowns()">
        <router-outlet [class.disabled]="errsrv.showOops"></router-outlet>
    </div> 

<!--
    <div class="main-form-scrolldown" style="height:2vh;" [style.visibility]="displayFeatures?'hidden':'visible'" [style.height]="displayFeatures?'0vh':'2vh'">
        <span class="scrolldown-arrow" style="height:100%;text-align:right;color:#223464;font-size:50px">\/</span>
    </div>
-->

    <!-- Footer! -->
    <!-- Bumper Here before next update -->
    <!--<footer class="fixed-bottom" style="height: 7vh; background-color: darkred; color: white">
        <div class="container">
            <div class="flex-container">
                <div class="flex-box-bactalogo">
                <a href="https://bacta.org.uk/iump-login/"><img class="img-bactalogo" alt="Bacta" src="./assets/icons/Bacta Assets/bacta portal logo white.svg" style="width:200px;margin-top:10px;margin-bottom: 10px;" /></a>
                </div>
                <div class="flex-box-controls">
                    Requires Form Action
                    <input type="submit" value="Sign Out" style="background-color:rgba(0, 0, 0, 0); border-color:rgba(0, 0, 0, 0); color: white;">
                    Requires Form Action
                    <input type="image" alt="Settings" src="./assets/icons/Bacta Assets/Settings.svg" style="height: 2.5em;"/>
                </div>
            </div>
        </div>
    </footer>-->
</div>

<div #status *ngIf="showStatus" style="position: absolute; bottom:3px; right:3px; color:black;">
</div>