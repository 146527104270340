import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-forgotten-password',
  templateUrl: './forgotten-password.component.html',
  styleUrls: ['../login/login.component.scss']
})
export class ForgottenPasswordComponent implements OnInit {
  showSpinner = false;
  public Username: string;

  // admin@bacta-selfexclusion.org.uk

  constructor(
    public dsrv: DataService,
    private router: Router
  ) { }

  ngOnInit() {
  }

  GetResetLink() {
    this.showSpinner = true;
    if (this.Username) {
      this.dsrv.Get<Token>(`api/View_CredentialResetTokens?CredentialResetRequest.login=${this.Username}`).subscribe(res => {
        if (res.Token) {
          alert('Please check your emails for the reset link. It may appear in the spam/junk folder.');
          this.router.navigate(['*']);
          this.showSpinner = false;
        } else {
          this.showSpinner = false;
        }
      })
    }
  }

}

interface Token {
  Token: string;
  Edited: Date;
  Edited_ActorId: number;
}
