<div *ngIf="isrv.ViewDataLoading" class="container global-seperator" style="min-height:400px;">
    <span>
        <app-loading></app-loading>
    </span>
</div>


<div *ngIf="!isrv.ViewDataLoading" class="container global-seperator">
    <div class="d-none d-md-block">
        <app-interaction-card-md [(formEditable)]="formEditable"></app-interaction-card-md>
    </div>

    <div class ="d-block d-md-none" style="margin:auto">
        <app-interaction-card-mobile></app-interaction-card-mobile>
    </div>

    
    <div *ngIf="(viewForm === appViewInteraction) && !(showDefaultButtons)" class="row" style="min-height:200px; padding-top: 20px;padding-bottom:20px;">
        <div class="col-md-12">
            <div style="background-color: #223464; padding: 10px; padding-top: 0px !important; border: 2px solid #223464; border-radius: 0.5em;">
                <div class="row">
                    <span style="color:white;">Notes</span>
                </div>
                <div class="row">
                    <!--[(ngModel)]="interaction.Notes"-->
                    <textarea id="ctrlNotes" class="color-bacta-standard" style="width:100%; height: 150px;" [(ngModel)]="isrv.Interaction.Notes"></textarea>
                </div>
            </div>
        </div>
    </div>

    <div class="button-container global-seperator" *ngIf="(viewForm === appViewInteraction) && (showDefaultButtons)">
        <div class="button-divider">
            <button *ngIf="!lockReview" type="button" id="btnExtend" class="btn btn-dark view-button" (click)="ShowReview()">REVIEW</button>
            <button *ngIf="lockReview" type="button" id="btnExtend" class="btn btn-dark view-button-disabled" disabled>REVIEW</button>
        </div> 
        <div class="button-divider">
            <button *ngIf="!lockCancelReview" type="button" id="btnReinstate" class="btn btn-dark view-button" (click)="ShowCancelReview()">CANCEL REVIEW</button>
            <button *ngIf="lockCancelReview" type="button" id="btnReinstate" class="btn btn-dark view-button-disabled" disabled>CANCEL REVIEW</button>
        </div> 
        <!--
        <div class="button-divider">
            <button type="button" id="btnMoreInfo" class="btn btn-dark view-button-disabled" (click)="ShowUploadCCTV()" disabled>UPLOAD CCTV</button>
        </div> 
        
        <div class="button-divider">
            <button type="button" id="btnUpdateExclusion" class="btn btn-dark view-button" (click)="ShowUpdateDetails()" >UPDATE DETAILS</button>
        </div> 
        -->
        <div class="button-divider">
            <button type="button" id="btnReinstate" class="btn btn-dark view-button" (click)="ShowMoreInfo()">MORE INFO</button>
        </div> 
    </div>

    <div class="button-container global-seperator" *ngIf="(viewForm === appViewInteraction) && !(showDefaultButtons)">
        <div class="button-divider">
            <button type="button" id="btnExtend" class="btn btn-dark view-button" (click)="CompleteInteraction()">COMPLETE INTERACTION</button>
        </div> 
        <div class="button-divider">
            <button type="button" id="btnReinstate" class="btn btn-dark view-button" (click)="ShowCancelReview()">ABANDON INTERACTION</button>
        </div> 
    </div>
    

    <div class="form-container global-seperator" *ngIf="!(viewForm === appViewInteraction)">
        <form>
            <div class="form-group">
                <app-review *ngIf="(viewForm === appReview)" (ShowDefaultApp)="ShowDefaultApp()" (SetLoadingData)="SetLoadingData();" (ReloadAndShowMainApp)="ReloadAndShowMainApp();"></app-review>
                <app-reinstated-se-review *ngIf="(viewForm === appReinstateSEReview)" [(interaction)]="interaction" (ShowDefaultApp)="ShowDefaultApp()"></app-reinstated-se-review>
                <app-cancel-review *ngIf="(viewForm === appCancelReview)" [(interaction)]="interaction" (ShowDefaultApp)="ShowDefaultApp()"></app-cancel-review>
                <app-upload-cctv *ngIf="(viewForm === appUploadCCTV)"></app-upload-cctv>
                <app-update-details *ngIf="(viewForm === appUpdateDetails)" (SetLoadingData)="SetLoadingData();" (ReloadAndShowMainApp)="ReloadAndShowMainApp();" (ShowDefaultApp)="ShowDefaultApp()"></app-update-details>
                <app-interaction-more-info *ngIf="(viewForm === appMoreInfo)" (SetLoadingData)="SetLoadingData();" (ReloadMainApp)="ReloadMainApp();" (ShowDefaultApp)="ShowDefaultApp()"></app-interaction-more-info>
            </div>
        </form>
    </div>
</div>


