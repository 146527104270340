import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { Honorific } from '../models/Honorific';
import { Gender } from '../models/Gender'
import { Observable } from 'rxjs';
import { IdentificationDocument } from '../models/IdentificationDocumentKey';
import { ExclusionDurationOption } from '../models/ExclusionDurationOption';
import { ExclusionReason } from '../models/ExclusionReason';
import { Site } from '../models/Site';
import { ActorCompositeModel } from '../models/ActorCompositeModel';
import { Breach } from '../models/Breach';
import { OrganisationBrief } from '../models/OrganisationBrief';
import { Reinstate } from '../models/Reinstate';
import { ActorNamesCompositeModel } from '../models/ActorNamesCompositeModel';
import { Organisation } from '../models/Organisation';
import { SiteDetail } from '../models/SiteDetail'
import { Actors_Names, Names } from '../models/Name'
import { EmployeeDetails } from '../models/EmployeeDetails';
import { ActorRole } from '../models/ActorRole';
import { ApiUsage } from '../models/ApiUsage';
import { Tag } from '../models/Tag';
import { PostalAddressFieldClasses } from '../models/PostalAddressFieldClasses';
import { ContactsPostalAddress } from '../models/ContactsPostalAddress';
import { Token } from '../models/Token';
import { ExclusionSites } from '../models/Exclusion_Sites';
import { CommunicationContactTags } from '../models/CommunicationContactTags';
import { CommunicationContacts_CommunicationContactTags, PostObjects } from '../models/PostObjects';
import { Communication } from '../models/Communication';
import { Credentials, CredentialsBrief } from '../models/Credentials';
import { BiologicalUserInfo, Name, NonBiologicalName } from '../models/actor/Name';
import { Router } from '@angular/router';
import { MessageDisplay } from '../models/messaging/MessageDisplay';
import { Views_MediaModel } from '../models/InteractionExclusion/Views_MediaModel';
import { UserDocumentation } from '../models/UserDocumentation';
import { View_ReinstatementsCompositeModel } from '../models/InteractionExclusion/Reinstatement/View_ReinstatementsCompositeModel';
import { Actor_Settings } from '../models/Actor_Settings';
import { Post_InteractionReferenceCompositeModel, View_InteractionReferenceCompositeModel } from '../models/InteractionExclusion/View_InteractionReferenceCompositeModel';
import { BackgroundColor } from '../models/Globals/BackgroundColor';
import { FontColor } from '../models/Globals/FontColor';
import { Positioning } from '../models/Globals/Positioning';
import { Border } from '../models/Globals/Border';
import { TagValues } from '../models/Globals/TagValues';
import { TableProperties } from '../models/Globals/TableProperties';
import { ClassKeyIds } from '../models/Globals/ClassKeyIds';
import { DropDownItem, Select } from '../models/controllers/Standard Controllers/Select';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class ActorService {
  private _superUser: boolean;
  public get SuperUser(): boolean {
    return this._superUser;
  }

  private _elevatedMode: boolean;
  public get ElevatedMode(): boolean {
    return this._elevatedMode;
  }

  private _unlockedButtons: boolean;
  public get UnlockedButtons(): boolean {
    return this._unlockedButtons;
  }

  public Globals = new Globals();
  public acknowledgementVersion: string = "1_0_0_0";

  // Rewrite the below to recheck if new records have been created, else users need to reload in order for certain tasks to perform.
  // Organisation
  public OrganisationSelect: Select;
  public OrganisationIds: number[] = [];
  public DefaultOrganisationNameCollection: NonBiologicalName[] = [];

  // Sites
  public DefaultSiteId;
  private _defaultSiteIds: number[] = [];
  public get DefaultSiteIds(): number[] {
    return this._defaultSiteIds;
  }
  public SiteSelect: Select;
  public SiteIds: number[] = [];
  public DefaultSiteNameCollection: NonBiologicalName[] = [];

  public MemWord;

  // Employee/Colleague
  public ColleagueSelect: Select;
  public ColleagueIds: number[] = [];
  public DefaultColleagueCollection: BiologicalUserInfo[] = [];
  // End of revision

  public UserOrganisationId: number;
  public UserOrganisationName: any = false;
  public UserDocumentation: UserDocumentation = new UserDocumentation();
  public ReasonOptions: ExclusionReason[] = [];
  public SiteGeoLocations: Site[] = [];
  public IdentificationDocumentTypes: IdentificationDocument[] = [];
  private _actorCompositeModel: ActorCompositeModel = new ActorCompositeModel();
  private _honorifics: Honorific[] = [];
  private _genders: Gender[] = [];
  private _postObjects: PostObjects = new PostObjects();
  public readonly Roles: ActorRole[] = [
    {ActorRoleId: 1, Name: "System", Edited_ActorId: 1, Edited: new Date()},
    {ActorRoleId: 2, Name: "System Administrator", Edited_ActorId: 1, Edited: new Date()},
    {ActorRoleId: 3, Name: "Bacta Administrator", Edited_ActorId: 1, Edited: new Date()},
    {ActorRoleId: 4, Name: "Organisation Administrator", Edited_ActorId: 1, Edited: new Date()},
    {ActorRoleId: 5, Name: "Supervisor", Edited_ActorId: 1, Edited: new Date()},
    {ActorRoleId: 6, Name: "User", Edited_ActorId: 1, Edited: new Date()}
  ]

  public rolesAvailable: ActorRole[];

  // Notes:
  // In the event of reloads, store the smaller data into the session storage so it can be retrieved faster.
  private SessionStorageItemExists(name: string): boolean {
    if (sessionStorage.getItem(name) === null) {
      return false
    }
    return true;
  }

  private ParseStorageItem(name: string): any {
    return JSON.parse(sessionStorage.getItem(name));
  }

  public ToggleElevationMode(): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      if (this.SuperUser) {
        this._elevatedMode = !this._elevatedMode;
        if (!this._elevatedMode) {
          this._unlockedButtons = false;
        }
        resolve(this._elevatedMode);
      } else {
        this._elevatedMode = false;
        resolve(false);
      }
    })
  }

  public ToggleLockedButtons(): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      if (this.ElevatedMode) {
        this._unlockedButtons = !this._unlockedButtons;
        resolve(this._unlockedButtons);
      } else {
        this._unlockedButtons = false;
        resolve(false);
      }
    })
  }

  // Set the generic values used during the system
  constructor(
    public dsrv: DataService, 
    private router: Router,
    private _api: ApiService,
    ) { 
    
    
   
  }

  public loadGendersToStorage() {
    let genderSubscription = this.dsrv.Get<Gender[]>("api/genders").subscribe(res => {
      genderSubscription.unsubscribe();
      this._genders = res;
      sessionStorage.setItem('genders', JSON.stringify(this._genders));
    })
  }

  public loadHonorificsToStorage() {
    let honorificSubscription = this.dsrv.Get<Honorific[]>("api/honorifics").subscribe(res => {
      honorificSubscription.unsubscribe();
      this._honorifics = res;
      sessionStorage.setItem('honorifics', JSON.stringify(this._honorifics));
    });
  }

  // Note, update this to ActorService across the system so dataService is being used less.
  public GetMyUserID() : number {
    return +sessionStorage.getItem('BactaUserID');
  }

  public IsLoggedUserAdmin(): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      this.GetActorRole().then(value => {
        this.GetSitesWithinRadius(101, 1000).subscribe(res => {
          this.SiteGeoLocations = res;
        }, err => {
          this.SiteGeoLocations = [];
        })
        resolve(value.ActorRoleId <= 3? true: false);
      })
    })
  }

  public GetLoggedUserDetails(): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      this.dsrv.Get<EmployeeDetails>(`api/view_employeecompositemodel/${this.dsrv.GetMyUserID()}`).subscribe(res => {
        if (res.SettingCollection.some(i => i.SettingKeyId === 61)) {
          this.DefaultSiteId = +res.SettingCollection.find(i => i.SettingKeyId === 61).Value;
          resolve(true);
        } else {
          this.DefaultSiteId = null;
          resolve(false);
        }
        if (res.SettingCollection.some(i => i.SettingKeyId === 62)) {
          this.MemWord = res.SettingCollection.find(i => i.SettingKeyId === 62).Value;
          this.dsrv.memWord = this.MemWord;
          resolve(true);
        } else {
          this.MemWord = null;
          this.router.navigate(
            ['user/admin/user-panel'],
            { queryParams: { uid: res.ActorId } }
          );
          resolve(false);
        }
      })
    })
  }

  public IsLoggedUserBactaAdmin(): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      this.GetActorRole().then(value => {
        resolve(value.ActorRoleId <= 3? true: false);
      })
    });
  }

  public IsLoggedUserOrganisationAdmin(): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      this.GetActorRole().then(value => {
        resolve(value.ActorRoleId <= 4? true: false);
      })
    });
  }

  public GetActorCompositeModel(): Promise<ActorCompositeModel> {
    return new Promise<ActorCompositeModel>(resolve => {
      this._actorCompositeModel = this.GetActorCompositeModelFromStorage();
      if (this._actorCompositeModel) {
        if (this._actorCompositeModel.ActorId === this.dsrv.GetMyUserID()) {
          this.GetActorRole(this._actorCompositeModel.ActorId).then(value => {
            this.SetAvailableUserRoles(value.ActorRoleId);
            resolve(this._actorCompositeModel);
          });
        }
      }

      let subscription = this.GetActorCompositeModelForId(this.dsrv.GetMyUserID()).subscribe(res => {
        subscription.unsubscribe();
        sessionStorage.setItem('_actor_composite_model_', JSON.stringify(res));
        this._actorCompositeModel = res;
        this.GetActorRole(this._actorCompositeModel.ActorId).then(value => {
          this.SetAvailableUserRoles(value.ActorRoleId);
          resolve(this._actorCompositeModel);
        });
      }, error => {
        this.router.navigate(['/#'])
      });
    })
  }

  private SetAvailableUserRoles(actorRoleId: number) {
    this.rolesAvailable = [];
    this.Roles.forEach(role => {
      if (role.ActorRoleId >= actorRoleId) {
        this.rolesAvailable.push(role);
      }
    })
  }

  public GetActorCompositeModelForId(actorId): Observable<ActorCompositeModel> {
    return this.dsrv.Get<ActorCompositeModel>('api/View_ActorCompositeModel/' + actorId)
  }

  private GetActorCompositeModelFromStorage(): ActorCompositeModel {
    return JSON.parse(sessionStorage.getItem('_actor_composite_model_'));
  }

  public GetUsersFullName(): string {
    if (this._actorCompositeModel.Actor_Names) {
      return this._actorCompositeModel.Actor_Names.find(i => i.NameClassKeyId === 1).Name.Name + ' ' + this._actorCompositeModel.Actor_Names.find(i => i.NameClassKeyId === 2).Name.Name;
    }
  }

  public GetHonorifics() : Promise<Honorific[]> {
    return new Promise<Honorific[]>(resolve => {
      if (this._honorifics.length > 0) {
        resolve(this._honorifics);
      } else {
        let subscription = this.dsrv.Get<Honorific[]>("api/honorifics").subscribe(res => {
          subscription.unsubscribe();
          this._honorifics = res;
          sessionStorage.setItem('honorifics', JSON.stringify(this._honorifics));
          resolve(this._honorifics);
        });
      }
    })
  }

  public GetHonorific(HonorificId: number) : Observable<Honorific> {
    return this.dsrv.Get<Honorific>("api/honorifics/"+HonorificId);
  }

  public GetGenders() : Promise<Gender[]> {
    return new Promise<Gender[]>(resolve => {
      if (this._genders.length > 0) {
        resolve(this._genders);
      } else {
        let subscription = this.dsrv.Get<Gender[]>("api/genders").subscribe(res => {
          subscription.unsubscribe();
          this._genders = res;
          sessionStorage.setItem('genders', JSON.stringify(this._genders));
          resolve(this._genders);
        });
      }
    })
  }

  public GetGender(GenderId: number) : Observable<Gender> {
    return this.dsrv.Get<Gender>("api/genders?GenderId="+GenderId);
  }

  public GetEmployeeIds(limit: number, offset: number, organisationid?: number, tagIds?: number[]): Promise<number[]> {
    return new Promise<number[]>(resolve => {
      let postObject: PostObjects = new PostObjects();
      postObject.Model.Limit = limit;
      postObject.Model.Offset = offset;

      if (tagIds && tagIds.length > 0) {
        postObject.Model.TagIdCollection = tagIds;
        postObject.Model.TagIdLookupOperator = 'Exists';
      } else {
        postObject.Model.TagIdCollection = [1];
        postObject.Model.TagIdLookupOperator = 'NotExists';
      }
      if (organisationid) {
        postObject.Model.OrganisationParameters.OrganisationIdCollection.push(organisationid);
      }

      let subscription = this.dsrv.Post<any>('api/view_employeeids', postObject.Model).subscribe(res => {
        subscription.unsubscribe();
        resolve(res);
      });
    });
  }

  public GetEmployeeIdsForSite(limit: number, offset: number, siteid: number): Promise<number[]> {
    return new Promise<number[]>(resolve => {
      let postObject: PostObjects = new PostObjects();
      postObject.Model.Limit = limit;
      postObject.Model.Offset = offset;
      postObject.Model.SiteParameters.SiteIdCollection.push(siteid);

      let subscription = this.dsrv.Post<any>('api/view_employeeids', postObject.Model).subscribe(res => {
        subscription.unsubscribe();
        resolve(res);
      });
    });
  }

  // DO NOT USE - USE ANY OF THE FUNCTIONS THAT CALL GetActorNames()
  public GetActorNameId(actorid: number): Promise<Actors_Names[]> {
    return new Promise<Actors_Names[]>(resolve => {
      let subscription = this.dsrv.Get<Actors_Names[]>('api/Actors_Names?Actors_Names.actorId=' + actorid).subscribe(res => {
        subscription.unsubscribe();    
        resolve(res);
      });
    });
  }

  // DO NOT USE - USE ANY OF THE FUNCTIONS THAT CALL GetActorNames()
  public GetActorName(nameid: number): Promise<string> {
    return new Promise<any>(resolve => {
      let subscription = this.dsrv.Get<Names>('api/names?Name_.nameId=' + nameid).subscribe(res => {
        subscription.unsubscribe();
        resolve(res.Name);
      });
    });
  }

  public GetBiologicalName(idCollection: number[]):  Promise<BiologicalUserInfo[]> {
    return new Promise<BiologicalUserInfo[]>(resolve => {
      let subscription = this.GetActorNames(idCollection).subscribe(res => {
        subscription.unsubscribe();
        let returnObject: BiologicalUserInfo[] = [];
        res.forEach(nameCollection => {
          var name = new BiologicalUserInfo();
          name.ActorId = nameCollection.ActorId;
          name.Forename = nameCollection.NameCollection.some(i => i.NameClassKeyId === 1)? nameCollection.NameCollection.find(i => i.NameClassKeyId === 1).Name: '';
          name.Surname = nameCollection.NameCollection.some(i => i.NameClassKeyId === 2)? nameCollection.NameCollection.find(i => i.NameClassKeyId === 2).Name: '';
          if (nameCollection.ActorRoleCollection) {
            name.ActorRoleCollection = nameCollection.ActorRoleCollection[0];
          }
          returnObject.push(name);
        });
        resolve(returnObject);
      });
    });
  }

  public GetActorNames(idCollection: number[]): Observable<Name[]> {
      /* let sendObject = new PostObjects();
      sendObject.AssociatedActorNameCompositeModel.AssociatedActorIdCollection = idCollection; */
      return this.dsrv.Post<any>('api/view_PublicActorCompositeModel', idCollection);
  }

  public GetSiteIds(limit: number, offset: number, organisationid?: number, tagIds?: number[]): Promise<number[]> {
    return new Promise<number[]>(resolve => {
      let postObject: PostObjects = new PostObjects();
      postObject.Model.Limit = limit;
      postObject.Model.Offset = offset;
      
      if (organisationid) {
        postObject.Model.OrganisationParameters.OrganisationIdCollection.push(organisationid);
      }
      
      if (tagIds) {
        if (tagIds.length > 0) {
          postObject.Model.SiteParameters.TagIdCollection = tagIds;
          postObject.Model.SiteParameters.TagIdLookupOperator = 'Exists';
        } else {
          postObject.Model.SiteParameters.TagIdCollection.push(1);
          postObject.Model.SiteParameters.TagIdLookupOperator = 'NotExists';
        }
      } else {
        postObject.Model.SiteParameters.TagIdCollection.push(1);
        postObject.Model.SiteParameters.TagIdLookupOperator = 'NotExists';
      }
      let subscription = this.dsrv.Post<any>('api/View_SiteIds', postObject.Model).subscribe(res => {
        subscription.unsubscribe();
        if (res.length === 1000) {
          this.GetSiteIds(0, postObject.Model.Offset+1000).then(value => {
            value.forEach(id => {
              this.SiteIds.push(id);
            })
            resolve(this.SiteIds);
          }) 
        } else {
          resolve(res);
        }
      });
    });
  }

  public GetSiteNameCollection(idCollection: number[]): Promise<NonBiologicalName[]> {
    return new Promise<NonBiologicalName[]>(resolve => {
      let subscription = this.GetActorNames(idCollection).subscribe(res => {
        subscription.unsubscribe();
        let returnObject: NonBiologicalName[] = [];
        res.forEach(nameCollection => {
          var obj: NonBiologicalName = new NonBiologicalName();
          obj.ActorId = nameCollection.ActorId;
          obj.Name = nameCollection.NameCollection.find(i => i.NameClassKeyId === 6).Name
          returnObject.push(obj);
        });
        resolve(returnObject);
      });
    });
  }

  public GetOrganisationIds(limit, offset, tagIds?: number[]): Promise<number[]> {
    return new Promise<number[]>(resolve => {

      let postObject: PostObjects = new PostObjects();
      postObject.Model.Limit = limit;
      postObject.Model.Offset = offset;
      if (tagIds && tagIds.length > 0) {
        postObject.Model.OrganisationParameters.TagIdCollection = tagIds;
        postObject.Model.OrganisationParameters.TagIdLookupOperator = 'Exists';
        postObject.Model.TagIdCollection = [1];
        postObject.Model.TagIdLookupOperator = 'Exists';
      } else {
        postObject.Model.OrganisationParameters.TagIdCollection = [1];
        postObject.Model.OrganisationParameters.TagIdLookupOperator = 'NotExists';
        postObject.Model.TagIdCollection = [1];
        postObject.Model.TagIdLookupOperator = 'NotExists';
      }
      let subscription = this.dsrv.Post<any>('api/View_OrganisationIds', postObject.Model).subscribe(res => {
        subscription.unsubscribe();
        resolve(res);
      });
    });
  }

  public GetOrganisationIdsDBSync(limit, offset): Promise<number[]> {
    return new Promise<number[]>(resolve => {
      let postObject: PostObjects = new PostObjects();
      postObject.Model.Limit = limit;
      postObject.Model.Offset = offset;

      // Remove Site Parameters from the DbSync version of this call
      delete postObject.Model.SiteParameters;
      postObject.Model.OrganisationParameters.TagIdCollection.push(1);
      postObject.Model.OrganisationParameters.TagIdLookupOperator = 'NotExists';
      let subscription = this.dsrv.Post<any>('api/View_OrganisationIdsDbSync', postObject.Model).subscribe(res => {
        subscription.unsubscribe();
        resolve(res);
      })
    })
  }

  public GetOrganisationNameCollection(idCollection: number[]): Promise<NonBiologicalName[]> {
    return new Promise<NonBiologicalName[]>(resolve => {
      let subscription = this.GetActorNames(idCollection).subscribe(res => {
        subscription.unsubscribe();
        let returnObject: NonBiologicalName[] = [];
        res.forEach(nameCollection => {
          var obj: NonBiologicalName = new NonBiologicalName();
          obj.ActorId = nameCollection.ActorId;
          obj.Name = nameCollection.NameCollection.find(i => i.NameClassKeyId === 5).Name
          returnObject.push(obj);
        });
        resolve(returnObject);
      });
    });
  }

  public GetSitesWithinRadius(siteActorId: number, radius: number): Observable<Site[]> {
    return this.dsrv.Get<Site[]>('api/View_SitesWithinRadiusCompositeModel?ActorId='+siteActorId+'&Radius='+radius);
  }

  public GetActorRole(actorid?: number): Promise<ActorRole> {
    let id = actorid? actorid: this.dsrv.GetMyUserID();
    return new Promise<ActorRole>(resolve => {
      let subscription = this.dsrv.Get<ActorRole[]>('api/Actors_ActorRoles?Actor_ActorRole.actorId=' + id).subscribe(res => {
        subscription.unsubscribe();
        resolve({ActorRoleId: res[0].ActorRoleId, Name: this.Roles.find(i => i.ActorRoleId === res[0].ActorRoleId).Name, Edited: new Date(), Edited_ActorId: 0});
      });
    });
  }

  public GetActorRoleSubscription(actorid: number): Observable<ActorRole[]> {
    return this.dsrv.Get<ActorRole[]>('api/Actors_ActorRoles?Actor_ActorRole.actorId=' + actorid)
  }

  public GetUnreadMessageCount(): Promise<MessageDisplay> {
    return new Promise<MessageDisplay>(resolve => {
      let postObject: PostObjects = new PostObjects();
      postObject.CommunicationAccountMessageCompositeModelController.TagIdCollection.push(3);
      postObject.CommunicationAccountMessageCompositeModelController.Limit = 10;
      postObject.CommunicationAccountMessageCompositeModelController.Offset = 0;
      postObject.CommunicationAccountMessageCompositeModelController.Start = new Date('2020-06-01');
      postObject.CommunicationAccountMessageCompositeModelController.End = new Date();
      postObject.CommunicationAccountMessageCompositeModelController.TagIdLookupOperator = "NotExists";
      this.PostCommunicationAccountMessageCompositeModelController(postObject.CommunicationAccountMessageCompositeModelController).subscribe(res => {
        var returnObject = new MessageDisplay();
        if (res.length > 0) {
          returnObject.HasUnreadMesssages = true;
          returnObject.MessageCount = res.length;
        }
        resolve(returnObject);
      })
    })
  }

  public PostCommunicationAccountMessageCompositeModelController(object: any): Observable<Communication[]> {
    return this.dsrv.Post<Communication[]>('api/View_CommunicationAccountMessageCompositeModel/Query', object)
  }

  public GetMyOrganisationId(): Promise<number> {
    return new Promise<number>(resolve => {
      let subscription = this.GetMyOrganisations().subscribe(res => {
        subscription.unsubscribe();
        resolve(res[0].Organisations_ActorId);
      })
    })
  }

  public GetMyOrganisations(): Observable<Organisation[]> {
    return this.dsrv.Get<Organisation[]>('api/View_EmployeeOrganisations?Views_EmployeeOrganisationsModel.employees_actorId='+this.dsrv.GetMyUserID());
  }

  public GetSiteCompositeModel(siteid): Observable<SiteDetail> {
    return this.dsrv.Get<SiteDetail>('api/View_SiteCompositeModel/' + siteid);
  }

  public GetMediaForInteraction(interactionId): Promise<Views_MediaModel> {
    return new Promise<Views_MediaModel>(resolve => {
      let subscription = this.GetInteractionMedia(interactionId).subscribe(res => {
        subscription.unsubscribe();
        resolve(res);
      }, error => {
        var media = new Views_MediaModel();
        media.MediaClassKeyId = 4;
        media.MediaId = 80;
        resolve(media);
      })
    })
  }

  public GetInteractionMedia(interactionId: number): Observable<Views_MediaModel>  {
    return this.dsrv.Get<Views_MediaModel>('api/view_interactionimages/' + interactionId)
  }

  // Default loading method
  GetUserSettings(): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      this.IsLoggedUserAdmin().then(value => {
        this._superUser = true;
      })

      this.GetMyOrganisationId().then(value => {
        let orgValues: boolean;
        let siteValues: boolean;
        let colleagueValues: boolean;
        let reason: boolean;

        // OrganisationId
        this.UserOrganisationId = value;
  
        let organisationSubscription = this.GetOrganisation(this.UserOrganisationId).subscribe(res => {
          organisationSubscription.unsubscribe();
          this.UserOrganisationName = res.Name;
        }, err => {
          organisationSubscription.unsubscribe();
          this.UserOrganisationName = '';
        })

        // Organisation Settings
        this.GetOrganisationIdsDBSync(0, 0).then(value => {
          this.OrganisationIds = value;
          this.GetOrganisationNameCollection(value).then(value => {
            value.sort((a, b) => {
              return a.Name.localeCompare(b.Name);
            })
            this.PopulateOrganisationSelect(value);
            orgValues = true;
            if (orgValues && reason) {
              resolve(true);
            }
          })
        })

        let defaultSiteSubscription = this.GetEmployeeDefaultSites().subscribe(res => {
          defaultSiteSubscription.unsubscribe();
          res.forEach(site => {
            this._defaultSiteIds.push(site.Sites_ActorId);
          });
        }, err => {
          defaultSiteSubscription.unsubscribe();
          this._defaultSiteIds = [];
        })

        // Site Settings
        this.GetSiteIds(0, 0).then(value => {
          this.SiteIds = value;
          this.GetLoggedUserDetails();
          this.GetSiteNameCollection(value).then(value => {
            value.sort((a, b) => {
              return a.Name.localeCompare(b.Name);
            })
            this.DefaultSiteNameCollection = value;
            this.PopulateDefaultEmployeeSitesSelect();
            siteValues = true;
          })
        })


        // Colleague Settings 
        this.GetEmployeeIds(0, 0).then(value => {
          this.ColleagueIds = value;
          this.GetBiologicalName(value).then(value => {
            value.sort((a, b) => {
              if (a.Surname > b.Surname) {
                return 1;
              }

              if (a.Surname === b.Surname) {
                if (a.Forename > b.Forename) {
                  return 1;
                } else {
                  return -1;
                }
              } else {
                return -1;
              }
              return 0
            })

            this.DefaultColleagueCollection = value;
            this.FillColleagueSelect();
            colleagueValues = true;
          })
        })

        // Identification Documents
        this.GetIdentificationDocuments().subscribe(res => {
          this.IdentificationDocumentTypes = res;
        })

        // Exclusion Reason Options
        let exclusionReasonOptionsSubscription = this.ExclusionReasonOptions().subscribe(res => {
          exclusionReasonOptionsSubscription.unsubscribe();
          this.ReasonOptions = res;

          reason = true;
          if (orgValues && reason) {
            resolve(true);
          }
        }, err => {
          exclusionReasonOptionsSubscription.unsubscribe();
        });
      })
    })
  }

  private PopulateOrganisationSelect(organisationCollection: NonBiologicalName[]) {
    this.DefaultOrganisationNameCollection = organisationCollection;
    var ddItems: DropDownItem[] = [];
    organisationCollection.forEach(organisation => {
      ddItems.push({Key: organisation.ActorId, Value: organisation.Name});
    })
    this.OrganisationSelect = new Select(0, 'orgnisationSelect', 'Organisation', 'ddOrganisation',ddItems);
    this.OrganisationSelect.KeySelected = ddItems[0].Key;
  }

  public PopulateDefaultEmployeeSitesSelect(): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      let ddItems: DropDownItem[] = [];
      this.DefaultSiteNameCollection.forEach(site => {
        if (this.DefaultSiteIds.some(i => i === site.ActorId)) {
          ddItems.push({Key: site.ActorId, Value: site.Name});
        }
      })
      this.SiteSelect = new Select(0, 'siteSelect', 'Site', 'ddSiteSelect', ddItems);
      if (ddItems.length > 0) {
        this.SiteSelect.KeySelected = ddItems[0].Key;
        resolve(true);
      } else {
        this.GetSiteNameCollection(this.DefaultSiteIds).then(value => {
          value.forEach(site => {
            ddItems.push({Key: site.ActorId, Value: site.Name});
            this.SiteSelect.KeySelected = ddItems[0].Key;
            resolve(true);
          })
        })
      }
    })
  }

  public PopulateSiteSelectForOrgansiationId(organisationId: number): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      this.PopulateSiteDropDown(organisationId).then(value => {
        var ddItems: DropDownItem[] = [];
        value.forEach(site => {
          ddItems.push({Key: site.ActorId, Value: site.Name});
        })
        this.SiteSelect = new Select(0, 'siteSelect', 'Site', 'ddSiteSelect', ddItems);
        this.SiteSelect.KeySelected = ddItems[0].Key;
        resolve(true);
      });
    })
  }

  
  private PopulateSiteDropDown(organisationFilterId): Promise<NonBiologicalName[]> {
    return new Promise<NonBiologicalName[]>(resolve => {
      this.GetSiteIds(10000, 0, organisationFilterId).then(value => {
        let returnSites = [];
        let missingIds: number[] = [];
        value.forEach(id => {
          if (this.DefaultSiteNameCollection.some(i => i.ActorId === id)) {
            var site = this.DefaultSiteNameCollection.find(i => i.ActorId === id)
            returnSites.push({ActorId: site.ActorId, Name: site.Name});
          } else {
            missingIds.push(id)
          }
        })
  
        if (missingIds.length > 0) {
          this.GetSiteNameCollection(missingIds).then(value => {
            value.forEach(site => {
              returnSites.push({ActorId: site.ActorId, Name: site.Name})
            })
            Sort();
            resolve(returnSites);
          })
        } else {
          Sort();
          resolve(returnSites);
        }
  
        function Sort() {
          returnSites.sort((a, b) => {
            let x = a.Name.localeCompare(b.Name);
            return x;
          });
        }
      })
    })
  }

  public FillColleagueSelect() {
    var ddItems: DropDownItem[] = [];
    this.DefaultColleagueCollection.forEach(colleague => {
      ddItems.push({Key: colleague.ActorId, Value: colleague.Forename + ' ' + colleague.Surname});
    })
    this.ColleagueSelect = new Select(0, 'colleagueSelect', 'Colleague', 'ddColleagueSelect', ddItems);
    this.ColleagueSelect.KeySelected = this.GetMyUserID();
  }


  public PopulateColleagueSelectForSiteId(siteId: number): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      this.PopulateEmployeeDropDown(siteId).then(value => {
        var ddItems: DropDownItem[] = [];
        value.forEach(employee => {
          ddItems.push({Key: employee.ActorId, Value: employee.Forename + ' ' + employee.Surname});
        })
        this.ColleagueSelect = new Select(0, 'colleagueSelect', 'Colleague', 'ddColleagueSelect', ddItems);
        this.ColleagueSelect.KeySelected = this.GetMyUserID();
        resolve(true);
      })
    })
  }

  private PopulateEmployeeDropDown(siteFilterId): Promise<BiologicalUserInfo[]> {
    return new Promise<BiologicalUserInfo[]>(resolve => {
      this.GetEmployeeIdsForSite(10000, 0, siteFilterId).then(value => {
        let returnEmployees = [];
        let missingIds: number[] = [];
        value.forEach(id => {
          if (this.DefaultColleagueCollection.some(i => i.ActorId === id)) {
            var employee = this.DefaultColleagueCollection.find(i => i.ActorId === id)
            returnEmployees.push({ActorId: employee.ActorId, Forename: employee.Forename, Surname: employee.Surname});
          } else {
            missingIds.push(id)
          }
        })
  
        if (missingIds.length > 0) {
          this.GetBiologicalName(missingIds).then(value => {
            value.forEach(employee => {
              returnEmployees.push({ActorId: employee.ActorId, Forename: employee.Forename, Surname: employee.Surname})
            })
            SortEmployees();
            resolve(returnEmployees);
          })
        } else {
          SortEmployees();
          resolve(returnEmployees);
        }
  
        function SortEmployees() {
          returnEmployees.sort((a, b) => {
            let x = a.Forename.localeCompare(b.Forename);
            if (x == 0) {
              return a.Surname.localeCompare(b.Surname);
            }
            return x;
          })
        }
      })
    })
  }

  PopulateColleagueDropDownForOrganisationId(organsiationId: number): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      this.PopulateEmployeeDropDownWithOrganisationId(organsiationId).then(value => {
        var ddItems: DropDownItem[] = [];
        value.forEach(employee => {
          ddItems.push({Key: employee.ActorId, Value: employee.Forename + ' ' + employee.Surname});
        })
        this.ColleagueSelect = new Select(0, 'colleagueSelect', 'Colleague', 'ddColleagueSelect', ddItems);
        this.ColleagueSelect.KeySelected = this.GetMyUserID();
        resolve(true);
      })
    })
  }

  private PopulateEmployeeDropDownWithOrganisationId(organisationId): Promise<BiologicalUserInfo[]> {
    return new Promise<BiologicalUserInfo[]>(resolve => {
      this.GetEmployeeIds(0, 0, organisationId).then(value => {
        let returnEmployees = [];
        let missingIds: number[] = [];
        value.forEach(id => {
          if (this.DefaultColleagueCollection.some(i => i.ActorId === id)) {
            var employee = this.DefaultColleagueCollection.find(i => i.ActorId === id)
            returnEmployees.push({ActorId: employee.ActorId, Forename: employee.Forename, Surname: employee.Surname});
          } else {
            missingIds.push(id)
          }
        })
  
        if (missingIds.length > 0) {
          this.GetBiologicalName(missingIds).then(value => {
            value.forEach(employee => {
              returnEmployees.push({ActorId: employee.ActorId, Forename: employee.Forename, Surname: employee.Surname})
            })
            SortEmployees();
            resolve(returnEmployees);
          })
        } else {
          SortEmployees();
          resolve(returnEmployees);
        }
  
        function SortEmployees() {
          returnEmployees.sort((a, b) => {
            let x = a.Forename.localeCompare(b.Forename);
            if (x == 0) {
              return a.Surname.localeCompare(b.Surname);
            }
            return x;
          })
        }
      })
    })
  }

  GetAgreementDocumentation(): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      let privacyPolicy: boolean;
      let summaryOfPrivacyPolicy: boolean;
      let terms: boolean;
      let summaryOfTerms: boolean;
      let ipsosMori: boolean;

      // Privacy Policy
      // If Privacy Policy fails, we need to check if the user is an Organisation Admin and take them to the Site Setup page.
      let privacyPolicyVersionSubscription = this.GetDocumentVersions(this.UserDocumentation.PrivacyPolicy).subscribe(res => {
        privacyPolicyVersionSubscription.unsubscribe();

        SortDocumentVersions(res);
        this.UserDocumentation.PrivacyPolicyVersion = res[res.length-1];

        let privacyPolicyHTMLSubscription = this.GetDocument(this.UserOrganisationId, this.UserDocumentation.PrivacyPolicy, this.UserDocumentation.PrivacyPolicyVersion).subscribe(res => {
          this.UserDocumentation.PrivacyPolicyHTML = res;
          privacyPolicyHTMLSubscription.unsubscribe();

          privacyPolicy = true;
          if (privacyPolicy && summaryOfPrivacyPolicy && terms && summaryOfTerms && ipsosMori) {
            resolve(true);
          }
        }, err => {
          privacyPolicyHTMLSubscription.unsubscribe();
          resolve(false);
        })

      }, err => {
        privacyPolicyVersionSubscription.unsubscribe();
        resolve(false);
      })
      
      // Summary of Privacy Policy
      let summaryOfPrivacyPolicyVersionSubscription = this.GetDocumentVersions(this.UserDocumentation.SummaryOfPrivacyPolicy).subscribe(res => {
        summaryOfPrivacyPolicyVersionSubscription.unsubscribe();

        SortDocumentVersions(res)
        this.UserDocumentation.SummaryOfPrivacyPolicyVersion = res[res.length-1];

        let summaryOfPrivacyPolicyHTMLSubscription = this.GetDocument(this.UserOrganisationId, this.UserDocumentation.SummaryOfPrivacyPolicy, this.UserDocumentation.SummaryOfPrivacyPolicyVersion).subscribe(res => {
          this.UserDocumentation.SummaryOfPrivacyPolicyHTML = res;
          summaryOfPrivacyPolicyHTMLSubscription.unsubscribe();

          summaryOfPrivacyPolicy = true;
          if (privacyPolicy && summaryOfPrivacyPolicy && terms && summaryOfTerms && ipsosMori) {
            resolve(true);
          }
        }, err => {
          summaryOfPrivacyPolicyHTMLSubscription.unsubscribe();
          resolve(false);
        })
      }, err => {
        summaryOfPrivacyPolicyVersionSubscription.unsubscribe();
        resolve(false);
      })

      // Summary of Terms
      let summaryOfTermsVersionSubscription= this.GetDocumentVersions(this.UserDocumentation.SummaryOfTerms).subscribe(res => {
        summaryOfTermsVersionSubscription.unsubscribe();

        SortDocumentVersions(res);
        this.UserDocumentation.SummaryOfTermsVersion = res[res.length-1];

        let summaryOfTermsHTMLSubscription = this.GetDocument(this.UserOrganisationId, this.UserDocumentation.SummaryOfTerms, this.UserDocumentation.SummaryOfTermsVersion).subscribe(res => {
          this.UserDocumentation.SummaryOfTermsHTML = res;
          summaryOfTermsHTMLSubscription.unsubscribe();

          summaryOfTerms = true
          if (privacyPolicy && summaryOfPrivacyPolicy && terms && summaryOfTerms && ipsosMori) {
            resolve(true);
          }
        }, err => {
          summaryOfTermsHTMLSubscription.unsubscribe();
          resolve(false);
        })
      }, err => {
        summaryOfTermsVersionSubscription.unsubscribe();
        resolve(false);
      })


      // Terms
      let termsVersionSubscription = this.GetDocumentVersions(this.UserDocumentation.Terms).subscribe(res => {
        termsVersionSubscription.unsubscribe();

        SortDocumentVersions(res);
        this.UserDocumentation.TermsVersion = res[res.length-1];

        let termsHTMLSubscription = this.GetDocument(this.UserOrganisationId, this.UserDocumentation.Terms, this.UserDocumentation.TermsVersion).subscribe(res => {
          this.UserDocumentation.TermsHTML = res;
          termsHTMLSubscription.unsubscribe();

          terms = true;
          if (privacyPolicy && summaryOfPrivacyPolicy && terms && summaryOfTerms && ipsosMori) {
            resolve(true);
          }
        }, err => {
          termsHTMLSubscription.unsubscribe();
          resolve(false);
        })
      }, err => {
        termsVersionSubscription.unsubscribe();
        resolve(false);
      })

      // Ipsos Mori
      let ipsosMoriVersionSubscription = this.GetGenericDocumentVersion(this.UserDocumentation.IpsosMori).subscribe(res => {
        ipsosMoriVersionSubscription.unsubscribe();

        SortDocumentVersions(res);
        this.UserDocumentation.IpsosMoriVersion = res[res.length-1];

        let ipsosMoriHTMLSubscription = this.GetGenericDocument(this.UserDocumentation.IpsosMori, this.UserDocumentation.IpsosMoriVersion).subscribe(res => {
          this.UserDocumentation.IpsosMoriHTML = res;
          ipsosMoriHTMLSubscription.unsubscribe();

          ipsosMori = true;
          if (privacyPolicy && summaryOfPrivacyPolicy && terms && summaryOfTerms && ipsosMori) {
            resolve(true);
          }
        }, err => {
          ipsosMoriHTMLSubscription.unsubscribe();
          resolve(false);
        })
      }, err => {
        ipsosMoriVersionSubscription.unsubscribe();
        resolve(false);
      })
    });

    function SortDocumentVersions(documentArray) {
      documentArray.sort((n1, n2) => {
        if (n1 > n2) {
          return 1;
        }
        if (n1 < n2) {
          return -1;
        }
        return 0;
      })
    }
  }

  public GetIdentificationDocuments() : Observable<IdentificationDocument[]> {
    return this.dsrv.Get<IdentificationDocument[]>("api/IdentificationDocumentKeys");
  }

  public GetReinstatementModel(interactionId: number): Promise<View_ReinstatementsCompositeModel> {
    return new Promise<View_ReinstatementsCompositeModel>(resolve => {
      let subscription = this._api.getReinstatements(interactionId).subscribe(res => {
        subscription.unsubscribe();
        resolve(res);
      }, err => {
        subscription.unsubscribe()
        resolve(new View_ReinstatementsCompositeModel(null, [], null, null, null, null, null, null, null));
      })
    })
  }

  // public AddReinstatement(reinstate: Reinstate) {
  //   return this.dsrv.Post<any>("api/view_reinstatementscompositemodel", reinstate)
  // }

  // public GetReinstatements(interactionId: number): Observable<View_ReinstatementsCompositeModel> {
  //   return this.dsrv.Get<View_ReinstatementsCompositeModel>('api/view_reinstatementscompositemodel?Views_ExclusionAssetModel.exclusions_InteractionId=' + interactionId);
  // }

  public InteractionHasReinstated(interactionId: number): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      let subscription = this.dsrv.Get('view_reinstatementscompositemodel?Views_ExclusionAssetModel.interactionId=' + interactionId).subscribe(res => {
        subscription.unsubscribe();
        resolve(true);
      }, err => {
        subscription.unsubscribe();
        resolve(false);
      })
    })
  }

  public GetExclusionIds(sendObject: any): Observable<any> {
    return this.dsrv.Post<any>('api/view_exclusioninteractionids', sendObject);
  }

  public GetBreach(interactionid: number): Promise<Breach> {
    return new Promise<Breach>(resolve => {
      let subscription = this._api.getBreachCompositeModel(interactionid).subscribe(res => {
        subscription.unsubscribe();
        console.log('*****************',res);
        res.AssociatedActorId = res.AssociatedActorIdCollection[0];
        resolve(res);
      })
    })
  }

  // private GetBreachCompositeModel(interactionid: number): Observable<Breach> {
  //   return this.dsrv.Get<Breach>('api/View_ExclusionBreachesCompositeModel?Views_ExclusionAssetModel.interactionId=' + interactionid);
  // }

  public GetActorSettings(actorid: number): Observable<Actor_Settings[]> {
    return this.dsrv.Get<Actor_Settings[]>('api/Actors_Settings?Actor_Setting.actorId=' + actorid)
  }
  
  public PostActorSetting(actorid: number, setting: Actor_Settings): Observable<Actor_Settings> {
    let obj = {ActorId: actorid, Settings: [setting.Settings]};
    return this.dsrv.Post<any>('api/Actors_Settings', obj);
  }

  public GetReinstatementIds(sendObject: any): Observable<any> {
    return this.dsrv.Post<any>('api/view_reinstatementinteractionids', sendObject)
  }

  public GetInteractionIds(sendObject: any): Observable<any> {
    return this.dsrv.Post<any>('api/View_InteractionIds', sendObject);
  }

  public GetCrimeAlertIds(sendObject: any): Observable<any> {
    return this.dsrv.Post<any>('api/view_crimealertinteractionids', sendObject);
  }

  public GetFireCheckIds(sendObject: any): Observable<any> {
    return this.dsrv.Post<any>('api/view_firecheckinteractionids', sendObject);
  }

  public GetToiletCheckIds(sendObject: any): Observable<any> {
    return this.dsrv.Post<any>('api/view_wccheckinteractionids', sendObject);
  }

  public GetCleaningCheckIds(sendObject: any): Observable<any> {
    return this.dsrv.Post<any>('api/view_cleaningcheckinteractionids', sendObject);
  }

  public GetMachinePayoutIds(sendObject: any): Observable<any> {
    return this.dsrv.Post<any>('api/view_machinepayoutinteractionids', sendObject);
  }

  public GetMachineServiceVisitIds(sendObject: any): Observable<any> {
    return this.dsrv.Post<any>('api/view_machineserviceinteractionids', sendObject);
  }

  public GetStatutoryVisitIds(sendObject: any): Observable<any> {
    return this.dsrv.Post<any>('api/view_statutoryvisitinteractionids', sendObject);
  }

  public GetAgeVerificationIds(sendObject: any): Observable<any> {
    return this.dsrv.Post<any>('api/view_ageverificationinteractionids', sendObject);
  }

  public GetOrganisation(actorid: number): Observable<Organisation> {
    return this.dsrv.Get<Organisation>('api/View_OrganisationCompositeModel/' + actorid);
  }

  public PostOrganisation(organisation: Organisation): Observable<Organisation> {
    return this.dsrv.Post<Organisation>('api/View_OrganisationCompositeModel/', organisation);
  }

  public GetInteractionReferenceCompositeModel(postObject: Post_InteractionReferenceCompositeModel): Observable<View_InteractionReferenceCompositeModel[]> {
    return this.dsrv.Post<any>('api/view_InteractionReferenceCompositeModel', postObject);
  }

  public GetUserOrganisationDataProtectionOfficer(): Promise<string> {
    return new Promise<string>(resolve => {
      // Default Organisation Settings 
      let orgSubscription = this.GetOrganisation(this.UserOrganisationId).subscribe(res => {
        orgSubscription.unsubscribe();
        if (res.SettingCollection.some(i => i.SettingKeyId === 23)) {
          this.GetBiologicalName([+res.SettingCollection.find(i => i.SettingKeyId === 23).Value]).then(value => {
            resolve(value[0].Forename + ' ' + value[0].Surname);
          })
        } else {
          resolve(this.GetUsersFullName());
        }
      })
    })
  }

  // ******************************************************************************
  // ******************************************************************************
  // Anything below here is redundent and needs a refactor. Working on this as I go.

  /*public GetMySites() : Observable<Site[]> {
    return this.dsrv.Get<Site[]>("api/View_EmployeeSites?Views_EmployeeSitesModel.employees_ActorId="+this.dsrv.GetMyUserID());
  }*/

  public GetMySites() : Observable<Site[]> {
    return this.dsrv.Get<Site[]>("api/View_ActorsSites?Views_ActorsSitesModel.actorId="+this.dsrv.GetMyUserID());
  }

  public GetSiteName(sites_ActorId: number): Observable<any> {
    return this.dsrv.Get<Site[]>("api/View_EmployeeSites?Views_EmployeeSitesModel.employees_ActorId=" + this.dsrv.GetMyUserID() + "&Views_EmployeeSitesModel.sites_ActorId=" + sites_ActorId);
  }

  public GetEmployeeDefaultSites(): Observable<Site[]> {
    return this.dsrv.Get<Site[]>("api/View_EmployeeSites?Views_EmployeeSitesModel.employees_ActorId=" + this.dsrv.GetMyUserID());
  }

  public ExclusionDurationOptions() : Observable<ExclusionDurationOption[]> {
    return this.dsrv.Get<ExclusionDurationOption[]>("api/ExclusionDurationOptions");
  }

  public ExclusionDurationOption(DurationId: number) : Observable<ExclusionDurationOption> {
    return this.dsrv.Get<ExclusionDurationOption>("api/ExclusionDurationOptions/"+DurationId);
  }

  public ExclusionReasonOptions() : Observable<ExclusionReason[]> {
    return this.dsrv.Get<ExclusionReason[]>("api/ExclusionReasonKeys");
  }

  // public AddBreach(Breach: Breach) : Observable<Breach> {
  //   return this.dsrv.Post<any>("api/View_ExclusionBreachesCompositeModel", Breach);
  // }

  // public GetBreaches(exclusionId: number) : Observable<Breach[]> {
  //   return this.dsrv.Get<Breach[]>("api/View_ExclusionBreachesCompositeModel?exclusionid=" + exclusionId);
  // }

  // public GetBreacheByBreachId(breachid: number) : Observable<Breach[]> {
  //   return this.dsrv.Get<Breach[]>("api/View_ExclusionBreachesCompositeModel?exclusionbreachid=" + breachid);
  // }

  public GetActorNamesCompositeModel(): Observable<any> {
    return this.dsrv.Get<ActorNamesCompositeModel>('api/view_actornamescompositemodel/' + this.dsrv.GetMyUserID());
  }

  public GetActorNamesCompositeModelByActorId(actorId: number): Observable<ActorNamesCompositeModel> {
    return this.dsrv.Get<ActorNamesCompositeModel>('api/view_actornamescompositemodel/' + actorId);
  }

  // New Calls, require models to be created
  // http://bactaapi/swagger/
  public GetSettingsKeys(): Observable<any> {
    return this.dsrv.Get<any>('api/settingkeys');
  }

  public GetSettingsKeysForId(settingsKeyId: number): Observable<any> {
    return this.dsrv.Get<any>('api/settingkeys/' + settingsKeyId);
  }

  public GetAPIUsage(sendObject: any): Observable<any> {
    return this.dsrv.Post<any>('api/view_apiusage', sendObject)
  }

  public GetExclusionBreachCount(sendObject: any): Observable<any> {
    return this.dsrv.Post<any>('api/view_exclusionbreachcount', sendObject)
  }

  public GetExclusionCount(sendObject: any): Observable<any> {
    return this.dsrv.Post<any>('api/view_exclusioncount', sendObject)
  }

  public GetReinstatementCount(sendObject: any): Observable<any> {
    return this.dsrv.Post<any>('api/view_reinstatementcount', sendObject)
  }

  public GetBreachIds(sendObject: any): Observable<any> {
    return this.dsrv.Post<any>('api/view_exclusionbreachids', sendObject)
  }

  public GetUserExclusionSummary(sendObject: any): Observable<ApiUsage[]> {
    return this.dsrv.Post<ApiUsage[]>('api/view_interactionsummary', sendObject);
  }

  public GetMySiteIds() : Observable<Site[]> {
    return this.dsrv.Get<Site[]>("api/Actors_Sites?Actors_Sites.actorId="+this.dsrv.GetMyUserID());
  }

  public GetSitesForId(id: number) : Observable<Site[]> {
    return this.dsrv.Get<Site[]>("api/Actors_Sites?Actors_Sites.actorId=" + id);
  }

  public GetSiteDetails(siteId: number) {
    return this.dsrv.Get<SiteDetail>('api/view_sitecompositemodel/' + siteId)
  }

  public UpdateSite(site: SiteDetail) {
    return this.dsrv.Post<SiteDetail>('api/view_sitecompositemodel/', site);
  }

  public GetNameDetailsForId(id: number): Observable<Actors_Names[]> {
    return this.dsrv.Get<Actors_Names[]>('api/actors_names?Actors_Names.actorId=' + id);
  }

  public GetNameForNameId(id: number): Observable<Names> {
    return this.dsrv.Get<Names>('api/names?Name_.nameId=' + id);
  }

  public GetCustomerAcknowledgement(): Observable<any> {
    return this.dsrv.Get<any>('api/documents_generic/CustomerAcknowledgement_' + this.acknowledgementVersion);
  }

  public GetGenericDocumentVersion(documentName: string): Observable<any> {
    return this.dsrv.Get<any>('api/view_documents_generic/' + documentName);
  }

  public GetGenericDocument(document: string, version: string): Observable<any> {
    return this.dsrv.Get<any>('api/view_documents_generic/' + document + '/' + version);
  }

  public GetDocumentVersions(documentName: string): Observable<any> {
    return this.dsrv.Get<any>('api/view_documents_organisation/' + documentName);
  }

  public GetDocument(organisations_actorid: number, document: string, version: string): Observable<any> {
    return this.dsrv.Get<any>('api/view_documents_organisation/' + organisations_actorid + '/' + document + '/' + version);
  }

  public GetOrganisationForActorId(actorId: number): Observable<OrganisationBrief[]> {
    return this.dsrv.Get<OrganisationBrief[]>('api/Actors_Organisations?Actors_Organisations.actorId=' + actorId);
  }

  public GetExclusionSummaryVersion(documentName: string): Observable<any> {
    return this.dsrv.Get<any>('api/view_documents_exclusion/' + documentName);
  }

  public GetExclusionSummary(exclusionId: number, documentName: string, version: string): Observable<any> {
    return this.dsrv.Get<any>('api/view_documents_exclusion/' + exclusionId + '/' + documentName + '/' + version);
  }

  public GetEmployeeDetails(actorId: number): Observable<EmployeeDetails> {
    return this.dsrv.Get<EmployeeDetails>('api/view_employeecompositemodel/' + actorId);
  }

  public PostEmployeeDetails(employeeDetails: EmployeeDetails) {
    return this.dsrv.Post<EmployeeDetails>('api/view_employeecompositemodel/', employeeDetails);
  }

  public GetTagInfo(tagId: number): Observable<Tag> {
    return this.dsrv.Get<Tag>('api/tags?Tags.tagId=' + tagId);
  }

  public TestStatusCodes(): Observable<any> {
    return this.dsrv.Get<any>('api/Test/400');
  }

  public GetPostalAddressForPostalAddressId(postalAddressId: number): Observable<PostalAddressFieldClasses[]> {
    return this.dsrv.Get<PostalAddressFieldClasses[]>('api/PostalAddresses_PostalAddressFieldClasses?PostalAddresses_PostalAddressFieldClasses.postalAddressId=' + postalAddressId)
  }

  public GetContactPostalAddress(actorId: number): Observable<ContactsPostalAddress[]> {
    return this.dsrv.Get<ContactsPostalAddress[]>('api/Contacts_PostalAddresses?Contacts_PostalAddresses.actorId=' + actorId);
  }
  
  public GetToken(): Observable<Token> {
    return this.dsrv.Get<Token>('api/View_SessionTokens');
  }

  public GetExclusionSites(exclusionId: number): Observable<ExclusionSites[]> {
    return this.dsrv.Get<ExclusionSites[]>('api/Exclusion_Sites?Exclusions_Sites.exclusionId=' + exclusionId);
  }

  public GetCommunicationContactTags(): Observable<CommunicationContactTags> {
    return this.dsrv.Get<CommunicationContactTags>('api/communicationcontacttags');
  }

  // Unknown return at this time
  public GetCommunicationContactsContactTags(): Observable<any> {
    return this.dsrv.Get<any>('');
  }

  public PostCommuicationContactsContactTags(object: CommunicationContacts_CommunicationContactTags): Observable<CommunicationContacts_CommunicationContactTags> {
    return this.dsrv.Post<CommunicationContacts_CommunicationContactTags>('api/CommunicationContacts_CommunicationContactTags', object);
  }

  public PostCommunicationAccountMessageCompositeModel(object: any): Observable<number> {
    return this.dsrv.Post<number>('api/View_CommunicationAccountMessageCompositeModel', object);
  } 

  public GetCommunicationDetailsForId(communicationId: number): Observable<Communication> {
    return this.dsrv.Get<Communication>('api/View_CommunicationAccountMessageCompositeModel/' + communicationId)
  }

  public GetUserLogin(actorId: number): Observable<Credentials> {
    return this.dsrv.Get<Credentials>('api/logincredentials/' + actorId);
  }

  public CompareLoginCredentials(credentialsBrief: CredentialsBrief) {
    return this.dsrv.Post<CredentialsBrief>('api/logincredentials/compare', credentialsBrief)
  }

  public PostNewCredentials(credentials: Credentials) {
    return this.dsrv.Post<Credentials>('api/logincredentials', credentials);
  }

  public UpsertTag(tag: Tag): Observable<Tag> {
    return this.dsrv.Post<Tag>('api/tags', tag);
  }

  public SearchTagByName(phrase: string): Observable<Tag> {
    return this.dsrv.Get<Tag>('api/tags?Tags.name=' + phrase);
  }

  public GetTagCollectionForSearchPhrase(tagColleciton: any): Observable<number[]> {
    return this.dsrv.Post<number[]>('api/view_TagIds', tagColleciton);
  }
}

class Globals {
  public BackgroundColor: BackgroundColor = new BackgroundColor();
  public Border: Border = new Border();
  public ClassKeyIds: ClassKeyIds = new ClassKeyIds();
  public FontColor: FontColor = new FontColor();
  public Positioning: Positioning = new Positioning();
  public TableProperties: TableProperties = new TableProperties();
  public TagValues: TagValues = new TagValues();
}