import { Honorific } from '../Honorific';
import { Gender } from '../Gender';
import { ExclusionDurationOption } from '../ExclusionDurationOption';
import { SiteCollection } from '../SiteCollection';
import { ISDDateMethods } from '../ISDDateMethods';
import { ActorService } from 'src/app/services/actor.service';
import { ExcluderService } from 'src/app/services/excluder.service';
import { InteractionService } from 'src/app/services/interaction.service';
import { ApiService } from 'src/app/services/api.service';
import { forkJoin } from 'rxjs';
import * as moment from 'moment';

// Class for creating tabular reports.
// Every tabular class requires the following properties.
// Rows: any[] = [] --Holds all the newRows pushed.
// HeaderTransaltion = [{}]  --This will print all the headers to the table.
// newRow = [{}] --Make the values all blank but match the HeaderTranslationIds
// public AddRow(rowObject) {} --Use this push rows, ensure all values are hardcoded for now.

export class MachineServiceVisitList {
  private DateMethods: ISDDateMethods = new ISDDateMethods();
  public Rows: any[] = [];
  public Honorifics: Honorific[] = [];
  public Genders: Gender[] = [];
  public DurationOptions: ExclusionDurationOption[] = [];
  public SubscriptionsOpened: number;
  public CompletedSubscriptions: number = 0;
  public DataLoaded: boolean = false;
  private siteNamePromises: SiteNamePromise[] = [];
  public HeaderTranslation = [{
    'Recorded': 'Date of Visit',
    'itemIssue': 'Issue',
    'itemResolution': 'Resolution',
    'SiteId': 'Site Name',
    'AssetReference': 'Machine No.',
    'Notes': 'Notes',
  }];
  public isLoading = true;
  machineIssueList;
  machineIssueResolutionList;

  constructor(
    public asrv: ActorService,
    public isrv: InteractionService,
    public ids: number[],
    private _api: ApiService,
  ) {
    this.ids.forEach(id => {
      this.AddRow(id);
    });
    this.DataLoaded = true;
  }

  public AddRow(id: number) {
    let returnRow = {};
    returnRow['InteractionId'] = id;
    returnRow['Recorded'] = false;
    returnRow['itemIssue'] = false;
    returnRow['itemResolution'] = false;
    returnRow['SiteId'] = false;
    returnRow['AssetReference'] = false;
    returnRow['Notes'] = false;
    const _miAPI = this._api.getTagCollectionCompositeModel(22);
    const _mrAPI = this._api.getTagCollectionCompositeModel(23);
    const _res = this._api.getMachineServiceVisitCompositeModel(id);

    forkJoin(_miAPI, _mrAPI, _res).subscribe(([miResults, mrResults, res]) => {
      this.machineIssueList = miResults.Tags;
      this.machineIssueResolutionList = mrResults.Tags;

      let itemIssue: string = '';
      let itemResolution: string = '';
      res.TagIdCollection.forEach(tagId => {
        const _foundMI = this.machineIssueList.find(x => x.TagId === tagId);
        const _foundMR = this.machineIssueResolutionList.find(x => x.TagId === tagId);
        if (_foundMI) {
          itemIssue = _foundMI.Name;
        }
        if (_foundMR) {
          itemResolution = _foundMR.Name;
        }
      });
      returnRow['Recorded'] = moment(res.Recorded, 'YYYY-MM-DDTHH:mm:ssZ').format('YYYY-MM-DD HH:mm');;
      returnRow['itemIssue'] = itemIssue;
      returnRow['itemResolution'] = itemResolution;
      returnRow['AssetReference'] = res.AssetReference;
      this.GetSiteName(res.SiteId).then(value => {
        returnRow['SiteId'] = value;
      })
      returnRow['Notes'] = res.Notes;
      this.isLoading = false;
    });

    this.Rows.push(returnRow);
  }

  GetSiteName(siteid): Promise<string> {
    if (this.siteNamePromises.some(i => i.SiteId === siteid)) {
      return this.siteNamePromises.find(i => i.SiteId === siteid).Promise;
    } else {
      return new Promise<string>(resolve => {
        this.asrv.GetSiteNameCollection([siteid]).then(value => {
          if (value) {
            resolve(value[0].Name);
          } else {
            resolve('');
          }
        })
      })
    }
  }
}

class SiteNamePromise {
  SiteId: number;
  Promise: Promise<string>;
}
