<div class="container global-seperator">
    <div class="row">
        <div class="col-md-12">
            <h4>{{formName}} Address</h4>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <div class="input-group" id="inputPostcode">
                <div class="input-group-prepend">
                    <span class="input-group-text">Postcode</span>
                </div>
                <input type="text" class="form-control" name="postcode" [(ngModel)]="postcodeInput" (ngModelChange)="postCodeControl.next($event)">
            </div>
        </div>
        <div class="col-md-6">
            <div class="input-group" id="inputAddressSelect">
                <div class="input-group-prepend">
                    <span class="input-group-text">Lookup</span>
                </div>
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="loadingAddressList"></span>
                <span class="sr-only input-group-text" *ngIf="loadingAddressList"> Fetching Addresses...</span>
                <input type="text" class="form-control" *ngIf="!loadingAddressList && !addresses.Items" disabled>
                <select class="form-control drop-down-purple" id="selectAddressLookup" (change)="PopulateAddress();" [(ngModel)]="currentAddressId" *ngIf="!loadingAddressList && addresses.Items">
                    <option></option>
                    <option *ngFor="let address of addresses.Items" [ngValue]="address.Id">{{address.Text + ',' + address.Description}}</option>
                </select>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <div class="input-group" id="inputLine1">
                <div class="input-group-prepend">
                    <span class="input-group-text">Line 1</span>
                </div>
                <input type="text" id="textLine1" class="form-control" name="addressLine1" [(ngModel)]="object.Line1"> 
            </div>  
        </div>  
        <div class="col-md-6">       
            <div class="input-group" id="inputLine2">
                <div class="input-group-prepend">
                    <span class="input-group-text">Line 2</span>
                </div>
                <input type="text" id="textLine2" class="form-control" name="addressLine2" [(ngModel)]="object.Line2">
            </div>
        </div> 
    </div>  
    <div class="row">
        <div class="col-md-6"> 
            <div class="input-group" id="inputTownCity">
                <div class="input-group-prepend">
                    <span class="input-group-text">Town / City</span>
                </div>
                <input type="text" id="textTownCity" class="form-control" name="town" [(ngModel)]="object.TownCity">
            </div>
        </div>  
        <div class="col-md-6">
            <div class="input-group" id="inputCounty">
                <div class="input-group-prepend">
                    <span class="input-group-text">County</span>
                </div>
                <input type="text" id="textCounty" class="form-control" name="county" [(ngModel)]="object.County">
            </div>
        </div>
    </div>

    <div #map id="map" style="height:500px; width:100%"></div>
</div>


