import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActorService } from 'src/app/services/actor.service';

@Component({
  selector: 'app-reporting-main',
  templateUrl: './reporting-main.component.html',
  styleUrls: ['./reporting-main.component.scss']
})
export class ReportingMainComponent implements OnInit {

  @ViewChild('reportdropdown') reportdropdown: ElementRef;

  public dateRequired: boolean;
  public showSpinner: boolean = true;
  public report: number;
  public isAdmin: boolean = false;

  // Create Main Calls here and then allow the Main-View to Load
  // The purpose of this component is to check the users access level and give them permissions 
  
  constructor(private titleService: Title, public asrv: ActorService) { }

  ngOnInit() {
    this.asrv.IsLoggedUserOrganisationAdmin().then(value => {
      this.isAdmin = value;
    })
    this.titleService.setTitle('Reporting');
  }
}
