<div class="container global-seperator">
    <div class="row form-top-row">
        <div class="col-md-12">
            No valid email address found. Please provide an email address to receive notifications about the exclusion.
        </div>
        <div class="col-md-12">
            <div class="input-group" id="inputEmail">
                <div class="input-group-prepend">
                    <span class="input-group-text">Email</span>
                </div>
                <input type="email" class="form-control" name="email" [(ngModel)]="esrv.CurrentExclusion.InteractedActorCompositeModel.BiologicalActorCompositeModel.Email">
            </div>              
        </div>
    </div>
    <div class="form-group" style="text-align: right;">
        <button type="button" class="btn btn-danger" (click)="Close();" style="margin-right: 10px;">Close</button>
        <button type="button" class="btn btn-success" (click)="Confirm();">Confirm</button>
    </div>
</div>