<div class="body">
    <div class="container loginPanel">
        <div class="col-12">
            <img class="center" src="./assets/icons/Bacta Assets/bacta portal logo full colour.svg" alt="Bacta" style="height:auto;max-width:600px;width:90%;" />
            <div class="text-center" style="margin-top:20px;">
                <span style="width:100%;text-align: center;font-size:25px;color:#223464;">Please Login</span>
            </div>
            <div class="input-group">
                <div class="input-group-prepend">
                    <span class="input-group-text">Username</span>
                </div>
                <input type="text" class="form-control" name="Login" [(ngModel)]="UserCredentials.Login" (keydown.enter)="login();">
            </div>
            <div class="input-group">
                <div class="input-group-prepend">
                    <span class="input-group-text">Password</span>
                </div>
                <input type="password" class="form-control" name="Password" [(ngModel)]="UserCredentials.Password" (keydown.enter)="login();">
            </div>
            <div *ngIf="showFailedLogin" class="links" style="color: #ff5050;">
                Incorrect Username/Password Entered.
            </div>
            <!--
                <div class="row" style="margin-top:20px;">
                    
                    <span style="width:100%;text-align:right;"><a routerLink="''">Forgotten Username?</a></span>
                </div>
                <div class="row">
                    
                    <span style="width:100%;text-align:right;"><a routerLink="''">Forgotten Password?</a></span>
                </div>
            -->
            <div class="row">
                <div class="col-12">
                    <div class="text-center" style="margin-top:20px;">
                        <button class="btn btn-primary" (click)="login()" style="min-width: 250px;">
                            <span class="spinner-border spinner-border-sm" role="status" *ngIf="showSpinner"></span>
                            Login
                        </button>
                    </div>
                </div>
                <div class="col-12">
                    <div class="text-center" style="margin-top:20px;">
                        <button class="btn btn-danger" (click)="forgottenPassword()" style="min-width: 250px;">
                            Forgotten Password
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div style="position: absolute; bottom:3px; right:3px;">
    {{dsrv.GetVersion()}}
</div>



<!--<div class="body">
    <div class="container global-seperator loginPanel">
        <div class="loginForm">
            <div class="text-center">
                <img class="companyLogo" src="./assets/views/login/images/companyLogo.png" alt="Bacta" />
            </div>
            <div class="form-group">
                <label for="userName">User</label>
                <input [(ngModel)]="UserCredentials.Login" type="email" class="form-control" id="userName" placeholder="Username/Email">
            </div>
            <div class="form-group">
                <label for="Password">Password</label>
                <input [(ngModel)]="UserCredentials.Password" type="password" class="form-control" id="Password" placeholder="Password">
            </div>
            <div class="text-center">
                <button class="btn btn-primary" (click)="login()">
                    <span class="spinner-border spinner-border-sm" role="status" *ngIf="showSpinner"></span>
                    Login
                </button>-->
                            <!--<button class="btn btn-primary" routerLink="/user">Login</button> Commented Out Originally-->
            <!--</div>
            <div *ngIf="showFailedLogin" class="links" style="color: #ff5050;">
                Incorrect Username/Password Entered.
            </div>
            <div class="links">
                <a routerLink="forgottenPassword">forgot password</a>
            </div>
        </div>
    </div>
</div>
-->