import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { SiteCollection } from 'src/app/models/SiteCollection';
@Component({
  selector: 'app-inspect-sites',
  templateUrl: './inspect-sites.component.html',
  styleUrls: ['./inspect-sites.component.scss']
})
export class InspectSitesComponent implements OnInit {
  inspectSites;
  motorway: SiteCollection; 
  @Input('motorway') get Motorway(): SiteCollection {
    return this.motorway;
  };
  @Output('motorway') motorwayChange: EventEmitter<SiteCollection> = new EventEmitter();
  set Motorway(val: SiteCollection) {
    this.motorway = val;
    this.motorwayChange.emit(this.motorway);
  }

  constructor() { }

  ngOnInit() {
  }

  CloseForm() {
    this.CloseInspect.next();
  }

  @Output() CloseInspect = new EventEmitter<string>();
  
}
