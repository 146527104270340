import { ISDDateMethods } from '../ISDDateMethods';
import { ActorService } from 'src/app/services/actor.service';
import { Tags } from '../Tags';
import { DataService } from 'src/app/services/data.service';
import { Communication } from '../Communication';
import { ErrorhanderService } from 'src/app/services/errorhander.service';
import { Names, NameDetails } from '../Name';
import { BiologicalUserInfo } from '../actor/Name';

// Class for creating tabular reports.
// Every tabular class requires the following properties.
// Rows: any[] = [] --Holds all the newRows pushed.
// HeaderTransaltion = [{}]  --This will print all the headers to the table.
// newRow = [{}] --Make the values all blank but match the HeaderTranslationIds
// public AddRow(rowObject) {} --Use this push rows, ensure all values are hardcoded for now.

export class MessageList {
    private DateMethods: ISDDateMethods = new ISDDateMethods();
    public Rows: CommunicationRow[] = [];
    public SubscriptionsOpened: number;
    public CompletedSubscriptions: number = 0;
    public DataLoaded:boolean = false;
    public nameDirectory: Names[];
    public actorIdNameIdDirectory: NameDetails[];
    public HeaderTranslation = [{
        'Edited': 'Sent',
        'Sender': 'Sender',
        'Subject': 'Subject',
    }];
    private newRow = {
        'Edited': 'Sent',
        'Edited_ActorID': 'Sender',
        'Subject': 'Subject',
    }  

    constructor(public asrv: ActorService, public communication: Communication[]) {
        let ids: number[] = [];
        if (this.communication.length > 0) {
            this.communication.forEach(communication => {
                this.AddRow(communication);
                if (!ids.some(i => i === communication.Edited_ActorID)) {
                    ids.push(communication.Edited_ActorID);
                }
            })

            this.asrv.GetBiologicalName(ids).then(value => {
                this.Rows.forEach(row => {
                    var biologicalName: BiologicalUserInfo = value.find(i => i.ActorId === row['Edited_ActorID'])
                    row.Sender = biologicalName.Forename + ' ' + biologicalName.Surname;
                })
            })
        }
    }

    public AddRow(communication: Communication) {
        var returnRow = new CommunicationRow();
        returnRow.CommunicationId = communication.CommunicationId;
        returnRow.Edited_ActorID = communication.Edited_ActorID;
        returnRow.Edited = this.DateMethods.FormatDateForReport(new Date(communication.Edited));
        returnRow.Sender = false;
        returnRow.Subject = communication.Subject;
        returnRow.Body = communication.Body;
        returnRow.IsNew = communication.TagIdCollection.length === 0;
        returnRow.IsImportant = communication.TagIdCollection.find(i => i === 2) === 2;
        this.Rows.push(returnRow);
    }    
}

export class CommunicationRow {
    CommunicationId: number;
    Edited_ActorID: number;
    Edited: string;
    Sender: boolean | string;
    Subject: string;
    Body: string;
    IsNew: boolean;
    IsImportant: boolean;
}
