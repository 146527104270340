import { Actors_Names } from './Name';
import { Actor_Settings } from './Actor_Settings'
import { Actor_Sites } from './Actor_Sites';
import { Actors_Tags } from './Actors_Tags';
import { UserSites } from './UserSites';


export class ActorCompositeModel {
    ActorId: number;
    OrganisationName: string;

    Actor_Names: Actors_Names[];
    Actor_Settings: Actor_Settings[];
    Actor_Sites: UserSites[];
    Actors_Tags: Actors_Tags[];

    constructor() {}
};
