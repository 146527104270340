import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd } from '@angular/router';
import { fromEvent, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';
import { ISDDateMethods } from 'src/app/models/ISDDateMethods';
import { ActorService } from 'src/app/services/actor.service';
import { AdvancedSearchService } from 'src/app/services/advanced-search.service';
import { ApiService } from 'src/app/services/api.service';
import { DataService } from 'src/app/services/data.service';
import { ErrorhanderService } from 'src/app/services/errorhander.service';
import { InteractionService } from 'src/app/services/interaction.service';
import * as moment from 'moment';
import { CrimeAlertList } from 'src/app/models/Reports/CrimeAlertList';

@Component({
  selector: 'app-crime-alert',
  templateUrl: './crime-alert.component.html',
  styleUrls: ['./crime-alert.component.scss'],
})
export class CrimeAlertComponent implements OnInit, AfterViewInit {
  @ViewChild('dropDownToggle') DropDownToggle: ElementRef;
  @ViewChild('exclusionDropDownToggle') ExclusionDropDownToggle: ElementRef;
  @ViewChild('reportingDropDownToggle') ReportingDropDownToggle: ElementRef;
  @ViewChild('adminDropDownToggle') AdminDropDownToggle: ElementRef;
  @ViewChild('messagingDropDownToggle') MessagingDropDownToggle: ElementRef;
  @ViewChild('advancedSearchToggle') AdvancedSearchToggle: ElementRef;
  @ViewChild('adminToolsDropDownToggle') AdminToolsDropDownToggle: ElementRef;

  @ViewChild('status') status: ElementRef;

  @ViewChild('equipmentSearch') equipmentSearch: ElementRef;
  public showStatus: boolean = false;

  constructor(
    public dsrv: DataService,
    public asrv: ActorService,
    private router: Router,
    public errsrv: ErrorhanderService,
    public assrv: AdvancedSearchService,
    private titleService: Title,
    private _api: ApiService,
    public isrv: InteractionService
  ) { }

  public displayFeatures: boolean = false;
  public displayAddNewExcluder: boolean = false;
  public mainWindowHeight: string;
  public unreadMessages: boolean = false;
  public unreadMessageCount: string;
  public readonly FeatureNotReady: boolean = false;

  public logOutSubscription: Subscription;

  public dropDownIsShowing: boolean;
  public exclusionDropDownIsShowing: boolean;
  public reportingDropDownIsShowing: boolean;
  public adminDropDownIsShowing: boolean;
  public messagingDropDownIsShowing: boolean;
  public advancedSearchIsShowing: boolean;
  public adminToolsDropDownIsShowing: boolean;

  private nextCheckForMessage: Date;
  private nextCheckTime: number = 30000;
  private dateMethods: ISDDateMethods = new ISDDateMethods();
  private _dataready: boolean = false;

  public NavigatedId: number;
  public employeeId: any;

  public stage: number;

  events: string[] = [];

  eventTypeList = [
    {
      Value: 'Break In'
    },
    {
      Value: 'Abusive Behaiour'
    },
    {
      Value: 'Violent Behaviour'
    },
    {
      Value: 'Property Damaged'
    },
    {
      Value: 'Fruit Machine Attacked/Strimmed'
    },
  ];

  suspectList = [
    // {
    //   genderId: null,
    //   hairColour: null,
    //   age: null,
    //   height: null,
    // }
  ];

  vehicleList = [
    // {
    //   vehicleReg: null
    // }
  ];

  searchTagCollection = [];
  searchResults: any[] = [];

  alertDateModel;
  alertDate;
  alertTime;
  equipmentTargeted;
  wasPoliceCalled = false;
  policeCalledTrue = false;
  policeCalledFalse = false;
  eventTypeID;
  notes;
  genderList;
  gender1;
  hairColourId1;
  ageId1;
  heightId1;
  isSearching = false;
  isSaving = false;
  isSavedSuccess = false;
  isError = false;
  errorMsg;
  showSearchResults = false;
  isSavingTag = false;
  textSearch;
  missingFields = [];
  isMissingFields = false;
  showLiveAlertModal = false;
  siteID;
  dataModel;
  isLoadingAlerts = true;
  tableKeys = [];
  dateRange = '';
  showAlertItemModal = false;
  isLoadingAlertItem = false;
  alertItem;
  selectedAlert;

  ngOnInit() {
    this.stage = 1;
    this.getGenderList();
    this.getEventAlertList();
    document.body.style.backgroundColor = '#f9f2f2';

    this.asrv.GetLoggedUserDetails().then(value => {
      // console.log('user settings', this.siteID = this.asrv.DefaultSiteId);
      this.getLiveCrimeAlerts();
      // this.asrv.GetActorCompositeModel().then(value => {
      //   console.log('actor comp model', value);
      //   this.siteID = this.asrv.DefaultSiteId;
      //   console.log(this.siteID);
      // });
    });
  }

  getLiveCrimeAlerts() {
    const dateStart = moment().subtract(4, 'weeks');
    const dateEnd = moment();

    this.dateRange = `${dateStart.format('ddd DD-MM-YYYY HH:mm')} - ${dateEnd.format('ddd DD-MM-YYYY HH:mm')}`;
    const searchObject = {
      Start: dateStart.format('YYYY-MM-DDTHH:mm:ss[Z]'),
      End: dateEnd.format('YYYY-MM-DDTHH:mm:ss[Z]'),
      Offset: 0,
      TagIdLookupOperator: "NotExists",
      TagIdCollection: [],
      OrganisationParameters: {
        OrganisationIdCollection: [],
        TagIdLookupOperator: "NotExists",
        TagIdCollection: []
      },
      SiteParameters: {
        SiteIdCollection: [],
        TagIdLookupOperator: "NotExists",
        TagIdCollection: []
      }
    }
    this.asrv.GetCrimeAlertIds(searchObject).subscribe(res => {
      this.dataModel = new CrimeAlertList(this.asrv, this.isrv, res, this._api);
      this.dataModel.SubscriptionsOpened = res.length;
      // console.log(this.dataModel.Rows);
      // this.dataModel.Rows = this.dataModel.Rows.map(item => {
      //   return {...item, Recorded2: moment(item.Recorded, 'YYYY-MM-DD HH:mm').utc().toString() }
      // });
      // console.log(this.dataModel);
      this.isLoadingAlerts = false;
    });
  }

  openAlert(item: any) {
    this.selectedAlert = item;

    this.isLoadingAlertItem = true;
    // console.log(item);
    this.showAlertItemModal = true;
    this._api.getCrimeAlertCompositeModel(item.InteractionId).subscribe(alertItem => {
      // console.log(alertItem);
      this.alertItem = alertItem;
      this.alertItem = { ...this.alertItem, suspects: [] };
      this.alertItem.InteractedActorCompositeModelCollection.forEach(IA => {
        // console.log(IA);

        let gender;
        let hairColour;
        let age;
        let height;
        switch (IA.BiologicalActorCompositeModel.GenderId) {
          case 1: gender = 'Male';
            break;

          case 2: gender = 'Female';
            break;

          case 3: gender = 'Other';
            break;
        }
        IA.BiologicalActorCompositeModel.BiologicalActorTraitModelCollection.forEach(bio => {
          // console.log(bio);
          switch (bio.TraitClassKeyId) {
            case 1: hairColour = bio.Data;
              break;

            case 6: age = bio.Data;
              break;

            case 7: height = bio.Data;
              break;
          }
        });
        this.alertItem.suspects.push(
          {
            gender,
            hairColour,
            age,
            height
          }
        )
        // console.log(this.alertItem);
      });

      this.isLoadingAlertItem = false;
    })
  }

  closeAlertItem() {
    this.showAlertItemModal = false;
  }

  ngAfterViewInit() {
    fromEvent(this.equipmentSearch.nativeElement, 'keyup').pipe(
      map((event: any) => {
        return event.target.value;
      })
      , filter(res => res.length > 0)
      , debounceTime(1000)
    ).subscribe((text: string) => {
      this.showSearchResults = false;
      this.textSearch = text;
      this.isSearching = true;
      const _searchModel = {
        OrganisationIdSearchCollection: [],
        OrganisationTagIdLookupOperatorSearchCollection: [],
        OrganisationTagIdCollection: [],
        SiteIdSearchCollection: [],
        SiteTagIdLookupOperatorSearchCollection: [],
        SiteTagIdCollection: [],
        TagIdLookupOperatorSearchCollection: [],
        TagIdSearchCollection: [],
        SearchSetChainCollection: [
          {
            SearchSetChainId: 1,
            Feature: "Tags"
          }
        ],
        SearchSetChainLinkCollection: [
          {
            SetId: 1,
            SetId_Seed: 0,
            SearchSetChainId: 1
          }
        ],
        SearchTermCollection: [
          {
            Id: 1,
            Order: 1,
            Domain: "Tags",
            Property: "Equipment",
            SearchTerm: this.textSearch,
            SetId: 1
          }
        ],
        Limit: 0,
        Offset: 0
      };


      this._api.searchEquipment(_searchModel).subscribe(results => {
        this.searchResults = results;
        this.searchResults.forEach(item => {
          this.searchTagCollection.forEach(tagItem => {
            if (tagItem.KeyId === item.KeyId) {
              item.selected = true;
            }
          });
        });
        this.isSearching = false;
        this.showSearchResults = true;
      });
    });
  }

  showLiveAlerts() {
    this.showLiveAlertModal = true;
  }

  closeLiveAlerts() {
    this.showLiveAlertModal = false;
  }

  addNewTag(name: string) {
    this.showSearchResults = false;
    // console.log(name);
    this.isSavingTag = true;
    this._api.postTagCollectionCompositeModelTags(20, 0, name).subscribe(
      results => {
        // console.log(results);
        this.isSavingTag = false;
        const _newTag =
        {
          KeyId: results.TagId,
          MetaData: {
            Name: results.Name
          },
          selected: true
        }
        this.selectSearchTag(_newTag);
      },
      error => {
        console.log(error);
        this.isSavingTag = false;
        alert('Error saving tag. Please try again');
      }
    );
  }

  addAtLeastOne() {
    alert('Please add at least one suspect');
  }

  nextStage() {
    this.missingFields = [];
    if (!this.alertDate) {
      this.missingFields.push('Date');
    }
    if (!this.alertTime) {
      this.missingFields.push('Time');
    }
    if (!this.eventTypeID) {
      this.missingFields.push('Event Type');
    }
    if (!this.policeCalledFalse && !this.policeCalledTrue) {
      this.missingFields.push('Police Called');
    }
    this.isMissingFields = this.missingFields.length > 0 ? true : false;
    if (!this.isMissingFields) {
      this.stage = 2;
    }
  }

  deleteSuspect(ind) {
    if (confirm(`Are you sure you want to delete suspect ${ind + 1}?`)) {
      this.suspectList.splice(ind, 1);
    }
  }

  deleteVehicle(ind) {
    if (confirm(`Are you sure you want to delete vehicle ${ind + 1}?`)) {
      this.vehicleList.splice(ind, 1);
    }
  }

  selectSearchTag(item) {
    item.selected = !item.selected;
    if (!this.searchTagCollection.some(x => x.KeyId === item.KeyId) && item.selected) {
      this.searchTagCollection.push(item);
    } else if (this.searchTagCollection.some(x => x.KeyId === item.KeyId)) {
      const _indexOfTag = this.searchTagCollection.findIndex(x => {
        return x.KeyId === item.KeyId;
      });
      this.searchTagCollection.splice(_indexOfTag, 1);
    }
  }

  removeTag(tag) {
    // console.log(tag.KeyId);
    const _indexOfTag = this.searchResults.findIndex(x => {
      return x.KeyId === tag.KeyId;
    });
    this.searchTagCollection.splice(_indexOfTag, 1);
  }

  closeSearchResults() {
    this.showSearchResults = false;
  }

  addSuspect() {
    this.suspectList.push({
      genderId: null,
      hairColour: null,
      age: null,
      height: null,
    });
  }

  addVehicle() {
    this.vehicleList.push({
      vehicleReg: null
    });
  }

  closeNotification() {
    this.isError = false;
    this.isSavedSuccess = false;
  }

  getGenderList() {
    // this.genderListLoading = true;
    this._api.getGenders().subscribe(results => {
      this.genderList = results;
      // this.genderListLoading = false;
    });
  }



  setGender(ev) {
    // console.log(ev);
  }

  getEventAlertList() {
    // this.challengeListLoading = true;
    this._api.getTagCollectionCompositeModel(2).subscribe(results => {
      this.eventTypeList = results.Tags;
      // this.eventTypeList = results.Tags.map((obj, index) => ({ ...obj, Value: this.eventTypeList[index].Value }));
      // this.challengeListLoading = false;
    });
  }

  policeCalled(wasCalled) {
    if (wasCalled) {
      this.policeCalledTrue = true;
      this.policeCalledFalse = false;
      this.wasPoliceCalled = true;

    } else {
      this.policeCalledTrue = false;
      this.policeCalledFalse = true;
      this.wasPoliceCalled = false;
    }
  }

  setTime(ev) {
    // console.log(this.alertTime);
  }




  addEvent(type: string, event: MatDatepickerInputEvent<Date>) {
    this.events.push(`${type}: ${event.value}`);
    this.alertDate = moment(this.alertDateModel).format('YYYY-MM-DD');
  }

  ToggleFeatureMenu() {
    // If Displaying Feature Collection, hide any buttons
    this.displayFeatures = !this.displayFeatures;
  }




  onActivate(componentRef) {
    this.displayAddNewExcluder = true;
  }

  HideDropDowns() {
    if (this.dropDownIsShowing) { this.DropDownToggle.nativeElement.click(); }
    if (this.exclusionDropDownIsShowing) { this.ExclusionDropDownToggle.nativeElement.click(); }
    if (this.reportingDropDownIsShowing) { this.ReportingDropDownToggle.nativeElement.click(); }
    if (this.adminDropDownIsShowing) { this.AdminDropDownToggle.nativeElement.click(); }
    if (this.messagingDropDownIsShowing) { this.MessagingDropDownToggle.nativeElement.click(); }
    if (this.advancedSearchIsShowing) { this.AdvancedSearchToggle.nativeElement.click(); };
    if (this.adminToolsDropDownIsShowing) { this.AdminToolsDropDownToggle.nativeElement.click(); }
    this.dropDownIsShowing = false;
    this.exclusionDropDownIsShowing = false;
    this.reportingDropDownIsShowing = false;
    this.adminDropDownIsShowing = false;
    this.messagingDropDownIsShowing = false;
    this.advancedSearchIsShowing = false;
    this.adminToolsDropDownIsShowing = false;
  }

  // Double click event in place to prevent bar locking.
  DropDownClicked() {
    if (this.advancedSearchIsShowing) {
      this.AdvancedSearchToggle.nativeElement.click();
      this.advancedSearchIsShowing = false;
    }
    this.dropDownIsShowing = !this.dropDownIsShowing;
  }

  ExclusionDropDownClicked() {
    this.exclusionDropDownIsShowing = !this.exclusionDropDownIsShowing;
  }

  ReportingDropDownClicked() {
    this.reportingDropDownIsShowing = !this.reportingDropDownIsShowing;
  }

  AdminDropDownClicked() {
    this.adminDropDownIsShowing = !this.adminDropDownIsShowing;
  }

  MessagingDropDownClicked() {
    this.messagingDropDownIsShowing = !this.messagingDropDownIsShowing;
  }

  AdministrationToolsDropDownClicked() {
    this.adminToolsDropDownIsShowing = !this.adminToolsDropDownIsShowing;
  }

  navigateToFeatureCollection() {
    this.HideOverlayMenu();
    this.HideDropDowns();
    this.router.navigate(['/user/features']);
  }

  NavigateToGallery() {
    this.HideOverlayMenu();
    this.HideDropDowns();
    this.router.navigate(['user/exclusion/gallery']);
  }

  CreateNewExclusion() {
    this.HideOverlayMenu();
    this.HideDropDowns();
    this.router.navigate(['user/exclusion/new']);
  }

  NavigateToReportsBuilder() {
    this.HideOverlayMenu();
    this.HideDropDowns();
    this.router.navigate(['user/reporting']);
  }

  NavigateToUserAdmin() {
    this.HideOverlayMenu();
    this.HideDropDowns();
    this.router.navigate(['user/admin/user-panel']);
  }

  NavigateToSiteAdmin() {
    this.HideOverlayMenu();
    this.HideDropDowns();
    this.router.navigate(['user/admin/site-panel']);
  }

  NavigateToOrganisationAdmin() {
    this.HideOverlayMenu();
    this.HideDropDowns();
    this.router.navigate(['user/admin/organisation-panel']);
  }

  NavigateToMessaging() {
    this.HideOverlayMenu();
    this.HideDropDowns();
    this.router.navigate(['user/messaging']);
  }

  HideOverlayMenu() {
    if (this.displayFeatures)
      this.displayFeatures = false;
  }

  LogOut() {
    this.logOutSubscription = this.dsrv.LogOut().subscribe(res => {
      this.router.navigate(['/#']);
    });
  }

  // Double click event in place to prevent bar locking.
  AdvancedSearchClicked() {
    if (this.dropDownIsShowing) {
      this.DropDownToggle.nativeElement.click();
      this.dropDownIsShowing = false;
    }
    this.assrv.ReinitializeSearchModel();
    this.advancedSearchIsShowing = !this.advancedSearchIsShowing;
  }

  PrintSearchQuery() {
    // this.assrv.PrintSearchQuery()
  }

  Search() {
    this.assrv.Search().then(value => {
      if (this.advancedSearchIsShowing) { this.AdvancedSearchToggle.nativeElement.click(); };
    });
  }

  NewSearch() {
    this.assrv.ReinitializeSearchModel().then(value => {
    });
  }

  ToggleElevationMode() {
    this.asrv.ToggleElevationMode().then(value => { })
  }

  ReloadDropDownControllers() {
    if (this.asrv.ElevatedMode) {
      this.asrv.PopulateSiteSelectForOrgansiationId(this.asrv.UserOrganisationId).then(value => {
        this.asrv.PopulateColleagueSelectForSiteId(this.asrv.SiteSelect.KeySelected).then(value => { })
      });
    } else {
      this.asrv.PopulateDefaultEmployeeSitesSelect().then(value => {
        this.asrv.PopulateColleagueSelectForSiteId(this.asrv.SiteSelect.KeySelected).then(value => { })
      })
    }
  }

  UnlockAllButtons() {
    this.asrv.ToggleLockedButtons().then(value => { })
  }

  GoBack() {
    this.router.navigate(['/user/features']);
  }

  saveCrimeAlert() {
    this.missingFields = [];
    const _recordedDate = moment(this.alertDate + ' ' + this.alertTime, 'YYYY-MM-DD HH:mm').utc().format('YYYY-MM-DDTHH:mm:ss');
    const _siteID = this.asrv.DefaultSiteId;
    const _userID = this.dsrv.GetMyUserID();
    this.isSaving = true;
    const _suspects: any[] = [];
    const _vehicles: any[] = [];
    const _tagIDCollection = [];

    _tagIDCollection.push(this.eventTypeID);

    if (this.policeCalledTrue) {
      _tagIDCollection.push(120);
    }

    this.suspectList.forEach((suspect, index) => {
      let isValid = true;
      // if (!suspect.genderId) {
      //   this.missingFields.push(`Suspect ${index + 1} gender`);
      //   // isValid = false;
      // }
      // if (!suspect.hairColour) {
      //   this.missingFields.push(`Suspect ${index + 1} hair colour`);
      //   // isValid = false;
      // }
      // if (!suspect.age) {
      //   this.missingFields.push(`Suspect ${index + 1} age`);
      //   // isValid = false;
      // }
      // if (!suspect.height) {
      //   this.missingFields.push(`Suspect ${index + 1} height`);
      //   // isValid = false;
      // }

      let hairTraitModel;
      let ageTraitModel;
      let heightTraitModel;

      if (suspect.hairColour) {
        hairTraitModel = {
          TraitClassKeyId: 1,
          Data: suspect.hairColour
        };
      }

      if (suspect.age) {
        ageTraitModel = {
          TraitClassKeyId: 6,
          Data: suspect.age
        };
      }

      if (suspect.height) {
        heightTraitModel = {
          TraitClassKeyId: 7,
          Data: suspect.height
        }
      }

      const BiologicalActorTraitModelCollection = [];
      if (hairTraitModel) {
        BiologicalActorTraitModelCollection.push(hairTraitModel);
      }
      if (ageTraitModel) {
        BiologicalActorTraitModelCollection.push(ageTraitModel);
      }
      if (heightTraitModel) {
        BiologicalActorTraitModelCollection.push(heightTraitModel);
      }
      
      if (isValid) {
        _suspects.push(
          {
            BiologicalActorCompositeModel: {
              BiologicalActorTraitModelCollection,
              GenderId: suspect.genderId,
              PostalAddressClassId: 1,
            }
          }
        );
      }
    });

    this.vehicleList.forEach((vehicle, index) => {
      let isValid = true;
      if (!vehicle.vehicleReg) {
        this.missingFields.push(`Vehicle ${index + 1} registration`);
        isValid = false;
      }
      if (isValid) {
        _vehicles.push(
          {
            VehicleProperties: [
              {
                VehiclePropertyKeyId: 1,
                Data: vehicle.vehicleReg
              }
            ]
          }
        );
      }
    });

    this.isMissingFields = this.missingFields.length > 0 ? true : false;

    this.searchTagCollection.forEach(tag => {
      _tagIDCollection.push(tag.KeyId);
    });

    const _crimeAlertModel = {
      Recorded: _recordedDate,
      InteractionId: 0,
      InteractedActorCompositeModelCollection: _suspects,
      AssociatedActorIdCollection: [
        _userID
      ],
      Notes: this.notes,
      SiteId: _siteID,
      TagIdCollection: _tagIDCollection,
      VehicleCollection: _vehicles
    };

    if (!this.isMissingFields) {
      this._api.postCrimeAlert(_crimeAlertModel).subscribe(
        result => {
          this.isSaving = false;
          this.isSavedSuccess = true;
          this.isError = false;
          setTimeout(() => {
            this.isSavedSuccess = false;
            this.router.navigate(['/user/features']);
          }, 3000);
        }, error => {
          this.isSaving = false;
          this.isError = true;
          this.errorMsg = error.error;
          console.log(error);
        });
    }
  }

}
