import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, Directive, Input, OnChanges } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ActorService } from 'src/app/services/actor.service';
import { Tags } from 'src/app/models/Tags';
import { ExcluderService } from 'src/app/services/excluder.service';
import { Site } from 'src/app/models/Site';
import { ExcluderViewService } from 'src/app/services/excluder-view.service';
import { ExcluderGalleryService } from 'src/app/services/excluder-gallery.service';
import { ApiService } from 'src/app/services/api.service';
import * as moment from 'moment';

@Component({
  selector: 'app-breach-view',
  templateUrl: './breach-view.component.html',
  styleUrls: ['./breach-view.component.scss']
})
export class BreachViewComponent implements OnInit {

  @ViewChild('btnExtend') btnExtend: ElementRef;
  @ViewChild('btnBreach') btnBreach: ElementRef;
  @ViewChild('btnReinstate') btnReinstate: ElementRef;
  @ViewChild('btnMoreInfo') btnMoreInfo: ElementRef;
  @ViewChild('btnUpdate') btnUpdate: ElementRef;
  @ViewChild('btnUpdateExclusion') btnUpdateExclusion: ElementRef;

  breachNotes = [];
  dataProcessed = false;

  public showSpinner: boolean = true;
  constructor(
    public evsrv: ExcluderViewService,
    private titleService: Title,
    private route: ActivatedRoute,
    public asrv: ActorService,
    public gsrv: ExcluderGalleryService,
    private _api: ApiService
  ) { }

  ngOnInit() {
    const _interactionID = this.route.snapshot.params['id'];
    this.showSpinner = true;
    this.titleService.setTitle('View Exclusion');
    this.evsrv.Initialize(+_interactionID).then(value => {
      this._api.getExclustionBreachCompositeModel(_interactionID).subscribe(results => {
        this.breachNotes = results;
        this.breachNotes.forEach(item => {
          item.BreachedDateTime = moment(item.Breached).utc().format('YYYY-MM-DD HH:mm');
          this._api.getActorNames([item.AssociatedActorIdCollection[0]]).subscribe(actorData => {
            item.Name = `${actorData[0].NameCollection[0].Name} ${actorData[0].NameCollection[1].Name}`;
            this.asrv.GetSiteNameCollection([item.SiteId]).then(value => {
              item.SiteName = value[0].Name;
              this.dataProcessed = true;
            });
          });
        });
        
        this.showSpinner = false;
      });
      
    })
  }

}
