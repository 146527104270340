<div class="container global-seperator">
    <div class="form-content" style="height:83%;">
        <app-interaction-customer-details *ngIf="stage===0" [(honorifics)]="honorifics" [(genders)]="genders"></app-interaction-customer-details>
        <app-interaction-identification *ngIf="stage===1" (ShowHideForwardArrow)="ShowHideForwardArrow();" (ImageTakenWithApp)="GoForward();"></app-interaction-identification>
        <app-interaction-description *ngIf="stage===2"></app-interaction-description>
    </div>

    <div class="form-footer">
        <div class="navigation" (click)="GoBack()">
            <span *ngIf="(showForwardArrow)"><input type="image" class="stage-button" src=".\assets\icons\Bacta Assets\Back button blue.svg" style="width:100px" /></span>
        </div>
        <div class="navigation-warning" style="text-align: center; vertical-align:middle; height: 100%;"></div>   
        <div class="navigation" (click)="GoForward()">
            <span *ngIf="(stage >= 0) && (stage <= 2)  && (showForwardArrow)"><input type="image" class="stage-button" src=".\assets\icons\Bacta Assets\Next button blue.svg" style="width:100px" /></span>
        </div>   
    </div>
</div>