import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Gender } from 'src/app/models/Gender';
import { Honorific } from 'src/app/models/Honorific';
import { ActorService } from 'src/app/services/actor.service';
import { ErrorhanderService } from 'src/app/services/errorhander.service';
import { InteractionService } from 'src/app/services/interaction.service';

@Component({
  selector: 'app-update-details',
  templateUrl: './update-details.component.html',
  styleUrls: ['./update-details.component.scss']
})
export class UpdateDetailsComponent implements OnInit {

  public showForwardArrow: boolean = true;
  public stage: number = -1;
  public honorifics: Honorific[] = [];
  public genders: Gender[] = [];

  constructor(public isrv: InteractionService, public asrv: ActorService, public errsrv: ErrorhanderService) { }

  ngOnInit() {
    this.asrv.GetHonorifics().then(value => {
      this.honorifics = value;
    })

    this.asrv.GetGenders().then(value => {
      this.genders = value;
    })

    this.stage = 0;
  }

  GoForward() {
    switch (this.stage) {
      case 0:
        if (this.DetailsAreValid()) {
          this.stage++;
        }
        break;
      case 1:
        if (this.stage === 1 && !this.MediaIsValid()) {
          this.errsrv.ShowValidationOops('No image!', 'You must supply a photo of the customer or select an icon to proceed.');
        } else {
          if (!this.isrv.HasIconImage()) {
            this.SetLoadingData.next();
            this.isrv.Save(false).then(value => {
              this.ReloadAndShowMainApp.next();
            });
          } else {
            this.stage++;
          }
        }
        break;
      default: 
        this.SetLoadingData.next();
        this.isrv.Save(false).then(value => {
          this.ReloadAndShowMainApp.next();
        });
        break;
    }
  }

  GoBack() {
    switch (this.stage) {
      case 1 || 2:
        this.stage--;
        break; 
      default:
        this.ShowDefaultApp.next();
        break;
    }
  }

  DetailsAreValid(): boolean {
    let biologicalModel = this.isrv.Interaction.InteractedActorCompositeModel.BiologicalActorCompositeModel;
    if (!biologicalModel.HonorificId) {
      this.errsrv.ShowValidationOops('No title selected!', 'You must supply the customers title.');
      return false;
    }

    if (!biologicalModel.GenderId) {
      this.errsrv.ShowValidationOops('No gender selected!', 'You must supply the customers gender.');
      return false;
    }

    if ((!biologicalModel.Forename || !biologicalModel.Surname) && !biologicalModel.Alias) {
      this.errsrv.ShowValidationOops('No names filled out!', 'You must supply the customers firstname and surname, or a nickname.');
      return false;
    }

    if (biologicalModel.Alias) {
      if (this.NameIsValid(biologicalModel.Alias)) {
        this.errsrv.ShowValidationOops('Invalid nickname!', 'Nicknames cannot contain numeric characters or spaces.');
        return false;
      }
    } else {
      if (this.NameIsValid(biologicalModel.Forename) || this.NameIsValid(biologicalModel.Surname)) {
        this.errsrv.ShowValidationOops('Invalid name!', 'Names cannot contain numeric characters or spaces.');
        return false;
      }
    }
    
    return true;
  }

  NameIsValid(name: string): boolean {
    return /\d/.test(name) || /\s/g.test(name);;
  }

  MediaIsValid(): boolean {
    if (!this.isrv.Interaction.MediaModelCollection.some(i => i.MediaClassKeyId === 4)) {
      return false;
    }

    let mediaId = this.isrv.Interaction.MediaModelCollection.find(i => i.MediaClassKeyId === 4).MediaId;
    let imgString = this.isrv.Interaction.MediaModelCollection.find(i => i.MediaClassKeyId === 4).Data;
    if (!(mediaId > 0 && mediaId <= 100) && imgString === this.isrv.whiteSpaceImage) {
      return false;
    }

    return true;
  }

  ShowHideForwardArrow() {
    this.showForwardArrow = !this.showForwardArrow;
  }

  @Output() SetLoadingData = new EventEmitter<string>();
  @Output() ShowDefaultApp = new EventEmitter<string>();
  @Output() ReloadAndShowMainApp = new EventEmitter<string>();
}
