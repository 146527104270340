<div class="memword-modal--wrapper" *ngIf="showModal && _is.secretKeyMap?.length > 0">
  <div class="memword-modal--container">
    <div class="header">
      <h4>Memorable Word</h4>
    </div>
    <div class="body">
      <p>Please select the requested characters of your memorable word to continue your session</p>
      <div class="row">
        <div class="character--wrapper">
          <div class="character">
            {{_is.secretKeyMap[0] + 1}}
          </div>
          <select [(ngModel)]="_is.memwordChar1">
            <option [ngValue]=""></option>
            <option [ngValue]="item" *ngFor="let item of memwordList">{{item}}</option>
          </select>
        </div>

        <div class="character--wrapper">
          <div class="character">
            {{_is.secretKeyMap[1] + 1}}
          </div>
          <select [(ngModel)]="_is.memwordChar2">
            <option [ngValue]=""></option>
            <option [ngValue]="item" *ngFor="let item of memwordList">{{item}}</option>
          </select>
        </div>

        <div class="character--wrapper">
          <div class="character">
            {{_is.secretKeyMap[2] + 1}}
          </div>
          <select [(ngModel)]="_is.memwordChar3">
            <option [ngValue]=""></option>
            <option [ngValue]="item" *ngFor="let item of memwordList">{{item}}</option>
          </select>
        </div>
      </div>
    </div>
    <div class="errors">
      <div *ngIf="showMissingError" class="error-message">Please make sure all fields are filled in</div>
    </div>
    <div class="footer">
      <button type="button" style="margin-right: 10px" class="btn btn-danger" (click)="cancel()">Cancel</button>
      <button type="button" class="btn btn-success" (click)="checkWord()">Continue</button>
    </div>
  </div>
</div>