import { Views_InteractedActorCompositeModel } from './Views_InteractedActorCompositeModel';
import { Views_InteractionMonitoringPeriodsModel } from './Views_InteractionMonitoringPeriodsModel';
import { Views_MediaModel } from './Views_MediaModel';
import { Views_VehicleCompositeModel } from './Views_VehicleCompositeModel';
import { ISDDateMethods } from 'src/app/models/ISDDateMethods';

/*
    Main Model for Interactions, used in the Interaction Service as the base.
*/

export class Views_InteractionsCompositeModel {
    InteractionId: number;
    AssociatedActorIdCollection: number[] = [];
    InteractedActorCompositeModel: Views_InteractedActorCompositeModel;
    InteractedActorCompositeModelCollection: any[] = [];
    InteractionMonitoringPeriod: Views_InteractionMonitoringPeriodsModel;
    MediaModelCollection: Views_MediaModel[] = [];
    Notes: string;
    Reference: string;
    SiteId: number;
    TagIdCollection: number[] = [];
    VehicleCollection: Views_VehicleCompositeModel[] = [];
    Review: any

    // Client Side
    NoActionTaken: boolean;
    Reasons: string;
    Actions: string;
}

export class InteractionsCompositeModel {
    // Client Side
    NoActionTaken: boolean;
    Reasons: string;
    Actions: string;

    private DateMethods: ISDDateMethods = new ISDDateMethods();

    constructor (
        public InteractionId: number,
        public AssociatedActorIdCollection: number[],
        public InteractedActorCompositeModel: Views_InteractedActorCompositeModel,
        public InteractionMonitoringPeriod: Views_InteractionMonitoringPeriodsModel,
        public MediaModelCollection: Views_MediaModel[],
        public Notes: string,
        public Reference: string,
        public SiteId: number,
        public TagIdCollection: number[],
        public VehicleCollection: Views_VehicleCompositeModel[]
    ) {}

    GetCurrentEndDate(): Date {
        return new Date(this.DateMethods.AddDays(this.InteractionMonitoringPeriod.Start, this.InteractionMonitoringPeriod.Duration));
    }
    
    GetExtensionStartDate(): Date {
        return new Date(this.DateMethods.AddDays(this.InteractionMonitoringPeriod.Start, this.InteractionMonitoringPeriod.Duration+1));
    }
}