<div class="interaction-container global-seperator">
    <div class="form-content" style="height:50%;margin-top:30px;">
        <app-customer-se-review *ngIf="stage===0" [(interaction)]="interaction"></app-customer-se-review>
        <app-interaction-review *ngIf="stage===1" (SaveCustomerInteraction)="SaveCustomerInteraction();"></app-interaction-review>
    </div>

    <div class="form-footer">
        <div class="navigation" (click)="GoBack()" style="width: 120px;max-width:120px;">
            <span><input *ngIf="(stage === 1)"  type="image" src=".\assets\icons\Bacta Assets\Back button blue.svg"style="width:100px;" /></span>
        </div>
        <div class="navigation-warning" style="text-align: center; vertical-align:middle; height: 100%;">
            <button id="btnViewInteraction" class="btn btn-dark view-button" type="image" alt="Settings" (click)="ReturnToActionPage();">RETURN TO ACTION PAGE</button>
        </div>   
        <div class="navigation" (click)="GoForward()" style="width: 120px;max-width:120px;text-align: right;">
            <span><input *ngIf="(stage === 0)" type="image" src=".\assets\icons\Bacta Assets\Next button blue.svg" style="width:100px;" /></span>
        </div>   
    </div>
</div>