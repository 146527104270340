import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
// import * as settings from '../../assets/settings.json';
import { Breach } from '../models/Breach';
import { View_ExclusionBreachesCompositeModel } from '../models/InteractionExclusion/Breach/View_ExclusionBreachesCompositeModel';
import { View_ReinstatementsCompositeModel } from '../models/InteractionExclusion/Reinstatement/View_ReinstatementsCompositeModel';
import { GetExclusionsCompositeModel, Views_Get_ExclusionsCompositeModel } from '../models/InteractionExclusion/Views_Get_ExclusionsCompositeModel';
import { Views_InteractionsCompositeModel } from '../models/InteractionExclusion/Views_InteractionsCompositeModel';
import { Reinstate } from '../models/Reinstate';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  // settings = settings.settings.apiEndpoint;
  private _apiBaseURL = environment.apiURL //demo
  creds = sessionStorage.getItem("BactaapiCreds");
  headers = new HttpHeaders({
    'Authorization': 'Basic ' + this.creds
  });
  constructor(
    private _http: HttpClient
  ) {

  }

  public getPublicDocument(docname: string): Observable<any> {
    return this._http.get(
      `${this._apiBaseURL}api/View_documents_public/${docname}`, { headers: this.headers }
    );
  }

  public getPublicDocumentVersion(docname: string, version: string): Observable<any> {
    return this._http.get(
      `${this._apiBaseURL}api/View_documents_public/${docname}/${version}`, { headers: this.headers }
    );
  }

  public getInteractionCompositeModel(interactionId: number): Observable<any> {
    return this._http.get(
      `${this._apiBaseURL}api/View_InteractionCompositeModel/${interactionId}`, { headers: this.headers }
    );
  }

  public getAgeVerificationCompositeModel(interactionId: number): Observable<any> {
    return this._http.get(
      `${this._apiBaseURL}api/View_AgeVerificationInteractionCompositeModel/${interactionId}`, { headers: this.headers }
    );
  }

  public getCrimeAlertCompositeModel(interactionId: number): Observable<any> {
    return this._http.get(
      `${this._apiBaseURL}api/View_CrimeAlertInteractionCompositeModel/${interactionId}`, { headers: this.headers }
    );
  }

  public getFireCheckCompositeModel(interactionId: number): Observable<any> {
    return this._http.get(
      `${this._apiBaseURL}api/View_FireCheckInteractionCompositeModel/${interactionId}`, { headers: this.headers }
    );
  }

  public getToiletCheckCompositeModel(interactionId: number): Observable<any> {
    return this._http.get(
      `${this._apiBaseURL}api/View_WcCheckInteractionCompositeModel/${interactionId}`, { headers: this.headers }
    );
  }

  public getCleaningCheckCompositeModel(interactionId: number): Observable<any> {
    return this._http.get(
      `${this._apiBaseURL}api/View_CleaningCheckInteractionCompositeModel/${interactionId}`, { headers: this.headers }
    );
  }

  public getMachinePayoutCompositeModel(interactionId: number): Observable<any> {
    return this._http.get(
      `${this._apiBaseURL}api/View_MachinePayoutInteractionCompositeModel/${interactionId}`, { headers: this.headers }
    );
  }

  public getMachineServiceVisitCompositeModel(interactionId: number): Observable<any> {
    return this._http.get(
      `${this._apiBaseURL}api/View_MachineServiceInteractionCompositeModel/${interactionId}`, { headers: this.headers }
    );
  }

  public getStatutoryVisitCompositeModel(interactionId: number): Observable<any> {
    return this._http.get(
      `${this._apiBaseURL}api/View_StatutoryVisitInteractionCompositeModel/${interactionId}`, { headers: this.headers }
    );
  }

  public postInteractionCompositeModel(interaction: Views_InteractionsCompositeModel): Observable<any> {
    // let _interaction = interaction;
    // _interaction.InteractedActorCompositeModelCollection.push(interaction.InteractedActorCompositeModel);
    let _interaction = interaction;
    if (_interaction.InteractedActorCompositeModelCollection.length > 0) {
      _interaction.InteractedActorCompositeModelCollection[0] = interaction.InteractedActorCompositeModel;
    } else {
      _interaction.InteractedActorCompositeModelCollection.push(interaction.InteractedActorCompositeModel);
    }
    return this._http.post(
      `${this._apiBaseURL}api/View_InteractionCompositeModel/`, _interaction, { headers: this.headers }
    );
  }

  public getBreachCompositeModel(interactionid: number): Observable<any> {
    return this._http.get(
      `${this._apiBaseURL}api/View_ExclusionBreachesCompositeModel?Views_ExclusionAssetModel.interactionId=${interactionid}`, { headers: this.headers }
    );
  }

  public addBreach(Breach: Breach): Observable<any> {
    return this._http.post(
      `${this._apiBaseURL}api/View_ExclusionBreachesCompositeModel`, Breach, { headers: this.headers }
    );
  }

  public getBreaches(exclusionId: number): Observable<any> {
    return this._http.get(
      `${this._apiBaseURL}api/View_ExclusionBreachesCompositeModel?exclusionid=${exclusionId}`, { headers: this.headers }
    );
  }

  public getBreacheByBreachId(breachid: number): Observable<any> {
    return this._http.get(
      `${this._apiBaseURL}api/View_ExclusionBreachesCompositeModel?exclusionbreachid=${breachid}`, { headers: this.headers }
    );
  }

  public postViewBreachCompositeModel(breach: View_ExclusionBreachesCompositeModel): Observable<any> {
    return this._http.post(
      `${this._apiBaseURL}api/View_ExclusionBreachesCompositeModel`, breach, { headers: this.headers }
    );
  }

  public getReinstatements(interactionId: number): Observable<any> {
    return this._http.get(
      `${this._apiBaseURL}api/view_reinstatementscompositemodel?Views_ExclusionAssetModel.exclusions_InteractionId=${interactionId}`, { headers: this.headers }
    );
  }

  public getReinstatementsForReports(interactionId: number): Observable<any> {
    return this._http.get(
      `${this._apiBaseURL}api/view_reinstatementscompositemodel?Views_ExclusionAssetModel.interactionId=${interactionId}`, { headers: this.headers }
    );
  }

  public addReinstatement(reinstate: Reinstate) {
    return this._http.post(
      `${this._apiBaseURL}api/view_reinstatementscompositemodel`, reinstate, { headers: this.headers }
    )
  }

  public postViewReinstatementsCompositeModel(reinstatement: View_ReinstatementsCompositeModel): Observable<any> {
    return this._http.post(
      `${this._apiBaseURL}api/View_ReinstatementsCompositeModel`, reinstatement, { headers: this.headers }
    );
  }

  public getViewExclusionCompositeModel(interactionId: number): Observable<any> {
    return this._http.get(
      `${this._apiBaseURL}api/View_ExclusionCompositeModel/${interactionId}`, { headers: this.headers }
    );
  }

  public postViewExclusionCompositeModel(exclusion?: Views_Get_ExclusionsCompositeModel): Observable<any> {
    let _exclusion = exclusion;
    if (_exclusion.InteractedActorCompositeModelCollection.length > 0) {
      _exclusion.InteractedActorCompositeModelCollection[0] = exclusion.InteractedActorCompositeModel;
    } else {
      _exclusion.InteractedActorCompositeModelCollection.push(exclusion.InteractedActorCompositeModel);
    }
    return this._http.post(
      `${this._apiBaseURL}api/View_ExclusionCompositeModel/`, exclusion, { headers: this.headers }
    );
  }

  public postExtension(exclusion: GetExclusionsCompositeModel): Observable<any> {
    let _exclusion = exclusion;
    if (_exclusion.InteractedActorCompositeModelCollection.length > 0) {
      _exclusion.InteractedActorCompositeModelCollection[0] = exclusion.InteractedActorCompositeModel;
    } else {
      _exclusion.InteractedActorCompositeModelCollection.push(exclusion.InteractedActorCompositeModel);
    }
    return this._http.post(
      `${this._apiBaseURL}api/View_ExclusionCompositeModel/`, _exclusion, { headers: this.headers }
    );
  }

  public getTagCollectionCompositeModel(collectionID: number): Observable<any> {
    return this._http.get(
      `${this._apiBaseURL}api/View_TagCollectionCompositeModel?TagCollectionId=${collectionID}`, { headers: this.headers }
    );
  }

  public postTagCollectionCompositeModelTags(tagCollectionId: number, tagId = 0, name: string): Observable<any> {
    return this._http.post(
      `${this._apiBaseURL}api/View_TagCollectionCompositeModel/tags`,
      {
        TagCollectionId: tagCollectionId,
        TagId: tagId,
        Name: name
      },
      { headers: this.headers }
    );
  }

  public getGenders(): Observable<any> {
    return this._http.get(
      `${this._apiBaseURL}api/Genders`, { headers: this.headers }
    );
  }

  public getUserOrgID(userID: number): Observable<any> {
    return this._http.get(
      `${this._apiBaseURL}api/View_EmployeeOrganisations?Views_EmployeeOrganisationsModel.employees_actorId=${userID}`, { headers: this.headers }
    );
  }

  public getUserEmployeesList(orgID: number): Observable<any> {
    return this._http.get(
      `${this._apiBaseURL}api/View_EmployeeOrganisations?Views_EmployeeOrganisationsModel.organisations_ActorId=${orgID}`, { headers: this.headers }
    );
  }

  public postAgeVerification(ageVerificationModel): Observable<any> {
    return this._http.post(
      `${this._apiBaseURL}api/View_AgeVerificationInteractionCompositeModel`, ageVerificationModel, { headers: this.headers }
    );
  }

  public postCrimeAlert(crimeAlertModel): Observable<any> {
    return this._http.post(
      `${this._apiBaseURL}api/View_CrimeAlertInteractionCompositeModel`, crimeAlertModel, { headers: this.headers }
    );
  }

  public searchEquipment(searchModel: any): Observable<any> {
    return this._http.post(
      `${this._apiBaseURL}api/View_SearchCompositeModel`, searchModel, { headers: this.headers }
    );
  }

  public getSubscriptionModel(userIDs: Array<number>, creds): Observable<any> {
    return this._http.post(
      `${this._apiBaseURL}api/View_ActorsSubscriptionsCompositeModel`, userIDs
    );
  }

  public postFireCheck(checkModel): Observable<any> {
    return this._http.post(
      `${this._apiBaseURL}api/View_FireCheckInteractionCompositeModel`, checkModel, { headers: this.headers }
    );
  }

  public postToiletCheck(checkModel): Observable<any> {
    return this._http.post(
      `${this._apiBaseURL}api/View_WcCheckInteractionCompositeModel`, checkModel, { headers: this.headers }
    );
  }

  public postCleaningCheck(checkModel): Observable<any> {
    return this._http.post(
      `${this._apiBaseURL}api/View_CleaningCheckInteractionCompositeModel`, checkModel, { headers: this.headers }
    );
  }

  public postMachinePayoutCheck(checkModel): Observable<any> {
    return this._http.post(
      `${this._apiBaseURL}api/View_MachinePayoutInteractionCompositeModel`, checkModel, { headers: this.headers }
    );
  }

  public postMachineServiceCheck(checkModel): Observable<any> {
    return this._http.post(
      `${this._apiBaseURL}api/View_MachineServiceInteractionCompositeModel`, checkModel, { headers: this.headers }
    );
  }

  public postStatutoryVisit(checkModel): Observable<any> {
    return this._http.post(
      `${this._apiBaseURL}api/View_StatutoryVisitInteractionCompositeModel`, checkModel, { headers: this.headers }
    );
  }

  public getExclustionBreachCompositeModel(interactionId: number): Observable<any> {
    return this._http.get(
      `${this._apiBaseURL}api/View_exclusionbreachescompositemodel/?Views_ExclusionAssetModel.exclusions_InteractionId=${interactionId}`, { headers: this.headers }
    );
  }

  public getActorNames(idCollection: number[]): Observable<any> {
    return this._http.post(
      `${this._apiBaseURL}api/view_PublicActorCompositeModel`, idCollection, { headers: this.headers }
    );
  }

  public getStatutoryBodyList(checkModel): Observable<any> {
    return this._http.post(
      `${this._apiBaseURL}api/View_SearchCompositeModel`, checkModel, { headers: this.headers }
    );
  }

  public getSecretKey(): Observable<any> {
    return this._http.get(
      `${this._apiBaseURL}api/View_SecretKeyToken`, { headers: this.headers }
    );
  }

  public postSecretKey(data): Observable<any> {
    return this._http.post(
      `${this._apiBaseURL}api/View_SecretKeyToken`, data, { headers: this.headers }
    );
  }

  public getCheckToken(token: string): Observable<any> {
    return this._http.get(
      `${this._apiBaseURL}api/View_SecretKeyToken/${token}`
    );
  }

  public getExclusionsList(fields: any): Observable<any> {
    return this._http.post(
      `${this._apiBaseURL}api/view_ExclusionInteractionIds/`, fields
    );
  }

  
}
