import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { InteractionService } from 'src/app/services/interaction.service';

@Component({
  selector: 'app-review',
  templateUrl: './review.component.html',
  styleUrls: ['./review.component.scss']
})
export class ReviewComponent implements OnInit {

  constructor(public isrv: InteractionService, private router: Router) { }

  stage: number;
  showAction: boolean;
  reviewMode: boolean = true;

  ngOnInit() {
    this.isrv.StartReview();
    this.stage = 0;
  }

  GoForward() {
    if (this.stage === 0 && (!this.isrv.chkGambling || !this.isrv.chkPattern || !this.isrv.chkBehaviour)) {
      alert('Please check the yes/no boxes before proceeding')
    } else if (this.stage === 1) {
      if (!this.isrv.ReviewHasAction() && !this.isrv.Review.NoActionTaken) {
        alert('You must select at least one action before you can proceed.')
      } else {
        this.stage = 2;
      }
    } else if (this.stage === 2) {
      // console.log('go forward stage 2');
      this.SetLoadingData.next();
      this.router.navigate(['/user/customer-interaction/view', this.isrv.Interaction.InteractionId]);
      this.isrv.setSaveInteraction(true);

      // Review later, this is not functioning properly and required a hack to process to the correct page.
      this.isrv.LoadViewModel(this.isrv.Interaction.InteractionId, true).then(value => {
        this.ReloadAndShowMainApp.next();
      })
      this.isrv.Interaction.TagIdCollection.push(4);
      this.ShowDefaultApp.next();
    } else {
      this.stage = 2;
    }
  }

  GoBack() {
    if (this.stage === 0) {
      this.isrv.ReviewPageVisited = true;
      this.ShowDefaultApp.next();
    } else {
      this.stage = 0;
    }
  }

  ReturnToActionPage() {
    if (this.stage === 0 && (!this.isrv.chkGambling || !this.isrv.chkPattern || !this.isrv.chkBehaviour)) {
      alert('Please check the yes/no boxes before proceeding')
    } else {
      this.showAction = true;
      this.stage = 1;
    }
  }

  @Output() SetLoadingData = new EventEmitter<string>();
  @Output() ShowDefaultApp = new EventEmitter<string>();
  @Output() ReloadAndShowMainApp = new EventEmitter<string>();

}
