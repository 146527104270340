import { SettingCollection } from './SettingCollection';

export class Organisation {
    Employees_ActorId: number; //Decrepit?
    Organisations_ActorId: number; //Decrepit?
    Organisations_Name: string; //Decrepit?

    EmployeeIdCollection: number[];
    SiteIdCollection: number[];
    ActorId: number;
    Name: string;
    ActorReferenceCollection: number[];
    ContactIdCollection: number[];
    SettingCollection: SettingCollection[] = [];
    TagIdCollection: number[];
    NameClassKeyId: number = 7;

}