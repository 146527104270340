import { Honorific } from '../Honorific';
import { Gender } from '../Gender';
import { ExclusionDurationOption } from '../ExclusionDurationOption';
import { SiteCollection } from '../SiteCollection';
import { ISDDateMethods } from '../ISDDateMethods';
import { ActorService } from 'src/app/services/actor.service';
import { ExcluderService } from 'src/app/services/excluder.service';
import { InteractionService } from 'src/app/services/interaction.service';
import { ApiService } from 'src/app/services/api.service';
import * as moment from 'moment';

// Class for creating tabular reports.
// Every tabular class requires the following properties.
// Rows: any[] = [] --Holds all the newRows pushed.
// HeaderTransaltion = [{}]  --This will print all the headers to the table.
// newRow = [{}] --Make the values all blank but match the HeaderTranslationIds
// public AddRow(rowObject) {} --Use this push rows, ensure all values are hardcoded for now.

export class AgeVerificationList {
    private DateMethods: ISDDateMethods = new ISDDateMethods();
    public Rows: any[] = [];
    public Honorifics: Honorific[] = [];
    public Genders: Gender[] = [];
    public DurationOptions: ExclusionDurationOption[] = [];
    public SubscriptionsOpened: number;
    public CompletedSubscriptions: number = 0;
    public DataLoaded: boolean = false;
    private siteNamePromises: SiteNamePromise[] = [];
    public HeaderTranslation = [{
        'Recorded': 'Date of Challenge',
        'detailsOfChallenge': 'Details of Challenge',
        'IDWasShown': 'ID Shown',
        'IDShown': 'ID Type',
        'machinesPlayed': 'Did Customer Play Machines',
        'SiteId': 'Site Name'
    }];

    constructor(
        public asrv: ActorService,
        public isrv: InteractionService,
        public ids: number[],
        private _api: ApiService,
    ) {
        this.ids.forEach(id => {
            this.AddRow(id);
        })

        this.DataLoaded = true;
    }

    public AddRow(id: number) {
        let idWasShown = 'No';
        let returnRow = {};
        returnRow['InteractionId'] = id;
        returnRow['Recorded'] = false;
        returnRow['detailsOfChallenge'] = false;
        returnRow['IDWasShown'] = false;
        returnRow['IDShown'] = false;
        returnRow['Action'] = false;
        returnRow['Start'] = false;
        returnRow['machinesPlayed'] = false;
        returnRow['ReviewDate'] = false;
        returnRow['SiteId'] = false;
        this._api.getAgeVerificationCompositeModel(id).subscribe(res => {
            res.InteractedActorCompositeModel = res.InteractedActorCompositeModelCollection[0];
            if (!(res.InteractedActorCompositeModel.BiologicalActorCompositeModel.Forename && res.InteractedActorCompositeModel.BiologicalActorCompositeModel.Surname)) {
                returnRow['Name'] = res.InteractedActorCompositeModel.BiologicalActorCompositeModel.Alias;
            } else {
                returnRow['Name'] = res.InteractedActorCompositeModel.BiologicalActorCompositeModel.Forename + ' ' + res.InteractedActorCompositeModel.BiologicalActorCompositeModel.Surname;
            }
            let machinesPlayed = 'No';
            let detailsOfChallenge: string = '';
            let idShown: string = '';
            res.TagIdCollection.forEach(tagId => {
                if (tagId >= 210 && tagId <= 212) {
                    if (detailsOfChallenge.length > 1) {
                        detailsOfChallenge = detailsOfChallenge + ' - ';
                    }
                    switch (tagId) {
                        case 210:
                            detailsOfChallenge = detailsOfChallenge + 'Challenged on Entry';
                            break;
                        case 211:
                            detailsOfChallenge = detailsOfChallenge + 'Challenged whilst Browsing';
                            break;
                        case 212:
                            detailsOfChallenge = detailsOfChallenge + 'Challenged whilst Playing';
                            break;
                    }
                }

                if (tagId >= 152 && tagId <= 159) {
                    idWasShown = 'Yes';
                    if (idWasShown === 'Yes') {
                        idShown = '';
                    }
                    switch (tagId) {
                        case 152:
                            idShown = idShown + 'Driving Licence';
                            break;
                        case 153:
                            idShown = idShown + 'Passport';
                            break;
                        case 156:
                            idShown = idShown + 'Citizen Card';
                            break;
                        case 157:
                            idShown = idShown + 'Military Id';
                            break;
                        case 158:
                            idShown = idShown + 'National Id Card';
                            break;
                        case 159:
                            idShown = idShown + 'Other';
                            break;
                    }
                } else if (tagId === 50 || tagId === 134 || tagId === 135) {
                    idShown = '';
                    let stakeReturned = false;
                    let prizeReturned = false;
                    switch (tagId) {
                        case 50:
                            machinesPlayed = 'Yes';
                            break;
                        case 134:
                            machinesPlayed = machinesPlayed + ' - stake returned';
                            stakeReturned = true;
                            break;
                        case 135:
                            machinesPlayed = machinesPlayed + ' - prize returned';
                            prizeReturned = true;
                            break;
                    }
                    
                    if (stakeReturned && prizeReturned) {
                        machinesPlayed = 'Yes - stake and prize returned';
                    }
                }


            })
            returnRow['Recorded'] = moment(res.Recorded, 'YYYY-MM-DDTHH:mm:ssZ').format('YYYY-MM-DD HH:mm');;
            returnRow['detailsOfChallenge'] = detailsOfChallenge;
            returnRow['IDWasShown'] = idWasShown;
            returnRow['IDShown'] = idShown;
            returnRow['machinesPlayed'] = machinesPlayed;
            returnRow['Start'] = this.DateMethods.FormatDateForReport(new Date(res.InteractionMonitoringPeriod.Start));
            returnRow['ReviewDate'] = this.DateMethods.FormatDateForReport(this.DateMethods.AddDays(res.InteractionMonitoringPeriod.Start, res.InteractionMonitoringPeriod.Duration));
            this.GetSiteName(res.SiteId).then(value => {
                returnRow['SiteId'] = value;
            })
        })

        this.Rows.push(returnRow);
    }

    GetSiteName(siteid): Promise<string> {
        if (this.siteNamePromises.some(i => i.SiteId === siteid)) {
            return this.siteNamePromises.find(i => i.SiteId === siteid).Promise;
        } else {
            return new Promise<string>(resolve => {
                this.asrv.GetSiteNameCollection([siteid]).then(value => {
                    if (value) {
                        resolve(value[0].Name);
                    } else {
                        resolve('');
                    }
                })
            })
        }
    }
}

class SiteNamePromise {
    SiteId: number;
    Promise: Promise<string>;
}
