import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { DataService } from './data.service';
import { map, catchError } from 'rxjs/operators';
import { Token } from '../models/Token';
import { ActorService } from './actor.service';
import { ISDDateMethods } from '../models/ISDDateMethods';
import { ErrorhanderService } from './errorhander.service';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class RouteguardService implements CanActivate {

  constructor(
    public dsrv: DataService,
    private router: Router,
    public asrv: ActorService,
    private errorHandler: ErrorhanderService,
    private _api: ApiService
  ) { }

  private dateMethods: ISDDateMethods = new ISDDateMethods();

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    
    if (!this.dsrv.Credentials() || !this.dsrv.GetMyUserID()) {
      // this.router.navigate(['/']);
      return false;
    }

    if (this.dsrv.Credentials) {
      let token: Token = this.dsrv.GetToken();
      if (token) {
        var diff = this.dateMethods.DateDifferenceInDays(token.Edited)
        if (diff >= 10000) {
          this.router.navigate['#']
          return false;
        }
      } else {
        let tokenSubscription = this.asrv.GetToken().subscribe(res => {
          this.dsrv.SetToken(res);
          tokenSubscription.unsubscribe();
        }, err => {
          this.errorHandler.CatchError('canActivate()', 'routeguard.service.ts', err)
          tokenSubscription.unsubscribe();
          return false
        })
      }
      return true;
    }

    // temp
    return false;
  }
}
