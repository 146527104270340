<div *ngIf="(!exclusionLoaded)">
    <div class="col-md-12" style="text-align: center;">
        <app-loading></app-loading>
    </div>
</div>

<div *ngIf="(exclusionLoaded)" class ="excluder-container global-seperator" style="height:83%;">
    <div class="form-header" *ngIf="(stage < 2)">
        <div class="container global-seperator">
            <div class="row">
                <div class="col-6">
                    <div class="excluder-card">
                        <div #card id="excluderCard" class="excluder-card-inner" [style.background]="GetBackgroundColor()">
                            <div class="excluder-photo">
                                <img *ngIf="exclusionImageLoaded" class="excluder-image" [src]="excluderImage" style="width:100%;" />
                                <div *ngIf="!exclusionImageLoaded" class="spinner-border" role="status">
                                    <span class="sr-only"></span>
                                </div>
                            </div>
                            <div class="excluder-info" style="width:100%;">
                                <p>{{Title}} {{esrv.CurrentExclusion.InteractedActorCompositeModel.BiologicalActorCompositeModel.Forename}} {{esrv.CurrentExclusion.InteractedActorCompositeModel.BiologicalActorCompositeModel.Surname}}</p>
                            </div>
                        </div> 
                    </div>
                </div>
                <div class="col-6">
                    <div class="excluder-headline" style="margin:auto;padding-top:50px; padding-bottom:50px;">
                        <h3 class="form-text">Update Exclusion</h3>
                        <h4 class="form-text">Reference: {{esrv.CurrentExclusion.Reference}}</h4>
                    </div>
                </div>
            </div>
        </div>
    </div> 
    <div class="form-content" style="margin:auto;">
        <div class="row" style="height:2vh"></div> 
        <app-customerdetail *ngIf="(stage == 0)" [(genders)]="genders" [(honorifics)]="honorifics"></app-customerdetail>
        <app-customeraddress *ngIf="(stage == 1)" [(object)]="esrv.CurrentExclusion.InteractedActorCompositeModel.BiologicalActorCompositeModel" [(formName)]="formName"></app-customeraddress>
        <app-exclusion-confirmed [(formMessage)]="formMessage" *ngIf="(stage == 2)"></app-exclusion-confirmed>
    </div>       
    <div class="form-footer">
        <div class="navigation" (click)="CallCloseForm()">
            <span *ngIf="(stage == 0)"><input type="image" class="bacta-button" src=".\assets\icons\Bacta Assets\Back button blue.svg" /></span>
        </div>
        <div class="navigation" (click)="GoBack()">
            <span *ngIf="(stage == 1)"><input type="image" class="bacta-button" src=".\assets\icons\Bacta Assets\Back button blue.svg" /></span>
        </div>
        <div class="navigation-warning" style="text-align: center; vertical-align:middle; height: 100%;">
            <span [hidden]="(!displayWarning)"><p id="errorMessageDisplay"></p></span>
            <span *ngIf="showSpinner">        
                <div class="col-md-12" style="text-align: center;">
                    <div class="spinner-border text-danger" style="width: 3rem; height: 3rem;" role="status">
                        <span class="sr-only"></span>
                    </div>
                </div>
            </span>
        </div>   
        <div class="navigation" (click)="GoForward()">
            <span *ngIf="(stage == 0)"><input type="image" class="bacta-button" src=".\assets\icons\Bacta Assets\Next button blue.svg" /></span>
        </div>   
        <div class="navigation" (click)="UpdateDetails()">
            <input id="btnProcessExclusion" *ngIf="(stage == 1)" type="image" class="bacta-button" src=".\assets\icons\Bacta Assets\Update Exclusion button green.svg" />
        </div>   
    </div>
</div>
   