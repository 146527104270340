export class PostObjects {
    AssociatedActorNameCompositeModel: AssociatedActorNameCompositeModel = new AssociatedActorNameCompositeModel();
    CommunicationAccountMessageCompositeModelController: CommunicationAccountMessageCompositeModelController = new CommunicationAccountMessageCompositeModelController();
    CommunicationAccountMessagesCompositeModel: CommunicationAccountMessagesCompositeModel = new CommunicationAccountMessagesCompositeModel();
    CommunicationContacts_CommunicationContactTags: CommunicationContacts_CommunicationContactTags = new CommunicationContacts_CommunicationContactTags();
    Model: BasicPostModel = new BasicPostModel();
}

export class AssociatedActorNameCompositeModel  {
    AssociatedActorIdCollection: number[] = [];
}

export class CommunicationAccountMessageCompositeModelController {
    CommunicationId: number;
    TagIdLookupOperator: string = "Exists";
    TagIdCollection: number[] = [];
    Processed: Date;
    Start: Date;
    End: Date;
    Limit: number;
    Offset: number;
}

export class CommunicationAccountMessagesCompositeModel {
    ActorIdCollection: number[] = [];
    Body: string;
    Subject: string;
    TagIdCollection: number[] = [];
    Edited_ActorId: number;
}

export class CommunicationContacts_CommunicationContactTags {
    ActorId: number;
    CommunicationId: number;
    CommunicationMethodKeyId: number;
    TagId: number;
    Edited: Date;
    Edited_ActorId: number;
}

class BasicPostParameters {
    Start: Date = new Date('2019-01-01');
    End: Date = new Date();
    Limit: number;
    Offset: number = 0;
}

export class BasicPostModel extends BasicPostParameters {
    TagIdLookupOperator: string = "NotExists";
    TagIdCollection: number[] = [];
    OrganisationParameters: OrganisationParameters = new OrganisationParameters();
    SiteParameters: SiteParameters = new SiteParameters();
}

class OrganisationParameters {
    OrganisationIdCollection: number[] = [];
    TagIdLookupOperator: string = 'NotExists';
    TagIdCollection: number[] = [];
}

class SiteParameters {
    SiteIdCollection: number[] = [];
    TagIdLookupOperator: string = 'NotExists';
    TagIdCollection: number[] = [];
}