// Keep All Dynamic Tables Consistant.
// Unused - Uncomment when implemented
export class TableProperties {
    public readonly HeaderBorder: string = '1px solid #223464';
    public readonly HeaderCellBackgroundColor: string = '#223464';
    public readonly HeaderCellColor: string = 'white';
    public readonly CellBackgroundColor: string = 'white';
    public readonly CellColor: string = 'red';
    public readonly CellHeight: string = '15px';
    public readonly CellWidth: string = '100px';
    public readonly CellOverflow: string = 'hidden';
    public readonly CellTextAlign: string = 'center';
    public readonly DataCellPadding: string = '0px';
}