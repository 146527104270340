<div #table class="table-responsive bacta-table-outline" style="margin-top:10px;"> <!--(scroll)="TableScrollEvent();"-->
    <table class="table">
        <thead class="table-header">
            <th class="table-header-inner" *ngFor="let key of objectKeys">{{key}}</th>
        </thead>
        <tbody class="table-row" *ngFor="let row of msrv.MessageList.Rows, index of i" style="background-color: white; color:red;">
            <td class="table-row-inner" *ngFor="let x of tableKeys" (click)="msrv.ReadMessage(row);">
                <span *ngIf="row[x] && row['IsImportant']"><b>{{row[x]}}</b></span>
                <span *ngIf="row[x] && row['IsNew'] && !row['IsImportant']" style="color: #223464"><b>{{row[x]}}</b></span>
                <span *ngIf="row[x] && !row['IsNew'] && !row['IsImportant']" style="color: #797c7c;"><i>{{row[x]}}</i></span>
                <div *ngIf="row[x] === false" class="spinner-border" role="status">
                    <span class="sr-only"></span>
                </div>        
            </td>            
        </tbody>
    </table>
</div>

<!--*ngIf="showMessage"-->
<!--(mouseenter)="MouseEnterRow(row)" (mouseleave)="MouseLeaveRow()"-->
