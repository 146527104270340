import { Component, Input, OnInit, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { InteractionService } from 'src/app/services/interaction.service';

@Component({
  selector: 'app-icon-image',
  templateUrl: './icon-image.component.html',
  styleUrls: ['./icon-image.component.scss']
})
export class IconImageComponent implements OnInit {
  previousEvent;

  // If the MediaId is between 1 and 99 it will request an image from here. 
  // Each iconId is related to SVG in the HTML.
  // iconId === MediaId

  iconId: number;
  @Input('iconId') get IconId(): number {
    return this.iconId;
  };
  @Output('iconId') iconIdChange: EventEmitter<number> = new EventEmitter();
  set IconId(val: number) {
    this.iconId = val;
    this.iconIdChange.emit(this.iconId);
  }

  allowCSS: boolean;
  @Input('allowCSS') get AllowCSS(): boolean {
    return this.allowCSS;
  };
  @Output('allowCSS') allowCSSChange: EventEmitter<boolean> = new EventEmitter();
  set AllowCSS(val: boolean) {
    this.allowCSS = val;
    this.allowCSSChange.emit(this.allowCSS);
  }

  constructor(public isrv: InteractionService) { }

  ngOnInit() {
  }

  SelectImage(id, event) {
    if (this.allowCSS) {
      this.isrv.AddBoxShadowToSelectedElement(event, id);
    }
  }
}
