<div *ngIf="evsrv.ShowLoadingSpinner" class="container global-seperator" style="min-height:400px;">
    <span>
        <app-loading></app-loading>
    </span>
</div>

<div class="container global-seperator" *ngIf="!evsrv.ShowLoadingSpinner">

    <!--Desktop/Tablet Card-->
    <div *ngIf="evsrv.ShowCard" class="d-none d-md-block">
        <app-exclusion-card-md></app-exclusion-card-md>
    </div>

    <div *ngIf="evsrv.ShowCard" class ="d-block d-md-none" style="margin:auto">
        <app-exclusion-card-mobile></app-exclusion-card-mobile>
    </div>

    <div class="button-container global-seperator" *ngIf="(evsrv.ShowButtons)" (ngInit)="evsrv.EnableDisableFormControls()">
        <div class="button-divider"><button #btnExtend type="button" [disabled]="evsrv.esrv.CurrentExclusion.Extension? true: false" [style.cursor]="evsrv.esrv.CurrentExclusion.Extension?'not-allowed':'pointer'" [style.borderColor]="evsrv.esrv.CurrentExclusion.Extension?'grey':'orangered'" [style.backgroundColor]="evsrv.esrv.CurrentExclusion.Extension?'grey':'orangered'" id="btnExtend" class="btn btn-dark view-button" (click)="evsrv.ExtendClick()">ADD EXTENSION</button></div> 
        <div class="button-divider"><button #btnBreach type="button" id="btnBreach" class="btn btn-dark view-button" (click)="evsrv.RecordBreachClick()">RECORD BREACH</button></div> 
        <div class="button-divider"><button #btnReinstate type="button" [disabled]="evsrv.CanReinstate || asrv.UnlockedButtons? false: true" [style.cursor]="evsrv.CanReinstate || asrv.UnlockedButtons?'pointer':'not-allowed'" [style.borderColor]="evsrv.CanReinstate || asrv.UnlockedButtons?'orangered':'grey'" [style.backgroundColor]="evsrv.CanReinstate || asrv.UnlockedButtons?'orangered':'grey'" id="btnReinstate" class="btn btn-dark view-button" (click)="evsrv.ReinstateClick()">REINSTATE</button></div> 
        <div class="button-divider"><button #btnMoreInfo type="button" id="btnMoreInfo" class="btn btn-dark view-button" (click)="evsrv.MoreInfoClick()">MORE INFO</button></div> 
        <div class="button-divider"><button #btnUpdate type="button" id="btnUpdate" class="btn btn-dark view-button" (click)="evsrv.UpdateExcluderDetailsClick()">UPDATE DETAILS</button></div>
        <div *ngIf="evsrv.UserIsAdmin" class="button-divider"><button #btnUpdateExclusion type="button" id="btnUpdate" class="btn btn-dark view-button" (click)="evsrv.UpdateExclusionDetailsClick()">UPDATE EXCLUSION</button></div>
    </div>

    <div class="form-container global-seperator" *ngIf="!(evsrv.ShowButtons)">
        <form>
            <div class="form-group">
                <app-extend *ngIf="(evsrv.CurrentForm == evsrv.AppExtend)"></app-extend>
                <app-record-breach *ngIf="(evsrv.CurrentForm == evsrv.AppRecordBreach)"></app-record-breach>
                <app-reinstate *ngIf="(evsrv.CurrentForm == evsrv.AppReinstate)"></app-reinstate>
                <app-more-info *ngIf="(evsrv.CurrentForm == evsrv.AppHTML)"></app-more-info>
                <app-update-exclusion *ngIf="(evsrv.CurrentForm == evsrv.AppUpdateExclusion)"></app-update-exclusion>
            </div>
        </form>
    </div>

    <!--<app-customer-acknowledgement [(html)]="exclusionSummaryHTML" [(additionalHTML)]="additionalHTML" (CloseForm)="CloseForm();" *ngIf="(viewForm == appHTML)"></app-customer-acknowledgement>-->
</div>


