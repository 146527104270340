import { Views_MediaModel } from "../../InteractionExclusion/Views_MediaModel";
import { Checkbox } from "../Standard Controllers/Checkbox";

export class Extension {
    public SiteId: number;
    public EmployeeId: number;
    public MediaModel: Views_MediaModel;
    public ConfirmedControl: Checkbox;
    constructor(public InteractionId: number, public StartDate: Date) {
        this.ConfirmedControl = new Checkbox(0, 'confirmExtension', 'I CONFIRM THE EXTENSION TO MY SELF-EXCLUSION', 'chkConfirmExtension', false);
    }

    public UpdatePhoto(): Views_MediaModel {
        this.MediaModel = new Views_MediaModel();
        this.MediaModel.MediaClassKeyId = 4;
        this.MediaModel.MediaId = 0;
        this.MediaModel.Data = null;
        return this.MediaModel;
    }

    public Validate(): boolean {
        var result: boolean = true;
        if (!this.SiteId || !this.EmployeeId) {
            result = false;
        };

        if (!this.ConfirmedControl.Value) {
            this.ConfirmedControl.ErrorFlag = true;
            result = false;
        } else {
            this.ConfirmedControl.ErrorFlag = false;
        };

        if (this.MediaModel) {
            if (!this.MediaModel.Data) {
                delete this.MediaModel;
            }
        };

        return result;
    }
}
