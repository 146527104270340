export class TagValues {
    public readonly ArchivedInteraction: number = 4;
    public readonly IncompleteInteraction: number = 5;
    public readonly ReinstatedExcluder: number = 7;

    // Interaction Reasons
    public readonly InteractionCustomerLed: number = 90
    public readonly InteractionReasonTime: number = 91;
    public readonly InteractionReasonSpend: number = 92;
    public readonly InteractionReasonBehaviour: number = 93
    public readonly InteractionReasonMachineAlert: number = 94
    public readonly InteractionReasonVulnerability: number = 95
    public readonly InteractionReasonWishToExclude: number = 96

    // Interaction Actions
    public readonly InteractionActionTimeOut: number = 6;
    public readonly InteractionActionBanned: number = 110;
    public readonly InteractionActionTakeABreak: number = 111;
    public readonly InteractionActionImposeTimeLimits: number = 112;
    public readonly InteractionActionImposeSpendLimits: number = 113;
    public readonly InteractionActionChangeCategory: number = 114;
    public readonly InteractionActionMonitorBehaviour: number = 115;
    public readonly InteractionActionSignpostToGamcare: number = 116;
    public readonly InteractionActionStayInControlLeaflet: number = 117;
    public readonly InteractionActionGambleManagementApp: number = 118;
    public readonly InteractionActionIssuedPlayDiary: number = 119;

}