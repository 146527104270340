export class UserDocumentation {
    public PrivacyPolicy: string = 'PrivacyPolicy';
    public SummaryOfPrivacyPolicy: string = "SummaryOfPrivacyPolicy";
    public SummaryOfTerms: string = "SummaryOfTerms";
    public Terms: string = "Terms";
    public IpsosMori: string = "ipsosMori";

    public IpsosMoriVersion: string;
    public PrivacyPolicyVersion: string;
    public SummaryOfPrivacyPolicyVersion: string;
    public SummaryOfTermsVersion: string;
    public TermsVersion: string; 

    public IpsosMoriHTML: string;
    public PrivacyPolicyHTML: string;
    public SummaryOfPrivacyPolicyHTML: string;
    public SummaryOfTermsHTML: string;
    public TermsHTML: string; 
}