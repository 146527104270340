import { Tag } from './Tag';
import { ActorService } from '../services/actor.service';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class Tags {
    Disabled: number = 1;
    Important: number = 2;
    Confirmed: number = 3;
    MotorwayService: number = 21;
    RadiusService: number = 22;
    IdentityChecked: number = 31;
    ReadTermsAndConditions: number = 32;
    ExcludeBingo: number = 33;
    ExcludeCasino: number = 34;
    ExcludeBetShop: number = 35;
    ExcludeOnline: number = 36;
    PlanExcludeBingo: number = 37;
    PlanExcludeCasino: number = 38;
    PlanExcludeBetShop: number = 39;
    PlanExcludeOnline: number = 40;
    Participated: number = 50;
    SiteNotificationContact: number = 61;

    // 61 email
    Tag: Tag[];
    UserRoleTags: UserRoleTags = new UserRoleTags();
    NameTags: NameTags = new NameTags();
    PostalAddressTags: PostalAddressTags = new PostalAddressTags();

    constructor(public asrv: ActorService) {}

    GetTagInfo(tagId: number): Tag {
        let tag = this.Tag.find(i => i.TagId === tagId);
        if (!tag) {
            this.asrv.GetTagInfo(tagId).subscribe(res => {
                this.Tag.push(res);
                return res;
            })
        } else {
            return tag;
        }
    }
}

class UserRoleTags {
    System = 1;
    SystemAdministrator = 2;
    BactaAdministrator = 3;
    OrganisationAdministrator = 4;
    Supervisor = 5;
    User = 6;

    GetRoleName(id) {
        switch(id) {
            case this.System: return 'System';
            case this.SystemAdministrator: return 'System Administrator';
            case this.BactaAdministrator: return 'Bacta Administrator';
            case this.OrganisationAdministrator: return 'Organisation Administrator';
            case this.Supervisor: return 'Supervisor';
            case this.User: return 'User';
        }
    }

    GetRolesObject(id): RoleBriefs[] {
        switch(id) {
            case this.System: 
                var object: RoleBriefs[] = [{Id: 1, Name: 'System'}, {Id: 2, Name: 'System Administrator'}, {Id: 3, Name: 'Bacta Administrator'}, {Id: 4, Name:'Organisation Administrator' }, {Id: 5, Name: 'Supervisor'}, {Id: 6, Name: 'User'}];
                return object;
            case this.SystemAdministrator: 
                var object: RoleBriefs[] = [{Id: 2, Name: 'System Administrator'}, {Id: 3, Name: 'Bacta Administrator'}, {Id: 4, Name:'Organisation Administrator' }, {Id: 5, Name: 'Supervisor'}, {Id: 6, Name: 'User'}];
                return object;
            case this.BactaAdministrator: 
                var object: RoleBriefs[] = [{Id: 3, Name: 'Bacta Administrator'}, {Id: 4, Name:'Organisation Administrator' }, {Id: 5, Name: 'Supervisor'}, {Id: 6, Name: 'User'}];
                return object;
            case this.OrganisationAdministrator: 
                var object: RoleBriefs[] = [{Id: 5, Name: 'Supervisor'}, {Id: 6, Name: 'User'}];
                return object;
            case this.Supervisor: 
                var object: RoleBriefs[] = [{Id: 6, Name: 'User'}];
                return object;
            case this.User: return [];
        }
    }
}

export class RoleBriefs {
    Id: number;
    Name: string;
}

class NameTags {
    Firstname = 1;
    Surname = 2;
    // 3
    // 4
    OrganisationName = 5;
    SiteName = 6;
}

class PostalAddressTags {
    public Country = 1;
    public Postcode = 2;
    public County = 3;
    public TownCity = 4;
    // City = 5
    public Line1 = 6;
    public Line2 = 7;
}

class DurationTags {
    private _years: number[] = [6,7,8,9,10,11,12];
    private _durationOptionIds: number[] = [0,1,2,3,4,5,6]

    FetchDurationOptionIdForMonth(month: number) {
    }
}