import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root'
})
export class TokeninterceptorService implements HttpInterceptor {

  private _token;

  constructor(
    private _ds: DataService
  ) {
    this._token = sessionStorage.getItem('BactaapiCreds');
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const _isAuthReq = request.url.includes('View_SessionTokens');
    if (this._ds.authToken && !_isAuthReq) {
      request = request.clone({
        setHeaders: {
          Authorization: `Basic ${this._ds.authToken}`
        }
      });
    } else if (this._token && !_isAuthReq) {
      request = request.clone({
        setHeaders: {
          Authorization: `Basic ${this._token}`
        }
      });
    }
    return next.handle(request);
  }


}
