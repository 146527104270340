<div *ngIf="gsrv.LoadingCards">
    <div class="col-md-12" style="text-align: center;" style="height:100vh;">
        <app-loading></app-loading>
    </div>
</div>
<div *ngIf="!gsrv.LoadingCards" class="container global-seperator" [style.marginTop]="asrv.Globals.Positioning.Small" [style.paddingLeft]="asrv.Globals.Positioning.Small" [style.paddingRight]="asrv.Globals.Positioning.Small">
    <div class="row">
        <div class="col-xs-12 col-md-4" *ngFor="let interaction of gsrv.CustomerInteractionBriefsCurrentlyVisible; let j = index">
            <app-interaction-card [interaction]="interaction"></app-interaction-card>
        </div>
    </div>
    <div class="page-counter" [style.marginTop]="asrv.Globals.Positioning.Medium" style="text-align: center;">
        <span *ngIf="gsrv.MaximumPageCount > 0" [style.marginTop]="asrv.Globals.Positioning.Small" [style.marginBottom]="asrv.Globals.Positioning.Small">Page {{gsrv.CurrentPage+1}} / {{gsrv.MaximumPageCount}}</span>
    </div>
    <div class="form-footer">
        <div class="navigation" (click)="gsrv.PreviousPage()">
            <span *ngIf="(gsrv.CurrentPage > 0)"><input type="image" src=".\assets\icons\Bacta Assets\Back button blue.svg" style="width:100px" /></span>
        </div>
        <span [style.marginTop]="asrv.Globals.Positioning.Small" [style.marginBottom]="asrv.Globals.Positioning.Small"></span>
        <div class="navigation" (click)="gsrv.NextPage()">
            <span *ngIf="(gsrv.MaximumPageCount > (gsrv.CurrentPage+1))"><input type="image" src=".\assets\icons\Bacta Assets\Next button blue.svg" style="width:100px" /></span>
        </div>   
    </div>
</div>