export class Actor_Settings {
    SettingId: number;
    Settings: Setting;
}

export class Settings {
    ActorId: number = -1;
    SettingId: number = -1;
    Edited: Date;
    Edited_ActorId: number;
}

export class Setting {
    SettingId: number;
    SettingKeyId: number = -1;
    Value: any = '';
    Edited: Date;
    Edited_ActorId: number;
}

class SettingKey {
    SettingKeyId: number;
    Name: any;
}

