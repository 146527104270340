import { Component, OnInit, Input, Output, EventEmitter, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { ActorService } from 'src/app/services/actor.service';

declare var require: any;
const jsPDF = require('jspdf');
require('jspdf-autotable');

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss', '../main-view.component.scss']
})
export class TableComponent implements OnInit, AfterViewInit {

  @ViewChild('table') table: ElementRef;

  maxWindowWidth = 1140;
  showLeftIndicator: boolean;
  showRightIndicator: boolean;
  csvObject = [];
  csvObjectKeys = [];
  tableKeys = [];

  public dataModel: any;
  @Input('dataModel') get DataModel(): any {
    return this.dataModel;
  };
  @Output('dataModel') dataModelChange: EventEmitter<any> = new EventEmitter();
  set DataModel(val: any) {
    this.dataModel = val;
    this.dataModelChange.emit(this.dataModel);
  }  

  public reportName: string;
  @Input('reportName') get ReportName(): string {
    return this.reportName;
  };
  @Output('reportName') reportNameChange: EventEmitter<string> = new EventEmitter();
  set ReportName(val: string) {
    this.reportName = val;
    this.reportNameChange.emit(this.reportName);
  }

  constructor(public asrv: ActorService) { }

  ngOnInit() {
    this.dataModel.Rows.forEach(row => {
      this.csvObject.push(row);
    });
    this.csvObjectKeys = Object.values(this.dataModel.HeaderTranslation[0]);
    this.tableKeys = Object.keys(this.dataModel.HeaderTranslation[0])
  }

  ngAfterViewInit() {
    if ((window.innerWidth*0.90) < this.maxWindowWidth) {
      this.maxWindowWidth = (window.innerWidth*0.90);
    }
    if (this.maxWindowWidth < this.table.nativeElement.scrollWidth) {
      this.showRightIndicator = true;
    }
  }

  ConvertToCSV() {
    var str = "";
    var row = "";
    for (let key of this.csvObjectKeys) {
      row += key + ',';
    }
    str += row + '\r\n';
    row = '';
    for (let object of this.csvObject) {
        for (let key of this.tableKeys) {
          row += object[key + ''] + ',';
        }
        str += row + '\r\n';
        row = '';
    }
    return str;
  }

  ConvertToPDF() {
    let orientation = '';
    let x = 0;
    if (this.reportName !== 'Excluded Customer List') {
      orientation = 'p';
      x = 250;
    } else {
      orientation = 'l';
      x = 380;
    }
    let doc = new jsPDF(orientation, 'pt');
    let rowsObject = [];
    for (let object of this.csvObject) {
      let rowObject = [];
      for (let key of this.tableKeys) {
        rowObject.push(object[key + '']);
      }
      rowsObject.push(rowObject)
    }

    doc.text(x, 20, this.reportName);
    doc.autoTable(this.csvObjectKeys, rowsObject);

    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      var pdf = doc.output('datauristring');
      var pdfWindow = window.open();
      pdfWindow.document.open();
      pdfWindow.document.location=pdf;  
    } else {
      doc.save(new Date().toISOString() +'_' + this.reportName + '.pdf');
    }
  }

  ExportToExcel(){    
    var csvData = this.ConvertToCSV();
    var a = document.createElement("a");
    a.setAttribute('style', 'display:none;');
    document.body.appendChild(a);
    var blob = new Blob([csvData], { type: 'text/csv' });
    var url= window.URL.createObjectURL(blob);
    a.href = url;
    a.download = new Date().toISOString() + '_' + this.reportName + '.csv';
    a.click();
    return 'success';
   }

   TableScrollEvent() {
    if (this.table.nativeElement.scrollLeft > 0) {
      this.showLeftIndicator = true;
    } else {
      this.showLeftIndicator = false;
    }

    let scrollWidthMax: number = this.table.nativeElement.scrollWidth - this.table.nativeElement.clientWidth;;
    if (this.table.nativeElement.scrollLeft < scrollWidthMax && this.maxWindowWidth < this.table.nativeElement.scrollWidth) {
      this.showRightIndicator = true;
    } else {
      this.showRightIndicator = false;
    }
   }

}
