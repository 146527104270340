<div *ngIf="showPhotoApp" class="container global-seperator">
    <app-customer-photo [(mediaModel)]="mediaModel" (ImageTaken)="ClosePhotoApp();" (NoImageTaken)="ClosePhotoApp();"></app-customer-photo>
</div>


<div *ngIf="!(showPhotoApp)" class="container global-seperator" style="margin-top:15px;">    
    <div class="row">
        <div class="col-md-12">
            <h4><b>Extend Exclusion</b></h4>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <div id="ctrlSites" class="input-group">
                <div class="input-group-prepend">
                    <span class="input-group-text">SITE*</span>
                </div>
                <select class="form-control drop-down-purple" [(ngModel)]="siteId" (change)="PopulateEmployeeDropDown()">
                    <option></option>
                    <option *ngFor="let dropDownItem of asrv.SiteSelect.DropDownItems" [ngValue]="dropDownItem.Key">{{dropDownItem.Value}}</option>
                </select>
            </div>
        </div>
        <div class="col-md-6">                
            <div class="input-group" id="inputUser">
                <div class="input-group-prepend">
                    <span class="input-group-text">Interview By</span>
                </div>
                <!--<div class="spinner-border" *ngIf="loadingEmployeeDropDown" role="status"><span class="sr-only"></span></div>-->
                <select class="form-control drop-down-purple" name="user" [(ngModel)]="employeeId">
                    <option></option>
                    <option *ngFor="let dropDownItem of asrv.ColleagueSelect.DropDownItems" [ngValue]="dropDownItem.Key">{{dropDownItem.Value}}</option> 
                </select>
            </div>                
        </div>
    </div>

    <div class="row">
        <div class="col-md-6">                
            <div class="input-group" id="inputUser">
                <div class="input-group-prepend">
                    <span class="input-group-text">Start Date</span>
                </div>
                <input type="text" class="form-control" [(ngModel)]="newStartDateString" disabled />
            </div>                    
        </div>
        <div class="col-md-6">
            <div class="input-group" id="inputExclusionPeriod">
                <div class="input-group-prepend">
                    <span class="input-group-text">Extend By</span>
                </div>
                <div class="input-group-append input-group-append-s" (click)="periodMovePrevious();">
                    <span class="input-group-text">&lt;</span>
                </div>
                <input type="text" class="form-control" [ngModel]="durationPeriods[selectedDurationIndex]" style="text-align: center;" disabled>
                <div class="input-group-append input-group-append-s" (click)="periodMoveNext();">
                    <span class="input-group-text">></span>
                </div>
                <div class="input-group-append">
                    <span class="input-group-text">MONTHS*</span>
                </div>
            </div>  
        </div>
    </div>

    <div class="row" style="text-align: center;margin-top:10px;">
        <div class="col-md-12 ">
            <button type="button" id="btnExtend" class="btn btn-dark view-button-standard" (click)="UpdatePhoto()">Update Photo</button>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-6 col-md-12">
            <div class="flex-check-box">
                <div class="flex-check-prepend">I confirm the extension to my self-exclusion</div>
                <input id="chkCustomerLed" type="checkbox" class="flex-check-box-control" [(ngModel)]="emailVerification">
            </div>        
        </div>
    </div>
    <div class="form-group" style="text-align: right; margin-top: 20px;">
        <button type="button" class="btn btn-danger" (click)="evsrv.ShowMainView(false);" style="margin-right: 10px;">Close</button>
        <button type="button" class="btn btn-success" (click)="ValidateAndSendData();">
            <span *ngIf="showSendingSpinner" class="spinner-grow spinner-grow-sm" role="status"></span>
            Add<span *ngIf="showSendingSpinner">ing</span> Extension<span *ngIf="showSendingSpinner">...</span>
        </button>
    </div>
</div>