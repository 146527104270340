import { ControllerMaster } from "../ControllerMaster";

export class Select extends ControllerMaster {
    public KeySelected: any;
    constructor(
        public Id: number, 
        public Name: string, 
        public Label: string, 
        public DomId: string,
        public DropDownItems: DropDownItem[]
    ) {
        super(Id, Name, Label, 'select', DomId);
    }
}

export class DropDownItem {
    constructor(public Key: any, public Value: any) {}
}