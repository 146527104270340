<div class="container global-seperator" style="margin-top:15px;" *ngIf="!inspectSites" (click)="CloseForm();">
    <h4>Sites on {{motorway.Name}} (Tap To Close)</h4>
    <div class="address-in-radius-table">
        <div class="address-in-radius-table_header-format">
            <div class="row">
                <div class="col-12">Site Name</div>
            </div>
        </div>
        <div class="address-in-radius-table_row-format">
            <div class="row" *ngFor="let site of motorway.Sites">
                <div class="col-12 address-in-radius-table_row-content">
                    {{site.Site_Name}}
                </div>
            </div>
        </div>
    </div>
</div>
