import { Honorific } from '../Honorific';
import { Gender } from '../Gender';
import { ExclusionDurationOption } from '../ExclusionDurationOption';
import { ExclusionReason } from '../ExclusionReason';
import { Site } from '../Site';
import { Organisation } from '../Organisation';
import { IdentificationDocument } from '../IdentificationDocumentKey';
import { SiteCollection } from '../SiteCollection';
import { ISDDateMethods } from '../ISDDateMethods';
import { ActorService } from 'src/app/services/actor.service';
import { Subscription } from 'rxjs';
import { ApiUsage } from '../ApiUsage';

// Class for creating tabular reports.
// Every tabular class requires the following properties.
// Rows: any[] = [] --Holds all the newRows pushed.
// HeaderTransaltion = [{}]  --This will print all the headers to the table.
// newRow = [{}] --Make the values all blank but match the HeaderTranslationIds
// public AddRow(rowObject) {} --Use this push rows, ensure all values are hardcoded for now.

export class UserAPIUsage {
    private DateMethods: ISDDateMethods = new ISDDateMethods();
    public Rows: any[] = [];
    public SubscriptionsOpened: number;
    public CompletedSubscriptions: number = 0;
    public DataLoaded:boolean = false;
    public HeaderTranslation = [{
        'ActorName': 'Name', 
        'PrimarySiteName': 'Primary Site',
        'DaysAccessedCount': 'Days Accessed', 
        'ExclusionCount':'Exclusions',
        'ExclusionBreachCount': 'Breaches',
        'InteractionCount': 'Interactions',
        'ReinstatementCount': 'Reinstatements',
        'LastAccessed':'Last Accessed'
    }];

    public GetExclusionIsSubscribed: boolean;
    public ExclusionSubscription: Subscription;

    constructor(public asrv: ActorService) {}


    public AddRow(databaseValues: ApiUsage) {
        var returnRow = {};
        returnRow['ActorId'] = databaseValues.ActorId;
        returnRow['ActorName'] = databaseValues.ActorName;
        returnRow['DaysAccessedCount'] = databaseValues.DaysAccessedCount.toString();
        returnRow['ReinstatementCount'] = databaseValues.ReinstatementCount.toString();
        returnRow['ExclusionBreachCount'] = databaseValues.ExclusionBreachCount.toString();
        returnRow['ExclusionCount'] = databaseValues.ExclusionCount.toString();
        returnRow['InteractionCount'] = databaseValues.InteractionCount.toString();
        returnRow['LastAccessed'] = new Date(databaseValues.LastAccessed).toDateString();
        returnRow['PrimarySiteName'] = databaseValues.PrimarySiteName;
        this.DataLoaded = true;
        this.Rows.push(returnRow);
    }
}
