import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { Observable } from 'rxjs';
import { Site } from '../models/Site';
import { SiteCollection } from '../models/SiteCollection';

@Injectable({
  providedIn: 'root'
})
export class OrganisationService {

  constructor(public dsrv: DataService) { }

  public GetSite(){
    
  }

  public GetSitesWithinRadius(SiteActorID: number, Radius: number) : Observable<Site[]> {
    return this.dsrv.Get<Site[]>('api/View_SitesWithinRadiusCompositeModel?ActorId='+SiteActorID+'&Radius='+Radius);
    // return this.dsrv.Get<Site[]>('api/sitecollections?ActorId='+SiteActorID+'&Radius='+Radius);
  }

  public GetMotorWayServices(): Observable<SiteCollection[]> {
    return this.dsrv.Get<SiteCollection[]>('api/View_SiteCollectionCompositeModel?SiteCollectionId=0');
    //return this.dsrv.Get<SiteCollection[]>('api/sitecollections?sitecollectionid=0')
  }

  public GetSitesOnMotorWay(collectionId: number): Observable<SiteCollection[]> {
    return this.dsrv.Get<SiteCollection[]>('api/View_SiteCollectionCompositeModel?sitecollectionid=' + collectionId);
  }

}
