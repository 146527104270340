import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActorService } from 'src/app/services/actor.service';
import { OrganisationService } from 'src/app/services/organisation.service';
import { Honorific } from 'src/app/models/Honorific';
import { Gender } from 'src/app/models/Gender';
import { ExclusionReason } from 'src/app/models/ExclusionReason';
import { ExclusionDurationOption } from 'src/app/models/ExclusionDurationOption';
import { Organisation } from 'src/app/models/Organisation';
import { Site } from 'src/app/models/Site';
import { IdentificationDocument } from 'src/app/models/IdentificationDocumentKey';
import { SiteCollection } from 'src/app/models/SiteCollection';
import { ErrorhanderService } from 'src/app/services/errorhander.service';
import { UserSites } from 'src/app/models/UserSites';
import { DataService } from 'src/app/services/data.service';
import { ExcluderService } from 'src/app/services/excluder.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-excluder-new',
  templateUrl: './new.component.html',
  styleUrls: ['./new.component.scss']
})
export class NewComponent implements OnInit {
  formMessage;
  displayWarning: boolean;
  constructor( 
    public esrv: ExcluderService,
    public asrv: ActorService, 
    private osrv: OrganisationService,
    private titleService: Title,
    private router: Router
  ) { 
  }

  public stage: number = 0;
  public showSpinner: boolean;
  public formName = 'Excluder';
  public showForwardArrow = true;

  // Form Control Collections
  public honorifics: Honorific[] = [];
  public genders: Gender[] = [];
  public sites: Site[] = [];
  public organisations: Organisation[] = [];
  public idDocTypes: IdentificationDocument[] = [];
  public motorWayCollection: SiteCollection[] = []; 
  public duration: number = 365;

  ngOnInit() {
    if (!this.esrv.CurrentExclusion) {
      this.router.navigate['user/exclusion/interaction']
    }

    this.esrv.ReInitiateExclusion();
    this.titleService.setTitle('New Exclusion');
    //default values for exclusion

    this.asrv.GetHonorifics().then(value => {
      this.honorifics = value;
    });
    this.asrv.GetGenders().then(value => {
      this.genders = value;
      this.showSpinner = false;
    })

    let motorWayServicesSubscription = this.osrv.GetMotorWayServices().subscribe(sites => {
      this.motorWayCollection = sites;
      motorWayServicesSubscription.unsubscribe();
    }, err => {
      motorWayServicesSubscription.unsubscribe();
    });
  }

  public GoBack() {
    if(this.stage > 0) {
      this.stage -= 1;
      window.scroll(0,0);
      if (this.stage === 4) {
        this.ShowHideForwardArrow();
      }
    }
  }

  public GoForward() {
    this.esrv.ValidatePage(this.stage).then(value => {
      if (value) {
        this.stage+=1;
        window.scroll(0,0);
        if (this.stage === 4) {
          this.ShowHideForwardArrow();
        }
      }
    });
  }

  ImageTaken() {
    this.ShowHideForwardArrow();
    this.GoForward();
  }

  ImageDiscarded() {
    this.ShowHideForwardArrow();
    this.GoBack();
  }

  ShowHideForwardArrow() {
    this.showForwardArrow = !this.showForwardArrow;
  }
}



