import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Gender } from 'src/app/models/Gender';
import { Honorific } from 'src/app/models/Honorific';
import { Views_MediaModel } from 'src/app/models/InteractionExclusion/Views_MediaModel';
import { ISDDateMethods } from 'src/app/models/ISDDateMethods';
import { ActorService } from 'src/app/services/actor.service';
import { InteractionService } from 'src/app/services/interaction.service';
import * as moment from 'moment';

const redalert: string = '#d11722';
const yellowalert: string = '#f8b03d';
const greenalert: string = '#64b32e';
const greyalert: string = '#9d9d9c';

@Component({
  selector: 'app-interaction-card-md',
  templateUrl: './interaction-card-md.component.html',
  styleUrls: ['./interaction-card-md.component.scss']
})
export class InteractionCardMdComponent implements OnInit, AfterViewInit {

  @ViewChild('cardOuter') cardOuter: ElementRef;
  @ViewChild('cardThumb') cardThumb: ElementRef;
  @ViewChild('cardContent') cardContent: ElementRef;

  formEditable: boolean;
  @Input('formEditable') get FormEditable(): boolean {
    return this.formEditable;
  };
  @Output('formEditable') formEditableChange: EventEmitter<boolean> = new EventEmitter();
  set FormEditable(val: boolean) {
    this.formEditable = val;
    this.formEditableChange.emit(this.formEditable);
  }

  constructor(public isrv: InteractionService, public asrv: ActorService, private route: ActivatedRoute) { }

  private dateMethods: ISDDateMethods = new ISDDateMethods();
  public interactionImage: any;
  public showIconImage: boolean = false;
  public mediaId: number;

  public interactionType: string;
  public showReason: boolean;
  public reasons: string;
  public showAction: boolean;
  public actions: string;
  public isArchived: boolean;

  private readonly outlineStart: number = 80;
  private readonly outlineEnd: number = 81;

  public showUpdateContent: boolean;
  public calledFromCloseUpdateContent: boolean = false;
  public contentToDisplay: number;
  public readonly readonlyContent = 0;
  public readonly editCustomerDetails = 1;
  public readonly editCustomerDescriptors = 2;
  public blurContentValue: string = '0px';
  public honorifics: Honorific[];
  public genders: Gender[];
  public editHonorificId: number;
  public editGenderId: number;
  public editForename: string;
  public editSurname: string;
  public editAlias: string;

  public isOutline: boolean;
  public showUpdateImage: boolean;
  public blurImageValue: string = '0px';
  public showLiveView: boolean;
  public media: Views_MediaModel;
  public backgroundColor: string = '#d11722';
  public showFullNameEdit: boolean = false;

  public raceInfo: string;
  public hairstyleInfo: string;
  public hairColourInfo: string;
  public ageRangeInfo: string;
  public heightRangeInfo: string;
  public bodyTypeInfo: string;

  ngOnInit() {
    this.interactionType = this.isrv.Interaction.TagIdCollection.some(i => i === 90) ? 'Customer-Led' : 'Staff-Led';
    this.isArchived = this.isrv.Interaction.TagIdCollection.some(i => i === 4);
    this.reasons = this.isrv.GetReasonsString();
    this.actions = this.isrv.GetActionsString();

    this.asrv.GetHonorifics().then(value => {
      this.honorifics = value;
    })

    this.asrv.GetGenders().then(value => {
      this.genders = value;
    })

    if (this.reasons.length > 1) {
      this.showReason = true;
    }

    if (this.actions.length > 1) {
      this.showAction = true;
    }

    if (this.isrv.HasIconImage()) {
      this.showIconImage = true;
      this.mediaId = this.isrv.Interaction.MediaModelCollection[0].MediaId;
      if (this.mediaId >= this.outlineStart && this.mediaId <= this.outlineEnd) {
        this.isOutline = true;
      }

      if (this.isrv.Interaction.InteractedActorCompositeModelCollection[0].BiologicalActorCompositeModel.BiologicalActorTraitModelCollection) {
        if (this.isrv.Interaction.InteractedActorCompositeModelCollection[0].BiologicalActorCompositeModel.BiologicalActorTraitModelCollection.some(i => i.TraitClassKeyId === 9)) {
          this.raceInfo = this.isrv.Interaction.InteractedActorCompositeModelCollection[0].BiologicalActorCompositeModel.BiologicalActorTraitModelCollection.find(i => i.TraitClassKeyId === 9).Data;
        }

        if (this.isrv.Interaction.InteractedActorCompositeModelCollection[0].BiologicalActorCompositeModel.BiologicalActorTraitModelCollection.some(i => i.TraitClassKeyId === 2)) {
          this.hairstyleInfo = this.isrv.Interaction.InteractedActorCompositeModelCollection[0].BiologicalActorCompositeModel.BiologicalActorTraitModelCollection.find(i => i.TraitClassKeyId === 2).Data;
        }

        if (this.isrv.Interaction.InteractedActorCompositeModelCollection[0].BiologicalActorCompositeModel.BiologicalActorTraitModelCollection.some(i => i.TraitClassKeyId === 1)) {
          this.hairColourInfo = this.isrv.Interaction.InteractedActorCompositeModelCollection[0].BiologicalActorCompositeModel.BiologicalActorTraitModelCollection.find(i => i.TraitClassKeyId === 1).Data;
        }

        if (this.isrv.Interaction.InteractedActorCompositeModelCollection[0].BiologicalActorCompositeModel.BiologicalActorTraitModelCollection.some(i => i.TraitClassKeyId === 6)) {
          this.ageRangeInfo = this.isrv.Interaction.InteractedActorCompositeModelCollection[0].BiologicalActorCompositeModel.BiologicalActorTraitModelCollection.find(i => i.TraitClassKeyId === 6).Data;
        }

        if (this.isrv.Interaction.InteractedActorCompositeModelCollection[0].BiologicalActorCompositeModel.BiologicalActorTraitModelCollection.some(i => i.TraitClassKeyId === 7)) {
          this.heightRangeInfo = this.isrv.Interaction.InteractedActorCompositeModelCollection[0].BiologicalActorCompositeModel.BiologicalActorTraitModelCollection.find(i => i.TraitClassKeyId === 7).Data;
        }

        if (this.isrv.Interaction.InteractedActorCompositeModelCollection[0].BiologicalActorCompositeModel.BiologicalActorTraitModelCollection.some(i => i.TraitClassKeyId === 8)) {
          this.bodyTypeInfo = this.isrv.Interaction.InteractedActorCompositeModelCollection[0].BiologicalActorCompositeModel.BiologicalActorTraitModelCollection.find(i => i.TraitClassKeyId === 8).Data;
        }
      }
    } else {
      this.interactionImage = this.isrv.Interaction.MediaModelCollection[0].Data;

    }
    this.media = this.isrv.Interaction.MediaModelCollection[0];
    this.isrv.reviewMediaCollection = (JSON.stringify(this.isrv.Interaction.MediaModelCollection));
    this.contentToDisplay = this.readonlyContent;
  }

  ngAfterViewInit() {
    if (this.isrv.Interaction.TagIdCollection.some(i => i === this.asrv.Globals.TagValues.ArchivedInteraction)) {
      this.cardOuter.nativeElement.style.borderColor = this.asrv.Globals.BackgroundColor.Grey;
      this.cardOuter.nativeElement.style.background = this.asrv.Globals.BackgroundColor.Grey;
      this.backgroundColor = this.asrv.Globals.BackgroundColor.Grey;
    } else if (this.isrv.Interaction.TagIdCollection.some(i => i === this.asrv.Globals.TagValues.IncompleteInteraction)) {
      this.cardOuter.nativeElement.style.borderColor = this.asrv.Globals.BackgroundColor.CyanBlue;
      this.cardOuter.nativeElement.style.background = this.asrv.Globals.BackgroundColor.CyanBlue;
      this.backgroundColor = this.asrv.Globals.BackgroundColor.CyanBlue;
      // else if () { this.cardOuter.nativeElement.style.borderColor = this.asrv.Globals.BackgroundColor.MagentaPink; }
    } else if (this.isrv.Interaction.InteractionMonitoringPeriod) {
      let diffDaysStart = this.dateMethods.DateDifferenceInDays(new Date(this.isrv.Interaction.InteractionMonitoringPeriod.Start))
      let daysUntilReview = (this.isrv.Interaction.InteractionMonitoringPeriod.Duration - diffDaysStart)
      if (daysUntilReview <= -14) {
        this.cardOuter.nativeElement.style.borderColor = this.asrv.Globals.BackgroundColor.Red;
        this.cardOuter.nativeElement.style.background = this.asrv.Globals.BackgroundColor.Red;
        this.backgroundColor = this.asrv.Globals.BackgroundColor.Red;
      } else if (daysUntilReview <= 0 && daysUntilReview > -14) {
        this.cardOuter.nativeElement.style.borderColor = this.asrv.Globals.BackgroundColor.Yellow;
        this.cardOuter.nativeElement.style.background = this.asrv.Globals.BackgroundColor.Yellow;
        this.backgroundColor = this.asrv.Globals.BackgroundColor.Yellow;
      } else {
        this.cardOuter.nativeElement.style.borderColor = this.asrv.Globals.BackgroundColor.Green;
        this.cardOuter.nativeElement.style.background = this.asrv.Globals.BackgroundColor.Green;
        this.backgroundColor = this.asrv.Globals.BackgroundColor.Green;
      }
    } else {
      this.cardOuter.nativeElement.style.borderColor = this.asrv.Globals.BackgroundColor.Grey;
      this.cardOuter.nativeElement.style.background = this.asrv.Globals.BackgroundColor.Grey;
      this.backgroundColor = this.asrv.Globals.BackgroundColor.Grey;
    }
  }

  InteractionOnTimeOut(): boolean {
    return this.isrv.Interaction.TagIdCollection.some(i => i === this.asrv.Globals.TagValues.InteractionActionTimeOut);
  }

  ReinstatedSelfExcluder(): boolean {
    return this.isrv.Interaction.TagIdCollection.some(i => i === this.asrv.Globals.TagValues.ReinstatedExcluder)
  }

  GetBackgroundColor(): string {
    if (this.isrv.Interaction.TagIdCollection.some(i => i === this.asrv.Globals.TagValues.ArchivedInteraction)) {
      return this.asrv.Globals.BackgroundColor.Grey;
    } else if (this.isrv.Interaction.TagIdCollection.some(i => i === this.asrv.Globals.TagValues.IncompleteInteraction)) {
      return this.asrv.Globals.BackgroundColor.CyanBlue;
      // else if () { this.cardOuter.nativeElement.style.borderColor = this.asrv.Globals.BackgroundColor.MagentaPink; }
    } else if (this.isrv.Interaction.InteractionMonitoringPeriod) {
      let diffDaysStart = this.dateMethods.DateDifferenceInDays(new Date(this.isrv.Interaction.InteractionMonitoringPeriod.Start))
      let daysUntilReview = (this.isrv.Interaction.InteractionMonitoringPeriod.Duration - diffDaysStart)
      if (daysUntilReview <= -14) {
        return this.asrv.Globals.BackgroundColor.Red;
      } else if (daysUntilReview <= 0 && daysUntilReview > -14) {
        return this.asrv.Globals.BackgroundColor.Yellow;
      } else {
        return this.asrv.Globals.BackgroundColor.Green;
      }
    } else {
      return this.asrv.Globals.BackgroundColor.Grey;
    }
  }

  GetEndDate() {
    if (this.isrv.Interaction.InteractionMonitoringPeriod) {
      return this.dateMethods.AddDays(this.isrv.Interaction.InteractionMonitoringPeriod.Start, this.isrv.Interaction.InteractionMonitoringPeriod.Duration);
    } else {
      return '';
    }
  }

  ContentClick() {
    /*
    if (this.formEditable && (this.calledFromCloseUpdateContent === false)) {
      this.showUpdateContent = true;
      this.blurContentValue = '10px';
      this.cardContent.nativeElement.style.cursor = 'default';
    }
    this.calledFromCloseUpdateContent = false;
    */
  }

  CloseUpdateContent() {
    this.showUpdateContent = false;
    this.blurContentValue = '0px';
    this.calledFromCloseUpdateContent = true;
    this.cardContent.nativeElement.style.cursor = 'pointer';
  }

  UpdateCustomerDetails() {
    this.editHonorificId = this.isrv.Interaction.InteractedActorCompositeModel.BiologicalActorCompositeModel.HonorificId.valueOf();
    this.editGenderId = this.isrv.Interaction.InteractedActorCompositeModel.BiologicalActorCompositeModel.GenderId.valueOf();
    this.editForename = this.isrv.Interaction.InteractedActorCompositeModel.BiologicalActorCompositeModel.Forename.valueOf();
    this.editSurname = this.isrv.Interaction.InteractedActorCompositeModel.BiologicalActorCompositeModel.Surname.valueOf();
    this.editAlias = this.isrv.Interaction.InteractedActorCompositeModel.BiologicalActorCompositeModel.Alias.valueOf();
    this.contentToDisplay = this.editCustomerDetails;
  }

  DiscardChanges() {
    this.showUpdateContent = false;
    this.blurContentValue = '0px';
    this.contentToDisplay = this.readonlyContent;
    this.cardContent.nativeElement.style.cursor = 'pointer';
  }

  SaveInteraction() {
    this.isrv.Interaction.InteractedActorCompositeModel.BiologicalActorCompositeModel.HonorificId = this.editHonorificId;
    this.isrv.Interaction.InteractedActorCompositeModel.BiologicalActorCompositeModel.GenderId = this.editGenderId;
    this.isrv.Interaction.InteractedActorCompositeModel.BiologicalActorCompositeModel.Forename = this.editForename;
    this.isrv.Interaction.InteractedActorCompositeModel.BiologicalActorCompositeModel.Surname = this.editSurname;
    this.isrv.Interaction.InteractedActorCompositeModel.BiologicalActorCompositeModel.Alias = this.editAlias;
    this.isrv.Save(false).then(value => { });
    this.showUpdateContent = false;
    this.blurContentValue = '0px';
    this.contentToDisplay = this.readonlyContent;
  }

  ImageClick() {
    /*
    if (this.formEditable) {
      this.showUpdateImage = true;
      this.blurImageValue = '10px';
      this.cardThumb.nativeElement.style.cursor = 'default';
    }
    */
  }

  CloseUpdateImage() {
    this.showUpdateImage = false;
    this.blurImageValue = '0px';
    this.cardThumb.nativeElement.style.cursor = 'pointer';
  }

  LiveCapture() {
    this.CloseUpdateImage();
    this.showLiveView = true;
  }

  ImageTaken() {
    if (this.showIconImage) {
      this.showIconImage = false;
    }
    this.interactionImage = this.media.Data;
    // console.log(this.interactionImage);
    this.isrv.Save(false).then(value => { });
    this.showLiveView = false;
  }

  NoImageTaken() {
    this.showLiveView = false;
  }
}
