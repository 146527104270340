import { Component, OnInit } from '@angular/core';
import { AdvancedSearchService } from 'src/app/services/advanced-search.service';

@Component({
  selector: 'app-advanced-search-search-results',
  templateUrl: './advanced-search-search-results.component.html',
  styleUrls: ['./advanced-search-search-results.component.scss']
})
export class AdvancedSearchSearchResultsComponent implements OnInit {

  constructor(public assrv: AdvancedSearchService) { }

  ngOnInit() {
  }

}
