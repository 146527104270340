import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CustomerInteractionCancelReview } from 'src/app/models/CustomerInteractionCancelReview';
import { CustomerInteraction } from 'src/app/models/CustomerInteraction';

@Component({
  selector: 'app-cancel-review-form',
  templateUrl: './cancel-review.component.html',
  styleUrls: ['./cancel-review.component.scss']
})
export class CancelReviewFormComponent implements OnInit {
  review;
  constructor() { }

  public customerInteractionReview: CustomerInteractionCancelReview = new CustomerInteractionCancelReview();

  interaction: CustomerInteraction;
  @Input('interaction') get Interaction(): CustomerInteraction {
    return this.interaction;
  };
  @Output('interaction') interactionChange: EventEmitter<CustomerInteraction> = new EventEmitter();
  set Interaction(val: CustomerInteraction) {
    this.interaction = val;
    this.interactionChange.emit(this.interaction);
  }
  ngOnInit() {
  }

}
