import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { product } from 'src/app/models/data/product';
import { Observable } from 'rxjs';
import { ActorService } from 'src/app/services/actor.service';
import { DataService } from 'src/app/services/data.service';
import { ApiService } from 'src/app/services/api.service';

declare var require: any;
const jsPDF = require('jspdf');

@Component({
  selector: 'app-feature-collection',
  templateUrl: './feature-collection.component.html',
  styleUrls: ['./feature-collection.component.scss']
})
export class FeatureCollectionComponent implements OnInit {
  // <Constructors>

  crimeAlertActive = false;
  ageVericationActive = false;
  toolkitActive = false;
  exclusionsActive = true; // SHould always be true no matter what user level
  interactionsActive = true; // SHould always be true no matter what user level
  isLoading = true;
  constructor(
    private router: Router,
    public asrv: ActorService,
    public dsrv: DataService,
    private _api: ApiService
  ) {
    
  }
  // </Constructors>

  // <Fields>
  public productCollection: Observable<product>
  // </Fields>

  @Output() public onSelection: EventEmitter<any> = new EventEmitter<any>();

  ngOnInit() {
    this.asrv.GetLoggedUserDetails().then(value => {
      const _userID = this.dsrv.GetMyUserID();
      this.getSubscriptions([_userID]);
    });
  }

  getSubscriptions(userIDs: Array<number>) {
    this._api.getSubscriptionModel(userIDs, this.dsrv.Credentials()).subscribe(sub => {
      // console.log(sub);
      if (sub[0].SubscriptionCompositeModelCollection.length > 0) {
        const _activeSubs = sub[0].SubscriptionCompositeModelCollection[0].ServiceCatalogueCompositeModelCollection[0].ServiceCompositeModelCollection
        _activeSubs.forEach((item, index) => {
          if (item.Name === 'Age Verification') {
            this.ageVericationActive = true;
          }

          if (item.Name === 'Crime Alert') {
            this.crimeAlertActive = true;
          }

          if (item.Name === 'Exclusions') {
            this.exclusionsActive = true;
          }

          if (item.Name === 'Customer Interaction') {
            this.interactionsActive = true;
          }

          if (item.Name === 'Toolkit') {
            this.toolkitActive = true;
          }

          if (index === _activeSubs.length - 1) {
            this.isLoading = false;
          }
        });
      } else {
        this.isLoading = false;
        this.interactionsActive = true;
        this.exclusionsActive = true;
      }
    },
      error => {
        this.isLoading = false;
        this.interactionsActive = true;
        this.exclusionsActive = true;
      });
  }

  public LinkToExclusions() {
    this.onSelection.emit('exclusion');
    this.router.navigate(['/user/exclusion']);
  }

  public LinkToCustomerInteraction() {
    this.onSelection.emit('exclusion');
    this.router.navigate(['/user/customer-interaction'])
  }

  public OpenCompliance(val: boolean) {
    if (val) {
      window.open('https://bacta.org.uk/iump-login/')
    } else {
      window.open('https://training.bactaportal.org.uk/compliance/')
    }
  }

  public OpenTraining() {
    window.open('https://training.bactaportal.org.uk/')
  }

  public OpenUserGuide() {
    window.open('https://bacta.org.uk/wp-content/uploads/2021/07/BACTA-Portal-User-Guide-v1.7-May-2021.pdf');
  }

  public LinkToMaintenance(sender: number) {
    this.onSelection.emit(2);
    if (sender == 0) {
      this.router.navigate(['user/maintenance/organisation']);
    } else if (sender == 1) {
      this.router.navigate(['user/maintenance/site']);
    } else if (sender == 2) {
      this.router.navigate(['user/maintenance/user']);
    } else {
      this.router.navigate(['user/maintenance/data']);
    }
  }

  public openToolkit() {
    this.router.navigate(['user/toolkit']);
  }

  public openAgeVerify() {
    this.router.navigate(['user/age-verify']);
  }

  public openCrimeAlert() {
    this.router.navigate(['user/crime-alert']);
  }
}
