<div class="excluder-box container global-seperator" [style.background]="GetExcluderColour(excluder)" (click)="ViewExcluder(excluder)">
    <div class="row excluder-image">
        <div *ngIf="showImageSpinner" class="spinner-border" role="status">
            <span class="sr-only"></span>
        </div>
        <img class="excluder-image-inner" *ngIf="!showImageSpinner" [src]="excluder.Image" />
    </div>
    <div class="row row-height-adjustment card-text" >
        <div class="col-xs-8 card-inner-text-container" style="height:100%;width:66%;">
            <div class="row row-height-adjustment-content" style="width:100%;margin:auto;padding-top:10px;overflow:hidden;white-space: nowrap;">
                <span class="excluder-text-header"><b>{{excluder.Forename}}<br>{{excluder.Surname}}</b></span>
            </div>
            <div class="row" style="width:100%;margin:auto;padding-top:5px;">
                <span class="excluder-text-body"><b>{{excluder.Start | date: 'dd/MM/yyyy'}}<br>{{GetEndDate(excluder) | date: 'dd/MM/yyyy'}}</b></span>
            </div>
        </div>
        
        <div class="col-xs-4 card-inner-images" style="min-height:100% !important;width:34%;">
            <!--card-img-container global-seperator-->
            <div class="row card-inner-image-row">
                <div class="flex-no-padding">
                    <img *ngIf="ExcluderIsCoolingOff(excluder);" class="card-img" src=".\assets\icons\Bacta Assets\24 icon.svg">
                </div>
            </div>
            <div class="row card-inner-image-row">
                <div class="flex-no-padding">
                    <img *ngIf="ExcluderRecentlyBreached(excluder);" class="card-img" src=".\assets\icons\Bacta Assets\Breach icon.svg">
                </div>
            </div>
            <div class="row card-inner-image-row">
                <div *ngIf="(excluder.Extensions);" class="flex-no-padding">
                    <div class="card-img-text card-img-radius">+{{GetExtensionInMonths(excluder)}}</div>
                </div>
            </div>
        </div>
    </div>
</div>
