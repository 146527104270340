import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActorService } from 'src/app/services/actor.service';
import { ExcluderViewService } from 'src/app/services/excluder-view.service';
import { ExcluderService } from 'src/app/services/excluder.service';

@Component({
  selector: 'app-more-info',
  templateUrl: './more-info.component.html',
  styleUrls: ['./more-info.component.scss']
})
export class MoreInfoComponent implements OnInit {

  public exclusionSites: string[] = [];
  public showSpinner: boolean;

  constructor(
    public esrv: ExcluderService, 
    public evsrv: ExcluderViewService,
    public asrv: ActorService
  ) { }

  ngOnInit() {
    this.showSpinner = true;
    this.asrv.GetSiteNameCollection(this.esrv.CurrentExclusion.SiteIdCollection).then(value => {
      value.forEach(site => {
        this.exclusionSites.push(site.Name);
      })
      this.showSpinner = false
    })
  }

  @Output() CloseForm = new EventEmitter<string>();

}
