import { Actors_Names } from './Name';

export class UserSites {
    ActorId: number;
    NameId: number;
    Name: string;
    Site: Site;
    Site_ActorId: number;
    WorksHere: Boolean = false;
    PrimarySite: Boolean = false;
    Headquarters: Boolean = false;
    TagIdCollection: number[] = [];
}

// Only active in the receiving model.
class Site {
    Actor: Actor;
    Site_ActorId: number;
    Actors_Organisations: Actors_Organisations[];
}

class Actor {
    ActorId: number;
    NameId: number;
    Actors_Names: Actors_Names[] = [];
}

class Actors_Organisations {
    ActorId: number;
    Organisations_ActorId: number;
    Edited: Date;
    Edited_ActorId: Date;
}