import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ActorService } from 'src/app/services/actor.service';
import { InteractionGalleryService } from 'src/app/services/interaction-gallery.service';

@Component({
  selector: 'app-interaction-gallery',
  templateUrl: './interaction-gallery.component.html',
  styleUrls: ['./interaction-gallery.component.scss']
})
export class InteractionGalleryComponent implements OnInit {

  @ViewChild('btnBarred') btnBarred: ElementRef;
  @ViewChild('btnArchive') btnArchive: ElementRef;


  public showSpinner: boolean;
  public sort: number = 0;
  public sortOptions = [
    { Id: 0, Value: 'Nearest Review Date' },
    { Id: 1, Value: 'Furthest Review Date' },
    { Id: 2, Value: 'Name (A to Z)' },
    { Id: 3, Value: 'Name (Z to A)' }
  ]
  public filter: number = 0;
  public filterOptions = [
    { Id: 0, Value: 'Active' },
    { Id: 2, Value: 'Archive' },
    { Id: 3, Value: 'Time Out' },
    { Id: 4, Value: 'Incomplete' },
    { Id: 5, Value: 'Reinstated Self-Excluder' },
    { Id: 6, Value: 'Review Due' }
  ]

  // { Id: 1, Value: 'Barred' }

  public fromReportBuilder: boolean;
  @Input('fromReportBuilder') get FromReportBuilder(): boolean {
    return this.fromReportBuilder;
  };
  @Output('fromReportBuilder') fromReportBuilderChange: EventEmitter<boolean> = new EventEmitter();
  set FromReportBuilder(val: boolean) {
    this.fromReportBuilder = val;
    this.fromReportBuilderChange.emit(this.fromReportBuilder);
  }

  constructor(public gsrv: InteractionGalleryService, private router: Router, private titleService: Title, public asrv: ActorService, private route: ActivatedRoute) { }

  ngOnInit() {
    this.titleService.setTitle('Interaction Gallery');

    this.gsrv.Initialize().then(value => {
      this.showSpinner = false;
    })

    this.filterOptions.sort((a, b) => {
      return a.Value.localeCompare(b.Value);
    })
  }

  CreateNewCustomerInteraction() {
    this.router.navigate(['/user/customer-interaction/new']);
  }

  FilterOrganisations() {
    this.gsrv.ActiveSiteFilterId = 0;
    this.gsrv.PopulateSiteList().then(value => {
        this.gsrv.GetInteractionCards();
    });
  }

  
}
