import { Component, OnInit, OnDestroy, AfterViewInit, ElementRef, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd } from '@angular/router';
import { ActorService } from 'src/app/services/actor.service';
import { OrganisationService } from 'src/app/services/organisation.service';
import { Honorific } from 'src/app/models/Honorific';
import { Gender } from 'src/app/models/Gender';
import { ExclusionDurationOption } from 'src/app/models/ExclusionDurationOption';
import { ExclusionReason } from 'src/app/models/ExclusionReason';
import { Site } from 'src/app/models/Site';
import { Organisation } from 'src/app/models/Organisation';
import { IdentificationDocument } from 'src/app/models/IdentificationDocumentKey';
import { SiteCollection } from 'src/app/models/SiteCollection';
import { Subscription } from 'rxjs';
import { UserSites } from 'src/app/models/UserSites';
import { AdminService } from 'src/app/services/admin.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent  implements OnInit {  
  constructor(public asrv: ActorService, public adminsrv: AdminService, private titleService: Title) { }

  ngOnInit() {
    this.titleService.setTitle('Admin');
  }
}
