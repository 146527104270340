import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ISDDateMethods } from 'src/app/models/ISDDateMethods';
import { CustomerInteractionBrief } from 'src/app/models/CustomerInteractionBrief';
import { InteractionService } from 'src/app/services/interaction.service';
import { Router } from '@angular/router';
import { ActorService } from 'src/app/services/actor.service';

@Component({
  selector: 'app-interaction-card',
  templateUrl: './interaction-card.component.html',
  styleUrls: ['./interaction-card.component.scss']
})
export class InteractionCardComponent implements OnInit {
  public showImageSpinner: boolean;
  public interactionImage: any;
  public showIconImage: boolean = false;
  public mediaId: number;
  public archived: boolean;
  public incomplete: boolean;
  public reinstatedSelfExcluder: boolean;

  private dateMethods: ISDDateMethods = new ISDDateMethods();
  public interaction: CustomerInteractionBrief;
  @Input('interaction') get Interaction(): CustomerInteractionBrief {
    return this.interaction;
  };
  @Output('interaction') interactionChange: EventEmitter<CustomerInteractionBrief> = new EventEmitter();
  set Interaction(val: CustomerInteractionBrief) {
    this.interaction = val;
    this.interactionChange.emit(this.interaction);
  }

  constructor(public asrv: ActorService, public isrv: InteractionService, private router: Router) { }

  ngOnInit() {
    this.showImageSpinner = true;
    // console.log(this.interaction);
    this.archived = this.interaction.TagIdCollection.some(i => i === 4);
    this.incomplete = this.interaction.TagIdCollection.some(i => i === 5);
    // this.reinstatedSelfExcluder = 
    this.isrv.GetMediaForInteraction(this.Interaction.InteractionId).then(value => {
      if (value.MediaClassKeyId === 4) {
        if (this.isrv.HasIconImage(value)) {
          this.showIconImage = true;
          this.mediaId = value.MediaId;
        } else {
          this.interactionImage = value.Data;
        }
      }

      this.showImageSpinner = false;
    });
  }

  GetInteractionColour() : string {    
    let diffDaysStart = this.dateMethods.DateDifferenceInDays(this.interaction.Start);
    let daysUntilReview = (this.interaction.PeriodDays - diffDaysStart);

    if(this.archived) {
      return this.asrv.Globals.BackgroundColor.Grey;
    } else if (this.incomplete) {
      return this.asrv.Globals.BackgroundColor.CyanBlue;
    } else if (this.reinstatedSelfExcluder) {
      return this.asrv.Globals.BackgroundColor.MagentaPink;
    } else if(daysUntilReview <= -14)
      return this.asrv.Globals.BackgroundColor.Red;
    else if(daysUntilReview <= 0 && daysUntilReview > -14)
      return this.asrv.Globals.BackgroundColor.Yellow;
    else return this.asrv.Globals.BackgroundColor.Green;
  }

  GetEndDate(): Date {
    return this.dateMethods.AddDays(this.interaction.Start, this.interaction.PeriodDays);
  }

  InteractionOnTimeOut(): boolean {
    return this.interaction.TagIdCollection.some(i => i === this.asrv.Globals.TagValues.InteractionActionTimeOut);
  }

  ReinstatedSelfExcluder(): boolean {
    return this.interaction.TagIdCollection.some(i => i === this.asrv.Globals.TagValues.ReinstatedExcluder)
  }

  ViewInteraction(interaction: CustomerInteractionBrief) {
    this.isrv.Load(interaction.InteractionId).then(value => {
      this.router.navigate(['/user/customer-interaction/view', this.Interaction.InteractionId]);
    })
  }
}
