import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Views_MediaModel } from 'src/app/models/InteractionExclusion/Views_MediaModel';
import { InteractionService } from 'src/app/services/interaction.service';

@Component({
  selector: 'app-interaction-identification',
  templateUrl: './interaction-identification.component.html',
  styleUrls: ['./interaction-identification.component.scss']
})
export class InteractionIdentificationComponent implements OnInit {

  constructor(public isrv: InteractionService) { }

  photoExists: boolean = false;
  showIcons: boolean = false;
  showImage: boolean;
  allowLiveCapture: boolean;
  allowOutlineSelector: boolean;

  public showApp: number = 0;

  ngOnInit() {
    this.allowLiveCapture = ((this.isrv.Interaction.InteractedActorCompositeModel.BiologicalActorCompositeModel.Forename && this.isrv.Interaction.InteractedActorCompositeModel.BiologicalActorCompositeModel.Surname) || (this.isrv.Interaction.InteractedActorCompositeModel.BiologicalActorCompositeModel.Alias))? true: false;

    if (this.isrv.Interaction.MediaModelCollection.some(i => i.MediaClassKeyId === 4)) {
      let media = this.isrv.Interaction.MediaModelCollection.find(i => i.MediaClassKeyId === 4);
      if (media.MediaId > 0 && media.MediaId < 100) {
        this.showImage = false;
      } else {
        this.showImage = true;
      }
      this.photoExists = true;
    }
  }

  ClosePhotoApp() {
    
  }

  ShowMainApp() {
    this.showApp = 0;
    this.CallShowHideForwardArrow();
  }

  LiveCapture() {
    this.showApp = 1;
    this.CallShowHideForwardArrow();
  }

  ImageTaken() {
    this.ShowMainApp();
    this.showImage = false;
    this.photoExists = true;
    this.ImageTakenWithApp.next();
  }

  NoImageTaken() {
    this.ShowMainApp();
  }

  SaveImage() {
    this.isrv.SaveImage();
    if (this.isrv.HasFruitImage() && !(this.isrv.Interaction.InteractedActorCompositeModel.BiologicalActorCompositeModel.Alias)) {
      this.isrv.Interaction.InteractedActorCompositeModel.BiologicalActorCompositeModel.Alias = this.isrv.GetIconNameForInteractionImage();
    }
    this.showImage = false;
    this.photoExists = true;
    this.ShowMainApp();
  }

  PickIcon() {
    this.showIcons = true;
    this.showApp =2;
    this.CallShowHideForwardArrow();
  }

  PickOutline() {
    this.showIcons = false;
    this.showApp =2;
    this.CallShowHideForwardArrow();
  }

  CallShowHideForwardArrow() {
    this.ShowHideForwardArrow.next();
  }

  @Output() ShowHideForwardArrow = new EventEmitter<string>();
  @Output() ImageTakenWithApp = new EventEmitter<string>();
}
