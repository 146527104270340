<div class="container global-seperator">
  <div class="panel panel-default">
    <h4>Edit User</h4>
  </div>
  <div class="panel panel-default" style="margin-top: 15px;">
    <div class="panel-body">
      <div class="row">
        <h5>User Details</h5>
      </div>
      <div class="row">
        <div *ngIf="adminsrv.IsAdmin" class="col-md-6">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">Organisation</span>
            </div>
            <select class="form-control sort-by-control drop-down-purple" name="Filter"
              [(ngModel)]="adminsrv.ActiveOrganisationFilterId" (ngModelChange)="GetUserSites();">
              <option [ngValue]="organisation.OrganisationId" *ngFor="let organisation of adminsrv.OrganisationFilter">
                {{organisation.OrganisationName}}</option>
            </select>
          </div>
        </div>
        <div class="col-md-6">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">Mobile</span>
            </div>
            <input type="tel" class="form-control" name="Mobile" [(ngModel)]="adminsrv.employeeDetails.Mobile">
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="input-group" id="inputTitle">
            <div class="input-group-prepend">
              <span class="input-group-text">Title</span>
            </div>
            <select class="form-control drop-down-purple" name="title"
              [(ngModel)]="adminsrv.employeeDetails.HonorificId">
              <option></option>
              <option *ngFor="let hono of honorifics" [ngValue]="hono.HonorificId">{{hono.Name}}</option>
            </select>
          </div>
        </div>
        <div class="col-md-6">
          <div class="input-group" id="inputGender">
            <div class="input-group-prepend">
              <span class="input-group-text">Gender</span>
            </div>
            <select class="form-control drop-down-purple" name="gender" [(ngModel)]="adminsrv.employeeDetails.GenderId">
              <option></option>
              <option *ngFor="let gender of genders" [ngValue]="gender.GenderId">{{gender.Name}}</option>
            </select>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="input-group" id="inputForename">
            <div class="input-group-prepend">
              <span class="input-group-text">First Name</span>
            </div>
            <input type="text" class="form-control" [(ngModel)]="adminsrv.employeeDetails.Forename">
          </div>
        </div>
        <div class="col-md-6">
          <div class="input-group" id="inputSurname">
            <div class="input-group-prepend">
              <span class="input-group-text">Surname</span>
            </div>
            <input type="text" class="form-control" [(ngModel)]="adminsrv.employeeDetails.Surname">
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="input-group" id="inputBirthDate">
            <div class="input-group-prepend">
              <span class="input-group-text">Birth Date</span>
            </div>
            <input class="form-control d-none d-lg-block" matInput [matDatepicker]="cddp1"
              (dateInput)="addEvent('input', $event)" (dateChange)="addEvent('change', $event)"
              [(ngModel)]="adminsrv.employeeDetails.BirthDate">
            <div class="input-group-append time-selector d-none d-lg-block">
              <mat-datepicker-toggle class="form-control-date-toggle d-none d-lg-block" matSuffix [for]="cddp1">
              </mat-datepicker-toggle>
            </div>
            <mat-datepicker class="d-none d-lg-block" touchUi #cddp1></mat-datepicker>
            <input type="date" class="form-control date-picker-padding d-lg-none" name="birthdate"
              [(ngModel)]="adminsrv.employeeDetails.BirthDate">
          </div>
        </div>
        <div class="col-md-6">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">Email</span>
            </div>
            <input type="email" class="form-control" name="Email" [(ngModel)]="adminsrv.employeeDetails.Email">
          </div>
        </div>

      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="input-group wordstrength">
            <div class="input-group-prepend">
              <span class="input-group-text">Memorable Word</span>
            </div>
            <input maxlength="12" type="text" class="form-control" name="MemWord" (ngModelChange)="checkWord($event)"
              [(ngModel)]="adminsrv.employeeDetails.MemWord" #memword="ngModel"
              pattern="^((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]))(.{8,12})$">
            <div class="memword-error" *ngIf="memword.errors?.pattern">
              Memorable word invalid. Please check all requirements
            </div>
            <div class="strength-meter--wrapper">
              <div class="strength-meter--item {{strength > 0 ? 'filled' : ''}}"></div>
              <div class="strength-meter--item {{strength > 1 ? 'filled' : ''}}"></div>
              <div class="strength-meter--item {{strength > 2 ? 'filled' : ''}}"></div>
              <div class="strength-meter--item {{strength > 3 ? 'filled' : ''}}"></div>
            </div>
            
          </div>
          <small class="memword-info">Memorable word needs to contain at least: 
            <ul>
              <li>1 lowercase letter</li>
              <li>1 uppercase letter</li>
              <li>1 digit</li>
              <li>8 - 12 characters</li>
            </ul>
          </small>
        </div>

      </div>
    </div>
    <div *ngIf="!adminsrv.organisationCreateMode && adminsrv.UserRoleId <= 5" class="panel-body" style="margin-top: 15px;">
      <div class="row">
        <h5>User Sites</h5>
      </div>
      <div *ngIf="!adminsrv.organisationCreateMode" class="row">
        <div class="col-md-6">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">Site Monitor</span>
            </div>
            <!--roleBriefs-->
            <select class="form-control drop-down-purple" name="role" [(ngModel)]="receiveNotifications">
              <option [ngValue]="true">Yes</option>
              <option [ngValue]="false">No</option>
            </select>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group" style="width: 100%; text-align:center; margin-top:10px;">
            <button *ngIf="NotAllSitesSelected" type="button" class="btn btn-success" (click)="SelectAllSites();">Select
              All Sites</button>
            <button *ngIf="!NotAllSitesSelected" type="button" class="btn btn-success"
              (click)="RemoveAllSites();">Remove All Sites</button>
          </div>
        </div>
        <div *ngIf="SearchEnabled" class="col-md-6">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">Search</span>
            </div>
            <input type="text" class="form-control" [(ngModel)]="SiteSearchPhrase"
              (ngModelChange)="SearchSites(SiteSearchPhrase);">
          </div>
        </div>
      </div>
      <div class="row">
        <div class="row" style="min-height:200px;">
          <div class="col-md-12">
            <div class="bacta-table-outline">
              <div class="row" style="height:40px;">
                <span class="col-sm-6 bacta-table-header-text">Site Name</span>
                <span class="col-sm-6 bacta-table-header-text"
                  style="text-align: center; padding-left:0px !important;padding-right: 10px !important;">Scope</span>
              </div>
              <div class="bacta-table-row-format">
                <div class="row" *ngFor="let siteBrief of sitesToDisplay" style="margin-top:5px;">
                  <span class="col-sm-6 bacta-table-row-content">
                    {{siteBrief.Name}}
                  </span>
                  <span class="col-sm-6 bacta-table-row-content"
                    style="text-align: center; padding-right: 0px !important;">
                    <input type="checkbox" [(ngModel)]="siteBrief.WorksHere" (ngModelChange)="CheckSite(siteBrief)">
                  </span>
                </div>
                <div *ngIf="(showSpinner)" class="row bacta-table-row-content">
                  <span class="spinner-border text-danger" role="status" style="margin-left:15px;"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">Primary Site</span>
            </div>
            <select class="form-control sort-by-control drop-down-purple" name="Filter" [(ngModel)]="primarySiteId">
              <option></option>
              <option *ngFor="let siteBrief of sitesInOrganisation" [ngValue]="siteBrief.ActorId">
                {{siteBrief.Name}}</option>
            </select>
          </div>
        </div>
      </div>
    </div>

    <!--
        <div class="panel-body" style="margin-top: 15px;">
            <div class="row">
                <h5>Tags</h5>
            </div>
            <div class="row">
                <app-tags id="appTags" [(allowNewTags)]="allowNewTags"></app-tags>
            </div>
        </div>
        -->
    <div *ngIf="!adminsrv.organisationCreateMode || this.showCredentials" class="panel-body" style="margin-top: 15px;">
      <div class="row">
        <h5>Bacta Portal Details</h5>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">Access</span>
            </div>
            <!--roleBriefs-->
            <select class="form-control drop-down-purple" name="role"
              [(ngModel)]="adminsrv.employeeDetails.ActorRoleId" [disabled]="adminsrv.UserRoleId > 5">
              <option></option>
              <option *ngFor="let roleBrief of asrv.rolesAvailable" [ngValue]="roleBrief.ActorRoleId">
                {{roleBrief.Name}}</option>
            </select>
          </div>
        </div>
        <div *ngIf="!adminsrv.creatingNewRecord" class="col-md-6">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">Login</span>
            </div>
            <input type="email" class="form-control" name="Login" autocomplete="off"
              [(ngModel)]="adminsrv.userCredententials.Login">
          </div>
        </div>
      </div>
      <div *ngIf="!adminsrv.creatingNewRecord" class="row">
        <div class="col-md-6">
          <div class="input-group" id="inputPassword">
            <div class="input-group-prepend">
              <span class="input-group-text">Password</span>
            </div>
            <input type="password" class="form-control" name="Password" autocomplete="new-password"
              [(ngModel)]="adminsrv.userCredententials.Hash">
          </div>
        </div>
        <div class="col-md-6">
          <div class="input-group" id="inputPasswordConfirm">
            <div class="input-group-prepend">
              <span class="input-group-text">Confirm</span>
            </div>
            <input type="password" class="form-control" name="Password" autocomplete="new-password"
              [(ngModel)]="adminsrv.passwordConfirm">
          </div>
        </div>
        <div *ngIf="!adminsrv.organisationCreateMode" class="col-md-12 custom-button-container"
          style="align-items:flex-end; padding-right:15px;">
          <button type="button" class="btn custom-button" style="background-color: #223464;"
            (click)="SetCredentials()"><span class="button-icon">+</span><span class="button-text">Set
              Credentials</span></button>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="!adminsrv.organisationCreateMode" class="form-footer" style="margin-top:20px">
  <div class="navigation" (click)="GoBack()">
    <span><input type="image" src=".\assets\icons\Bacta Assets\Back button blue.svg" style="width:100px" /></span>
  </div>
  <div class="navigation-warning" style="vertical-align:middle; height: 100%;">
    <div class="col-md-12">
      <div *ngIf="!adminsrv.creatingNewRecord" class="form-group"
        style="width: 100%; text-align:center; margin-top:10px;">
        <button *ngIf="userActive" type="button" class="btn btn-danger" (click)="SuspendUser();">Suspend
          User</button>
        <button *ngIf="!userActive" type="button" class="btn btn-success" (click)="ReinstateUser();">Reinstate
          User</button>
      </div>
    </div>
  </div>
  <div class="navigation" (click)="GoForward()">
    <span><input type="image" src=".\assets\icons\Bacta Assets\Next button blue.svg" style="width:100px" /></span>
  </div>
</div>