import { Component, OnInit, OnChanges, ElementRef, ViewChild } from '@angular/core';
import { ActorService } from 'src/app/services/actor.service';
import { DataService } from 'src/app/services/data.service';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { ISDDateMethods } from 'src/app/models/ISDDateMethods';
import { ErrorhanderService } from 'src/app/services/errorhander.service';
import { AdvancedSearchService } from 'src/app/services/advanced-search.service';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';

const exclusion = 'exclusion';

@Component({
  selector: 'app-user-dashboard',
  templateUrl: './user-dashboard.component.html',
  styleUrls: ['./user-dashboard.component.scss']
})

export class UserDashboardComponent implements OnInit, OnChanges {

  @ViewChild('dropDownToggle') DropDownToggle: ElementRef;
  @ViewChild('exclusionDropDownToggle') ExclusionDropDownToggle: ElementRef;
  @ViewChild('reportingDropDownToggle') ReportingDropDownToggle: ElementRef;
  @ViewChild('adminDropDownToggle') AdminDropDownToggle: ElementRef;
  @ViewChild('messagingDropDownToggle') MessagingDropDownToggle: ElementRef;
  @ViewChild('advancedSearchToggle') AdvancedSearchToggle: ElementRef;
  @ViewChild('adminToolsDropDownToggle') AdminToolsDropDownToggle: ElementRef;

  @ViewChild('status') status: ElementRef;
  public showStatus: boolean = false;

  constructor(
    public dsrv: DataService,
    public asrv: ActorService,
    private router: Router,
    public errsrv: ErrorhanderService,
    public assrv: AdvancedSearchService
  ) { }

  // Always display on initialise
  public displayFeatures: boolean = false;
  public displayAddNewExcluder: boolean = false;
  public mainWindowHeight: string;
  public unreadMessages: boolean = false;
  public unreadMessageCount: string;
  public readonly FeatureNotReady: boolean = false;

  public logOutSubscription: Subscription;

  public dropDownIsShowing: boolean;
  public exclusionDropDownIsShowing: boolean;
  public reportingDropDownIsShowing: boolean;
  public adminDropDownIsShowing: boolean;
  public messagingDropDownIsShowing: boolean;
  public advancedSearchIsShowing: boolean;
  public adminToolsDropDownIsShowing: boolean;

  private nextCheckForMessage: Date;
  private nextCheckTime: number = 30000;
  private dateMethods: ISDDateMethods = new ISDDateMethods();
  public _dataready: boolean = false;

  public NavigatedId: number;
  dp1;

  events = [];
  addEvent(type: string, event: MatDatepickerInputEvent<Date>) {
    this.events.push(`${type}: ${event.value}`);
  }

  ngOnInit() {
    this.GetUnreadMessageCount();

    let userSettingsLoaded: boolean = false;
    let actorCompositeModelLoaded: boolean = false;

    // Untangle this
    this.asrv.GetUserSettings().then(value => {

      userSettingsLoaded = true;
      if (userSettingsLoaded && actorCompositeModelLoaded) { this._dataready = true; };

      this.asrv.GetAgreementDocumentation().then(value => {
        if (!value) {
          this.asrv.IsLoggedUserOrganisationAdmin().then(value => {
            if (value) {
              this.errsrv.ShowValidationOops('No Organisation Site Found', 'Please set a site as the organisation headquarters before proceeding. Your organisation will be unable to create any interactions until this is resolved.');
              this.router.navigate(['user/admin/organisation-panel']);
            } else {
              this.errsrv.ShowValidationOops('No Organisation Site Found', 'No organisation headquarters found! Please contact the organisation admin to resolve this. No interactions can be created by this organisation until this is resolved.')
            }
          })
        }
      })
      this.asrv.GetActorCompositeModel().then(value => {
        actorCompositeModelLoaded = true;
        if (userSettingsLoaded && actorCompositeModelLoaded) { this._dataready = true; };
      });
    });
    
    this.router.events.subscribe(event => {
      // Check Message Ping
      var currentTime = new Date();
      if (currentTime > this.nextCheckForMessage) {
        this.nextCheckForMessage = new Date(this.dateMethods.ConvertDateToInteger(new Date()) + this.nextCheckTime);
        this.GetUnreadMessageCount();
      };

      if(event instanceof NavigationEnd) {
        // Display Footer Buttons Based on the URL
        if (event.url.includes('user/features')) {
          this.displayFeatures = false;
          this.displayAddNewExcluder = false;
        };

        if (!event.url.includes('user/exclusion/new')) {
          this.ClearNewExclusionCache();
        };
  
        if (event.url.includes('customer-interaction')) {
          document.body.style.backgroundColor = this.asrv.Globals.BackgroundColor.LightBlue;
          document.body.style.color = this.asrv.Globals.BackgroundColor.DarkBlue;
        } else {
          document.body.style.backgroundColor = this.asrv.Globals.BackgroundColor.Beige;
          document.body.style.color = this.asrv.Globals.BackgroundColor.DarkPurple;
        };
      };
    });
    
    if (window.innerHeight > 820) {
      this.mainWindowHeight = '88vh';
    } else {
      this.mainWindowHeight = '100%';
    };
  }

  GetUnreadMessageCount() {
    this.asrv.GetUnreadMessageCount().then(value => {
      this.unreadMessages = value.HasUnreadMesssages;
      if (value.MessageCount > 9) {
        this.unreadMessageCount = '9+'
      } else {
        this.unreadMessageCount = value.MessageCount.toString();
      }
      this.nextCheckForMessage = new Date(this.dateMethods.ConvertDateToInteger(new Date()) + this.nextCheckTime);
    });
  }

  ClearNewExclusionCache() {
    this.dsrv.ClearExclusionProgress();
  }

  ngOnChanges() {
  }

  ToggleFeatureMenu() {
    // If Displaying Feature Collection, hide any buttons
    this.displayFeatures = !this.displayFeatures;
  }

  MenuSelectionMade(event: any){
    if (event == exclusion) {
      this.HideOverlayMenu();
      this.displayAddNewExcluder = true;
    }
  }

  onActivate(componentRef) {
    this.displayAddNewExcluder = true;
  }

  HideDropDowns() {
    if (this.dropDownIsShowing) { this.DropDownToggle.nativeElement.click(); }
    if (this.exclusionDropDownIsShowing) { this.ExclusionDropDownToggle.nativeElement.click(); }
    if (this.reportingDropDownIsShowing) { this.ReportingDropDownToggle.nativeElement.click(); }
    if (this.adminDropDownIsShowing) { this.AdminDropDownToggle.nativeElement.click(); }
    if (this.messagingDropDownIsShowing) { this.MessagingDropDownToggle.nativeElement.click(); }
    if (this.advancedSearchIsShowing) { this.AdvancedSearchToggle.nativeElement.click(); };
    if (this.adminToolsDropDownIsShowing) { this.AdminToolsDropDownToggle.nativeElement.click(); }
    this.dropDownIsShowing = false;
    this.exclusionDropDownIsShowing = false;
    this.reportingDropDownIsShowing = false;
    this.adminDropDownIsShowing = false;
    this.messagingDropDownIsShowing = false;
    this.advancedSearchIsShowing = false;
    this.adminToolsDropDownIsShowing = false;
  }

  // Double click event in place to prevent bar locking.
  DropDownClicked() {
    if (this.advancedSearchIsShowing) {
      this.AdvancedSearchToggle.nativeElement.click();
      this.advancedSearchIsShowing = false;
    }
    this.dropDownIsShowing = !this.dropDownIsShowing;
  }

  ExclusionDropDownClicked() {
    this.exclusionDropDownIsShowing = !this.exclusionDropDownIsShowing;
  }

  ReportingDropDownClicked() {
    this.reportingDropDownIsShowing = !this.reportingDropDownIsShowing;
  }

  AdminDropDownClicked() {
    this.adminDropDownIsShowing = !this.adminDropDownIsShowing;
  }

  MessagingDropDownClicked() {
    this.messagingDropDownIsShowing = !this.messagingDropDownIsShowing;
  }

  AdministrationToolsDropDownClicked() {
    this.adminToolsDropDownIsShowing = !this.adminToolsDropDownIsShowing;
  }

  navigateToFeatureCollection() {
    this.HideOverlayMenu();
    this.HideDropDowns();
    this.router.navigate(['/user/features']);
  }

  NavigateToGallery() {
    this.HideOverlayMenu();
    this.HideDropDowns();
    this.router.navigate(['user/exclusion/gallery']);
  }

  CreateNewExclusion() {
    this.HideOverlayMenu();
    this.HideDropDowns();
    this.router.navigate(['user/exclusion/new']);
  }

  NavigateToReportsBuilder() {
    this.HideOverlayMenu();
    this.HideDropDowns();
    this.router.navigate(['user/reporting']);
  }

  NavigateToUserAdmin() {
    this.HideOverlayMenu();
    this.HideDropDowns();
    this.router.navigate(['user/admin/user-panel']);  
  }

  NavigateToSiteAdmin() {
    this.HideOverlayMenu();
    this.HideDropDowns();
    this.router.navigate(['user/admin/site-panel']);  
  }

  NavigateToOrganisationAdmin() {
    this.HideOverlayMenu();
    this.HideDropDowns();
    this.router.navigate(['user/admin/organisation-panel']);  
  }

  NavigateToMessaging() {
    this.HideOverlayMenu();
    this.HideDropDowns();
    this.router.navigate(['user/messaging']);
  }

  HideOverlayMenu() {
    if (this.displayFeatures)
      this.displayFeatures = false;
  }

  LogOut() {
    this.logOutSubscription = this.dsrv.LogOut().subscribe(res => {
      this.router.navigate(['/#']);
    });
  }

  // Double click event in place to prevent bar locking.
  AdvancedSearchClicked() {
    if (this.dropDownIsShowing) {
      this.DropDownToggle.nativeElement.click();
      this.dropDownIsShowing = false;
    }
    this.assrv.ReinitializeSearchModel();
    this.advancedSearchIsShowing = !this.advancedSearchIsShowing;
  }
  
  PrintSearchQuery() {
    // this.assrv.PrintSearchQuery()
  }

  Search() {
    this.assrv.Search().then(value => {
      if (this.advancedSearchIsShowing) { this.AdvancedSearchToggle.nativeElement.click(); };
    });
  }

  NewSearch() {
    this.assrv.ReinitializeSearchModel().then (value => {
    });
  }

  ToggleElevationMode() {
    this.asrv.ToggleElevationMode().then(value => {})
  }

  ReloadDropDownControllers() {
    if (this.asrv.ElevatedMode) {
      this.asrv.PopulateSiteSelectForOrgansiationId(this.asrv.UserOrganisationId).then(value => {
        this.asrv.PopulateColleagueSelectForSiteId(this.asrv.SiteSelect.KeySelected).then(value => {})
      });
    } else {
      this.asrv.PopulateDefaultEmployeeSitesSelect().then(value => {
        this.asrv.PopulateColleagueSelectForSiteId(this.asrv.SiteSelect.KeySelected).then(value => {})
      })
    }
  }

  UnlockAllButtons() {
    this.asrv.ToggleLockedButtons().then(value => {})
  }
}