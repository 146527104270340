import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';

import { ISDDateMethods } from 'src/app/models/ISDDateMethods';
import { ActorService } from 'src/app/services/actor.service';
import { Subscription } from 'rxjs';
import { Honorific } from 'src/app/models/Honorific';
import { Gender } from 'src/app/models/Gender';
import { ExclusionReason } from 'src/app/models/ExclusionReason';
import { ExclusionDurationOption } from 'src/app/models/ExclusionDurationOption';
import { OrganisationBrief } from 'src/app/models/OrganisationBrief';
import { Site } from 'src/app/models/Site';
import { IdentificationDocument } from 'src/app/models/IdentificationDocumentKey';
import { DataService } from 'src/app/services/data.service';
import { ExcluderService } from 'src/app/services/excluder.service';

const redalert: string = '#d11722';
const yellowalert: string = '#f8b03d';
const greenalert: string = '#64b32e';
const bluealert: string = '#24ace2';
const defaultBorderColor = '#223464';

@Component({
  selector: 'app-update',
  templateUrl: './update.component.html',
  styleUrls: ['./update.component.scss']
})
export class UpdateComponent implements OnInit, AfterViewInit {
  Title;
  formName;
  displayWarning: boolean;
  showSpinner: boolean;
  @ViewChild('card') card: ElementRef;

  constructor(
    public dsrv: DataService, 
    private route: ActivatedRoute, 
    public asrv: ActorService, 
    public esrv: ExcluderService,
    private titleService: Title,
    private router: Router
  ) {}

  public honorifics: Honorific[] = [];
  public genders: Gender[] = [];
  public formMessage = "Exclusion Updated";
  public stage: number = 0;
  public showForwardArrow: boolean = true;
  private dateMethods: ISDDateMethods = new ISDDateMethods();
  public excluderImage: string;


  public exclusionImageLoaded: boolean = false;
  public exclusionLoaded: boolean = false;

  ngOnInit() {
    this.GetExcluder();

    this.asrv.GetHonorifics().then(value => {
      this.honorifics = value;
    });
    this.asrv.GetGenders().then(value => {
      this.genders = value;
    })
  }

  GetExcluder() {
    if (!this.ExclusionExists()) {
      this.esrv.LoadExclusion(+this.route.snapshot.params['id']).then(value => {
        this.exclusionLoaded = true;
        this.GetExcluderImage();
      })
    } else {
      this.exclusionLoaded = true;
      this.GetExcluderImage();
    }
  }

  private ExclusionExists(): boolean {
    return this.esrv.CurrentExclusion? true: false;
  }

  GetExcluderImage() {
    if (!this.ExclusionImageExists()) {
      this.esrv.GetCurrentExcluderImage().then(value => {
        this.SetExcluderImage();
      })
    } else {
      this.SetExcluderImage();
    }
  }

  private ExclusionImageExists(): boolean {
    if (!this.esrv.CurrentExclusion.MediaModelCollection.some(i => i.Data)) {
      return false;
    }
    return this.esrv.CurrentExclusion.MediaModelCollection.some(i => i.MediaClassKeyId === 4);
  }

  private SetExcluderImage() {
    this.excluderImage = this.esrv.CurrentExclusion.MediaModelCollection.find(i => i.MediaClassKeyId === 4).Data;
    this.exclusionImageLoaded = true;
  }

  ngAfterViewInit() {};

  public GoBack() {
    if(this.stage > 0) {
      this.stage -= 1;
      window.scroll(0,0);
    }
  }

  public GoForward() {
    this.esrv.ValidatePage(this.stage).then(value => {
      if (value) {
        this.stage+=1;
        window.scroll(0,0);
      }
    })
  }

  ShowHideForwardArrow() {
    this.showForwardArrow = !this.showForwardArrow;
  }

  UpdateDetails() {
    this.PreventProcess();
    this.esrv.SaveExclusion().then(value => {
      this.GoForward();
    });
  }

  GetEndDate(): Date {
    return this.esrv.GetCurrentExcluderEndDate();
  }

  PreventProcess() {
    (document.getElementById('btnProcessExclusion') as HTMLButtonElement).disabled = true;
    (document.getElementById('btnProcessExclusion') as HTMLButtonElement).style.filter = 'Grayscale(0.75)';
  }

  GetBackgroundColor() {  
    let diffDaysStart = this.dateMethods.DateDifferenceInDays(this.esrv.CurrentExclusion.InteractionMonitoringPeriod.Start)
    let diffDaysEnd = this.dateMethods.DateDifferenceInDays(this.dateMethods.AddDays(this.esrv.CurrentExclusion.InteractionMonitoringPeriod.Start, this.esrv.CurrentExclusion.InteractionMonitoringPeriod.Duration));
    if(this.esrv.CurrentExclusion.ClientValues.ReinstatementExists) {
      return bluealert;
    } else if(diffDaysStart <= 5)
      return redalert;
    else if(diffDaysEnd  >= 0)
      return greenalert;
    else return yellowalert;
  }

  CallCloseForm() {
    this.router.navigate(['/user/exclusion/view', this.esrv.CurrentExclusion.InteractionId]);
  }


}


