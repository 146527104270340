import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { SiteDetail } from 'src/app/models/SiteDetail'
import { PostcodeanywhereService } from 'src/app/services/postcodeanywhere.service';
import { Addresses, Address } from 'src/app/models/Addresses';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ActorService } from 'src/app/services/actor.service';
import { OrganisationBrief } from 'src/app/models/OrganisationBrief';
import { GenericTagList } from 'src/app/models/admin/GenericTag';
import { AdminService } from 'src/app/services/admin.service';
import { TagService } from 'src/app/services/tag.service';

@Component({
  selector: 'app-edit-site',
  templateUrl: './edit-site.component.html',
  styleUrls: ['./edit-site.component.scss']
})
export class EditSiteComponent implements OnInit, OnDestroy {

  addresses: Addresses = new Addresses(); 
  address: Address;
  loadingAddressList: boolean = false;
  currentAddressId;
  addressPopulated: boolean = false;
  postCodeSearchIsValid: boolean = false;
  postCodeControl = new Subject<string>();
  public showSendingSpinner: boolean = false;
  public showSiteData: boolean = true;
  public addingNewSite: boolean;
  public allowNewTags: boolean = true;
  public newOrganisation: boolean;
  public isMotorwayService: boolean;

  private getAddressIdSubscription: Subscription;
  private getAddressListSubscription: Subscription;
  private allowUnsubscribe: boolean = false;
  private defaultBorderColor = '#223464';
  private errorBorderColor = 'red';
  public errorMessage: string;

  public get siteInactive(): boolean {
    return this.tsrv.Tags.some(i => i.TagId === 1);
  }

  constructor(private postcodeAnywhere: PostcodeanywhereService, public asrv: ActorService, public adminsrv: AdminService, public tsrv: TagService) {
    this.postCodeControl.pipe(
      debounceTime(400),
      distinctUntilChanged())
      .subscribe(value => {
        if (value.length > 4)
          this.AddressLookup();
          this.allowUnsubscribe = true;
      }
    );
   }

  ngOnInit() {
    this.tsrv.Init(true, this.adminsrv.site.TagIdCollection);
    if (this.adminsrv.site.TagIdCollection.some(i => i === 21)) {
      this.isMotorwayService = true;
    }
    if (this.adminsrv.site.Postcode) {
      this.postcodeAnywhere.getGeocode(this.adminsrv.site.Postcode).subscribe( res => {
        this.adminsrv.site.Longitude = res.Items[0].Longitude;
        this.adminsrv.site.Latitude = res.Items[0].Latitude;
      });
      this.addressPopulated = true;
    }

    this.SetDefaultValues();
  }

  SetDefaultValues() {
    this.adminsrv.siteTags = new GenericTagList(this.asrv);
    this.addingNewSite = !(this.adminsrv.site.ActorId > 0)
  }

  ngOnDestroy() {
    if (this.allowUnsubscribe) {
      this.getAddressIdSubscription.unsubscribe();
      this.getAddressListSubscription.unsubscribe();
    }
  }

  AddressLookup() {
    this.loadingAddressList = true;

    this.postcodeAnywhere.getGeocode(this.adminsrv.site.Postcode).subscribe( res => {
      if (res.Items.length > 0) {
        this.adminsrv.site.Longitude = res.Items[0].Longitude;
        this.adminsrv.site.Latitude = res.Items[0].Latitude;
      } else {
        this.adminsrv.site.Longitude = null;
        this.adminsrv.site.Latitude = null;
      }
    })

    // Query the API and Add the Addresses
    return this.getAddressIdSubscription = this.postcodeAnywhere.getAddressId(this.adminsrv.site.Postcode).subscribe( res => {
      this.getAddressListSubscription = this.postcodeAnywhere.getAddressList(this.adminsrv.site.Postcode, res.Items[0].Id).subscribe( res2 => {
          this.addresses = res2;
          this.postCodeSearchIsValid = true;
          this.adminsrv.site.Line1 = null;
          this.adminsrv.site.Line2 = null;
          this.adminsrv.site.TownCity = null;
          this.adminsrv.site.County = null;
          this.addressPopulated = false;
          this.loadingAddressList = false;
      });
    });
  }

  PopulateAddress() {
    this.postcodeAnywhere.getAddressDetails(this.currentAddressId).subscribe( res => {
      this.adminsrv.site.Line1 = res.Items[0].Line1;
      this.adminsrv.site.Line2 = res.Items[0].Line2;
      this.adminsrv.site.TownCity = res.Items[0].City;
      this.adminsrv.site.County = res.Items[0].Province;
      if (!this.adminsrv.site.County) {
        this.adminsrv.site.County = this.adminsrv.site.TownCity;
      }
      this.addressPopulated = true;
    });
  }

  AddNewTag() {
    this.showSiteData = false;
  }

  GoForward() {
    this.adminsrv.site.TagIdCollection = this.tsrv.Tags.map(function (el) { return el.TagId; });
    this.adminsrv.UpdateSite().then(value => {
      if (value) {
        this.CloseEditForm.next();
      }
    })
  }

  SuspendSite() {
    if (confirm('This will suspend the site. Do you wish to proceed?')) {
      if (!this.tsrv.Tags.some(i => i.TagId === 1)) {
        var edited = new Date('2019-11-20T19:38:48Z');
        this.tsrv.Tags.push({TagId: 1, Name: 'Disabled', Edited: edited, Edited_ActorId: 1});
      }
      this.GoForward();
    }
  }

  ReinstateSite() {
    if (confirm('This will reinstate the site. Do you wish to proceed?')) {
      var index = this.tsrv.Tags.map(function(e) { return e.TagId }).indexOf(1, 0);
      if (index >= 0) {
        this.tsrv.Tags.splice(index, 1);
      }
      this.GoForward();
    }
  }

  SetMotorwayService() {
    if (!this.isMotorwayService) {
      this.tsrv.AddTagToCollection({TagId: 21, Name: 'Motorway Service', Edited: null, Edited_ActorId: null});
    } else {
      this.tsrv.RemoveTagFromCollection(21);
    }
  }

  GoBack() {
    this.CloseEditForm.next()
  }

  @Output() CloseEditForm = new EventEmitter<string>();

}
