<div #cardOuter id="cardOuter" class="row card-container global-seperator">
    <!--LEFT SIDE | IMAGE-->
    <div #cardThumb class="col-6 card-thumb">     
        <img (click)="ImageClick()" *ngIf="!showIconImage && !showLiveView" class="img-exclusion" [src]="interactionImage" [ngStyle]="{'filter': 'blur(' + blurImageValue + ')'}"/>
        <div (click)="ImageClick()" *ngIf="showIconImage && !showLiveView" class="img-exclusion" style="position: relative;" [ngStyle]="{'filter': 'blur(' + blurImageValue + ')'}">
            <app-icon-image id="iconApp" *ngIf="!showLiveView" class="icon-app" [(iconId)]="mediaId" [style.position]="isOutline? 'absolute':'relative'" [style.height]="isOutline? '65%': '100%'"></app-icon-image>
        </div>
        <div *ngIf="showUpdateImage" class="update-image" id="updateImageMenu">
            <div class="row">
                <div class="col-12" style="text-align: center;">
                    <input class="bacta-button" type="image" alt="Settings" src=".\assets\icons\Bacta Assets\Capture Live BLUE.svg" style="width:200px;" (click)="LiveCapture();"/>
                </div>
            </div>
            <!--
            <div class="row">
                <div class="col-12" style="text-align: center;">
                    <input class="bacta-button" type="image" alt="Settings" src=".\assets\icons\Bacta Assets\Capture Outline blue.svg" style="width:200px;" (click)="PickOutline();"/>
                </div>
            </div>
            <div class="row">
                <div class="col-12" style="text-align: center;">
                    <input class="bacta-button" type="image" alt="Settings" src=".\assets\icons\Bacta Assets\Capture Icon blue.svg" style="width:200px;" (click)="PickIcon();"/>
                </div>
            </div>
            -->
            <div class="row">
                <div class="col-12" style="text-align: center;">
                    <input class="button-danger-format" type="button" value="close" (click)="CloseUpdateImage()" />
                </div>
            </div>
        </div>
        <app-customer-photo-inline class="img-exclusion" *ngIf="showLiveView" [(media)]="media" (ImageTaken)="ImageTaken();" (NoImageTaken)="NoImageTaken();"></app-customer-photo-inline>
    </div>

    <!--RIGHT SIDE INFO-->
    <div #cardContent class="col-5 card-content" *ngIf="contentToDisplay === readonlyContent" (click)="ContentClick();" style="font-size:100%"> 
        <h3 class="card-title" *ngIf="(isrv.Interaction.InteractedActorCompositeModelCollection[0].BiologicalActorCompositeModel.Forename && isrv.Interaction.InteractedActorCompositeModelCollection[0].BiologicalActorCompositeModel?.Surname)" [ngStyle]="{'filter': 'blur(' + blurContentValue + ')'}"><b>{{isrv.Interaction.InteractedActorCompositeModelCollection[0].BiologicalActorCompositeModel?.Forename}} {{isrv.Interaction.InteractedActorCompositeModelCollection[0].BiologicalActorCompositeModel?.Surname}}</b></h3>
        <h3 class="card-title" *ngIf="!(isrv.Interaction.InteractedActorCompositeModelCollection[0].BiologicalActorCompositeModel.Forename && isrv.Interaction.InteractedActorCompositeModelCollection[0].BiologicalActorCompositeModel?.Surname)" [ngStyle]="{'filter': 'blur(' + blurContentValue + ')'}"><b>{{isrv.Interaction.InteractedActorCompositeModelCollection[0].BiologicalActorCompositeModel?.Alias}}</b></h3>
        <p [ngStyle]="{'filter': 'blur(' + blurContentValue + ')'}">
            <b>Reference:</b> {{isrv.Interaction?.Reference}} <br>
            <b>Type of interaction:</b> {{interactionType}} <br>
            <b *ngIf="showReason">Reason(s):</b> {{reasons}} <br>
            <b *ngIf="showAction">Action(s):</b> {{actions}} <br>
            <b *ngIf="!isArchived">Review Date: </b> <span *ngIf="!isArchived">{{GetEndDate() | date: 'dd/MM/yyyy'}}</span>

            
        </p>
        <div class="flex-no-padding" *ngIf="InteractionOnTimeOut()">
            <span class="card-img-text card-img-radius"><b>T</b></span>
        </div>
        <div class="flex-no-padding" *ngIf="ReinstatedSelfExcluder()">
            <span class="card-img card-img-radius"><img class="col-img" src=".\assets\icons\Bacta Assets\Reinstated SE.svg"></span>
        </div>
        <p *ngIf="showIconImage">
            <span *ngIf="raceInfo"><b>Ethnicity: </b> {{raceInfo}} <br></span>
            <span *ngIf="hairstyleInfo"><b>Hairstyle: </b> {{hairstyleInfo}} <br></span>
            <span *ngIf="hairColourInfo"><b>Hair Colour: </b> {{hairColourInfo}} <br></span>
            <span *ngIf="ageRangeInfo"><b>Age Range: </b> {{ageRangeInfo}} <br></span>
            <span *ngIf="heightRangeInfo"><b>Height Range: </b> {{heightRangeInfo}} <br></span>
            <span *ngIf="bodyTypeInfo"><b>Body Type: </b> {{bodyTypeInfo}} </span>
        </p>

        <div *ngIf="showUpdateContent" class="update-image" id="updateImageMenu">
            <div class="row">
                <div class="col-12" style="text-align: center;">
                    <input class="button-standard-format" type="text" alt="Settings" value="UPDATE DETAILS" style="width:200px;" (click)="UpdateCustomerDetails();"/>
                </div>
            </div>
            <div class="row">
                <div class="col-12" style="text-align: center;">
                    <input class="button-danger-format" type="button" value="close" (click)="CloseUpdateContent()" />
                </div>
            </div>
        </div>
    </div>
    <div class="col-5 card-content" *ngIf="contentToDisplay === editCustomerDetails" style="font-size:100%">
        <div class="row">
            <label class="inline-label">TITLE: </label>
            <select class="inline-input drop-down-red" name="title" [style.borderColor]="backgroundColor" [(ngModel)]="editHonorificId" style="cursor: pointer;">
                <option></option>
                <option *ngFor="let hono of honorifics" [ngValue]="hono.HonorificId">{{hono.Name}}</option>
            </select>
        </div>
        <div style="height: 50%; cursor: default !important;">
            <div class="row">
                <label class="inline-label">GENDER: </label>
                <select class="inline-input drop-down-red" name="gender" [style.borderColor]="backgroundColor" [(ngModel)]="editGenderId" style="cursor: pointer;"> 
                    <option></option>
                    <option *ngFor="let gender of genders" [ngValue]="gender.GenderId">{{gender.Name}}</option>
                </select>
            </div>
            <div class="row">
                <label class="inline-label">FORENAME: </label><input type="text" class="inline-input" [style.borderColor]="backgroundColor" [(ngModel)]="editForename">
            </div>
            <div class="row">
                <label class="inline-label">SURNAME: </label><input type="text" class="inline-input" [style.borderColor]="backgroundColor" [(ngModel)]="editSurname">
            </div>
            <div class="row">
                <label class="inline-label">NICKNAME: </label><input type="text" class="inline-input" [style.borderColor]="backgroundColor" [(ngModel)]="editAlias">
            </div>
        </div>
        <div class="row">
            <div class="col-6" style="text-align: center;">
                <input type="button" class="button-danger-format" style="max-width: 90%" value="DISCARD" (click)="DiscardChanges();">
            </div>
            <div class="col-6" style="text-align: center;">
                <input type="button" class="button-submit-format" style="max-width: 90%" value="SAVE" (click)="SaveInteraction();">
            </div>
        </div>
    </div>

    <!--FAR RIGHT CLOSE-->
    <div class="col-1 close-container">
        <div class="row">
            <input class="btn-close btn-close-flex-box" type="image" src=".\assets\icons\Bacta Assets\Close.svg" routerLink="/user/customer-interaction/gallery" />
        </div>
        <div class="row">
            <span class="close-text" style="text-align:center;">CLOSE</span>
        </div>  
    </div>
</div>