import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { EmployeeDetails } from 'src/app/models/EmployeeDetails';
import { ActorService } from 'src/app/services/actor.service';
import { Honorific } from 'src/app/models/Honorific';
import { Gender } from 'src/app/models/Gender';
import { Credentials, CredentialsBrief } from 'src/app/models/Credentials';
import { UserSites } from 'src/app/models/UserSites';
import { AdminService } from 'src/app/services/admin.service';
import { NonBiologicalName } from 'src/app/models/actor/Name';
import { GenericTagList } from 'src/app/models/admin/GenericTag';
import { TagService } from 'src/app/services/tag.service';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';


const primarysitekey: number = 61;
const secretkey: number = 62;

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss']
})
export class EditUserComponent implements OnInit {
  public genders: Gender[];
  public honorifics: Honorific[];

  public ActiveCredentials: boolean;
  public siteId: number;
  public showSendingSpinner: boolean;
  public showSpinner: boolean;
  public sitesInOrganisation: UserSites[];
  public sitesToDisplay: UserSites[];
  public showSiteSelect: boolean = false;
  public siteName: string = 'test'
  public SiteSearchPhrase: string;
  public primarySiteId: number;
  public passwordConfirm: string;
  private primarySiteExists: boolean;
  public allowNewTags: boolean = true;
  public tagFilters: GenericTagList;
  public receiveNotifications: boolean;
  public userActive: boolean;


  strength = 0;

  public get NotAllSitesSelected(): boolean {
    return this.sitesInOrganisation.some(i => !i.WorksHere);
  }

  public get SearchEnabled(): boolean {
    return this.sitesInOrganisation.length > 10;
  }

  showCredentials: boolean;
  @Input('showCredentials') get ShowCredentials(): boolean {
    return this.showCredentials;
  };
  @Output('showCredentials') showCredentialsChange: EventEmitter<boolean> = new EventEmitter();
  set ShowCredentials(val: boolean) {
    this.showCredentials = val;
    this.showCredentialsChange.emit(this.showCredentials);
  }

  constructor(
    public asrv: ActorService,
    public adminsrv: AdminService,
    public tsrv: TagService
  ) {
    this.tagFilters = new GenericTagList(this.asrv);

  }

  addEvent(type: string, event: MatDatepickerInputEvent<Date>) {
  }

  checkWord(ev: any) {
    const _memWord = ev;
    const _strengthCountArr = [];
    const _lowercase = new RegExp('^.*[a-z].*');
    const _uppercase = new RegExp('^.*[A-Z].*');
    const _numbers = new RegExp('^.*[0-9].*');
    const _length = new RegExp('^.*[a-zA-Z0-9]{8,12}$');
    // const _symbols = new RegExp("^.*[!@#$%^&*()_-'].*");
    _strengthCountArr.push(
      _lowercase.test(_memWord),
      _uppercase.test(_memWord),
      _numbers.test(_memWord),
      _length.test(_memWord),
    );
    // console.log(_symbols.test(_memWord));
    this.strength = _strengthCountArr.filter(Boolean).length;
  }

  ngOnInit() {
    this.adminsrv.passwordConfirm = null;
    this.adminsrv.userCredententials.Hash = null;
    this.asrv.GetHonorifics().then(value => {
      this.honorifics = value;
    });
    this.asrv.GetGenders().then(value => {
      this.genders = value;
    });

    if (this.adminsrv.employeeDetails.Organisation_ActorId) {
      this.adminsrv.ActiveOrganisationFilterId = this.adminsrv.employeeDetails.Organisation_ActorId;
    }
    this.showSendingSpinner = false;
    this.receiveNotifications = this.adminsrv.employeeDetails.TagIdCollection.some(i => i === 61);
    this.userActive = !this.adminsrv.employeeDetails.TagIdCollection.some(i => i === 1);

    if (this.adminsrv.employeeDetails.SiteIdCollection) {
      this.showSiteSelect = true;
      this.GetUserSites();
      this.siteId = this.adminsrv.employeeDetails.SiteIdCollection[0];
    }

    if (this.adminsrv.creatingNewRecord) {
      this.ActiveCredentials = false;
    } else {
      this.ActiveCredentials = true;
    }
  }

  GetUserSites() {
    this.showSpinner = true;
    this.sitesInOrganisation = [];
    this.adminsrv.employeeDetails.Organisation_ActorId = this.adminsrv.ActiveOrganisationFilterId;
    this.asrv.GetSiteIds(10000, 0, this.adminsrv.ActiveOrganisationFilterId).then(value => {
      this.showSiteSelect = true;
      this.asrv.GetSiteNameCollection(value).then(value => {
        value.sort((a, b) => {
          return a.Name.localeCompare(b.Name);
        })
        this.sitesInOrganisation = this.GetSiteMap(value);
        this.sitesToDisplay = this.sitesInOrganisation;
        if (this.adminsrv.employeeDetails.SettingCollection.some(i => i.SettingKeyId === primarysitekey)) {
          this.primarySiteId = +this.adminsrv.employeeDetails.SettingCollection.find(i => i.SettingKeyId === primarysitekey).Value;
          this.primarySiteExists = true;
        }
        this.showSpinner = false;
      })
    })
  }

  GetSiteMap(result: NonBiologicalName[]): UserSites[] {
    return result.map(item => {
      const userSite: UserSites = new UserSites();
      userSite.ActorId = item.ActorId;
      userSite.Name = item.Name;
      if (this.adminsrv.employeeDetails.SiteIdCollection) {
        userSite.WorksHere = this.adminsrv.employeeDetails.SiteIdCollection.some(i => i === userSite.ActorId);
      } else {
        userSite.WorksHere = false;
      }
      return userSite;
    })
  }

  CheckSite(siteBrief: UserSites) {
    if (siteBrief.WorksHere) {
      siteBrief.WorksHere = true;
      this.adminsrv.employeeDetails.SiteIdCollection.push(siteBrief.ActorId);
    } else {
      siteBrief.WorksHere = false;
      var index = this.adminsrv.employeeDetails.SiteIdCollection.indexOf(siteBrief.ActorId, 0);
      if (index >= 0) {
        this.adminsrv.employeeDetails.SiteIdCollection.splice(index, 1);
      }
    }
  }

  SelectAllSites() {
    this.sitesInOrganisation.forEach(site => {
      site.WorksHere = true;
      if (!this.adminsrv.employeeDetails.SiteIdCollection.some(i => i === site.ActorId)) {
        this.adminsrv.employeeDetails.SiteIdCollection.push(site.ActorId);
      }
    })
  }

  RemoveAllSites() {
    this.sitesInOrganisation.forEach(site => {
      site.WorksHere = false;
      if (!this.adminsrv.employeeDetails.SiteIdCollection.some(i => i === site.ActorId)) {
        this.adminsrv.employeeDetails.SiteIdCollection.push(site.ActorId);
      }
    })
  }

  SearchSites(searchPhase: string) {
    this.sitesToDisplay = [];
    this.sitesInOrganisation.forEach(site => {
      if (site.Name.toLowerCase().includes(searchPhase.toLowerCase())) {
        this.sitesToDisplay.push(site);
      }
    })
  }

  SuspendUser() {
    if (confirm('This will suspend the user and they can no longer use the system. Do you wish to proceed?')) {
      if (!this.adminsrv.employeeDetails.TagIdCollection.some(i => i === 1)) {
        this.adminsrv.employeeDetails.TagIdCollection.push(1)
      }

      this.UpdateEmployee();
    }
  }

  ReinstateUser() {
    if (confirm('This will reinstate the user and the user will be able to access the system. Do you wish to proceed?')) {
      var index = this.adminsrv.employeeDetails.TagIdCollection.indexOf(1, 0);
      if (index >= 0) {
        this.adminsrv.employeeDetails.TagIdCollection.splice(index, 1);
      }
      this.UpdateEmployee();
    }
  }

  SetCredentials() {
    this.adminsrv.SetCredentials().then(value => {
    })
  }

  AddEmployee() {
    const _memWord = this.adminsrv.employeeDetails.MemWord;
    if (!this.primarySiteId) {
      alert('You cannot update an Employee without a Primary Site.');
    } else {
      if (!this.adminsrv.employeeDetails.SettingCollection.some(i => i.SettingKeyId === primarysitekey)) {
        this.adminsrv.employeeDetails.SettingCollection.push({ SettingKeyId: primarysitekey, Value: this.primarySiteId.toString() });
      } else {
        this.adminsrv.employeeDetails.SettingCollection.find(i => i.SettingKeyId === primarysitekey).Value = this.primarySiteId.toString();
      }

      if (_memWord && _memWord !== '') {
        if (!this.adminsrv.employeeDetails.SettingCollection.some(i => i.SettingKeyId === secretkey)) {
          this.adminsrv.employeeDetails.SettingCollection.push({ SettingKeyId: secretkey, Value: _memWord.toString() });
        } else {
          this.adminsrv.employeeDetails.SettingCollection.find(i => i.SettingKeyId === secretkey).Value = _memWord.toString();
        }
      }

      this.adminsrv.AddEmployee().then(value => {
        if (value) {
          this.adminsrv.creatingNewRecord = false;
          alert('Please create credentials in order for the new user to login.')
        }
      })
    }
  }

  UpdateEmployee() {
    const _memWord = this.adminsrv.employeeDetails.MemWord;
    if (!this.primarySiteId) {
      alert('You cannot update an Employee without a Primary Site.');
    } else {
      if (this.adminsrv.employeeDetails.SettingCollection.length > 0) {
        this.adminsrv.employeeDetails.SettingCollection.find(i => i.SettingKeyId === primarysitekey).Value = this.primarySiteId.toString();

      } else {
        this.adminsrv.employeeDetails.SettingCollection = [];
        this.adminsrv.employeeDetails.SettingCollection.push({ SettingKeyId: primarysitekey, Value: this.primarySiteId.toString() });

      }

      if (_memWord && _memWord !== '') {
        if (!this.adminsrv.employeeDetails.SettingCollection.some(i => i.SettingKeyId === secretkey)) {
          this.adminsrv.employeeDetails.SettingCollection.push({ SettingKeyId: secretkey, Value: _memWord.toString() });
        } else {
          this.adminsrv.employeeDetails.SettingCollection.find(i => i.SettingKeyId === secretkey).Value = _memWord.toString();
        }
      }


      this.adminsrv.UpdateEmployee().then(value => {
        if (value) {
          this.CloseEditForm.next();
        }
      });
    }
  }

  GoForward() {
    this.adminsrv.employeeDetails.TagIdCollection = this.tagFilters.Rows.length > 0 ? this.tagFilters.Rows.map(function (el) { return el.TagId; }) : [];
    if (this.receiveNotifications) {
      this.adminsrv.employeeDetails.TagIdCollection.push(61);
    }
    if (this.adminsrv.creatingNewRecord) {
      this.AddEmployee();
    } else {
      this.UpdateEmployee();
    }
  }

  GoBack() {
    this.CloseEditForm.next()
  }

  @Output() CloseEditForm = new EventEmitter<string>();

}
