import { InteractionsCompositeModel, Views_InteractionsCompositeModel } from './Views_InteractionsCompositeModel';

export class Views_ReviewsCompositeModel extends Views_InteractionsCompositeModel {
    Interactions_InteractionId: number;
}

export class ReviewsCompositeModel extends InteractionsCompositeModel {
    constructor(            
        InteractionId,
        AssociatedActorIdCollection,
        InteractedActorCompositeModel,
        InteractionMonitoringPeriod,
        MediaModelCollection,
        Notes,
        Reference,
        SiteId,
        TagIdCollection,
        VehicleCollection,
        Interactions_InteractionId: number
    ) {
        super(        
            InteractionId,
            AssociatedActorIdCollection,
            InteractedActorCompositeModel,
            InteractionMonitoringPeriod,
            MediaModelCollection,
            Notes,
            Reference,
            SiteId,
            TagIdCollection,
            VehicleCollection
        )
    }
}