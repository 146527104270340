import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { TagService } from 'src/app/services/tag.service';

@Component({
  selector: 'app-tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.scss']
})
export class TagComponent implements OnInit, AfterViewInit {

  @ViewChild('tag') tag: ElementRef;

  public value: string;
  @Input('value') get Value(): string {
    return this.value;
  };
  @Output('value') valueChange: EventEmitter<string> = new EventEmitter();
  set Value(val: string) {
    this.value = val;
    this.valueChange.emit(this.value);
  }  

  public id: number;
  @Input('id') get Id(): number {
    return this.id;
  };
  @Output('id') idChange: EventEmitter<number> = new EventEmitter();
  set Id(val: number) {
    this.id = val;
    this.idChange.emit(this.id);
  }  

  @Output() RemoveTag: EventEmitter<number> = new EventEmitter();

  constructor(public tsrv: TagService) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.GetTagStyle();
  }

  RemoveMe() {
    this.tsrv.RemoveTagFromCollection(this.id);
  }

  GetTagStyle() {
    // var rnd = Math.floor(Math.random() * 5) + 1;
    var rnd = 5;
    switch(rnd) {
      case 2:
        this.tag.nativeElement.style.background = 'white';
        this.tag.nativeElement.style.color = 'black';
        this.tag.nativeElement.style.border = '2px solid black';
        this.tag.nativeElement.style.borderRadius = '0.5em';
        break;
      case 3:
        this.tag.nativeElement.style.background = 'black';
        this.tag.nativeElement.style.color = 'white';
        this.tag.nativeElement.style.border = '2px solid white';
        this.tag.nativeElement.style.borderRadius = '0.5em';
        break;
      case 4:
        this.tag.nativeElement.style.background = 'purple';
        this.tag.nativeElement.style.color = 'white';
        this.tag.nativeElement.style.border = '2px solid black';
        this.tag.nativeElement.style.borderRadius = '0.5em';
        break;
      case 5:
        this.tag.nativeElement.style.background = 'yellow';
        this.tag.nativeElement.style.color = 'black';
        this.tag.nativeElement.style.border = '2px solid black';
        this.tag.nativeElement.style.borderRadius = '0.5em';
        break;
      default: 
        this.tag.nativeElement.style.background = 'red';
        this.tag.nativeElement.style.color = 'white';
        this.tag.nativeElement.style.border = '2px solid white';
        this.tag.nativeElement.style.borderRadius = '0.5em';
    }
  }
}
