import { Breach } from './Breach'
import { Reinstate } from './Reinstate'
import { Extension } from './Extension'
import { ActorService } from '../services/actor.service';
import { ExclusionBreaches } from './ExclusionBreaches';

export class ExcluderBrief {
    InteractionId: number;
    InteractedActorId: number;
    BirthDate: Date;
    GenderId: number;
    HonorificId: number;
    Length: number;
    LengthMetricKeyId: number;
    Sites_ActorId: number;
    Reference: string;
    Start: Date;
    PeriodDays: number;
    Forename: string;
    Surname: string;

    // Loaded after Gallery call
    Image: string;
    MediaId: number;
    Name: string;
    Breaches: Breach[];
    BreachExists: boolean = false;
    //Extensions: Extension[];
    Extensions: Extension = new Extension();
    Reinstatement: Reinstate;
    ExclusionBreachesCollection: ExclusionBreaches[];


};
