<div #cardOuter id="cardOuterMobile" class="row card-container global-seperator-mobile">
    <div class="row">
    </div>
    <div class="row" style="display: block;">
        <div class="card-thumb col-12">              
            <div class="back-button-container global-seperator col-12" style="display: flex;flex-direction: row-reverse;padding:0px;" routerLink="/user/customer-interaction/gallery">
                <input class="btn-close btn-close-flex-box" type="image" src=".\assets\icons\Bacta Assets\Close.svg">
            </div> 
            <!--<img (click)="ImageClick($event, 1)" *ngIf="!showIconImage && !showLiveView" class="img-exclusion" [src]="interactionImage" [ngStyle]="{'filter': 'blur(' + blurImageValue + ')'}"/>-->
            <!--            
            <div (click)="ImageClick($event, 1)" *ngIf="showIconImage && !showLiveView" class="img-exclusion" style="position: relative;" [ngStyle]="{'filter': 'blur(' + blurImageValue + ')'}">
                <app-icon-image id="iconApp" *ngIf="!showLiveView" class="icon-app" [(iconId)]="mediaId" [style.position]="isOutline? 'absolute':'relative'" [style.height]="isOutline? '65%': '100%'"></app-icon-image>
            </div>
            -->
            <img *ngIf="!showIconImage" class="img-exclusion" [src]="interactionImage" />
            <div *ngIf="showIconImage" class="img-exclusion" style="position: relative;">
                <app-icon-image id="iconApp" class="icon-app" [(iconId)]="mediaId" [style.position]="isOutline? 'absolute':'relative'" [style.height]="isOutline? '65%': '100%'"></app-icon-image>
            </div>
            <!--
            <div *ngIf="showUpdateImage" class="update-image" id="updateImageMenu">
                <div class="row">
                    <div class="col-12" style="text-align: center;">
                        <input class="bacta-button" type="image" alt="Settings" src=".\assets\icons\Bacta Assets\Capture Live BLUE.svg" style="width:200px;" (click)="LiveCapture();"/>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12" style="text-align: center;">
                        <input class="bacta-button" type="image" alt="Settings" src=".\assets\icons\Bacta Assets\Capture Outline blue.svg" style="width:200px;" (click)="PickOutline();"/>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12" style="text-align: center;">
                        <input class="bacta-button" type="image" alt="Settings" src=".\assets\icons\Bacta Assets\Capture Icon blue.svg" style="width:200px;" (click)="PickIcon();"/>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12" style="text-align: center;">
                        <input class="button-danger-format" type="button" value="close" (click)="CloseUpdateImage()" />
                    </div>
                </div>
            </div>
            <app-customer-photo-inline class="img-exclusion" *ngIf="showLiveView" [(media)]="media" (ImageTaken)="ImageTaken();" (NoImageTaken)="NoImageTaken();"></app-customer-photo-inline>
            -->
        </div>
    </div>
    <div class="row">
        <div class="col-12 card-content" style="margin-top:10px;font-size:100%"> 
            <h3 class="card-title" *ngIf="isrv.Interaction.InteractedActorCompositeModelCollection[0].BiologicalActorCompositeModel.Forename && isrv.Interaction.InteractedActorCompositeModelCollection[0].BiologicalActorCompositeModel?.Surname"><b>{{isrv.Interaction.InteractedActorCompositeModelCollection[0].BiologicalActorCompositeModel?.Forename}} {{isrv.Interaction.InteractedActorCompositeModelCollection[0].BiologicalActorCompositeModel?.Surname}}</b></h3>
            <h3 class="card-title" *ngIf="!(isrv.Interaction.InteractedActorCompositeModelCollection[0].BiologicalActorCompositeModel.Forename && isrv.Interaction.InteractedActorCompositeModelCollection[0].BiologicalActorCompositeModel?.Surname)"><b>{{isrv.Interaction.InteractedActorCompositeModelCollection[0].BiologicalActorCompositeModel?.Alias}}</b></h3>
            <p>
                <b>Reference:</b> {{isrv.Interaction?.Reference}} <br>
                <b>Type of interaction:</b> {{interactionType}} <br>
                <b *ngIf="showReason">Reason(s):</b> {{reasons}} <br>
                <b *ngIf="showAction">Action(s):</b> {{actions}} <br>
                <b *ngIf="!isArchived">Review Date: </b> <span *ngIf="!isArchived">{{GetEndDate() | date: 'dd/MM/yyyy'}}</span> 
            </p>
            <div class="flex-no-padding" *ngIf="InteractionOnTimeOut()">
                <span class="card-img-text card-img-radius"><b>T</b></span>
            </div>
            <div class="flex-no-padding" *ngIf="ReinstatedSelfExcluder()">
                <span class="card-img card-img-radius"><img class="col-img" src=".\assets\icons\Bacta Assets\Reinstated SE.svg"></span>
            </div>
        </div>
    </div>
</div>
