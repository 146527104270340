<div class="body">
    <div class="container loginPanel">
        <div class="col-12">
            <img class="center" src="./assets/icons/Bacta Assets/bacta portal logo full colour.svg" alt="Bacta" style="height:auto;max-width:600px;width:90%;" />
            <div class="text-center" style="margin-top:20px;">
                <span style="width:100%;text-align: center;font-size:20px;color:#223464;">Please provide your username to search for your account</span>
            </div>
            <div class="input-group">
                <div class="input-group-prepend">
                    <span class="input-group-text">Username</span>
                </div>
                <input type="text" class="form-control" name="Login" [(ngModel)]="Username" (keydown.enter)="GetResetLink();">
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="text-center" style="margin-top:20px;">
                        <button class="btn btn-primary" (click)="GetResetLink()" style="min-width: 250px;">
                            <span class="spinner-border spinner-border-sm" role="status" *ngIf="showSpinner"></span>
                            Email The Reset Link
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>