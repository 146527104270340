<div class="features-background">
    <div class="container feature-page">
        <div class="feature-list">
            <div style="position: relative; bottom:3px; right:3px; color:white;">
                {{dsrv.GetVersion()}}
            </div>
            <div class="feature-list-row">
                <div class="double-tile-interaction d-none d-md-block tile--wrapper">
                    <div class="loader--overlay" *ngIf="isLoading">
                        <span class="spinner-border " role="status" aria-hidden="true" style="width: 3rem; height: 3rem;"></span>
                    </div>
                    <input *ngIf="interactionsActive" class="form-check-input" type="image"
                        src=".\assets\icons\feature-collection-new\Customer Interaction Tile double width.svg"
                        style="width:440px;height:240px;" (click)="LinkToCustomerInteraction()">

                    <input *ngIf="!interactionsActive" class="form-check-input not-implemented" type="image"
                        src=".\assets\icons\feature-collection-new\Customer Interaction Tile double width GREY.svg"
                        style="width:440px;height:240px;">
                </div>
                <div class="tile-interaction d-block d-md-none tile--wrapper">
                    <div class="loader--overlay" *ngIf="isLoading">
                        <span class="spinner-border " role="status" aria-hidden="true" style="width: 3rem; height: 3rem;"></span>
                    </div>
                    <input *ngIf="interactionsActive" class="form-check-input" type="image"
                        src=".\assets\icons\feature-collection-new\Customer Interaction Tile single Tile.svg"
                        (click)="LinkToCustomerInteraction()" width="210px" height="240px">
                    <input *ngIf="!interactionsActive" class="form-check-input not-implemented" type="image"
                        src=".\assets\icons\feature-collection-new\Customer Interaction Tile single Tile GREY.svg"
                        width="210px" height="240px">
                </div>
                <div class="tile-interaction tile--wrapper">
                    <div class="loader--overlay" *ngIf="isLoading">
                        <span class="spinner-border " role="status" aria-hidden="true" style="width: 3rem; height: 3rem;"></span>
                    </div>
                    <input *ngIf="exclusionsActive" class="form-check-input" type="image"
                        src=".\assets\icons\feature-collection-new\Exclusion Gallery Tile.svg" id="casino-exclude"
                        (click)="LinkToExclusions()" width="210px" height="240px">
                    <input *ngIf="!exclusionsActive" class="form-check-input not-implemented" type="image"
                        src=".\assets\icons\feature-collection-new\Exclusion Gallery Tile GREY.svg" id="casino-exclude"
                         width="210px" height="240px">
                </div>
                <div class="tile-interaction tile--wrapper">
                    <div class="loader--overlay" *ngIf="isLoading">
                        <span class="spinner-border " role="status" aria-hidden="true" style="width: 3rem; height: 3rem;"></span>
                    </div>
                    <input *ngIf="ageVericationActive" class="form-check-input" type="image"
                        src=".\assets\icons\feature-collection-new\Age Verify Tile.svg" width="210px" height="240px"
                        (click)="openAgeVerify()">
                    <input *ngIf="!ageVericationActive" class="form-check-input not-implemented" type="image"
                        src=".\assets\icons\feature-collection-new\Age Verify Tile GREY.svg" width="210px"
                        height="240px">
                </div>
                <div class="tile-interaction tile--wrapper">
                    <div class="loader--overlay" *ngIf="isLoading">
                        <span class="spinner-border " role="status" aria-hidden="true" style="width: 3rem; height: 3rem;"></span>
                    </div>
                    <input *ngIf="crimeAlertActive" class="form-check-input" type="image"
                        src=".\assets\icons\feature-collection-new\Crime Alert Tile.svg" (click)="openCrimeAlert()"
                        width="210px" height="240px">
                    <input *ngIf="!crimeAlertActive" class="form-check-input" type="image"
                        src=".\assets\icons\feature-collection-new\Crime Alert Tile GREY.svg" width="210px"
                        height="240px">
                </div>
                <div class="tile-interaction tile--wrapper">
                  <div class="loader--overlay" *ngIf="isLoading">
                      <span class="spinner-border " role="status" aria-hidden="true" style="width: 3rem; height: 3rem;"></span>
                  </div>
                  <input *ngIf="toolkitActive" class="form-check-input" type="image"
                      src="../../../assets/icons/feature-collection-new/Toolkit Tile.svg" (click)="openToolkit()"
                      width="210px" height="240px">
                  <input *ngIf="!toolkitActive" class="form-check-input" type="image"
                      src="../../../assets/icons/feature-collection-new/Toolkit Tile GREY.svg" width="210px"
                      height="240px">
              </div>
                <div class="tile-interaction">
                    <input class="form-check-input" type="image"
                        src=".\assets\icons\feature-collection-new\Compliance Handbook Tile.svg"
                        (click)="OpenCompliance(true)" width="210px" height="240px">
                </div>
                <div class="tile-interaction tile--wrapper">
                    <input class="form-check-input" type="image"
                        src=".\assets\icons\feature-collection-new\Training Tile.svg" (click)="OpenTraining()"
                        width="210px" height="240px">
                </div>
                <div class="tile-interaction tile--wrapper">
                    <input class="form-check-input" type="image"
                        src=".\assets\icons\feature-collection-new\User Guide Tile.svg" (click)="OpenUserGuide();"
                        width="210px" height="240px">
                </div>
                <!-- <div class="tile-interaction">
                    <input class="form-check-input not-implemented" type="image"
                        src=".\assets\icons\feature-collection-new\Contact Tile GREY.svg" width="210px" height="240px">
                </div> -->
                <div class="tile-interaction" style="height:0px !important;"></div>
                <div class="tile-interaction" style="height:0px !important;"></div>
            </div>
        </div>
    </div>
</div>