<div class="container global-seperator">
    <div class="row">
        <div class="col-md-12">
            <h4>Exclusion Details</h4>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <div class="input-group" id="inputReason">
                <div class="input-group-prepend">
                    <span class="input-group-text">REASON</span>
                </div>
                <select class="form-control drop-down-purple" [(ngModel)]="esrv.CurrentExclusion.ExclusionReasonKeyId">
                    <option></option>
                    <option *ngFor="let reason of asrv.ReasonOptions" [ngValue]="reason.ExclusionReasonKeyId">{{reason.Name}}</option>
                </select>
            </div>
        </div> 
        <!--
        <div class="col-md-6">
            <div class="input-group" id="inputStartDate">
                <div class="input-group-prepend">
                    <span class="input-group-text">START DATE</span>
                </div>
                <input class="form-control d-none d-lg-block" matInput [matDatepicker]="edp1" (dateInput)="addEvent('input', $event)" (dateChange)="addEvent('change', $event)" [(ngModel)]="esrv.CurrentExclusion.InteractionMonitoringPeriod.Start">
                <div class="input-group-append d-none d-lg-block">
                    <mat-datepicker-toggle class="form-control-date-toggle d-none d-lg-block" matSuffix [for]="edp1"></mat-datepicker-toggle>
                </div>
                <mat-datepicker class="d-none d-lg-block" touchUi #edp1></mat-datepicker>
                <input type="date" class="form-control date-picker-padding d-lg-none" [(ngModel)]="esrv.CurrentExclusion.InteractionMonitoringPeriod.Start">
            </div>
        </div>
        -->
        <div class="col-md-6">            
            <div class="input-group" id="inputExclusionPeriod">
                <div class="input-group-prepend">
                    <span class="input-group-text">PERIOD</span>
                </div>
                <div class="input-group-append input-group-append-s" (click)="periodMovePrevious();">
                    <span class="input-group-text"><</span>
                </div>
                <input style="text-align: center !important;" type="text" class="form-control" disabled [ngModel]="durationPeriods[selectedDurationIndex]">
                <div class="input-group-append input-group-append-s" (click)="periodMoveNext();">
                    <span class="input-group-text">></span>
                </div>
                <div class="input-group-append period-selector" style="flex-grow: 2 !important;">
                    <span class="input-group-text">MONTHS</span>
                </div>
            </div>                       
        </div>
    </div>
    <!--
    <div class="row">
        <div class="col-12 d-none d-sm-block">
            <div class="exclusion-table">
                <div class="exclusion-table-headers">
                    <div class="row">
                        <div class="col-4"><p></p></div>                                        
                        <div class="col-4"><p>EXCLUDE FROM</p></div>
                        <div class="col-4"><p>PLAN TO</p></div>
                    </div>
                </div>
                <div class="exclusion-table-rows">
                    <div class="row md-table-row">
                        <div class="col-4">Bingo</div>
                        <div class="col-4">
                            <div class="form-check">
                                <input class="form-check-input md-checkbox" type="checkbox" value="true" id="bingo-plan-to-exclude" [(ngModel)]="Exclusion.Exclude_Bingo">
                            </div>
                        </div>   
                        <div class="col-4">
                            <div class="form-check">
                                <input class="form-check-input md-checkbox" type="checkbox" value="true" id="bingo-plan-to-exclude" [(ngModel)]="Exclusion.PlanExclude_Bingo">
                            </div>
                        </div>    
                    </div>
                    <div class="row md-table-row">
                        <div class="col-4">Casino</div>
                        <div class="col-4">
                            <div class="form-check">
                                <input class="form-check-input md-checkbox" type="checkbox" value="true" id="casino-plan-to-exclude" [(ngModel)]="Exclusion.Exclude_Casino">
                            </div>
                        </div>   
                        <div class="col-4">
                            <div class="form-check">
                                <input class="form-check-input md-checkbox" type="checkbox" value="true" id="casino-plan-to-exclude" [(ngModel)]="Exclusion.PlanExclude_Casino">
                            </div>
                        </div>   
                    </div>
                    <div class="row md-table-row">
                        <div class="col-4">Betting Shop</div>
                        <div class="col-4">
                            <div class="form-check">
                                <input class="form-check-input md-checkbox" type="checkbox" value="true" id="betting-shop-plan-to-exclude" [(ngModel)]="Exclusion.Exclude_BetShop">
                            </div>
                        </div> 
                        <div class="col-4">
                            <div class="form-check">
                                <input class="form-check-input md-checkbox" type="checkbox" value="true" id="betting-shop-plan-to-exclude" [(ngModel)]="Exclusion.PlanExclude_BetShop">
                            </div>
                        </div>   
                    </div>
                    <div class="row md-table-row">
                        <div class="col-4">Online</div>
                        <div class="col-4">
                            <div class="form-check">
                                <input class="form-check-input md-checkbox" type="checkbox" value="true" id="online-plan-to-exclude" [(ngModel)]="Exclusion.Exclude_Online" >
                            </div>
                        </div>  
                        <div class="col-4">
                            <div class="form-check">
                                <input class="form-check-input md-checkbox" type="checkbox" value="true" id="online-plan-to-exclude" [(ngModel)]="Exclusion.PlanExclude_Online" >
                            </div>
                        </div>   
                    </div>
                    <div class="row md-table-row">
                        <div class="col-4">MSA's</div>
                        <div class="col-4">
                            <div class="form-check">
                                <input class="form-check-input md-checkbox" type="checkbox" value="true" id="msa-plan-to-exclude" [(ngModel)]="Exclusion.Exclude_MSA" >
                            </div>
                        </div>  
                        <div class="col-4">
                            <div class="form-check">
                                <input class="form-check-input md-checkbox" type="checkbox" value="true" id="msa-plan-to-exclude" [(ngModel)]="Exclusion.PlanExclude_MSA" >
                            </div>
                        </div>   
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-12 d-sm-none">
            <div class="exclusion-table">
                <div class="exclusion-table-headers-small">
                    <div class="row" style="margin:auto;">
                        <div class="exclusion-table-header-small">EXCLUDE FROM</div>
                    </div>
                    <div class="exclusion-table-rows-small">
                        <div class="row" style="margin:auto;">
                            <div class="exclusion-table-content-small">Bingo</div>
                            <div class="exclusion-table-content-small">                            
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="true" id="bingo-plan-to-exclude" [(ngModel)]="Exclusion.Exclude_Bingo">
                                </div>
                            </div>
                        </div>  
                        <div class="row" style="margin:auto;">
                            <div class="exclusion-table-content-small">Casino</div>
                            <div class="exclusion-table-content-small">                            
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="true" id="casino-plan-to-exclude" [(ngModel)]="Exclusion.Exclude_Casino">
                                </div>
                            </div>
                        </div>  
                        <div class="row" style="margin:auto;">
                            <div class="exclusion-table-content-small">Betting Shop</div>
                            <div class="exclusion-table-content-small">                            
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="true" id="betting-shop-plan-to-exclude" [(ngModel)]="Exclusion.Exclude_BetShop">
                                </div>
                            </div>
                        </div>  
                        <div class="row" style="margin:auto;">
                            <div class="exclusion-table-content-small">Online</div>
                            <div class="exclusion-table-content-small">                            
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="true" id="online-plan-to-exclude" [(ngModel)]="Exclusion.Exclude_Online">
                                </div>
                            </div>
                        </div>      
                        <div class="row" style="margin:auto;">
                            <div class="exclusion-table-content-small">MSA's</div>
                            <div class="exclusion-table-content-small">                            
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="true" id="msa-plan-to-exclude" [(ngModel)]="Exclusion.Exclude_MSA">
                                </div>
                            </div>
                        </div>                     
                    </div>  
                </div>      
            </div>
        </div>
        <div class="col-sm-12 d-sm-none" style="padding-top:10px;">
            <div class="exclusion-table">
                <div class="exclusion-table-headers-small">
                    <div class="row" style="margin:auto;">
                        <div class="exclusion-table-header-small">PLAN TO EXCLUDE</div>
                    </div>
                    <div class="exclusion-table-rows-small">
                        <div class="row" style="margin:auto;">
                            <div class="exclusion-table-content-small">Bingo</div>
                            <div class="exclusion-table-content-small">                            
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="true" id="bingo-plan-to-exclude" [(ngModel)]="Exclusion.PlanExclude_Bingo">
                                </div>
                            </div>
                        </div>  
                        <div class="row" style="margin:auto;">
                            <div class="exclusion-table-content-small">Casino</div>
                            <div class="exclusion-table-content-small">                            
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="true" id="casino-plan-to-exclude" [(ngModel)]="Exclusion.PlanExclude_Casino">
                                </div>
                            </div>
                        </div>  
                        <div class="row" style="margin:auto;">
                            <div class="exclusion-table-content-small">Betting Shop</div>
                            <div class="exclusion-table-content-small">                            
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="true" id="betting-shop-plan-to-exclude" [(ngModel)]="Exclusion.PlanExclude_BetShop">
                                </div>
                            </div>
                        </div>  
                        <div class="row" style="margin:auto;">
                            <div class="exclusion-table-content-small">Online</div>
                            <div class="exclusion-table-content-small">                            
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="true" id="online-plan-to-exclude" [(ngModel)]="Exclusion.PlanExclude_Online">
                                </div>
                            </div>
                        </div>
                        <div class="row" style="margin:auto;">
                            <div class="exclusion-table-content-small">MSA's</div>
                            <div class="exclusion-table-content-small">                            
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="true" id="msa-plan-to-exclude" [(ngModel)]="Exclusion.PlanExclude_MSA">
                                </div>
                            </div>
                        </div>                            
                    </div>  
                </div>      
            </div>
        </div>
    </div>
    -->
</div>      