import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActorService } from 'src/app/services/actor.service';
import { AdminService } from 'src/app/services/admin.service';

@Component({
  selector: 'app-admin-main-view',
  templateUrl: './admin-main-view.component.html',
  styleUrls: ['./admin-main-view.component.scss']
})

export class AdminMainViewComponent implements OnInit {

  public dataModel: any;
  public dataSpinner: boolean = true;
  public showPage: number;

  constructor(private router: Router, public asrv: ActorService, public adminsrv: AdminService) { }

  ngOnInit() {    
    this.adminsrv.CheckUserIsAdmin();
    this.adminsrv.FetchOrganisationFilterValues().then(value => {
      this.dataSpinner = false;
    });
  }
}
