<!--<div class="container global-seperator" *ngIf="!inspectMotorway">-->
    <div class="global-seperator"></div>
    <div class="row">
        <div class="col-12">
            <h4>Motorway Services</h4>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <div class="input-group" id="inputSearchPhrase">
                <div class="input-group-prepend">
                    <span class="input-group-text">Search</span>
                </div>
                <input type="text" class="form-control" name="searchPhrase" [(ngModel)]="searchPhrase" (ngModelChange)="SearchTable();">
            </div>
        </div> 
    </div>
    <div class="col-md-12">
        <div class="address-in-radius-table bacta-table-outline">
            <div class="address-in-radius-table_header-format">
                <div class="row">
                    <div class="col-6 bacta-table-header-text">MOTORWAY</div>                                        
                    <div class="col-4 bacta-table-header-text">SITE COUNT</div>
                    <div class="col-2 bacta-table-header-text"></div>
                </div>
            </div>
            <div class="bacta-table-row-format" style="height:125px; overflow-y: scroll;">
                <div class="row" *ngFor="let motorway of displayMotorWayCollection">
                    <div class="col-6 bacta-table-row-content" style="width:100%;text-align:left;">
                        {{motorway.Name}}
                    </div>
                    <div class="col-4 bacta-table-row-content" style="width:100%;text-align:center;">
                        {{motorway.Sites.length}}
                    </div>
                    <div class="col-2 bacta-table-row-content" style="width:100%">
                        <span *ngIf="!(motorway.AddedToExclusion)" (click)="AddMotorwayToExclusion(motorway);" style="height:100%;width:100%;">(Add)</span>
                        <span *ngIf="(motorway.AddedToExclusion)" (click)="RemoveMotorwayFromExclusion(motorway);" style="height:100%;width:100%;">(Remove)</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
<!--</div>


<app-inspect-sites *ngIf="inspectMotorway" [(motorway)]="motorway" (CloseInspect)="CloseInspect();"></app-inspect-sites>-->