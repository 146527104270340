<div #tag class="container global-seperator" style="margin-top:10px;">
    <div *ngIf="!value" class="spinner-border" role="status">
        <span class="sr-only"></span>
    </div>
    <div *ngIf="value" class="row" style="height:30px; padding-top:2.5px;">
        <div class="col-10" style="height:20px;vertical-align: middle;">
            <b><span class="float-text">{{value}}</span></b>
        </div>
        <div class="col-2" style="text-align: center;vertical-align: middle;" (click)="RemoveMe()" style="padding: 0;cursor:pointer;text-align: right;">
            <input type="image" src=".\assets\icons\Bacta Assets\Close.svg" style="filter:invert(1); height: 24px;">
        </div>
    </div>
</div>