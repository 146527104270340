<div class="container">
    <input #captureBtn id="captureBtn" type="file" accept="image/*" capture="user" style="display:none;" (change)="onFileSelected($event)" />
    <input id="getDirectory" type="file" accept="image/*" style="display:none;" (change)="onFileSelected($event)" />

    <div class="row" *ngIf="(ShowLiveView)">
        <div class="col-sm-12" style="padding-right:0px;">
            <app-camera-live-view [(media)]="media" (CloseForm)="CloseLiveView();"></app-camera-live-view>   
        </div>
    </div>
    <div class="row image-row" *ngIf="(PhotoTaken) && !(ShowLiveView)">
        <div class="col-sm-12 image-container" style="padding-right:0px;">
            <img #image class="excluder-image-preview" [src]="media.Data" id="excluderCapture">
        </div>
    </div>
    <div class="row button-container" *ngIf="(PhotoTaken) && !(ShowLiveView)" style="margin:auto;margin-top:20px;">
        <div class="col-6" style="text-align: center;">
            <input type="image" class="bacta-button photo-inline-button-sizing" src=".\assets\icons\Bacta Assets\Turn Anti Clockwise Blue.svg" style="cursor:pointer" (click)="RotateLeft()"/>
        </div>
        <div class="col-6" style="text-align: center;">
            <input type="image" class="bacta-button photo-inline-button-sizing" src=".\assets\icons\Bacta Assets\Turn Clockwise Blue.svg" style="cursor:pointer" (click)="RotateRight()"/>
        </div>
    </div>
    <div class="row button-container" *ngIf="(PhotoTaken) && !(ShowLiveView)" style="margin:auto;margin-top:20px;">
        <div class="col-6" style="text-align: center;">
            <input type="button" value="Close" class="button-danger-format photo-inline-button-sizing" style="cursor:pointer" (click)="Close()"/>
        </div>
        <div class="col-6" style="text-align: center;">
            <input type="button" value="Save Image" class="button-submit-format photo-inline-button-sizing" style="cursor:pointer" (click)="Accept()"/>
        </div>
    </div>
</div>