<div *ngIf="!adminsrv.organisationCreateMode || creationStep === 0" id="organisationContainer" class="container global-seperator" style="padding:0px !important;">
    <div class="panel panel-defaul">
        <div class="panel-heading"><h4>Organisation</h4></div>
        <div class="panel-body"></div>
    </div>
    <div id="detailsPanel" class="panel panel-defaul">
        <div class="panel-body">
            <div class="row">
                <div class="col-md-6">
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text">Name</span>
                        </div>
                        <input type="text" class="form-control" [(ngModel)]="organisation.Name">
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="!adminsrv.organisationCreateMode" class="panel-body" style="margin-top: 15px;">
        <div class="row">
            <h5>Organisation Sites</h5>
        </div>
        <div class="row" style="min-height:200px;">
            <div class="col-md-12">
                <div class="bacta-table-outline">
                    <div class="row" style="height:40px;">
                        <span class="col-sm-6 bacta-table-header-text">Site Name</span>
                        <span class="col-sm-6 bacta-table-header-text" style="text-align: center; padding-left:0px !important;padding-right: 0px !important;">Headquarters</span>
                    </div>
                    <div class="bacta-table-row-format">
                        <div class="row" *ngFor="let siteBrief of sitesInOrganisation" style="margin-top:5px;">
                            <span class="col-sm-6 bacta-table-row-content">
                                {{siteBrief.Name}}
                            </span>
                            <span class="col-sm-6 bacta-table-row-content" style="text-align: center; padding-right: 0px !important;">
                                <input type="checkbox" [(ngModel)]="siteBrief.Headquarters" (ngModelChange)="CheckSite(siteBrief)">
                            </span>
                        </div>
                        <div *ngIf="(showSpinner)" class="row bacta-table-row-content">
                            <span class="spinner-border text-danger" role="status" style="margin-left:15px;"></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="!adminsrv.organisationCreateMode" class="panel-body" style="margin-top: 15px;">
        <div class="row">
            <h5>Organisation Employees</h5>
        </div>
        <div class="row" style="min-height:200px;">
            <div class="col-md-12">
                <div class="bacta-table-outline">
                    <div class="row" style="height:40px;">
                        <span class="col-sm-4 bacta-table-header-text">Name</span>
                        <span class="col-sm-4 bacta-table-header-text" style="text-align: center; padding-left:0px !important;padding-right: 10px !important;">Primary Contact</span>
                        <span class="col-sm-4 bacta-table-header-text" style="text-align: center; padding-left:0px !important;padding-right: 10px !important;">Data Protection Officer</span>
                    </div>
                    <div class="bacta-table-row-format">
                        <div class="row" *ngFor="let user of usersInOrganisation" style="margin-top:5px;">
                            <span class="col-sm-4 bacta-table-row-content">
                                {{user.Name}}
                            </span>
                            <span class="col-sm-4 bacta-table-row-content" style="text-align: center; padding-right: 0px !important;">
                                <input type="checkbox" [(ngModel)]="user.PrimaryContact" (ngModelChange)="CheckUser(user)">
                            </span>
                            <span class="col-sm-4 bacta-table-row-content" style="text-align: center; padding-right: 0px !important;">
                                <input type="checkbox" [(ngModel)]="user.DataProtectionOfficer" (ngModelChange)="CheckDataProtectionOfficer(user)">
                            </span>
                        </div>
                        <div *ngIf="(showSpinner)" class="row bacta-table-row-content">
                            <span class="spinner-border text-danger" role="status" style="margin-left:15px;"></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="creationStep === 1">
    <app-edit-site (CloseEditForm)="CloseSiteForm();"></app-edit-site>
</div>

<div *ngIf="creationStep === 2 || creationStep === 3">
    <app-edit-user (CloseEditForm)="CloseUserForm();" [(showCredentials)]="showCredentials"></app-edit-user>
</div>

<div class="form-footer">
    <div class="navigation" (click)="GoBack()">
        <span><input type="image" src=".\assets\icons\Bacta Assets\Back button blue.svg" style="width:100px" /></span>
    </div>
    <div class="navigation-warning" style="text-align: center; vertical-align:middle; height: 100%;">
        <div class="col-md-12" >
            <div *ngIf="!adminsrv.creatingNewRecord" class="form-group" style="width: 100%; text-align:center; margin-top:10px;">
                <button *ngIf="organisationActive" type="button" class="btn btn-danger" (click)="SuspendOrganisation();">Suspend Organisation</button>
                <button *ngIf="!organisationActive" type="button" class="btn btn-success" (click)="ReinstateOrganisation();">Reinstate Organisation</button>
            </div>
        </div>
    </div>  
    <div class="navigation" (click)="GoNext()">
        <span *ngIf="!showPushSpinner"><input type="image" src=".\assets\icons\Bacta Assets\Next button blue.svg" style="width:100px"/></span>
        <div *ngIf="showPushSpinner" class="spinner-border" style="width:4rem; height: 4rem;" role="status">
            <span class="sr-only"></span>
        </div>
    </div>   
</div>