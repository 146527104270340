import { Honorific } from '../Honorific';
import { Gender } from '../Gender';
import { ExclusionDurationOption } from '../ExclusionDurationOption';
import { ExclusionReason } from '../ExclusionReason';
import { Site } from '../Site';
import { Organisation } from '../Organisation';
import { IdentificationDocument } from '../IdentificationDocumentKey';
import { SiteCollection } from '../SiteCollection';
import { ISDDateMethods } from '../ISDDateMethods';
import { ActorService } from 'src/app/services/actor.service';
import { Subscription } from 'rxjs';
import { BiologicalUserInfo } from '../actor/Name';
import { InteractionService } from 'src/app/services/interaction.service';
import { ExcluderService } from 'src/app/services/excluder.service';
import { ApiService } from 'src/app/services/api.service';

// Class for creating tabular reports.
// Every tabular class requires the following properties.
// Rows: any[] = [] --Holds all the newRows pushed.
// HeaderTransaltion = [{}]  --This will print all the headers to the table.
// newRow = [{}] --Make the values all blank but match the HeaderTranslationIds
// public AddRow(rowObject) {} --Use this push rows, ensure all values are hardcoded for now.

export class BreachList {
    private DateMethods: ISDDateMethods = new ISDDateMethods();
    public Rows: any[] = []
    public SubscriptionsOpened: number;
    public CompletedSubscriptions: number = 0;
    private siteNamePromises: SiteNamePromise[] = [];
    public DataLoaded: boolean = false;
    public HeaderTranslation = [{
        'HonorificId': 'Title',
        'Forename': 'Forename',
        'Surname': 'Surname',
        'Breached': 'Breach Date',
        'SiteId': 'Breach Site',
        'hasParticipated': 'Played',
        'Reference': 'Reference'
    }];

    public GetExclusionIsSubscribed: boolean;
    public ExclusionSubscription: Subscription;

    constructor(
        public asrv: ActorService,
        public esrv: ExcluderService,
        private breachids: number[],
        private _api: ApiService,
    ) {
        this.breachids.forEach(id => {
            this.AddRow(id);
        })

        this.DataLoaded = true;
    }

    public AddRow(id: number) {
        var returnRow = {};
        returnRow['ExclusionBreachId'] = id;
        returnRow['Reference'] = false;
        returnRow['ExclusionId'] = false;
        returnRow['Breached'] = false;
        returnRow['HonorificId'] = false;
        returnRow['Forename'] = false;
        returnRow['Surname'] = false;
        returnRow['SiteId'] = false;
        returnRow['hasParticipated'] = false;
        this.asrv.GetBreach(id).then(value => {
            returnRow['Reference'] = value.Reference;
            returnRow['ExclusionId'] = value.Exclusions_InteractionId;
            returnRow['Breached'] = this.DateMethods.FormatDateForReport(new Date(value.Breached));

            this._api.getViewExclusionCompositeModel(value.Exclusions_InteractionId).subscribe(res => {
                res.InteractedActorCompositeModel = res.InteractedActorCompositeModelCollection[0];
                returnRow['Forename'] = res.InteractedActorCompositeModel.BiologicalActorCompositeModel.Forename;
                returnRow['Surname'] = res.InteractedActorCompositeModel.BiologicalActorCompositeModel.Surname;
                this.asrv.GetHonorifics().then(value => {
                    returnRow['HonorificId'] = value.find(i => i.HonorificId === res.InteractedActorCompositeModel.BiologicalActorCompositeModel.HonorificId).Name;
                });
            })

            this.GetSiteName(value.SiteId).then(value => {
                returnRow['SiteId'] = value;
            })

            returnRow['hasParticipated'] = value.hasParticipated ? 'Yes' : 'No';
        })
        this.Rows.push(returnRow);
    }

    GetSiteName(siteid): Promise<string> {
        if (this.siteNamePromises.some(i => i.SiteId === siteid)) {
            return this.siteNamePromises.find(i => i.SiteId === siteid).Promise;
        } else {
            return new Promise<string>(resolve => {
                this.asrv.GetSiteNameCollection([siteid]).then(value => {
                    if (value) {
                        resolve(value[0].Name);
                    } else {
                        resolve('');
                    }
                })
            })
        }
    }
}

class SiteNamePromise {
    SiteId: number;
    Promise: Promise<string>;
}
