import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CustomerInteraction } from 'src/app/models/CustomerInteraction';
import { CustomerInteractionReview } from 'src/app/models/CustomerInteractionReview';
import { Views_ReviewsCompositeModel } from 'src/app/models/InteractionExclusion/Views_ReviewsCompositeModel';
import { InteractionService } from 'src/app/services/interaction.service';

@Component({
  selector: 'app-customer-review',
  templateUrl: './customer-review.component.html',
  styleUrls: ['./customer-review.component.scss']
})
export class CustomerReviewComponent implements OnInit {

  public chkGamblingTrue: boolean;
  public chkGamblingFalse: boolean;
  public chkBehaviourTrue: boolean;
  public chkBehaviourFalse: boolean;
  public chkPatternTrue: boolean;
  public chkPatternFalse: boolean;

  constructor(public isrv: InteractionService) { }

  public customerInteractionReview: CustomerInteractionReview = new CustomerInteractionReview();

  ngOnInit() {
    if (this.isrv.ReviewPageVisited) {
      this.chkGamblingTrue = this.isrv.Interaction.TagIdCollection.some(i => i === 130);
      this.chkGamblingFalse = !this.chkGamblingTrue;
      this.chkBehaviourTrue = this.isrv.Interaction.TagIdCollection.some(i => i === 131); 
      this.chkBehaviourFalse = !this.chkBehaviourTrue;
      this.chkPatternTrue = this.isrv.Interaction.TagIdCollection.some(i => i === 132);
      this.chkPatternFalse = !this.chkPatternTrue;
    } else {
      this.isrv.ReviewPageVisited = true;
    }

    if (this.isrv.Interaction.MediaModelCollection.some(i => i.MediaClassKeyId === 4)) {
      if (this.isrv.Interaction.MediaModelCollection.find(i => i.MediaId > 0 && i.MediaClassKeyId < 100)) {
        this.isrv.Interaction.MediaModelCollection.find(i => i.MediaClassKeyId === 4).Data = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADkAAAA9CAIAAACY61bEAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAABUSURBVGhD7c5BDQAwEASh+je9VcHjkkEBb3d0NboaXY2uRlejq9HV6Gp0NboaXY2uRlejq9HV6Gp0NboaXY2uRlejq9HV6Gp0NboaXY2uRlejq7B974OYmhOKU9kAAAAASUVORK5CYII=';
      }
    }
  }

  GamblingToolUsed(bool: boolean) {
    if (bool) {
      this.isrv.AddItemToTagCollection(130);
    } else {
      this.isrv.RemoveItemFromTagCollection(130);
    }
    this.chkGamblingTrue = bool;
    this.chkGamblingFalse = !bool;
    this.isrv.chkGambling = true
  }

  PositiveBehaviourChange(bool: boolean) {
    if (bool) {
      this.isrv.AddItemToTagCollection(131);
    } else {
      this.isrv.RemoveItemFromTagCollection(131);
    }
    this.chkBehaviourTrue = bool;
    this.chkBehaviourFalse = !bool;
    this.isrv.chkBehaviour = true;
  }

  PatternOfGamblingChanged(bool: boolean) {
    if (bool) {
      this.isrv.AddItemToTagCollection(132);
    } else {
      this.isrv.RemoveItemFromTagCollection(132);
    }
    this.chkPatternTrue = bool;
    this.chkPatternFalse = !bool;
    this.isrv.chkPattern = true;
  }

}
