<div class="container global-seperator">    
    <div class="row">
        <div class="col-md-12">
            <h4><b>Record Breach</b></h4>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <div id="ctrlSites" class="input-group">
                <div class="input-group-prepend">
                    <span class="input-group-text">SITE</span>
                </div>
                <select id="ctrlSites" class="form-control drop-down-purple" [(ngModel)]="breach.SiteId" (ngModelChange)="PopulateEmployeeDropDown();">
                    <option></option>
                    <option *ngFor="let dropDownItem of asrv.SiteSelect.DropDownItems" [ngValue]="dropDownItem.Key">{{dropDownItem.Value}}</option>
                </select>
            </div>
        </div>
        <div class="col-md-6">                
            <div class="input-group" id="inputUser">
                <div class="input-group-prepend">
                    <span class="input-group-text">Interview By</span>
                </div>
                <select class="form-control drop-down-purple" name="user" [(ngModel)]="employeeId">
                    <option></option>
                    <option *ngFor="let dropDownItem of asrv.ColleagueSelect.DropDownItems" [ngValue]="dropDownItem.Key">{{dropDownItem.Value}}</option>    
                </select>
            </div>                
        </div>
    </div>

    <div class="row">
        <div class="col-md-6">
            <div id="ctrlBreachDate" class="input-group">
                <div class="input-group-prepend">
                    <span class="input-group-text">Breach Date</span>
                </div>
                <input class="form-control d-none d-lg-block" matInput [matDatepicker]="cddp1" (dateInput)="addEvent('input', $event)" (dateChange)="addEvent('change', $event)" [(ngModel)]="breach.ControllerDate">
                <div class="input-group-append time-selector d-none d-lg-block">
                    <mat-datepicker-toggle class="form-control-date-toggle d-none d-lg-block" matSuffix [for]="cddp1"></mat-datepicker-toggle>
                </div>
                <mat-datepicker class="d-none d-lg-block" touchUi #cddp1></mat-datepicker>
                <input type="date" class="form-control date-picker-padding d-lg-none" name="breachdate" [(ngModel)]="breach.ControllerDate">
            </div>
        </div>
        <div class="col-md-6">
            <div id="ctrlBreachTime" class="input-group">
                <div class="input-group-prepend">
                    <span class="input-group-text">Breach Time</span>
                </div>
                <input type="time" class="form-control d-none d-lg-block" name="breachTime" [(ngModel)]="breach.ControllerTime">
                <input type="time" class="form-control date-picker-padding d-lg-none" name="breachTime" [(ngModel)]="breach.ControllerTime">
            </div>
        </div>
    </div>

    <div class="row" style="min-height:200px; padding-top: 20px;">
        <div class="col-md-12">
            <div style="background-color: #223464; padding: 10px; padding-top: 0px !important; border: 2px solid #223464; border-radius: 0.5em;">
                <div class="row">
                    <span style="color:white;"><b>BREACH DETAILS</b></span>
                </div>
                <div class="row">
                    <!--[(ngModel)]="interaction.Notes"-->
                    <textarea id="ctrlNotes" style="width:100%; height: 150px; color: red; padding-left:5px; padding-right: 5px;"></textarea>
                </div>
            </div>
        </div>
    </div>

    <div class="form-group" style="text-align: right; margin-top:20px;">
        <button type="button" class="btn btn-danger" (click)="evsrv.ShowMainView(false);" style="margin-right: 10px;">Close</button>
        <button #update type="button" class="btn btn-success" (click)="ValidateAndSendData();">        
            <span *ngIf="showSendingSpinner" class="spinner-grow spinner-grow-sm" role="status"></span>
            Add<span *ngIf="showSendingSpinner">ing</span> Breach<span *ngIf="showSendingSpinner">...</span>
        </button>
    </div>
</div>  

