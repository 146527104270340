<div class="container global-seperator">
    <div class="row">
        <div class="col-md-6">
            <div class="flex-check-box">
                <div class="flex-check-prepend">REVIEW REQUIRED</div>
                <input id="chkReviewRequired" type="checkbox" class="flex-check-box-control"
                    [(ngModel)]="reviewRequired">
            </div>
        </div>
    </div>

    <ng-container *ngIf="reviewRequired">
        <div class="row">
            <div class="col-md-6">
                <div class="input-group" id="inputGender">
                    <div class="input-group-prepend">
                        <span class="input-group-text">Review</span>
                    </div>
                    <select class="form-control drop-down-purple" name="reviewperiod"
                        [(ngModel)]="monitoringPeriod.Duration" (ngModelChange)="setReviewDuration()">
                        <option *ngFor="let review of ReviewPeriodOptions" [ngValue]="review.ReviewPeriodInDays">
                            {{review.ReviewPeriodInWeeks}} WEEKS</option>
                    </select>
                </div>
            </div>
            <div class="col-md-6">
                <div class="input-group" id="inputGender">
                    <div class="input-group-prepend">
                        <span class="input-group-text">Staff Member</span>
                    </div>
                    <select *ngIf="!isUser" class="form-control drop-down-purple" name="personcompletingform"
                        [(ngModel)]="isrv.PersonCompletingForm">
                        <option></option>
                        <option *ngFor="let dropDownItem of asrv.ColleagueSelect?.DropDownItems"
                            [ngValue]="dropDownItem.Key">{{dropDownItem.Value}}</option>
                    </select>
                    <select *ngIf="isUser" class="form-control drop-down-purple" name="personcompletingform"
                        [(ngModel)]="isrv.PersonCompletingForm">
                        <option *ngFor="let dropDownItem of userList"
                            [ngValue]="dropDownItem.Key" selected="selected">{{dropDownItem.Value}}</option>
                    </select>
                </div>
            </div>
        </div>
    </ng-container>
    <div class="row">
        <div class="col-md-12">
            
            <div class="information" *ngIf="reviewRequired">
                <strong>Info: </strong> <br>
                You've selected to require a review. <br>
                This will create a new review with the current interaction's details and any options selected.<br>
                <span *ngIf="amountOfWeeks > 0">
                    The new review will be due in {{amountOfWeeks}} weeks
                </span>

            </div>
            <div class="information" *ngIf="!reviewRequired">
                <strong>Info: </strong> <br>
                You've selected <strong>NO</strong> review required.<br>
                If review is not required, interaction will be updated and saved into archived.
            </div>
        </div>
    </div>
    <div class="row" style="padding-top:15px">
        <div class="col-md-12 exclusion-button" style="text-align: Left;">
            <input #btnSave class="bacta-button" type="image" alt="Settings"
                src=".\assets\icons\Bacta Assets\Save Interaction green.svg"
                style="width:250px;padding-right: 50px !important;" (click)="Save();" />
        </div>
    </div>
</div>