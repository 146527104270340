<div class="container global-seperator" style="overflow-y:auto;overflow-x:hidden;">
  <div class="container global-seperator" style="padding: 20px 0 !important;">
    <div class="row">
      <div class="col-md-12">
        <h3><b>Machine Service Visits</b></h3>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-6">
      <div id="ctrlCheckDate" class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text">Date</span>
        </div>
        <input class="form-control d-none d-lg-block" matInput [matDatepicker]="cddp1"
          (dateInput)="addEvent('input', $event)" (dateChange)="addEvent('change', $event)"
          [(ngModel)]="checkDateModel">
        <div class="input-group-append time-selector d-none d-lg-block">
          <mat-datepicker-toggle class="form-control-date-toggle d-none d-lg-block" matSuffix [for]="cddp1">
          </mat-datepicker-toggle>
        </div>
        <mat-datepicker class="d-none d-lg-block" touchUi #cddp1></mat-datepicker>
        <input type="date" class="form-control date-picker-padding d-lg-none" name="checkDate"
          [(ngModel)]="checkDateModel" (ngModelChange)="addEvent('change', $event)">
      </div>
    </div>
    <div class="col-md-6">
      <div id="ctrlCheckTime" class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text">Time</span>
        </div>
        <input type="time" class="form-control d-none d-lg-block" name="checkTime" [(ngModel)]="checkTime">
        <input type="time" class="form-control date-picker-padding d-lg-none" name="checkTime" [(ngModel)]="checkTime">
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-6">
      <div class="input-group" id="inputUser">
        <div class="input-group-prepend">
          <span class="input-group-text">Staff Member</span>
        </div>
        <div *ngIf="employeeListLoading" class="inline-spinner">
          <div class="spinner-border" style="width:40px; height: 40px;" role="status">
            <span class="sr-only"></span>
          </div>
        </div>

        <select *ngIf="!employeeListLoading && !usingLoggedInName" class="form-control drop-down-purple" name="user"
          [(ngModel)]="employeeId">
          <option></option>
          <option *ngFor="let dropDownItem of employeeList" [ngValue]="dropDownItem.ActorId">
            {{dropDownItem.Forename}} {{dropDownItem.Surname}}</option>
        </select>
        <select *ngIf="!employeeListLoading && usingLoggedInName" class="form-control drop-down-purple" name="user"
          [(ngModel)]="loggedInUserID">
          <option [ngValue]="loggedInUserID" selected="selected"> {{loggedInName}}</option>
        </select>
      </div>
    </div>

    <div class="col-md-6">
      <div class="input-group" id="inputMachineNo">
        <div class="input-group-prepend">
          <span class="input-group-text">Machine No.</span>
        </div>
        <input placeholder="Type Here" type="text" class="form-control color-bacta-standard" name="machineNumber" [(ngModel)]="machineNumber">
      </div>

    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <div class="input-group" id="inputMachineIssue">
        <div class="input-group-prepend">
          <span class="input-group-text">Machine Issue</span>
        </div>
        <div *ngIf="machineIssueListLoading" class="inline-spinner">
          <div class="spinner-border" style="width:40px; height: 40px;" role="status">
            <span class="sr-only"></span>
          </div>
        </div>
        <select *ngIf="!machineIssueListLoading" class="form-control drop-down-purple" name="machineIssue"
          [(ngModel)]="machineIssue">
          <option></option>
          <option *ngFor="let dropDownItem of machineIssueList" [ngValue]="dropDownItem.TagId">
            {{dropDownItem.Name}}</option>
        </select>
      </div>
    </div>

    <div class="col-md-6">
      <div class="input-group" id="inputMachineResolution">
        <div class="input-group-prepend">
          <span class="input-group-text">Machine Resolution</span>
        </div>
        <div *ngIf="machineResolutionListLoading" class="inline-spinner">
          <div class="spinner-border" style="width:40px; height: 40px;" role="status">
            <span class="sr-only"></span>
          </div>
        </div>
        <select *ngIf="!machineResolutionListLoading" class="form-control drop-down-purple" name="machineIssue"
          [(ngModel)]="machineResolution">
          <option></option>
          <option *ngFor="let dropDownItem of machineResolutionList" [ngValue]="dropDownItem.TagId">
            {{dropDownItem.Name}}</option>
        </select>
      </div>
    </div>
    <div class="col-md-6">
      <div class="input-group" id="inputEngineerFirstname">
        <div class="input-group-prepend">
          <span class="input-group-text">Engineer Firstname</span>
        </div>
        <input placeholder="Type Here" type="text" class="form-control color-bacta-standard" name="engineerFirstname"
          [(ngModel)]="engineerFirstname">
      </div>
    </div>
    <div class="col-md-6">
      <div class="input-group" id="inputEngineerSurname">
        <div class="input-group-prepend">
          <span class="input-group-text">Engineer Surname</span>
        </div>
        <input placeholder="Type Here" type="text" class="form-control color-bacta-standard" name="engineerSurname"
          [(ngModel)]="engineerSurname">
      </div>
    </div>
  </div>

  <!-- <div class="row">
    <div class="col-md-6">
      <div class="flex-check-box">
        <div class="flex-check-prepend large">Check OK?</div>
        <div class="checkbox-label">
          <span>Yes</span>
          <input id="chkTime" type="checkbox" class="flex-check-box-control" [(ngModel)]="checkOKTrue"
            (click)="checkItem(true)">
        </div>
        <div class="checkbox-label">
          <span>No</span>
          <input id="chkTime" type="checkbox" class="flex-check-box-control" [(ngModel)]="checkOKFalse"
            (click)="checkItem(false)">
        </div>
      </div>
    </div>
  </div> -->

  <div class="row" style="min-height:200px; padding-top: 20px;">
    <div class="col-md-12">
      <span>Please add any details about the check</span>
      <div
        style="background-color: #223464;padding: 10px; padding-top: 0px !important; border: 2px solid; border-radius: 0.5em;">
        <div class="row">
          <span style="color:white;"><b>Notes</b></span>
        </div>
        <div class="row">

          <!--[(ngModel)]="interaction.Notes"-->
          <textarea id="ctrlNotes" style="width:100%; height: 150px; padding-left:5px; padding-right: 5px;"
            [(ngModel)]="notes"></textarea>
        </div>
      </div>
    </div>
  </div>

  <div class="form-footer">
    <div class="navigation" (click)="GoBack()">
      <span><input type="image" class="stage-button" src=".\assets\icons\Bacta Assets\Back button blue.svg"
          style="width:100px" /></span>
    </div>
    <!-- 
      <div *ngIf="stage===2" class="navigation-warning" style="text-align: center; vertical-align:middle; height: 100%;">
          <input id="btnStartSelfExclusion" class="bacta-button" type="image" src=".\assets\icons\Bacta Assets\Start Self-exclusion orange.svg" alt="Settings" (click)="StartSelfExclusion();" />
      </div>   -->
    <div class="navigation save" (click)="saveCheck()">
      <input type="image" class="stage-button" src="../../../assets/icons/Bacta Assets/Save Interaction green.svg"
        style="width:100px" />

    </div>
  </div>
</div>
<div class="notification-dialog--overlay" *ngIf="isSaving">
  <div class="saving-dialog--spinner">
    <div class="dialog-spinner">
      <div class="text">
        Saving...
      </div>
      <div class="spinner-border" style="width:100px; height: 100px;" role="status">
        <span class="sr-only"></span>
      </div>
    </div>
  </div>
</div>

<div class="notification-dialog--overlay" *ngIf="isSavedSuccess">
  <div class="message-wrapper">
    <div class="message-wrapper--header">
      <div class="text">Success</div>
      <div class="close">X</div>
    </div>
    <div class="message-wrapper--body">
      Saved Machine Service Visit
    </div>
  </div>
</div>

<div class="notification-dialog--overlay" *ngIf="isError">
  <div class="message-wrapper error">
    <div class="message-wrapper--header error">
      <div class="text">Error</div>
      <div class="close" (click)="closeNotification()">X</div>
    </div>
    <div class="message-wrapper--body">
      There was an error saving the machine service visit. Please check all fields and try again.
      <br>
      {{errorMsg | json}}
    </div>
  </div>
</div>