<div *ngIf="!showEmployeeData"  class="container global-seperator">
   <div class="row">
        <div class="col-md-6">
            <div class="input-group" *ngIf="adminsrv.IsAdmin">
                <div class="input-group-prepend">
                    <span class="input-group-text">Organisation</span>
                </div>
                <select class="form-control sort-by-control drop-down-purple" name="Filter" [(ngModel)]="adminsrv.ActiveOrganisationFilterId" (ngModelChange)="FilterOrganisations();">
                    <option [ngValue]="0">All</option>
                    <option [ngValue]="organisation.OrganisationId" *ngFor="let organisation of adminsrv.OrganisationFilter">{{organisation.OrganisationName}}</option>
                </select>  
            </div>
        </div>
        <div class="col-md-6">
            <div class="form-group" style="width: 100%; text-align:right; margin-top:10px;">
                <button type="button" class="btn btn-success" (click)="AddNewRecord();">Add New User</button>
            </div>
        </div>  
    </div>
    <div class="row">
        <div class="col-md-6">
            <div class="flex-check-box">
                <div class="flex-check-prepend">Show Suspended</div>
                <input id="chkTakeABreak" type="checkbox" class="flex-check-box-control" [(ngModel)]="showSuspended" (ngModelChange)="FilterOrganisations();">
            </div>  
        </div>
    </div>
    <div class="row">
        <div *ngIf="!showEmployeeData" #table class="table-responsive bacta-table-outline"> 
            <table #table class="table">
                <thead class="table-header">
                    <th class="table-header-inner bacta-table-header-text" *ngFor="let key of objectKeys">{{key}}</th>
                </thead>
                <tbody class="table-row" *ngFor="let row of object, index as i">
                    <td class="table-row-inner" *ngFor="let x of tableKeys" (click)="ShowEmployee(row, $event);">
                        <span *ngIf="row[x]">{{row[x]}}</span>
                        <div *ngIf="row[x] === false" class="spinner-border" role="status">
                            <span class="sr-only"></span>
                        </div>        
                    </td>            
                </tbody>
            </table>
        </div>
    </div>
</div>

<div *ngIf="showEmployeeData">
    <app-edit-user (CloseEditForm)="CloseEditForm();"></app-edit-user>
</div>