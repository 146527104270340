import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { ExcluderService } from 'src/app/services/excluder.service';
import { NonBiologicalName } from 'src/app/models/actor/Name';
import { ActorService } from 'src/app/services/actor.service';

@Component({
  selector: 'app-site-shopping-basket',
  templateUrl: './site-shopping-basket.component.html',
  styleUrls: ['./site-shopping-basket.component.scss']
})
export class SiteShoppingBasketComponent implements OnInit {
  showSpinner: boolean;
  @Input('showSpinner') get ShowSpinner(): boolean {
    return this.showSpinner;
  };
  @Output('showSpinner') showSpinnerChange: EventEmitter<boolean> = new EventEmitter();
  set ShowSpinner(val: boolean) {
    this.showSpinner = val;
    this.showSpinnerChange.emit(this.showSpinner);
  }

  public sites: NonBiologicalName[] = [];
  public sortAscend: boolean = true;

  constructor(
    public esrv: ExcluderService
  ) { }

  ngOnInit() {
  }
}
