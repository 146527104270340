import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { ActorService } from 'src/app/services/actor.service';
import { AdditionalHTML } from 'src/app/models/Additional_HTML'

@Component({
  selector: 'app-customer-acknowledgement',
  templateUrl: './customer-acknowledgement.component.html',
  styleUrls: ['./customer-acknowledgement.component.scss']
})
export class CustomerAcknowledgementComponent implements OnInit {

  html: string;
  @Input('html') get Html(): string {
    this.FixPage();
    return this.html;
  };
  @Output('html') htmlChange: EventEmitter<string> = new EventEmitter();
  set Html(val: string) {
    this.html = val;
    this.htmlChange.emit(this.html);
  }

  additionalHTML: AdditionalHTML[];
  @Input('additionalHTML') get AdditionalHTML(): AdditionalHTML[] {
    return this.additionalHTML;
  };
  @Output('additionalHTML') additionalHTMLChange: EventEmitter<AdditionalHTML[]> = new EventEmitter();
  set AdditionalHTML(val: AdditionalHTML[]) {
    this.additionalHTML = val;
    this.additionalHTMLChange.emit(this.additionalHTML);
  }

  constructor(public asrv: ActorService) { }

  ngOnInit() {
    this.FixPage();
  }

  FixPage() {
    document.getElementById("acknowledgement").innerHTML = this.html;
    if (this.additionalHTML) {
      this.additionalHTML.forEach(obj => {
        document.getElementById(obj.DOMElement).innerHTML = obj.HTML;
      })
    }
    let bactaLink = document.querySelectorAll('[bactaLink]');
    bactaLink.forEach(link => {
      if (link.getAttribute('bactalink') === 'summaryOfPrivacyPolicy') {
        link.addEventListener('click', this.CallSummaryOfPrivacyPolicy.bind(this));
        link.innerHTML = '<b><u style="color:blue;cursor:pointer;">bactaexclusions.maximws.net/summaryofprivacypolicy</u></b>';
      }

      if (link.getAttribute('bactalink') === 'summaryOfTerms') {
        link.addEventListener('click', this.CallSummaryOfTerms.bind(this));
        link.innerHTML = '<b><u style="color:blue;cursor:pointer;">bactaexclusions.maximws.net/summaryOfTerms</u></b>';
      }

      if (link.getAttribute('bactalink') === 'privacyPolicy') {
        link.addEventListener('click', this.CallPrivacyPolicy.bind(this));
        link.innerHTML = '<b><u style="color:blue;cursor:pointer;">bactaexclusions.maximws.net/privacyPolicy</u></b>';
      }

      if (link.getAttribute('bactalink') === 'terms') {
        link.addEventListener('click', this.CallTerms.bind(this));
        link.innerHTML = '<b><u style="color:blue;cursor:pointer;">bactaexclusions.maximws.net/terms</u></b>';
      }
    })
  }

  CallSummaryOfPrivacyPolicy() {
    this.html = this.asrv.UserDocumentation.SummaryOfPrivacyPolicyHTML;
    this.FixPage();
  }

  CallSummaryOfTerms() {
    this.html = this.asrv.UserDocumentation.SummaryOfTermsHTML;
    this.FixPage();
  }

  CallPrivacyPolicy() {
    this.html = this.asrv.UserDocumentation.PrivacyPolicyHTML;
    this.FixPage();
  }

  CallTerms() {
    this.html = this.asrv.UserDocumentation.TermsHTML;
    this.FixPage();
  }

  CallCloseForm() {
    this.CloseForm.next();
  }
  @Output() CloseForm = new EventEmitter<string>();

/*
else if (!this.exclusion.ReadTCs) {
alert('You Must Read and Agree With The Terms And Conditions Before Proceeding!');
*/

}
