<div class="container global-seperator">
    <div class="col-12">
        <div class="row">
            <div class="col-6">
                <div class="input-group">
                    <div class="input-group-prepend">
                        <span class="input-group-text">Organisation</span>
                    </div>
                    <!--Dropdown-->
                    <input type="text" class="form-control">
                </div>
            </div>
        </div>

        <!--Only show in tabular form on medium devices or above-->
        <div class="row">
            <div class="col-4">
                Field
            </div>
            <div class="col-3">
                Display
            </div>
            <div class="col-3">
                Mandatory
            </div>
            <div class="col-2">
                <!--blank-->
            </div>
        </div>
    
        <!--ngfor each row for in datagrid object-->
        <div class="row">
            <div class="col-4">
                [(actor.dataService.field)]
            </div>
            <div class="col-3">
                [(actor.dataService.display)]
            </div>
            <div class="col-3">
                [(actor.dataService.mandatory)]
            </div>
            <div class="col-2">
                <!--Img Button-->
                <!--editOrganisation()-->
            </div>
        </div>  
        <!--End-->
    </div>  
</div>