import { Component, OnInit } from '@angular/core';
import { ActorService } from 'src/app/services/actor.service';
import { Honorific } from 'src/app/models/Honorific';
import { Gender } from 'src/app/models/Gender';
import { ActivatedRoute, Router } from '@angular/router';
import { Organisation } from 'src/app/models/Organisation';
import { DataService } from 'src/app/services/data.service';
import { UserDocumentation } from 'src/app/models/UserDocumentation';
import { InteractionService } from 'src/app/services/interaction.service';
import { ErrorhanderService } from 'src/app/services/errorhander.service';

@Component({
  selector: 'app-interaction-new',
  templateUrl: './interaction-new.component.html',
  styleUrls: ['./interaction-new.component.scss']
})
export class InteractionNewComponent implements OnInit {

  constructor(public isrv: InteractionService, public asrv: ActorService, private router: Router, private route: ActivatedRoute, public dsrv: DataService, public errsrv: ErrorhanderService) { }
  public showPage: boolean = false;
  public showSaveStateSpinner: boolean = false;
  public honorifics: Honorific[];
  public genders: Gender[];
  // public interaction: CustomerInteraction;
  public stage: number = 0;
  public showForwardArrow: boolean = true;

  public userDocumentation: UserDocumentation = this.dsrv.GetUserDocumentationVersions();
  public organisations: Organisation[] = [];
  public privacyPolicyHTML: string;
  public summaryPrivacyPolicyHTML: string;
  public formMessage: string = "New Interaction";

  ngOnInit() {
    if (!this.router.url.toString().includes('continue')) {
      this.isrv.StartNewInteraction();
      this.LoadSecondaryData();
    } else {
      this.isrv.Load(+this.route.snapshot.params['id']).then(value => {
        this.LoadSecondaryData();
      })
    }
  }

  LoadSecondaryData() {
    this.showPage = true;
    // this.interaction = new CustomerInteraction();
    this.asrv.GetHonorifics().then(value => {
      this.honorifics = value;
    });
    this.asrv.GetGenders().then(value => {
      this.genders = value;
    });

    let myOrganisationsSubscription = this.asrv.GetMyOrganisations().subscribe( orgs => {
      this.organisations = orgs;
      myOrganisationsSubscription.unsubscribe();

      let privacyPolicyHTMLSubscription = this.asrv.GetDocument(this.organisations[0].Organisations_ActorId, this.userDocumentation.PrivacyPolicy, this.userDocumentation.PrivacyPolicyVersion).subscribe(res => {
        this.privacyPolicyHTML = res;
        privacyPolicyHTMLSubscription.unsubscribe();
      }, err => {
        privacyPolicyHTMLSubscription.unsubscribe();
      })
    }, err => {
      myOrganisationsSubscription.unsubscribe();
    });
  }

  GoBack() {
    if (this.stage > 0) {
      if (this.stage === 6 && !this.isrv.HasIconImage())  {
        this.stage--;
      }
      this.stage--;
    } else {
      this.router.navigate(['/user/customer-interaction/gallery']);
    }
  }

  GoForward() {
    if (this.stage === 7) {
      this.SaveCustomerInteraction();
    } else if (this.stage === 4 && !this.MediaIsValid()) {
      this.errsrv.ShowValidationOops('No image!', 'You must supply a photo of the customer or select an icon to proceed.');
    } else if (this.stage === 3 && !this.DetailsAreValid()) {
      // halt
      // has it's own error messages
    } else if (this.stage === 2 && (!this.isrv.HasAction() && !this.isrv.Interaction.NoActionTaken)) {
      this.errsrv.ShowValidationOops('You have not selected an Action!', 'You must select at least one action before you can proceed.');
    } else if (this.stage === 1 && !this.isrv.ReadPrivacyNotice) { 
      this.errsrv.ShowValidationOops('Missing required field!', 'Please read and confirm that you have read the privacy notice.');
    } else {
      this.stage++;
      // Skip Description if the interaction does not use an Icon Image
      if (this.stage === 5 && !this.isrv.HasIconImage())  {
        this.stage++;
      }
      window.scroll(0,0);
    }
  }

  StartSelfExclusion() {
    // Send interaction here.
    this.router.navigate(['/user/exclusion/new']);
  }

  SaveCustomerInteraction() {
    this.isrv.Save(true).then(value => {
      this.stage++;
    });
  }

  SaveCustomerInteractionState() {
    this.showSaveStateSpinner = true;
    this.isrv.SaveProgress(this.stage).then(value => {
      this.showSaveStateSpinner = false;
    })
  }

  MediaIsValid(): boolean {
    if (!this.isrv.Interaction.MediaModelCollection.some(i => i.MediaClassKeyId === 4)) {
      return false;
    }

    let mediaId = this.isrv.Interaction.MediaModelCollection.find(i => i.MediaClassKeyId === 4).MediaId;
    let imgString = this.isrv.Interaction.MediaModelCollection.find(i => i.MediaClassKeyId === 4).Data;
    if (!(mediaId > 0 && mediaId <= 100) && imgString === this.isrv.whiteSpaceImage) {
      return false;
    }

    return true;
  }

  DetailsAreValid(): boolean {
    let biologicalModel = this.isrv.Interaction.InteractedActorCompositeModel.BiologicalActorCompositeModel;

    if (!biologicalModel.GenderId) {
      this.errsrv.ShowValidationOops('No gender selected!', 'You must supply the customers gender.');
      return false;
    }    
    return true;
  }

  NameIsValid(name: string): boolean {
    return (/\d/.test(name) || /\s/g.test(name)) && (name.length >= 2);
  }

  ShowHideForwardArrow() {
    this.showForwardArrow = !this.showForwardArrow;
  }

  ShowSaveProgressButton(): boolean {
    return ((this.stage === 0) || (this.stage > 1 && this.stage < 6))  && (this.showForwardArrow)
  }
}
