import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-upload-cctv',
  templateUrl: './upload-cctv.component.html',
  styleUrls: ['./upload-cctv.component.scss']
})
export class UploadCctvComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
