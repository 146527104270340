export class Post_InteractionReferenceCompositeModel {
    ReferenceCollection: string[] = [];
    constructor(public InteractionIdCollection: number[]) {
    }
}

export interface View_InteractionReferenceCompositeModel {
    InteractionId: number;
    ActorReferenceModelCollection: ActorReferenceModelCollection[];
}

interface ActorReferenceModelCollection {
    ActorId: number;
    Reference: string;
}