export class BackgroundColor {
    // Standard UI background colors
    public readonly DarkPurple: string = '#223464';
    public readonly Beige: string = '#fceee3';
    public readonly LightBlue: string = '#ebf6f6';
    public readonly DarkBlue: string = '#5e698e';

    // Used on Customer Cards
    public readonly Red: string = '#d11723';
    public readonly Yellow: string = '#f9b03b';
    public readonly Green: string = '#65b42d';
    public readonly CyanBlue: string = '#009fe3';
    public readonly Purple: string = '#7164aa';
    public readonly MagentaPink: string = '#e5097e';
    public readonly Grey: string = '#9d9d9c';
    public readonly White: string = 'white';
    
}