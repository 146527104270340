export class SiteDetail {
    EmployeeIdCollection: number[] = [];
    EmployeeCollection: EmployeeRow[] = [];
    ActorId: number;
    Organisation_ActorId: number;
    Organisation_Name: String;
    Name: String;
    Country: String;
    County: String;
    Line1: String;
    Line2: String;
    Postcode: string;
    TownCity: String;
    Latitude: number;
    Longitude: number;
    PostalAddressClassId: number;
    ContactIdCollection: number[] = [];
    TagIdCollection: number[] = [];
    SettingCollection: [] = [];
    ActorReferenceCollection: [] = [];   
}

export class EmployeeRow {
    Id: number;
    Name: string;
    Contactable: boolean;
}
  