import { Views_BiologicalActorTraitModel } from './Views_BiologicalActorTraitModel';

export class Views_BiologicalActorCompositeModel {
    Alias: string;
    BiologicalActorTraitModelCollection: Views_BiologicalActorTraitModel[] = [];
    BirthDate: Date;
    Forename: string;
    GenderId: number;
    HonorificId: number;
    Surname: string;
    PostalAddressClassId: number;
    Country: string;
    County: string;
    Line1: string;
    Line2: string;
    Postcode: string;
    TownCity: string;
    Landline: string;
    Mobile: string;
    Email: string;
}


