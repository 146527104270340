import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { ExcluderBrief } from '../models/ExcluderBrief';
import { ISDDateMethods } from '../models/ISDDateMethods';
import { ActorService } from './actor.service';
import { ExclusionDurationOption } from '../models/ExclusionDurationOption';
import { PostObjects } from '../models/PostObjects';
import { DropDownItem } from '../models/controllers/Standard Controllers/Select';
import { ActorRole } from '../models/ActorRole';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class ExcluderGalleryService {
  public ExcluderBriefs: ExcluderBrief[] = [];
  public ExcluderBriefsToShow: ExcluderBrief[] = [];
  public ExcluderBriefsCurrentlyVisible: ExcluderBrief[] = [];
  public DurationOptions: ExclusionDurationOption[] = [];
  public Limit: number = 9; //Constant for now
  public EventHandler: GalleryEventsHandler = new GalleryEventsHandler(this.asrv);
  public Settings: Settings = new Settings();
  public MaximumPageCount: number;
  public LoadingCards: boolean;

  public ActiveOrganisationFilterId: number;
  public ActiveSiteFilterId: number;
  public IsAdmin: boolean = false;
  public ActorRole: ActorRole;
  public SiteList: DropDownItem[];
  public SiteListToShow: DropDownItem[];
  public OrganisationList: DropDownItem[];
  public FirstLoad: boolean;
  public reinstatementItems = [];
  public reinstatementItemstoShow = [];

  public CurrentPage: number = 0;
  private ExcluderIdsToShow: number[] = [];

  public initRunning = false;

  public galleryLoading = false;

  private destroy = new Subject();

  constructor(public dsrv: DataService, public asrv: ActorService, private dateMethods: ISDDateMethods) {
    if (sessionStorage.getItem('OrganisationFilter')) {
      this.ActiveOrganisationFilterId = +sessionStorage.getItem('OrganisationFilter');
    } else {
      this.ActiveOrganisationFilterId = 0;
    }
    if (sessionStorage.getItem('SiteFilter')) {
      this.ActiveSiteFilterId = +sessionStorage.getItem('SiteFilter');
    } else {
      this.ActiveSiteFilterId = 0;
    }
    this.FirstLoad = true;
  }

  public Initialize(): Promise<boolean> {
    this.initRunning = true;
    this.CheckUserIsAdmin();
    return this.GetExcluderCards();
  }

  public GetExcluderCards(): Promise<boolean> {
    this.destroy.next();
    this.destroy.complete();

    this.ExcluderBriefs = [];
    this.ExcluderBriefsToShow = [];
    this.ExcluderBriefsCurrentlyVisible = [];
    this.ExcluderIdsToShow = [];
    this.CurrentPage = 0;
    this.LoadingCards = true;
    return new Promise<boolean>(resolve => {
      if (this.FirstLoad) {
        this.GetExcuderDurationOptions().then(value => {
          this.DurationOptions = value;
        })
      }
      this.GetExcluders(0).then(value => {
        this.GetActiveExcluderIds();
        this.ViewActiveExcluders();
        this.SortByDateAscending();
        this.LoadPage();
        this.MaximumPageCount = Math.ceil(this.ExcluderBriefsToShow.length / this.Limit);
        this.LoadingCards = false;
        resolve(true);
        this.initRunning = false;
      });
    })
  }

  CheckUserIsAdmin() {
    if (!this.OrganisationList) {
      this.asrv.GetActorRole().then(value => {
        this.ActorRole = value;
        this.SiteList = [];
        this.SiteListToShow = [];
        this.OrganisationList = [];
        this.IsAdmin = this.ActorRole.ActorRoleId <= 3 ? true : false;
        if (this.ActorRole.ActorRoleId <= 4) {
          this.asrv.GetOrganisationIdsDBSync(0, 0).then(value => {
            this.PopulateOrganisationList(value);
          });
        } else {
          this.PopulateSiteList();
        }
      })
    };
  }

  PopulateOrganisationList(organisationIds: number[]): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      this.asrv.GetOrganisationNameCollection(organisationIds).then(value => {
        value.forEach(object => {
          this.OrganisationList.push({ Key: object.ActorId, Value: object.Name });
        })
        this.OrganisationList.sort((a, b) => {
          let x = a.Value.localeCompare(b.Value);
          return x;
        })
        this.PopulateSiteList().then(value => {
        });
        resolve(true);
      })
    })
  }

  PopulateSiteList(): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      this.asrv.GetSiteIds(0, 0, this.ActiveOrganisationFilterId).then(value => {
        value.forEach(id => {
          this.SiteListToShow.push({ Key: id, Value: null });
        })
        this.asrv.GetSiteNameCollection(value).then(value => {
          this.SiteListToShow = [];
          value.forEach(siteName => {
            this.SiteListToShow.push({ Key: siteName.ActorId, Value: siteName.Name });
          })
          this.SortSiteListBySiteNameAscending();
          resolve(true);
        })
      })
    });
  }

  SortSiteListBySiteNameAscending() {
    this.SiteListToShow.sort((a, b) => {
      let x = a.Value.localeCompare(b.Value);
      if (x == 0 && !this.IsAdmin) {
        return a.Value.localeCompare(b.Value);
      }
      return x;
    });
  }

  private ViewActiveExcluders() {
    this.ExcluderIdsToShow.forEach(id => {
      this.ExcluderBriefsToShow.push(this.ExcluderBriefs.find(i => i.InteractionId === id));
    });
    this.reinstatementItems.forEach(id => {
      this.reinstatementItemstoShow.push(this.ExcluderBriefs.find(i => i.InteractionId === id));
    })
  }

  public GetExcluders(offset: number): Promise<boolean> {
    console.log('%c Gallery Loading Started', 'background: #222; color: #bada55');
    this.galleryLoading = true;
    return new Promise<boolean>(resolve => {
      let object = new PostObjects();
      if (!this.FirstLoad) {
        if (this.IsAdmin) {
          if (this.ActiveOrganisationFilterId > 0) {
            object.Model.OrganisationParameters.OrganisationIdCollection.push(this.ActiveOrganisationFilterId);
            sessionStorage.setItem('OrganisationFilter', this.ActiveOrganisationFilterId.toString());
          } else {
            // object.Model.OrganisationParameters.OrganisationIdCollection.push(this.asrv.UserOrganisationId);
            sessionStorage.setItem('OrganisationFilter', "0");
          }
        } else {
          object.Model.OrganisationParameters.OrganisationIdCollection.push(this.asrv.UserOrganisationId);
          sessionStorage.setItem('OrganisationFilter', "0");
        }

        if (this.ActiveSiteFilterId > 0) {
          object.Model.SiteParameters.SiteIdCollection.push(this.ActiveSiteFilterId);
          sessionStorage.setItem('SiteFilter', this.ActiveSiteFilterId.toString());
        } else {
          sessionStorage.setItem('SiteFilter', "0");
        }
      } else {
        sessionStorage.setItem('OrganisationFilter', "0");
        sessionStorage.setItem('SiteFilter', "0");
        this.FirstLoad = false;
      }

      object.Model.Offset = 1000 * offset;
      this.dsrv.Post<any>("api/view_ExclusionInteractionGalleryCompositeModel", object.Model).pipe(takeUntil(this.destroy)).subscribe(res => {
        console.log('%c Gallery Loading Finished', 'background: #222; color: #bada55');
        this.galleryLoading = false;
        res.forEach(brief => {
          this.ExcluderBriefs.push(brief);
        });
        if (res.length === 1000) {
          this.GetExcluders(offset + 1).then(value => {
            resolve(true);
          })
        } else {
          resolve(true);
        }
      }, err => {
        let returnBrief = new ExcluderBrief();
        let returnBriefs = [];
        returnBrief.InteractionId = -1;
        returnBriefs.push(returnBrief);
        resolve(true);
      });
    })
  }

  private GetExcuderDurationOptions(): Promise<ExclusionDurationOption[]> {
    return new Promise<ExclusionDurationOption[]>(resolve => {
      this.asrv.ExclusionDurationOptions().subscribe(res => {
        this.DurationOptions = res;
        resolve(res);
      })
    });
  }

  private GetActiveExcluderIds() {
    this.reinstatementItems = [];
    var now = this.dateMethods.ConvertDateToInteger(new Date());
    this.ExcluderBriefs.forEach(exclusion => {
      
      var start = this.dateMethods.ConvertDateToInteger(exclusion.Start);
      if (start < now) {
        
        if (exclusion.Extensions && !(this.dateMethods.ConvertDateToInteger(this.dateMethods.AddDays(exclusion.Start, exclusion.PeriodDays)) > now)) 
        {
          // console.log(exclusion, exclusion.Reinstatement, exclusion.InteractionId);
          const _reinstatementDate = moment(exclusion.Reinstatement.Accepted);
          const _now = moment();
          const _dateDiff = _now.diff(_reinstatementDate, 'hours');

          // console.log(_dateDiff, exclusion.InteractionId);
          if (exclusion.Reinstatement && _dateDiff < 24) {
            // this.ExcluderIdsToShow.push(exclusion.InteractionId);
            this.reinstatementItems.push(exclusion.InteractionId);
          }
        } else {
          this.ExcluderIdsToShow.push(exclusion.InteractionId);
          
        }
      }
    });
    // this.ExcluderIdsToShow.unshift(..._reinstatementItems);
  }

  private LoadPage() {
    this.ExcluderBriefsCurrentlyVisible = [];
    let firstItem = this.CurrentPage * this.Limit;
    let lastItem = ((this.CurrentPage + 1) * this.Limit);
    for (let currentItem = firstItem; currentItem < lastItem; currentItem++) {
      if (this.ExcluderBriefsToShow[currentItem]) {
        this.ExcluderBriefsCurrentlyVisible.push(this.ExcluderBriefsToShow[currentItem])
      }
    }
  }

  NextPage() {
    this.CurrentPage = this.CurrentPage + 1;
    this.LoadPage();
  }

  PreviousPage() {
    this.CurrentPage = this.CurrentPage - 1;
    this.LoadPage();
  }

  SortByDateAscending() {
    this.ExcluderBriefsToShow.sort((a, b) => {

      if (a.Start === b.Start) {
        if (a.InteractionId < b.InteractionId) {
          return 1;
        } else {
          return -1;
        }
      }

      if (new Date(a.Start) < new Date(b.Start)) {
        return 1;
      }

      if (new Date(a.Start) > new Date(b.Start)) {
        return -1;
      }

      return 0;
    });
    this.ExcluderBriefsToShow.unshift(...this.reinstatementItemstoShow);
    this.LoadPage();
  }

  SortByDateDescending() {
    this.ExcluderBriefsToShow.sort((a, b) => {
      if (a.Start === b.Start) {
        if (a.InteractionId > b.InteractionId) {
          return 1;
        } else {
          return -1;
        }
      }

      if (new Date(a.Start) > new Date(b.Start)) {
        return 1;
      }

      if (new Date(a.Start) < new Date(b.Start)) {
        return -1;
      }

      return 0;
    });
    this.ExcluderBriefsToShow.unshift(...this.reinstatementItemstoShow);
    this.LoadPage();
  }

  SortByNameAscending() {
    this.ExcluderBriefsToShow.sort((a, b) => {
      let x = a.Surname.localeCompare(b.Surname);
      if (x == 0) {
        return a.Forename.localeCompare(b.Forename);
      }
      return x;
    });
    this.LoadPage();
  }

  SortByNameDescending() {
    this.ExcluderBriefsToShow.sort((a, b) => {
      let x = b.Surname.localeCompare(a.Surname);
      if (x == 0) {
        return b.Forename.localeCompare(a.Forename);
      }
      return x;
    });
    this.LoadPage();
  }

  ExcluderEndDate(excluder: ExcluderBrief): Date {
    let startDate: Date = new Date(excluder.Start);
    return new Date(this.dateMethods.AddDays(excluder.Start, excluder.PeriodDays));
  }
}

class GalleryEventsHandler {
  constructor(public asrv: ActorService) { }
}

class Settings {
  public OrganisationActorId: number = 0;
  public SitesActorid: number = 0;
  public Limit: number = 1000;
  public Offset: number = 0;

  public Colors: Colors = new Colors();
  constructor() { }
}

class Colors {
  public readonly Red: string = '#d11722';
  public readonly Yellow: string = '#f8b03d';
  public readonly Green: string = '#64b32e';
  public readonly Blue: string = '#24ace2';
}