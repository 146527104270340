import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';


@Component({
  selector: 'app-exclusion-terms',
  templateUrl: './exclusion-terms.component.html',
  styleUrls: ['./exclusion-terms.component.scss', '../generic-form.component.scss']
})
export class ExclusionTermsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
