<div class="container global-seperator">
    <div class="row">
        <div class="col-md-12">
            <h5><b>Customer Details</b></h5>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            {{isrv.GetInteractionUIDate()}}
        </div>
    </div>

    <div class="row">
        <div class="col-md-6">
            <div class="input-group" id="inputTitle">
                <div class="input-group-prepend">
                    <span class="input-group-text">Title</span>
                </div>
                <select class="form-control drop-down-purple color-bacta-standard" name="title" [(ngModel)]="isrv.Interaction.InteractedActorCompositeModel.BiologicalActorCompositeModel.HonorificId">
                    <option></option>
                    <option *ngFor="let hono of honorifics" [ngValue]="hono.HonorificId">{{hono.Name}}</option>
                </select>
            </div>
        </div>
        <div class="col-md-6">
            <div class="input-group" id="inputGender">
                <div class="input-group-prepend">
                    <span class="input-group-text">Gender</span>
                </div>
                <select class="form-control drop-down-purple color-bacta-standard" name="gender" [(ngModel)]="isrv.Interaction.InteractedActorCompositeModel.BiologicalActorCompositeModel.GenderId">
                    <option></option>
                    <option *ngFor="let gender of genders" [ngValue]="gender.GenderId">{{gender.Name}}</option>
                </select>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-6">
            <div class="input-group" id="inputFirstName">
                <div class="input-group-prepend">
                    <span class="input-group-text">First Name</span>
                </div>
                <input type="text" class="form-control color-bacta-standard" name="firstName" [(ngModel)]="isrv.Interaction.InteractedActorCompositeModel.BiologicalActorCompositeModel.Forename">
            </div>
        </div>
        <div class="col-md-6">
            <div class="input-group" id="inputSurname">
                <div class="input-group-prepend">
                    <span class="input-group-text">Surname</span>
                </div>
                <input type="text" class="form-control color-bacta-standard" name="surname" [(ngModel)]="isrv.Interaction.InteractedActorCompositeModel.BiologicalActorCompositeModel.Surname">
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-6">
            <div class="input-group" id="inputMobile">
                <div class="input-group-prepend">
                    <span class="input-group-text">Nickname</span>
                </div>
                <input type="text" class="form-control color-bacta-standard" name="nickname" [(ngModel)]="isrv.Interaction.InteractedActorCompositeModel.BiologicalActorCompositeModel.Alias"> 
            </div>
        </div>
    </div>
</div>