import { Injectable } from '@angular/core';
import { Observable, fromEvent, merge, Subject, timer, Subscription, interval, throwError } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ApiService } from './api.service';
import { DataService } from './data.service';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { ExcluderGalleryService } from './excluder-gallery.service';

@Injectable({
  providedIn: 'root'
})
export class InactivityService {

  currentDate = new Date();
  startOfNextMinute = new Date(
    this.currentDate.getFullYear(),
    this.currentDate.getMonth(),
    this.currentDate.getDate(),
    this.currentDate.getHours(),
    this.currentDate.getMinutes() + 1
  );

  private idle$: Observable<any> = new Observable();
  private idleSubscription$: Subscription = new Subscription();
  private timer$;
  private heartbeat$;
  private modal$;

  // private timer: Subscription = new Subscription();
  // private timer2: Subscription = new Subscription();
  // private timeOutMilliSeconds: number = 1000;
  // private idleSubscription: Subscription = new Subscription();

  public token = '';
  public secretKeyMap = [];
  public memwordChar1 = '';
  public memwordChar2 = '';
  public memwordChar3 = '';

  // public cachedMemwordChar1 = '';
  // public cachedMemwordChar2 = '';
  // public cachedMemwordChar3 = '';

  // public expired: Subject<boolean> = new Subject<boolean>();

  timerX = 0;
  timerY = 0;

  modalTimerX = 0;
  modalTimerY = 0;

  isInactive = true;

  userKicked = false;

  constructor(
    private _ds: DataService,
    private _api: ApiService,
    private _router: Router,
    private _gsrv: ExcluderGalleryService,
  ) {
    // this.startMonitor();
    // this.startTimer();
  }

  startMonitor() {
    if (this.idleSubscription$) {
      this.idleSubscription$.unsubscribe();
    }
    // console.log('Inactivity Monitor Started');
    this.idle$ = merge(
      // fromEvent(document, 'mousemove'),
      // fromEvent(window, 'mousemove'),
      fromEvent(document, 'click'),
      fromEvent(document, 'mousedown'),
      fromEvent(document, 'keypress'),
      fromEvent(document, 'DOMMouseScroll'),
      fromEvent(document, 'mousewheel'),
      fromEvent(document, 'touchmove'),
      fromEvent(document, 'MSPointerMove'),
      fromEvent(window, 'resize'),
    );

    this.idleSubscription$ = this.idle$.subscribe((res) => {
      this.isInactive = false;

    });
  }

  stopMonitor() {
    if (this.idleSubscription$) {
      this.idleSubscription$.unsubscribe();
    }
    // console.log('Inactivity Monitor Stopped');
  }

  startModalTimer() {
    if (this.modal$) {
      this.modal$.unsubscribe();
    }
    // console.log('Modal Timer Started');
    this.modalTimerX = 0;
    this.modalTimerY = 0;
    this.modal$ = timer(0, 1000).subscribe(n => {
      if (!this.isInactive) {
        this.resetModalTimer();
      }
      if (n % 10 === 0) {
        // console.log(`${this.modalTimerY}s elapsed`, this.modalTimerX, this.isInactive);
        if (this.modalTimerX === 5) {
          console.warn('50s elapsed. Modal Timer stopped');
          this.modal$.unsubscribe();
          this.kickUser();
        }
        this.modalTimerX++;
      }
      this.modalTimerY++;
    });
  }

  

  stopModalTimer() {
    if (this.modal$) {
      this.modal$.unsubscribe();
    }
    // console.log('Modal Timer Stopped');
  }

  resetModalTimer() {
    this.modalTimerX = 0;
    this.modalTimerY = 0;
    this.isInactive = true;
  }

  kickUser() {
    this._router.navigate(['/user/features']);
    this._ds.setMemwordError(false);
    this._ds.dataLoaded = false;
    this.userKicked = true;
  }

  startTimer() {
    if (this.timer$) {
      this.timer$.unsubscribe();
    }
    // console.log('Inactivity Timer Started');
    this.timerX = 0;
    this.timerY = 0;
    this.timer$ = timer(0, 1000).subscribe(n => {
      // console.log('timer', n, this.isInactive);
      
      if (n % 10 === 0) {
        if (!this.isInactive || this._gsrv.galleryLoading) {
          this.refreshToken();
          this.resetTimer();
          if (this._gsrv.galleryLoading) {
            console.log('%c Gallery still loading -- resetting timer', 'background: #222; color: #FFBF00');
          }
            
        }
        // console.log(`${this.timerY}s elapsed`, `---`, `Inactive triggered: ${!this.isInactive}`);
        this.checkToken();
        if (this.timerX === 5) {
          // console.warn('50s elapsed. Inactivity Timer stopped');
          this.timer$.unsubscribe();
          if (this.isInactive) {
            this._ds.setMemwordError(true);
          }
        }
        this.timerX++;
      }
      this.timerY++;
    });
  }

  refreshToken() {
    const _postData = JSON.parse(sessionStorage.getItem('__postData'));
    this._api.postSecretKey(_postData).subscribe(
      results => {
        
        // console.log('Token Refreshed');
      },
      error => {
        this.kickUser();
      }
    );
  }

  resetTimer() {
    this.timerX = 0;
    this.timerY = 0;
    this.isInactive = true;
  }

  stopTimer() {
    if (this.timer$) {
      this.timer$.unsubscribe();
    }
    // console.log('Inactivity Timer Stopped');
  }

  startHeartbeat() {
    // console.log('Heartbeat Started');
    let x = 0;
    this.heartbeat$ = timer(0, 1000).subscribe(n => {
      if (n % 10 === 0) {
        // console.warn('Heartbeat Ping', x, this.isInactive);
        if (x === 5) {
          this.heartbeat$.unsubscribe();
        }
        x++;
      }
    });
  }

  stopHeartbeat() {
    if (this.heartbeat$) {
      this.heartbeat$.unsubscribe();
    }
    // console.log('Heartbeat Stopped');
  }

  getNewKey() {
    this.secretKeyMap = [];
    // console.log('+=+= Getting New Key');
    this.memwordChar1 = '';
    this.memwordChar2 = '';
    this.memwordChar3 = '';
    this._api.getSecretKey().subscribe(results => {
      this.token = results.Token;
      const _secretKey = results.SecretKeyCharacterCollection;
      _secretKey.sort((a, b) => (a.Position > b.Position) ? 1 : -1);
      _secretKey.forEach(char => {
        this.secretKeyMap.push(char.Position);
      });
    });
  }

  checkToken() {
    // console.log('*** Checking Token ***');
    const _token = sessionStorage.getItem('__galleryToken');
    if (_token) {
      this._api.getCheckToken(_token).subscribe(
        results => {
        },
        error => {
          this._ds.setMemwordError(true);
          sessionStorage.removeItem('__galleryToken');
        }
      )
    } else {
      this._ds.setMemwordError(true);
    }
  }
  
  killSubscriptions() {
    if (this.heartbeat$) {this.heartbeat$.unsubscribe()}
    if (this.modal$) {this.modal$.unsubscribe()}
    if (this.idleSubscription$) {this.idleSubscription$.unsubscribe()}
    if (this.timer$) {this.timer$.unsubscribe()}
  }
}
