import {Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {UserCredentials} from '../../models/data/UserCredentials';
import {Login as ComponentModel_Login} from '../../models/component/Login';
import {DataService} from 'src/app/services/data.service';
import {Subscription} from 'rxjs'
import { ErrorhanderService } from 'src/app/services/errorhander.service';
import { ActorService } from 'src/app/services/actor.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public showFailedLogin: boolean = false;
  public showSpinner: boolean = false;

  constructor(
    public dsrv: DataService,
    private errorHandler: ErrorhanderService,
    private router: Router,
    public asrv: ActorService
  ) {
    this.UserCredentials = new UserCredentials ();
  }

  public ComponentModel_Login : ComponentModel_Login;
  public UserCredentials : UserCredentials;

  public login() : void {
    this.dsrv.authToken = btoa(`${this.UserCredentials.Login}:${this.UserCredentials.Password}`);
    this.showFailedLogin = false;
    this.showSpinner = true;
    this.asrv.loadGendersToStorage();
    this.asrv.loadHonorificsToStorage();
    let login = this.dsrv.Login(this.UserCredentials.Login, this.UserCredentials.Password).subscribe(res => {
      if (res === 401) {
        this.showFailedLogin = true;
        this.showSpinner = false;
      } else {
        this.showSpinner = false;
        this.router.navigate(['/user']);
      }
      login.unsubscribe();
    }, err => {
      this.errorHandler.CreateParameter('UserCredentials.Login',this.UserCredentials.Login);
      this.errorHandler.CreateParameter('UserCredentials.Password', btoa(this.UserCredentials.Password));
      this.errorHandler.CatchError('login()','login.component.ts', err);
      login.unsubscribe();
    });
  }

  public forgottenPassword() {
    this.router.navigate(['forgottenPassword']);
  }
  
  ngOnInit() {
  }
}