import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd } from '@angular/router';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { ISDDateMethods } from 'src/app/models/ISDDateMethods';
import { ActorService } from 'src/app/services/actor.service';
import { AdvancedSearchService } from 'src/app/services/advanced-search.service';
import { ApiService } from 'src/app/services/api.service';
import { DataService } from 'src/app/services/data.service';
import { ErrorhanderService } from 'src/app/services/errorhander.service';

@Component({
  selector: 'app-age-verification',
  templateUrl: './age-verification.component.html',
  styleUrls: ['./age-verification.component.scss']
})
export class AgeVerificationComponent implements OnInit {

  @ViewChild('dropDownToggle') DropDownToggle: ElementRef;
  @ViewChild('exclusionDropDownToggle') ExclusionDropDownToggle: ElementRef;
  @ViewChild('reportingDropDownToggle') ReportingDropDownToggle: ElementRef;
  @ViewChild('adminDropDownToggle') AdminDropDownToggle: ElementRef;
  @ViewChild('messagingDropDownToggle') MessagingDropDownToggle: ElementRef;
  @ViewChild('advancedSearchToggle') AdvancedSearchToggle: ElementRef;
  @ViewChild('adminToolsDropDownToggle') AdminToolsDropDownToggle: ElementRef;

  @ViewChild('status') status: ElementRef;
  public showStatus: boolean = false;

  ageDate;
  dobDate;
  ageDateModel;
  dobDateModel;
  ageTime;
  challengeType;
  idType;
  checkedIDTrue;
  checkedIDFalse;
  genderList;
  gender;
  wasAskedToLeave = false;
  transactions;
  employeeList;
  isSavedSuccess = false;
  isError = false;
  employeeListLoading = true;
  challengeListLoading = true;
  idTypeListLoading = true;
  genderListLoading = true;
  playListLoading = true;
  notes;
  errorMsg;

  detailsOfChallengeList = [
    {
      Value: 'Challenged on Entry'
    },
    {
      Value: 'Challenged whilst Browsing'
    },
    {
      Value: 'Challenged whilst playing machines'
    },
  ];

  idTypeList = [
    {
      Value: 'Bus Pass'
    },
    {
      Value: 'Driving Licence'
    },
    {
      Value: 'Passport'
    },
    {
      Value: 'Student Card'
    },
    {
      Value: 'Other'
    },
  ];

  siteID;

  transactionsList = [];

  constructor(
    public dsrv: DataService,
    public asrv: ActorService,
    private router: Router,
    public errsrv: ErrorhanderService,
    public assrv: AdvancedSearchService,
    private titleService: Title,
    private _api: ApiService,
    private _snackBar: MatSnackBar
  ) { }

  // Always display on initialise
  public displayFeatures: boolean = false;
  public displayAddNewExcluder: boolean = false;
  public mainWindowHeight: string;
  public unreadMessages: boolean = false;
  public unreadMessageCount: string;
  public readonly FeatureNotReady: boolean = false;

  public logOutSubscription: Subscription;

  public dropDownIsShowing: boolean;
  public exclusionDropDownIsShowing: boolean;
  public reportingDropDownIsShowing: boolean;
  public adminDropDownIsShowing: boolean;
  public messagingDropDownIsShowing: boolean;
  public advancedSearchIsShowing: boolean;
  public adminToolsDropDownIsShowing: boolean;

  private nextCheckForMessage: Date;
  private nextCheckTime: number = 30000;
  private dateMethods: ISDDateMethods = new ISDDateMethods();
  private _dataready: boolean = false;

  public NavigatedId: number;
  public employeeId: any;

  isSaving = false;

  events: string[] = [];

  toastRef;
  usingLoggedInName = false;
  loggedInName = '';
  loggedInUserID = 0;

  openToast(message: string, action: string) {
    this.toastRef = this._snackBar.open(message, action, { duration: 10000 });
  }

  addEvent(type: string, event: MatDatepickerInputEvent<Date>) {
    this.events.push(`${type}: ${event.value}`);
    this.dobDate = moment(this.dobDateModel).format('YYYY-MM-DDTHH:mm:ss:SSS[Z]');
    this.ageDate = moment(this.ageDateModel).format('YYYY-MM-DD');
  }

  showedIDCheck(wasChecked) {
    if (wasChecked) {
      this.checkedIDTrue = true;
      this.checkedIDFalse = false;
      this.wasAskedToLeave = false;
      this.transactions = [];
    } else {
      this.checkedIDTrue = false;
      this.checkedIDFalse = true;
    }
  }



  ngOnInit() {
    document.body.style.backgroundColor = '#f7f9fc';
   
    this.getDetailsOfChallengeList();
    this.getIDTypeList();
    this.getGenderList();
    this.getTransactionsList();
    this.getEmployeeList();

    this.titleService.setTitle('Age Verify');
    this.GetUnreadMessageCount();

    let userSettingsLoaded: boolean = false;
    let actorCompositeModelLoaded: boolean = false;

    // Untangle this
    this.asrv.GetUserSettings().then(value => {
      // console.log(value);

      userSettingsLoaded = true;
      if (userSettingsLoaded && actorCompositeModelLoaded) { this._dataready = true; };

      this.asrv.GetAgreementDocumentation().then(value => {
        if (!value) {
          this.asrv.IsLoggedUserOrganisationAdmin().then(value => {
            if (value) {
              this.errsrv.ShowValidationOops('No Organisation Site Found', 'Please set a site as the organisation headquarters before proceeding. Your organisation will be unable to create any interactions until this is resolved.');
              this.router.navigate(['user/admin/organisation-panel']);
            } else {
              this.errsrv.ShowValidationOops('No Organisation Site Found', 'No organisation headquarters found! Please contact the organisation admin to resolve this. No interactions can be created by this organisation until this is resolved.')
            }
          })
        }
      })
      this.asrv.GetActorCompositeModel().then(value => {
        actorCompositeModelLoaded = true;
        if (userSettingsLoaded && actorCompositeModelLoaded) { this._dataready = true; };
      });
    });

    this.router.events.subscribe(event => {
      // Check Message Ping
      var currentTime = new Date();
      if (currentTime > this.nextCheckForMessage) {
        this.nextCheckForMessage = new Date(this.dateMethods.ConvertDateToInteger(new Date()) + this.nextCheckTime);
        this.GetUnreadMessageCount();
      };

      if (event instanceof NavigationEnd) {
        // Display Footer Buttons Based on the URL
        if (event.url.includes('user/features')) {
          this.displayFeatures = false;
          this.displayAddNewExcluder = false;
        };

        if (!event.url.includes('user/exclusion/new')) {
          this.ClearNewExclusionCache();
        };

        if (event.url.includes('customer-interaction')) {
          document.body.style.backgroundColor = this.asrv.Globals.BackgroundColor.LightBlue;
          document.body.style.color = this.asrv.Globals.BackgroundColor.DarkBlue;
        } else {
          document.body.style.backgroundColor = this.asrv.Globals.BackgroundColor.Beige;
          document.body.style.color = this.asrv.Globals.BackgroundColor.DarkPurple;
        };
      };
    });

    if (window.innerHeight > 820) {
      this.mainWindowHeight = '88vh';
    } else {
      this.mainWindowHeight = '100%';
    };

    this._api.getTagCollectionCompositeModel(50).subscribe(res => {
      // console.log(res);
    });
  }

  setTime(ev) {
    // this.ageDate = moment(this.ageDateModel + ' ' + this.ageTime).format('YYYY-MM-DDTHH:mm:ss:SSS[Z]');
    // console.log(this.ageTime, this.ageDate);
    
    // console.log('Final: ', moment(this.ageDate + ' ' + this.ageTime, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DDTHH:mm:ss:SSS[Z]') );
  }

  getEmployeeList() {
    this.usingLoggedInName = false;
    this.employeeListLoading = true;
    const _userID = this.dsrv.GetMyUserID();
    this.loggedInUserID = _userID;
    // console.log(this.loggedInUserID);
    this._api.getUserOrgID(_userID).subscribe(results => {

      const _orgID = results[0].Organisations_ActorId;
      const _employeeIDs = [];
      this._api.getUserEmployeesList(_orgID).subscribe(employeeResults => {
        employeeResults.forEach(element => {
          _employeeIDs.push(element.Employees_ActorId);
        });

        this.asrv.GetBiologicalName(_employeeIDs).then(value => {
          this.employeeList = value;
          this.employeeListLoading = false;
        });
      }, 
      error => {
        this.usingLoggedInName = true;
        this.loggedInName = this.asrv.GetUsersFullName();
        this.employeeListLoading = false;
      });
    });
  }

  getDetailsOfChallengeList() {
    this.challengeListLoading = true;
    this._api.getTagCollectionCompositeModel(1).subscribe(results => {
      this.detailsOfChallengeList = results.Tags;
      // this.detailsOfChallengeList = results.Tags.map((obj, index) => ({ ...obj, Value: this.detailsOfChallengeList[index].Value }));
      this.challengeListLoading = false;
    });
  }

  setChallengeType() {
    // console.log('Challenge Type TagID: ', this.challengeType);
  }

  getIDTypeList() {
    this.idTypeListLoading = true;
    this._api.getTagCollectionCompositeModel(30).subscribe(results => {
      this.idTypeList = results.Tags;
      // this.idTypeList = results.Tags.map((obj, index) => ({ ...obj, Value: this.idTypeList[index].Value }));
      this.idTypeListLoading = false;
    });
  }

  setIDType() {
    // console.log('ID Type TagID: ', this.idType);
  }

  getGenderList() {
    this.genderListLoading = true;
    this._api.getGenders().subscribe(results => {
      this.genderList = results;
      this.genderListLoading = false;
    });
  }

  setGender() {
    // console.log('Gender TagID: ', this.gender);
  }

  askedToLeave() {
    // console.log('Was asked to leave: ', this.wasAskedToLeave);
  }

  getTransactionsList() {
    this._api.getTagCollectionCompositeModel(50).subscribe(results => {
      this.transactionsList = [
        {
          Name: "",
          TagId: null,
          Value: "No"
        },
        {
          Name: "",
          TagId: [50, 134],
          Value: "Yes, stake returned"
        },
        {
          Name: "",
          TagId: [50, 135],
          Value: "Yes, prize returned"
        },
        {
          Name: "",
          TagId: [50, 134, 135],
          Value: "Yes, stake and prize returned"
        },
      ]
    });
  }

  setTransactions() {
    // console.log(this.transactions);
  }

  saveAgeVerification() {
    console.log(moment(this.ageDate + ' ' + this.ageTime, 'YYYY-MM-DD HH:mm').utc().format('YYYY-MM-DDTHH:mm'));
    console.log(moment(this.ageDate + ' ' + this.ageTime, 'YYYY-MM-DD HH:mm').utc());
    console.log(moment(this.ageDate + ' ' + this.ageTime, 'YYYY-MM-DD HH:mm'));
    const _recordedDate = moment(this.ageDate + ' ' + this.ageTime, 'YYYY-MM-DD HH:mm').utc().format('YYYY-MM-DDTHH:mm');
    this.siteID = this.asrv.DefaultSiteId;
    this.isSaving = true;
    const _tagIDCollection = [];

    // _tagIDCollection.push(this.employeeId);

    if (this.transactions && this.transactions.length > 0) {
      this.transactions.forEach(el => {
        _tagIDCollection.push(el);
      });
    }

    if (this.wasAskedToLeave) {
      _tagIDCollection.push(110);
    }
    if (this.checkedIDTrue) {
      _tagIDCollection.push(this.idType);
    }
    _tagIDCollection.push(this.challengeType);

    this._api.postAgeVerification(
      {
        AssociatedActorIdCollection: [
          this.employeeId
        ],
        InteractedActorCompositeModelCollection: [
          {
            BiologicalActorCompositeModel: {
              BirthDate: this.dobDate,
              GenderId: this.gender,
              PostalAddressClassId: 1,
            }
          }
        ],

        Notes: this.notes,
        SiteId: this.siteID,
        Recorded: _recordedDate,
        TagIdCollection: _tagIDCollection,

      }
    ).subscribe(result => {
      this.isSaving = false;
      this.isSavedSuccess = true;
      this.isError = false;
      setTimeout(() => {     
        this.isSavedSuccess = false;                     
        this.router.navigate(['/user/features']);
      }, 3000);
    }, error => {
      this.isSaving = false;
      this.isError =  true;
      this.errorMsg = error.error;
      console.log(error);
    });
  }

  closeNotification() {
    this.isError = false;
    this.isSavedSuccess = false;
  }

  GetUnreadMessageCount() {
    this.asrv.GetUnreadMessageCount().then(value => {
      this.unreadMessages = value.HasUnreadMesssages;
      if (value.MessageCount > 9) {
        this.unreadMessageCount = '9+'
      } else {
        this.unreadMessageCount = value.MessageCount.toString();
      }
      this.nextCheckForMessage = new Date(this.dateMethods.ConvertDateToInteger(new Date()) + this.nextCheckTime);
    });
  }

  ClearNewExclusionCache() {
    this.dsrv.ClearExclusionProgress();
  }

  ngOnChanges() {
  }

  ToggleFeatureMenu() {
    // If Displaying Feature Collection, hide any buttons
    this.displayFeatures = !this.displayFeatures;
  }

  MenuSelectionMade(event: any) {

  }

  onActivate(componentRef) {
    this.displayAddNewExcluder = true;
  }

  HideDropDowns() {
    if (this.dropDownIsShowing) { this.DropDownToggle.nativeElement.click(); }
    if (this.exclusionDropDownIsShowing) { this.ExclusionDropDownToggle.nativeElement.click(); }
    if (this.reportingDropDownIsShowing) { this.ReportingDropDownToggle.nativeElement.click(); }
    if (this.adminDropDownIsShowing) { this.AdminDropDownToggle.nativeElement.click(); }
    if (this.messagingDropDownIsShowing) { this.MessagingDropDownToggle.nativeElement.click(); }
    if (this.advancedSearchIsShowing) { this.AdvancedSearchToggle.nativeElement.click(); };
    if (this.adminToolsDropDownIsShowing) { this.AdminToolsDropDownToggle.nativeElement.click(); }
    this.dropDownIsShowing = false;
    this.exclusionDropDownIsShowing = false;
    this.reportingDropDownIsShowing = false;
    this.adminDropDownIsShowing = false;
    this.messagingDropDownIsShowing = false;
    this.advancedSearchIsShowing = false;
    this.adminToolsDropDownIsShowing = false;
  }

  // Double click event in place to prevent bar locking.
  DropDownClicked() {
    if (this.advancedSearchIsShowing) {
      this.AdvancedSearchToggle.nativeElement.click();
      this.advancedSearchIsShowing = false;
    }
    this.dropDownIsShowing = !this.dropDownIsShowing;
  }

  ExclusionDropDownClicked() {
    this.exclusionDropDownIsShowing = !this.exclusionDropDownIsShowing;
  }

  ReportingDropDownClicked() {
    this.reportingDropDownIsShowing = !this.reportingDropDownIsShowing;
  }

  AdminDropDownClicked() {
    this.adminDropDownIsShowing = !this.adminDropDownIsShowing;
  }

  MessagingDropDownClicked() {
    this.messagingDropDownIsShowing = !this.messagingDropDownIsShowing;
  }

  AdministrationToolsDropDownClicked() {
    this.adminToolsDropDownIsShowing = !this.adminToolsDropDownIsShowing;
  }

  navigateToFeatureCollection() {
    this.HideOverlayMenu();
    this.HideDropDowns();
    this.router.navigate(['/user/features']);
  }

  NavigateToGallery() {
    this.HideOverlayMenu();
    this.HideDropDowns();
    this.router.navigate(['user/exclusion/gallery']);
  }

  CreateNewExclusion() {
    this.HideOverlayMenu();
    this.HideDropDowns();
    this.router.navigate(['user/exclusion/new']);
  }

  NavigateToReportsBuilder() {
    this.HideOverlayMenu();
    this.HideDropDowns();
    this.router.navigate(['user/reporting']);
  }

  NavigateToUserAdmin() {
    this.HideOverlayMenu();
    this.HideDropDowns();
    this.router.navigate(['user/admin/user-panel']);
  }

  NavigateToSiteAdmin() {
    this.HideOverlayMenu();
    this.HideDropDowns();
    this.router.navigate(['user/admin/site-panel']);
  }

  NavigateToOrganisationAdmin() {
    this.HideOverlayMenu();
    this.HideDropDowns();
    this.router.navigate(['user/admin/organisation-panel']);
  }

  NavigateToMessaging() {
    this.HideOverlayMenu();
    this.HideDropDowns();
    this.router.navigate(['user/messaging']);
  }

  HideOverlayMenu() {
    if (this.displayFeatures)
      this.displayFeatures = false;
  }

  LogOut() {
    this.logOutSubscription = this.dsrv.LogOut().subscribe(res => {
      this.router.navigate(['/#']);
    });
  }

  // Double click event in place to prevent bar locking.
  AdvancedSearchClicked() {
    if (this.dropDownIsShowing) {
      this.DropDownToggle.nativeElement.click();
      this.dropDownIsShowing = false;
    }
    this.assrv.ReinitializeSearchModel();
    this.advancedSearchIsShowing = !this.advancedSearchIsShowing;
  }

  PrintSearchQuery() {
    // this.assrv.PrintSearchQuery()
  }

  Search() {
    this.assrv.Search().then(value => {
      if (this.advancedSearchIsShowing) { this.AdvancedSearchToggle.nativeElement.click(); };
    });
  }

  NewSearch() {
    this.assrv.ReinitializeSearchModel().then(value => {
    });
  }

  ToggleElevationMode() {
    this.asrv.ToggleElevationMode().then(value => { })
  }

  ReloadDropDownControllers() {
    if (this.asrv.ElevatedMode) {
      this.asrv.PopulateSiteSelectForOrgansiationId(this.asrv.UserOrganisationId).then(value => {
        this.asrv.PopulateColleagueSelectForSiteId(this.asrv.SiteSelect.KeySelected).then(value => { })
      });
    } else {
      this.asrv.PopulateDefaultEmployeeSitesSelect().then(value => {
        this.asrv.PopulateColleagueSelectForSiteId(this.asrv.SiteSelect.KeySelected).then(value => { })
      })
    }
  }

  UnlockAllButtons() {
    this.asrv.ToggleLockedButtons().then(value => { })
  }

  GoBack() {
    this.router.navigate(['/user/features']);
  }

}
