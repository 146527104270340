<div id="errorContainer" class="container" style="max-height:90%;height:auto;">
    <div *ngIf="errsrv.httpOops" class="row global-seperator">
        <div class="col-12 text-center">
            <span><b>OOPS...</b></span> we have encountered a <b>{{errsrv.name}}</b> error. 
        </div>
    </div>
    <div *ngIf="errsrv.validationOops" class="row global-seperator">
        <div class="col-12 text-center">
            <span><b>OOPS...</b></span> {{errsrv.header}} 
        </div>
    </div>
    <div *ngIf="errsrv.httpOops" class="row" style="margin-top:40px;">
        <div class="col-3 text-center">
            <p><b>Status Code:</b></p>
        </div>
        <div class="col-9 text-left">
            <p>{{errsrv.status}}</p>
        </div>
    </div>

    <div *ngIf="errsrv.httpOops" class="row" style="margin-top:20px;">
        <div class="col-3 text-center">
            <p><b>Reason:</b></p>
        </div>
        <div class="col-9 text-left" style="word-break:normal; overflow-y: scroll">
            <p>{{errsrv.message}}</p>
        </div>
    </div>

    <div *ngIf="errsrv.validationOops" class="row" style="margin-top:20px;">
        <div class="col-12 text-center" >
            <p>{{errsrv.message}}</p>
        </div>
    </div>

    <div *ngIf="errsrv.showDataBaseCallback" class="row" style="margin-top:20px;">
        <div class="col-3 text-center">
            <p><b>Message:</b></p>
        </div>
        <div class="col-9 text-left">
            <span *ngFor="let message of errsrv.messages" style="word-break:normal; display: block; height: 25px;">
                <p>{{message}}</p><br>
            </span>
        </div>
    </div>

    <!--Rework incoming
    <div *ngIf="errsrv.showDataBaseCallback" class="row" style="margin-top:20px;">
        <div class="col-3 text-center">
            <p><b>Value:</b></p>
        </div>
        <div class="col-9 text-left">
            <span *ngFor="let fieldValue of fieldValues" style="word-break:normal; display: block; height: 25px;">
                <p>{{fieldValue}}</p><br>
            </span>
        </div>
    </div>
    -->
    <div *ngIf="errsrv.showPostObject" class="row" style="margin-top:40px;">
        <div class="col-3 text-center">
            <p><b>Data Submitted:</b></p>
        </div>
        <div class="col-9 text-left break-all" style="max-height:300px; overflow-y:scroll">
            <p>{{lastPostObject}}</p>
        </div>
    </div>

    <div *ngIf="errsrv.httpOops" class="row" style="margin-top:40px;">
        <div class="col-12 text-center">
            Please email the <a href="mailto:support@isdgroup.co.uk">support desk</a> if you require assistance.
        </div>  
    </div>

    <div class="row" style="margin-top:40px; margin-bottom:20px;">
        <!--
        <div class="col-6 text-center">
            <input class="button-standard-format" value="COPY ERROR" (click)="CopyToClipboard()" />
        </div>
        -->
        <div class="col-12 text-center">
            <input class="button-standard-format" value="CLOSE" (click)="HideOops()" />
        </div>  
    </div>
</div>