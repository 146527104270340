export class View_ReinstatementsCompositeModel {
    constructor(
        public InteractionId: number,
        public AssociatedActorIdCollection: number[],
        public Notes: string,
        public Reference: string,
        public SiteId: number,
        public TagIdCollection: number[],
        public Exclusions_InteractionId: number,
        public ReinstatementRequestMethodKeyId: number,
        public Accepted: Date
    ) {
    } 
}