import { Injectable } from '@angular/core';
import { Actor_Settings } from '../models/Actor_Settings';
import { GenericTagList } from '../models/admin/GenericTag';
import { Credentials, CredentialsBrief } from '../models/Credentials';
import { EmployeeDetails } from '../models/EmployeeDetails';
import { SettingCollection } from '../models/SettingCollection';
import { SiteDetail, EmployeeRow } from '../models/SiteDetail';
import { ActorService } from './actor.service';

const retired: number = 1;
const adminlevel: number = 3;
const primarysite: number = 61;
const errorBorderColor: string = 'red';
const defaultBorderColor: string = '#223464';

@Injectable({
  providedIn: 'root'
})
export class AdminService {
  public SiteList: SiteList;
  public SiteListToShow: SiteList;
  public OrganisationList: OrganisationList;
  public UserList: UserList;
  public UserListToShow: UserList;
  public SiteListSiteNameAscended: boolean = false;
  public SiteListOrganisationNameAscended: boolean = true;
  public IsAdmin: boolean = false;
  public UserRoleId: number;

  // Cross App Form Controls
  public creatingNewRecord: boolean;
  public organisationCreateMode: boolean;
  public passwordConfirm: string;
  public site: SiteDetail;
  public siteTags: GenericTagList;
  public employeeDetails: EmployeeDetails;
  public userCredententials: Credentials;

  private readonly defaultBorderColor: string = '#223464';
  private readonly errorBorderColor: string = 'red';
  private readonly primarysitekey: number = 61;

  public OrganisationFilter: OrganisationFilter[] = [];
  public ActiveOrganisationFilterId: number = 0;

  constructor(public asrv: ActorService) {
  }

  SetOrganisationFilter() {
    if (this.ActiveOrganisationFilterId === 0) {
      this.ActiveOrganisationFilterId = this.asrv.DefaultOrganisationNameCollection[0].ActorId
    }
  }

  PopulateSiteList(suspended): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      let tags: number[] = [];
      if (suspended) {
        tags.push(1);
      }
      this.SiteListToShow = new SiteList(this.IsAdmin, this.asrv); 

      this.asrv.GetSiteIds(0, 0, this.ActiveOrganisationFilterId, tags).then(value => {
        value.forEach(id => {
          this.SiteListToShow.AddRow(id, 'Fetching Sitename...', this.ActiveOrganisationFilterId);
        })
        this.asrv.GetSiteNameCollection(value).then(value => {
          value.forEach(siteName => {
            this.SiteListToShow.Rows.find(i => i.SiteId === siteName.ActorId).SiteName = siteName.Name;
          })
          this.SortSiteListBySiteNameAscending();
          resolve(true);
        })
      })
    });
  }

  PopulateOrganisationList(organisationIds: number[]): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      this.OrganisationList = new OrganisationList();
      this.asrv.GetOrganisationNameCollection(organisationIds).then(value => {
        value.forEach(object => {
          this.OrganisationList.AddRow(object.ActorId, object.Name);
        })
        this.OrganisationList.Rows.sort((a, b) => {
          let x = a.OrganisationName.localeCompare(b.OrganisationName);
          return x;
        })
        resolve(true);
      })
    })
  }

  FetchOrganisationFilterValues(): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      this.asrv.GetOrganisationIdsDBSync(0, 0).then(value => {
        this.asrv.GetOrganisationNameCollection(value).then(value => {
          value.forEach(organisation => {
            if (!this.OrganisationFilter.some(i => i.OrganisationId === organisation.ActorId)) {
              this.OrganisationFilter.push({OrganisationId: organisation.ActorId, OrganisationName: organisation.Name})
            }
          })
          this.SortOrganisationFilter();
          resolve(true);
        });
      });
    });
  }

  AddOrganisationToFilter(organisationId: number, organisationName: string) {
    if (!this.OrganisationFilter.some(i => i.OrganisationId === organisationId)) {
      this.OrganisationFilter.push({OrganisationId: organisationId, OrganisationName: organisationName});
      this.SortOrganisationFilter();
    }
  }

  FilterOrganisationList(suspended?: boolean): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      let tags: number[] = [];
      if (suspended) {
        tags.push(1);
      }
      this.asrv.GetOrganisationIds(10000, 0, tags).then(value => {
      this.PopulateOrganisationList(value).then(value => {
          resolve(value);
        });
      })
    })
  }

  CheckUserIsAdmin() {
    this.asrv.GetActorRole().then(value => {
      this.IsAdmin = value.ActorRoleId <= adminlevel? true: false;
      this.UserRoleId = value.ActorRoleId;
    })
  }

  PopulateUserList(userIds: number[]): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      this.UserList = new UserList(this.asrv);
      this.UserListToShow = new UserList(this.asrv);
      userIds.forEach(id => {
        this.UserList.AddRow(id);
      })
      this.UserListToShow.Rows = this.UserList.Rows;
      this.UserListToShow.GetAllData();
      // this.SortUserListByNameAscending();
      resolve(true);
    })
  }

  FilterUserList(suspended?: boolean): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      let tags: number[] = [];
      if (suspended) {
        tags.push(1);
      }
      this.asrv.GetEmployeeIds(10000, 0, this.ActiveOrganisationFilterId, tags).then(value => {
      this.PopulateUserList(value).then(value => {
          resolve(value);
        });
      })
    })
  }

  SortOrganisationFilter() {
    this.OrganisationFilter.sort((a, b) => {
      let x = a.OrganisationName.localeCompare(b.OrganisationName);
      return x;
    });
  }

  SortSiteList(key: string) {
    if (key === 'Organisation') {
      if (this.SiteListOrganisationNameAscended) {
        this.SortSiteListByOrganisationNameDescending();
      } else {
        this.SortSiteListByOrganisationNameAscending();
      }
    } else {
      if (this.SiteListSiteNameAscended) {
        this.SortSiteListBySiteNameDescending();
      } else {
        this.SortSiteListBySiteNameAscending();
      }
    }
  }

  SortSiteListByOrganisationNameAscending() {
    this.SiteListToShow.Rows.sort((a, b) => {
      let x = a.OrganisationName.localeCompare(b.OrganisationName);
      if (x == 0) {
        return a.OrganisationName.localeCompare(b.SiteName);
      }
      this.SiteListSiteNameAscended = false;
      this.SiteListOrganisationNameAscended = true;
      return x;
    });
  }

  SortSiteListByOrganisationNameDescending() {
    this.SiteListToShow.Rows.sort((a, b) => {
      let x = b.OrganisationName.localeCompare(a.OrganisationName);
      if (x == 0) {
        return b.OrganisationName.localeCompare(a.SiteName);
      }
      this.SiteListSiteNameAscended = false;
      this.SiteListOrganisationNameAscended = false;
      return x;
    });
  }

  SortSiteListBySiteNameAscending() {
    this.SiteListToShow.Rows.sort((a, b) => {
      let x = a.SiteName.localeCompare(b.SiteName);
      if (x == 0 && !this.IsAdmin) {
        return a.OrganisationName.localeCompare(b.OrganisationName);
      }
      this.SiteListSiteNameAscended = true;
      this.SiteListOrganisationNameAscended = false;
      return x;
    });
  }

  SortSiteListBySiteNameDescending() {
    this.SiteListToShow.Rows.sort((a, b) => {
      let x = b.SiteName.localeCompare(a.SiteName);
      if (x == 0  && !this.IsAdmin) {
        return b.OrganisationName.localeCompare(a.OrganisationName);
      }
      this.SiteListSiteNameAscended = false;
      this.SiteListOrganisationNameAscended = false;
      return x;
    });
  }

  SortUserListByNameAscending() {
    this.UserListToShow.Rows.sort((a, b) => {
      let x = a.Forename.localeCompare(b.Forename);
      if (x == 0 && !this.IsAdmin) {
        return a.Surname.localeCompare(b.Surname);
      }
      this.SiteListSiteNameAscended = true;
      this.SiteListOrganisationNameAscended = false;
      return x;
    });
  }
  
  SortUserListByNameDescending() {
    this.UserListToShow.Rows.sort((a, b) => {
      let x = b.Forename.localeCompare(a.Forename);
      if (x == 0  && !this.IsAdmin) {
        return b.Surname.localeCompare(a.Surname);
      }
      this.SiteListSiteNameAscended = false;
      this.SiteListOrganisationNameAscended = false;
      return x;
    });
  }

  GetActorSettings(actorid): Promise<Actor_Settings[]> {
    return new Promise<Actor_Settings[]>(resolve => {
      let subscription = this.asrv.GetActorSettings(actorid).subscribe(res => {
        subscription.unsubscribe();
        resolve(res);
      })
    })
  }

  SaveActorPrimarySite(actorid, primarysiteid): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      let setting = new Actor_Settings();
      setting.Settings.SettingKeyId = primarysite;
      setting.Settings.Value = primarysiteid;
      let subscription = this.asrv.PostActorSetting(actorid, setting).subscribe(res => {
        subscription.unsubscribe();
        resolve(true);
      }, err => {
        subscription.unsubscribe();
        resolve(false);
      }) 
    })
  }

  public UpdateElement(domId: string, validationResult: boolean) {
    document.getElementById(domId).style.borderColor = validationResult? defaultBorderColor: errorBorderColor;
  }

  // Site Common Functions
  CreateFirstSite(organisationId: number) {
    this.site = new SiteDetail();
    this.siteTags = new GenericTagList(this.asrv);
    this.site.Organisation_ActorId = organisationId;
  }

  UpdateSite(): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      if (this.ValidateSite()) {
        this.site.Postcode = this.site.Postcode.replace(' ', '');
        this.site.Country = "UK";
        this.site.PostalAddressClassId = 2;
        if (this.site.EmployeeCollection && this.site.EmployeeCollection.length > 0) {
          this.site.ContactIdCollection = [];
          this.site.EmployeeCollection.forEach(employee => {
            if (employee.Contactable) {
              this.site.ContactIdCollection.push(employee.Id);
            }
          })
        }
        this.siteTags.Rows.forEach(row => {
          var tagId: number = row['TagId'];
          if (tagId > 0) {
            this.site.TagIdCollection.push(tagId);
          }
        })
        let subscription = this.asrv.UpdateSite(this.site).subscribe(res => {
          subscription.unsubscribe();
          this.site = res;
          resolve(true);
        }, err => {
          subscription.unsubscribe();
          resolve(false);
        })
      } else {
        resolve(false);
      }
    }) 
  }

  ValidateSite(): boolean {
    let result: boolean = true;
    if (!this.site.Name) {
      document.getElementById('inputGroupName').style.borderColor = this.errorBorderColor;
      result = false;
    } else {
      document.getElementById('inputGroupName').style.borderColor = this.defaultBorderColor;
    }
    if (!this.site.Postcode) {
      document.getElementById('inputGroupPostcode').style.borderColor = this.errorBorderColor;
      result = false;
    } else {
      document.getElementById('inputGroupPostcode').style.borderColor = this.defaultBorderColor;
    }   
    if (!this.site.Line1) {
      document.getElementById('inputGroupLine1').style.borderColor = this.errorBorderColor;
      document.getElementById('inputAddressSelect').style.borderColor = this.errorBorderColor;
      result = false;
    } else {
      document.getElementById('inputGroupLine1').style.borderColor = this.defaultBorderColor;
      document.getElementById('inputAddressSelect').style.borderColor = this.defaultBorderColor;
    }
    return result;
  }


  // Employee Common Functions
  CreateFirstUser(organisationId: number, siteId: number) {
    this.employeeDetails = new EmployeeDetails();
    this.userCredententials = new Credentials();
    this.userCredententials.Login = '';
    this.userCredententials.Hash = '';

    this.employeeDetails.Organisation_ActorId = organisationId;
    this.employeeDetails.ActorRoleId = 4;
    this.employeeDetails.SiteIdCollection.push(siteId);
    this.employeeDetails.SettingCollection.push({
      SettingKeyId: this.primarysitekey,
      Value: siteId.toString()
    });
  }

  SetCredentials(): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      if (this.userCredententials.Hash === this.passwordConfirm) {
        this.UpdateElement('inputPassword', true);
        this.UpdateElement('inputPasswordConfirm', true);
        if (!this.CheckPasswordStrength()) {
          resolve(false);
          return;
        }
      } else {
        this.UpdateElement('inputPassword', false);
        this.UpdateElement('inputPasswordConfirm', false);
        alert('Password inputs do not match, please make sure you enter the correct password.')
        resolve(false);
        return;
      } 
      let postCredentials = this.asrv.PostNewCredentials(this.userCredententials).subscribe(res => {
        postCredentials.unsubscribe();
        alert(`Successfully set credentials for ${this.employeeDetails.Forename} ${this.employeeDetails.Surname}`);
        resolve(true);
        return;
      }, err => {
        postCredentials.unsubscribe();
        alert('Error posting credentials, please try again. If the error persists, please contact support.');
        resolve(false);
        return;
      });
    })
  }

  AddEmployee(): Promise<boolean> {
    if (this.employeeDetails.MemWord === '') {
      delete this.employeeDetails.MemWord;
    }
    return new Promise<boolean>(resolve => {
      this.CheckEmailAddress();
      this.employeeDetails.PostalAddressClassId = 1;
      this.employeeDetails.Organisation_ActorId = this.ActiveOrganisationFilterId;
      if (this.employeeDetails.SiteIdCollection.length === 0) {
        this.employeeDetails.SiteIdCollection.push(+this.employeeDetails.SettingCollection.find(i => i.SettingKeyId === this.primarysitekey).Value)
      };
      // Actor Exists, likely only wants Credentials posted
      let postEmployeeSubscription = this.asrv.PostEmployeeDetails(this.employeeDetails).subscribe(res => {
        postEmployeeSubscription.unsubscribe();
        this.employeeDetails = res;
        this.userCredententials.ActorId = this.employeeDetails.ActorId;
        resolve(true);
      }, err => {
        postEmployeeSubscription.unsubscribe();
        alert('Error posting employee details, please try again. If the error persists, please contact support.');
        resolve(false);
      });
    })
  }

  UpdateEmployee(): Promise<boolean> {
    if (this.employeeDetails.MemWord === '') {
      delete this.employeeDetails.MemWord;
    }
    return new Promise<boolean>(resolve => {
      if (this.ValidateEmployee()) {
        this.employeeDetails.PostalAddressClassId = 1;

        let postEmployeeSubscription = this.asrv.PostEmployeeDetails(this.employeeDetails).subscribe(res => {
          postEmployeeSubscription.unsubscribe();
          resolve(true);
        }, err => {
          postEmployeeSubscription.unsubscribe();
        });
      }
    })
  }

  ValidateEmployee(): boolean {
    var result: boolean = true;
    if (!this.employeeDetails.HonorificId) {
      this.UpdateElement('inputTitle', false);
      result = false;
    } else {
      this.UpdateElement('inputTitle', true);
    }

    if (!this.employeeDetails.GenderId) {
      this.UpdateElement('inputGender', false);
      result =  false;
    } else {
      this.UpdateElement('inputGender', true);
    }

    if (!this.employeeDetails.Forename) {
      this.UpdateElement('inputForename', false);
      result =  false;
    } else {
      this.UpdateElement('inputForename', true);
    }

    if (!this.employeeDetails.Surname) {
      this.UpdateElement('inputSurname', false);
      result =  false;
    } else {
      this.UpdateElement('inputSurname', true);
    }

    if (this.employeeDetails.SiteIdCollection.length === 0) {
      this.UpdateElement('inputTitle', false);
      result =  false;
    } else {
      this.UpdateElement('inputTitle', true);
    }

    var settingCollection: SettingCollection[] = [];
    this.employeeDetails.SettingCollection.forEach(setting => {
      if (!settingCollection.some(i => i.Value === setting.Value && i.SettingKeyId === setting.SettingKeyId)) {
        settingCollection.push(setting);
      }
    })
    this.employeeDetails.SettingCollection = settingCollection;

    if (!result) {
      return result;
    } 

    return this.CheckEmailAddress();
  }

  CheckEmailAddress(): boolean {
    /*
    let mailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (this.userCredententials.Login == null || !this.userCredententials.Login.match(mailFormat)) {
      alert('Please enter a valid email address for the user login.');
      return false;
    } else {
      this.employeeDetails.Email = this.userCredententials.Login;
    }
    */
    return true;
  }

  CheckPasswordStrength(): boolean {
    let result = true;
    if (this.userCredententials.Hash.length > 0) {
      var testStrength: RegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/;
      if (!testStrength.test(this.userCredententials.Hash)) {
        alert("Passwords must be at least 8 characters long and contain at least 1 uppercase, special & numeric character");
        result = false
      }
    }
    return result;
  }

  CheckCredentials() {
    let credentialsBrief = new CredentialsBrief();
    credentialsBrief.Login = this.userCredententials.Login;
    credentialsBrief.Password = this.userCredententials.Hash;
    this.asrv.CompareLoginCredentials(credentialsBrief).subscribe(res => {
      // Matched
    }, err => {
      // Not matched
    })
  }

}

// Move to seperate files, these are here strictly for testing purposes for now.
export class OrganisationList {
  public Rows: OrganisationRow[] = [];
  public HeaderTranslation = [{
    'OrganisationName': 'Name',
  }];

  public AddRow(organisationId: number, organisationName: string) {
    this.Rows.push({
      OrganisationId: organisationId, 
      OrganisationName: organisationName
    });
  }
}

export class OrganisationRow {
  OrganisationId: number;
  OrganisationName: string;
}

class OrganisationFilter {
  OrganisationId: number;
  OrganisationName: string;
}

export class SiteList {
  public Rows: SiteRow[];
  public AddRow(siteId: number, siteName: string, organisationId: number) {
    this.Rows.push({
      SiteId: siteId, 
      SiteName: siteName, 
      OrganisationId: organisationId, 
      OrganisationName: ''
    });
  }

  constructor(private IsAdmin: boolean, public asrv: ActorService) {
    this.Rows = [];
  }

  public HeaderTranslation() {
    return [{'SiteName': 'Site Name'}];  
  } 

  public UpdateRow(siteId: number, organisationId: number) {
    this.asrv.GetSiteNameCollection([siteId]).then(value => {
      this.Rows.find(i => i.SiteId === siteId).SiteName = value[0].Name;
    })

    this.asrv.GetOrganisationNameCollection([organisationId]).then(value => {
      this.Rows.find(i => i.SiteId === siteId && i.OrganisationId === organisationId).OrganisationName = value[0].Name;
    })
  }
}

class SiteRow {
  SiteId: number;
  SiteName: string;
  OrganisationId: number;
  OrganisationName: string;
}

export class UserList {
  public Rows: any = [];
  public HeaderTranslation = [{
    'Name': 'Name'
  }];

  constructor(public asrv: ActorService) {}

  public AddRow(actorId: number) {
    let newRow = {};
    newRow['ActorId'] = actorId;
    newRow['Forename'] = false;
    newRow['Surname'] = false;
    newRow['Name'] = false;
    newRow['ActorRole'] = false;
    this.Rows.push(newRow);
  }

  public GetAllData() {
    let ids: number[] = this.Rows.map(function (el) { return el.ActorId; });
    this.asrv.GetBiologicalName(ids).then(value => {
      this.Rows.forEach(row => {
        var actor = value.find(i => i.ActorId === row.ActorId);
        row['Forename'] = actor.Forename;
        row['Surname'] = actor.Surname;
        row['Name'] = row['Forename'] + ' ' + row['Surname'];
        if (actor.ActorRoleCollection) {
          row['ActorRoleId'] = actor.ActorRoleCollection.ActorRoleId;
          row['ActorRole'] = actor.ActorRoleCollection.Name;
        }
      })
    })
  }
}