import { Honorific } from '../Honorific';
import { Gender } from '../Gender';
import { ExclusionDurationOption } from '../ExclusionDurationOption';
import { SiteCollection } from '../SiteCollection';
import { ISDDateMethods } from '../ISDDateMethods';
import { ActorService } from 'src/app/services/actor.service';
import { ExcluderService } from 'src/app/services/excluder.service';
import { InteractionService } from 'src/app/services/interaction.service';
import { ApiService } from 'src/app/services/api.service';

// Class for creating tabular reports.
// Every tabular class requires the following properties.
// Rows: any[] = [] --Holds all the newRows pushed.
// HeaderTransaltion = [{}]  --This will print all the headers to the table.
// newRow = [{}] --Make the values all blank but match the HeaderTranslationIds
// public AddRow(rowObject) {} --Use this push rows, ensure all values are hardcoded for now.

export class InteractionList {
    private DateMethods: ISDDateMethods = new ISDDateMethods();
    public Rows: any[] = [];
    public Honorifics: Honorific[] = [];
    public Genders: Gender[] = [];
    public DurationOptions: ExclusionDurationOption[] = []; 
    public SubscriptionsOpened: number;
    public CompletedSubscriptions: number = 0;
    public DataLoaded:boolean = false;
    private siteNamePromises: SiteNamePromise[] = [];
    public HeaderTranslation = [{
        'Name': 'Name/Nickname', 
        'Reason': 'Reason(s)',
        'Action': 'Action(s)',
        'Start': 'Start',
        'ReviewDate': 'Review Date',
        'SiteId': 'Site Name'
    }];

    constructor(
        public asrv: ActorService,
        public isrv: InteractionService,
        public ids: number[],
        private _api: ApiService,
        ) {
        this.ids.forEach(id => {
            this.AddRow(id);
        })

        this.DataLoaded = true;
    }

    public AddRow(id: number) {
        let returnRow = {};
        returnRow['InteractionId'] = id;
        returnRow['Name'] = false;
        returnRow['Reason'] = false;
        returnRow['Action'] = false;
        returnRow['Start'] = false;
        returnRow['ReviewDate'] = false;
        returnRow['SiteId'] = false;
        this._api.getInteractionCompositeModel(id).subscribe(res => {
            res.InteractedActorCompositeModel = res.InteractedActorCompositeModelCollection[0];
            if (!(res.InteractedActorCompositeModel.BiologicalActorCompositeModel.Forename && res.InteractedActorCompositeModel.BiologicalActorCompositeModel.Surname)) {
                returnRow['Name'] = res.InteractedActorCompositeModel.BiologicalActorCompositeModel.Alias;
            } else {
                returnRow['Name'] = res.InteractedActorCompositeModel.BiologicalActorCompositeModel.Forename + ' ' + res.InteractedActorCompositeModel.BiologicalActorCompositeModel.Surname;
            }

            let reasons: string = '';
            let actions: string = '';
            res.TagIdCollection.forEach(tagId => {
                if (tagId > 90 && tagId <= 96) {
                    if (reasons.length > 1) {
                        reasons = reasons + ' - ';
                    }
                    switch(tagId) {
                        case 91:
                            reasons = reasons + 'Time'; 
                            break;
                        case 92:
                            reasons = reasons + 'Spend';
                            break;
                        case 93:
                            reasons = reasons + 'Behaviour';
                            break;
                        case 94:
                            reasons = reasons + 'Machine Alert';
                            break;
                        case 95:
                            reasons = reasons + 'Vulnerability';
                            break;
                        case 96:
                            reasons = reasons + 'Wish to Self-Exclude';
                    }
                }
            
                if (tagId >= 110 && tagId <= 119) {
                    if (actions.length > 1) {
                        actions = actions + ' - ';
                    }
                    switch(tagId) {
                        case 110:
                          actions = actions + 'Banned'; 
                          break;
                        case 111:
                          actions = actions + 'Take a Break';
                          break;
                        case 112:
                          actions = actions + 'Impose Time Limits';
                          break;
                        case 113:
                          actions = actions + 'Impose Spend Limits';
                          break;
                        case 114:
                          actions = actions + 'Change Category';
                          break;
                        case 115:
                          actions = actions + 'Monitor Behaviour';
                          break;
                        case 116:
                          actions = actions + 'Signpost to Gamcare';
                          break;
                        case 117:
                          actions = actions + 'Stay in Control Leaflet';
                          break;
                        case 118:
                          actions = actions + 'Gamble Management App';
                          break;
                        case 119:
                          actions = actions + 'Issued Play Diary';
                    }
                }
            })
            returnRow['Reason'] = reasons;
            returnRow['Action'] = actions;
            returnRow['Start'] = this.DateMethods.FormatDateForReport(new Date(res.InteractionMonitoringPeriod.Start));
            returnRow['ReviewDate'] = this.DateMethods.FormatDateForReport(this.DateMethods.AddDays(res.InteractionMonitoringPeriod.Start, res.InteractionMonitoringPeriod.Duration));
            this.GetSiteName(res.SiteId).then(value => {
                returnRow['SiteId'] = value;
            })
        })

        this.Rows.push(returnRow);
    }

    GetSiteName(siteid): Promise<string> {
        if (this.siteNamePromises.some(i => i.SiteId === siteid)) {
            return this.siteNamePromises.find(i => i.SiteId === siteid).Promise;
        } else {
            return new Promise<string>(resolve => {
                this.asrv.GetSiteNameCollection([siteid]).then(value => {
                    if (value) {
                        resolve(value[0].Name);
                    } else {
                        resolve('');
                    }
                })
            })
        }
    }
}

class SiteNamePromise {
    SiteId: number;
    Promise: Promise<string>;
}
