import { Component, OnInit, Input, EventEmitter, Output, ElementRef, ViewChild, AfterViewInit } from '@angular/core';

import { IdentificationDocument } from 'src/app/models/IdentificationDocumentKey';
import { ActorService } from 'src/app/services/actor.service';
import { ExcluderService } from 'src/app/services/excluder.service';
// import SignaturePad from 'node_modules/signature_pad';

@Component({
  selector: 'app-exclusion-declaration-details',
  templateUrl: './exclusion-declaration-details.component.html',
  styleUrls: ['./exclusion-declaration-details.component.scss', '../generic-form.component.scss']
})
export class ExclusionDeclarationDetailsComponent implements OnInit {
  
  @ViewChild('canvas') Canvas: ElementRef;
  @ViewChild('btnProcess') btnProcess: ElementRef;

  // public signaturePad: SignaturePad;
  public data;
  public emailVerification: boolean = false;
  public showDeclaration: boolean = true;
  public termsDisplayedOnce: boolean = false;
  public chkTermsAndConditions: boolean;
  public agreeToThirdParty: boolean;

  constructor(public asrv: ActorService, public esrv: ExcluderService) { }

  showEmail: Boolean = false;
  ShowEmailApp() {
    this.showEmail = !this.showEmail;
    if (this.showEmail) {
      this.DisableButtonProcessExclusion();
    } else {
      this.EnableButtonProcessExclusion();
    }
  }

  ngOnInit() {
  }

  ShowTerms() {
    this.ShowHideTermsApp.next();
  }

  CallValidateExclusion() {
    // this.exclusion.Image_Signiture = this.signaturePad.toDataURL();
    // this.exclusion.Image_SignitureBase64 = this.signaturePad.toDataURL();
    /*if (!(this.esrv.CurrentExclusion.IdentificationDocumentKeyId >= 0)) {
      alert('Please seclect the form of ID the Excluder presented.')
    } else */
     if (this.emailVerification && (!this.esrv.CurrentExclusion.InteractedActorCompositeModel.BiologicalActorCompositeModel.Email)) {
      this.ShowEmailApp();
    } else {
      if (this.emailVerification) {
        this.esrv.CurrentExclusion.TagIdCollection.push(42);
      }
      this.DisableButtonProcessExclusion();
      this.ValidateExclusion.next();
    }
  }

  DisableButtonProcessExclusion() {
    this.btnProcess.nativeElement.disabled = true;
    this.btnProcess.nativeElement.style.filter = 'Grayscale(0.75)';
    this.btnProcess.nativeElement.style.cursor = 'not-allowed';
  }

  EnableButtonProcessExclusion() {
    this.btnProcess.nativeElement.disabled = false;
    this.btnProcess.nativeElement.style.filter = 'Grayscale(0)';
    this.btnProcess.nativeElement.style.cursor = 'pointer';
  }

  @Output() ShowHideTermsApp = new EventEmitter<String>();
  @Output() ValidateExclusion = new EventEmitter<String>();

}