<div class="container global-seperator">
    <div *ngIf="showIcons" class="row">
        <!--*ngFor="let icon of icons.items[0].icons"-->
        <div class="col-12" style="max-width: 100%;margin:auto;">
            <span *ngFor="let iconId of iconIds">
                <img [attr.id]="isrv.SystemIconImageList[iconId].domid" [src]="isrv.SystemIconImageList[iconId].src" style="width:10%; margin: 10px;" (click)="SelectImage(isrv.SystemIconImageList[iconId].id, $event);">
            </span>
        </div>
    </div>

    <div *ngIf="!showIcons" class="row">
        <div class="col-12" style="max-width: 100%;height:400px;margin:auto;display:flex;justify-content: center;flex-direction: row;">
            <span *ngFor="let icon of isrv.SystemOutlineImageList" style="width:50%;display:flex;justify-content: center;">
                <img [attr.id]="icon.domid" [src]="icon.src" [style.width]="icon.width" (click)="SelectImage(icon.id, $event);">
            </span>
        </div>
    </div> 
</div>