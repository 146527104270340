// import { inherits } from 'util';

export class Name {
    ActorId: number;
    NameCollection: NameCollection[];
    ActorRoleCollection: ActorRoleCollection[];
}

class NameCollection {
    NameId: number;
    Name: string;
    NameClassKeyId: number;
    Order: number;
}


class ActorRoleCollection {
    ActorRoleId: number;
    Name: string;
}

export class BiologicalUserInfo {
    ActorId: number;
    Forename: string;
    Surname: string;
    ActorRoleCollection: ActorRoleCollection;
}

export class NonBiologicalName {
    ActorId: number;
    Name: string;
    
    ClientValues: ClientValues = new ClientValues();
}

class ClientValues {
    LoadedFromMotorwayServices: boolean = false;
}