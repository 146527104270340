<div class="container global-seperator">
    <div class="row">
        <div class="col-md-12">
            <h4><b>Declaration</b></h4>
        </div>
    </div>
    <app-email *ngIf="showEmail" (ShowEmailApp)="ShowEmailApp()"></app-email>
    <div class="row" *ngIf="(!showEmail)">
        <div class="col-md-6">
            <div id="ctrlIDCheck" class="flex-check-box">
                <div class="flex-check-prepend">ID CHECKED</div>
                <input style="margin:auto;min-width:10%;" type="checkbox" value="true" class="md-checkbox form-control form-checkbox bottom-checkbox" [(ngModel)]="esrv.CurrentExclusion.ClientValues.IdChecked"> 
            </div>  
        </div>
        <div class="col-md-6">
            <div class="input-group" id="inputIDType">
                <select class="form-control drop-down-purple" name="iddoc" [(ngModel)]="esrv.CurrentExclusion.IdentificationDocumentKeyId">
                    <option [ngValue]="null"></option>
                    <option *ngFor="let doc of asrv.IdentificationDocumentTypes" [ngValue]="doc.IdentificationDocumentKeyId">{{doc.Name}}</option>
                </select>
            </div>              
        </div>
    </div>

    <div class="row" *ngIf="(!showEmail)">
        <div class="col-md-12" style="padding-top:20px;">
            <div class="flex-check-box">
                <div class="flex-check-prepend">EMAIL A COPY OF THE EXCLUSION DETAILS</div> 
                <input style="margin:auto;min-width:10%;" type="checkbox" value="true" class="md-checkbox form-control form-checkbox bottom-checkbox" [(ngModel)]="emailVerification"> 
            </div>
        </div>              
    </div>
    <div class="row" *ngIf="(!showEmail)" style="padding-top:20px;">
        <div class="col-md-12">
            <div class="flex-check-box">
                <div class="flex-check-prepend">I CONFIRM MY SELF-EXCLUSION</div>
                <input style="margin:auto;min-width:10%;" type="checkbox" value="true" class="md-checkbox form-control form-checkbox bottom-checkbox" [(ngModel)]="esrv.CurrentExclusion.ClientValues.Confirmed"> 
            </div>  
        </div>              
    </div>
    <!--
<input style="margin:auto;width:10%;" type="checkbox" value="true" class="md-checkbox form-control form-checkbox bottom-checkbox" [(ngModel)]="emailVerification">

        <div class="row" style="margin-top:25px;height:135px;">
            <div class="col-md-12">
                <div class="signature-pad-container global-seperator" style="border:5px solid #223464; border-bottom: 2px solid #223464; width: 100%; display: inline-block; background-color: #223464; color: white;">
                    <span class="signature-pad-text">SIGNATURE</span>
                    <canvas #canvas id="signature-pad" class="signature-pad" style="background-color: white;width:100%;height:100px;"></canvas>               
                </div>
            </div>
        </div>
        <div class="row" style="max-height: 75px;">
            <div class="col-md-12" style="text-align:left;"><button style="color: #223464 !important;" type="button" class="btn btn-link" (click)="clearImage();"><u>REDO SIGNATURE</u></button></div>  
        </div>
    -->

    <div class="row" style="width:100%;text-align: right;flex-direction:row-reverse;">
        <input #btnProcess id="btnProcessExclusion" type="image" class="bacta-button" src=".\assets\icons\Bacta Assets\Confirm Exclusion Green.svg" style="margin-right:10px;" (click)="CallValidateExclusion();" />
    </div>
</div>