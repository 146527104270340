import { Injectable } from '@angular/core';
import { ActorService } from './actor.service';
import { Views_InteractionsCompositeModel } from '../models/InteractionExclusion/Views_InteractionsCompositeModel';
import { Router } from '@angular/router';
import { Views_BiologicalActorCompositeModel } from '../models/InteractionExclusion/Views_BiologicalActorCompositeModel';
import { ISDDateMethods } from '../models/ISDDateMethods';
import { DataService } from './data.service';
import { Observable } from 'rxjs';
import { NonBiologicalName } from '../models/actor/Name';
import { Site } from '../models/Site';
import { GetExclusionsCompositeModel, Views_Get_ExclusionsCompositeModel } from '../models/InteractionExclusion/Views_Get_ExclusionsCompositeModel';
import { View_ExclusionBreachesCompositeModel } from '../models/InteractionExclusion/Breach/View_ExclusionBreachesCompositeModel';
import { View_ReinstatementsCompositeModel } from '../models/InteractionExclusion/Reinstatement/View_ReinstatementsCompositeModel';
import { ExcluderBrief } from '../models/ExcluderBrief';
import { ExclusionClientValues } from '../models/InteractionExclusion/ExclusionClientValues';
import { Views_MediaModel } from '../models/InteractionExclusion/Views_MediaModel';
import { Views_InteractedActorCompositeModel } from '../models/InteractionExclusion/Views_InteractedActorCompositeModel';
import { InteractionMonitoringPeriodsModel, Views_InteractionMonitoringPeriodsModel } from '../models/InteractionExclusion/Views_InteractionMonitoringPeriodsModel';
import { ExclusionBreaches } from '../models/ExclusionBreaches';
import { InteractionService } from './interaction.service';
import { Reinstatement } from '../models/controllers/ExclusionControllers/Reinstatement';
import { Extension } from '../models/controllers/ExclusionControllers/Extension';
import { ApiService } from './api.service';

const errorBorderColor: string = 'red';
const defaultBorderColor: string = '#223464';
const exclusionSessionStorage: string = 'current_exclusion';
const tagIdChecked: number = 31;
const tagReadTermsAndConditions: number = 32;
const marketing: number = 41;
const thirdPartyContactable: number = 42;

@Injectable({
  providedIn: 'root'
})
export class ExcluderService {

  constructor(
    public asrv: ActorService,
    public dsrv: DataService,
    private router: Router,
    public isrv: InteractionService,
    private _api: ApiService
  ) { }

  public CurrentExclusion: Views_Get_ExclusionsCompositeModel;
  public SitesInRadiusNameCollection: NonBiologicalName[]
  public failedToSendOnce: boolean;
  private _postalAddressPopulated: boolean;
  private _errors: string[];
  private _datemethods: ISDDateMethods = new ISDDateMethods();
  private _pages = {
    customerDetail: 0,
    customerAddress: 1,
    excluderExclusion: 2,
    exclusionZone: 3,
    customerPhoto: 4,
    termsAndConditions: 5,
    exclusionDeclaration: 6,
    exclusionConfirmed: 7
  };

  public ExtensionControllers: Extension;
  public ReinstatementControllers: Reinstatement;

  public get PostalAddressPopulated(): boolean {
    return this._postalAddressPopulated;
  }

  public get ExclusionRadius(): string {
    if (this.CurrentExclusion.Radius > 0) {
      return `${this.CurrentExclusion.Radius}km`
    }
    return `Wide-Area Exclusion`
  }

  public StartNewExclusion(interaction: Views_InteractionsCompositeModel) {
    this.CurrentExclusion = new Views_Get_ExclusionsCompositeModel();
    // this.CurrentExclusion.InteractedActorCompositeModel = new Views_InteractedActorCompositeModel();
    this.CurrentExclusion.InteractionMonitoringPeriod = new Views_InteractionMonitoringPeriodsModel();
    this.CurrentExclusion.MediaModelCollection = [];
    this.CurrentExclusion.TagIdCollection = interaction.TagIdCollection;
    this.router.navigate(['user/exclusion/new']);
  }

  public ReInitiateExclusion() {
    this.CurrentExclusion = new Views_Get_ExclusionsCompositeModel();
    this.CurrentExclusion.InteractedActorCompositeModel = new Views_InteractedActorCompositeModel();
    this.CurrentExclusion.InteractionMonitoringPeriod = new Views_InteractionMonitoringPeriodsModel();
    this.CurrentExclusion.MediaModelCollection = [];
  }

  public ShutDownExclusion() {
    this.CurrentExclusion = null;
  }

  public StoreExcluderBrief(excluderBrief: ExcluderBrief) {
    this.CurrentExclusion = new Views_Get_ExclusionsCompositeModel();
    this.CurrentExclusion.InteractedActorCompositeModel = new Views_InteractedActorCompositeModel();
    this.CurrentExclusion.MediaModelCollection = [];

    // Standard
    this.CurrentExclusion.InteractionId = excluderBrief.InteractionId;
    this.CurrentExclusion.Reference = excluderBrief.Reference;

    // BiologicalActor
    this.CurrentExclusion.InteractedActorCompositeModel.BiologicalActorCompositeModel.Forename = excluderBrief.Forename;
    this.CurrentExclusion.InteractedActorCompositeModel.BiologicalActorCompositeModel.Surname = excluderBrief.Surname;

    // Get Image and fetch the full details
    this.CurrentExclusion.MediaModelCollection.push({ MediaId: excluderBrief.MediaId, MediaClassKeyId: 4, Data: excluderBrief.Image });
  }

  // Check if the interactionId has a promise preloaded, else in the event of a reload. Recall the Observable
  public LoadExclusion(interactionId: number): Promise<Views_Get_ExclusionsCompositeModel> {
    return new Promise<Views_Get_ExclusionsCompositeModel>(resolve => {
      this.LoadExclusionFromDatabase(interactionId).then(value => {
        this.CurrentExclusion = value;
        this.CurrentExclusion.ClientValues = new ExclusionClientValues();
        this.asrv.GetReinstatementModel(interactionId).then(value => {
          this.CurrentExclusion.Reinstatement = value;
          resolve(this.CurrentExclusion);
        })
      })
    })
  }

  private LoadExclusionFromDatabase(interactionId): Promise<Views_Get_ExclusionsCompositeModel> {
    return new Promise<Views_Get_ExclusionsCompositeModel>(resolve => {
      let subscription = this._api.getViewExclusionCompositeModel(interactionId).subscribe(res => {
        subscription.unsubscribe();
        res.InteractedActorCompositeModel = res.InteractedActorCompositeModelCollection[0];
        resolve(res);
      })
    })
  }

  // public GetViewExclusionCompositeModel(interactionId: number): Observable<Views_Get_ExclusionsCompositeModel> {
  //   return this.dsrv.Get<Views_Get_ExclusionsCompositeModel>('api/View_ExclusionCompositeModel/' + interactionId);
  // }

  public SaveExclusion(): Promise<Views_Get_ExclusionsCompositeModel> {
    // May require updating in the future, but for now all PostalAddressClassId's in Exclusion are 2.
    this.CurrentExclusion.InteractedActorCompositeModel.BiologicalActorCompositeModel.PostalAddressClassId = 2;

    // Remove any space from the Postcode
    this.CurrentExclusion.InteractedActorCompositeModel.BiologicalActorCompositeModel.Postcode = this.CurrentExclusion.InteractedActorCompositeModel.BiologicalActorCompositeModel.Postcode.replace(/\s/g, '');

    // May require updating in the future, but for now all Countries are U.K.
    this.CurrentExclusion.InteractedActorCompositeModel.BiologicalActorCompositeModel.Country = 'UK';

    // The SiteId of where the Exclusion Took place must also exist in the SiteIdCollection.
    if (!this.CurrentExclusion.SiteIdCollection.some(i => i === this.CurrentExclusion.SiteId)) {
      this.CurrentExclusion.SiteIdCollection.push(this.CurrentExclusion.SiteId);
    }

    return new Promise<Views_Get_ExclusionsCompositeModel>(resolve => {
      let subscription = this.PostViewExclusionCompositeModel().subscribe(res => {
        subscription.unsubscribe();
        resolve(res);
      }, err => {
        this.EnableButton('btnProcessExclusion');
      })
    })
  }

  public InitiateExtensionForm() {
    this.ExtensionControllers = new Extension(this.CurrentExclusion.InteractionId, this.GetExtensionStartDate());
  }

  public SaveExtension(months: number, mediaModel: Views_MediaModel[]): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      var viewMediaModel = mediaModel.length > 0 ? mediaModel : this.CurrentExclusion.MediaModelCollection;

      var newDate: Date;
      if (this.GetCurrentExcluderEndDate() > new Date()) {
        newDate = this.GetExtensionStartDate();
      } else {
        newDate = new Date();
      }
      var monitorPeriod = this._datemethods.GetMonitoringPeriodUsingMonths(newDate, months)
      var monitoringPeriod: InteractionMonitoringPeriodsModel = new InteractionMonitoringPeriodsModel(monitorPeriod, newDate);
      let extension: GetExclusionsCompositeModel = new GetExclusionsCompositeModel(
        0,
        this.CurrentExclusion.AssociatedActorIdCollection,
        this.CurrentExclusion.InteractedActorCompositeModel,
        this.CurrentExclusion.InteractedActorCompositeModelCollection,
        monitoringPeriod,
        viewMediaModel,
        null,
        this.CurrentExclusion.Reference,
        this.CurrentExclusion.SiteId,
        this.CurrentExclusion.TagIdCollection,
        this.CurrentExclusion.VehicleCollection,
        this.CurrentExclusion.ExclusionReasonKeyId,
        this.CurrentExclusion.IdentificationDocumentKeyId,
        this.CurrentExclusion.Radius,
        this.CurrentExclusion.SiteIdCollection,
        this.CurrentExclusion.Extension,
        this.CurrentExclusion.Reinstatement,
        this.CurrentExclusion.ExclusionBreachesCollection
      );

      let renavigate = this._datemethods.ConvertDateToInteger(this._datemethods.AddDays(this.CurrentExclusion.InteractionMonitoringPeriod.Start, this.CurrentExclusion.InteractionMonitoringPeriod.Duration)) < this._datemethods.ConvertDateToInteger(new Date())
      delete extension.ClientValues;
      let subscription = this.PostExtension(extension).subscribe(res => {
        subscription.unsubscribe();
        this.CurrentExclusion.InteractionId = res.InteractionId;
        this.CurrentExclusion.ClientValues.ExtensionExists = true;
        alert('Extension applied To Exclusion.')
        if (renavigate) { this.router.navigate([`user/exclusion/view/`, res.InteractionId]) };
        resolve(true);
      }, err => {
        subscription.unsubscribe();
      })
    })
  }

  // private PostViewExclusionCompositeModel(exclusion?: Views_Get_ExclusionsCompositeModel): Observable<Views_Get_ExclusionsCompositeModel> {
  //   if (exclusion) {
  //     return this.dsrv.Post<Views_Get_ExclusionsCompositeModel>('api/View_ExclusionCompositeModel/', exclusion);
  //   } else {
  //     return this.dsrv.Post<Views_Get_ExclusionsCompositeModel>('api/View_ExclusionCompositeModel/', this.CurrentExclusion);
  //   }
  // }

  private PostViewExclusionCompositeModel(exclusion?: Views_Get_ExclusionsCompositeModel): Observable<Views_Get_ExclusionsCompositeModel> {
    if (exclusion) {
      return this._api.postViewExclusionCompositeModel(exclusion);
    } else {
      return this._api.postViewExclusionCompositeModel(this.CurrentExclusion);
    }
  }  

  private PostExtension(exclusion: GetExclusionsCompositeModel): Observable<GetExclusionsCompositeModel> {
    exclusion.SiteCollectionIdCollection = [];
    return this.dsrv.Post<GetExclusionsCompositeModel>('api/View_ExclusionCompositeModel/', exclusion);
  }

  public SaveBreach(breach: View_ExclusionBreachesCompositeModel): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      let subscription = this._api.postViewBreachCompositeModel(breach).subscribe(res => {
        subscription.unsubscribe();
        this.CurrentExclusion.ClientValues.BreachExists = true;
        resolve(true);
      }, err => {
        subscription.unsubscribe();
        resolve(false);
      })
    })
  }

  public GetCurrentExcluderImage(): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      let subscription = this.asrv.GetInteractionMedia(this.CurrentExclusion.InteractionId).subscribe(res => {
        subscription.unsubscribe();
        if (!this.CurrentExclusion.MediaModelCollection) {
          this.CurrentExclusion.MediaModelCollection.push(res);
        } else {
          this.CurrentExclusion.MediaModelCollection = [];
          this.CurrentExclusion.MediaModelCollection.push(res);
        }
        resolve(true);
      }, err => {
        subscription.unsubscribe();
        resolve(false);
      })
    })
  }

  public GetRecentBreach(): ExclusionBreaches {
    let returnBreach: ExclusionBreaches;
    if (this.CurrentExclusion.ExclusionBreachesCollection) {
      let recentBreach: number = 0;
      this.CurrentExclusion.ExclusionBreachesCollection.forEach(breach => {
        let currentBreach = this._datemethods.ConvertDateToInteger(breach.Breached);
        if (currentBreach > recentBreach) {
          recentBreach = currentBreach;
          returnBreach = breach;
        }
      })
    }
    return returnBreach;
  }

  // private PostViewBreachCompositeModel(breach: View_ExclusionBreachesCompositeModel): Observable<View_ExclusionBreachesCompositeModel> {
  //   return this.dsrv.Post<View_ExclusionBreachesCompositeModel>('api/View_ExclusionBreachesCompositeModel', breach);
  // }

  public StartReinstatement() {
    this.ReinstatementControllers = new Reinstatement(this.asrv.OrganisationSelect.KeySelected);
  }

  public ReinstatementSave(): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      if (!this.ReinstatementControllers.Validate()) {
        var reinstatement = new View_ReinstatementsCompositeModel(
          0,
          [this.ReinstatementControllers.EmployeeId],
          null,
          this.CurrentExclusion.Reference,
          this.ReinstatementControllers.SiteId,
          null,
          this.CurrentExclusion.InteractionId,
          this.ReinstatementControllers.MethodId,
          this.ReinstatementControllers.Date
        );

        if (!reinstatement.TagIdCollection) {
          reinstatement.TagIdCollection = [];
        }

        if (!reinstatement.TagIdCollection.some(i => i === 7)) {
          reinstatement.TagIdCollection.push(7);
        }
        reinstatement.ReinstatementRequestMethodKeyId = this.ReinstatementControllers.MethodId;
        let subscription = this._api.postViewReinstatementsCompositeModel(reinstatement).subscribe(res => {
          subscription.unsubscribe();
          resolve(true);
        }, err => {
          subscription.unsubscribe();
          resolve(false);
        })
      } else {
        // flag error service
        resolve(false);
      }
    })
  }

  public SaveReinstatement(reinstatement: View_ReinstatementsCompositeModel): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      reinstatement.InteractionId = this.CurrentExclusion.InteractionId;
      // reinstatement.Exclusions_InteractionId = this.CurrentExclusion.InteractionId;
      reinstatement.Reference = this.CurrentExclusion.Reference;
      reinstatement.ReinstatementRequestMethodKeyId = 1;
      let subscription = this._api.postViewReinstatementsCompositeModel(reinstatement).subscribe(res => {
        subscription.unsubscribe();
        resolve(true);
      }, err => {
        subscription.unsubscribe();
        resolve(false);
      })
    })
  }

  // private PostViewReinstatementsCompositeModel(reinstatement: View_ReinstatementsCompositeModel): Observable<View_ReinstatementsCompositeModel> {
  //   return this.dsrv.Post<View_ReinstatementsCompositeModel>('api/View_ReinstatementsCompositeModel', reinstatement);
  // }

  /*
  private CreateNewInteractionForReinstatement(reinstatement: View_ReinstatementsCompositeModel): Promise<boolean> {
    var interaction: Views_InteractionsCompositeModel = new Views_InteractionsCompositeModel();

    interaction.AssociatedActorIdCollection = this.CurrentExclusion.AssociatedActorIdCollection;
    interaction.InteractedActorCompositeModel = this.CurrentExclusion.InteractedActorCompositeModel;
    interaction.InteractionId = 0;
    interaction.InteractionMonitoringPeriod.Duration = 28;
    interaction.InteractionMonitoringPeriod.Start = new Date();
    interaction.MediaModelCollection = this.CurrentExclusion.MediaModelCollection;
    interaction.Notes = this.CurrentExclusion.InteractedActorCompositeModel.BiologicalActorCompositeModel.Forename + ' ' + this.CurrentExclusion.InteractedActorCompositeModel.BiologicalActorCompositeModel.Surname + ' was reinstated ' +  reinstatement.Accepted.toLocaleDateString() + '. This will require a review on ' + this._datemethods.AddDays(interaction.InteractionMonitoringPeriod.Start, 28) + '.';
    // Customer-led as this is a reinstatement
    interaction.TagIdCollection.push(this.asrv.Globals.TagValues.InteractionCustomerLed);
    // Push in the Behavior Reason
    interaction.TagIdCollection.push(this.asrv.Globals.TagValues.InteractionReasonBehaviour);
    // Push in the Monitor Behavior Action
    interaction.TagIdCollection.push(this.asrv.Globals.TagValues.InteractionActionMonitorBehaviour);
    interaction.Reference = this.CurrentExclusion.Reference;

    // Update when actor service has the users siteid
    interaction.SiteId = 101;
    

    return new Promise<boolean>(resolve => {
      this.PostInteractionCompositeModel(interaction).subscribe(res => {
        resolve(true);
      }, err => {
        resolve(false);
      })
    })
  }

  private PostInteractionCompositeModel(interaction): Observable<Views_InteractionsCompositeModel> {
    return this.dsrv.Post<Views_InteractionsCompositeModel>('api/View_InteractionCompositeModel', interaction);
  }
  */

  public AddSitesWithinOneKilometer(): Promise<boolean> {
    this.CurrentExclusion.ClientValues.SiteCollection = [];
    this.CurrentExclusion.Radius = 1;
    if (this.CurrentExclusion.SiteId) {
      return new Promise<boolean>(resolve => {
        let subscription = this.asrv.GetSitesWithinRadius(this.CurrentExclusion.SiteId, 1).subscribe(res => {
          subscription.unsubscribe();
          try {
            this.CurrentExclusion.SiteIdCollection = [];
            this.CurrentExclusion.SiteIdCollection = res.map(function (el) { return el.ActorId; });
          } catch (error) {
            resolve(false);
          }
          this.asrv.GetSiteNameCollection(this.CurrentExclusion.SiteIdCollection).then(value => {
            this.CurrentExclusion.ClientValues.SiteCollection = value;
            resolve(true);
          })
        })
      })
    } else {
      return new Promise<boolean>(resolve => { resolve(false); });
    }

  }

  public UpdateSitesWithinRadius(): Promise<Site[]> {
    this.CurrentExclusion.ClientValues.SiteCollection = [];
    return new Promise<Site[]>(resolve => {
      let subscription = this.asrv.GetSitesWithinRadius(this.CurrentExclusion.SiteId, this.CurrentExclusion.Radius).subscribe(res => {
        subscription.unsubscribe();
        this.CurrentExclusion.SiteIdCollection = res.map(function (el) { return el.ActorId; });
        resolve(res);
      }, err => {
        subscription.unsubscribe();
        resolve([]);
      })
    })
  }

  public GetSitesUsingRadius(radius: number): Promise<Site[]> {
    this.CurrentExclusion.ClientValues.SiteCollection = [];
    return new Promise<Site[]>(resolve => {
      let subscription = this.asrv.GetSitesWithinRadius(this.CurrentExclusion.SiteId, radius).subscribe(res => {
        subscription.unsubscribe();
        resolve(res);
      }, err => {
        subscription.unsubscribe();
        resolve([]);
      })
    })
  }

  public ValidatePage(page: number): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      this._errors = [];
      switch (page) {
        case this._pages.customerDetail:
          this.ValidateCustomerDetail();
          break;
        case this._pages.customerAddress:
          this.ValidateCustomerAddress();
          break;
        case this._pages.excluderExclusion:
          this.ValidateExclusionInfo();
          break;
        case this._pages.exclusionZone:
          this.ValidateExclusionZone();
          break;
        case this._pages.customerPhoto:
          this.ValidateCustomerPhoto(true);
          break;
        case this._pages.termsAndConditions:
          this.ValidateTermsAndConditions();
          break;
        case this._pages.exclusionDeclaration:
          this.ExclusionDeclaration();
          break;
      }

      var result = this._errors.length > 0 ? false : true;
      if (!result) {
        this.ShowError();
      }

      // If validated and we're on the last page, save the exclusion.
      if (result && page === this._pages.exclusionDeclaration) {
        let sitename = this.CurrentExclusion.ClientValues.ExclusionSiteName;
        let radius = this.CurrentExclusion.ClientValues.Radius;

        var tags: number[] = [];
        this.CurrentExclusion.TagIdCollection.forEach(tagid => {
          if (!tags.some(i => i === tagid)) {
            tags.push(tagid);
          }
        })
        this.CurrentExclusion.TagIdCollection = tags;
        this.SaveExclusion().then(value => {
          this.CurrentExclusion = value;
          this.CurrentExclusion.ClientValues = new ExclusionClientValues();
          this.CurrentExclusion.ClientValues.ExclusionSiteName = sitename;
          this.CurrentExclusion.ClientValues.Radius = radius;
          resolve(result);
        })
      } else {
        // this._memoryManager.SetExclusionIntoSessionStorage(this.CurrentExclusion);
        resolve(result);
      }
    })
  }

  private ValidateCustomerDetail(): void {
    let biologicalModel: Views_BiologicalActorCompositeModel = this.CurrentExclusion.InteractedActorCompositeModel.BiologicalActorCompositeModel;
    // First Validate that Text Data has been populated.
    if (!biologicalModel.HonorificId) {
      this.ValidatedInput('inputTitle', false);
      this._errors.push('Title');
    } else {
      this.ValidatedInput('inputTitle', true);
    }

    if (!biologicalModel.GenderId) {
      this.ValidatedInput('inputGender', false);
      this._errors.push('Gender');
    } else {
      this.ValidatedInput('inputGender', true);
    }

    if (!biologicalModel.Forename && this.StringHasNumber(biologicalModel.Forename)) {
      this.ValidatedInput('inputFirstName', false);
      this._errors.push('Forename');
    } else {
      if (biologicalModel.Forename.length < 2) {
        this.ValidatedInput('inputFirstName', false);
        this._errors.push('Forename');
      } else {
        this.ValidatedInput('inputFirstName', true);
      }
    }

    if (!biologicalModel.Surname && this.StringHasNumber(biologicalModel.Surname)) {
      this.ValidatedInput('inputSurname', false);
      this._errors.push('Surname');
    } else {
      if (biologicalModel.Surname.length < 2) {
        this.ValidatedInput('inputSurname', false);
        this._errors.push('Surname');
      } else {
        this.ValidatedInput('inputSurname', true);
      }
    }

    // Check the Excluder is 18
    if (!this._datemethods.CheckBirthdateIsValid(new Date(biologicalModel.BirthDate))) {
      this.ValidatedInput('inputBirthDate', false);
      this._errors.push('Birthdate');
    } else {
      this.ValidatedInput('inputBirthDate', true);
    }

    if (biologicalModel.Mobile) {
      if (!this.StringHasNumber(biologicalModel.Mobile)) {
        this.ValidatedInput('inputMobile', false);
        this._errors.push('Mobile');
      } else {
        this.ValidatedInput('inputMobile', true);
      }
    }
  }

  private ValidateCustomerAddress() {
    if (!this.CurrentExclusion.InteractedActorCompositeModel.BiologicalActorCompositeModel.Postcode) {
      this.ValidatedInput('inputPostcode', false);
      this._errors.push('Postcode');
    } else {
      this.ValidatedInput('inputPostcode', true);
    }

    if (!this.CurrentExclusion.InteractedActorCompositeModel.BiologicalActorCompositeModel.Line1) {
      this.ValidatedInput('inputPostcode', false);
      this._errors.push('Select Address');
    } else {
      this.ValidatedInput('inputPostcode', true);
    }

    if (this.CurrentExclusion.InteractedActorCompositeModel.BiologicalActorCompositeModel.TownCity && !this.CurrentExclusion.InteractedActorCompositeModel.BiologicalActorCompositeModel.County) {
      this.CurrentExclusion.InteractedActorCompositeModel.BiologicalActorCompositeModel.County = this.CurrentExclusion.InteractedActorCompositeModel.BiologicalActorCompositeModel.TownCity.valueOf();
    }
  }

  private ValidateExclusionInfo(): void {
    if (!this.CurrentExclusion.InteractionMonitoringPeriod.Duration) {
      this.ValidatedInput('inputExclusionPeriod', false);
      this._errors.push('Exclusion Period');
    } else {
      this.ValidatedInput('inputExclusionPeriod', true);
    }

    // Will likely be a tag, get more tag options implemented.
    /*
    if (!this.exclusion.ExclusionReasonKeyId) {
      document.getElementById('inputReason').style.borderColor = this.errorBorderColor;
      errStrings.push('Reason');
    } else {
      document.getElementById('inputReason').style.borderColor = this.defaultBorderColor;
    }
    */

    /*
    if (!this.CurrentExclusion.InteractionMonitoringPeriod.Start) {
      this.ValidatedInput('inputStartDate', false);
      this._errors.push('Start Date');
    } else {
      this.ValidatedInput('inputStartDate', true);
    }
    */
  }

  private ValidateExclusionZone(): void {
    if (!this.CurrentExclusion.SiteId) {
      this.ValidatedInput('ctrlSites', false);
      this._errors.push('Site');
    } else {
      this.ValidatedInput('ctrlSites', true);
    }
  }

  public ValidateCustomerPhoto(fromExclusionJourney: boolean): void {
    /*
    if (!this.DummyExclusion.Image_ExcluderBase64 && fromExclusionJourney) {
      this._errors.push('Image');
    } else {
      if (this.CurrentExclusion.MediaModelCollection.some(i => i.MediaClassKeyId === 4)) {
        this.CurrentExclusion.MediaModelCollection.find(i => i.MediaClassKeyId === 4).Data = this.DummyExclusion.Image_ExcluderBase64;
      } else {
        this.CurrentExclusion.MediaModelCollection.push({Data: this.DummyExclusion.Image_ExcluderBase64, MediaClassKeyId: 4, MediaId: 0})
      }
    }
    */
  }

  private ValidateTermsAndConditions() {
    if (!this.CurrentExclusion.ClientValues.TermsAndConditions) {
      this.ValidatedInput('grpTermsAndConditions', false);
      this._errors.push('Terms & Conditions');
    } else {
      this.ValidatedInput('grpTermsAndConditions', true);
      this.AddTagToExclusion(tagReadTermsAndConditions);
    }

    if (!this.CurrentExclusion.ClientValues.ReinstateTerms) {
      this.ValidatedInput('grpReinstate', false);
      this._errors.push('Reinstatement Terms');
    } else {
      this.ValidatedInput('grpReinstate', true);
    }

    if (!this.CurrentExclusion.ClientValues.AgreeToThirdPartyContact) {
      this.ValidatedInput('grpAgreeToThirdPartyContact', false);
      this._errors.push('Third Party Details');
    } else {
      this.ValidatedInput('grpAgreeToThirdPartyContact', true);
    }

    if (!this.CurrentExclusion.ClientValues.OptOutPromo) {
      this.ValidatedInput('grpOptOutPromo', false);
      this._errors.push('Marketing & Promotional Details');
    } else {
      this.ValidatedInput('grpOptOutPromo', true);
    }

    if (!(this._errors.length > 0)) {
      this.AddTagToExclusion(tagReadTermsAndConditions);
    }
  }

  private ExclusionDeclaration() {
    if (this.CurrentExclusion.ClientValues.IdChecked) {
      if (!this.CurrentExclusion.IdentificationDocumentKeyId) {
        this.ValidatedInput('inputIDType', false);
        this._errors.push('ID Type');
      } else {
        this.ValidatedInput('inputIDType', true);
      }
    }
  }

  GetCurrentExcluderEndDate(): Date {
    return new Date(this._datemethods.AddDays(this.CurrentExclusion.InteractionMonitoringPeriod.Start, this.CurrentExclusion.InteractionMonitoringPeriod.Duration));
  }

  GetExtensionStartDate(): Date {
    return new Date(this._datemethods.AddDays(this.CurrentExclusion.InteractionMonitoringPeriod.Start, this.CurrentExclusion.InteractionMonitoringPeriod.Duration + 1));
  }

  StringHasNumber(value: string): boolean {
    return /\d/.test(value);
  }

  EligibleToReinstate(): boolean {
    if (new Date() >= this.GetCurrentExcluderEndDate() && !this.ReinstatementExists()) {
      return true;
    }
    return false;
  }

  ReinstatementExists(): boolean {
    if (this.CurrentExclusion.Reinstatement) {
      return this.CurrentExclusion.Reinstatement.InteractionId > 0;
    }
    return false;
  }

  // Generic DOM run commands
  private EnableButton(domId: string) {
    (document.getElementById(domId) as HTMLButtonElement).disabled = false;
    (document.getElementById(domId) as HTMLButtonElement).style.filter = 'Grayscale(0)';
    (document.getElementById(domId) as HTMLButtonElement).style.cursor = 'pointer';
  }

  private DisableButton(domId: string) {
    (document.getElementById(domId) as HTMLButtonElement).disabled = true;
    (document.getElementById(domId) as HTMLButtonElement).style.filter = 'Grayscale(0.75)';
    (document.getElementById(domId) as HTMLButtonElement).style.cursor = 'not-allowed';
  }

  private ValidatedInput(domId: string, validationResult: boolean) {
    document.getElementById(domId).style.borderColor = validationResult ? defaultBorderColor : errorBorderColor;
  }

  // Alert handler
  private ShowError() {
    var msg: string = 'Unable to proceed, please complete the following';
    this._errors.forEach(error => {
      msg += ', ' + error;
    })
    alert(msg + '.');
  }

  // Generic Handlers
  public SetExcluderEndDate(periodInMonths: number) {
    this.CurrentExclusion.InteractionMonitoringPeriod.Duration = this._datemethods.GetMonitoringPeriodUsingMonths(new Date(this.CurrentExclusion.InteractionMonitoringPeriod.Start), periodInMonths);
  }

  public GetEndDateForStartDate(startDate: Date, periodInMonths: number) {
    return this._datemethods.GetMonitoringPeriodUsingMonths(startDate, periodInMonths);
  }

  public AddTagToExclusion(tagId: number) {
    if (!this.CurrentExclusion.TagIdCollection.some(i => i === tagId)) {
      this.CurrentExclusion.TagIdCollection.push(tagId);
    }
  }

  public RemoveTagFromExclusion(tag: number) {
    if (this.CurrentExclusion.TagIdCollection.some(i => i === tag)) {
      let index = this.CurrentExclusion.TagIdCollection.indexOf(tag, 0);
      if (index > -1) {
        this.CurrentExclusion.TagIdCollection.splice(index, 1);
      }
    }
  }
}
