<div class="container global-seperator">
    <div class="row">
        <div class="col-md-12">
            <h4>Excluder Details</h4>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <div class="input-group" id="inputTitle">
                <div class="input-group-prepend">
                    <span class="input-group-text">Title</span>
                </div>
                <select class="form-control drop-down-purple" name="title" [(ngModel)]="esrv.CurrentExclusion.InteractedActorCompositeModel.BiologicalActorCompositeModel.HonorificId">
                    <option></option>
                    <option *ngFor="let hono of honorifics" [ngValue]="hono.HonorificId">{{hono.Name}}</option>
                </select>
            </div>
        </div>
        <div class="col-md-6">
            <div class="input-group" id="inputGender">
                <div class="input-group-prepend">
                    <span class="input-group-text">Gender</span>
                </div>
                <select class="form-control drop-down-purple" name="gender" [(ngModel)]="esrv.CurrentExclusion.InteractedActorCompositeModel.BiologicalActorCompositeModel.GenderId">
                    <option></option>
                    <option *ngFor="let gender of genders" [ngValue]="gender.GenderId">{{gender.Name}}</option>
                </select>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-6">
            <div class="input-group" id="inputFirstName">
                <div class="input-group-prepend">
                    <span class="input-group-text">First Name</span>
                </div>
                <input type="text" class="form-control" name="firstName" [(ngModel)]="esrv.CurrentExclusion.InteractedActorCompositeModel.BiologicalActorCompositeModel.Forename">
            </div>
        </div>
        <div class="col-md-6">
            <div class="input-group" id="inputSurname">
                <div class="input-group-prepend">
                    <span class="input-group-text">Surname</span>
                </div>
                <input type="text" class="form-control" name="surname" [(ngModel)]="esrv.CurrentExclusion.InteractedActorCompositeModel.BiologicalActorCompositeModel.Surname">
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-6">
            <div class="input-group" id="inputBirthDate">
                <div class="input-group-prepend">
                    <span class="input-group-text">Birth Date</span>
                </div>
                <input class="form-control d-none d-lg-block" matInput [matDatepicker]="cddp1" (dateInput)="addEvent('input', $event)" (dateChange)="addEvent('change', $event)" [(ngModel)]="esrv.CurrentExclusion.InteractedActorCompositeModel.BiologicalActorCompositeModel.BirthDate">
                <div class="input-group-append time-selector d-none d-lg-block">
                    <mat-datepicker-toggle class="form-control-date-toggle d-none d-lg-block" matSuffix [for]="cddp1"></mat-datepicker-toggle>
                </div>
                <mat-datepicker class="d-none d-lg-block" touchUi #cddp1></mat-datepicker>
                <input type="date" id="ctrlBirthDate" class="form-control date-picker-padding d-lg-none" name="birthdate" [(ngModel)]="birthDateString" (change)="updateExclsionBirthDate()">
            </div>
        </div>
        <div class="col-md-6">
            <div class="input-group" id="inputMobile">
                <div class="input-group-prepend">
                    <span class="input-group-text">Tel/Mob</span>
                </div>
                <input type="tel" class="form-control" name="mobile" [(ngModel)]="esrv.CurrentExclusion.InteractedActorCompositeModel.BiologicalActorCompositeModel.Mobile"> 
            </div>
        </div>
    </div>
    
    <div class="row">
        <!--
            <div class="col-md-6">
                <div class="input-group" id="inputTelephone">
                    <div class="input-group-prepend">
                        <span class="input-group-text">Telephone</span>
                    </div>
                    <input type="tel" class="form-control" name="telephone" [(ngModel)]="Exclusion.Landline">
                </div>
            </div>
        -->
        <div class="col-md-6">
            <div class="input-group" id="inputEmail">
                <div class="input-group-prepend">
                    <span class="input-group-text">Email</span>
                </div>
                <input type="email" class="form-control" name="email" [(ngModel)]="esrv.CurrentExclusion.InteractedActorCompositeModel.BiologicalActorCompositeModel.Email">
            </div>              
        </div>
    </div>

    <!--Update to show for only MSA users only-->
    <!-- Reintroduced soon

    <div class="row">
        <div class="col-md-6" *ngIf="isMSAUser">
            <div class="input-group" id="inputCarReg">
                <div class="input-group-prepend">
                    <span class="input-group-text">Car Reg</span>
                </div>
                <input type="text" class="form-control" name="carReg" [(ngModel)]="Exclusion.CarReg" >
            </div>
        </div>
    </div>
    -->

        <!--
            <div class="col-md-6">
                <div class="input-group" id="inputOtherInfo">
                    <div class="input-group-prepend">
                        <span class="input-group-text">Other Info</span>
                    </div>
                    <input type="text" class="form-control" name="notes" [(ngModel)]="Exclusion.Notes">
                </div>
            </div>


            -->
</div>