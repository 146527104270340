import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BasicPostModel } from '../models/PostObjects';
import { ActorService } from './actor.service';
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root'
})
export class TagService {
  private readonly organisationlookupkey: number = 0;
  private readonly sitelookupkey: number = 1;
  private readonly userlookupkey: number = 2;
  private SearchMode: number = 1; // Currently the default search outside of create.
  public Tags: Tag[] = [];
  public AllowNewTags: boolean;
  public TagsLoaded: boolean;

  // Make sure dsrv contains your get and post http calls. Otherwise this will not work.
  constructor(public dsrv: DataService) {}

  Init(allowNewTags: boolean, tagIdCollection: number[]) {
    this.AllowNewTags = allowNewTags;
    this.LoadTagCollection(tagIdCollection).then(value => {
      this.TagsLoaded = true
    });
  } 

  SetSearchMode(searchMode: number) {
    this.SearchMode = searchMode;
  }

  public LoadTagCollection(tagIdCollection: number[]): Promise<boolean> { 
    return new Promise<boolean>(resolve => {
      this.Tags = [];
      tagIdCollection.forEach(TagId => {
        this.Tags.push(new TagBuilder(TagId, this.dsrv));
      })
      resolve(true)
    })
  }

  AddTagToCollection(tag: Tag): Promise<void> {
    return new Promise<void>(resolve => {
      if (!this.Tags.some(i => i.TagId === tag.TagId)) {
        if (tag.TagId > 0) {
          this.Tags.push(tag);
        } else if (this.AllowNewTags) {
          let subscription = this.CreateTag(tag).subscribe(res => {
            subscription.unsubscribe();
            this.Tags.push(res);
          }, err => {
            subscription.unsubscribe();
          })
        }
      }
      resolve();
    })
  }

  RemoveTagFromCollection(tagIdToRemove: number): void {
    var rowToRemove = this.Tags.find(i => i.TagId === tagIdToRemove);
    let index = this.Tags.indexOf(rowToRemove, 0);
    if (index > -1) {
        this.Tags.splice(index, 1);
    }
  }

  CheckIfTagExistsForPhrase(lookupPhrase: string): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      let returnTags: Tag[] = [];
      let subscription = this.SearchTagByName(lookupPhrase).subscribe(res => {
        subscription.unsubscribe();
        resolve(true);
      }, err => {
        subscription.unsubscribe();
        resolve(false);
      });
    })
  }

  // Returns a collection of tags resembling the lookup phrase.
  TagLookup(lookupPhrase: string): Promise<Tag[]> {
    return new Promise<Tag[]>(resolve => {
      let returnTags: Tag[] = [];
      let postObject = new TagLookup();
      switch (this.SearchMode) {
        case this.organisationlookupkey:
          // Currently not in use.
          postObject.TagNameParameters.OrganisationTagNameCollection.push(lookupPhrase);
          break;
        case this.sitelookupkey:
          postObject.TagNameParameters.SiteTagNameCollection.push(lookupPhrase);
          break;
        case this.userlookupkey: 
          // Not in existance yet
          break;
      }

      let subscription = this.GetTagCollectionForSearchPhrase(postObject).subscribe(res => {
        subscription.unsubscribe();
        if (res.length > 0) {
          res.forEach(tagId => {
            returnTags.push(new TagBuilder(tagId, this.dsrv));
          })
        }
        resolve(returnTags);
      }, err => {
        // New Tag
        subscription.unsubscribe();
        resolve([{
          TagId: -1,
          Name: lookupPhrase,
          Edited: new Date(),
          Edited_ActorId: null
        }]);
      })
    })
  }

  GetTagByName(name: string): Promise<Tag> {
    return new Promise<Tag>(resolve => {
      let subsription = this.SearchTagByName(name).subscribe(res => {
        subsription.unsubscribe();
        resolve(res);
      }, err => {
        subsription.unsubscribe();
        resolve({
          TagId: -1,
          Name: name,
          Edited: new Date(),
          Edited_ActorId: null
        })
      }) 
    })
  }

  public CreateTag(tag: Tag): Observable<Tag> {
    return this.dsrv.Post<Tag>('api/tags', tag);
  }

  public SearchTagByName(name: string): Observable<Tag> {
    return this.dsrv.Get<Tag>('api/tags?Tags.name=' + name);
  }

  public GetTagCollectionForSearchPhrase(tagColleciton: any): Observable<number[]> {
    return this.dsrv.Post<number[]>('api/view_TagIds', tagColleciton);
  }
}

export interface Tag {
  TagId: number;
  Name: any;
  Edited: Date;
  Edited_ActorId: number;
}

export class TagBuilder implements Tag {
  Name: any = false;
  Edited: Date;
  Edited_ActorId: number;

  constructor(public TagId: number, public dsrv: DataService) {
    this.LoadTagDetails();
  }

  private LoadTagDetails() {
    let subscription = this.GetTag().subscribe(result => {
      subscription.unsubscribe();
      this.TagId = result.TagId;
      this.Name = result.Name;
      this.Edited = result.Edited;
      this.Edited_ActorId = result.Edited_ActorId;
    }, err => {
      subscription.unsubscribe();
      this.Name = 'Failed to load name';
    })
  }

  private GetTag(): Observable<Tag> {
    return this.dsrv.Get<Tag>('api/tags?Tags.tagId=' + this.TagId);
  }
}

class TagLookup extends BasicPostModel {
  TagNameParameters: TagNameParameters = new TagNameParameters();
  Limit: number;
  Offset: number;
}

class TagNameParameters {
  OrganisationTagNameCollection: string[] = [];
  SiteTagNameCollection: string[] = [];
}
