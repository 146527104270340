import { Injectable } from '@angular/core';
import { NonBiologicalName } from '../models/actor/Name';
import { GenericTagList } from '../models/admin/GenericTag';
import { DropDownItem } from '../models/controllers/Standard Controllers/Select';
import { CommunicationRow, MessageList } from '../models/messaging/MessageList';
import { PostObjects } from '../models/PostObjects';
import { ActorService } from './actor.service';

declare var require: any;

@Injectable({
  providedIn: 'root'
})
export class MessagingService {
  private readonly importantmessage: number = 2;
  private readonly unreadmessage: number = 3;

  private _getMessagesPostObject: PostObjects = new PostObjects();

  // Main Table Variables
  public TableDataLoaded: boolean;
  public MessageList: MessageList;

  // Show Edit Variables
  public ShowMessage: boolean;
  public ComposeMode: boolean;
  public SelectedComposer: string;
  public SelectedRecipients: DropDownItem[];
  public SelectedSubject: string;
  public SelectedBody: string;

  // Advanced Filter Variables
  public AvailableOrganisations: NonBiologicalName[] = [];
  public AvailableContacts: DropDownItem[] = [];
  public OrganisationIdFilter: number;
  public TagFilters: GenericTagList;

  // All Ids
  public AllCommunicationUserIds: number[];
  public AllowCompose: boolean;

  constructor(public asrv: ActorService) {
    this.AvailableOrganisations = this.asrv.DefaultOrganisationNameCollection;
    this._getMessagesPostObject.CommunicationAccountMessageCompositeModelController.Start = new Date('2019-01-01');
    this._getMessagesPostObject.CommunicationAccountMessageCompositeModelController.End = new Date();
    this._getMessagesPostObject.CommunicationAccountMessageCompositeModelController.Limit = 0;
    this._getMessagesPostObject.CommunicationAccountMessageCompositeModelController.Offset = 0;
    this.MessageList = new MessageList(this.asrv, []);
    this.TagFilters = new GenericTagList(this.asrv);
    this.asrv.GetEmployeeIds(0,0).then(value => {
      this.AllCommunicationUserIds = value;
    })
    this.asrv.IsLoggedUserBactaAdmin().then(value => {
      this.AllowCompose = value;
    });
  }

  SetStartDateFilter(val: Date): void {
    this._getMessagesPostObject.CommunicationAccountMessageCompositeModelController.Start = val;
  }

  SetEndDateFilter(val: Date): void {
    this._getMessagesPostObject.CommunicationAccountMessageCompositeModelController.End = val;
  }

  SetLimitFilter(val: number): void {
    this._getMessagesPostObject.CommunicationAccountMessageCompositeModelController.Limit = val;
  }

  SetOffsetFilter(val: number): void {
    this._getMessagesPostObject.CommunicationAccountMessageCompositeModelController.Offset = val;
  }

  public GetUnreadMessages(): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      this._getMessagesPostObject.CommunicationAccountMessageCompositeModelController.TagIdLookupOperator = 'NotExists';
      this._getMessagesPostObject.CommunicationAccountMessageCompositeModelController.TagIdCollection = [this.unreadmessage];
      this.GetMessageObject().then(value => {
        resolve(value);
      })
    })
  }

  public GetImportantMessage(): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      this._getMessagesPostObject.CommunicationAccountMessageCompositeModelController.TagIdLookupOperator = 'Exists';
      this._getMessagesPostObject.CommunicationAccountMessageCompositeModelController.TagIdCollection = [this.importantmessage];
      this.GetMessageObject().then(value => {
        resolve(value);
      })
    })
  }

  // Return All Messages 
  public GetAllMessages(): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      this._getMessagesPostObject.CommunicationAccountMessageCompositeModelController.TagIdLookupOperator = 'NotExists';
      this._getMessagesPostObject.CommunicationAccountMessageCompositeModelController.TagIdCollection = [];
      this.GetMessageObject().then(value => {
        resolve(value);
      })
    })
  }

  private GetMessageObject(): Promise<boolean> {
    document.body.style.cursor = 'progress';
    this.TableDataLoaded = false;
    return new Promise<boolean>(resolve => {
      let subscription = this.asrv.PostCommunicationAccountMessageCompositeModelController(this._getMessagesPostObject.CommunicationAccountMessageCompositeModelController).subscribe(res => {
        subscription.unsubscribe();
        this.MessageList = new MessageList(this.asrv, res);
        document.body.style.cursor = 'default';
        this.TableDataLoaded = true;
        resolve(true);
      }, err => {
        subscription.unsubscribe();
        this.MessageList = new MessageList(this.asrv, []);
        document.body.style.cursor = 'default';
        resolve(false);
      })
    })
  }

  public ReadMessage(communicationRow: CommunicationRow): Promise<boolean> {
    this.ComposeMode = false;
    return new Promise<boolean>(resolve => {
      if (!communicationRow.Sender) {
        this.SelectedComposer = '';
        this.asrv.GetBiologicalName([communicationRow.Edited_ActorID]).then(value => {
          this.SelectedComposer = value[0].Forename + ' ' + value[0].Surname;
        })
      } else {
        this.SelectedComposer = communicationRow.Sender.toString();
      }
      this.ParseXML(communicationRow.Body).then(value => {
        this.SelectedBody = value.accountMessage.body[0];
      });
      this.SelectedSubject = communicationRow.Subject;

      if (communicationRow.IsNew) {
        let postObject: PostObjects = new PostObjects();
        postObject.CommunicationContacts_CommunicationContactTags.ActorId = +this.asrv.GetMyUserID();
        postObject.CommunicationContacts_CommunicationContactTags.Edited_ActorId = +this.asrv.GetMyUserID();
        postObject.CommunicationContacts_CommunicationContactTags.CommunicationId = communicationRow.CommunicationId;
        postObject.CommunicationContacts_CommunicationContactTags.CommunicationMethodKeyId = 2;
        postObject.CommunicationContacts_CommunicationContactTags.TagId = 3;
        postObject.CommunicationContacts_CommunicationContactTags.Edited = new Date();
        communicationRow.IsNew = false;
        let postCommuicationContactsContactTags = this.asrv.PostCommuicationContactsContactTags(postObject.CommunicationContacts_CommunicationContactTags).subscribe(res => {
          postCommuicationContactsContactTags.unsubscribe();
        })
      }

      this.ShowMessage = true;
      resolve(true);
    })
  }

  ParseXML(xml): Promise<XMLMessage> {
    var parseString = require('xml2js').parseString;
    return new Promise<XMLMessage>(resolve => {
      parseString(xml, function(err, result: XMLMessage) {
        resolve(result);
      })
    })
  }

  public ComposeNewMessage(): void {
    this.ComposeMode = true;
    this.SelectedComposer = this.asrv.GetUsersFullName();
    this.SelectedSubject = '';
    this.SelectedBody = '';
    this.SelectedRecipients = [];
    this.ShowMessage = true;
  }

  public SendMessage(): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      let postObject: PostObjects = new PostObjects();
      postObject.CommunicationAccountMessagesCompositeModel.Subject = this.SelectedSubject;
      postObject.CommunicationAccountMessagesCompositeModel.Body = this.SelectedBody;
      postObject.CommunicationAccountMessagesCompositeModel.TagIdCollection = [];
      postObject.CommunicationAccountMessagesCompositeModel.Edited_ActorId = +this.asrv.GetMyUserID();
      postObject.CommunicationAccountMessagesCompositeModel.ActorIdCollection = this.SelectedRecipients.map(function (el) { return el.Key; });
      let subscription = this.asrv.PostCommunicationAccountMessageCompositeModel(postObject.CommunicationAccountMessagesCompositeModel).subscribe(res => {
        subscription.unsubscribe();
        resolve(true);
        this.ShowMessage = false;
      }, err => {
        subscription.unsubscribe();
        resolve(false);
      })
    })
  }

  UpdateOrganisationFilter() {
    this.asrv.PopulateColleagueDropDownForOrganisationId(this.OrganisationIdFilter).then(value => {
      this.AvailableContacts = this.asrv.ColleagueSelect.DropDownItems;
    })
  }

  UpdateTagFilters() {
    let tagIds: number[] = this.TagFilters.Rows.map(function (el) { return el.TagId; })
    this.asrv.GetEmployeeIds(0,0, null, tagIds).then(value => {
    })
  }
}

interface XMLMessage {
  accountMessage: AccountMessage;
}

interface AccountMessage {
  body: string[];
}
