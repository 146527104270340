import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { CustomerInteraction } from 'src/app/models/CustomerInteraction';
import { InteractionService } from 'src/app/services/interaction.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ActorService } from 'src/app/services/actor.service';
import { ISDDateMethods } from 'src/app/models/ISDDateMethods';
import { Views_InteractionsCompositeModel } from 'src/app/models/InteractionExclusion/Views_InteractionsCompositeModel';
import * as moment from 'moment';
import { distinctUntilChanged, filter } from 'rxjs/operators';

@Component({
  selector: 'app-interaction-view',
  templateUrl: './interaction-view.component.html',
  styleUrls: ['./interaction-view.component.scss']
})
export class InteractionViewComponent implements OnInit, AfterViewInit {

  @ViewChild('btnCancelReview') btnCancelReview: ElementRef;
  @ViewChild('divCancelReview') divCancelReview: ElementRef;

  constructor(
    public asrv: ActorService,
    public isrv: InteractionService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    // route.data.subscribe(data => {
    //   this.loadViewModel();
    // });

    this.isrv.getSaveInteraction().subscribe(result => {
      // console.log('firing obs');
      this.loadViewModel();
    });
    
  }

  public reference: string;
  public interaction: CustomerInteraction;
  public dataHasLoaded: boolean;
  public viewForm: number;
  public showFooter: boolean;
  public dummyInteraction: CustomerInteraction;
  public isArchived: boolean;
  public stage: number = 0;
  public showDefaultButtons: boolean;
  private _dateMethods: ISDDateMethods = new ISDDateMethods();
  private _justCancelled: boolean;

  public lockReview: boolean;
  public lockCancelReview: boolean;
  public formEditable: boolean = true;

  appViewInteraction = 0;
  appReview = 1;
  appReinstateSEReview = 2;
  appCancelReview = 3;
  appUploadCCTV = 4;
  appUpdateDetails = 5;
  appMoreInfo = 6;

  ngOnInit() {
    this.loadViewModel();

  }

  loadViewModel() {
    this.isrv.LoadViewModel(+this.route.snapshot.params['id']).then(value => {
      if (this._justCancelled && !this.isrv.Interaction.TagIdCollection.some(i => i === 4)) {
        this.isrv.Interaction.TagIdCollection.push(4);
        this._justCancelled = false;
      }
      this.isArchived = this.isrv.Interaction.TagIdCollection.some(i => i === 4);
      this.CheckLocks();
      this.ShowDefaultApp();
    });
  }

  ngAfterViewInit() {
  }

  CheckLocks() {
    
    // console.log('checking locks', this.isArchived);
    if (this.InteractionIncomplete()) {
      this.showDefaultButtons = false;
    } else {
      this.showDefaultButtons = true;
      let monitoringPeriod = this.isrv.Interaction.InteractionMonitoringPeriod;
      const _review = this.isrv.Interaction.Review;
      let reviewDate = this._dateMethods.AddDays(monitoringPeriod.Start, monitoringPeriod.Duration);
      // this.lockReview = reviewDate >= new Date() || this.isArchived;
      const _reviewDate = moment(this.isrv.Interaction.InteractionMonitoringPeriod.Start).add(this.isrv.Interaction.InteractionMonitoringPeriod.Duration, 'd');
      const _today = moment();
      const _dateDiff = _reviewDate.diff(_today, 'days');
      this.lockReview = (_review.InteractionId === 0 && _dateDiff > 7) || this.isArchived;
      this.lockCancelReview = this.isArchived; // (this._dateMethods.DateDifferenceInDays(reviewDate) > 1) || 
    }
  }

  InteractionIncomplete(): boolean {
    return this.isrv.Interaction.TagIdCollection.some(i => i === 5);
  }

  CompleteInteraction() {
    this.router.navigate(['/user/customer-interaction/continue/' + this.isrv.Interaction.InteractionId])
  }

  ShowDefaultApp() {
    this.viewForm = this.appViewInteraction;
    this.showFooter = false;
  }

  ShowReview() {
    this.isrv.ReviewPageVisited = false;
    this.isrv.chkGambling = false;
    this.isrv.chkPattern = false;
    this.isrv.chkBehaviour = false;
    // this.dummyInteraction = new CustomerInteraction();
    this.viewForm = this.appReview;
    this.showFooter = true;
  }

  ShowReinstateSEReview() {
    this.viewForm = this.appReinstateSEReview;
    this.stage = 0;
    this.showFooter = true;
  }

  ShowCancelReview() {
    if (confirm('This will place this interaction into the Archive, do you wish to continue?')) {
      this.isrv.CancelReview().then(value => {
        this.router.navigate(['user/customer-interaction/gallery']);
      });
    }
  }

  ShowUploadCCTV() {
    /*
    this.viewForm = this.appUploadCCTV;
    */
  }

  ShowUpdateDetails() {
    this.viewForm = this.appUpdateDetails;
    this.stage = 0;
    this.showFooter = true;
  }

  SetLoadingData() {
    this.dataHasLoaded = false;
  }

  ReloadMainApp() {
    this.isArchived = this.isrv.Interaction.TagIdCollection.some(i => i === 4);
    this.CheckLocks();
    this.asrv.GetMediaForInteraction(this.isrv.Interaction.InteractionId).then(value => {
      this.isrv.Interaction.MediaModelCollection.push(value);
      this.dataHasLoaded = true;
    })
  }

  ReloadAndShowMainApp() {
    this.isArchived = this.isrv.Interaction.TagIdCollection.some(i => i === 4);
    this.CheckLocks();
    this.asrv.GetMediaForInteraction(this.isrv.Interaction.InteractionId).then(value => {
      this.isrv.Interaction.MediaModelCollection.push(value);
      this.ShowDefaultApp();
      this.dataHasLoaded = true;
    })
  }

  ShowMoreInfo() {
    this.viewForm = this.appMoreInfo;
  }
}
