<div *ngIf="evsrv.ShowLoadingSpinner" class="container global-seperator" style="min-height:400px;">
  <span>
    <app-loading></app-loading>
  </span>
</div>

<div class="container global-seperator" *ngIf="!evsrv.ShowLoadingSpinner">

  <!--Desktop/Tablet Card-->
  <div *ngIf="evsrv.ShowCard" class="d-none d-md-block">
    <app-exclusion-card-md></app-exclusion-card-md>
  </div>

  <div *ngIf="evsrv.ShowCard" class="d-block d-md-none" style="margin:auto">
    <app-exclusion-card-mobile></app-exclusion-card-mobile>
  </div>

  <div class="breach-notes--container">
    <h4>Breach Notes</h4>
    <div class="breach-notes--list">
      <div *ngIf="!dataProcessed" class="inline-spinner">
        <div class="spinner-border" style="width:40px; height: 40px;" role="status">
          <span class="sr-only"></span>
        </div>
      </div>
      <table class="table table-sm table-bordered" *ngIf="dataProcessed">
        <thead>
          <tr>
            <th scope="col">#</th>
            <!-- <th scope="col">Reference</th> -->
            <th scope="col">Site Name</th>
            <th scope="col">Breach Date</th>
            <th scope="col">Recorded By</th>
            <th scope="col">Notes</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of breachNotes; index as i">
            <th scope="row">{{i+1}}</th>
            <!-- <td>{{item.Reference}}</td> -->
            <td>{{item.SiteName}}</td>
            <td>{{item.BreachedDateTime}}</td>
            <td>{{item.Name}}</td>
            <td>{{item.Notes}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>