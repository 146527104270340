import { Component, OnInit, ViewChild, Input, Output, EventEmitter, ElementRef, AfterViewInit } from '@angular/core';
import { Tag } from 'src/app/services/tag.service';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ActorService } from 'src/app/services/actor.service';
import { TagService } from 'src/app/services/tag.service';

@Component({
  selector: 'app-tags',
  templateUrl: './tags.component.html',
  styleUrls: ['./tags.component.scss']
})
export class TagsComponent implements OnInit, AfterViewInit {

  // Control Variables
  public tagLookUpController = new Subject<string>();
  public loadingTagList: boolean;
  public userSearchPhrase: any;
  public loadedTags: Tag[] = [];
  public userSelectedTag: Tag;


  @ViewChild('btnPushTag') btnPushTag: ElementRef;
  public insertTag: boolean = false;

  public allowNewTags: boolean;
  @Input('allowNewTags') get AllowNewTags(): boolean {
    return this.allowNewTags;
  };
  @Output('allowNewTags') allowNewTagsChange: EventEmitter<boolean> = new EventEmitter();
  set AllowNewTags(val: boolean) {
    this.allowNewTags = val;
    this.allowNewTagsChange.emit(this.allowNewTags);
  }  

  public lookupOperator: string;
  @Input('lookupOperator') get LookupOperator(): string {
    return this.lookupOperator;
  };
  @Output('lookupOperator') lookupOperatorChange: EventEmitter<string> = new EventEmitter();
  set LookupOperator(val: string) {
    this.lookupOperator = val;
    this.lookupOperatorChange.emit(this.lookupOperator);
  }  

  constructor(public asrv: ActorService, public tsrv: TagService) { 
    this.tagLookUpController.pipe(
      debounceTime(400),
      distinctUntilChanged()
    ).subscribe(value => {
      if (value.length > 1) {
        if (this.tsrv.AllowNewTags) {
          this.tsrv.GetTagByName(this.userSearchPhrase).then(value => {
            this.userSelectedTag = value;
            this.EnableAddTag();
          })
        } else {
          this.tsrv.TagLookup(this.userSearchPhrase).then(value => {
            this.loadedTags = value;
            this.loadingTagList = false;
          });
        }
      } else {
        if (this.tsrv.AllowNewTags) {
          this.DisablePushTag();
        }
        this.loadedTags = [];
        this.loadingTagList = false;
      }
    });   
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.DisablePushTag();
  }

  AddTag() {
    this.tsrv.AddTagToCollection(this.userSelectedTag).then(value => {});
    this.userSearchPhrase = '';
    this.loadedTags = [];

    if (this.tsrv.AllowNewTags) {
      this.DisablePushTag()
    }
  }

  RemoveTag(ev: any) {

  }

  DisablePushTag() {
    if (this.btnPushTag) {
      this.btnPushTag.nativeElement.disabled = true;
      this.btnPushTag.nativeElement.style.filter = 'Grayscale(0.5)';
    }
  }

  EnableAddTag() {
    this.btnPushTag.nativeElement.disabled = false;
    this.btnPushTag.nativeElement.style.filter = 'Grayscale(0)';
    this.insertTag = false;
  }

  EnableInsertTag() {
    this.btnPushTag.nativeElement.disabled = false;
    this.btnPushTag.nativeElement.style.filter = 'Grayscale(0)';
    this.insertTag = true;
  }

  @Output() TagAddedOrRemoved = new EventEmitter<string>();
}

class TagLookup {
  OrganisationParameters: OrganisationParameters = new OrganisationParameters();
  SiteParameters: SiteParameters = new SiteParameters();
  TagNameParameters: TagNameParameters = new TagNameParameters();
  Limit: 1000;
  Offset: 0;
}

class OrganisationParameters {
  OrganisationIdCollection: number[] = []; 
  TagIdLookupOperator: string = 'NotExists';
  TagIdCollection: number[] = [];
}

class SiteParameters {
  SiteIdCollection: number[] = [];
  TagIdLookupOperator: string ='NotExists';
  TagIdCollection: number[] = [];
}

class TagNameParameters {
  OrganisationTagNameCollection: string[] = [];
  SiteTagNameCollection: string[] = [];
}