import { Component, HostListener, isDevMode } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'BactaPortal';
  
  @HostListener('contextmenu', ['$event'])
  onRightClick(event) {
    // if (!isDevMode) {
    //   event.preventDefault();
    // }
    // if (environment.production) event.preventDefault();
    event.preventDefault();
  }

  constructor() {
    // console.log('BACTA App is running in Prod mode:', isDevMode());
    // console.log('BACTA Env running in prod mode', environment.production);
  }
}
