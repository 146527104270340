import { ControllerMaster } from "../ControllerMaster";

export class Checkbox extends ControllerMaster {
    constructor(
        public Id: number, 
        public Name: string, 
        public Label: string, 
        public DomId: string,
        public Value: boolean,
    ) {
        super(Id, Name, Label, 'checkBox', DomId)
    }
}