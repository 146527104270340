import { Component, OnInit } from '@angular/core';
import { ActorService } from 'src/app/services/actor.service';
import { ExcluderService } from 'src/app/services/excluder.service';

@Component({
  selector: 'app-excluder-exclusion',
  templateUrl: './excluder-exclusion.component.html',
  styleUrls: ['./excluder-exclusion.component.scss', '../generic-form.component.scss']
})
export class ExcluderExclusionComponent implements OnInit {

  constructor(
    public asrv: ActorService,
    public esrv: ExcluderService
  ) { }

  selectedDurationIndex: number = 6;
  durationPeriods: number[] = [6, 7, 8, 9, 10, 11, 12]
  exclusionDate: string;

  ngOnInit() {
    if (!this.esrv.CurrentExclusion.InteractionMonitoringPeriod.Start) {
      this.esrv.CurrentExclusion.InteractionMonitoringPeriod.Start = new Date();
    }

    if (!this.esrv.CurrentExclusion.InteractionMonitoringPeriod.Duration) {
      this.esrv.SetExcluderEndDate(this.durationPeriods[this.selectedDurationIndex]);
    }
  }

  updateExclusionStart() {
    this.esrv.CurrentExclusion.InteractionMonitoringPeriod.Start = new Date(this.exclusionDate);
  }

  periodMovePrevious() {
    if (this.selectedDurationIndex <= 0) {
      this.selectedDurationIndex = 0;
    } else
    {
      this.selectedDurationIndex--;
    }
    this.esrv.SetExcluderEndDate(this.durationPeriods[this.selectedDurationIndex]);
  }

  periodMoveNext() {
    if(this.selectedDurationIndex >= this.durationPeriods.length - 1)
    {
      this.selectedDurationIndex = this.durationPeriods.length - 1;
    }
    else
    {
      this.selectedDurationIndex++;
    }
    this.esrv.SetExcluderEndDate(this.durationPeriods[this.selectedDurationIndex]);
  }

}
