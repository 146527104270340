import { ExclusionClientValues } from './ExclusionClientValues';
import { Views_InteractedActorCompositeModel } from './Views_InteractedActorCompositeModel';
import { Views_InteractionMonitoringPeriodsModel } from './Views_InteractionMonitoringPeriodsModel';
import { InteractionsCompositeModel, Views_InteractionsCompositeModel } from './Views_InteractionsCompositeModel';
import { Views_MediaModel } from './Views_MediaModel';

/*
    Main Model for Exclusions, used in the Exclusion Service as the base.
*/

export class Views_Put_ExclusionsCompositeModel extends Views_InteractionsCompositeModel {
    ExclusionReasonKeyId: number;
    IdentificationDocumentKeyId: number;
    Radius: number;
    SiteIdCollection: number[] = [];
    SiteCollectionIdCollection: number[] = [];
}

export class PutExclusionsCompositeModel extends InteractionsCompositeModel {
    SiteCollectionIdCollection: number[];
    constructor(
        public InteractionId,
        public AssociatedActorIdCollection,
        public InteractedActorCompositeModel,
        public InteractionMonitoringPeriod,
        public MediaModelCollection,
        public Notes,
        public Reference,
        public SiteId,
        public TagIdCollection: number[],
        public VehicleCollection,
        public ExclusionReasonKeyId: number,
        public IdentificationDocumentKeyId: number,
        public Radius: number,
        public SiteIdCollection: number[]
    ) {
        super(
            InteractionId, 
            AssociatedActorIdCollection, 
            InteractedActorCompositeModel, 
            InteractionMonitoringPeriod, 
            MediaModelCollection, 
            Notes,
            Reference,
            SiteId,
            TagIdCollection,
            VehicleCollection
        );
    }
}