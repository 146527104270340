import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Breach } from 'src/app/models/Breach';
import { ActorService } from 'src/app/services/actor.service';
import { Site } from 'src/app/models/Site';
import { OrganisationService } from 'src/app/services/organisation.service';
import { DataService } from 'src/app/services/data.service';
import { ExcluderService } from 'src/app/services/excluder.service';
import { View_ExclusionBreachesCompositeModel } from 'src/app/models/InteractionExclusion/Breach/View_ExclusionBreachesCompositeModel';
import { BiologicalUserInfo, NonBiologicalName } from 'src/app/models/actor/Name';
import { ExcluderViewService } from 'src/app/services/excluder-view.service';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';

@Component({
  selector: 'app-record-breach',
  templateUrl: './record-breach.component.html',
  styleUrls: ['./record-breach.component.scss', '../view.component.scss']
})
export class RecordBreachComponent implements OnInit {

  constructor(
    public dsrv: DataService,
    public asrv: ActorService,
    public esrv: ExcluderService,
    public evsrv: ExcluderViewService
  ) { }

  public loadingSiteDropDown: boolean;
  public siteDropDown: NonBiologicalName[] = [];
  public loadingEmployeeDropDown: boolean;
  public employeeId: number;
  public employeeDropDown: BiologicalUserInfo[] = [];
  public loadingUserDropDown: boolean;

  public showSendingSpinner: boolean = false;
  public actorName: string;
  public breach: View_ExclusionBreachesCompositeModel;

  @ViewChild('update', { static: true }) updateElement: ElementRef;  

  events = [];
  addEvent(type: string, event: MatDatepickerInputEvent<Date>) {
    this.events.push(`${type}: ${event.value}`);
  }

  ngOnInit() {
    this.breach = new View_ExclusionBreachesCompositeModel();
    this.breach.Exclusions_InteractionId = this.esrv.CurrentExclusion.InteractionId;
    this.actorName = this.dsrv.GetUsername();
    this.loadingSiteDropDown = true;
    this.loadingEmployeeDropDown = true;
    this.PopulateSiteDropDown();
  }

  PopulateSiteDropDown() {
    this.loadingSiteDropDown = true;
    this.loadingEmployeeDropDown = true;
    this.asrv.PopulateDefaultEmployeeSitesSelect().then(value => {
      this.breach.SiteId = this.asrv.SiteSelect.KeySelected;
      this.loadingSiteDropDown = false;
      this.PopulateEmployeeDropDown();
    })
  }

  PopulateEmployeeDropDown() {
    this.loadingEmployeeDropDown = true;
    this.asrv.PopulateColleagueSelectForSiteId(this.breach.SiteId).then(value => {
      this.employeeId = this.asrv.GetMyUserID();
      this.loadingEmployeeDropDown = false;
    })
  }

  ValidateAndSendData() {
    if (!this.showSendingSpinner) {
      this.updateElement.nativeElement.disabled = true;
      if (!this.breach.ControllerDate) {
        alert('Please input the date of the breach.');
        this.updateElement.nativeElement.disabled = false;
      } else if (!this.breach.ControllerTime) {
        alert('Please input the time of the breach.');
        this.updateElement.nativeElement.disabled = false;
      } else if (!this.breach.SiteId) {
        alert('Please select the site where the breach took place.')
        this.updateElement.nativeElement.disabled = false;
      } else if (!this.employeeId) {
        alert('Please select the employee reporting the breach.');
        this.updateElement.nativeElement.disabled = false;
      } else {
        this.showSendingSpinner = true;
        this.breach.SetBreached();
        this.breach.AssociatedActorIdCollection = [];
        this.breach.AssociatedActorIdCollection.push(this.employeeId);

        this.esrv.SaveBreach(this.breach).then(value => {
          if (value) {
            this.evsrv.ShowMainView(true)
          } else {
            this.updateElement.nativeElement.disabled = false;
            this.showSendingSpinner = false;
          }
        });
      }
    }
  }
}
