import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, of, throwError, Subject, BehaviorSubject } from 'rxjs';
import { catchError, concatMap, delay, retry, retryWhen, tap, timeout } from 'rxjs/operators';
import { SecurityToken } from '../models/SecurityToken';
import { ErrorhanderService } from './errorhander.service'
import { Token } from '../models/Token';
import { UserSites } from '../models/UserSites';
import { ExcluderBrief } from '../models/ExcluderBrief';
import { Actor_Settings } from '../models/Actor_Settings';
import { Actors_Tags } from '../models/Actors_Tags';
import { UserDocumentation } from '../models/UserDocumentation';
import { Names, NameDetails, Actors_Names } from '../models/Name';
import { OrganisationBrief } from '../models/OrganisationBrief';
// import { settings } from '../../assets/settings.json'
import { HtmlParser } from '@angular/compiler';
import { duration } from 'moment';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor(private http: HttpClient,
    private router: Router,
    public errsrv: ErrorhanderService) {
    this.onError = new EventEmitter();
  }

  dataLoaded = false;
  memWord = '';
  authToken = '';

  private lqtkey: string = 'TW78-WN15-WR57-MT84';
  private addressFindEndpoint: string = 'https://services.postcodeanywhere.co.uk/Capture/Interactive/Find/v1.10/json3.ws?';
  private addressRetrieveEndpoint: string = 'https://services.postcodeanywhere.co.uk/Capture/Interactive/Retrieve/v1.10/json3.ws?';
  private geocodeRetrieveEndpoint: string = 'https://api.addressy.com/Geocoding/UK/Geocode/v2.1/json3.ws?';
  // Update Endpoint on error handler if different here.
  private Endpoint = environment.apiURL;
  public onError: EventEmitter<string>;
  public lastPostModel: any;

  public postErrors = false;
  public memwordError = new Subject<boolean>();
  public initGallery = new Subject<boolean>();


  public GetVersion(): string {
    let version = 'v1.3.4';
    if (this.Endpoint.includes('staging')) {
      version += ' - Staging';
    }
    return version;
  }

  public getInitGallery(): Observable<boolean> {
    return this.initGallery;
  }

  public setInitGallery(val: boolean) {
    this.initGallery.next(val);
  }

  public getMemwordError(): Observable<boolean> {
    return this.memwordError;
  }

  public setMemwordError(val: boolean) {
    this.memwordError.next(val);
  }

  public Credentials(): string {
    return sessionStorage.getItem("BactaapiCreds");
  } /* base64 */

  public SetMyUserID(uid: number) {
    sessionStorage.setItem("BactaUserID", uid.toString());
  }

  public GetMyUserID(): number {
    return +sessionStorage.getItem('BactaUserID');
  }

  public GetUserSites(): UserSites[] {
    return JSON.parse(sessionStorage.getItem('UserSites'));
  }

  public SetUserSites(sites: UserSites[]) {
    sessionStorage.setItem('UserSites', JSON.stringify(sites));
  }

  public GetUserSettings(): Actor_Settings[] {
    return JSON.parse('UserSettings');
  }

  public SetUserSettings(actorSettings: Actor_Settings[]) {
    sessionStorage.setItem('UserSettings', JSON.stringify(actorSettings));
  }

  public GetUserTags(): Actors_Tags[] {
    return JSON.parse('UserTags');
  }

  public SetUserTags(userTags: Actors_Tags[]) {
    sessionStorage.setItem('UserTags', JSON.stringify(userTags));
  }

  public ClearExclusionProgress() {
    sessionStorage.removeItem('NewExclusionProgress');
    sessionStorage.setItem('NewExclusionProgressPage', '0');
  }

  public GetNewExclusionProgressPage(): number {
    return +sessionStorage.getItem('NewExclusionProgressPage');
  }

  public SetNewExclusionProgressPage(page: number) {
    sessionStorage.setItem('NewExclusionProgressPage', page.toString());
  }

  public RemoveUpdateExclusion() {
    sessionStorage.removeItem('UpdateExclusion');
  }

  public GetSelectedExclusion(): ExcluderBrief {
    return JSON.parse(sessionStorage.getItem('SelectedExclusion'));
  }

  public SetSelectedExclusion(excluder: ExcluderBrief) {
    sessionStorage.setItem('SelectedExclusion', JSON.stringify(excluder));
  }

  public RemoveSelectedExclusion() {
    sessionStorage.removeItem('SelectedExclusion');
  }

  public GetSelectedExclusionId(): number {
    return +sessionStorage.getItem('SelectedExclusionId');
  }

  public SetSelectedExclusionId(id: number) {
    sessionStorage.setItem('SelectedExclusionId', id.toString());
  }

  public RemoveSelectedExclusionId() {
    sessionStorage.removeItem('SelectedExclusionId');
  }

  public SetUsername(firstName, surname) {
    sessionStorage.setItem("Username", firstName + ' ' + surname);
  }

  public GetUsername(): string {
    return sessionStorage.getItem("Username");
  }

  public SetMyOrganisationBriefs(organisationBrief: OrganisationBrief[]) {
    var title: string = '_' + btoa('actorOrganisations');
    localStorage.setItem(title, JSON.stringify(organisationBrief))
  }

  public GetMyOrganisationBriefs(): OrganisationBrief[] {
    var title: string = '_' + btoa('actorOrganisations');
    return JSON.parse(localStorage.getItem(title));
  }

  // Directories for speeding the page up.
  public AddToNameDirectory(id: number, name: string) {
    var directory: Names[] = JSON.parse(sessionStorage.getItem('_names'));
    if (!directory) {
      directory = [];
    }
    var newEntry: Names = new Names;

    if (!(directory.find(i => i.NameId === id))) {
      newEntry.NameId = id;
      newEntry.Name = name;
      directory.push(newEntry)
    }
    sessionStorage.setItem('_names', JSON.stringify(directory));
  }
  public GetNameDirectory(): Names[] {
    var directory = JSON.parse(sessionStorage.getItem('_names'));
    if (!directory) {
      directory = [];
    }
    return directory;
  }

  public AddToActorIdToNameIdDirectory(actorsNames: Actors_Names[]) {
    var directory: NameDetails[] = JSON.parse(sessionStorage.getItem('_actorIdToNameId'));
    if (!directory) {
      directory = [];
    }
    actorsNames.forEach(name => {
      if (!(directory.find(i => i.ActorId === name.ActorId && i.NameClassKeyId === name.NameClassKeyId))) {
        var newEntry: NameDetails = new NameDetails();
        newEntry.ActorId = name.ActorId;
        newEntry.NameId = name.NameId;
        newEntry.NameClassKeyId = name.NameClassKeyId;
        directory.push(newEntry);
      }
    })

    sessionStorage.setItem('_actorIdToNameId', JSON.stringify(directory));
  }
  public GetActorIdToNameIdDirectory(): NameDetails[] {
    var directory = JSON.parse(sessionStorage.getItem('_actorIdToNameId'));
    if (!directory) {
      directory = [];
    }
    return directory;
  }

  public SetToken(token: Token) {
    sessionStorage.setItem('UserToken', JSON.stringify(token))
  }

  public GetToken(): Token {
    return JSON.parse(sessionStorage.getItem('UserToken'));
  }

  public GetUserDocumentationVersions(): UserDocumentation {
    return JSON.parse(localStorage.getItem('UserDocumentationVersions'))
  }

  public SetUserDocumentationVersions(userDocumentation: UserDocumentation) {
    localStorage.setItem('UserDocumentationVersions', JSON.stringify(userDocumentation));
  }

  public ClearExclusionCache() {
    this.RemoveSelectedExclusion();
    this.RemoveSelectedExclusionId();
  }

  public SetAdminUserList(rows: any[]) {
    sessionStorage.setItem('AdminUserList', JSON.stringify(rows));
  }

  public GetAdminUserList(): any[] {
    let result;
    if (!sessionStorage.getItem('AdminUserList')) {
      result = [];
    } else {
      result = JSON.parse(sessionStorage.getItem('AdminUserList'));
    }
    return result;
  }

  // End of Memory Manager

  private SetUser(user) {
    this.SetMyUserID(user.ActorIdCollection[0]);
    this.SetToken(user.Token)
  }

  private GetHeader(uri: string): HttpHeaders {
    /* remove comment with new API version
    if (uri !== 'View_SessionTokens') {
      return new HttpHeaders({
        'Authorization': 'Bearer '+this.GetToken()
      }); 
    }
    */
    return new HttpHeaders({
      'Authorization': 'Basic ' + this.Credentials()
    });
  }

  public Get<T>(uri: string): Observable<T> {
    let head: HttpHeaders = this.GetHeader(uri);

    sessionStorage.setItem("sentUri", uri);
    sessionStorage.setItem("sentHeader", head.getAll.toString());

    return this.http.get<T>(this.Endpoint + uri).pipe(
      timeout(120000),
      retryWhen(errors => errors.pipe(
        concatMap((error, count) => {
          if (error.status === 404) {
            // Write a handler for user documents.

            // End of requests
            return throwError(error);
          } else if (error.status >= 500 && error.status <= 599) {
            this.errsrv.ShowHttpOops(error, false);
            return throwError(error);
          } else if (error.status === 401 || error.status === 403) {
            this.WipeTokens();
            window.location.href = '#'
          } else if (count === 3) {
            this.errsrv.ShowHttpOops(error, false);
            return throwError(error);
          } else {
            return of(count);
          }
        }),
        delay(500)
      ))
    );
    //catchError((err: any, caught: Observable<T>) => this.handleError(err, caught)));
  }

  public Post<T>(uri: string, model: T): Observable<T> {
    let head: HttpHeaders = this.GetHeader(uri);
    this.postErrors = false;
    this.lastPostModel = model;
    return this.http.post<T>(this.Endpoint + uri, model).pipe(
      timeout(120000),
      retryWhen(errors => errors.pipe(
        concatMap((error, count) => {
          if (error.status === 404) {
            return throwError(error);
          } else if (error.status >= 500 && error.status <= 599) {

            this.errsrv.ShowHttpOops(error, true);
            return throwError(error);
          } else if (error.status === 401 || error.status === 403) {
            this.WipeTokens();
            window.location.href = '#'
          } else if (count === 3 && error.status !== 418) {
            this.errsrv.ShowHttpOops(error, true);
            return throwError(error);
          } else if (error.status === 418) {
            this.setMemwordError(true);
            this.postErrors = true;
            return throwError(error);
          } else {
            return of(count);
          }
        }),
        delay(500)
      ))
    );
  }

  public Put<T>(uri: string, model: T): Observable<T> {
    let head: HttpHeaders = this.GetHeader(uri);
    return this.http.put<T>(this.Endpoint + uri, model, { headers: head }).pipe(
      retryWhen(errors => errors.pipe(
        concatMap((error, count) => {
          if (error.status === 404) {
            return throwError(error);
          } else if (error.status >= 500 && error.status <= 599) {
            this.errsrv.ShowHttpOops(error, true);
            return throwError(error);
          } else if (error.status === 401 || error.status === 403) {
            this.WipeTokens();
            window.location.href = '#'
          } else if (count === 3) {
            this.errsrv.ShowHttpOops(error, true);
            return throwError(error);
          } else {
            return of(count);
          }
        }),
        delay(500)
      ))
    );
  }

  public Delete<T>(uri: string): Observable<T> {
    let head: HttpHeaders = this.GetHeader(uri);
    return this.http.delete<T>(this.Endpoint + uri, { headers: head }).pipe(
      retryWhen(errors => errors.pipe(
        concatMap((error, count) => {
          if (error.status === 404) {
            return throwError(error);
          } else if (error.status >= 500 && error.status <= 599) {
            this.errsrv.ShowHttpOops(error, false);
            return throwError(error);
          } else if (error.status === 401 || error.status === 403) {
            this.WipeTokens();
            window.location.href = '#'
          } else if (count === 3) {
            this.errsrv.ShowHttpOops(error, false);
            return throwError(error);
          } else {
            return of(count);
          }
        }),
        delay(500)
      ))
    );
  }

  public getAddressId<T>(postcode: string): Observable<T> {
    return this.http.get<T>(this.addressFindEndpoint + 'Key=' + this.lqtkey + '&Text=' + postcode)
  }
  public getAddressList<T>(postcode: string, Id: string): Observable<T> {
    return this.http.get<T>(this.addressFindEndpoint + 'Key=' + this.lqtkey + '&Text=' + postcode + '&Container=' + Id)
  }
  public getAddressDetails<T>(Id: string): Observable<T> {
    return this.http.get<T>(this.addressRetrieveEndpoint + 'Key=' + this.lqtkey + '&Id=' + Id)
  }
  public getGeocode<T>(location: string) {
    return this.http.get<T>(this.geocodeRetrieveEndpoint + 'Key=' + this.lqtkey + '&Location=' + location);
  }

  public Login(Username: string, Password: string): Observable<any> {
    this.authToken = btoa(Username + ':' + Password);
    this.WipeTokens();
    sessionStorage.setItem("BactaapiCreds", btoa(Username + ':' + Password));
    let head: HttpHeaders = new HttpHeaders({
      'Authorization': 'Basic ' + this.Credentials()
    });
    return this.http.get<SecurityToken>(this.Endpoint + 'api/View_SessionTokens', { headers: head }).pipe(
      tap(x => this.SetUser(x)),
      // Updated to return the error code to the calling method.
      catchError(err => of(err.status)
      ));
  }

  public LogOut() {
    let head: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'SecurityToken': sessionStorage.getItem("BactaapiCreds")
    });
    this.WipeTokens();
    sessionStorage.removeItem('BactaUserID');
    return this.http.post<SecurityToken>(this.Endpoint + 'api/View_SessionTokens', { headers: head }).pipe(
      catchError(err => of(err.status)
      ));
  }

  public IsLoggedIn(): boolean {
    this.http.get<boolean>(this.Endpoint + 'auth').subscribe(res => {
      if (!res) {
        this.WipeTokens();
        return false;
      }
    });
    return true;
  }

  public WipeTokens() {
    sessionStorage.removeItem('BactaapiCreds');
    sessionStorage.removeItem('Username');
    sessionStorage.removeItem('UserToken');

    sessionStorage.setItem('BactaapiCreds', null);
    sessionStorage.setItem('Username', null);
    sessionStorage.setItem('UserToken', null);
  }

  public CreateInterval(): Observable<boolean> {
    return this.http.get<boolean>('').pipe();
  }
}
