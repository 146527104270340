import { Honorific } from '../Honorific';
import { Gender } from '../Gender';
import { ExclusionDurationOption } from '../ExclusionDurationOption';
import { SiteCollection } from '../SiteCollection';
import { ISDDateMethods } from '../ISDDateMethods';
import { ActorService } from 'src/app/services/actor.service';
import { ExcluderService } from 'src/app/services/excluder.service';
import { InteractionService } from 'src/app/services/interaction.service';
import { ApiService } from 'src/app/services/api.service';
import * as moment from 'moment';

// Class for creating tabular reports.
// Every tabular class requires the following properties.
// Rows: any[] = [] --Holds all the newRows pushed.
// HeaderTransaltion = [{}]  --This will print all the headers to the table.
// newRow = [{}] --Make the values all blank but match the HeaderTranslationIds
// public AddRow(rowObject) {} --Use this push rows, ensure all values are hardcoded for now.

export class CleaningCheckList {
  private DateMethods: ISDDateMethods = new ISDDateMethods();
  public Rows: any[] = [];
  public Honorifics: Honorific[] = [];
  public Genders: Gender[] = [];
  public DurationOptions: ExclusionDurationOption[] = [];
  public SubscriptionsOpened: number;
  public CompletedSubscriptions: number = 0;
  public DataLoaded: boolean = false;
  private siteNamePromises: SiteNamePromise[] = [];
  public HeaderTranslation = [{
    'Recorded': 'Date of Check',
    'name': 'Name',
    'SiteId': 'Site Name',
    'CheckOK': 'Check OK',
    'Notes': 'Notes',
  }];
  public isLoading = true;

  constructor(
    public asrv: ActorService,
    public isrv: InteractionService,
    public ids: number[],
    private _api: ApiService,
  ) {
    this.ids.forEach(id => {
      this.AddRow(id);
    })

    this.DataLoaded = true;
  }

  public AddRow(id: number) {
    let idWasShown = 'No';
    let returnRow = {};
    returnRow['InteractionId'] = id;
    returnRow['Recorded'] = false;
    returnRow['name'] = false;
    returnRow['SiteId'] = false;
    returnRow['CheckOK'] = false;
    returnRow['Notes'] = false;
    this._api.getCleaningCheckCompositeModel(id).subscribe(res => {
      // res.InteractedActorCompositeModel = res.InteractedActorCompositeModelCollection[0];
      // if (!(res.InteractedActorCompositeModel.BiologicalActorCompositeModel.Forename && res.InteractedActorCompositeModel.BiologicalActorCompositeModel.Surname)) {
      //     returnRow['Name'] = res.InteractedActorCompositeModel.BiologicalActorCompositeModel.Alias;
      // } else {
      //     returnRow['Name'] = res.InteractedActorCompositeModel.BiologicalActorCompositeModel.Forename + ' ' + res.InteractedActorCompositeModel.BiologicalActorCompositeModel.Surname;
      // }
      let itemType: string = '';
      let checkOK: string = 'No';
      res.TagIdCollection.forEach(tagId => {
        // if (tagId >= 214 && tagId <= 224) {
        //   switch (tagId) {
        //     case 159:
        //       itemType = 'Other';
        //       break;
        //     case 241:
        //       itemType = 'Fire Alarm';
        //       break;
        //     case 242:
        //       itemType = 'Fire Blanket';
        //       break;
        //     case 243:
        //       itemType = 'Fire Door';
        //       break;
        //     case 244:
        //       itemType = 'Fire Extinguisher';
        //       break;
        //   }
        // }

        if (tagId === 3) { checkOK = 'Yes' }


      })
      returnRow['Recorded'] = moment(res.Recorded, 'YYYY-MM-DDTHH:mm:ssZ').format('YYYY-MM-DD HH:mm');;
      
      returnRow['CheckOK'] = checkOK;
      returnRow['Notes'] = res.Notes;
      this.GetSiteName(res.SiteId).then(value => {
        returnRow['SiteId'] = value;
      })

      this.asrv.GetActorNames(res.AssociatedActorIdCollection).subscribe(res => {
        res.forEach(employee => {
          const _employee = [];
          _employee.push({
            ActorId: employee.ActorId,
            Forename: employee.NameCollection.find(i => i.NameClassKeyId === 1).Name,
            Surname: employee.NameCollection.find(i => i.NameClassKeyId === 2).Name,
            ActorRoleCollection: null,
            
          });
          returnRow['name'] = `${_employee[0].Forename} ${_employee[0].Surname}`;
        })
      });
      this.isLoading = false;
    })

    this.Rows.push(returnRow);
  }

  GetSiteName(siteid): Promise<string> {
    if (this.siteNamePromises.some(i => i.SiteId === siteid)) {
      return this.siteNamePromises.find(i => i.SiteId === siteid).Promise;
    } else {
      return new Promise<string>(resolve => {
        this.asrv.GetSiteNameCollection([siteid]).then(value => {
          if (value) {
            resolve(value[0].Name);
          } else {
            resolve('');
          }
        })
      })
    }
  }
}

class SiteNamePromise {
  SiteId: number;
  Promise: Promise<string>;
}
