import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BiologicalUserInfo, NonBiologicalName } from 'src/app/models/actor/Name';
import { Organisation } from 'src/app/models/Organisation';
import { UserSites } from 'src/app/models/UserSites';
import { ActorService } from 'src/app/services/actor.service';
import { AdminService } from 'src/app/services/admin.service';

const creationOrganisation: number = 0;
const creationSite: number = 1;
const creationUser: number = 2;
const creationCredentials: number = 3;
const primarycontact: number = 21;
const dataprotectionofficer: number = 23;
const headquarters: number = 41;

@Component({
  selector: 'app-edit-organisation',
  templateUrl: './edit-organisation.component.html',
  styleUrls: ['./edit-organisation.component.scss']
})
export class EditOrganisationComponent implements OnInit {

  public organisation: Organisation;
  @Input('organisation') get Organisation(): Organisation {
    return this.organisation;
  };
  @Output('organisation') organisationDetailsChange: EventEmitter<Organisation> = new EventEmitter();
  set Organisation(val: Organisation) {
    this.organisation = val;
    this.organisationDetailsChange.emit(this.organisation);
  }

  public creationStep: number;
  public showPushSpinner: boolean;
  public showSiteData: boolean = false;
  public showCredentials: boolean;

  public showEmployeeData: boolean = false;
  public organisationActive: boolean;

  public showSpinner: boolean;
  public headquartersExists: boolean;
  public primaryContactExists: boolean;
  public dataProtectionOfficerExists: boolean;
  public sitesInOrganisation: UserSites[] = [];
  public usersInOrganisation: UserRow[] = [];

  public errorMessage: string;

  public get siteInactive(): boolean {
    return this.organisation.TagIdCollection.some(i => i === 1);
  }

  constructor(public asrv: ActorService, public adminsrv: AdminService) { }

  ngOnInit() {
    if (this.adminsrv.organisationCreateMode) {
      this.ToggleCreationMode();
    } else if (!this.organisation.Name) { 
        this.asrv.GetOrganisation(this.asrv.UserOrganisationId).subscribe(res => {
          this.organisation = res;  
          this.organisationActive = !this.organisation.TagIdCollection.some(i => i === 1);
          this.GetSitesForOrganisationId();
          this.GetEmployeesForOrganisationId();
        })
    } else {
      this.organisationActive = !this.organisation.TagIdCollection.some(i => i === 1);
      this.GetSitesForOrganisationId();
      this.GetEmployeesForOrganisationId();
    }
  }

  ToggleCreationMode() {
    this.creationStep = creationOrganisation;
    if (this.organisation.ActorId) {
      this.adminsrv.ActiveOrganisationFilterId = this.organisation.ActorId;
      if (this.organisation.SiteIdCollection.length > 0) {
        this.GetSitesForOrganisationId();
        if (this.organisation.EmployeeIdCollection.length > 0) {
          this.adminsrv.organisationCreateMode = false;
          this.creationStep = null;
          this.GetEmployeesForOrganisationId();
        } else {
          this.adminsrv.CreateFirstUser(this.organisation.ActorId, this.organisation.SiteIdCollection[0]);
        }
      } else {
        this.adminsrv.CreateFirstSite(this.organisation.ActorId);
      }
    }
  }

  GetSitesForOrganisationId(): void {
    this.showSpinner = true;
    this.sitesInOrganisation = [];
    this.asrv.GetSiteIds(0, 0, this.organisation.ActorId).then(value => {
      if (value.length > 0) {
        this.asrv.GetSiteNameCollection(value).then(value => {
          this.sitesInOrganisation = this.GetSiteMap(value);
          this.sitesInOrganisation.forEach(site => {
            if (this.adminsrv.employeeDetails.SiteIdCollection.some(i => i === site.Site_ActorId)) {
              this.adminsrv.employeeDetails.SiteIdCollection.push(site.Site_ActorId);
            }
          })
          this.showSpinner = false;
        })
      }
    })
  }

  GetEmployeesForOrganisationId(): void {
    this.showSpinner = true;
    this.sitesInOrganisation = [];
    this.asrv.GetEmployeeIds(0, 0, this.organisation.ActorId).then(value => {
      this.asrv.GetBiologicalName(value).then(value => {
        this.usersInOrganisation = this.GetEmployeeMap(value);
        this.showSpinner = false;
      })
    })
  }

  GetSiteMap(result: NonBiologicalName[]): UserSites[] {
    return result.map(item => {
      const userSite: UserSites = new UserSites();
      userSite.ActorId = item.ActorId;
      userSite.Name = item.Name;
      if (this.Organisation.SettingCollection.some(i => i.Value === userSite.ActorId.toString())) {
        userSite.Headquarters = true;
        this.headquartersExists = true;
      }
      return userSite;
    })
  }

  GetEmployeeMap(result: BiologicalUserInfo[]): UserRow[] {
    return result.map(item => {
      const user: UserRow = new UserRow();
      user.ActorId = item.ActorId;
      user.Name = item.Forename + ' ' + item.Surname;
      if (this.Organisation.SettingCollection.some(i => i.Value === user.ActorId.toString() && i.SettingKeyId === primarycontact)) {
        user.PrimaryContact = true;
        this.primaryContactExists = true;
      }

      if (this.Organisation.SettingCollection.some(i => i.Value === user.ActorId.toString() && i.SettingKeyId === dataprotectionofficer)) {
        user.DataProtectionOfficer = true;
        this.dataProtectionOfficerExists = true;
      } 

      return user;
    })
  }

  CheckSite(siteBrief: UserSites): void {
    if (siteBrief.Headquarters) {
      this.SetSiteAsOrganisationHeadquarters(siteBrief);
    } else {
      this.RemoveSiteAsOrganisationHeadquarters(siteBrief);
    }
  }

  SetSiteAsOrganisationHeadquarters(siteBrief: UserSites): void {
    if (this.headquartersExists) {
      var previousid = +this.Organisation.SettingCollection.find(i => i.SettingKeyId === headquarters).Value
      this.sitesInOrganisation.find(i => i.ActorId === previousid).Headquarters = false;
      this.Organisation.SettingCollection.find(i => i.SettingKeyId === headquarters).Value = siteBrief.ActorId.toString();
    } else {
      this.SetUpSettingCollectionObject(headquarters, siteBrief.ActorId.toString());
    }
    this.headquartersExists = true;
  }

  RemoveSiteAsOrganisationHeadquarters(siteBrief: UserSites): void {
    siteBrief.Headquarters = false;
    this.SetUpSettingCollectionObject(headquarters, '');
    this.headquartersExists = false;
  }

  CheckUser(user: UserRow) {
    if (user.PrimaryContact) {
      this.SetUserAsPrimaryContact(user);
    } else {
      this.RemoveUserAsPrimaryContact(user);
    }
  }

  SetUserAsPrimaryContact(user: UserRow): void {
    if (this.primaryContactExists) {
      var previousid = +this.Organisation.SettingCollection.find(i => i.SettingKeyId === primarycontact).Value
      this.usersInOrganisation.find(i => i.ActorId === previousid).PrimaryContact = false;
      this.Organisation.SettingCollection.find(i => i.SettingKeyId === primarycontact).Value = user.ActorId.toString();
    } else {
      this.SetUpSettingCollectionObject(primarycontact, user.ActorId.toString());
    }
    this.primaryContactExists = true;
  }

  RemoveUserAsPrimaryContact(user: UserRow): void {
    user.PrimaryContact = false;
    this.SetUpSettingCollectionObject(primarycontact, '');
    this.primaryContactExists = false;
  }

  CheckDataProtectionOfficer(user: UserRow) {
    if (user.DataProtectionOfficer) {
      this.SetUserAsDataProtectionOfficer(user);
    } else {
      this.RemoveUserAsDataProtectionOfficer(user);
    }
  }

  SetUserAsDataProtectionOfficer(user: UserRow): void {
    if (this.dataProtectionOfficerExists) {
      var previousid = +this.Organisation.SettingCollection.find(i => i.SettingKeyId === dataprotectionofficer).Value
      this.usersInOrganisation.find(i => i.ActorId === previousid).DataProtectionOfficer = false;
      this.Organisation.SettingCollection.find(i => i.SettingKeyId === dataprotectionofficer).Value = user.ActorId.toString();
    } else {
      this.SetUpSettingCollectionObject(dataprotectionofficer, user.ActorId.toString());
    }
    this.dataProtectionOfficerExists = true;
  }

  RemoveUserAsDataProtectionOfficer(user: UserRow): void {
    user.DataProtectionOfficer = false;
    this.SetUpSettingCollectionObject(dataprotectionofficer, '');
    this.dataProtectionOfficerExists = false;
  }

  SetUpSettingCollectionObject(keyid: number, value: string): void {
    if (!this.Organisation.SettingCollection.some(i => i.SettingKeyId === keyid)) {
      this.Organisation.SettingCollection.push({
        SettingKeyId: keyid, 
        Value: value
      })
    }
  }

  CloseUserForm() {
    this.showEmployeeData = false;
    this.GetEmployeesForOrganisationId();
  }

  CloseSiteForm() {
    
  }
  
  SubmitNewOrganisation(): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      this.showPushSpinner = true;
      let subscription = this.asrv.PostOrganisation(this.organisation).subscribe(res => {
        subscription.unsubscribe();
        this.showPushSpinner = false;
        this.organisation = res;
        this.adminsrv.FetchOrganisationFilterValues().then(value => {
          this.adminsrv.AddOrganisationToFilter(this.organisation.ActorId, this.organisation.Name);
          this.adminsrv.ActiveOrganisationFilterId = this.organisation.ActorId;
        })
        resolve(true);
      }, err => {
        subscription.unsubscribe();
        this.showPushSpinner = false;
        resolve(false);
      })
    })
  }

  UpdateOrganisation(): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      let subscription = this.asrv.PostOrganisation(this.organisation).subscribe(res => {
        subscription.unsubscribe();
        resolve(true);
      }, err => {
        subscription.unsubscribe();
        resolve(false);
      })
    })
  }

  GoNext() {
    this.showPushSpinner = true;
    if (this.adminsrv.organisationCreateMode) {
      if (this.creationStep === creationOrganisation)  {
        if (this.organisation.Name) {
          this.SubmitNewOrganisation().then(value => {
            if (value) {
              this.adminsrv.CreateFirstSite(this.organisation.ActorId);
              this.creationStep = creationSite;
            }
            this.showPushSpinner = false;
          });
        } else {
          alert('Please enter an Organisation Name before proceeding.')
          this.showPushSpinner = false;
        }
      } else if (this.creationStep === creationSite) {
        this.adminsrv.UpdateSite().then(value => {
          if (value) {
            this.organisation.SettingCollection = [];
            this.organisation.SiteIdCollection = [];

            this.SetUpSettingCollectionObject(headquarters, this.adminsrv.site.ActorId.toString());
            this.organisation.SiteIdCollection.push(this.adminsrv.site.ActorId);
            
            this.adminsrv.CreateFirstUser(this.organisation.ActorId, this.organisation.SiteIdCollection[0]);
            this.creationStep = creationUser;
          }
          this.showPushSpinner = false;
        })
      } else if (this.creationStep === creationUser) {
        this.adminsrv.AddEmployee().then(value => {
          if (value) {
            this.UpdateOrganisation().then(value => {});
            this.organisation.EmployeeIdCollection = [];
            this.organisation.EmployeeIdCollection.push(this.adminsrv.employeeDetails.ActorId);
            this.SetUpSettingCollectionObject(primarycontact, this.organisation.EmployeeIdCollection[0].toString());
            this.creationStep = creationCredentials;
            this.showCredentials = true;
            this.showPushSpinner = false;
          } else {
            this.showPushSpinner = false;
          }
        })
      } else {
        this.adminsrv.SetCredentials().then(value => {
          if (value) {
            this.creationStep = null;
            this.adminsrv.organisationCreateMode = false;
            this.CloseEditForm.next();
          }
          this.showPushSpinner = false;
        })
      }
    } else {
      this.UpdateOrganisation().then(value => {
        this.showPushSpinner = false;
        this.CloseEditForm.next();
      });
    }
  }

  SuspendOrganisation() {
    if (confirm('This will suspend the organisation. Do you wish to proceed?')) {
      if (!this.organisation.TagIdCollection.some(i => i === 1)) {
        this.organisation.TagIdCollection.push(1);
      }
      this.GoNext();
    }
  }

  ReinstateOrganisation() {
    if (confirm('This will reinstate the organisation. Do you wish to proceed?')) {
      var index = this.organisation.TagIdCollection.indexOf(1, 0);
      if (index >= 0) {
        this.organisation.TagIdCollection.splice(index, 1);
      }
      this.GoNext();
    }
  }

  GoBack() {
    this.CloseEditForm.next();
  }

  CloseForm() {
    this.CloseEditForm.next();
  }

  @Output() CloseEditForm = new EventEmitter<string>();
}

class UserRow {
  ActorId: number;
  Name: string;
  PrimaryContact: boolean;
  DataProtectionOfficer: boolean;
}
