<div class="container global-seperator">
    <div class="row">
        <div class="col-sm-12">
            <h4>Terms & Conditions</h4>
        </div>
    </div>
    <div class="row terms-row">
        <div class="col-sm-12">
            I consent to my self exclusion data being shared on Bacta Self Exclusion System and other arcade self-exclusion systems.
        </div>
    </div>
    <div class="row terms-row">
        <div class="col-sm-12">
            I consent to my non personal data being shared with the Gambling Commission and other relevant stakeholders.
        </div>
    </div>
    <div class="row terms-row">
        <div class="col-sm-12">
            I understand the self-exclusion process and I confirm my self-exclusion.
        </div>
    </div>
    <div class="row terms-row">
        <div class="col-sm-12">
            <br>
            *<i>Once self excluded your details will be removed from marketing and promotion databases within 2 days.</i>
        </div>
    </div>
</div>