import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { Honorific } from 'src/app/models/Honorific';
import { Site } from 'src/app/models/Site';
import { ActorService } from 'src/app/services/actor.service';
import { OrganisationService } from 'src/app/services/organisation.service';
import { SiteCollection } from 'src/app/models/SiteCollection';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Tags } from 'src/app/models/Tags';
import { ErrorhanderService } from 'src/app/services/errorhander.service';
import { ExcluderService } from 'src/app/services/excluder.service';
import { NonBiologicalName } from 'src/app/models/actor/Name';

@Component({
  selector: 'app-exclusion-zone',
  templateUrl: './exclusion-zone.component.html',
  styleUrls: ['./exclusion-zone.component.scss', '../generic-form.component.scss']
})
export class ExclusionZoneComponent implements OnInit {

  constructor(
    public asrv: ActorService,
    public esrv: ExcluderService,
    private osrv: OrganisationService,
    private errorHandler: ErrorhanderService,
    private tags: Tags
  ) { }

  public showSpinner: boolean = false;
  public showSiteList: boolean = false;
  public showMotorwayServices: boolean;
  public exclusionSites: Site[] = [];
  public radius = new Subject<string>();
  public honorifics: Honorific[] = [];
  public showBactaTelephone: boolean = false;

  // Controller Properties
  public organisationFilterId: number;
  public sites: NonBiologicalName[] = [];
  public loadingOrganisationDropDown: boolean;
  public loadingSiteDropDown: boolean;

  organisationID: number;  //For selecting sites available

  motorWayCollection: SiteCollection[] = []; 
  @Input('motorWayCollection') get MotorWayCollection(): SiteCollection[] {
    return this.motorWayCollection;
  };
  @Output('motorWayCollection') motorWayCollectionChange: EventEmitter<SiteCollection[]> = new EventEmitter();
  set MotorWayCollection(val: SiteCollection[]) {
    this.motorWayCollection = val;
    this.motorWayCollectionChange.emit(this.motorWayCollection);
  }

  showSiteListOnClick() {
    this.showSiteList = !this.showSiteList;
  }

  showMotorwayServicesOnClick() {
    this.showMotorwayServices = !this.showMotorwayServices;
  }

  ngOnInit() {
    if (this.esrv.CurrentExclusion.SiteId) {
      let actorCompositeModel = this.asrv.GetSiteCompositeModel(this.esrv.CurrentExclusion.SiteId).subscribe(actor => {
        if (actor.TagIdCollection.length > 0) {
          if (actor.TagIdCollection.find(i => i === this.tags.MotorwayService)) {
            this.showMotorwayServices = true;
          }
        }
        actorCompositeModel.unsubscribe();
      }, err => {
        this.errorHandler.CreateParameter('exclusion.SiteId',this.esrv.CurrentExclusion.SiteId);
        this.errorHandler.CatchError('ngOnInit()', 'exclusion-zone.component.ts', err);
        actorCompositeModel.unsubscribe();
      });
    } else {
      this.organisationFilterId = this.asrv.UserOrganisationId;
      this.loadingOrganisationDropDown = false;
      this.PopulateSiteDropDown();
    }

    // this.exclusionSites = this.esrv.CurrentExclusion.SiteCollection;
    this.radius.pipe(debounceTime(500), distinctUntilChanged()).subscribe(value => {
        this.GetSiteList();
    });
  }

  PopulateSiteDropDown() {
    this.sites = [];
    this.asrv.PopulateSiteSelectForOrgansiationId(this.organisationFilterId).then(value => {
      if (!this.asrv.SiteSelect.KeySelected) {
        this.esrv.CurrentExclusion.SiteId = null;
      } else {
        this.esrv.CurrentExclusion.SiteId = this.asrv.SiteSelect.KeySelected;
      }
      this.esrv.CurrentExclusion.ClientValues.ExclusionSiteName = this.asrv.SiteSelect.DropDownItems[0].Value;
      this.GetSiteList();
    })
  }

  GetSiteList() {
    this.esrv.CurrentExclusion.ClientValues.ExclusionSiteName = this.asrv.SiteSelect.KeySelected;
    let actorCompositeModel = this.asrv.GetSiteCompositeModel(this.esrv.CurrentExclusion.SiteId).subscribe(actor => {
      if (actor.TagIdCollection.length > 0) {
        if (actor.TagIdCollection.find(i => i === this.tags.MotorwayService)) {
          this.showMotorwayServices = true;
        }
      }
      actorCompositeModel.unsubscribe();
    }, err => {
      this.errorHandler.CreateParameter('exclusion.SiteId',this.esrv.CurrentExclusion.SiteId);
      this.errorHandler.CatchError('ngOnInit()', 'exclusion-zone.component.ts', err);
      actorCompositeModel.unsubscribe();
    });


    // this.exclusion.SiteCollection = [];
    this.showSpinner = true;
    this.esrv.AddSitesWithinOneKilometer().then(value => {
      this.showSpinner = false;
    });
  }

  showExtraInfo() {
    this.showBactaTelephone = !this.showBactaTelephone;
  }
}
