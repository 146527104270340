<div class="col-sm-12 container global-seperator" style="text-align: center; padding-right:0px !important;">
    <video #video class="vid" autoplay></video>
    <div class="captureTools">
        <input type="image" id="captureImg" class="bacta-button" src=".\assets\icons\Bacta Assets\Capture Photo button blue.svg" style="cursor:pointer" (click)="Capture();"/>
    </div>
</div>

<div class="exclusion-canvas" style="max-height:0px;">
    <canvas #canvas></canvas>
</div>
