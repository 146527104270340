import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { DataService } from 'src/app/services/data.service';
import { InactivityService } from 'src/app/services/inactivity.service';
import { first, take } from 'rxjs/operators';
@Component({
  selector: 'app-memorable-word',
  templateUrl: './memorable-word.component.html',
  styleUrls: ['./memorable-word.component.scss'],
})
export class MemorableWordComponent implements OnInit {

  memwordList =
    [
      'A',
      'B',
      'C',
      'D',
      'E',
      'F',
      'G',
      'H',
      'I',
      'J',
      'K',
      'L',
      'M',
      'N',
      'O',
      'P',
      'Q',
      'R',
      'S',
      'T',
      'U',
      'V',
      'W',
      'X',
      'Y',
      'Z',
      'a',
      'b',
      'c',
      'd',
      'e',
      'f',
      'g',
      'h',
      'i',
      'j',
      'k',
      'l',
      'm',
      'n',
      'o',
      'p',
      'q',
      'r',
      's',
      't',
      'u',
      'v',
      'w',
      'x',
      'y',
      'z',
      0,
      1,
      2,
      3,
      4,
      5,
      6,
      7,
      8,
      9
    ];

  memwordError = false;
  showModal = false;
  

  showMissingError = false;

  intervalTimer;
  token = '';
  constructor(
    private _api: ApiService,
    public _ds: DataService,
    private _router: Router,
    public _is: InactivityService
  ) {

    _ds.getMemwordError().subscribe(isError => {
      if (!this.showModal && isError) {
        this._is.getNewKey();
        this._is.startModalTimer();
        this._is.startMonitor();
        this.memwordError = isError;
        this.showModal = true;
        console.log('Show modal: ', this.showModal);

      } else if (this.showModal && !isError) {
        this.showModal = false;
        this.clearErrors();
      }
    });
  }

  ngOnInit() {

  }

  clearErrors() {
    this.showMissingError = false;
  }

  

  checkWord() {

    if (
      this._is.memwordChar1 !== '' &&
      this._is.memwordChar2 !== '' &&
      this._is.memwordChar3 !== ''
    ) {
      this.showMissingError = false;
      const _token = this._is.token;
      const _memwordFields = [
        {
          Position: this._is.secretKeyMap[0],
          Character: this._is.memwordChar1.toString()
        },
        {
          Position: this._is.secretKeyMap[1],
          Character: this._is.memwordChar2.toString()
        },
        {
          Position: this._is.secretKeyMap[2],
          Character: this._is.memwordChar3.toString()
        }
      ];
      const _postData = {
        Token: _token,
        SecretKeyCharacterCollection: _memwordFields
      };
      this._api.postSecretKey(_postData).subscribe(
        results => {
          this._ds.setInitGallery(true);
          this._is.startMonitor();
          this._is.startTimer();
          this._is.stopModalTimer();
          this.close();
          sessionStorage.setItem('__galleryToken', _token);
          sessionStorage.setItem('__postData', JSON.stringify(_postData));
          
        },
        error => {
          sessionStorage.removeItem('__postData');
          sessionStorage.removeItem('__galleryToken');
          this.close();
          console.log(error);
          this._router.navigate(['/user/features']);


        }
      );
    } else {
      this.showMissingError = true;
    }
  }



  close() {
    this.showModal = false;
    this._ds.setMemwordError(false);
  }

  cancel() {
    this.showModal = false;
    this._ds.setMemwordError(false);

    this._router.navigate(['/user/features']);
    this._is.stopMonitor();
    this._is.stopTimer();
    this._is.stopModalTimer();
  }

  ngOnDestroy() {
    this.showMissingError = false;
  }
}
