import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Honorific } from 'src/app/models/Honorific';
import { Gender } from 'src/app/models/Gender';
import { InteractionService } from 'src/app/services/interaction.service';

@Component({
  selector: 'app-interaction-customer-details',
  templateUrl: './interaction-customer-details.component.html',
  styleUrls: ['./interaction-customer-details.component.scss']
})
export class InteractionCustomerDetailsComponent implements OnInit {

  constructor(public isrv: InteractionService) { }

  honorifics: Honorific[];
  @Input('honorifics') get Honorific(): Honorific[] {
    return this.honorifics;
  };
  @Output('honorifics') honorificsChange: EventEmitter<Honorific[]> = new EventEmitter();
  set Honorific(val: Honorific[]) {
    this.honorifics = val;
    this.honorificsChange.emit(this.honorifics);
  }

  genders: Gender[];
  @Input('genders') get Gender(): Gender[] {
    return this.genders;
  };
  @Output('genders') gendersChange: EventEmitter<Gender[]> = new EventEmitter();
  set Gender(val: Gender[]) {
    this.genders = val;
    this.gendersChange.emit(this.genders);
  }

  ngOnInit() {
  }
}
