export class Credentials {
    ActorId: number;
    Login: string;
    Hash: string;
    Edited: string;
    Edited_ActorId: string;
}

export class CredentialsBrief {
    Login: string;
    Password: string;
}