<div *ngIf="!msrv.ShowMessage" class="container global-seperator" style="margin-top:10px !important">
    <div *ngIf="msrv.AllowCompose" class="col-md-12">
        <!--<app-view-message [(messageToDisplay)]="messageToDisplay"></app-view-message>-->
        <div class="form-group" style="text-align: right;">
            <button type="button" class="btn btn-success" (click)="msrv.ComposeNewMessage();" style="margin-right: 10px;">Compose New Message</button>
        </div>
    </div>
    <div class="col-md-6">
        <div class="input-group" id="inputTo">
            <div class="input-group-prepend" style="width:25% !important">
                <span class="input-group-text">Filter</span>
            </div>
            <select class="form-control drop-down-purple" name="title" [(ngModel)]="selectedFilter" (change)="ChangeFilter()">
                <option [ngValue]="0">All</option>
                <!--<option [ngValue]="1">Important</option>-->
                <option [ngValue]="2">Unread</option>
            </select>
        </div>
    </div>
    <app-messaging-main-view></app-messaging-main-view>
</div>

<div *ngIf="msrv.ShowMessage">
    <app-read-compose-message (CloseCompose)="CloseCompose()"></app-read-compose-message>
</div>  