import { ActorService } from 'src/app/services/actor.service';
import { Tag } from '../Tag';

// Class for creating tabular reports.
// Every tabular class requires the following properties.
// Rows: any[] = [] --Holds all the data.
// HeaderTransaltion = [{}]  --This will print all the headers to the table.
// public AddRow(id) {} --Use this push rows, ensure all values are hardcoded for now.
// public UpdateRow(id) {} --Will update the row.

export class GenericTagList {
    private _title: string = '_' + btoa('GenericTagList');
    private _memory: any[] = [];
    public Rows: any[] = [];
    public DataLoaded: boolean = false;
    public HeaderTranslation = [{
        'Name': 'Tag',
    }];

    constructor(public asrv: ActorService) {
        this.LoadMemoryManager();
        this.Rows = [];
    }

    public AddRow(id: number) {
        var rowExists = this._memory.some(i => i['TagId'] === id);
        var alreadyExist = this.Rows.some(i => i['TagId'] === id);

        if (rowExists && !alreadyExist) {
            this.Rows.push(this.LoadRowFromMemoryManager(id));
        } else if (!alreadyExist) {
            // Doesn't Exist, fetch from the server.
            let returnRow = {};
            returnRow['TagId'] = id;
            var row = this.GetRowValues(id, returnRow);
            this.Rows.push(row);
        }
        // If row exists inside the public object disregard it.
    }

    public UpdateRow(id: number) {
        let rowToUpdate = this.Rows.find(i => i['TagId'] === id);
        this.GetRowValues(id, rowToUpdate);
        this.UpdateMemoryManager();
    }

    public RemoveRow(row) {
        let index = this.Rows.indexOf(row, 0);
        if (index > -1) {
            this.Rows.splice(index, 1);
        }
    }

    public FindRow(id: number) {
        return this.Rows.find(i => i['TagId'] === id);
    }

    private GetRowValues(id: number, row: any) {
        row['Name'] = false;
        let tagSubscription = this.asrv.GetTagInfo(id).subscribe(res => {
            row['Name'] = res.Name;
            row['Edited'] = res.Edited
            tagSubscription.unsubscribe();
        })
        return row;
    }

    // Memory Methods
    private LoadMemoryManager() {
        if (!localStorage.getItem(this._title)) {
            this._memory = [];
        } else {
            this._memory = JSON.parse(localStorage.getItem(this._title));
        }
    }

    private LoadRowFromMemoryManager(id: number) {
        var matchedRow = this._memory.find(i => i['TagId'] === id);
        return matchedRow;
    }

    private UpdateMemoryManager() {
        localStorage.setItem(this._title, JSON.stringify(this.Rows));
        this.RefreshMemory();
    }

    private RefreshMemory() {
        this._memory = JSON.parse(localStorage.getItem(this._title));
    }
}
