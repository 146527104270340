import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { ExclusionBreaches } from 'src/app/models/ExclusionBreaches';

import { ISDDateMethods } from 'src/app/models/ISDDateMethods';
import { ActorService } from 'src/app/services/actor.service';
import { ApiService } from 'src/app/services/api.service';
import { DataService } from 'src/app/services/data.service';
import { ExcluderService } from 'src/app/services/excluder.service';
import { InactivityService } from 'src/app/services/inactivity.service';

const redalert: string = '#d11722';
const yellowalert: string = '#f8b03d';
const greenalert: string = '#64b32e';
const bluealert: string = '#24ace2';

@Component({
  selector: 'app-exclusion-card-mobile',
  templateUrl: './exclusion-card-mobile.component.html',
  styleUrls: ['./exclusion-card-mobile.component.scss']
})
export class ExclusionCardMobileComponent implements OnInit, AfterViewInit {

  @ViewChild('cardOuter') cardOuter: ElementRef;

  private dateMethods: ISDDateMethods = new ISDDateMethods();
  public breached: boolean = false;
  public recentBreach: ExclusionBreaches;
  public startDateString: string;
  public image: string;

  constructor(
    public esrv: ExcluderService,
    public asrv: ActorService,
    private _is: InactivityService,
    private _ds: DataService,
    private _api: ApiService
  ) { }

  public showBreachSite: boolean;

  ngOnInit() {
    const _token = sessionStorage.getItem('__galleryToken');

    if (_token) {
      this._api.getCheckToken(_token).subscribe(
        results => {
          this._is.startMonitor();
          this._is.startTimer();
        },
        error => {
          this._ds.setMemwordError(true);
          sessionStorage.removeItem('__galleryToken');
        }
      )
    } else {
      this._ds.setMemwordError(true);
    }

    this.recentBreach = this.esrv.GetRecentBreach();
    if (this.recentBreach) {
      this.breached = true;
      this.asrv.GetSiteNameCollection([this.recentBreach.SiteId]).then(value => {
        this.recentBreach.Site = value[0].Name;
        this.showBreachSite = true;
      })
    }

    this.image = this.esrv.CurrentExclusion.MediaModelCollection.find(i => i.MediaClassKeyId === 4).Data;
  }

  ngAfterViewInit() {
    // let startDate = new Date(this.exclusion.Start);
    let diffDaysStart = this.dateMethods.DateDifferenceInDays(new Date(this.esrv.CurrentExclusion.InteractionMonitoringPeriod.Start));
    let durationDate = (this.esrv.CurrentExclusion.InteractionMonitoringPeriod.Duration ? this.esrv.CurrentExclusion.InteractionMonitoringPeriod.Duration : 365);
    let diffDaysEnd = this.dateMethods.DateDifferenceInDays(this.dateMethods.AddDays(new Date(this.esrv.CurrentExclusion.InteractionMonitoringPeriod.Start), durationDate));

    if (this.esrv.ReinstatementExists()) {
      this.cardOuter.nativeElement.style.borderColor = bluealert;
      this.cardOuter.nativeElement.style.background = bluealert;
    } else if (diffDaysStart <= 5) {
      this.cardOuter.nativeElement.style.borderColor = redalert;
      this.cardOuter.nativeElement.style.background = redalert;
    } else if (diffDaysEnd >= 0) {
      this.cardOuter.nativeElement.style.borderColor = greenalert;
      this.cardOuter.nativeElement.style.background = greenalert;
    } else {
      this.cardOuter.nativeElement.style.borderColor = yellowalert;
      this.cardOuter.nativeElement.style.background = yellowalert;
    }
  }

  GetEndDateString(): String {
    return new Date(this.dateMethods.AddDays(this.esrv.CurrentExclusion.InteractionMonitoringPeriod.Start, this.esrv.CurrentExclusion.InteractionMonitoringPeriod.Duration)).toISOString();
  }

  GetEndDate(): Date {
    return new Date(this.dateMethods.AddDays(this.esrv.CurrentExclusion.InteractionMonitoringPeriod.Start, this.esrv.CurrentExclusion.InteractionMonitoringPeriod.Duration));
  }

  GetExtensionInMonths() {
    return this.dateMethods.GetMonthDifference(this.esrv.CurrentExclusion.Extension.Start, this.esrv.CurrentExclusion.Extension.Duration);
  }

  ExcluderRecentlyBreached(): Boolean {
    if (this.esrv.CurrentExclusion.ExclusionBreachesCollection) {
      if (this.esrv.CurrentExclusion.ExclusionBreachesCollection.length > 0) {
        return true;
      }
    }
    return false;
  }

  ExcluderIsCoolingOff() {
    if (this.esrv.CurrentExclusion.Reinstatement) {
      var diff = this.dateMethods.DateDifferenceInDays(this.esrv.CurrentExclusion.Reinstatement.Accepted);
      if (diff === 0) {
        return true;
      }
      return false;
    }
  }

  ngOnDestroy() {
    console.log('***leaving gallery');
    this._is.killSubscriptions();
    this._ds.setMemwordError(false);
  }
}
