import { Component, OnInit, ViewChild, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { ActorService } from 'src/app/services/actor.service';
import { Organisation } from 'src/app/models/Organisation';
import { AdminService, OrganisationList, OrganisationRow } from 'src/app/services/admin.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-organisation',
  templateUrl: './organisation.component.html',
  styleUrls: ['./organisation.component.scss', '../admin-main-view.component.scss']
})
export class AdminOrganisationComponent implements OnInit {
  
  @ViewChild('table') table: ElementRef;

  object = [{id: false}];
  objectKeys = [];
  tableKeys = [];

  public dataLoaded: boolean;
  public showOrganisationData: boolean = false;
  public organisation: Organisation;
  public showSendingSpinner: boolean = false;
  public showAddOrganisation: boolean = false;
  public doNotShow: boolean = false; 
  public creationMode: boolean = false;
  public showSuspended: boolean = false;
  
  public dataModel: OrganisationList;
  @Input('dataModel') get DataModel(): OrganisationList {
    return this.dataModel;
  };
  @Output('dataModel') dataModelChange: EventEmitter<OrganisationList> = new EventEmitter();
  set DataModel(val: OrganisationList) {
    this.dataModel = val;
    this.dataModelChange.emit(this.dataModel);
  } 

  constructor(public asrv: ActorService, public adminsrv: AdminService, private router: Router) { }

  ngOnInit() {
    this.adminsrv.organisationCreateMode = false;
    this.dataLoaded = false;
    if (this.adminsrv.UserRoleId <= 3) {
      this.asrv.GetOrganisationIdsDBSync(0, 0).then(value => {
        this.adminsrv.PopulateOrganisationList(value).then(value => {
          if (value) {
            if (this.adminsrv.OrganisationList.Rows.length >= 2) {
              this.SetDataObject();
            } else {
              this.ShowEditOrganisationOnly();
            }
            this.dataLoaded = true;
          }
        })
      })
    } else {
      alert('You are unable to view, add or edit organisation records.');
      this.router.navigate(['user']);
    }

  }

  SortSiteList(key: any) {

  }

  FilterOrganisations() {
    this.adminsrv.FilterOrganisationList(this.showSuspended).then(value => {
      this.SetDataObject();
    });
  }

  SetDataObject() {
    this.object = [];
    this.objectKeys = Object.values(this.adminsrv.OrganisationList.HeaderTranslation[0]);
    this.tableKeys = Object.keys(this.adminsrv.OrganisationList.HeaderTranslation[0]);
    this.adminsrv.OrganisationList.Rows.forEach(row => {
      let newRow: any = {};
      newRow['OrganisationId'] = row.OrganisationId;
      newRow['OrganisationName'] = row.OrganisationName;
      this.object.push(newRow);
    })
  }

  ShowEditOrganisationOnly() {
    this.organisation = new Organisation();
    this.showSendingSpinner = false;
    this.showOrganisationData = true;
    this.organisation.Organisations_ActorId = this.dataModel.Rows[0]['OrganisationId'];
    this.organisation.Organisations_Name = this.dataModel.Rows[0]['OrganisationName'];
  }

  ShowOrganisation(row: OrganisationRow) {
    document.body.style.cursor = 'progress';
    this.asrv.GetOrganisation(row.OrganisationId).subscribe(res => {
      this.organisation = res;
      this.showSendingSpinner = false;

      // Organisations must have at least 1 site and 1 employee to be edited, else they need to be created.
      if (res.SiteIdCollection.length > 0 && res.EmployeeIdCollection.length > 0) {
        this.adminsrv.organisationCreateMode = false;
      } else {
        this.adminsrv.organisationCreateMode = true;
      }

      document.body.style.cursor = 'default';
      this.showOrganisationData = true;
    })
  }

  AddNewRecord() {
    this.organisation = new Organisation();
    this.adminsrv.organisationCreateMode = true;
    this.showOrganisationData = true;
  }

  CloseEditForm() {
    this.showOrganisationData = false;
    this.ngOnInit();
  }

  PostOrganisation() {
    // this.organisation.Postcode = this.employeeDetails.Postcode.replace(' ', '');
    // this.organisation.Country = "UK";
    // this.organisation.PostalAddressClassId = 2;
    this.showSendingSpinner = true;
    this.asrv.PostOrganisation(this.organisation).subscribe(res => {
      // Do things
    })
  }
}
