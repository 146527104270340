<div class="row" style="min-height:200px; padding-top: 20px;padding-bottom:20px;">
    <div class="col-md-12">
        <div style="background-color: #223464; padding: 10px; padding-top: 0px !important; border: 2px solid #223464; border-radius: 0.5em;">
            <div class="row">
                <span style="color:white;">Notes</span>
            </div>
            <div class="row">
                <!--[(ngModel)]="interaction.Notes"-->
                <textarea id="ctrlNotes" style="width:100%; height: 150px;" [(ngModel)]="isrv.Interaction.Notes" disabled></textarea>
            </div>
        </div>
    </div>
</div>

<div #table class="table-responsive bacta-table-outline" style="margin-top:10px;">
    <table class="table">
        <thead class="table-header">
            <th class="table-header-inner">ID</th>
            <th class="table-header-inner">Interaction</th>
            <th class="table-header-inner">Review Date</th>
            <th class="table-header-inner">Reason(s)</th>
            <th class="table-header-inner">Action(s)</th>
        </thead>
        <tbody class="table-row" *ngFor="let interaction of interactions" (click)="ShowInteraction(interaction);">
            <td class="table-row-inner" [style.backgroundColor]="interaction.InteractionId === isrv.Interaction.InteractionId? 'lightgray': 'white'" [style.color]="interaction.InteractionId === isrv.Interaction.InteractionId? 'black': 'red'">{{interaction.InteractionId}}</td>
            <td class="table-row-inner" [style.backgroundColor]="interaction.InteractionId === isrv.Interaction.InteractionId? 'lightgray': 'white'" [style.color]="interaction.InteractionId === isrv.Interaction.InteractionId? 'black': 'red'">{{GetStartDate(interaction) | date: 'dd/MM/yyyy'}}</td>
            <td class="table-row-inner" [style.backgroundColor]="interaction.InteractionId === isrv.Interaction.InteractionId? 'lightgray': 'white'" [style.color]="interaction.InteractionId === isrv.Interaction.InteractionId? 'black': 'red'">{{GetEndDate(interaction) | date: 'dd/MM/yyyy'}}</td>
            <td class="table-row-inner" [style.backgroundColor]="interaction.InteractionId === isrv.Interaction.InteractionId? 'lightgray': 'white'" [style.color]="interaction.InteractionId === isrv.Interaction.InteractionId? 'black': 'red'">{{interaction.Reasons}}</td>
            <td class="table-row-inner" [style.backgroundColor]="interaction.InteractionId === isrv.Interaction.InteractionId? 'lightgray': 'white'" [style.color]="interaction.InteractionId === isrv.Interaction.InteractionId? 'black': 'red'">{{interaction.Actions}}</td>
        </tbody>
    </table>
</div>

<div class="form-group" style="text-align: right;">
    <button type="button" class="btn btn-danger" (click)="CallCloseForm();" style="margin-right: 10px;">Close</button>
</div>