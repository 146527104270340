<div class="container global-seperator">
    <div class="row" *ngIf="(!esrv.CurrentExclusion.InteractionId)">
        <div class="col-md-6">
            <div style="max-height: 250px;max-width:250px; margin-bottom:5px;">
                <img class="exclusion-image-preview" [src]="excluderImage" style="max-height: 250px;max-width:250px;">
            </div>
        </div>
        <div class="col-md-6" style="margin:auto;">
            <h3 class="form-text" style="text-align: center;">{{esrv.CurrentExclusion.InteractedActorCompositeModel.BiologicalActorCompositeModel.Forename + ' ' + esrv.CurrentExclusion.InteractedActorCompositeModel.BiologicalActorCompositeModel.Surname}}</h3>
        </div>
    </div>
    <div class="row" style="height:1px;background-color:#223464;"></div>

    <app-exclusion-terms *ngIf="showHideTerms"></app-exclusion-terms>

    <div *ngIf="showHideTerms">
        <div class="form-group" style="text-align: right;">
            <button type="button" class="btn btn-danger" (click)="ShowHideTermsApp();" style="margin-right: 10px;">Close</button>
        </div>
    </div>
    <app-exclusion-declaration-details *ngIf="!showHideTerms" (ShowHideTermsApp)="ShowHideTermsApp()" (ValidateExclusion)="ValidateExclusion()"></app-exclusion-declaration-details>
</div>