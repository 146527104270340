import { Component, OnInit } from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ActorService } from 'src/app/services/actor.service';
import { AdvancedSearchService } from 'src/app/services/advanced-search.service';
import { ApiService } from 'src/app/services/api.service';
import { DataService } from 'src/app/services/data.service';
import { ErrorhanderService } from 'src/app/services/errorhander.service';
import * as moment from 'moment';

@Component({
  selector: 'app-machine-payouts',
  templateUrl: './machine-payouts.component.html',
  styleUrls: ['./machine-payouts.component.scss']
})
export class MachinePayoutsComponent implements OnInit {
  checkDate;
  checkDateModel;
  checkTime;
  siteID;
  machineNumber;
  machineIssue;
  amount;
  customerFirstname;
  customerSurname;
  events: string[] = [];
  usingLoggedInName = false;
  employeeListLoading = false;
  loggedInUserID;
  employeeList = [];
  loggedInName;
  checkOKTrue;
  checkOKFalse;
  isSaving = false;
  employeeId;
  notes;
  errorMsg;
  isSavedSuccess;
  isError;

  machineIssueListLoading = true;
  machineIssueList = [
    // {
    //   Name: "AML Alert",
    //   TagId: 0
    // },
    // {
    //   Name: "Coin Mech Error",
    //   TagId: 0
    // },
    // {
    //   Name: "Crash/Freeze",
    //   TagId: 0
    // },
    // {
    //   Name: "Dataport Error",
    //   TagId: 0
    // },
    // {
    //   Name: "Ex Gratia",
    //   TagId: 0
    // },
    // {
    //   Name: "Expired ticket",
    //   TagId: 0
    // },
    // {
    //   Name: "Failed to pay",
    //   TagId: 0
    // },
    // {
    //   Name: "Failed to print ticket",
    //   TagId: 0
    // },
    // {
    //   Name: "Handpay",
    //   TagId: 0
    // },
    // {
    //   Name: "Hopper empty",
    //   TagId: 0
    // },
    // {
    //   Name: "Invalid ticket",
    //   TagId: 0
    // },
    // {
    //   Name: "No credit given",
    //   TagId: 0
    // },
    // {
    //   Name: "Note acceptor error",
    //   TagId: 0
    // },
    // {
    //   Name: "Payout short",
    //   TagId: 0
    // },
    // {
    //   Name: "Printer error",
    //   TagId: 0
    // },
  ]

  constructor(
    public dsrv: DataService,
    public asrv: ActorService,
    private router: Router,
    public errsrv: ErrorhanderService,
    public assrv: AdvancedSearchService,
    private titleService: Title,
    private _api: ApiService,
    private _snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    this.getEmployeeList();
    this.getMachineIssuesList();
    document.body.style.backgroundColor = '#c3cddd';
  }

  addEvent(type: string, event: MatDatepickerInputEvent<Date>) {
    this.events.push(`${type}: ${event.value}`);
    this.checkDate = moment(this.checkDateModel).format('YYYY-MM-DD');
  }

  getMachineIssuesList() {
    this.machineIssueListLoading = true;
    this._api.getTagCollectionCompositeModel(22).subscribe(collection => {
      this.machineIssueList = collection.Tags;
      this.machineIssueList.sort((a, b) => a.Name.toLowerCase().localeCompare(b.Name.toLowerCase()));
      this.machineIssueListLoading = false;
    });
  }

  setItem() { }

  getEmployeeList() {
    this.usingLoggedInName = false;
    this.employeeListLoading = true;
    const _userID = this.dsrv.GetMyUserID();
    this.loggedInUserID = _userID;
    // console.log(this.loggedInUserID);
    this._api.getUserOrgID(_userID).subscribe(results => {

      const _orgID = results[0].Organisations_ActorId;
      const _employeeIDs = [];
      this._api.getUserEmployeesList(_orgID).subscribe(employeeResults => {
        employeeResults.forEach(element => {
          _employeeIDs.push(element.Employees_ActorId);
        });

        this.asrv.GetBiologicalName(_employeeIDs).then(value => {
          this.employeeList = value;
          this.employeeListLoading = false;
        });
      },
        error => {
          this.usingLoggedInName = true;
          this.loggedInName = this.asrv.GetUsersFullName();
          this.employeeListLoading = false;
        });
    });
  }

  checkItem(wasChecked) {
    if (wasChecked) {
      this.checkOKTrue = true;
      this.checkOKFalse = false;
    } else {
      this.checkOKTrue = false;
      this.checkOKFalse = true;
    }
  }

  GoBack() {
    this.router.navigate(['/user/toolkit']);
  }

  closeNotification() {
    this.isError = false;
    this.isSavedSuccess = false;
  }

  saveCheck() {
    console.log(moment(this.checkDate + ' ' + this.checkTime, 'YYYY-MM-DD HH:mm').utc().format('YYYY-MM-DDTHH:mm'));
    console.log(moment(this.checkDate + ' ' + this.checkTime, 'YYYY-MM-DD HH:mm').utc());
    console.log(moment(this.checkDate + ' ' + this.checkTime, 'YYYY-MM-DD HH:mm'));
    const _recordedDate = moment(this.checkDate + ' ' + this.checkTime, 'YYYY-MM-DD HH:mm').utc().format('YYYY-MM-DDTHH:mm');
    this.siteID = this.asrv.DefaultSiteId;
    this.isSaving = true;
    const _tagIDCollection = [];

    if (this.machineIssue) {
      _tagIDCollection.push(this.machineIssue);
    }

    if (this.amount) {
      this._api.postMachinePayoutCheck(
        {
          AssociatedActorIdCollection: [
            this.employeeId
          ],
          InteractedActorCompositeModelCollection: [
            {
              BiologicalActorCompositeModel: {
                PostalAddressClassId: 1,
                Forename: this.customerFirstname,
                Surname: this.customerSurname
              }
            }
          ],

          Notes: this.notes,
          SiteId: this.siteID,
          Recorded: _recordedDate,
          TagIdCollection: _tagIDCollection,
          Amount: this.amount,
          AssetReference: this.machineNumber

        }
      ).subscribe(
        result => {
          this.isSaving = false;
          this.isSavedSuccess = true;
          this.isError = false;
          setTimeout(() => {
            this.isSavedSuccess = false;
            this.router.navigate(['/user/toolkit']);
          }, 3000);
        },
        error => {
          this.isSaving = false;
          this.isError = true;
          this.errorMsg = error.error;
          console.log(error);
        });
    }

    this.isSaving = false;
  }


}
