import { Honorific } from '../Honorific';
import { Gender } from '../Gender';
import { ExclusionDurationOption } from '../ExclusionDurationOption';
import { ExclusionReason } from '../ExclusionReason';
import { Site } from '../Site';
import { Organisation } from '../Organisation';
import { IdentificationDocument } from '../IdentificationDocumentKey';
import { SiteCollection } from '../SiteCollection';
import { ISDDateMethods } from '../ISDDateMethods';
import { ActorService } from 'src/app/services/actor.service';
import { Subscription } from 'rxjs';
import { UserSites } from '../UserSites';
import { DataService } from 'src/app/services/data.service';
import { NonBiologicalName } from '../actor/Name';

// Class for creating tabular reports.
// Every tabular class requires the following properties.
// Rows: any[] = [] --Holds all the newRows pushed.
// HeaderTransaltion = [{}]  --This will print all the headers to the table.
// newRow = [{}] --Make the values all blank but match the HeaderTranslationIds
// public AddRow(rowObject) {} --Use this push rows, ensure all values are hardcoded for now.

export class OrganisationSitesList {
    private DateMethods: ISDDateMethods = new ISDDateMethods();
    public Rows: any[] = [];
    public SubscriptionsOpened: number;
    public CompletedSubscriptions: number = 0;
    public DataLoaded:boolean = true;
    public HeaderTranslation = [{
        'Name': 'Site Name', 
    }];

    public GetExclusionIsSubscribed: boolean;
    public ExclusionSubscription: Subscription;

    constructor(public asrv: ActorService, private siteids: number[]) {
        this.siteids.forEach(id => {
            this.AddRow(id);
        })
        this.asrv.GetSiteNameCollection(this.siteids).then(value => {
            value.forEach(sitedetails => {
                this.Rows.find(i => i['SiteId'] === sitedetails.ActorId)['Name'] = sitedetails.Name;
            })
            this.DataLoaded = true;
        })
    }

    public AddRow(id: number) {
        var returnRow = {};
        returnRow['SiteId'] = id;
        returnRow['Name'] = false;
        this.Rows.push(returnRow);
    }


}
