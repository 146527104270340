<img *ngIf="iconId === 1" id="icon1" class="icon" src=".\assets\icons\fruits-new\Blue_Apple.svg" (click)="SelectImage(iconId, $event)">
<img *ngIf="iconId === 2" id="icon2"  class="icon" src=".\assets\icons\fruits-new\Blue_Banana.svg" (click)="SelectImage(iconId, $event)">
<img *ngIf="iconId === 3" id="icon3"  class="icon" src=".\assets\icons\fruits-new\Blue_Cherry.svg" (click)="SelectImage(iconId, $event)">
<img *ngIf="iconId === 4" id="icon4"  class="icon" src=".\assets\icons\fruits-new\Blue_Grape.svg" (click)="SelectImage(iconId, $event)">
<img *ngIf="iconId === 5" id="icon5"  class="icon" src=".\assets\icons\fruits-new\Blue_Lemon.svg" (click)="SelectImage(iconId, $event)">
<img *ngIf="iconId === 6" id="icon6"  class="icon" src=".\assets\icons\fruits-new\Blue_Pear.svg" (click)="SelectImage(iconId, $event)">
<img *ngIf="iconId === 7" id="icon7"  class="icon" src=".\assets\icons\fruits-new\Blue_Pineapple.svg" (click)="SelectImage(iconId, $event)">
<img *ngIf="iconId === 8" id="icon8"  class="icon" src=".\assets\icons\fruits-new\Blue_Strawberry.svg" (click)="SelectImage(iconId, $event)">
<img *ngIf="iconId === 9" id="icon9"  class="icon" src=".\assets\icons\fruits-new\Green_Apple.svg" (click)="SelectImage(iconId, $event)">
<img *ngIf="iconId === 10" id="icon10"  class="icon" src=".\assets\icons\fruits-new\Green_Banana.svg" (click)="SelectImage(iconId, $event)">
<img *ngIf="iconId === 11" id="icon11"  class="icon" src=".\assets\icons\fruits-new\Green_Cherry.svg" (click)="SelectImage(iconId, $event)">
<img *ngIf="iconId === 12" id="icon12"  class="icon" src=".\assets\icons\fruits-new\Green_Grape.svg" (click)="SelectImage(iconId, $event)">
<img *ngIf="iconId === 13" id="icon13"  class="icon" src=".\assets\icons\fruits-new\Green_Lemon.svg" (click)="SelectImage(iconId, $event)">
<img *ngIf="iconId === 14" id="icon14"  class="icon" src=".\assets\icons\fruits-new\Green_Pear.svg" (click)="SelectImage(iconId, $event)">
<img *ngIf="iconId === 15" id="icon15"  class="icon" src=".\assets\icons\fruits-new\Green_Pineapple.svg" (click)="SelectImage(iconId, $event)">
<img *ngIf="iconId === 16" id="icon16"  class="icon" src=".\assets\icons\fruits-new\Green_Strawberry.svg" (click)="SelectImage(iconId, $event)">
<img *ngIf="iconId === 17" id="icon17"  class="icon" src=".\assets\icons\fruits-new\Mauve_Apple.svg" (click)="SelectImage(iconId, $event)">
<img *ngIf="iconId === 18" id="icon18"  class="icon" src=".\assets\icons\fruits-new\Mauve_Banana.svg" (click)="SelectImage(iconId, $event)">
<img *ngIf="iconId === 19" id="icon19"  class="icon" src=".\assets\icons\fruits-new\Mauve_Cherry.svg" (click)="SelectImage(iconId, $event)">
<img *ngIf="iconId === 20" id="icon20"  class="icon" src=".\assets\icons\fruits-new\Mauve_Grape.svg" (click)="SelectImage(iconId, $event)">
<img *ngIf="iconId === 21" id="icon21"  class="icon" src=".\assets\icons\fruits-new\Mauve_Lemon.svg" (click)="SelectImage(iconId, $event)">
<img *ngIf="iconId === 22" id="icon22"  class="icon" src=".\assets\icons\fruits-new\Mauve_Pear.svg" (click)="SelectImage(iconId, $event)">
<img *ngIf="iconId === 23" id="icon23"  class="icon" src=".\assets\icons\fruits-new\Mauve_Pineapple.svg" (click)="SelectImage(iconId, $event)">
<img *ngIf="iconId === 24" id="icon24"  class="icon" src=".\assets\icons\fruits-new\Mauve_Strawberry.svg" (click)="SelectImage(iconId, $event)">
<img *ngIf="iconId === 25" id="icon25"  class="icon" src=".\assets\icons\fruits-new\Red_Apple.svg" (click)="SelectImage(iconId, $event)">
<img *ngIf="iconId === 26" id="icon26"  class="icon" src=".\assets\icons\fruits-new\Red_Banana.svg" (click)="SelectImage(iconId, $event)">
<img *ngIf="iconId === 27" id="icon27"  class="icon" src=".\assets\icons\fruits-new\Red_Cherry.svg" (click)="SelectImage(iconId, $event)">
<img *ngIf="iconId === 28" id="icon28"  class="icon" src=".\assets\icons\fruits-new\Red_Grape.svg" (click)="SelectImage(iconId, $event)">
<img *ngIf="iconId === 29" id="icon29"  class="icon" src=".\assets\icons\fruits-new\Red_Lemon.svg" (click)="SelectImage(iconId, $event)">
<img *ngIf="iconId === 30" id="icon30"  class="icon" src=".\assets\icons\fruits-new\Red_Pear.svg" (click)="SelectImage(iconId, $event)">
<img *ngIf="iconId === 31" id="icon31"  class="icon" src=".\assets\icons\fruits-new\Red_Pineapple.svg" (click)="SelectImage(iconId, $event)">
<img *ngIf="iconId === 32" id="icon32"  class="icon" src=".\assets\icons\fruits-new\Red_Strawberry.svg" (click)="SelectImage(iconId, $event)">

<img *ngIf="iconId === 80" id="icon80" class="outline-svg" src=".\assets\icons\outlines\Male icon.svg" (click)="SelectImage(iconId, $event)">
<img *ngIf="iconId === 81" id="icon81" class="outline-svg" src=".\assets\icons\outlines\Female icon.svg" (click)="SelectImage(iconId, $event)">