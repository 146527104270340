/// <reference types="@types/googlemaps" />

import { Input } from "@angular/core";
import { ActorService } from "src/app/services/actor.service";
import { ExcluderService } from "src/app/services/excluder.service";
import { NonBiologicalName } from "../actor/Name";
import { Site } from "../Site";

export class Maps {
  // Simply create the google maps class and set an elementRef to GenerateMap() and away you go.

  private APIKey: string = "AIzaSyDgwK9DgD_e85FCI6aolnlKT6NbcBVHez8";
  public Map: google.maps.Map;
  public Polylines: google.maps.Polyline[] = [];
  public PolylineLatLng: google.maps.LatLngLiteral[] = [];
  public PolylineMarker: google.maps.Marker;
  public Markers: google.maps.Marker[] = [];
  public Shapes: Shape[] = [];
  private listner;

  // For Bacta Only
  public SiteMarkers: Site[] = [];

  constructor(public esrv: ExcluderService, public asrv: ActorService) {
  }

  public Initialise(): void {
      let script = document.createElement('script');
      script.src = "https://maps.googleapis.com/maps/api/js?key=" + this.APIKey + "&callback=initMap";
      script.defer = true;
      script.async = true;
      document.getElementsByTagName('head')[0].appendChild(script);
  }

  public GenerateMap(lat, lng, zoom: number): google.maps.Map {
      this.Map = new google.maps.Map(document.getElementById('map') as HTMLElement, {
        zoom: zoom,
        center: new google.maps.LatLng(lat, lng),
        disableDefaultUI: false,
        mapTypeId: 'roadmap'
      });
      return this.Map;
  }

  public AddCircle(lat, lng, radius: number): google.maps.Map {
    const cityCircle = new google.maps.Circle({
      strokeColor: "#FF0000",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: "#FF0000",
      fillOpacity: 0.35,
      map: this.Map,
      center: new google.maps.LatLng(lat, lng),
      radius: radius * 1000,
    });
    return this.Map;
  }

  public GenerateMapWithCircle(lat, lng, zoom: number, radius: number): google.maps.Map {
    this.ClearMarkers();
    var center: google.maps.LatLng = new google.maps.LatLng(lat, lng);
    this.Map = new google.maps.Map(document.getElementById('map') as HTMLElement, {
      zoom: zoom,
      center: center,
      disableDefaultUI: false,
      mapTypeId: 'roadmap'
    });
    const cityCircle = new google.maps.Circle({
      strokeColor: "#FF0000",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: "#FF0000",
      fillOpacity: 0.35,
      map: this.Map,
      center: center,
      radius: radius * 1000,
    });

    this.asrv.SiteGeoLocations.forEach(site => {
      var position: google.maps.LatLng = new google.maps.LatLng(site.Latitude, site.Longitude)
      if (google.maps.geometry.spherical.computeDistanceBetween(position, center) <= radius * 1000) {
        var displaySite: NonBiologicalName = new NonBiologicalName();
        displaySite.ActorId = site.ActorId
        displaySite.Name = site.Name
        this.esrv.CurrentExclusion.ClientValues.SiteCollection.push(displaySite);
        this.esrv.CurrentExclusion.SiteIdCollection.push(site.ActorId);
        this.AddMarker(site.Latitude, site.Longitude, site.Name);
      }
    });
    return this.Map;
  }

  public GenerateMapWithCustomDrawing(lat, lng, zoom: number): google.maps.Map {
    this.ClearMarkers();
    this.PolylineLatLng = [];
    this.Map = new google.maps.Map(document.getElementById('map') as HTMLElement, {
      zoom: zoom,
      center: new google.maps.LatLng(lat, lng),
      disableDefaultUI: false,
      mapTypeId: 'roadmap',
      clickableIcons: false
    });

    this.listner = this.Map.addListener("click", (event) => {
      this.DrawNewMarkerOnTheMap(event.latLng);
    });
    
    return this.Map;
  }

  public DrawNewMarkerOnTheMap(position) {
    this.PolylineLatLng.push(position);

    const polyline = new google.maps.Polyline({
      path: this.PolylineLatLng,
      geodesic: true,
      strokeColor: "#FF0000",
      strokeOpacity: 1.0,
      strokeWeight: 2,
    })

    if (!this.PolylineMarker) {
      var marker = new google.maps.Marker({
        position: position,
        map: this.Map
      });

      marker.addListener("click", () => {
        this.AddFinalLineToMap(marker.getPosition());
      })

      this.PolylineMarker = marker;
    }

    polyline.setMap(this.Map);
    this.Polylines.push(polyline);
  }

  public AddFinalLineToMap(position) {
    if (confirm('Finish?')) {
      this.PolylineLatLng.push(position);
      this.Polylines.forEach(polyline => {polyline.setMap(null)})
      this.PolylineMarker.setMap(null);
      this.PolylineMarker = null;
      this.GeneratePolygon();
    }
  }

  private GeneratePolygon() {
    const shape: Shape = new Shape();
    shape.polygon = new google.maps.Polygon({
      paths: this.PolylineLatLng,
      strokeOpacity: 0.8,
      strokeWeight: 3,
      fillColor: "#FF0000",
      fillOpacity: 0.35,
    })
    this.PolylineLatLng = [];

    shape.polygon.addListener("rightclick", () => { 
      if (confirm('Would you like to remove this area?')) { 
        this.RemovePolygon(shape); 
      }; 
    });

    this.asrv.SiteGeoLocations.forEach(site => {
      var position: google.maps.LatLng = new google.maps.LatLng(site.Latitude, site.Longitude)
      if (google.maps.geometry.poly.containsLocation(position, shape.polygon)) {
        if (!this.esrv.CurrentExclusion.ClientValues.SiteCollection.some(i => i.ActorId === site.ActorId)) {
          var displaySite: NonBiologicalName = new NonBiologicalName();
          displaySite.ActorId = site.ActorId;
          displaySite.Name = site.Name;
          this.esrv.CurrentExclusion.ClientValues.SiteCollection.push(displaySite);
          this.esrv.CurrentExclusion.SiteIdCollection.push(site.ActorId);
          shape.markers.push(this.AddMarker(site.Latitude, site.Longitude, site.Name));
        };
        shape.sites.push(site);
      };
    });
    this.SortSiteNameAscending();
    shape.polygon.setMap(this.Map);
    this.Shapes.push(shape);
    google.maps.event.clearInstanceListeners(this.listner);
  }

  RemovePolygon(shape: Shape) {
    // Remove the Polygon and markers
    shape.polygon.setMap(null);
    shape.markers.forEach(marker => {marker.setMap(null);});
    
    // Remove the shape
    let index = this.Shapes.indexOf(shape, 0);
    if (index > -1) {
      this.Shapes.splice(index, 1);
    }

    // Reset the Map
    this.esrv.CurrentExclusion.ClientValues.SiteCollection = [];
    this.Shapes.forEach(shape => {
      shape.markers.forEach(marker => {
        marker.setMap(null);
      });
      shape.markers = [];

      shape.sites.forEach(site => {
        if (!this.esrv.CurrentExclusion.ClientValues.SiteCollection.some(i => i.ActorId === site.ActorId)) {
          var displaySite: NonBiologicalName = new NonBiologicalName();
          displaySite.ActorId = site.ActorId;
          displaySite.Name = site.Name;
          shape.markers.push(this.AddMarker(site.Latitude, site.Longitude, site.Name));
          this.esrv.CurrentExclusion.ClientValues.SiteCollection.push(displaySite);
        }
      });
    });
  }

  public AddBlankMarker(lat, lng): google.maps.Map {
      new google.maps.Marker({
        position: new google.maps.LatLng(lat, lng),
        map: this.Map
      });

      return this.Map;
  }

  public AddMarker(lat, lng, text): google.maps.Marker {
    const infowindow = new google.maps.InfoWindow({
      content: text
    }); 
    
    const marker = new google.maps.Marker({
      position: new google.maps.LatLng(lat, lng),
      map: this.Map
    });

    marker.addListener("click", () => {
      infowindow.open(this.Map, marker)
    })

    this.Markers.push(marker);
    return marker;
  }

  public ClearMarkers() {
    this.Markers.forEach(marker => {marker.setMap(null)});
    this.Markers = [];
  }

  SiteWithinLatBounds(Westbound, Eastbound, site: Site): boolean {
    return (site.Latitude >= Westbound && site.Latitude <= Eastbound)
  }

  SiteWithinLngBounds(Westbound, Eastbound, site: Site): boolean {
    return (site.Longitude >= Westbound && site.Longitude <= Eastbound)
  }

  SortSiteNameAscending(){
    this.esrv.CurrentExclusion.ClientValues.SiteCollection.sort((a, b) => {
      return a.Name.localeCompare(b.Name);
    }); 
  }
}

class Shape {
  polygon: google.maps.Polygon;
  markers: google.maps.Marker[] = [];
  sites: Site[] = [];
}