import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { Communication } from 'src/app/models/Communication';
import { DataService } from 'src/app/services/data.service';
import { ActorService } from 'src/app/services/actor.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ErrorhanderService } from 'src/app/services/errorhander.service';
import { Title } from '@angular/platform-browser';
import { Tags } from 'src/app/models/Tags';
import { UserDetail } from 'src/app/models/UserDetail';
import { PostObjects } from 'src/app/models/PostObjects';
import { BiologicalUserInfo } from 'src/app/models/actor/Name';
import { MessagingService } from 'src/app/services/messaging.service';

@Component({
  selector: 'app-read-compose-message',
  templateUrl: './read-compose-message.component.html',
  styleUrls: ['./read-compose-message.component.scss']
})
export class ReadComposeMessageComponent implements OnInit {
  public sendTo: BiologicalUserInfo[] = [];
  public communicationId: number;
  public dataHasLoaded: boolean = false;
  public UserName: string;
  public postObjects: PostObjects = new PostObjects();
  public currentRoute: string;

  public communication: Communication;
  @Input('communication') get Communication(): Communication {
    return this.communication;
  };
  @Output('communication') communicationChange: EventEmitter<Communication> = new EventEmitter();
  set Communication(val: Communication) {
    this.communication = val;
    this.communicationChange.emit(this.communication);
  }  

  public composing: Boolean;
  @Input('composing') get Composing(): Boolean {
    return this.composing;
  };
  @Output('composing') composingChange: EventEmitter<Boolean> = new EventEmitter();
  set Composing(val: Boolean) {
    this.composing = val;
    this.composingChange.emit(this.composing);
  }  

  constructor (
    public msrv: MessagingService,
    public dsrv: DataService, 
    public asrv: ActorService, 
    private route: ActivatedRoute, 
    private router: Router,
    private errorHandler: ErrorhanderService,
    private titleService: Title,
    private tags: Tags) { 
  }

  ngOnInit() {
  }

  CallCloseCompose() {
    this.msrv.ShowMessage = false;
  }

  @Output() CloseCompose = new EventEmitter<string>();
}
