<div class="container global-seperator" *ngIf="!showHideInfo">
    <div class="row seperator">
        <div class="col-md-12">
            <h4><b>Terms & Conditions</b></h4>
        </div>
    </div>
    <div id="grpTermsAndConditions" class="row seperator">
        <div class="page-bottom-text">
            <b>You accept </b>our 
            <span (click)="ShowTerms(false);" style="height: 100%; cursor:pointer"><b><u>Terms</u></b></span>, 
            <span style="height: 100%;"><b>Disclaimer</b></span> and 
            <span (click)="ShowPrivacyPolicy(false);" style="height: 100%; cursor:pointer"><b><u>Privacy Notice</u> </b></span>, which we have summarised for you and you consent to your personal data being shared as described therein.
        </div>   
        <input style="margin:auto;width:10%;" type="checkbox" value="true" class="md-checkbox form-control form-checkbox bottom-checkbox" [(ngModel)]="esrv.CurrentExclusion.ClientValues.TermsAndConditions">
    </div>

    <div id="grpReinstate" class="row seperator">
        <div class="page-bottom-text">
            <b>You agree</b> to self-exclusion and <b>accept</b> that this cannot be cancelled during your chosen exclusion period, which will <b>automatically</b> extend for 6 months <b>UNLESS you re-instate.</b> 
        </div>   
        <input style="margin:auto;width:10%;" type="checkbox" value="true" class="md-checkbox form-control form-checkbox bottom-checkbox" [(ngModel)]="esrv.CurrentExclusion.ClientValues.ReinstateTerms">
    </div>

    <div id="grpAgreeToThirdPartyContact" class="row seperator">
        <div class="page-bottom-text">
            <b>You acknowledge</b> that we cannot guarantee your exclusion by third-party AGC operators. 
        </div>   
        <input style="margin:auto;width:10%;" type="checkbox" value="true" class="md-checkbox form-control form-checkbox bottom-checkbox" [(ngModel)]="esrv.CurrentExclusion.ClientValues.AgreeToThirdPartyContact">
    </div>

    <div id="grpOptOutPromo" class="row seperator">
        <div class="page-bottom-text">
            <b>You acknowledge</b> that, within 2 days from the start of your self-exclusion period, you will be removed from the marketing and promotional lists of all operators within a 1km radius. 
        </div>   
        <input style="margin:auto;width:10%;" type="checkbox" value="true" class="md-checkbox form-control form-checkbox bottom-checkbox" [(ngModel)]="esrv.CurrentExclusion.ClientValues.OptOutPromo">
    </div>
</div>



<div class="container global-seperator" *ngIf="showHideInfo">
    <app-customer-acknowledgement [(html)]="html" (CloseForm)="CloseForm();"></app-customer-acknowledgement>
</div>
