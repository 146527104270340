<div class="excluder-box container global-seperator" [style.background]="GetInteractionColour()" (click)="ViewInteraction(interaction)">
    <div class="row excluder-image">
        <div *ngIf="showImageSpinner" class="spinner-border" role="status">
            <span class="sr-only"></span>
        </div>
        <img *ngIf="!showIconImage && !showImageSpinner" class="excluder-image-inner" [src]="interactionImage" [style.borderRadius]="asrv.Globals.Border.StandardRadius" />
        <div *ngIf="showIconImage && !showImageSpinner" class="icon-container excluder-image-inner" [style.width]="asrv.Globals.Positioning.StandardBoxWidth">
            <app-icon-image class="icon-app excluder-image icon-image-inner" [(iconId)]="mediaId"></app-icon-image>
        </div>
    </div>
    <div class="row row-height-adjustment card-text" >
        <div class="col-xs-6 card-inner-text-container" style="height:100%;">
            <div class="row row-height-adjustment-content" [style.width]="asrv.Globals.Positioning.StandardBoxWidth" [style.margin]="asrv.Globals.Positioning.Auto" [style.paddingTop]="asrv.Globals.Positioning.Small" style="overflow:hidden;white-space: nowrap;">
                <span class="excluder-text-header"><b *ngIf="interaction.Forename || interaction.Surname">{{interaction.Forename}}<br>{{interaction.Surname}}</b><b *ngIf="!interaction.Forename && !interaction.Surname">{{interaction.Alias}}</b></span>
            </div>
            <div class="row" [style.width]="asrv.Globals.Positioning.StandardBoxWidth" [style.margin]="asrv.Globals.Positioning.Auto" [style.paddingTop]="asrv.Globals.Positioning.ExtraSmall">
                <span *ngIf="!archived" class="excluder-text-body"><b>{{interaction.Start | date: 'dd/MM/yyyy'}}<br>{{GetEndDate() | date: 'dd/MM/yyyy'}}</b></span>
            </div>
        </div>
        <div class="col-xs-6 card-inner-text-container" style="height:100%;">
            <div class="row card-inner-image-row">
                <div class="flex-no-padding">
                </div>
            </div>
            <div class="flex-no-padding" *ngIf="InteractionOnTimeOut()">
                <span class="card-img-text card-img-radius"><b>T</b></span>
            </div>
            <div class="flex-no-padding" *ngIf="ReinstatedSelfExcluder()">
                <span class="card-img card-img-radius"><img class="col-img" src=".\assets\icons\Bacta Assets\Reinstated SE.svg"></span>
            </div>
            <div class="row card-inner-image-row">
                <div class="flex-no-padding">
                </div>
            </div>
        </div>
    </div>
</div>