import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  public passwordResetToken: string;
  public Password: string;
  public Confirm: string;
  showSpinner = false;
  constructor(
    public dsrv: DataService,
    public router: Router,
    public route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.passwordResetToken = this.route.snapshot.params['id'];
  }

  SetPassword() {
    this.showSpinner = true;
    if (this.Password === this.Confirm) {
      const token: Token = {
        Token: this.passwordResetToken,
        Password: this.Password
      }
      this.dsrv.Post<Token>('api/View_CredentialResetTokens', token).subscribe(res => {
        alert('Password updated, please login with your new credentials.');
        this.showSpinner = false;
        this.router.navigate(['*']);
      })
    } else {
      alert('Password fields do not match.');
      this.showSpinner = false;
    }
  }
}

interface Token {
  Token: string;
  Password: string;
}
