import { Views_MediaModel } from './InteractionExclusion/Views_MediaModel';
import { ISDDateMethods } from './ISDDateMethods';

export class CustomerInteractionBrief {
    private dateMethods: ISDDateMethods = new ISDDateMethods();

    InteractionId: number;
    InteractedActorId: number;
    BirthDate: Date;
    GenderId: number;
    HonorificId: number;
    Reference: string;
    Sites_ActorId: number;
    Start: Date;
    PeriodDays: number;
    Forename: string;
    Surname: string;
    Alias: string;
    Image: string;
    TagIdCollection: number[] = [];
    Media: Media;
}

interface Media {
    MediaId: number;
}