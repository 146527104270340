import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, Directive, Input, OnChanges } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ActorService } from 'src/app/services/actor.service';
import { Tags } from 'src/app/models/Tags';
import { ExcluderService } from 'src/app/services/excluder.service';
import { Site } from 'src/app/models/Site';
import { ExcluderViewService } from 'src/app/services/excluder-view.service';
import { ExcluderGalleryService } from 'src/app/services/excluder-gallery.service';

@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss']
})
export class ViewComponent implements OnInit {
 @ViewChild('btnExtend') btnExtend: ElementRef;
 @ViewChild('btnBreach') btnBreach: ElementRef;
 @ViewChild('btnReinstate') btnReinstate: ElementRef;
 @ViewChild('btnMoreInfo') btnMoreInfo: ElementRef;
 @ViewChild('btnUpdate') btnUpdate: ElementRef;
 @ViewChild('btnUpdateExclusion') btnUpdateExclusion: ElementRef;

  public showSpinner: boolean = true;
  constructor (
    public evsrv: ExcluderViewService,
    private titleService: Title,
    private route: ActivatedRoute,
    public asrv: ActorService,
    public gsrv: ExcluderGalleryService
  ) {}

  ngOnInit() {
    this.showSpinner = true;
    this.titleService.setTitle('View Exclusion'); 
    this.evsrv.Initialize(+this.route.snapshot.params['id']).then(value => {
      this.showSpinner = false;
    })
  }
}
