import { Component, OnInit, ViewChild, Input, Output, EventEmitter, ElementRef } from '@angular/core';
import { SiteDetail, EmployeeRow } from 'src/app/models/SiteDetail'
import { ActorService } from 'src/app/services/actor.service';
import { Tags } from 'src/app/models/Tags';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { OrganisationBrief } from 'src/app/models/OrganisationBrief';
import { AdminService } from 'src/app/services/admin.service';
import { EmployeeDetails } from 'src/app/models/EmployeeDetails';
import { Router } from '@angular/router';

@Component({
  selector: 'app-site',
  templateUrl: './site.component.html',
  styleUrls: ['./site.component.scss', '../admin-main-view.component.scss']
})
export class AdminSiteComponent implements OnInit {

  @ViewChild('table') table: ElementRef;

  object = [{id: false}];
  objectKeys = [];
  tableKeys = [];

  public dataLoaded: boolean;
  public showSiteData: boolean = false;
  public site: SiteDetail;
  public searchControl = new Subject<string>();
  public displaySiteSearch: boolean = false;
  public siteSearchPhrase: string;
  public organisationBriefs: OrganisationBrief[] = [];
  private disableTable: boolean;
  public showSuspended: boolean = false;
  
  public dataModel: any;
  @Input('dataModel') get DataModel(): any {
    return this.dataModel;
  };
  @Output('dataModel') dataModelChange: EventEmitter<any> = new EventEmitter();
  set DataModel(val: any) {
    this.dataModel = val;
    this.dataModelChange.emit(this.dataModel);
  } 

  constructor(public asrv: ActorService, private tags: Tags, public adminsrv: AdminService, private router: Router) { 
    this.searchControl.pipe(debounceTime(400), distinctUntilChanged()).subscribe(value => {
        if (value.length > 2) {
          this.SearchSites();
        }
      }
    );
  }

  ngOnInit() {
    if (this.adminsrv.UserRoleId <= 4) {
      this.adminsrv.organisationCreateMode = false;
      this.adminsrv.SetOrganisationFilter();
      this.adminsrv.PopulateSiteList(this.showSuspended).then(value => {
        if (value) {
          this.SetDataObject();
        }
      })
    } else {
      alert('You are unable to view, add or edit site records.');
      this.router.navigate(['user']);
    }
  }

  SetDataObject() {
    this.object = [];
    this.objectKeys = Object.values(this.adminsrv.SiteListToShow.HeaderTranslation()[0]);
    this.tableKeys = Object.keys(this.adminsrv.SiteListToShow.HeaderTranslation()[0]);
    this.adminsrv.SiteListToShow.Rows.forEach(row => {
      let newRow: any = {};
      newRow['SiteId'] = row.SiteId;
      newRow['SiteName'] = row.SiteName;
      this.object.push(newRow);
    })
  }

  FilterOrganisations() {
    this.object = [];
    this.adminsrv.PopulateSiteList(this.showSuspended).then(value => {
      this.SetDataObject();
    });
  }

  SearchSites() {
    this.object = [];
    this.dataModel.Rows.forEach(row => {
      let searchPhrase: string = row['Name'] + '';
      if (searchPhrase.toUpperCase().includes(this.siteSearchPhrase.toUpperCase())) {
        this.object.push(row);
      }

      searchPhrase = row['OrganisationName'] + '';
      if (searchPhrase.toUpperCase().includes(this.siteSearchPhrase.toUpperCase())) {
        if (!this.object.some(i => i['OrganisationName'] === row['OrganisationName']) && !this.object.some(i => i['SiteId'] === row['SiteId'])) {
          this.object.push(row);
        }
      }
    });
  }

  SortSiteList(key: string) {
    this.adminsrv.SortSiteList(key);
    this.ngOnInit();
  }

  ShowSite(row) {
    if (!this.disableTable) {
      document.body.style.cursor = 'progress';
      this.disableTable = true;
      let siteDetails = this.asrv.GetSiteDetails(row['SiteId']).subscribe(res => {
        this.adminsrv.site = res;
        if (res.TagIdCollection.length > 0) {
          this.adminsrv.site.TagIdCollection = res.TagIdCollection;
        }
        let employees = this.asrv.GetActorNames(this.adminsrv.site.EmployeeIdCollection).subscribe(res => {
          this.adminsrv.site.EmployeeCollection = res.map(item => {
            var forename: string = item.NameCollection.find(i => i.NameClassKeyId === 1).Name;
            var surname: string = item.NameCollection.find(i => i.NameClassKeyId === 2).Name;
            const row: EmployeeRow = {
              Id: item.ActorId,
              Name: `${forename} ${surname}`,
              Contactable: this.adminsrv.site.ContactIdCollection.some(i => i === item.ActorId)
            }
            return row;
          })
          employees.unsubscribe();
        })

        siteDetails.unsubscribe();
        this.showSiteData = true;
        this.disableTable = false;
        document.body.style.cursor = 'default';
      });
    }
  }

  AddNewRecord() {
    this.adminsrv.site = new SiteDetail();
    this.showSiteData = true;
  }

  CloseEditForm() {
    this.showSiteData = false;
    // Update the site Model
    this.object = [];
    this.adminsrv.PopulateSiteList(this.showSuspended).then(value => {
      this.adminsrv.SiteListToShow.Rows.forEach(row => {
        if (!this.object.find(i => i['SiteId'] === row.SiteId)) {
          let newRow: any = {};
          newRow['SiteId'] = row.SiteId;
          newRow['SiteName'] = row.SiteName;
          this.object.push(newRow);
        }
      })
    });
  }
}
