import { Component, OnInit } from '@angular/core';
import { BiologicalUserInfo } from 'src/app/models/actor/Name';
import { GenericTagList } from 'src/app/models/admin/GenericTag';
import { DropDownItem } from 'src/app/models/controllers/Standard Controllers/Select';
import { ActorService } from 'src/app/services/actor.service';
import { MessagingService } from 'src/app/services/messaging.service';

@Component({
  selector: 'app-recipient-filter',
  templateUrl: './recipient-filter.component.html',
  styleUrls: ['./recipient-filter.component.scss']
})
export class RecipientFilterComponent implements OnInit {
  public tagFilters: GenericTagList = new GenericTagList(this.asrv);
  public tagLookupOperator: string = 'Organisation';
  public selectedActor: DropDownItem;
  public sendToNames: string = '';
  public sendTo: DropDownItem[] = [];
  public employeeFilterId: number;
  public employees: BiologicalUserInfo[] = [];
  public loadingEmployeeDropDown: boolean;
  public SendingToEveryone: boolean = false;

  constructor(public asrv: ActorService, public msrv: MessagingService) { }

  ngOnInit() {
    this.PopulateEmployeeDropDown();
  }

  PopulateEmployeeDropDown() {
    this.loadingEmployeeDropDown = true;
    this.employees = [];
    this.asrv.GetEmployeeIds(10000, 0, this.msrv.OrganisationIdFilter, [1]).then(value => {
      this.asrv.GetActorNames(value).subscribe(res => {
        res.forEach(employee => {
          this.employees.push({
            ActorId: employee.ActorId,
            Forename: employee.NameCollection.find(i => i.NameClassKeyId === 1).Name,
            Surname: employee.NameCollection.find(i => i.NameClassKeyId === 2).Name,
            ActorRoleCollection: null,
          })
        })
      })
    })
  }

  AddSendTo() {
    this.PushBiologicalNameToList(this.selectedActor);
  }

  AddEveryoneToSendTo() {
    if (this.SendingToEveryone) {
      this.msrv.AllCommunicationUserIds.forEach(id => {
        this.msrv.SelectedRecipients.push({Key: id, Value: ''})
      })
    } else {
      this.msrv.SelectedRecipients = [];
    }
  }

  BackspaceUp(event: any) {
    var newSendTo: DropDownItem[] = [];
    this.msrv.SelectedRecipients.forEach(name => {
      if (this.sendToNames.search(name.Value + '#' + name.Key) >= 0) {
        // console.log(`added: ${name}`);
        newSendTo.push(name);
      }
    })

    this.msrv.SelectedRecipients = [];
    this.sendToNames = '';
    newSendTo.forEach(name => {
      this.PushBiologicalNameToList(name);
    })
    this.sendTo = this.msrv.SelectedRecipients;
  }

  PushBiologicalNameToList(name: DropDownItem) {
    if (!this.msrv.SelectedRecipients.some(i => i.Key === name.Key)) {
      if (this.sendToNames.length === 0) {
        this.sendToNames = name.Value + '#' + name.Key;
      } else {
        this.sendToNames = this.sendToNames + ';' + name.Value + '#' + name.Key;
      }
      this.msrv.SelectedRecipients.push(name);
    }
  }

  TagFilters() {
    let sendObject;
    if (this.tagFilters.Rows.length > 0) {
      sendObject.Model.SiteParameters.TagIdLookupOperator = 'Exists';
      this.tagFilters.Rows.forEach(row => {
        sendObject.Model.SiteParameters.TagIdCollection.push(row['TagId']);
      });
    }
  }
}
