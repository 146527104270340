<div class="container global-seperator" style="overflow-y:auto;overflow-x:hidden;">
  <div class="container global-seperator" style="padding: 20px 0 !important;">
    <div class="row">
      <div class="col-md-12">
        <h3><b>Toolkit</b></h3>
      </div>
    </div>
  </div>

  <div class="container toolkit--wrapper">
    <div class="row">
      <div class="col-md-4 icon">
        <div class="icon--wrapper" routerLink="/user/fire-check">
          <img src="../../../assets/icons/toolkit/fire.svg">
          <div class="text">
            Fire Check
          </div>
        </div>
      </div>
      <div class="col-md-4 icon">
        <div class="icon--wrapper"  routerLink="/user/toilet-check">
          <img src="../../../assets/icons/toolkit/toilet.svg">
          <div class="text">
            Toilet Check
          </div>
        </div>
      </div>
      <div class="col-md-4 icon">
        <div class="icon--wrapper"  routerLink="/user/cleaning-check">
          <img src="../../../assets/icons/toolkit/cleaning.svg">
          <div class="text">
            Cleaning Check
          </div>
        </div>
      </div>
      <div class="col-md-4 icon">
        <div class="icon--wrapper" routerLink="/user/machine-payout">
          <img src="../../../assets/icons/toolkit/payouts.svg">
          <div class="text">
            Machine Payouts
          </div>
        </div>
      </div>
      <div class="col-md-4 icon">
        <div class="icon--wrapper" routerLink="/user/machine-service">
          <img src="../../../assets/icons/toolkit/service.svg">
          <div class="text">
            Machine<br> Service  Visits
          </div>
        </div>
      </div>
      <div class="col-md-4 icon">
        <div class="icon--wrapper" routerLink="/user/statutory-visit">
          <img src="../../../assets/icons/toolkit/statutory.svg">
          <div class="text">
            Statutory Visit
          </div>
        </div>
      </div>
    </div>

  </div>
</div>