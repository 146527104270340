import { Honorific } from '../Honorific';
import { Gender } from '../Gender';
import { ExclusionDurationOption } from '../ExclusionDurationOption';
import { SiteCollection } from '../SiteCollection';
import { ISDDateMethods } from '../ISDDateMethods';
import { ActorService } from 'src/app/services/actor.service';
import { ExcluderService } from 'src/app/services/excluder.service';
import { ApiService } from 'src/app/services/api.service';

// Class for creating tabular reports.
// Every tabular class requires the following properties.
// Rows: any[] = [] --Holds all the newRows pushed.
// HeaderTransaltion = [{}]  --This will print all the headers to the table.
// newRow = [{}] --Make the values all blank but match the HeaderTranslationIds
// public AddRow(rowObject) {} --Use this push rows, ensure all values are hardcoded for now.

export class CustomerList {
    private DateMethods: ISDDateMethods = new ISDDateMethods();
    public Rows: any[] = [];
    public Honorifics: Honorific[] = [];
    public Genders: Gender[] = [];
    public DurationOptions: ExclusionDurationOption[] = []; 
    public SubscriptionsOpened: number;
    public CompletedSubscriptions: number = 0;
    public DataLoaded:boolean = false;
    private siteNamePromises: SiteNamePromise[] = [];
    public HeaderTranslation = [{
        'HonorificId': 'Title', 
        'Forename': 'Forename', 
        'Surname': 'Surname', 
        'Start': 'Start',
        'End': 'End',
        'BirthDate': 'Birth Date', 
        'Mobile': 'Mobile', 
        'Radius': 'Radius (KM)', 
        'SiteId': 'Site',
        'Reference': 'Reference'
    }];

    constructor(
        public asrv: ActorService,
        public esrv: ExcluderService,
        public ids: number[],
        private _api: ApiService
        ) {
        this.asrv.GetHonorifics().then(value => {
            this.Honorifics = value;
        });
        this.asrv.GetGenders().then(value => {
            this.Genders = value;
        });

        this.ids.forEach(id => {
            this.AddRow(id);
        })

        this.DataLoaded = true;
    }

    public AddRow(id: number) {
        let returnRow = {};
        returnRow['InteractionId'] = id;
        returnRow['Reference'] = false;
        returnRow['BirthDate'] = false;
        returnRow['Forename'] = false;
        returnRow['Surname'] = false;
        returnRow['Mobile'] = false;
        returnRow['Email'] = false;
        returnRow['Radius'] = false;
        returnRow['Start'] = false;
        returnRow['End'] = false;
        returnRow['GenderId'] = false;
        returnRow['HonorificId'] = false;
        returnRow['SiteId'] = false;
        this._api.getViewExclusionCompositeModel(id).subscribe(res => {
            res.InteractedActorCompositeModel = res.InteractedActorCompositeModelCollection[0];
            returnRow['Reference'] = res.Reference;
            returnRow['BirthDate'] = this.DateMethods.FormatDateForReport(new Date(res.InteractedActorCompositeModel.BiologicalActorCompositeModel.BirthDate));
            returnRow['Forename'] = res.InteractedActorCompositeModel.BiologicalActorCompositeModel.Forename;
            returnRow['Surname'] = res.InteractedActorCompositeModel.BiologicalActorCompositeModel.Surname;
            returnRow['Mobile'] = res.InteractedActorCompositeModel.BiologicalActorCompositeModel.Mobile;
            returnRow['Email'] = res.InteractedActorCompositeModel.BiologicalActorCompositeModel.Email;
            returnRow['Radius'] = 1;
            returnRow['Start'] = this.DateMethods.FormatDateForReport(new Date(res.InteractionMonitoringPeriod.Start));
            returnRow['End'] = this.DateMethods.FormatDateForReport(this.DateMethods.AddDays(res.InteractionMonitoringPeriod.Start, res.InteractionMonitoringPeriod.Duration));
            returnRow['GenderId'] = this.Genders.find(x => x.GenderId === res.InteractedActorCompositeModel.BiologicalActorCompositeModel.GenderId).Name;
            returnRow['HonorificId'] = this.Honorifics.find(x => x.HonorificId === res.InteractedActorCompositeModel.BiologicalActorCompositeModel.HonorificId).Name;
            this.GetSiteName(res.SiteId).then(value => {
                returnRow['SiteId'] = value;
            })
        })
        this.Rows.push(returnRow);
    }

    GetSiteName(siteid): Promise<string> {
        if (this.siteNamePromises.some(i => i.SiteId === siteid)) {
            return this.siteNamePromises.find(i => i.SiteId === siteid).Promise;
        } else {
            return new Promise<string>(resolve => {
                this.asrv.GetSiteNameCollection([siteid]).then(value => {
                    if (value) {
                        resolve(value[0].Name);
                    } else {
                        resolve('');
                    }
                })
            })
        }
    }
}

class SiteNamePromise {
    SiteId: number;
    Promise: Promise<string>;
}
