<div class="global-seperator"></div>
<div class="row">
    <div class="col-md-6">
        <h4>Exclusion Sites</h4>
    </div>
</div>
<div class="col-md-12">
    <div class="address-in-radius-table bacta-table-outline" style="margin-top:7px;">
        <div class="address-in-radius-table_header-format">
            <div class="row">
                <div class="col-sm-12 bacta-table-header-text">All Sites Excluded From</div>                                    
            </div>
        </div>
        <div class="bacta-table-row-format">
            <div class="row" *ngFor="let site of esrv.CurrentExclusion.ClientValues.SiteCollection">
                <div class="col-sm-12 bacta-table-row-content" style="width:100%;">
                    {{site.Name}}
                </div>
            </div>
            <div *ngIf="(showSpinner)" class="row" style="width:100%;">
                <div class="spinner-border text-danger" role="status" style="margin-left:15px;"></div>
            </div>
        </div>
    </div>
</div>
