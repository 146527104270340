<div class="container global-seperator" *ngIf="!(fromReportBuilder)" style="margin-top:10px;">
    <div class="row">
        <div class="col-lg-4 d-none d-lg-block">
            <div class="excluder-box container global-seperator">
                <svg class="icon" style="text-align:center;">
                    <image xlink:href="./assets/icons/application/product-exclusion.svg"
                        alt="Products / Features" class="icon" style="height: 100%; margin: 10px;"  />
                </svg>
            </div>
        </div>
        <div class="d-none d-md-block col-md-12 col-lg-8" style="margin-top:10px;">
            <div class="col-xs-12 excluder-box table" style="border: 1px solid black;color:black; border-radius: 0.5em;padding-top:5px;padding-bottom:5px;">
                <div class="row table-row">
                    <div class="flex-no-padding col-xs-2 col-sm-1">
                        <div class="col-img col-img-radius" style="background-color:#d11722"></div>
                    </div>
                    <div class="col-xs-10 col-sm-5 key-table-text">New Exclusion</div>
                    <div class="flex-no-padding col-xs-2 col-sm-1"> 
                        <img class="col-img col-img-radius" src=".\assets\icons\Bacta Assets\24 icon.svg">
                    </div>
                    <div class="col-xs-10 col-sm-4 key-table-text">24 Hour Cooling-off</div>
                </div>
                <div class="row table-row">
                    <div class="flex-no-padding col-xs-2 col-sm-1 key-table-text">
                        <div class="col-img col-img-radius" style="background-color:#f8b03d"></div>
                    </div>
                    <div class="col-xs-10 col-sm-5 key-table-text">Current Exclusion</div>
                    <div class="flex-no-padding col-xs-2 col-sm-1"> 
                        <img class="col-img" src=".\assets\icons\Bacta Assets\Breach icon.svg" alt="Products / Features">
                    </div>
                    <div class="col-xs-10 col-sm-4 key-table-text">Breach</div>
                </div>
                <div class="row table-row">
                    <div class="flex-no-padding col-xs-2 col-sm-1  key-table-text">
                        <div class="col-img col-img-radius" style="background-color:#64b32e"></div>
                    </div>
                    <div class="col-xs-10 col-sm-5 key-table-text">Awaiting Re-instatement</div>
                    <div class="flex-no-padding col-xs-2 col-sm-1  key-table-text">
                        <div class="col-img col-img-radius" style="color:#64b32e; background-color: white;cursor:default;">+6</div>
                    </div>
                    <div class="col-xs-10 col-sm-5 key-table-text">Extension Applied</div>
                </div>
            </div>
        </div>
        <div class="d-block d-md-none col-md-12 col-lg-8">
            <div class="col-xs-12 excluder-box container global-seperator table" style="border: 1px solid black;color:black; border-radius: 0.5em;">
                <div class="row table-row">
                    <div class="flex-no-padding col-sm-1" style="width:20%;">
                        <div class="col-img col-img-radius" style="background-color:#d11722"></div>
                    </div>
                    <span class="key-table-text-mobile" style="width:80%;">SE Less Than 5 Days</span>
                </div>
                <div class="row table-row">
                    <div class="flex-no-padding col-sm-1" style="width:20%;">
                        <div class="col-img col-img-radius" style="background-color:#f8b03d"></div>
                    </div>
                    <span class="key-table-text-mobile" style="width:80%;">SE More Than 5 Days</span>
                </div>
                <div class="row table-row">
                    <div class="flex-no-padding col-sm-1" style="width:20%;">
                        <div class="col-img col-img-radius" style="background-color:#64b32e"></div>
                    </div>
                    <span class="key-table-text-mobile" style="width:80%;">SE Ended</span>
                </div>
                <div class="row table-row">
                    <div class="flex-no-padding col-sm-1" style="width:20%;"> 
                        <img class="col-img col-img-radius" src=".\assets\icons\Bacta Assets\24 icon.svg">
                    </div>
                    <span class="key-table-text-mobile" style="width:80%;">24 Hour Cooling-off</span>
                </div>
                <div class="row table-row">
                    <div class="flex-no-padding col-sm-1" style="width:20%;"> 
                        <img class="col-img" src=".\assets\icons\Bacta Assets\Breach icon.svg" alt="Products / Features">
                    </div>
                    <span class="key-table-text-mobile" style="width:80%;">Breach</span>
                </div>
                <div class="row table-row">
                    <div class="flex-no-padding col-sm-1" style="width:20%;">
                        <div class="col-img col-img-radius" style="color:#64b32e; background-color:white; font-size:20px;">+6</div>
                    </div>
                    <span class="key-table-text-mobile" style="width:80%;">Extension Applied</span>
                </div>
            </div>
        </div>
        <div class="row" style="margin-top: 10px;">
            <div class="col-md-6">
                <div class="input-group">
                    <div class="input-group-prepend" style="background-color: darkred !important">
                        <span class="input-group-text" style="background-color: darkred !important">Sort By</span>
                    </div>
                    <select class="form-control sort-by-control drop-down-red" name="sort" [(ngModel)]="sort" (change)="SortOnChange();">
                        <option [ngValue]="0">Date (Newest to Oldest)</option>
                        <option [ngValue]="1">Date (Oldest to Newest)</option>
                        <option [ngValue]="2">Name (A to Z)</option>
                        <option [ngValue]="3">Name (Z to A)</option>
                    </select>  
                </div>
            </div>
            <div class="col-md-6 exclusion-button" style="text-align: right;flex-direction:row-reverse;">
            <input *ngIf="ShowExclusionButton" id="createExclusionButton" class="bacta-button" type="image" alt="Settings" src="./assets/icons/Bacta Assets/Create New Exclusion button.svg" style="background-color: darkred;border: 1px solid darkred;border-radius: 0.6em;" (click)="CreateExcluder();"/>
            </div>
        </div> 
        <div class="row">
            <div *ngIf="gsrv.IsAdmin" class="col-md-6">
                <div class="input-group">
                    <div class="input-group-prepend" style="background-color: darkred !important">
                        <span class="input-group-text" style="background-color: darkred !important">Organisation</span>
                    </div>
                    <select class="form-control sort-by-control drop-down-red" name="Filter" [(ngModel)]="gsrv.ActiveOrganisationFilterId" (ngModelChange)="FilterOrganisations();">
                        <option [ngValue]="0">All</option>
                        <option [ngValue]="organisation.Key" *ngFor="let organisation of gsrv.OrganisationList">{{organisation.Value}}</option>
                    </select>  
                </div>
            </div>
            <div class="col-md-6">
                <div class="input-group">
                    <div class="input-group-prepend" style="background-color: darkred !important">
                        <span class="input-group-text" style="background-color: darkred !important">Site</span>
                    </div>
                    <select class="form-control sort-by-control drop-down-red" name="Filter" [(ngModel)]="gsrv.ActiveSiteFilterId" (ngModelChange)="gsrv.GetExcluderCards();">
                        <option [ngValue]="0">All</option>
                        <option [ngValue]="site.Key" *ngFor="let site of gsrv.SiteListToShow">{{site.Value}}</option>
                    </select>  
                </div>
            </div>  
        </div>
    </div> 
</div>  
<div *ngIf="showSpinner && !(fromReportBuilder)" class="container global-seperator">
    <div class="row" style="height:200px;width:100%;">
        <app-loading style="width:100%;"></app-loading>
    </div>
</div>
<div *ngIf="!showSpinner" class="container global-seperator" style="margin:auto;padding:0;">
    <app-excluder-cards></app-excluder-cards>
</div>