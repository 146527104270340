import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ISDDateMethods } from 'src/app/models/ISDDateMethods';
import { CustomerInteractionBrief } from 'src/app/models/CustomerInteractionBrief';
import { InteractionGalleryService } from 'src/app/services/interaction-gallery.service';
import { ActorService } from 'src/app/services/actor.service';

@Component({
  selector: 'app-interaction-cards',
  templateUrl: './interaction-cards.component.html',
  styleUrls: ['./interaction-cards.component.scss']
})
export class InteractionCardsComponent implements OnInit {

  @ViewChild('searchField') searchField: ElementRef;
  public searchPhrase: string;
  private dateMethods: ISDDateMethods = new ISDDateMethods();

  constructor(public gsrv: InteractionGalleryService, public asrv: ActorService) {}

  ngOnInit() {
  }

  GetInteractionColour(interaction: CustomerInteractionBrief) : string {    
    let diffDaysStart = this.dateMethods.DateDifferenceInDays(interaction.Start)
    let daysUntilReview = (interaction.PeriodDays - diffDaysStart)
    
    if(daysUntilReview < 0)
      return this.asrv.Globals.BackgroundColor.Red;
    else if(daysUntilReview > 0 && daysUntilReview < 5)
      return this.asrv.Globals.BackgroundColor.Yellow;
    else return this.asrv.Globals.BackgroundColor.Green;
  }
}
