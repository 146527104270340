<div style="margin-top:5px;border:1px solid #223464; height: 1px;"></div>
<div class="row" *ngIf="dateRequired" style="margin-top:5px;">
    <div class="col-md-6">
        <div class="input-group">
            <div class="input-group-prepend">
                <span class="input-group-text">From</span>
            </div>
            <input class="form-control d-none d-lg-block" matInput [matDatepicker]="dp1" (dateInput)="addEvent('input', $event)" (dateChange)="addEvent('change', $event)" [(ngModel)]="dateFrom">
            <div class="input-group-append d-none d-lg-block">
                <mat-datepicker-toggle class="form-control-date-toggle d-none d-lg-block" matSuffix [for]="dp1"></mat-datepicker-toggle>
            </div>
            <mat-datepicker class="d-none d-lg-block" touchUi #dp1></mat-datepicker>
            <input type="date" class="form-control date-picker-padding d-lg-none" [(ngModel)]="dateFrom">
        </div>
    </div>
    <div class="col-md-6">
        <div class="input-group">
            <div class="input-group-prepend">
                <span class="input-group-text">To</span>
            </div>
            <input class="form-control d-none d-lg-block" matInput [matDatepicker]="dp2" (dateInput)="addEvent('input', $event)" (dateChange)="addEvent('change', $event)" [(ngModel)]="dateTo">
            <div class="input-group-append d-none d-lg-block">
                <mat-datepicker-toggle class="form-control-date-toggle d-none d-lg-block" matSuffix [for]="dp2"></mat-datepicker-toggle>
            </div>
            <mat-datepicker class="d-none d-lg-block" touchUi #dp2></mat-datepicker>
            <input type="date" class="form-control date-picker-padding d-lg-none" [(ngModel)]="dateTo">
        </div>
    </div>
</div>
<div class="row" *ngIf="tagRequired" style="margin-top:5px;">
    <!--[(dataModel)]="tagFilters"-->
    <app-tags id="appTags" [(allowNewTags)]="allowNewTags"></app-tags>
</div>
<div class="row" style="text-align: center;margin-top:10px;">
    <div class="col-md-12">
        <button type="button" id="btnExtend" class="btn btn-dark view-button-standard" (click)="FetchData();">         
            <span *ngIf="!dataSpinner">Run Report</span>
            <div *ngIf="dataSpinner" class="spinner-border" role="status">
                <span class="sr-only"></span>
            </div> 
        </button>
    </div>
</div>

<div class="row" *ngIf="tableRequired && dataReturned && showData">
    <div class="col-md-12" style="padding:0;">
        <app-table [reportName]="reportName" [dataModel]="dataModel"></app-table>
    </div>
</div>
