export class UserCredentials {
    // <Constructors>
    // </Constructors>

    // <Fields>
    Login : string;
    Password : string;
    // </Fields>

    // <Methods>
    // <Private>
    // </Private>

    // <Public>
    // </Public>
    // </Methods>
}