import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { Views_InteractionMonitoringPeriodsModel } from 'src/app/models/InteractionExclusion/Views_InteractionMonitoringPeriodsModel';
import { Views_InteractionsCompositeModel } from 'src/app/models/InteractionExclusion/Views_InteractionsCompositeModel';
import { ISDDateMethods } from 'src/app/models/ISDDateMethods';
import { ActorService } from 'src/app/services/actor.service';
import { InteractionService } from 'src/app/services/interaction.service';

/*
Change History:
  0.9: 
    Added an Input / Output for an Interaction as this can be a New Interaction or a Review for a previous Inteaction.
    This also included some functions moved from Interaction service.
*/

const archived: number = 4;

@Component({
  selector: 'app-interaction-review',
  templateUrl: './interaction-review.component.html',
  styleUrls: ['./interaction-review.component.scss']
})
export class InteractionReviewComponent implements OnInit {
  amountOfWeeks = 0;
  public ReviewPeriodId: number = 0;
  public ReviewPeriodOptions = [
    { ReviewPeriodId: 0, ReviewPeriodInWeeks: 4, ReviewPeriodInDays: 28 },
    { ReviewPeriodId: 1, ReviewPeriodInWeeks: 8, ReviewPeriodInDays: 56 },
    { ReviewPeriodId: 2, ReviewPeriodInWeeks: 12, ReviewPeriodInDays: 84 }
  ]

  @ViewChild('btnSave') btnSave: ElementRef;
  isUser = false;
  userList = [];
  public reviewRequired: boolean;
  public monitoringPeriod: Views_InteractionMonitoringPeriodsModel;

  public isReview: boolean;
  @Input('isReview') get IsReview(): boolean {
    return this.isReview;
  };
  @Output('isReview') isReviewChange: EventEmitter<boolean> = new EventEmitter();
  set IsReview(val: boolean) {
    this.isReview = val;
    this.isReviewChange.emit(this.isReview);
  }

  constructor(public isrv: InteractionService, public asrv: ActorService) { }

  ngOnInit() {
    this.reviewRequired = false;
    this.monitoringPeriod = new Views_InteractionMonitoringPeriodsModel();
    this.asrv.PopulateColleagueSelectForSiteId(this.asrv.DefaultSiteId).then(value => {
      this.isrv.PersonCompletingForm = this.asrv.GetMyUserID();
    });
    this.asrv.GetActorRole().then(user => {
      if (user.ActorRoleId === 6) {
        this.isUser = true;
        const _userID = this.asrv.GetMyUserID();
        this.userList = this.asrv.ColleagueSelect.DropDownItems.filter(x => x.Key === _userID);
      }
    });
  }

  setReviewDuration() {
    this.amountOfWeeks = this.ReviewPeriodOptions.find(x => x.ReviewPeriodInDays === this.monitoringPeriod.Duration).ReviewPeriodInWeeks;
  }

  Save() {
    this.DisableButtonSaveInteraction();
    if (this.reviewRequired) {
      this.AddReviewToInteraction();
    } else {
      this.AddArchiveTagToInteraction();
    }

    if (this.IsReview && this.reviewRequired) {
      this.isrv.SaveReview().then(value => {
        this.ProcessStage(value);
      })
    } else {
      this.isrv.Save(false).then(value => {
        this.ProcessStage(value);
      })
    }
  }

  AddReviewToInteraction() {
    if (this.IsReview) {
      this.isrv.Review.InteractionMonitoringPeriod = this.monitoringPeriod;
    } else {
      this.isrv.Interaction.InteractionMonitoringPeriod = this.monitoringPeriod;
    }
  }

  AddArchiveTagToInteraction() {
    if (this.IsReview && this.reviewRequired) {
      this.isrv.Review.TagIdCollection.push(archived);
    } else if (this.IsReview && !this.reviewRequired) {
      this.isrv.Interaction.TagIdCollection.push(archived);
    } else {
      this.isrv.Interaction.TagIdCollection.push(archived);
    }
    // console.log(this.isrv.Interaction.TagIdCollection);
  }

  ProcessStage(value: boolean) {
    if (value) {
      this.GoForward.next();
    }
    this.EnableButtonSaveInteraction();
  }

  DisableButtonSaveInteraction() {
    this.btnSave.nativeElement.disabled = true;
    this.btnSave.nativeElement.style.filter = 'Grayscale(0.75)';
    this.btnSave.nativeElement.style.cursor = 'not-allowed';
  }

  EnableButtonSaveInteraction() {
    this.btnSave.nativeElement.disabled = false;
    this.btnSave.nativeElement.style.filter = 'Grayscale(0)';
    this.btnSave.nativeElement.style.cursor = 'pointer';
  }

  @Output() GoForward = new EventEmitter<string>();
  @Output() SaveCustomerInteraction = new EventEmitter<string>(); //Temporary
}
