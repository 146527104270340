
<div *ngIf="showPage" class ="interaction-container global-seperator" style="overflow-y:auto;overflow-x:hidden;">   
    <div class="container global-seperator" *ngIf="(stage > 2) && (stage < 7)" style="padding: 20px 15px;">
        <div class="row">
            <div class="col-md-12">
                <h3><b>New Interaction</b></h3>
            </div>
        </div> 
    </div>
    <div class="form-content" style="height:83%;">
        <app-interaction-reason *ngIf="stage===0"></app-interaction-reason>   
        <app-interaction-privacy-notice *ngIf="stage===1" [(privacyPolicyHTML)]="privacyPolicyHTML" (ShowHideForwardArrow)="ShowHideForwardArrow();"></app-interaction-privacy-notice>
        <app-interaction-action *ngIf="stage===2" [(interaction)]="isrv.Interaction"></app-interaction-action>
        <app-interaction-customer-details *ngIf="stage===3" [(honorifics)]="honorifics" [(genders)]="genders"></app-interaction-customer-details>
        <app-interaction-identification *ngIf="stage===4" (ShowHideForwardArrow)="ShowHideForwardArrow();" (ImageTakenWithApp)="GoForward();"></app-interaction-identification>
        <app-interaction-description *ngIf="stage===5"></app-interaction-description>
        <app-interaction-review *ngIf="stage===6" (GoForward)="GoForward();"></app-interaction-review>
        <app-interaction-confirmation *ngIf="stage===7"></app-interaction-confirmation>
        <div *ngIf="ShowSaveProgressButton()" class="container" [style.marginTop]="asrv.Globals.Positioning.Medium">
            <div class="row" style="max-width:100%;">
                <div class="col-md-12" style="text-align: right;">
                    <button #update type="button" class="btn btn-success" (click)="SaveCustomerInteractionState();">  
                        <span *ngIf="showSaveStateSpinner" class="spinner-grow spinner-grow-sm" role="status"></span>
                        Save Progress
                    </button>  
                </div>
            </div>
        </div>
    </div>
    <div class="form-footer">
        <div class="navigation" (click)="GoBack()">
            <span *ngIf="(stage >= 0) && (stage < 7)  && (showForwardArrow)"><input type="image" class="stage-button" src=".\assets\icons\Bacta Assets\Back button blue.svg" style="width:100px" /></span>
        </div>
        <div *ngIf="stage===2" class="navigation-warning" style="text-align: center; vertical-align:middle; height: 100%;">
            <input id="btnStartSelfExclusion" class="bacta-button" type="image" src=".\assets\icons\Bacta Assets\Start Self-exclusion orange.svg" alt="Settings" (click)="StartSelfExclusion();" />
        </div>   
        <div class="navigation" (click)="GoForward()">
            <span *ngIf="(stage >= 0) && (stage < 6)  && (showForwardArrow)"><input type="image" class="stage-button" src=".\assets\icons\Bacta Assets\Next button blue.svg" style="width:100px" /></span>
        </div>   
    </div>
</div>  