import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { MessagingService } from 'src/app/services/messaging.service';

@Component({
  selector: 'app-messaging-main-view',
  templateUrl: './messaging-main-view.component.html',
  styleUrls: ['./messaging-main-view.component.scss']
})
export class MessagingMainViewComponent implements OnInit {
  @ViewChild('table') table: ElementRef;

  public objectKeys = [];
  public tableKeys = [];
  public showSendingSpinner: boolean = false;

  constructor(public msrv: MessagingService) { }

  ngOnInit() {
    this.objectKeys = Object.values(this.msrv.MessageList.HeaderTranslation[0]);
    this.tableKeys = Object.keys(this.msrv.MessageList.HeaderTranslation[0])
  }
}
