import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
// import { settings } from '../../../assets/settings.json'

@Component({
  selector: 'app-privacypolicy',
  templateUrl: './privacypolicy.component.html',
  styleUrls: ['./privacypolicy.component.scss']
})
export class PrivacypolicyComponent implements OnInit {
  public privacyPolicyHTML: string;
  private Endpoint = environment.apiURL //demo
  private readonly document: string = 'SummaryOfPrivacyPolicy';
  constructor(private http: HttpClient) { }

  ngOnInit() {
    this.Get(`api/View_documents_public/${this.document}`).subscribe(res => {
      this.Get<string>(`api/View_documents_public/${this.document}/${res[0]}`).subscribe(res => {
        this.privacyPolicyHTML = res;
      })
    })
  }

  public Get<T>(uri: string) : Observable<T> {
    let head: HttpHeaders = new HttpHeaders({});
    return this.http.get<T>(this.Endpoint + uri, {headers:head}).pipe();
  }
}
