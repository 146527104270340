// import { PropertyBindingType } from '@angular/compiler';
import { Injectable } from '@angular/core';
import { MatNativeDateModule } from '@angular/material/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { BreachList } from '../models/Reports/BreachList';
import { DataService } from './data.service';
import { ErrorhanderService } from './errorhander.service';

@Injectable({
  providedIn: 'root'
})
export class AdvancedSearchService {

  public SearchedFeature: string;
  public SearchedDomain: string;
  public SearchModel: SearchModel = new SearchModel();
  public SearchTerms: SearchTermCollection[] = [];
  public SearchResult: AdvancedSearchResult[];
  public MetaData: any[] = [];
  public MetaDataKeys: any[] = [];
  public ShowEditNavigation: boolean = false;

  public Properties = [
    { id: 0, domainIds: [4, 5, 6], name: 'Organisation', type: 'string', value: null },
    { id: 1, domainIds: [5], name: 'Site', type: 'string', value: null },
    { id: 2, domainIds: [0, 1], name: 'Forename', type: 'string', value: null },
    { id: 3, domainIds: [0, 1], name: 'Surname', type: 'string', value: null },
    { id: 4, domainIds: [0], name: 'Alias', type: 'string', value: null },
    { id: 5, domainIds: [2], name: 'Bacta', type: 'string', value: null },
    { id: 6, domainIds: [3], name: 'Line1', type: 'string', value: null },
    { id: 7, domainIds: [3], name: 'Line2', type: 'string', value: null },
    { id: 8, domainIds: [3], name: 'Town', type: 'string', value: null },
    { id: 9, domainIds: [3], name: 'County', type: 'string', value: null },
    { id: 10, domainIds: [3], name: 'Postcode', type: 'string', value: null },
  ]
  // {id: 11, domainIds: [3], name: 'City', type: 'string', value: null},
  // {id: 12, domainIds: [8], name: 'From', type: 'datetime', from: new Date(), dpd: 'dp1'}, 
  // {id: 13, domainIds: [8], name: 'To', type: 'test', from: new Date(), dpd: 'dp2'}, 
  // {id: 14, domainIds: [7], name: 'Products', type: 'string', value: null},

  public SearchingDomainId: number = 0;
  public Domains = [
    { id: 0, featureIds: [0, 1, 2, 3], name: 'InteractedActors', display: 'Name', properties: this.Properties.filter(x => x.domainIds.some(i => i === 0)) },
    { id: 1, featureIds: [0, 1, 2, 3, 4], name: 'EmployeeActors', display: 'Employee', properties: this.Properties.filter(x => x.domainIds.some(i => i === 1)) },
    { id: 2, featureIds: [0, 1, 2, 3], name: 'Reference', display: 'Bacta Reference', properties: this.Properties.filter(x => x.domainIds.some(i => i === 2)) },
    { id: 3, featureIds: [0, 1, 2, 3], name: 'InteractedActorAddress', display: 'Address', properties: this.Properties.filter(x => x.domainIds.some(i => i === 3)) },
  ]
  // {id: 4, featureIds: [4, 5, 6], name: 'Reference', display: 'Organisation Reference', properties: this.Properties.filter(x => x.domainIds.some(i => i === 4))}, 
  // {id: 5, featureIds: [5, 6], name: 'Actor', display: 'Actor', properties: this.Properties.filter(x => x.domainIds.some(i => i === 5))}, 
  // {id: 6, featureIds: [0], name: 'ProcessingActors', display: 'Location', properties: this.Properties.filter(x => x.domainIds.some(i => i === 6))},
  // {id: 7, featureIds: [0], name: 'Tags', display: 'Tags', properties: this.Properties.filter(x => x.domainIds.some(i => i === 7))},
  // {id: 8, featureIds: [0, 1, 2, 3, 4, 5, 6], name: 'Edited', display: 'Edited', properties: this.Properties.filter(x => x.domainIds.some(i => i === 8))},

  public SearchingFeatureId: number = 0;
  public Features = [
    { id: 0, name: 'CustomerInteractions', display: 'Customer Interaction', domains: this.Domains.filter(x => x.featureIds.some(i => i === 0)) },
    { id: 1, name: 'ExclusionInteractions', display: 'Self-Exclusion', domains: this.Domains.filter(x => x.featureIds.some(i => i === 1)) },
    { id: 2, name: 'ExclusionBreaches', display: 'Breaches', domains: this.Domains.filter(x => x.featureIds.some(i => i === 2)) },
    { id: 3, name: 'Reinstatements', display: 'Reinstatements', domains: this.Domains.filter(x => x.featureIds.some(i => i === 3)) },
  ]
  // {id: 4, name: 'EmployeeActors', display: 'Employee', domains: this.Domains.filter(x => x.featureIds.some(i => i === 4))},
  // {id: 5, name: 'Organisations', display: 'Organisation', domains: this.Domains.filter(x => x.featureIds.some(i => i === 5))},
  // {id: 6, name: 'Sites', display: 'Sites', domains: this.Domains.filter(x => x.featureIds.some(i => i === 6))},

  /* Currently know logic
    James uses the setid to associate which logic to apply. Any with the same set id will use an "AND" operator.
    Different setids likely use an "OR" operator.
  */
  public LogicFilters = [
    { Id: 0, BackendValue: 1, FrontendValue: 'and' },
  ]

  constructor(public dsrv: DataService, public errsrv: ErrorhanderService, private router: Router) { }

  ReinitializeSearchModel(): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      this.SearchingFeatureId = 0;
      this.SearchingDomainId = 0;
      this.Properties.forEach(property => { property.value = null; });

      this.ShowEditNavigation = false;
      this.SearchModel = new SearchModel();
      this.SearchTerms = [];
      resolve(true);
    })
  }

  Search(): Promise<boolean> {
    this.SearchedFeature = this.Features[this.SearchingFeatureId].name;
    this.SearchedDomain = this.Domains[this.SearchingDomainId].name;
    this.SearchModel.SearchSetChainCollection.push(new SearchSetChainCollection(1, this.Features[this.SearchingFeatureId].name))
    this.SearchModel.SearchSetChainLinkCollection.push(new SearchSetChainLinkCollection(1, 0, 1));

    var id: number = 1;
    this.Domains[this.SearchingDomainId].properties.forEach(property => {
      var searchTermCollection: SearchTermCollection = new SearchTermCollection(id, id, this.SearchedDomain, property.name, property.value, 1);
      if (searchTermCollection.SearchTerm) {
        id += 1;
        this.SearchModel.SearchTermCollection.push(searchTermCollection);
      }
    })
    this.MetaData = [];

    return new Promise<boolean>(resolve => {
      let subscription = this.QueryDatabase().subscribe(res => {
        subscription.unsubscribe();
        try {
          this.SearchResult = res;
          this.MetaDataKeys = Object.keys(this.SearchResult[0].MetaData);
          this.SearchResult.forEach(result => {
            result.Domain = this.SearchModel.SearchTermCollection.find(i => i.SetId === result.SearchSetChainId).Domain;
          })
        } catch (error) {
          console.log('No object returned, resetting advanced search');
          this.SearchResult = [];
          this.MetaDataKeys = [];
        }
        this.NavigateToSearchPage();
        this.ReinitializeSearchModel();
        resolve(true);
      }, err => {
        subscription.unsubscribe();
        this.ReinitializeSearchModel();
        resolve(false);
      });
    })
  }

  NavigateToSearchPage() {
    this.router.navigate(['/user/search']);
  }

  SearchRecordByClick(tableRow: AdvancedSearchResult) {
    console.log(tableRow, this.SearchedFeature);
    if (this.SearchedFeature !== 'ExclusionBreaches') {
      this.router.navigate([this.GetUrl(tableRow.KeyId)]);
    } else {
      this.router.navigate([this.GetUrl(tableRow.MetaData.Exclusions_InteractionID)]);
    }
  }

  SearchRecordByAuxClick(tableRow: AdvancedSearchResult) {

    if (this.SearchedFeature !== 'ExclusionBreaches') {
      window.open(this.GetUrl(tableRow.KeyId));
    } else {
      window.open(this.GetUrl(tableRow.MetaData.Exclusions_InteractionID));
    }
  }

  GetUrl(keyid: number) {
    switch (this.SearchedFeature) {
      case 'CustomerInteractions':
        return 'user/customer-interaction/view/' + keyid;
        break;
      case 'ExclusionBreaches':
        return 'user/breach/view/' + keyid;
        break;
      case 'ExclusionInteractions':
      case 'ExclusionReinstatements':
        return 'user/exclusion/view/' + keyid;
        break;
      default:
        break;
    }
  }

  QueryDatabase(): Observable<AdvancedSearchResult[]> {
    return this.dsrv.Post<any>('api/View_SearchCompositeModel', this.SearchModel);
  }
}

class SearchFilter {
  constructor(public Domain: string, public Property: string, public SearchTerm: string, public SetId: number, public Page: number, public SearchId) { }
}

class SearchModel {
  OrganisationIdSearchCollection: number[] = []
  OrganisationTagIdLookupOperatorSearchCollection: number[] = [];
  OrganisationTagIdCollection: number[] = [];
  SiteIdSearchCollection: number[] = [];
  SiteTagIdLookupOperatorSearchCollection: number[] = [];
  SiteTagIdCollection: number[] = [];
  TagIdLookupOperatorSearchCollection: number[] = [];
  TagIdSearchCollection: number[] = [];
  SearchSetChainCollection: SearchSetChainCollection[] = [];
  SearchSetChainLinkCollection: SearchSetChainLinkCollection[] = [];
  SearchTermCollection: SearchTermCollection[] = [];
  Limit: number = 0;
  Offset: number = 0;
}

class SearchSetChainCollection {
  constructor(public SearchSetChainId: number, public Feature: string) { };
}

class SearchSetChainLinkCollection {
  constructor(public SetId: number, public SetId_Seed: number, public SearchSetChainId: number) { };
}

export class SearchTermCollection {
  constructor(public Id: number, public Order: number, public Domain: string, public Property: string, public SearchTerm: string, public SetId: number) { };
}

export class AdvancedSearchResult {
  Domain: string;
  KeyId: number;
  KeyName: string;
  SearchSetChainId: number;
  SetId: number;
  MetaData: any; // JSON
}
