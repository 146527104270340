import { NonBiologicalName } from '../actor/Name';

export class ExclusionClientValues {
    IdChecked: boolean;
    Exclude_BetShop: boolean = false;
    Exclude_Bingo: boolean = false;
    Exclude_Online: boolean = false;
    Exclude_Casino: boolean = false;
    PlanExclude_BetShop: boolean = false;
    PlanExclude_Bingo: boolean = false;
    PlanExclude_Casino: boolean = false;
    PlanExclude_Online: boolean = false;
    Confirmed: boolean = false;
    Radius: number = 1;
    ExclusionDurationOptionId = 7;
    TermsAndConditions: boolean = false;
    ReinstateTerms: boolean = false;
    AgreeToThirdPartyContact: boolean = false;
    OptOutPromo: boolean = false;
    ExclusionSiteName: string;
    ExtensionExists: boolean = false;
    BreachExists: boolean = false;
    ReinstatementExists: boolean = false;

    SiteCollection: NonBiologicalName[] = [];
    SentFromInteractions: boolean;
}

class ExclusionSiteCollection {
    SiteId: number;
    Name: string;
}

/*            
    IdentityChecked = 31,
    ReadTermsAndConditions = 32,            
    ExcludeBingo = 33,
    ExcludeCasino = 34,
    ExcludeBetShop = 35,
    ExcludeOnline = 36,
    PlanExcludeBingo = 37,
    PlanExcludeCasino = 38,
    PlanExcludeBetShop = 39,
    PlanExcludeOnline = 40,
*/