import { Component, OnInit } from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ActorService } from 'src/app/services/actor.service';
import { AdvancedSearchService } from 'src/app/services/advanced-search.service';
import { ApiService } from 'src/app/services/api.service';
import { DataService } from 'src/app/services/data.service';
import { ErrorhanderService } from 'src/app/services/errorhander.service';
import * as moment from 'moment';

@Component({
  selector: 'app-statutory-visit',
  templateUrl: './statutory-visit.component.html',
  styleUrls: ['./statutory-visit.component.scss']
})
export class StatutoryVisitComponent implements OnInit {
  checkDate;
  checkDateModel;
  checkTime;
  siteID;
  officerFirstname;
  officerSurname;
  events: string[] = [];
  usingLoggedInName = false;
  employeeListLoading = false;
  loggedInUserID;
  employeeList = [];
  loggedInName;
  checkOKTrue;
  checkOKFalse;
  isSaving = false;
  employeeId;
  notes;
  errorMsg;
  isSavedSuccess;
  isError;
  statutoryListLoading = true;
  orgID;
  orgList = [];

  constructor(
    public dsrv: DataService,
    public asrv: ActorService,
    private router: Router,
    public errsrv: ErrorhanderService,
    public assrv: AdvancedSearchService,
    private titleService: Title,
    private _api: ApiService,
    private _snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    this.getEmployeeList();
    this.getStatutoryList();
    document.body.style.backgroundColor = '#c3cddd';
  }

  addEvent(type: string, event: MatDatepickerInputEvent<Date>) {
    this.events.push(`${type}: ${event.value}`);
    this.checkDate = moment(this.checkDateModel).format('YYYY-MM-DD');
  }

  getStatutoryList() {
    this.statutoryListLoading = false;
    const _searchModel = {
      OrganisationIdSearchCollection: [],
      OrganisationTagIdLookupOperatorSearchCollection: [],
      OrganisationTagIdCollection: [],
      SiteIdSearchCollection: [],
      SiteTagIdLookupOperatorSearchCollection: [],
      SiteTagIdCollection: [],
      TagIdLookupOperatorSearchCollection: [],
      TagIdSearchCollection: [],
      SearchSetChainCollection: [
        {
          SearchSetChainId: 1,
          Feature: "Organisations"
        }
      ],
      SearchSetChainLinkCollection: [
        {
          SetId: 1,
          SetId_Seed: 0,
          SearchSetChainId: 1
        }
      ],
      SearchTermCollection: [
        {
          Id: 1,
          Order: 1,
          Domain: "Tag",
          Property: "Name",
          SearchTerm: "Statutory Body",
          SetId: 1
        }
      ],
      Limit: 0,
      Offset: 0
    };
    this._api.getStatutoryBodyList(_searchModel).subscribe(collection => {
      const _actors = [];
      collection.forEach(actor => {
        _actors.push(actor.KeyId);
      });

      this._api.getActorNames(_actors).subscribe(result => {
        result.forEach(item => {
          this.orgList.push(
            {
              ActorId: item.ActorId,
              Name: item.NameCollection[0].Name
            }
          )
        });
        this.orgList.sort((a, b) => a.Name.toLowerCase().localeCompare(b.Name.toLowerCase()));
      });
      this.statutoryListLoading = false;
    });
  }

  getEmployeeList() {
    this.usingLoggedInName = false;
    this.employeeListLoading = true;
    const _userID = this.dsrv.GetMyUserID();
    this.loggedInUserID = _userID;
    // console.log(this.loggedInUserID);
    this._api.getUserOrgID(_userID).subscribe(results => {

      const _orgID = results[0].Organisations_ActorId;
      const _employeeIDs = [];
      this._api.getUserEmployeesList(_orgID).subscribe(employeeResults => {
        employeeResults.forEach(element => {
          _employeeIDs.push(element.Employees_ActorId);
        });

        this.asrv.GetBiologicalName(_employeeIDs).then(value => {
          this.employeeList = value;
          this.employeeListLoading = false;
        });
      },
        error => {
          this.usingLoggedInName = true;
          this.loggedInName = this.asrv.GetUsersFullName();
          this.employeeListLoading = false;
        });
    });
  }

  checkItem(wasChecked) {
    if (wasChecked) {
      this.checkOKTrue = true;
      this.checkOKFalse = false;
    } else {
      this.checkOKTrue = false;
      this.checkOKFalse = true;
    }
  }

  GoBack() {
    this.router.navigate(['/user/toolkit']);
  }

  closeNotification() {
    this.isError = false;
    this.isSavedSuccess = false;
  }

  saveCheck() {
    const _recordedDate = moment(this.checkDate + ' ' + this.checkTime, 'YYYY-MM-DD HH:mm').utc().format('YYYY-MM-DDTHH:mm');
    this.siteID = this.asrv.DefaultSiteId;
    this.isSaving = true;
    const _tagIDCollection = [];

    // if () {
      this._api.postStatutoryVisit(
        {
          AssociatedActorIdCollection: [
            this.employeeId
          ],
          InteractedActorCompositeModelCollection: [
            {
              BiologicalActorCompositeModel: {
                PostalAddressClassId: 1,
                Forename: this.officerFirstname,
                Surname: this.officerSurname
              }
            }
          ],

          Notes: this.notes,
          SiteId: this.siteID,
          Recorded: _recordedDate,
          TagIdCollection: _tagIDCollection,
          OrganisationID: this.orgID
        }
      ).subscribe(
        result => {
          this.isSaving = false;
          this.isSavedSuccess = true;
          this.isError = false;
          setTimeout(() => {
            this.isSavedSuccess = false;
            this.router.navigate(['/user/toolkit']);
          }, 3000);
        },
        error => {
          this.isSaving = false;
          this.isError = true;
          this.errorMsg = error.error;
          console.log(error);
        });
    // }

    this.isSaving = false;
  }
}
