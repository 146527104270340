<div class="interaction-container global-seperator">
    <div class="form-content" style="height:50%;margin-top:30px;">
        <app-customer-review *ngIf="stage===0"></app-customer-review>
        <app-interaction-action *ngIf="stage===1" [(interaction)]="isrv.Review"></app-interaction-action>
        <app-interaction-review *ngIf="stage===2" [(isReview)]="reviewMode" (GoForward)="GoForward()"></app-interaction-review>
    </div>

    <div class="form-footer">
        <div class="navigation" (click)="GoBack()" style="width: 120px;max-width:120px;">
            <span><input type="image" src=".\assets\icons\Bacta Assets\Back button blue.svg"style="width:100px;" /></span>
        </div>
        <div class="navigation-warning" style="text-align: center; vertical-align:middle; height: 100%;">
            <button *ngIf="(stage===0)" id="btnViewInteraction" class="btn btn-dark view-button" type="image" alt="Settings" (click)="ReturnToActionPage();">RETURN TO ACTION PAGE</button>
        </div>   
        <div class="navigation" (click)="GoForward()" style="width: 120px;max-width:120px;text-align: right;">
            <span><input *ngIf="(stage < 2)" type="image" src=".\assets\icons\Bacta Assets\Next button blue.svg" style="width:100px;" /></span>
        </div>   
    </div>
</div>