export class Positioning {
    // Standard
    public readonly Auto: string = 'auto';

    // Ensure there is consistancy where applicable.
    public readonly None: string = '0px';
    public readonly ExtraSmall: string = '5px';
    public readonly Small: string = '10px';
    public readonly Medium: string = '20px';
    public readonly Large: string = '30px';
    public readonly ExtraLarge: string = '40px';

    // Box Properties
    public readonly StandardBoxHeight: string = '200px';
    public readonly StandardBoxWidth: string = '100%';
}