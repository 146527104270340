<div class="container global-seperator" *ngIf="showApp===0">
    <div class="row">
        <div class="col-md-12 padding-top padding-bottom">
            <h5><b>Identification</b></h5>
        </div>
    </div>

    <div class="row flex-box padding-top">
        <div class="flex-4 exclusion-button" style="text-align: Left;">
            <input *ngIf="((isrv.Interaction.InteractedActorCompositeModel.BiologicalActorCompositeModel.Forename && isrv.Interaction.InteractedActorCompositeModel.BiologicalActorCompositeModel.Surname) || (isrv.Interaction.InteractedActorCompositeModel.BiologicalActorCompositeModel.Alias))" id="btnLiveCapture" class="bacta-button" type="image" alt="Settings" src=".\assets\icons\Bacta Assets\Capture Live BLUE.svg" style="width:200px;" (click)="LiveCapture();"/>
            <input *ngIf="!((isrv.Interaction.InteractedActorCompositeModel.BiologicalActorCompositeModel.Forename && isrv.Interaction.InteractedActorCompositeModel.BiologicalActorCompositeModel.Surname) || (isrv.Interaction.InteractedActorCompositeModel.BiologicalActorCompositeModel.Alias))" id="btnLiveCapture" class="bacta-button disabled" type="image" alt="Settings" src=".\assets\icons\Bacta Assets\Capture Live GREY.svg" style="width:200px;" disabled/>
        </div>
        <div class="flex-8 text-center">
            <span>Use if customer agrees to photo being taken</span>
        </div>
    </div>

    <div class="row flex-box padding-top">
        <div class="flex-4 exclusion-button" style="text-align: Left;">
            <input *ngIf="((isrv.Interaction.InteractedActorCompositeModel.BiologicalActorCompositeModel.Forename && isrv.Interaction.InteractedActorCompositeModel.BiologicalActorCompositeModel.Surname) || (isrv.Interaction.InteractedActorCompositeModel.BiologicalActorCompositeModel.Alias))" id="btnOutlineSelector" class="bacta-button" type="image" alt="Settings" src=".\assets\icons\Bacta Assets\Capture Outline blue.svg" style="width:200px;" (click)="PickOutline();"/>
            <input *ngIf="!((isrv.Interaction.InteractedActorCompositeModel.BiologicalActorCompositeModel.Forename && isrv.Interaction.InteractedActorCompositeModel.BiologicalActorCompositeModel.Surname) || (isrv.Interaction.InteractedActorCompositeModel.BiologicalActorCompositeModel.Alias))" id="btnOutlineSelector" class="bacta-button disabled" type="image" alt="Settings" src=".\assets\icons\Bacta Assets\Capture outline grey.svg" style="width:200px;" disbled/>
        </div>
        <div class="flex-8 text-center">
            <span>Use if customer provides name but no photo</span>
        </div>
    </div>

    <div class="row flex-box padding-top">
        <div class="flex-4 exclusion-button" style="text-align: Left;"> 
            <input *ngIf="!(isrv.Interaction.InteractedActorCompositeModel.BiologicalActorCompositeModel.Forename && isrv.Interaction.InteractedActorCompositeModel.BiologicalActorCompositeModel.Surname)" id="btnIconSelector" class="bacta-button" type="image" alt="Settings" src=".\assets\icons\Bacta Assets\Capture Icon blue.svg" style="width:200px;" (click)="PickIcon();"/>
            <input *ngIf="(isrv.Interaction.InteractedActorCompositeModel.BiologicalActorCompositeModel.Forename && isrv.Interaction.InteractedActorCompositeModel.BiologicalActorCompositeModel.Surname)" id="btnIconSelector" class="bacta-button" type="image" alt="Settings" src=".\assets\icons\Bacta Assets\Capture Icon grey.svg" style="width:200px;" (click)="PickIcon();"/>
        </div>
        <div class="flex-8 text-center">
            <span>Use if customer provides no name or photo</span>
        </div>
    </div>

    <div class="row" *ngIf="(photoExists)" style="padding-top:15px;" >
        <div class="col-sm-12 image-container" style="text-align: center;">
            <img #image *ngIf="showImage" class="excluder-image-preview" [src]="isrv.Interaction.MediaModelCollection[0].Data" id="excluderCapture" style="max-width:480px; max-height:360px;">
            <app-icon-image *ngIf="!showImage" [(iconId)]="isrv.Interaction.MediaModelCollection[0].MediaId"></app-icon-image>
        </div>
    </div>

</div>

<div *ngIf="(showApp===1)" class="container global-seperator" style="padding-left:0px;padding-right:0px;"> 
    <app-customer-photo  [(mediaModel)]="isrv.Interaction.MediaModelCollection" (ImageTaken)="ImageTaken();" (NoImageTaken)="NoImageTaken();"></app-customer-photo>
</div>  

<div *ngIf="(showApp===2)" class="container global-seperator">
    <app-icon-gallery [(showIcons)]="showIcons"></app-icon-gallery>
    <div class="form-group" style="text-align: right;">
        <button type="button" class="btn btn-danger" (click)="ClosePhotoApp();" style="margin-right: 10px;">Close</button>
        <button type="button" class="btn btn-success" (click)="SaveImage();">Save Image</button>
    </div>
</div>