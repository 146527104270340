<div *ngIf="!showOrganisationData"  class="container global-seperator">
    <div class="row">
        <div class="col-md-6">
            <div class="flex-check-box">
                <div class="flex-check-prepend">Show Suspended</div>
                <input id="chkTakeABreak" type="checkbox" class="flex-check-box-control" [(ngModel)]="showSuspended" (ngModelChange)="FilterOrganisations();">
            </div>  
        </div>
        <div *ngIf="adminsrv.IsAdmin" class="col-md-6">
            <div class="form-group" style="width: 100%; text-align:right; margin-top:10px;">
                <button type="button" class="btn btn-success" (click)="AddNewRecord();">Add New Organisation</button>
            </div>
        </div>
    </div>

    <div *ngIf="!dataLoaded" class="container global-seperator" style="min-height:400px;">
        <span>
            <app-loading></app-loading>
        </span>
    </div>

    <div *ngIf="dataLoaded" class="row">
        <div #table id="siteTable" class="table-responsive bacta-table-outline" style="margin-top:10px;"> <!--(scroll)="TableScrollEvent();"-->
            <table class="table">
                <thead class="table-header">
                    <th class="table-header-inner bacta-table-header-text" *ngFor="let key of objectKeys" (click)="SortSiteList(key);">{{key}}</th>
                </thead>
                <tbody class="table-row" *ngFor="let row of object, index as i" style="background-color: white; color:red;">
                    <td class="table-row-inner" *ngFor="let x of tableKeys" (click)="ShowOrganisation(row);">
                        <span *ngIf="row[x]">{{row[x]}}</span>
                        <div *ngIf="row[x] === false" class="spinner-border" role="status">
                            <span class="sr-only"></span>
                        </div>
                    </td>            
                </tbody>
            </table>
        </div>
    </div>
</div> 

<div *ngIf="showOrganisationData">
    <app-edit-organisation [(organisation)]="organisation" (CloseEditForm)="CloseEditForm();"></app-edit-organisation>
</div>