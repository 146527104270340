import { ActorService } from "src/app/services/actor.service";
import { AdvancedSelect } from "../Non-Standard Select Controllers/AdvancedSelect";
import { Checkbox } from "../Standard Controllers/Checkbox";
import { DropDownItem, Select } from "../Standard Controllers/Select";

export class Reinstatement {
    private MethodDropDownItems: DropDownItem[] = [
        {Key: 1, Value: 'Meeting'},
        {Key: 2, Value: 'Phone'}
        // {Key: 3, Value: 'Email'}
        // {Key: 4, Value: 'Letter'}
    ]
    public SiteId: number;
    public EmployeeId: number;
    public MethodId: number;
    public MethodControl: Select;
    public Date: Date;
    public ConsideredControl: Checkbox;
    public RecievedCounsellingControl: Checkbox;
    public UnderstandsCoolingOffControl: Checkbox;
    public AwareOfMonitoringControl: Checkbox;

    constructor(public OrganisationId: number) {
        this.MethodId = 0;
        this.MethodControl = new Select(0, 'method', 'Method', 'ddMethod', this.MethodDropDownItems);
        this.Date = new Date();
        this.ConsideredControl = new Checkbox(0, 'considered', 'I HAVE CONSIDERED THE IMPLICATIONS OF GAMBLING AGAIN.', 'chkConsidered', false);
        this.RecievedCounsellingControl = new Checkbox(0, 'receivedCounselling', 'I HAVE RECEIVED GAMBLING COUNSELLING WHILST SELF-EXCLUDED.', 'chkReceivedCounselling', false);
        this.UnderstandsCoolingOffControl = new Checkbox(0, 'understandsCoolingOff', 'I UNDERSTAND THAT I AM SUBJECT TO A 24 HOUR COOLING OFF PERIOD.', 'chkUnderstandsCoolingOff', false);
        this.AwareOfMonitoringControl = new Checkbox(0, 'awareOfMonitoring', 'I AM AWARE THAT MY GAMBLING/BEHAVIOUR WILL BE MONITORED.', 'chkAwareOfMonitoring', false);
    }
    
    // Returns a string array if any value is found. Else will return null.
    Validate(): string[] {
        var result: string[];
        if (!this.OrganisationId) {
            result.push('Organisation');
        }

        if (!this.SiteId) {
            result.push('Site');
        }

        if (!this.EmployeeId) {
            result.push('Employee');
        }

        if (!this.MethodId) {
            this.MethodControl.ErrorFlag = true;
            result.push('Method');
        }

        if (!this.ConsideredControl.Value || !this.UnderstandsCoolingOffControl.Value || ! this.AwareOfMonitoringControl.Value) {
            this.ConsideredControl.ErrorFlag = true;
            this.RecievedCounsellingControl.ErrorFlag = true;
            this.UnderstandsCoolingOffControl.ErrorFlag = true;
            this.AwareOfMonitoringControl.ErrorFlag = true;
            result.push('Checkboxes')
        }
        return result
    }
}