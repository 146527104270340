import { Injectable, EventEmitter } from '@angular/core';
import { DataService } from './data.service';
import { Observable } from 'rxjs';
import { Addresses, AddressDetails, GeocodingItems } from '../models/Addresses';
import { debounceTime } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PostcodeanywhereService {
  addresses: Addresses;
  constructor(public dsrv: DataService) {
  }

  getAddressId(postcode: string): Observable<Addresses> {
    return this.dsrv.getAddressId<Addresses>(postcode);
  }

  getAddressList(postcode: string, Id: string): Observable<Addresses> {
    console.log(postcode, Id);
    return this.dsrv.getAddressList<Addresses>(postcode, Id);
  }

  getAddressDetails(Id: string): Observable<AddressDetails> {
    return this.dsrv.getAddressDetails<AddressDetails>(Id);
  }  

  getGeocode(Location: string): Observable<GeocodingItems> {
    return this.dsrv.getGeocode<GeocodingItems>(Location);
  }
}
