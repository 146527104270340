<div #table class="table-responsive bacta-table-outline" style="margin-top:10px;">
    <table class="table">
        <thead class="table-header">
            <th class="table-header-inner">Sites Excluded From</th>
        </thead>
        <tbody class="table-row" style="background-color: white; color:red;" *ngFor="let site of exclusionSites">
            <td *ngIf="(!site)" class="spinner-border text-danger" role="status" style="margin-left:15px; height: 20px; width: 100%;"></td>
            <td *ngIf="(site)" class="table-row-inner">{{site}}</td>
        </tbody>
    </table>
</div>

<div class="form-group" style="text-align: right;">
    <button type="button" class="btn btn-danger" (click)="evsrv.ShowMainView(false);" style="margin-right: 10px;">Close</button>
</div>