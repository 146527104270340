import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-exclusion-journey',
  templateUrl: './exclusion-journey.component.html',
  styleUrls: ['./exclusion-journey.component.scss']
})
export class ExclusionJourneyComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
